import React from 'react';
import { abbreviateNumber } from '/src/util/formatting/numbers';
import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import { DealDetailData } from '/../libs/shared-types/src/types/view/AggregatedDeals';
import DateString from '/src/components/utility/DateString';
import ListSummaryCard from '/src/components/cards/ListSummaryCard';

interface DealDetailInvestmentSummaryCardProps {
  dealDetailData: DealDetailData;
}

function DealDetailInvestmentSummaryCard({
  dealDetailData,
}: DealDetailInvestmentSummaryCardProps): JSX.Element {
  const sortedPortfolioStatuses = dealDetailData.investorActivity.statusHistory
    .filter((x) => x.status === DealStatusTypes.Portfolio)
    .sort((x, y) => x.statusChangedOn.getTime() - y.statusChangedOn.getTime());

  const investmentTotalSum = dealDetailData.investments
    .map((x) => x.amount)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  const investmentEstimatedValue = dealDetailData.investments
    .map((x) => x.estimatedValue)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  const investmentTotalGrossMoic =
    investmentEstimatedValue / investmentTotalSum;

  const investmentSummaryCardData = {
    'First Invested': (
      <DateString
        date={sortedPortfolioStatuses[0]?.statusChangedOn}
        tooltipPosition="bottom"
      />
    ),
    'Number of Investments': sortedPortfolioStatuses.length,
    'Total Invested': `$ ${abbreviateNumber(investmentTotalSum)}`,
    'Estimated Value': `$ ${abbreviateNumber(investmentEstimatedValue)}`,
    MOIC: `${investmentTotalGrossMoic.toFixed(2)}x`,
  };

  return (
    <>
      {sortedPortfolioStatuses.length > 0 && (
        <div>
          <ListSummaryCard
            headerTitle="Investment Summary"
            dataObj={investmentSummaryCardData}
            classElm={{
              title: 'flex text-lg leading-6 font-medium text-gray-900',
              headerContainer: `flex flex-wrap rounded-t-lg justify-between 
                       align-middle border-b shadow`,
              bodyContainer: 'rounded-b-lg shadow',
              keyElms: 'text-sm font-medium text-gray-500',
              valElm: 'text-sm font-medium space-x-2 space-y-2 text-gray-900  ',
              notificationContainer: `flex justify-center items-center pl-6 h-18
                       font-medium rounded-b-lg shadow`,
              notificationLabel: 'app-tag app-tag--red',
            }}
          >
            <>
              {
                <div className="flex items-center text-gray-500">
                  <span className="mr-1 text-sm">Last invested</span>
                  <b className="text-sm">
                    <DateString
                      date={
                        sortedPortfolioStatuses[
                          sortedPortfolioStatuses.length - 1
                        ].statusChangedOn
                      }
                      tooltipPosition="bottom"
                    />
                  </b>
                </div>
              }
            </>
          </ListSummaryCard>
        </div>
      )}
    </>
  );
}

export default DealDetailInvestmentSummaryCard;
