import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Stage } from '/../libs/shared-types/src/types/model/Stage';
import { DealDetailData } from '/../libs/shared-types/src/types/view/AggregatedDeals';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import DealDetailRouteContent from './DealDetailRouteContent';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import { createNewAuthenticatedDealViewSession } from '/src/services/DealViewSession';
import { DealDetailTypes } from '/../libs/shared-types/src/constants/DealDetailTypes';
import {
  InvestorDealflowActiveBoardStages,
  InvestorGetDealById,
} from '/../libs/shared-types/src/constants/ApiRoutes';

function DealDetailRoute(): JSX.Element {
  const { id } = useParams();

  const [aggregatedDeal, setAggregatedDeal] = useState<DealDetailData>();
  const [isInDealflow, setIsInDealflow] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [activeBoardStages, setActiveBoardStages] = useState<Stage[]>([]);
  const { joyrideActivationTourState, setJoyrideActivationTourState } =
    useContext(SharedStateContext);
  const [dealViewSessionId, setDealViewSessionId] = useState<string>();

  async function createDealViewSession(startupId: string) {
    const newDealViewSession = await createNewAuthenticatedDealViewSession(
      DealDetailTypes.DealFull,
      undefined,
      undefined,
      startupId,
      undefined,
    );
    setDealViewSessionId(newDealViewSession?.dealViewSession._id);
  }

  async function fetchDeal(isRefreshing: boolean) {
    try {
      setIsLoading(!isRefreshing);
      const dealDetailData = await API.get<DealDetailData>(
        InvestorGetDealById.buildEndpoint(id),
      );

      if (!isRefreshing) {
        await createDealViewSession(dealDetailData.startup._id);
      }
      setAggregatedDeal(dealDetailData);

      if (
        dealDetailData.investorActivity.priorityHistoryPerRound.length !==
        dealDetailData.investorActivity.ratingHistoryPerRound.length
      ) {
        Logger.error('Priority and rating history lengths do not match');
      }
    } catch (error: any) {
      setIsInDealflow(false);
      Logger.error(error);
    } finally {
      setIsLoading(false);
      setJoyrideActivationTourState({
        ...joyrideActivationTourState,
        run: true,
      });
    }
  }

  async function fetchActiveStageNames() {
    try {
      const data = await API.get<Stage[]>(
        InvestorDealflowActiveBoardStages.buildEndpoint(),
      );
      setActiveBoardStages(data);
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  useEffect(() => {
    fetchActiveStageNames();
    fetchDeal(false);
  }, []);

  if (isLoading) {
    return (
      <PageLoadingSpinner message="Loading potential unicorn&nbsp;...&nbsp;🦄" />
    );
  }

  return aggregatedDeal && dealViewSessionId ? (
    <DealDetailRouteContent
      activeBoardStages={activeBoardStages}
      dealDetailData={aggregatedDeal}
      fetchDeal={fetchDeal}
      isInDealflow={isInDealflow}
      dealViewSessionId={dealViewSessionId}
    />
  ) : (
    <div className="min-h-screen bg-gray-100">
      <main className="py-10">
        <h3>
          Oops! The startup either doesn&apos;t exist or you do not have access
          to it.
        </h3>
      </main>
    </div>
  );
}

export default DealDetailRoute;
