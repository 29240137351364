import { listToSelectOptions } from '../../extensions/SelectOptionsExtensions';

const UNIVERSITY_OPTIONS = [
  {
    groupLabel: 'United States',
    options: [
      'Massachusetts Institute of Technology',
      'Harvard University',
      'Stanford University',
      'Cornell University',
      'University of California, Berkeley',
      'Columbia University in the City of New York',
      'University of Michigan',
      'University of Washington',
      'University of Pennsylvania',
      'University of Minnesota-Twin Cities',
      'Yale University',
      'University of California, Los Angeles',
      'Johns Hopkins University',
      'The University of Texas at Austin',
      'City University of New York',
      'New York University',
      'University of Wisconsin-Madison',
      'Purdue University',
      'Penn State University',
      'Princeton University',
      'Carnegie Mellon University',
      'University of Southern California',
      'University of Chicago',
      'Arizona State University',
      'University of Florida',
      'Michigan State University',
      'University of California, San Diego',
      'University of Illinois at Urbana-Champaign',
      'University of North Carolina at Chapel Hill',
      'Duke University',
      'Northwestern University',
      'University of California, Davis',
      'Boston University',
      'University of Maryland',
      'Rutgers, The State University of New Jersey',
      'University of California, Irvine',
      'The University of Arizona',
      'Texas A&M University',
      'The University of Utah',
      'University of Virginia',
      'The Ohio State University',
      'University of Colorado Boulder',
      'North Carolina State University',
      'Georgia Institute of Technology',
      'University of Pittsburgh',
      'Indiana University Bloomington',
      'California Institute of Technology',
      'Washington University in St. Louis',
      'University of California, Santa Barbara',
      'Georgetown University',
      'Tufts University',
      'University of Nebraska-Lincoln',
      'Virginia Polytechnic Institute and State University',
      'Florida State University',
      'University of South Florida',
      'Oregon State University',
      'University of Massachusetts Amherst',
      'University of Georgia',
      'Brown University',
      'Iowa State University',
      'Brigham Young University',
      'University of Rochester',
      'University of Iowa',
      'University of California, Riverside',
      'Washington State University',
      'University of Delaware',
      'Georgia State University',
      'William Marsh Rice University',
      'University at Buffalo, State University of New York',
      'Dartmouth College',
      'George Washington University',
      'Vanderbilt University',
      'Syracuse University',
      'Colorado State University',
      'University of Houston',
      'George Mason University',
      'University of Oregon',
      'University of California, Santa Cruz',
      'University of Illinois at Chicago',
      'University of California, San Francisco',
      'University of Notre Dame',
      'University of Connecticut',
      'Emory University',
      'Temple University',
      'Northeastern University',
      'University of Missouri',
      'University of Central Florida',
      'University of Kansas',
      'University of Kentucky',
      'Florida International University',
      'University of New Mexico',
      'Drexel University',
      'The University of Tennessee, Knoxville',
      'Rochester Institute of Technology',
      'University of Miami',
      'Louisiana State University',
      'The University of Alabama',
      'San Diego State University',
      'American University',
      'Boston College',
      'Fordham University',
      'The University of Oklahoma',
      'University of Vermont',
      'The University of Tennessee at Martin',
      'University of New Hampshire',
      'Clemson University',
      'University of Cincinnati',
      'University of North Texas',
      'University of South Carolina',
      'Southern Methodist University',
      'California State University, Northridge',
      'San José State University',
      'Utah State University',
      'Case Western Reserve University',
      'Virginia Commonwealth University',
      'San Francisco State University',
      'Baylor University',
      'Oklahoma State University',
      'Auburn University',
      'West Virginia University',
      'Rensselaer Polytechnic Institute',
      'University of Alabama at Birmingham',
      'Texas Tech University',
      'Florida Atlantic University',
      'Portland State University',
      'College of William & Mary',
      'Tulane University',
      'Stony Brook University',
      'University of Wisconsin-Milwaukee',
      'The University of Texas at Dallas',
      'Michigan Technological University',
      'Mississippi State University',
      'University of Denver',
      'Kent State University',
      'Wayne State University',
      'Brandeis University',
      'The New School',
      'University of Maryland, Baltimore County',
      'California State University, Long Beach',
      'Marquette University',
      'Santa Clara University',
      'University of Arkansas',
      'Southern New Hampshire University',
      'Indiana University - Purdue University Indianapolis',
      'Northern Illinois University',
      'University at Albany, State University of New York',
      'University of Rhode Island',
      'DePaul University',
      'The University of Maine',
      'Kansas State University',
      'University of Nevada, Reno',
      'Illinois Institute of Technology',
      'Liberty University',
      'Loyola University Chicago',
      'New Mexico State University',
      'California State University, Fullerton',
      'California Polytechnic State University, San Luis Obispo',
      'The University of Texas at San Antonio',
      'Binghamton University, State University of New York',
      'University of Wyoming',
      'Northern Arizona University',
      'University of Missouri-Kansas City',
      'Montana State University',
      'Wake Forest University',
      'The University of Texas at Arlington',
      'Western Michigan University',
      'University of Idaho',
      'University of Nevada, Las Vegas',
      'The University of Texas MD Anderson Cancer Center',
      'Bowling Green State University',
      'Oregon Health & Science University',
      'James Madison University',
      'Quinnipiac University',
      'University of Mississippi',
      'Nova Southeastern University',
      'University of San Francisco',
      'Carleton College',
      'University of Alaska Fairbanks',
      'Middlebury College',
      'New Jersey Institute of Technology',
      'Ohio University',
      'Worcester Polytechnic Institute',
      'University of Louisville',
      'United States Air Force Academy',
      'The University of Montana',
      'Texas State University',
      'Berklee College of Music',
      'Pepperdine University',
      'Wesleyan University',
      'Lehigh University',
      'Saint Louis University',
      'Appalachian State University',
      'Swarthmore College',
      'Williams College',
      'University of North Dakota',
      'Old Dominion University',
      'Bard College',
      'Southern Illinois University Carbondale',
      'Chapman University',
      'University of Massachusetts Boston',
      'Embry-Riddle Aeronautical University',
      'The University of Memphis',
      'University of Maryland, Baltimore',
      'University of Richmond',
      'University of North Carolina at Greensboro',
      'California State Polytechnic University, Pomona',
      'University of Dayton',
      'Pace University',
      'University of North Carolina at Charlotte',
      'California State University, Sacramento',
      'California State University, Chico',
      'Western Washington University',
      'Miami University',
      'Kennesaw State University',
      'Amherst College',
      'Grand Valley State University',
      'Howard University',
      'Baylor College of Medicine',
      'Calvin University',
      'Clark University',
      'University of Massachusetts Lowell',
      'Smith College Northampton',
      'Villanova University',
      'University of Colorado Denver',
      'Yeshiva University',
      'University of Akron',
      'Hofstra University',
      'Creighton University',
      'The University of Texas at El Paso',
      'The Catholic University of America',
      'University of San Diego',
      'University of Michigan-Dearborn',
      'Mount Holyoke College',
      'Middle Tennessee State University',
      'North Dakota State University',
      'Seton Hall University',
      'Boise State University',
      'Illinois State University',
      'University of Northern Iowa',
      'Bryn Mawr College',
      'United States Military Academy at West Point',
      'Babson College',
      'Bucknell University',
      'Wellesley College',
      'Loyola Marymount University',
      'Vassar College',
      'University of Nebraska at Omaha',
      'Wright State University',
      'Brigham Young University-Idaho',
      'Cleveland State University',
      'Savannah College of Art and Design',
      'Colby College',
      'University of Toledo',
      'East Carolina University',
      'Montclair State University',
      'Monmouth University',
      'Thomas Jefferson University',
      'Central Michigan University',
      'Marist College Poughkeepsie',
      'Humboldt State University',
      'Texas Christian University',
      'Rasmussen College',
      'Grand Canyon University',
      'Western Kentucky University',
      'Elon University',
      'University of Missouri-St. Louis',
      'University of North Florida',
      'The University of Alabama in Huntsville',
      'Ithaca College',
      'Lehman College, CUNY',
      'Medical University of South Carolina',
      'California State University, San Bernardino',
      'Oakland University',
      'Missouri State University',
      'Harvey Mudd College',
      'Southern Illinois University Edwardsville',
      'Loma Linda University',
      'University of Southern Mississippi',
      'Ball State University',
      'Sonoma State University',
      'Wichita State University',
      'Oberlin College',
      'College of Charleston',
      'Missouri University of Science and Technology',
      'Towson University',
      'Eastern Michigan University',
      'Bowdoin College',
      'Suffolk University',
      'University of St. Thomas',
      'University of Texas Southwestern Medical Center',
      'University of Massachusetts Medical School',
      'Kenyon College',
      'Marshall University',
      'Rhode Island School of Design',
      'Idaho State University',
      'Eastern Kentucky University',
      'Florida Institute of Technology',
      'Ferris State University',
      'California State University, Fresno',
      'University of California, Merced',
      'Lamar University',
      'Sam Houston State University',
      'University of North Carolina Wilmington',
      'Earlham College',
      'The University of Texas Health Science Center at San Antonio',
      'University of Baltimore',
      'Fashion Institute of Technology',
      'East Tennessee State University',
      'Pomona College',
      'Gallaudet University',
      'Duquesne University',
      'University of Wisconsin-Eau Claire',
      'The Evergreen State College',
      'Seattle University',
      'University of Colorado Colorado Springs',
      'Bentley University',
      'Washington and Lee University',
      'Weber State University',
      'Rowan University',
      'Minnesota State University, Mankato',
      'Dickinson College',
      'Biola University',
      'Western Illinois University',
      'Valencia College',
      'University of Wisconsin-Stevens Point',
      'Davidson College',
      'Reed College',
      'The University of Texas Health Science Center at Houston',
      'Webster University',
      'Georgia Southern University',
      'Hanover College',
      'School of Visual Arts',
      'Barnard College',
      'University of Illinois at Springfield',
      'The Rockefeller University',
      'University of South Dakota',
      'The University of Tulsa',
      'California State University, Los Angeles',
      'The University of Texas Medical Branch at Galveston',
      'University of Massachusetts Dartmouth',
      'Central Connecticut State University',
      'Simmons University',
      'DeVry University',
      'Lock Haven University',
      'Stetson University',
      'Trinity College',
      'Bates College',
      'Fairleigh Dickinson University',
      'College of the Holy Cross',
      'Radford University',
      'Miami Dade College',
      'University of Nebraska Medical Center',
      'Hobart and William Smith Colleges',
      'United States Naval Academy',
      'University of Texas Rio Grande Valley',
      'Colgate University',
      'Northern Michigan University',
      'California State University San Marcos',
      'New Mexico Institute of Mining and Technology',
      'Clarkson University',
      "St. John's University",
      'Wheaton College',
      'Eastern Illinois University',
      'Furman University',
      'Indiana University of Pennsylvania',
      'Maharishi International University',
      'Pratt Institute',
      'Macalester College',
      'Utah Valley University',
      'Gonzaga University',
      'Florida Gulf Coast University',
      'University of Arkansas at Little Rock',
      'Eastern Washington University',
      'Maryville University',
      'California State University, Dominguez Hills',
      'University of the Pacific',
      'Medical College of Wisconsin',
      'University of Wisconsin-Green Bay',
      'St. Olaf College',
      'Stevens Institute of Technology',
      "College of Saint Benedict/Saint John's University",
      'ArtCenter College of Design',
      'Colorado School of Mines',
      'The Culinary Institute of America',
      'Indiana State University',
      'West Chester University of Pennsylvania',
      'Gettysburg College',
      'Georgetown College',
      'Western Carolina University',
      'State University of New York at Oswego',
      'Emerson College',
      'Hillsdale College',
      'Haverford College',
      'Hamilton College',
      'University of Wisconsin-Oshkosh',
      'Skidmore College',
      'Augusta University',
      'DePauw University',
      'California State University, Stanislaus',
      'Northern Kentucky University',
      'University of Hawaii-West Oahu',
      'Central Washington University',
      'Texas A&M University-Corpus Christi',
      'Drew University',
      'Grinnell College',
      'SUNY College of Environmental Science and Forestry',
      'North Carolina Agricultural and Technical State University',
      'Rider University',
      'Drake University',
      'The Juilliard School',
      'Loyola University New Orleans',
      'University of Northern Colorado',
      'Colorado College',
      'Fairfield University',
      'Loyola University Maryland',
      'St. Cloud State University',
      'Union College',
      'Lafayette College',
      'Connecticut College',
      'University of Wisconsin-La Crosse',
      'Lewis & Clark College',
      'Bradley University',
      'California State University, East Bay',
      'Tennessee Tech University',
      'Strayer University',
      'Hope College',
      'Shippensburg University of Pennsylvania',
      'University of Evansville',
      'Hult International Business School',
      'Butler University',
      'Arkansas State University',
      'Broward College',
      'Naval Postgraduate School',
      'Occidental College',
      'Long Island University',
      'Willamette University',
      "Saint Joseph's University",
      'The University of Tennessee at Chattanooga',
      'Azusa Pacific University',
      'The College of New Jersey',
      'The Cooper Union for the Advancement of Science and Art',
      'Stephen F. Austin State University',
      'Rhode Island College',
      'Johnson and Wales University',
      'Hampshire College',
      'Adelphi University',
      'South Dakota State University',
      'Widener University',
      'Regent University',
      'Trinity University',
      'University of Louisiana at Lafayette',
      'Mercer University',
      'SUNY Cortland',
      'Valparaiso University',
      'Whitman College',
      'University of West Florida',
      'University of Wisconsin-Whitewater',
      'University of New Orleans',
      'University of Central Arkansas',
      'Stockton University',
      'Rose-Hulman Institute of Technology',
      'Pitzer College',
      'University of California, Hastings College of the Law',
      'Andrews University',
      'Seattle Pacific University',
      'Pacific University',
      'Belmont University',
      'Academy of Art University',
      'Plymouth State University',
      'University of West Georgia',
      'Xavier University',
      'State University of New York at New Paltz',
      'Franklin and Marshall College',
      'Columbia College Chicago',
      'Bridgewater State University',
      'Abilene Christian University',
      'Purdue University Fort Wayne',
      'Truman State University',
      'Antioch University',
      'University of Mary Washington',
      'West Texas A&M University',
      'Tennessee State University',
      'Claremont Graduate University',
      'Morgan State University',
      'National University',
      'Champlain College',
      'Youngstown State University',
      'Cedarville University',
      'Samford University',
      'University of Scranton',
      'Norwich University',
      'University of Southern Indiana',
      'Rollins College',
      'Pacific Lutheran University',
      'Frostburg State University',
      'Delaware State University',
      'Louisiana Tech University',
      'The University of Tampa',
      'Metropolitan State University of Denver',
      "Texas Woman's University",
      'University of Arkansas for Medical Sciences',
      'National Defense University',
      'Gustavus Adolphus College',
      'Sweet Briar College',
      'Agnes Scott College',
      'Troy University',
      'Siena College',
      'Fort Hays State University',
      'Valdosta State University',
      'SUNY Geneseo',
      'Florida Agricultural and Mechanical University',
      'University of New England',
      'California Institute of the Arts',
      'Touro College',
      'Seton Hill University',
      'Elmhurst University',
      'Emporia State University',
      'University of Hartford',
      'School of the Art Institute of Chicago',
      'University of Wisconsin-Stout',
      'Kean University',
      'Illinois Wesleyan University',
      'California State University, Monterey Bay',
      'Wabash College',
      'Lawrence University',
      'Morehouse College',
      'Lesley University',
      'Hamline University',
      'St. Catherine University',
      'Louisiana State University Health Sciences Center',
      'Claremont McKenna College',
      'Rhodes College',
      'Texas Tech University Health Sciences Center',
      'Western Oregon University',
      'Spelman College',
      'Sewanee: The University of the South',
      'Icahn School of Medicine at Mount Sinai',
      'Coastal Carolina University',
      'Muhlenberg College',
      'Berea College',
      'Maryland Institute College of Art',
      'William Paterson University',
      'The College of Wooster',
      'Salisbury University',
      'Tuskegee University',
      'Southern Oregon University',
      'California Lutheran University',
      'SUNY College at Oneonta',
      'Southern Utah University',
      'North Carolina Central University',
      'Golden Gate University',
      'University of Puget Sound',
      'University of Hawaii at Manoa',
      'Virginia Military Institute',
      "St. Edward's University",
      'Bryant University',
      'Southeast Missouri State University',
      'Goucher College',
      'Murray State University',
      'Roger Williams University',
      'Denison University',
      'Northeastern Illinois University',
      'Roosevelt University',
      'High Point University',
      'The State University of New York at Potsdam',
      'Winthrop University',
      'Southeastern Louisiana University',
      'St. Lawrence University',
      'New York Institute of Technology',
      'Georgia College and State University',
      'The University of Oklahoma Health Sciences Center',
      'Alfred University',
      'Millersville University of Pennsylvania',
      'Augsburg University',
      'Elizabethtown College',
      'La Salle University',
      'Lake Superior State University',
      'California College of the Arts San Francisco',
      'Oral Roberts University',
      'Hunter College, CUNY',
      'SUNY Upstate Medical University',
      'Bloomsburg University of Pennsylvania',
      'University of Mississippi Medical Center',
      'University of La Verne',
      'Bluffton University',
      'Pittsburg State University',
      'Sacred Heart University',
      'Sarah Lawrence College',
      'St. Bonaventure University',
      'SUNY Downstate Medical Center',
      'University of Portland',
      'The Citadel, The Military College of South Carolina',
      'Minneapolis College of Art and Design',
      'Capital University',
      'Minnesota State University Moorhead',
      'Barry University',
      'University of South Alabama',
      'Beloit College',
      "Saint Mary's College of California",
      'Messiah University',
      'Mills College',
      'Atlantic University College',
      'Universidad del Sagrado Corazon',
      'Escuela de Artes Plásticas y Diseño de Puerto Rico',
      'Universidad Central de Bayamón',
      'Universidad Central del Caribe',
      'Interamerican University of Puerto Rico at Aguadilla',
      'Interamerican University of Puerto Rico at Arecibo',
      'Interamerican University of Puerto Rico at Barranquitas',
      'Interamerican University of Puerto Rico at Bayamon',
      'Interamerican University of Puerto Rico at Fajardo',
      'Interamerican University of Puerto Rico at Guayama',
      'Interamerican University of Puerto Rico at Ponce',
      'Interamerican University of Puerto Rico at San Germán',
      'University of Puerto Rico at Aguadilla',
      'University of Puerto Rico at Arecibo',
      'University of Puerto Rico at Bayamón',
      'University of Puerto Rico at Carolina',
      'University of Puerto Rico at Cayey',
      'University of Puerto Rico at Humacao',
      'University of Puerto Rico at Mayagüez',
      'University of Puerto Rico at Ponce',
      'University of Puerto Rico at Rio Piedras',
      'University of Puerto Rico at Utuado',
    ],
  },
  {
    groupLabel: 'Canada',
    options: [
      'Acadia University',
      'Algoma University',
      'Ambrose University',
      'Aurora College',
      "Bishop's University",
      'Brandon University',
      'British Columbia Institute of Technology',
      'Brock University',
      'Burman University',
      'Campion College at the University of Regina',
      'Canadian Mennonite University',
      'Cape Breton University',
      'Capilano University',
      'Carleton University',
      'College of the North Atlantic',
      'Collège Universitaire Dominicain',
      'Concordia University',
      'Concordia University of Edmonton',
      'Crandall University',
      'Dalhousie University',
      'École de Technologie Supérieure',
      "École Nationale d'Administration Publique",
      'École Polytechnique de Montréal',
      'Emily Carr University of Art and Design',
      'First Nations University of Canada',
      'HEC Montréal',
      'Kingswood University',
      'Kwantlen Polytechnic University',
      'Lakehead University',
      'Laurentian University',
      'Luther College at the University of Regina',
      'MacEwan University',
      'McGill University',
      'McMaster University',
      'Memorial University of Newfoundland',
      'Mount Allison University',
      'Mount Royal University',
      'Mount Saint Vincent University',
      'Nicola Valley Institute of Technology',
      'Nipissing University',
      'Northern Alberta Institute of Technology',
      'NSCAD University',
      'OCAD University',
      'Ontario Tech University',
      "Queen's University",
      'Quest University Canada',
      'Redeemer University College',
      'Royal Military College of Canada',
      'Royal Roads University',
      'Ryerson University',
      "Saint Mary's University",
      'SAIT Polytechnic',
      'Simon Fraser University',
      "St Mary's University",
      'St. Francis Xavier University',
      "St. Stephen's University",
      'St. Thomas More College',
      'St. Thomas University',
      "The King's University",
      'The University of British Columbia',
      'The University of Winnipeg',
      'Thompson Rivers University',
      'Trent University',
      'Trinity Western University',
      'Université de Hearst',
      'Université de Moncton',
      'Université de Montréal',
      'Université de Saint-Boniface',
      'Université de Sherbrooke',
      'Université du Québec',
      'Université du Québec à Chicoutimi',
      'Université du Québec à Montréal',
      'Université du Québec à Rimouski',
      'Université du Québec à Trois-Rivières',
      'Université du Québec en Abitibi-Témiscamingue',
      'Université du Québec en Outaouais',
      'Université Laval',
      'Université Sainte-Anne',
      'University Canada West',
      'University of Alberta',
      'University of Calgary',
      'University of Guelph',
      "University of King's College",
      'University of Lethbridge',
      'University of Manitoba',
      'University of New Brunswick',
      'University of Northern British Columbia',
      'University of Ottawa',
      'University of Prince Edward Island',
      'University of Regina',
      'University of Saskatchewan',
      'University of the Fraser Valley',
      'University of Toronto',
      'University of Victoria',
      'University of Waterloo',
      'University of Windsor',
      'Vancouver Island University',
      'Western University',
      'Wilfrid Laurier University',
      'York University',
      'Yorkville University',
    ],
  },
  {
    groupLabel: 'Mexico',
    options: [
      'Universidad Nacional Autónoma de México',
      'Tecnológico de Monterrey',
      'Universidad de Guadalajara',
      'Instituto Politécnico Nacional',
      'Universidad Autónoma de Nuevo León',
      'Universidad Veracruzana',
      'Universidad Autónoma del Estado de Hidalgo',
      'Benemerita Universidad Autonoma de Puebla',
      'Centro de Estudios Superiores de Diseño de Monterrey S.C.',
      'Centro de Estudios Superiores de San Ángel S.C.',
      'Centro de Estudios Universitarios Xochicalco',
      'CETYS Universidad',
      'El Colegio de México',
      'El Colegio de Sonora',
      'El Colegio Mexiquense A.C.',
      'Escuela Bancaria y Comercial',
      'Instituto de Estudios Universitarios A.C.',
      'Instituto Tecnológico Autonómo de México',
      'Instituto Tecnológico de Aguascalientes',
      'Instituto Tecnológico de Celaya',
      'Instituto Tecnológico de Chihuahua',
      'Instituto Tecnológico de Hermosillo',
      'Instituto Tecnológico de La Laguna',
      'Instituto Tecnológico de La Paz',
      'Instituto Tecnológico de Mérida',
      'Instituto Tecnológico de Querétaro',
      'Instituto Tecnológico de Sonora',
      'Instituto Tecnológico y de Estudios Superiores de Occidente A.C.',
      'Universidad Anáhuac Mayab',
      'Universidad Anáhuac México Norte',
      'Universidad Autónoma Agraria Antonio Narro',
      'Universidad Autónoma Benito Juárez de Oaxaca',
      'Universidad Autónoma de Aguascalientes',
      'Universidad Autónoma de Baja California',
      'Universidad Autónoma de Baja California Sur',
      'Universidad Autónoma de Campeche',
      'Universidad Autónoma de Chapingo',
      'Universidad Autónoma de Chiapas',
      'Universidad Autónoma de Chihuahua',
      'Universidad Autónoma de Ciudad Juárez',
      'Universidad Autónoma de Coahuila',
      'Universidad Autónoma de Durango',
      'Universidad Autónoma de Guadalajara',
      'Universidad Autónoma de Guerrero',
      'Universidad Autónoma de la Ciudad de México',
      'Universidad Autónoma de la Laguna',
      'Universidad Autónoma de Nayarit',
      'Universidad Autónoma de Occidente',
      'Universidad Autónoma de Querétaro',
      'Universidad Autónoma de San Luis Potosí',
      'Universidad Autónoma de Sinaloa',
      'Universidad Autónoma de Tamaulipas',
      'Universidad Autónoma de Tlaxcala',
      'Universidad Autónoma de Yucatán',
      'Universidad Autónoma de Zacatecas',
      'Universidad Autónoma del Carmen',
      'Universidad Autónoma del Estado de México',
      'Universidad Autónoma del Estado de Morelos',
      'Universidad Autónoma del Noreste',
      'Universidad Autónoma Intercultural de Sinaloa',
      'Universidad Autónoma Metropolitana',
      'Universidad Cristóbal Colón',
      'Universidad de Ciencias y Artes de Chiapas',
      'Universidad de Colima',
      'Universidad de Guanajuato',
      'Universidad de la Salle Bajío A.C.',
      'Universidad de la Sierra Sur',
      'Universidad de las Américas',
      'Universidad de Londres A.C.',
      'Universidad de Montemorelos A.C.',
      'Universidad de Monterrey',
      'Universidad de Quintana Roo',
      'Universidad de Sonora',
      'Universidad de Xalapa A.C.',
      'Universidad del Caribe, Mexico',
      'Universidad del Claustro de Sor Juana',
      'Universidad del Mar',
      'Universidad del Papaloapan',
      'Universidad del Valle de Atemajac A.C.',
      'Universidad del Valle de México A.C.',
      'Universidad del Valle de Puebla S.C.',
      'Universidad Iberoamericana A.C.',
      'Universidad Interamericana para el Desarrollo',
      'Universidad Intercontinental',
      'Universidad Internacional',
      'Universidad Juárez Autónoma de Tabasco',
      'Universidad Juárez del Estado de Durango',
      'Universidad La Salle',
      'Universidad La Salle Cancún A.C.',
      'Universidad Latina de America',
      'Universidad Latinoamericana S.C.',
      'Universidad Madero',
      'Universidad Mexicana S.C.',
      'Universidad Michoacana de San Nicolás de Hidalgo',
      'Universidad Panamericana',
      'Universidad Pedagógica Nacional, Mexico',
      'Universidad Politécnica de Sinaloa',
      'Universidad Popular Autonóma del Estado de Puebla',
      'Universidad Regiomontana',
      'Universidad Regional del Sureste A.C.',
      'Universidad TecMilenio',
      'Universidad Tecnológica de Hermosillo, Sonora',
      'Universidad Tecnológica de la Costa Santiago',
      'Universidad Tecnológica de la Mixteca',
      'Universidad Tecnológica de León',
      'Universidad Tecnológica de México',
      'Universidad Tecnológica de Querétaro',
      'Universidad Tecnológica de Tula-Tepeji',
      'Universidad Tecnológica Metropolitana, Mexico',
      'Universidad Vasco de Quiroga A.C.',
    ],
  },
  {
    groupLabel: 'Panama',
    options: [
      'Columbus University',
      'Florida State University Panama City',
      'ISAE Universidad',
      'Quality Leadership University',
      'Universidad Americana, Panama',
      'Universidad Autonoma de Chiriqui',
      'Universidad Católica Santa María La Antigua',
      'Universidad Cristiana de Panamá',
      'Universidad de Panamá',
      'Universidad del Arte Ganexa',
      'Universidad del Istmo',
      'Universidad Especializada de Las Americas',
      'Universidad Especializada del Contador Público de Panamá',
      'Universidad Interamericana de Panamá',
      'Universidad Latina de Panamá',
      'Universidad Latinamericana de Comercio Exterior',
      'Universidad Latinoamericana de Ciencia y Tecnologia',
      'Universidad Marítima Internacional de Panamá',
      'Universidad Metropolitana de Educacion, Ciencia y Tecnologia',
      'Universidad Santander',
      'Universidad Tecnológica de Panamá',
      'Universidad Tecnológica Oteima',
    ],
  },
  {
    groupLabel: 'Ecuador',
    options: [
      'Universidad de Guayaquil',
      'Escuela Superior Politécnica del Litoral',
      'Universidad Politécnica Salesiana',
      'Universidad Técnica Particular de Loja',
      'Universidad Estatal de Milagro',
      'Escuela Politécnica Nacional',
      'Universidad Central del Ecuador',
      'Universidad San Francisco de Quito',
      'Escuela Superior Politecnica de Chimborazo',
      'Universidad Laica Eloy Alfaro de Manabi',
      'Universidad de Cuenca',
      'Universidad Técnica de Ambato',
      'Universidad Nacional de Loja',
      'Universidad Andina Simón Bolívar, Ecuador',
      'Universidad Técnica de Manabí',
      'Pontificia Universidad Católica del Ecuador',
      'Universidad Católica de Santiago de Guayaquil',
      'Universidad de Las Américas',
      'Universidad Nacional de Chimborazo',
      'Universidad del Azuay',
    ],
  },
  {
    groupLabel: 'Venezuela',
    options: [
      'Universidad de Los Andes',
      'Universidad Central de Venezuela',
      'Universidad Católica Andres Bello',
      'Universidad Simón Bolívar',
      'Universidad de Carabobo',
      'Universidad Dr. Rafael Belloso Chacín',
      'Universidad Metropolitana',
      'Universidad Pedagógica Experimental Libertador',
      'Universidad Centro Occidental Lisandro Alvarado',
      'Universidad Nacional Experimental del Táchira',
      'Universidad del Zulia',
      'Universidad Nacional Experimental Politécnica Antonio José de Sucre',
      'Universidad Nacional Experimental de los Llanos Occidentales',
      'Universidad Nueva Esparta',
      'Universidad Yacambú',
      'Universidad de Oriente, Venezuela',
      'Universidad Nacional Experimental Simón Rodriguez',
      'Universidad Católica del Táchira',
      'Universidad Nacional Experimental de Guayana',
      'Universidad Católica Cecilio Acosta',
    ],
  },
  {
    groupLabel: 'Colombia',
    options: [
      'Universidad Nacional de Colombia',
      'Colegio de Estudios Superiores de Administración',
      'Corporación Universitaria Adventista',
      'Corporación Universitaria de la Costa',
      'Corporacion Universitaria del Caribe',
      'Corporacion Universitaria del Meta',
      'Corporacion Universitaria Iberoamericana',
      'Corporación Universitaria Minuto de Dios',
      'Corporacion Universitaria Rafael Nuñez',
      'Corporacion Universitaria Remington',
      'Corporación Universitaria Unitec',
      'Escuela Colombiana de Ingenieria Julio Garavito',
      'Escuela de Ingeniería de Antioquia',
      'Escuela Superior de Administración Pública',
      'Fundacion Escuela Colombiana de Rehabilitacion',
      'Fundacion Universitaria Catolica del Norte',
      'Fundacion Universitaria Catolica Lumen Gentium',
      'Fundacion Universitaria Ceipa',
      'Fundación Universitaria Compensar',
      'Fundacion Universitaria de Ciencias de La Salud',
      'Fundacion Universitaria de Popayan',
      'Fundación Universitaria del Area Andina',
      'Fundacion Universitaria Konrad Lorenz',
      'Fundacion Universitaria Maria Cano',
      'Fundacion Universitaria Para el Desarrollo Humano',
      'Institución Universitaria de Envigado',
      'Institución Universitaria Esumer',
      'Instituto Caro y Cuervo',
      'Instituto Tecnológico Metropolitano',
      'Instituto Tecnológico Pascual Bravo',
      'Politécnico Colombiano Jaime Isaza Cadavid',
      'Politécnico Grancolombiano',
      'Pontificia Universidad Javeriana',
      'Tecnológico de Antioquia',
      'Unidad Central del Valle del Cauca',
      'Unilasallista Corporación Universitaria',
      'Universidad Antonio Nariño',
      'Universidad Autónoma de Bucaramanga',
      'Universidad Autónoma de Colombia',
      'Universidad Autónoma de Manizales',
      'Universidad Autónoma de Occidente',
      'Universidad Autónoma del Caribe',
      'Universidad Autónoma Latinoamericana',
      'Universidad Católica de Colombia',
      'Universidad Católica de Manizales',
      'Universidad Católica de Oriente',
      'Universidad Católica de Pereira',
      'Universidad Católica Luis Amigó',
      'Universidad Central',
      'Universidad CES',
      'Universidad Colegio Mayor de Cundinamarca',
      'Universidad Cooperativa de Colombia',
      'Universidad de América',
      'Universidad de Antioquía',
      'Universidad de Bogotá Jorge Tadeo Lozano',
      'Universidad de Boyacá',
      'Universidad de Caldas',
      'Universidad de Cartagena',
      'Universidad de Ciencias Aplicadas y Ambientales',
      'Universidad de Córdoba, Colombia',
      'Universidad de Cundinamarca',
      'Universidad de Ibagué',
      'Universidad de la Amazonia',
      'Universidad de La Guajira',
      'Universidad de La Sabana',
      'Universidad de La Salle',
      'Universidad de los Andes, Colombia',
      'Universidad de los Llanos',
      'Universidad de Manizales',
      'Universidad de Medellín',
      'Universidad de Nariño',
      'Universidad de Pamplona',
      'Universidad de San Buenaventura',
      'Universidad de Santander',
      'Universidad del Atlántico',
      'Universidad del Cauca',
      'Universidad del Magdalena',
      'Universidad del Norte',
      'Universidad del Quindío',
      'Universidad del Rosario',
      'Universidad del Tolima',
      'Universidad del Valle',
      'Universidad Distrital Francisco José de Caldas',
      'Universidad EAFIT',
      'Universidad EAN',
      'Universidad ECCI',
      'Universidad El Bosque',
      'Universidad Externado de Colombia',
      'Universidad Francisco de Paula Santander',
      'Universidad ICESI',
      'Universidad Industrial de Santander',
      'Universidad La Gran Colombia',
      'Universidad Libre',
      'Universidad Manuela Beltrán',
      'Universidad Mariana',
      'Universidad Militar Nueva Granada',
      'Universidad Pedagógica Nacional',
      'Universidad Pedagógica y Tecnológica de Colombia',
      'Universidad Piloto de Colombia',
      'Universidad Pontificia Bolivariana',
      'Universidad Popular del Cesar',
      'Universidad Santiago de Cali',
      'Universidad Santo Tomás',
      'Universidad Sergio Arboleda',
      'Universidad Simón Bolívar, Colombia',
      'Universidad Surcolombiana',
      'Universidad Tecnológica de Bolívar',
      'Universidad Tecnológica de Pereira',
      'Universidad Tecnológica del Choco',
      'Universitaria Agustiniana',
    ],
  },
  {
    groupLabel: 'Peru',
    options: [
      'Pontificia Universidad Católica del Perú',
      'Universidad Alas Peruanas',
      'Universidad Andina del Cusco',
      'Universidad Andina Néstor Cáceres Velásquez',
      'Universidad Antonio Ruiz de Montoya',
      'Universidad Arzobispo Loayza',
      'Universidad Autónoma de Ica',
      'Universidad Autónoma del Perú',
      'Universidad Católica de Santa María',
      'Universidad Católica de Trujillo Benedicto XVI',
      'Universidad Católica Los Ángeles de Chimbote',
      'Universidad Católica San Pablo',
      'Universidad Católica Santo Toribio de Mogrovejo',
      'Universidad Católica Sedes Sapientiae',
      'Universidad César Vallejo',
      'Universidad Científica del Perú',
      'Universidad Cientifica del Sur',
      'Universidad Continental',
      'Universidad de Ciencias y Artes de América Latina',
      'Universidad de Ciencias y Humanidades',
      'Universidad de Huánuco',
      'Universidad de Ingeniería y Tecnología',
      'Universidad de Lima',
      'Universidad de Piura',
      'Universidad de San Martín de Porres',
      'Universidad del Pacifico',
      'Universidad ESAN',
      'Universidad Femenina del Sagrado Corazón',
      'Universidad Inca Garcilaso de la Vega',
      'Universidad Jaime Bausate y Meza',
      'Universidad José Carlos Mariátegui',
      'Universidad La Salle, Peru',
      'Universidad Latinoamericana CIMA',
      'Universidad Le Cordon Bleu',
      'Universidad Marcelino Champagnat',
      'Universidad María Auxiliadora',
      'Universidad Nacional Agraria de la Selva',
      'Universidad Nacional Agraria La Molina',
      'Universidad Nacional Amazónica de Madre de Dios',
      'Universidad Nacional Autónoma Altoandina de Tarma',
      'Universidad Nacional Autónoma de Chota',
      'Universidad Nacional Autónoma de Huanta',
      'Universidad Nacional Daniel Alcides Carrion',
      'Universidad Nacional de Barranca',
      'Universidad Nacional de Cajamarca',
      'Universidad Nacional de Cañete',
      'Universidad Nacional de Educación Enrique Guzmán y Valle',
      'Universidad Nacional de Frontera',
      'Universidad Nacional de Huancavelica',
      'Universidad Nacional de Ingeniería',
      'Universidad Nacional de Jaén',
      'Universidad Nacional de Juliaca',
      'Universidad Nacional de la Amazonía Peruana',
      'Universidad Nacional de Moquegua',
      'Universidad Nacional de Piura',
      'Universidad Nacional de San Agustín de Arequipa',
      'Universidad Nacional de San Antonio Abad del Cusco',
      'Universidad Nacional de San Cristóbal de Huamanga',
      'Universidad Nacional de San Martín',
      'Universidad Nacional de Trujillo',
      'Universidad Nacional de Tumbes',
      'Universidad Nacional de Ucayali',
      'Universidad Nacional del Altiplano',
      'Universidad Nacional del Callao',
      'Universidad Nacional del Centro del Perú',
      'Universidad Nacional del Santa',
      'Universidad Nacional Federico Villarreal',
      'Universidad Nacional Hermilio Valdizan',
      'Universidad Nacional Intercultural de la Amazonía',
      'Universidad Nacional Jorge Basadre Grohmann',
      'Universidad Nacional José Faustino Sánchez Carrión',
      'Universidad Nacional José María Arguedas',
      'Universidad Nacional Mayor de San Marcos',
      'Universidad Nacional Micaela Bastidas de Apurímac',
      'Universidad Nacional Pedro Ruíz Gallo',
      'Universidad Nacional San Luis Gonzaga',
      'Universidad Nacional Santiago Antúnez de Mayolo',
      'Universidad Nacional Tecnológica de Lima Sur',
      'Universidad Nacional Toribio Rodríguez de Mendoza',
      'Universidad Particular de Chiclayo',
      'Universidad Peruana Cayetano Heredia',
      'Universidad Peruana de Ciencias Aplicadas',
      'Universidad Peruana de Ciencias e Informatica',
      'Universidad Peruana de las Américas',
      'Universidad Peruana del Centro',
      'Universidad Peruana Los Andes',
      'Universidad Peruana Simón Bolivar',
      'Universidad Peruana Unión',
      'Universidad Privada Antenor Orrego',
      'Universidad Privada Antonio Guillermo Urrelo',
      'Universidad Privada de Huancayo Franklin Roosevelt',
      'Universidad Privada de Pucallpa',
      'Universidad Privada de Tacna',
      'Universidad Privada del Norte',
      'Universidad Privada Norbert Wiener',
      'Universidad Privada Peruano Alemana',
      'Universidad Privada San Juan Bautista',
      'Universidad Privada TELESUP',
      'Universidad Ricardo Palma',
      'Universidad San Ignacio de Loyola',
      'Universidad San Pedro',
      'Universidad Señor de Sipán',
      'Universidad Sergio Bernales',
      'Universidad Tecnológica del Peru',
    ],
  },
  {
    groupLabel: 'Brazil',
    options: [
      'Universidade de São Paulo',
      'Pontifícia Universidade Católica de Campinas',
      'Pontifícia Universidade Católica de Goiás',
      'Pontifícia Universidade Católica de Minas Gerais',
      'Pontifícia Universidade Católica de São Paulo',
      'Pontifícia Universidade Católica do Paraná',
      'Pontifícia Universidade Católica do Rio de Janeiro',
      'Pontifícia Universidade Católica do Rio Grande do Sul',
      'Universidade Anhanguera de São Paulo',
      'Universidade Anhembi Morumbi',
      'Universidade Católica de Brasília',
      'Universidade Católica de Pelotas',
      'Universidade Católica de Pernambuco',
      'Universidade Cruzeiro do Sul',
      'Universidade da Amazônia',
      'Universidade de Brasília',
      'Universidade de Caxias do Sul',
      'Universidade de Fortaleza',
      'Universidade de Passo Fundo',
      'Universidade de Santa Cruz do Sul',
      'Universidade de Uberaba',
      'Universidade do Estado da Bahia',
      'Universidade do Estado de Mato Grosso',
      'Universidade do Estado de Minas Gerais',
      'Universidade do Estado de Santa Catarina',
      'Universidade do Estado do Amazonas',
      'Universidade do Estado do Pará',
      'Universidade do Estado do Rio de Janeiro',
      'Universidade do Estado do Rio Grande do Norte',
      'Universidade do Extremo Sul Catarinense',
      'Universidade do Sul de Santa Catarina',
      'Universidade do Vale do Itajaí',
      'Universidade do Vale do Paraíba',
      'Universidade do Vale do Rio dos Sinos',
      'Universidade Estácio de Sá',
      'Universidade Estadual da Paraíba',
      'Universidade Estadual de Campinas',
      'Universidade Estadual de Feira de Santana',
      'Universidade Estadual de Goiás',
      'Universidade Estadual de Londrina',
      'Universidade Estadual de Maringá',
      'Universidade Estadual de Mato Grosso do Sul',
      'Universidade Estadual de Montes Claros',
      'Universidade Estadual de Ponta Grossa',
      'Universidade Estadual de Santa Cruz',
      'Universidade Estadual do Ceará',
      'Universidade Estadual do Centro-Oeste',
      'Universidade Estadual do Maranhão São Luís',
      'Universidade Estadual do Oeste do Paraná',
      'Universidade Estadual do Piauí',
      'Universidade Estadual do Sudoeste da Bahia',
      'Universidade Estadual Paulista',
      'Universidade Federal da Bahia',
      'Universidade Federal da Grande Dourados',
      'Universidade Federal da Paraíba',
      'Universidade Federal de Alagoas',
      'Universidade Federal de Alfenas',
      'Universidade Federal de Campina Grande',
      'Universidade Federal de Goiás',
      'Universidade Federal de Juiz de Fora',
      'Universidade Federal de Lavras',
      'Universidade Federal de Mato Grosso',
      'Universidade Federal de Mato Grosso do Sul',
      'Universidade Federal de Minas Gerais',
      'Universidade Federal de Ouro Preto',
      'Universidade Federal de Pelotas',
      'Universidade Federal de Pernambuco',
      'Universidade Federal de Santa Catarina',
      'Universidade Federal de Santa Maria',
      'Universidade Federal de São Carlos',
      'Universidade Federal de São João Del Rei',
      'Universidade Federal de São Paulo',
      'Universidade Federal de Sergipe',
      'Universidade Federal de Uberlândia',
      'Universidade Federal de Viçosa',
      'Universidade Federal do ABC',
      'Universidade Federal do Acre',
      'Universidade Federal do Amapá',
      'Universidade Federal do Amazonas',
      'Universidade Federal do Cariri',
      'Universidade Federal do Ceará',
      'Universidade Federal do Espírito Santo',
      'Universidade Federal do Estado do Rio de Janeiro',
      'Universidade Federal do Maranhão',
      'Universidade Federal do Pampa',
      'Universidade Federal do Pará',
      'Universidade Federal do Paraná',
      'Universidade Federal do Piauí',
      'Universidade Federal do Recôncavo da Bahia',
      'Universidade Federal do Rio de Janeiro',
      'Universidade Federal do Rio Grande',
      'Universidade Federal do Rio Grande do Norte',
      'Universidade Federal do Rio Grande do Sul',
      'Universidade Federal dos Vales do Jequitinhonha e Mucuri',
      'Universidade Federal Fluminense',
      'Universidade Federal Rural de Pernambuco',
      'Universidade Federal Rural do Rio de Janeiro',
      'Universidade Luterana do Brasil',
      'Universidade Metodista de São Paulo',
      'Universidade Norte do Paraná',
      'Universidade Nove de Julho',
      'Universidade Paranaense',
      'Universidade Paulista',
      'Universidade Presbiteriana Mackenzie',
      'Universidade Regional de Blumenau',
      'Universidade Regional do Noroeste do Estado do Rio Grande do Sul',
      'Universidade Salgado de Oliveira',
      'Universidade Tecnológica Federal do Paraná',
      'Universidade Tuiuti do Paraná',
      'Universidade Veiga de Almeida',
    ],
  },
  {
    groupLabel: 'Chile',
    options: [
      'Pontificia Universidad Católica de Chile',
      'Pontificia Universidad Católica de Valparaíso',
      'Universidad Academia de Humanismo Cristiano',
      'Universidad Adolfo Ibañez',
      'Universidad Adventista de Chile',
      'Universidad Alberto Hurtado',
      'Universidad Andrés Bello',
      'Universidad Arturo Prat',
      'Universidad Austral de Chile',
      'Universidad Autónoma de Chile',
      "Universidad Bernardo O'Higgins",
      'Universidad Bolivariana',
      'Universidad Católica de la Santísima Concepción',
      'Universidad Católica de Temuco',
      'Universidad Católica del Maule',
      'Universidad Católica del Norte',
      'Universidad Católica Silva Henriquez',
      'Universidad Central de Chile',
      'Universidad de Aconcagua',
      'Universidad de Antofagasta',
      'Universidad de Artes, Ciencias y Comunicación',
      'Universidad de Atacama',
      'Universidad de Aysén',
      'Universidad de Chile',
      'Universidad de Concepción',
      'Universidad de La Frontera',
      'Universidad de La Serena',
      'Universidad de Las Américas',
      'Universidad de los Andes, Chile',
      'Universidad de los Lagos',
      'Universidad de Magallanes',
      "Universidad de O'Higgins",
      'Universidad de Playa Ancha de Ciencias de la Educacion',
      'Universidad de Santiago de Chile',
      'Universidad de Talca',
      'Universidad de Tarapacá',
      'Universidad de Valparaíso',
      'Universidad de Viña del Mar',
      'Universidad del Alba',
      'Universidad del Bío-Bío',
      'Universidad del Desarrollo',
      'Universidad Diego Portales',
      'Universidad Finis Terrae',
      'Universidad Gabriela Mistral',
      'Universidad Iberoamericana de Ciencias y Tecnología',
      'Universidad La República',
      'Universidad Los Leones',
      'Universidad Mayor',
      'Universidad Metropolitana de Ciencias de la Educación',
      'Universidad Miguel de Cervantes',
      'Universidad San Sebastián',
      'Universidad Santo Tomás, Chile',
      'Universidad SEK',
      'Universidad Técnica Federico Santa María',
      'Universidad Tecnológica de Chile INACAP',
      'Universidad Tecnológica Metropolitana',
    ],
  },
  {
    groupLabel: 'Argentina',
    options: [
      'Universidad de Buenos Aires',
      'Universidad Nacional de La Plata',
      'Universidad Nacional de Córdoba',
      'Universidad Tecnológica Nacional',
      'Universidad Nacional de Rosario',
      'Universidad Nacional de Cuyo',
      'Universidad de Palermo',
      'Universidad Nacional del Nordeste',
      'Universidad Nacional de Mar del Plata',
      'Universidad Nacional del Litoral',
      'Pontificia Universidad Católica Argentina',
      'Universidad Argentina de la Empresa',
      'Universidad Nacional del Centro de la Provincia de Buenos Aires',
      'Universidad Nacional de San Martín, Argentina',
      'Universidad Nacional del Comahue',
      'Universidad de San Andrés',
      'Universidad Nacional de Tucumán',
      'Universidad de Ciencias Empresariales y Sociales',
      'Universidad Nacional de Quilmes',
      'Universidad Austral',
      'Universidad Nacional del Sur',
      'Universidad del Salvador',
      'Universidad Torcuato di Tella',
      'Universidad Empresarial Siglo 21',
      'Universidad Nacional de Salta',
      'Universidad Nacional de General Sarmiento',
      'Universidad de Belgrano',
      'Universidad Nacional de Lanús',
      'Universidad Nacional de Entre Ríos',
      'Facultad Latinoamericana de Ciencias Sociales',
      'Universidad Nacional de San Luis',
      'Universidad Nacional de La Matanza',
      'Universidad Nacional de Misiones',
      'Universidad Nacional de San Juan',
      'Universidad Nacional de la Patagonia San Juan Bosco',
      'Universidad Nacional de Río Cuarto',
      'Universidad Abierta Interamericana',
      'Universidad Nacional de La Pampa',
      'Universidad Nacional de Tres de Febrero',
      'Universidad del CEMA',
      'Universidad Católica de Córdoba',
      'Universidad de Mendoza',
      'Universidad Nacional Arturo Jauretche',
      'Universidad Nacional de Jujuy',
      'Universidad FASTA',
      'Instituto Tecnológico de Buenos Aires',
      'Universidad Nacional de Santiago del Estero',
      'Universidad Blas Pascal',
      'Universidad Champagnat',
      'Universidad Nacional de Luján',
      'Universidad Nacional de las Artes',
      'Universidad de Flores',
      'Universidad Nacional de Río Negro',
      'Universidad Nacional Guillermo Brown',
      'Universidad Católica de Salta',
      'Universidad Juan Agustín Maza',
      'Universidad Nacional del Noroeste de la Provincia de Buenos Aires',
      'Universidad Nacional de Catamarca',
      'Universidad Argentina John F. Kennedy',
      'Instituto Universitario de Ciencias de la Salud',
      'Universidad Nacional del Chaco Austral',
      'Universidad CAECE',
      'Universidad de Morón',
      'Instituto Universitario ESEADE',
      'Universidad Nacional de La Rioja',
      'Universidad Maimónides',
      'Universidad Católica de La Plata',
      'Universidad Favaloro',
      'Universidad Nacional de la Patagonia Austral',
      'Universidad del Norte Santo Tomás de Aquino',
      'Universidad Nacional de Lomas de Zamora',
      'Universidad Nacional de Hurlingham',
      'Universidad Nacional de Avellaneda',
      'Universidad Autónoma de Entre Ríos',
      'Instituto Universitario CEMIC',
      'Universidad del Museo Social Argentino',
      'Universidad Pedagógica Nacional',
      'Universidad del Centro Educativo Latinoamericano',
      'Universidad Católica de Santa Fe',
      'Universidad del Aconcagua',
      'Universidad del Cine',
      'Universidad Adventista del Plata',
      'Universidad Nacional de Villa María',
      'Universidad de la Cuenca del Plata',
      'Instituto para el Desarrollo Empresarial de la Argentina',
      'Universidad Nacional de Tierra del Fuego, Antártida e Islas del Atlántico Sur',
      'Universidad Católica de Santiago del Estero',
      'Universidad de la Marina Mercante',
      'Universidad de Congreso',
      'Universidad Católica de Cuyo',
      'Universidad ISALUD',
      'Universidad Nacional de los Comechingones',
      'Universidad Nacional de José C. Paz',
      'Instituto de Enseñanza Superior del Ejército',
      'Universidad Metropolitana para la Educación y el Trabajo',
      'Universidad de Concepción del Uruguay',
      'Instituto Universitario Aeronáutico',
      'Universidad Nacional de Moreno',
      'Universidad Nacional de Chilecito',
      'Instituto Universitario Escuela Argentina de Negocios',
      'Universidad Nacional de Formosa',
      'Instituto Universitario del Hospital Italiano',
      'Universidad Provincial de Córdoba',
      'Universidad Atlántida Argentina',
      'Universidad del Gran Rosario',
      'Instituto Universitario Italiano de Rosario',
      'Universidad Provincial del Sudoeste Pigüé',
      'Universidad de San Pablo-T',
      'Universidad Nacional de Rafaela',
      'Instituto Universitario Nacional de Derechos Humanos Madres de Plaza de Mayo',
      'Universidad Gastón Dachary Posadas',
      'Universidad Provincial de Ezeiza',
      'Instituto Universitario Patagónico de las Artes',
      'Instituto Universitario de Salud Mental',
      'Universidad de San Isidro',
      'Universidad Nacional de San Antonio de Areco',
      'Universidad Nacional de Villa Mercedes',
      'Universidad Nacional del Oeste',
      'Universidad Notarial Argentina',
      'Universidad Católica de las Misiones',
      'Universidad Salesiana',
      'Universidad del Este',
      'Universidad Nacional del Alto Uruguay',
      'Universidad Nacional Raúl Scalabrini Ortiz',
      'Instituto Universitario River Plate',
      'Instituto Universitario de Ciencias Biomédicas de Córdoba',
    ],
  },
  {
    groupLabel: 'Finland',
    options: [
      'Aalto University',
      'Abo Akademi University',
      'Arcada University of Applied Sciences',
      'Centria University of Applied Sciences',
      'Diaconia University of Applied Sciences',
      'Haaga-helia University of Applied Sciences',
      'Hanken School of Economics',
      'Humak University of Applied Sciences',
      'Hame University of Applied Sciences',
      'Jamk University of Applied Sciences',
      'Kajaani University of Applied Sciences',
      'Karelia University of Applied Sciences',
      'Lab University of Applied Sciences',
      'Lut University',
      'Lapland University of Applied Sciences',
      'Laurea University of Applied Sciences',
      'Metropolia University of Applied Sciences',
      'Novia University of Applied Sciences',
      'Oulu University of Applied Sciences',
      'Satakunta University of Applied Sciences',
      'Savonia University of Applied Sciences',
      'Seinajoki University of Applied Sciences',
      'South-Eastern Finland University of Applied Sciences',
      'TAMK University of Applied Sciences',
      'Tampere University',
      'Turku University of Applied Sciences',
      'University of Eastern Finland',
      'University of Helsinki',
      'University of Jyvaskyla',
      'University of Lapland',
      'University of Oulu',
      'University of Turku',
      'University of Vaasa',
      'University of The Arts Helsinki',
      'Vaasa University of Applied Sciences',
    ],
  },
  {
    groupLabel: 'Sweden',
    options: [
      'Blekinge Institute of Technology',
      'Chalmers University of Technology',
      'Dalarna University',
      'Ersta Sköndal University College',
      'GIH – the Swedish School of Sport and Health Sciences',
      'Halmstad University',
      'Jönköping University',
      'KMH – Royal College of Music in Stockholm',
      'KTH Royal Institute of Technology',
      'Karlstad University',
      'Karolinska Institutet',
      'Konstfack',
      'Kristianstad University',
      'Linköping University',
      'Linnaeus University',
      'Luleå University of Technology',
      'Lund University',
      'Malmö University',
      'Mid Sweden University',
      'Mälardalen University',
      'Royal Institute of Art',
      'SLU – Swedish University of Agricultural Sciences',
      'SMI – University College of Music Education in Stockholm',
      'Sophiahemmet University College',
      'Stockholm School of Economics',
      'Stockholm School of Theology',
      'Stockholm University',
      'Stockholm University of the Arts',
      'Södertörn University',
      'Umeå University',
      'University West',
      'University of Borås',
      'University of Gothenburg',
      'University of Gävle',
      'University of Skövde',
      'Uppsala University',
      'Örebro University',
    ],
  },
  {
    groupLabel: 'Norway',
    options: [
      'Aalesund University College',
      'Akershus University College, Lillestrøm',
      'Bergen University College, Bergen',
      'Bodø University College, Bodø',
      'Buskerud University College, Kongsberg',
      'Diakonhjemmet University College, Oslo',
      'Finnmark University College, Alta',
      'Gjøvik University College, Gjøvik',
      'Harstad University College, Harstad',
      'Hedmark University College, Elverum',
      'Lillehammer University College, Lillehammer',
      'Molde University College, Molde',
      'Narvik University College, Narvik',
      'Nord University, Nesna',
      'Norwegian University of Life Sciences, Aas',
      'Norwegian University of Science and Technology, Trondheim',
      'Oslo University College, Oslo',
      'Ostfold University College, Halden',
      'Sami University College, Guovdageaidnu',
      'Sogn og Fjordane University College, Sogndal',
      'Stord/Haugesund University College, Stord',
      'Telemark University College, Porsgrunn',
      'The University Centre in Svalbard, Longyearbyen',
      'University Graduate Center, Kjeller',
      'University of Agder, Kristiansand',
      'University of Bergen, Bergen',
      'University of Oslo, Oslo',
      'University of Stavanger, Stavanger',
      'University of Tromsø, Tromsø',
      'Vestfold University College, Horten',
      'Volda University College',
    ],
  },
  {
    groupLabel: 'Ireland',
    options: [
      'Athlone Institute of Technology',
      'Carlow College',
      'Cork Institute of Technology',
      'Dublin City University',
      'Dún Laoghaire Institute of Art, Design and Technology',
      'Dundalk Institute of Technology',
      'Galway-Mayo Institute of Technology',
      'Institute of Public Administration',
      'Institute of Technology Carlow',
      'Institute of Technology Sligo',
      'Institute of Technology, Tralee',
      'Letterkenny Institute of Technology',
      'Limerick Institute of Technology',
      'Marino Institute of Education',
      'Maynooth University',
      'National College of Art and Design',
      'National College of Ireland',
      'National University of Ireland, Galway',
      'National University of Ireland, System',
      'Royal College of Surgeons in Ireland',
      'Royal Irish Academy of Music',
      "Saint Patrick's College, Maynooth",
      'Technological University Dublin',
      'Trinity College Dublin, University of Dublin',
      'University College Cork',
      'University College Dublin',
      'University of Limerick',
      'Waterford Institute of Technology',
    ],
  },
  {
    groupLabel: 'United Kingdom',
    options: [
      'University of Oxford',
      'University of Cambridge',
      'University College London',
      'The University of Edinburgh',
      'University of Glasgow',
      'The University of Manchester',
      'The London School of Economics and Political Science',
      'Imperial College London',
      'The University of Warwick',
      'University of Leeds',
      'University of Southampton',
      'University of St Andrews',
      'University of Birmingham',
      "King's College London",
      'Newcastle University',
      'University of Liverpool',
      'The University of York',
      'The University of Nottingham',
      'University of Exeter',
      'University of Bristol',
      'The University of Sheffield',
      'Lancaster University',
      'Queen Mary University of London',
      'Durham University',
      'Cardiff University',
      'University of Bath',
      'University of East Anglia',
      'University of Reading',
      'University of Sussex',
      'University of Aberdeen',
      'Heriot-Watt University',
      "Queen's University Belfast",
      'University of Leicester',
      'University of Surrey',
      'University of Strathclyde',
      'University of Dundee',
      'University of Essex',
      'Loughborough University',
      'City, University of London',
      'University of Kent',
      'Swansea University',
      'De Montfort University',
      'University of the Arts London',
      'Aberystwyth University',
      'University of the West of England',
      'University of Portsmouth',
      'Middlesex University',
      'Bangor University',
      'Royal Holloway, University of London',
      'London School of Hygiene and Tropical Medicine, University of London',
      'Birmingham City University',
      'London Business School',
      'Sheffield Hallam University',
      'Brunel University London',
      'Birkbeck, University of London',
      'University of Hertfordshire',
      'Nottingham Trent University',
      'Coventry University',
      'University of Plymouth',
      'Ulster University',
      'Goldsmiths, University of London',
      'Leeds Beckett University',
      'University of Stirling',
      'SOAS, University of London',
      'Oxford Brookes University',
      'Edinburgh Napier University',
      'University of Salford',
      'Liverpool John Moores University',
      'Aston University',
      'Keele University',
      'Anglia Ruskin University',
      'The University of Hull',
      'University of the Highlands and Islands',
      'University of Greenwich',
      'University of London',
      'Manchester Metropolitan University',
      'University of Brighton',
      'Bournemouth University',
      'Cranfield University',
      'University of Westminster',
      'Northumbria University',
      'University of Central Lancashire',
      'Kingston University',
      'Royal College of Art',
      'University of Wolverhampton',
      'University of Lincoln',
      'University of East London',
      'School of Advanced Study, University of London',
      'London Metropolitan University',
      'London South Bank University',
      'University of Sunderland',
      'Abertay University',
      'University of Huddersfield',
      'University of South Wales',
      'University of Derby',
      'Robert Gordon University',
      'Staffordshire University',
      'The University of Northampton',
      'University of Roehampton',
      'University of Bedfordshire',
      'Glasgow Caledonian University',
      'Bath Spa University',
      'Teesside University',
      'Royal Veterinary College University of London',
      'University of Bradford',
      'The Courtauld Institute of Art, University of London',
      'The Glasgow School of Art',
      'Edge Hill University',
      'Canterbury Christ Church University',
      'University of Chester',
      'Solent University',
      'Cardiff Metropolitan University',
      'University of Wales Trinity Saint David',
      'University of the West of Scotland',
      'Royal College of Music',
      "St George's, University of London",
      'The University of Buckingham',
      "Scotland's Rural College",
      'University for the Creative Arts',
      'University of Gloucestershire',
      'University of Bolton',
      'Liverpool Hope University',
      'Royal Academy of Music, University of London',
      'The University of Winchester',
      'The University of Law',
      'Guildhall School of Music and Drama',
      'University of West London',
      'University of Cumbria',
      'University of Worcester',
    ],
  },
  {
    groupLabel: 'Denmark',
    options: [
      'Aalborg Universitet',
      'Aarhus Universitet',
      'Arkitektskolen Aarhus',
      'Danmarks Medie- og journalisthøjskole',
      'Danmarks Tekniske Universitet',
      'Den Danske Scenekunstskole',
      'Designskolen Kolding',
      'Det Jyske Musikkonservatorium',
      'Det Kongelige Akademi - Arkitektur, Design, Konservering',
      'Det Kongelige Danske Kunstakademi, Billedkunstskolerne',
      'Det Kongelige Danske Musikkonservatorium',
      'Erhvervsakademi Aarhus',
      'Erhvervsakademi Dania',
      'Erhvervsakademi Midtvest',
      'Erhvervsakademi Sjælland',
      'Erhvervsakademi SydVest',
      'Erhvervsakademiet Copenhagen Business',
      'Handelshøjskolen i København',
      'IBA Erhvervsakademi Kolding',
      'IT-Universitetet i København',
      'Københavns Erhvervsakademi',
      'Københavns Universitet',
      'Roskilde Universitet',
      'Syddansk Musikkonservatorium',
      'Syddansk Universitet',
      'University College Lillebaelt',
    ],
  },
  {
    groupLabel: 'Poland',
    options: [
      'Akademia Finansów i Biznesu Vistula',
      'Akademia Górniczo-Hutnicza',
      'Akademia Humanistyczno-Ekonomiczna w Lodzi',
      'Akademia Leona Kozminskiego',
      'Akademia Morska w Szczecinie',
      'Akademia Muzyczna im. Feliksa Nowowiejskiego w Bydgoszczy',
      'Akademia Muzyczna im. Grazyny i Kiejstuta Bacewiczów w Lodzi',
      'Akademia Muzyczna im. Ignacego Jana Paderewskiego w Poznaniu',
      'Akademia Muzyczna im. Karola Lipinskiego we Wroclawiu',
      'Akademia Muzyczna im. Karola Szymanowskiego w Katowicach',
      'Akademia Muzyczna im. Stanislawa Moniuszki w Gdansku',
      'Akademia Muzyczna w Krakowie',
      'Akademia Pedagogiki Specjalnej im. Marii Grzegorzewskiej',
      'Akademia Polonijna w Czestochowie',
      'Akademia Pomorska w Slupsku',
      'Akademia Sztuk Pieknych im. Eugeniusza Gepperta we Wroclawiu',
      'Akademia Sztuk Pieknych im. Jana Matejki w Krakowie',
      'Akademia Sztuk Pieknych im. Wladyslawa Strzeminskiego w Lodzi',
      'Akademia Sztuk Pieknych w Gdansku',
      'Akademia Sztuk Pieknych w Katowicach',
      'Akademia Sztuk Pieknych w Warszawie',
      'Akademia Sztuk Teatralnych im. Stanislawa Wyspianskiego w Krakowie',
      'Akademia Techniczno-Humanistyczna',
      'Akademia Wychowania Fizycznego i Sportu im. Jedrzeja Sniadeckiego',
      'Akademia Wychowania Fizycznego im. Bronislawa Czecha w Krakowie',
      'Akademia Wychowania Fizycznego im. Eugeniusza Piaseckiego w Poznaniu',
      'Akademia Wychowania Fizycznego im. Jerzego Kukuczki w Katowicach',
      'Akademia Wychowania Fizycznego Józefa Pilsudskiego w Warszawie',
      'Akademia Wychowania Fizycznego we Wroclawiu',
      'Akademii Sztuki w Szczecinie',
      'Bielska Wyzsza Szkola im. Józefa Tyszkiewicza',
      'Chrzescijanska Akademia Teologiczna w Warszawie',
      'Gdanski Uniwersytet Medyczny',
      'Górnoslaska Wyzsza Szkola Handlowa im. Wojciecha Korfantego w Katowicach',
      'Katolicki Uniwersytet Lubelski Jana Pawla II',
      'Miedzynarodowa Wyzsza Szkola Logistyki i Transportu we Wroclawiu',
      'Nadbuzanska Szkola Wyzsza im. Marka J. Karpia w Siemiatyczach',
      'Panstwowa Uczelnia Stanislawa Staszica w Pile',
      'Panstwowa Uczelnia Zawodowa im. Ignacego Moscickiego w Ciechanowie',
      'Panstwowa Wyzsza Szkola Informatyki i Przedsiebiorczosci w Lomzy',
      'Panstwowa Wyzsza Szkola Zawodowa w Chelmie',
      'Panstwowa Wyzsza Szkola Zawodowa w Walczu',
      'Politechnika Bialostocka',
      'Politechnika Czestochowska',
      'Politechnika Gdanska',
      'Politechnika Koszalinska',
      'Politechnika Krakowska im. Tadeusza Kosciuszki',
      'Politechnika Lódzka',
      'Politechnika Lubelska',
      'Politechnika Opolska',
      'Politechnika Poznanska',
      'Politechnika Rzeszowska im. Ignacego Lukasiewicza',
      'Politechnika Slaska',
      'Politechnika Swietokrzyska w Kielcach',
      'Politechnika Warszawska',
      'Politechnika Wroclawska',
      'Polsko-Japonska Akademia Technik Komputerowych',
      'Pomorski Uniwersytet Medyczny w Szczecinie',
      'Slaski Uniwersytet Medyczny w Katowicach',
      'Staropolska Szkola Wyzsza w Kielcach',
      'Szkola Filmowa w Lodzi',
      'Szkola Glówna Gospodarstwa Wiejskiego',
      'Szkola Glówna Handlowa w Warszawie',
      'Szkola Wyzsza im. Pawla Wlodkowica w Plocku',
      'Uczelnia Jana Wyzykowskiego',
      'Uczelnia Lazarskiego',
      'Uczelnia Medyczna im. Marii Sklodowskiej - Curie w Warszawie',
      'Uczelnia Techniczno-Handlowa im. Heleny Chodkowskiej',
      'Uniwersytet Artystyczny w Poznaniu',
      'Uniwersytet Ekonomiczny w Katowicach',
      'Uniwersytet Ekonomiczny w Krakowie',
      'Uniwersytet Ekonomiczny w Poznaniu',
      'Uniwersytet Ekonomiczny we Wroclawiu',
      'Uniwersytet Gdanski',
      'Uniwersytet Humanistyczno-Przyrodniczy im. Jana Dlugosza w Czestochowie',
      'Uniwersytet im. Adama Mickiewicza w Poznaniu',
      'Uniwersytet Jagiellonski',
      'Uniwersytet Jana Kochanowskiego w Kielcach',
      'Uniwersytet Kardynala Stefana Wyszynskiego w Warszawie',
      'Uniwersytet Kazimierza Wielkiego w Bydgoszczy',
      'Uniwersytet Lódzki',
      'Uniwersytet Marii Curie-Sklodowskiej w Lublinie',
      'Uniwersytet Medyczny im. Karola Marcinkowskiego w Poznaniu',
      'Uniwersytet Medyczny im. Piastów Slaskich',
      'Uniwersytet Medyczny w Bialymstoku',
      'Uniwersytet Medyczny w Lodzi',
      'Uniwersytet Medyczny w Lublinie',
      'Uniwersytet Mikolaja Kopernika w Toruniu',
      'Uniwersytet Morski w Gdyn',
      'Uniwersytet Muzyczny Fryderyka Chopina',
      'Uniwersytet Opolski',
      'Uniwersytet Pedagogiczny im. Komisji Edukacji Narodowej w Krakowie',
      'Uniwersytet Przyrodniczo-Humanistyczny w Siedlcach',
      'Uniwersytet Przyrodniczy w Lublinie',
      'Uniwersytet Przyrodniczy w Poznaniu',
      'Uniwersytet Przyrodniczy we Wroclawiu',
      'Uniwersytet Rolniczy w Krakowie',
      'Uniwersytet Rzeszowski',
      'Uniwersytet Slaski w Katowicach',
      'Uniwersytet SWPS',
      'Uniwersytet Szczecinski',
      'Uniwersytet Technologiczno-Humanistyczny im. Kazimierza Pulaskiego w Radomiu',
      'Uniwersytet Technologiczno-Przyrodniczy w Bydgoszczy',
      'Uniwersytet w Bialymstoku',
      'Uniwersytet Warminsko-Mazurski w Olsztynie',
      'Uniwersytet Warszawski',
      'Uniwersytet Wroclawski',
      'Uniwersytet Zielonogórski',
      'Warszawski Uniwersytet Medyczny',
      'Wojskowa Akademia Techniczna im. Jaroslawa Dabrowskiego',
      'Wyzsza Szkola Bankowa w Poznaniu',
      'Wyzsza Szkola Biznesu - National-Louis University',
      'Wyzsza Szkola Ekonomii i Informatyki w Krakowie',
      'Wyzsza Szkola Ekonomii i Innowacji w Lublinie',
      'Wyzsza Szkola Ekonomii, Prawa i Nauk Medycznych',
      'Wyzsza Szkola Finansów i Zarzadzania w Bialymstoku',
      'Wyzsza Szkola Informatyki i Umiejetnosci z siedziba w Lodzi',
      'Wyzsza Szkola Informatyki i Zarzadzania im. Prof. Tadeusza Kotarbinskiego',
      'Wyzsza Szkola Informatyki Stosowanej i Zarzadzania',
      'Wyzsza Szkola Lingwistyczna w Czestochowie',
      'Wyzsza Szkola Pedagogiczna im. Janusza Korczaka w Warszawie',
      'Wyzsza Szkola Spoleczno-Ekonomiczna w Gdansku',
      'Wyzsza Szkola Zarzadzania i Administracji w Zamosciu',
      'Wyzsza Szkola Zarzadzania i Bankowosci w Krakowie',
      'Wyzsza Szkola Zdrowia w Gdansku',
      'Zachodniopomorska Szkola Biznesu w Szczecinie',
      'Zachodniopomorski Uniwersytet Technologiczny w Szczecinie',
    ],
  },
  {
    groupLabel: 'Germany',
    options: [
      'Freie Universität Berlin',
      'Rheinisch-Westfälische Technische Hochschule Aachen',
      'Johannes Gutenberg-Universität Mainz',
      'Technische Universität München',
      'Ludwig-Maximilians-Universität München',
      'Ruhr-Universität Bochum',
      'Ruprecht-Karls-Universität Heidelberg',
      'Universität Hamburg',
      'Technische Universität Dresden',
      'Humboldt-Universität zu Berlin',
      'Friedrich-Alexander-Universität Erlangen-Nürnberg',
      'Technische Universität Berlin',
      'Rheinische Friedrich-Wilhelms-Universität Bonn',
      'Leibniz Universität Hannover',
      'Westfälische Wilhelms-Universität Münster',
      'Karlsruher Institut für Technologie',
      'Universität zu Köln',
      'Goethe-Universität Frankfurt am Main',
      'Universität Leipzig',
      'Eberhard Karls Universität Tübingen',
      'Albert-Ludwigs-Universität Freiburg',
      'Georg-August-Universität Göttingen',
      'Philipps-Universität Marburg',
      'Universität Bremen',
      'Otto-von-Guericke-Universität Magdeburg',
      'Universität Stuttgart',
      'Christian-Albrechts-Universität zu Kiel',
      'Julius-Maximilians-Universität Würzburg',
      'Technische Universität Darmstadt',
      'Heinrich-Heine-Universität Düsseldor',
      'Universität Bielefeld',
      'Technische Universität Kaiserslautern',
      'Friedrich-Schiller-Universität Jena',
      'Universität des Saarlandes',
      'Universität Kassel',
      'Universität Potsdam',
      'Universität Osnabrück',
      'Universität Regensburg',
      'Universität Duisburg-Essen',
      'Charité - Universitätsmedizin Berlin',
      'Technische Universität Chemnitz',
      'Martin-Luther-Universität Halle-Wittenberg',
      'Carl von Ossietzky Universität Oldenburg',
      'Universität Paderborn',
      'Universität Mannheim',
      'Technische Universität Dortmund',
      'Universität Ulm',
      'Universität Konstanz',
      'Justus-Liebig-Universität Giessen',
      'Otto-Friedrich-Universität Bamberg',
      'Universität Trier',
      'Universität Bayreuth',
      'Universität Augsbur',
      'Technische Hochschule Deggendorf',
      'FernUniversität in Hagen',
      'Bergische Universität Wuppertal',
      'Universität Rostock',
      'Technische Universität Braunschweig',
      'Technische Universität Ilmenau',
      'Universität Siegen',
      'Fachhochschule Aachen',
      'Ernst-Moritz-Arndt-Universität Greifswald',
      'Technische Hochschule Köln',
      'Technische Universität Hamburg',
      'Bauhaus-Universität Weimar',
      'Universität Hohenheim',
      'Hochschule Augsburg',
      'Universität Passau',
      'Fachhochschule Dortmund',
      'Leuphana Universität Lüneburg',
      'Hochschule für Angewandte Wissenschaften Hamburg',
      'Hochschule Emden/Leer',
      'Universität der Künste Berlin',
      'Frankfurt University of Applied Sciences',
      'Technische Universität Clausthal',
      'Hochschule für Polizei und öffentliche Verwaltung Nordrhein-Westfalen',
      'Hochuschule Furtwangen',
      'Beuth Hochschule für Technik Berlin',
      'Technische Universität Bergakademie Freiberg',
      'Jacobs University Bremen',
      'Medizinische Hochschule Hannover',
      'Universität Erfurt',
      'Hochschule für angewandte Wissenschaften München',
      'Hochschule Heilbronn',
      'Hochschule Osnabrück',
      'Fachhochschule Münster',
      'Hochschule für angewandte Wissenschaften Würzburg-Schweinfurt',
      'Brandenburgische Technische Universität Cottbus-Senftenberg',
      'Deutsche Sporthochschule Köln',
      'Hochschule für Technik und Wirtschaft Berlin',
      'Universität Koblenz-Landau',
      'HAWK Hochschule Hildesheim/Holzminden/Göttingen',
      'Katholische Universität Eichstätt-Ingolstadt',
      'Hochschule der Medien',
      'Hochschule Bremen',
      'Universität zu Lübeck',
      'Technische Hochschule Mittelhessen',
      'Hochschule Mainz',
      'Hochschule Anhalt',
      'Universität Hildesheim',
      'Hochschule Niederrhein',
      'Universität Witten/Herdecke',
      'Hochschule Karlsruhe',
      'Tierärztliche Hochschule Hannover',
      'ESMT Berlin',
      'Kunsthochschule für Medien Köln',
      'Hochschule Worms',
      'Hochschule für Technik, Wirtschaft und Kultur Leipzig',
      'Hochschule Esslingen',
      'Hochschule für Philosophie München',
      'Hochschule Offenburg',
      'Hochschule Fulda',
      'Fachhochschule Bielefeld',
      'Frankfurt School of Finance and Management',
      'Universität der Bundeswehr München',
      'Hochschule Düsseldorf',
      'Hertie School of Governance Berlin',
      'Hochschule Wismar',
      'Hochschule Koblenz',
      'WHU - Otto Beisheim School of Management',
      'Hochschule Rhein-Waal',
      'Hochschule Darmstadt',
      'FOM Hochschule für Oekonomie und Management',
      'Hochschule für Wirtschaft und Recht Berlin',
      'Helmut-Schmidt-Universität',
      'Hochschule Hannover',
      'Ostfalia Hochschule für angewandte Wissenschaften',
      'Hochschule für Technik und Wirtschaft Dresden',
      'Fachhochschule Potsdam',
      'Technische Hochschule Ostwestfalen-Lippe',
      'Hochschule Trier',
      'Fachhochschule',
    ],
  },
  {
    groupLabel: 'Netherlands',
    options: [
      'Aeres Hogeschool',
      'Amsterdamse Hogeschool voor de Kunsten',
      'ArtEZ Hogeschool voor de kunsten',
      'AVANS Hogeschool',
      'Breda University of Applied Sciences',
      'Christelijke Hogeschool Ede',
      'Codarts Rotterdam',
      'De Haagse Hogeschool',
      'Design Academy Eindhoven',
      'Driestar Hogeschool',
      'Erasmus Universiteit Rotterdam',
      'Fontys Hogescholen',
      'Gerrit Rietveld Academie',
      'Hanzehogeschool Groningen',
      'HAS Hogeschool',
      'Hogeschool de Kempel',
      'Hogeschool INHolland',
      'Hogeschool iPABO',
      'Hogeschool Leiden',
      'Hogeschool Rotterdam',
      'Hogeschool Saxion',
      'Hogeschool Utrecht',
      'Hogeschool van Amsterdam',
      'Hogeschool van Arnhem en Nijmegen',
      'Hogeschool Viaa',
      'Hogeschool voor de Kunsten Utrecht',
      'Hogeschool Windesheim',
      'Hogeschool Zeeland',
      'Hotelschool The Hague',
      'IHE Delft Institute for Water Education',
      'Iselinge Hogeschool',
      'Islamic University of Applied Sciences Rotterdam',
      'Katholieke PABO Zwolle',
      'Koninklijke Academie van Beeldende Kunsten',
      'Marnix Academie',
      'NHL Stenden Hogeschool',
      'Nyenrode Business Universiteit',
      'Radboud Universiteit',
      'Rijksuniversiteit Groningen',
      'Technische Universiteit Delft',
      'Technische Universiteit Eindhoven',
      'TIAS School for Business and Society',
      'Tilburg University',
      'Universiteit Leiden',
      'Universiteit Maastricht',
      'Universiteit Twente',
      'Universiteit Utrecht',
      'Universiteit van Amsterdam',
      'Universiteit voor Humanistiek',
      'Van Hall Larenstein University of Applied Sciences',
      'Vrije Universiteit Amsterdam',
      'Wageningen Universiteit',
      'Wittenborg University of Applied Sciences',
      'Zuyd Hogeschool',
    ],
  },
  {
    groupLabel: 'Belgium',
    options: [
      'Académie des Beaux-Arts de Tournai',
      'Antwerp Management School',
      'AP Hogeschool Antwerpen',
      'Arteveldehogeschool',
      'Arts² - École supérieure des Arts',
      "Collège d'Europe",
      'Conservatoire Royal de Bruxelles',
      'Conservatoire Royal de Liège',
      'École de Recherche Graphique',
      'École Nationale Supérieure des Arts Visuels de La Cambre',
      'École Supérieure des Arts de la Ville de Liège',
      'École Supérieure des Arts Saint-Luc de Liège',
      'Erasmushogeschool Bruxelles',
      'Evangelische Theologische Faculteit',
      'Haute École Albert Jacquard',
      'Haute École Bruxelles-Brabant',
      'Haute École Charlemagne',
      'Haute École de la Communauté française en Hainaut',
      'Haute École de la Province de Liège',
      'Haute École de la Province de Namur',
      'Haute École de la Ville de Liège',
      'Haute École de Namur-Liège-Luxembourg',
      'Haute École EPHEC',
      'Haute École Francisco Ferrer',
      'Haute École Galilée',
      'Haute École Léonard de Vinci',
      'Haute École Libre de Bruxelles Ilya Prigogine',
      'Haute École Libre Mosane',
      'Haute École Louvain en Hainaut',
      'Haute École Lucia de Brouckère',
      'Haute École Provinciale de Hainaut-Condorcet',
      'Haute École Robert Schuman',
      'Hogere Zeevaartschool Antwerpen',
      'Hogeschool Gent',
      'Hogeschool PXL',
      'Hogeschool West-Vlaanderen',
      'Institut Catholique des Hautes Études Commerciales',
      'Institut des Arts de Diffusion',
      'Institut national supérieur des Arts du Spectacle et des Techniques de DiffusionInstitut Saint-Luc Tournai',
      'Institut Supérieur de Formation Sociale et de Communication',
      'Institut Supérieur de Musique et de Pédagogie',
      'Instituts Saint-Luc Bruxelles',
      'Instituut voor Tropische Geneeskunde',
      'Karel de Grote-Hogeschool',
      'Katholieke Universiteit Leuven',
      'LUCA School of Arts',
      'Odisee  Bruxelles',
      'Thomas More Hogeschool',
      'UC Leuven-Limburg',
      'Université Catholique de Louvain',
      'Université de Liège',
      'Université de Mons',
      'Université de Namur',
      'Université Libre de Bruxelles',
      'Université Saint-Louis - Bruxelles',
      'Universiteit Antwerpen',
      'Universiteit Gent',
      'Universiteit Hasselt',
      'Vesalius College',
      'VIVES Kortrijk',
      'Vlerick Business School',
      'Vrije Universiteit Brussel',
    ],
  },
  {
    groupLabel: 'Luxembourg',
    options: ['Université du Luxembourg'],
  },
  {
    groupLabel: 'France',
    options: [
      'Université de Lille',
      'Université de Strasbourg',
      'Université de Paris',
      'Université de Lorraine',
      'École Normale Supérieure',
      'Université Claude Bernard Lyon 1',
      'Université Grenoble Alpes',
      'Sorbonne Université',
      'Université Paris-Saclay',
      'Université de Caen Normandie',
      'CentraleSupélec',
      'Université Paris 1 Panthéon-Sorbonne',
      'Université Clermont Auvergne',
      'Université Paris 8 Vincennes-Saint-Denis',
      'Université de Rouen Normandie',
      'Université de Bordeaux',
      'École Normale Supérieure de Lyon',
      "Institut d'Études Politiques de Paris",
      'Université Lumière Lyon 2',
      'Université de Poitiers Poitiers',
      "Université Côte d'Azur",
      'Aix-Marseille Université',
      'École Polytechnique',
      'Université Paris 13',
      'Université de Montpellier',
      'École Centrale de Lyon',
      'Université de Nantes Nantes',
      'Université de Rennes 1',
      'Université Paris-Est Créteil Val de Marne',
      'Université Paris Nanterre',
      'Université de Tours',
      'École des Hautes Études Commerciales de Paris',
      'Université de Limoges',
      'Université de Franche-Comté',
      'École des Hautes Études en Sciences Sociales',
      'Université de Picardie Jules Verne',
      'Université Polytechnique Hauts-de-France',
      'Université de Bourgogne',
      'Université Toulouse III - Paul Sabatier',
      'École Supérieure des Sciences Économiques et Commerciales',
      "Université d'Angers",
      'Université Jean Moulin Lyon 3',
      "Université de Pau et des Pays de l'Adour",
      'Université Paris-Dauphine',
      'CY Cergy Paris Université',
      'Université de Technologie de Compiègne',
      'Université Toulouse - Jean Jaurès',
      'Université Sorbonne Nouvelle - Paris 3',
      "Université d'Orléans",
      'Université Rennes 2',
      'MINES ParisTech',
      'ESCP Business School',
      'Grenoble INP',
      'Université Paris-Est Marne-la-Vallée',
      'École des Ponts ParisTech',
      'Université de Toulon',
      'Université Paul Valéry Montpellier 3',
      'Télécom Paris',
      'Université de Reims Champagne-Ardenne',
      'Institut National des Sciences Appliquées de Lyon',
      'Université Paris 2 Panthéon-Assas',
      'IMT Atlantique',
      'Université de Versailles Saint-Quentin-en-Yvelines',
      'Université de Bretagne Occidentale',
      'Le Mans Université',
      'Université Bretagne Sud',
      'École des Hautes Études Commerciales du Nord',
      'Université Savoie Mont Blanc',
      "École Nationale Supérieure des Sciences de l'Information et des Bibliothèques",
      'Université de Perpignan',
      'EMLYON Business School',
      'Université de Haute-Alsace',
      "Université du Littoral Côte d'Opale",
      'Avignon Université',
      'ENSTA ParisTech',
      'École Normale Supérieure Paris-Saclay',
      'AgroParisTech',
      'Université Jean Monnet',
      'Université Toulouse 1 Capitole',
      'École des Hautes Études en Santé Publique',
      'Grenoble École de Management',
      'Université Bordeaux Montaigne',
      "École pour l'Informatique et les Techniques Avancées",
      'Université du Havre',
      'KEDGE Business School',
      "Université d'Évry-Val d'Essonne",
      'Audencia Business School',
      'École Nationale Supérieure des Mines de St-Etienne',
      'École Centrale de Nantes',
      "École Nationale d'Administration",
      'Télécom SudParis',
      'Eurecom',
      "École Nationale de l'Aviation Civile",
      'The American University of Paris',
      'École Nationale des Sciences Géographiques',
      'Arts et Métiers ParisTech',
      'Institut National des Sciences Appliquées de Toulouse',
      'École supérieure de physique et de chimie industrielles de la Ville de Paris',
      'Centrale Lille',
      'SKEMA Business School',
      "École Nationale Vétérinaire d'Alfort",
      'IMT Mines Alès',
      'Bordeaux INP',
      "Institut Supérieur de l'Aéronautique et de l'Espace",
      "Université d'Artois",
      'Université de Technologie de Troyes',
      'ESIEE Paris',
      'La Rochelle Université',
      'IÉSEG School of Management',
      'Université de Technologie de Belfort-Montbéliard',
      "Université Catholique de l'Ouest",
      'Montpellier SupAgro',
      'Institut Catholique de Paris',
      'Institut National des Sciences Appliquées de Rouen',
      'ESSCA School of Management',
    ],
  },
  {
    groupLabel: 'Andorra',
    options: ["Universitat d'Andorra"],
  },
  {
    groupLabel: 'Spain',
    options: [
      'IE University',
      'Universidad Abat Oliba CEU',
      'Universidad Alfonso X el Sabio',
      'Universidad Antonio de Nebrija',
      'Universidad Autónoma de Barcelona',
      'Universidad Autónoma de Madrid',
      'Universidad Camilo José Cela',
      'Universidad Carlos III de Madrid',
      'Universidad Católica de Ávila',
      'Universidad Católica de Valencia San Vicente Màrtir',
      'Universidad Católica San Antonio de Murcia',
      'Universidad CEU Cardenal Herrera',
      'Universidad CEU San Pablo',
      'Universidad Complutense de Madrid',
      'Universidad de Alcalá',
      'Universidad de Alicante',
      'Universidad de Almería',
      'Universidad de Barcelona',
      'Universidad de Burgos',
      'Universidad de Cádiz',
      'Universidad de Cantabria',
      'Universidad de Castilla-La Mancha',
      'Universidad de Córdoba',
      'Universidad de Deusto',
      'Universidad de Extremadura',
      'Universidad de Gerona',
      'Universidad de Granada',
      'Universidad de Huelva',
      'Universidad de Jaén',
      'Universidad de La Laguna',
      'Universidad de La Rioja',
      'Universidad de Las Palmas de Gran Canaria',
      'Universidad de León',
      'Universidad de Lérida',
      'Universidad de les Illes Balears',
      'Universidad de Málaga',
      'Universidad de Mondragón',
      'Universidad de Murcia',
      'Universidad de Navarra',
      'Universidad de Oviedo',
      'Universidad de Salamanca',
      'Universidad de Santiago de Compostela',
      'Universidad de Sevilla',
      'Universidad de Valencia',
      'Universidad de Valladolid',
      'Universidad de Vich - Universidad Central de Cataluña',
      'Universidad de Zaragoza',
      'Universidad del Atlántico Medio',
      'Universidad del País Vasco',
      'Universidad Europea de Madrid',
      'Universidad Europea del Atlántico',
      'Universidad Europea Miguel de Cervantes',
      'Universidad Fernando Pessoa Canarias',
      'Universidad Francisco de Vitoria',
      'Universidad Internacional de Andalucía',
      'Universidad Internacional de Cataluña',
      'Universidad Internacional Menéndez Pelayo',
      'Universidad Jaime I',
      'Universidad Loyola Andalucía',
      'Universidad Miguel Hernández de Elche',
      'Universidad Pablo de Olavide',
      'Universidad Politécnica de Cartagena',
      'Universidad Politécnica de Cataluña',
      'Universidad Politécnica de Madrid',
      'Universidad Politécnica de València',
      'Universidad Pompeu Fabra',
      'Universidad Pontificia Comillas',
      'Universidad Pontificia de Salamanca',
      'Universidad Pública de Navarra',
      'Universidad Ramon Llull',
      'Universidad Rey Juan Carlos',
      'Universidad Rovira i Virgili',
      'Universidad San Jorge',
      'Universidade da Coruña',
      'Universidade de Vigo',
    ],
  },
  {
    groupLabel: 'Portugal',
    options: [
      'Academia Nacional Superior de Orquestra',
      'Conservatório Superior de Música de Gaia',
      'Escola Superior Artística do Porto',
      'Escola Superior de Artes e Design',
      'Escola Superior de Atividades Imobiliárias',
      'Escola Superior de Educação Almeida Garrett',
      'Escola Superior de Educação de João de Deus',
      'Escola Superior de Educação de Paula Frassinetti',
      'Escola Superior de Educadores de Infância Maria Ulrich',
      'Escola Superior de Enfermagem Cruz Vermelha Portuguesa',
      'Escola Superior de Enfermagem de Coimbra',
      'Escola Superior de Enfermagem de Lisboa',
      'Escola Superior de Enfermagem de São José de Cluny',
      'Escola Superior de Enfermagem do Porto',
      'Escola Superior de Enfermagem São Francisco das Misericórdias',
      'Escola Superior de Hotelaria e Turismo do Estoril',
      'Escola Superior de Negócios Atlântico',
      'Escola Superior de Saúde da Cruz Vermelha Portuguesa',
      'Escola Superior de Saúde de Santa Maria',
      'Escola Superior de Saúde do Alcoitão',
      'Escola Superior de Saúde Egas Moniz',
      'Escola Superior de Saúde Norte da Cruz Vermelha Portuguesa',
      'Escola Superior Gallaecia',
      'Escola Superior Náutica Infante D. Henrique',
      'Escola Universitária Vasco da Gama',
      'Instituto de Estudos Superiores de Fafe',
      'Instituto Piaget',
      'Instituto Politécnico da Guarda',
      'Instituto Politécnico da Lusofonia',
      'Instituto Politécnico da Maia',
      'Instituto Politécnico de Beja',
      'Instituto Politécnico de Bragança',
      'Instituto Politécnico de Castelo Branco',
      'Instituto Politécnico de Coimbra',
      'Instituto Politécnico de Gestão e Tecnologia',
      'Instituto Politécnico de Leiria',
      'Instituto Politécnico de Lisboa',
      'Instituto Politécnico de Portalegre',
      'Instituto Politécnico de Santarém',
      'Instituto Politécnico de Setúbal',
      'Instituto Politécnico de Tomar',
      'Instituto Politécnico de Viana do Castelo',
      'Instituto Politécnico de Viseu',
      'Instituto Politécnico do Cávado e do Ave',
      'Instituto Politécnico do Porto',
      'Instituto Português de Administração de Marketing',
      'Instituto Superior D. Dinis',
      'Instituto Superior de Administração e Gestão',
      'Instituto Superior de Administração e Línguas',
      'Instituto Superior de Ciências da Informação e da Administração',
      'Instituto Superior de Ciências Educativas',
      'Instituto Superior de Ciências Educativas do Douro Penafiel',
      'Instituto Superior de Ciências Empresariais e do Turismo',
      'Instituto Superior de Educação e Ciências',
      'Instituto Superior de Entre Douro e Vouga',
      'Instituto Superior de Gestão',
      'Instituto Superior de Línguas e Administração de Leiria',
      'Instituto Superior de Novas Profissões',
      'Instituto Superior de Paços de Brandão',
      'Instituto Superior de Saúde do Alto Ave',
      'Instituto Superior de Serviço Social do Porto',
      'Instituto Superior de Tecnologias Avançadas de Lisboa',
      'Instituto Superior Manuel Teixeira Gomes',
      'Instituto Superior Miguel Torga',
      'Instituto Superior Politécnico do Oeste',
      'Instituto Superior Politécnico Gaya',
      'Instituto Universitário da Maia',
      'Instituto Universitário de Ciências da Saúde Gandra',
      'Instituto Universitário de Ciências Psicológicas, Sociais e da Vida',
      'Instituto Universitário de Lisboa',
      'Universidade Atlântica',
      'Universidade Autónoma de Lisboa Luís de Camões',
      'Universidade Católica Portuguesa',
      'Universidade da Beira Interior',
      'Universidade da Madeira',
      'Universidade de Aveiro',
      'Universidade de Coimbra',
      'Universidade de Évora',
      'Universidade de Lisboa',
      'Universidade de Trás-os-Montes e Alto Douro',
      'Universidade do Algarve',
      'Universidade do Minho',
      'Universidade do Porto',
      'Universidade dos Açores',
      'Universidade Europeia',
      'Universidade Fernando Pessoa',
      'Universidade Lusíada',
      'Universidade Lusófona de Humanidades e Technologias',
      'Universidade Lusófona do Porto',
      'Universidade Nova de Lisboa',
      'Universidade Portucalense Infante D. Henrique',
    ],
  },
  {
    groupLabel: 'Switzerland',
    options: [
      'Berner Fachhochschule',
      'École Polytechnique Fédérale de Lausanne',
      'Eidgenössische Technische Hochschule Zürich',
      'Fachhochschule Nordwestschweiz',
      'Fachhochschule Ostschweiz',
      'Glion Institute of Higher Education',
      'Haute École Spécialisée de Suisse Occidentale',
      'Hochschule Luzern',
      'Institut de hautes études internationales et du développement',
      'Kalaidos Fachhochschule',
      'Scuola universitaria professionale della Svizzera italiana',
      'Università della Svizzera Italiana',
      'Universität Basel',
      'Universität Bern',
      'Universität Luzern',
      'Universität St.Galle',
      'Universität Zürich',
      'Université de Fribourg',
      'Université de Genève',
      'Université de Lausanne',
      'Université de Neuchâtel',
      'Webster University Geneva',
      'Zürcher Fachhochschule',
    ],
  },
  {
    groupLabel: 'Austria',
    options: [
      'Akademie der Bildenden Künste Wien',
      'Alpen-Adria-Universität Klagenfurt',
      'Anton Bruckner Privatuniversität',
      'Bertha von Suttner Privatuniversität',
      'CAMPUS 02 Fachhochschule der Wirtschaft',
      'Danube Private University',
      'Diplomatische Akademie Wien',
      'Donau-Universität Krems',
      'Fachhochschule Burgenland',
      'Fachhochschule Campus Wien',
      'Fachhochschule des bfi Wien',
      'Fachhochschule Kärnten',
      'Fachhochschule Kufstein Tirol',
      'Fachhochschule Salzburg',
      'Fachhochschule St. Pölten',
      'Fachhochschule Vorarlberg',
      'Fachhochschule Wiener Neustadt',
      'Ferdinand Porsche FernFH',
      'FH Gesundheitsberufe OÖ',
      'FH Joanneum',
      'FH Oberösterreich',
      'FH Technikum Wien',
      'FHG - Zentrum für Gesundheitsberufe Tirol',
      'FHWien der WKW',
      'Gustav Mahler Privatuniversität für Musik',
      'Hochschule für Agrar- und Umweltpädagogik Wien',
      'IMC Fachhochschule Krems',
      'Institute of Science and Technology Austria',
      'Jam Music Lab Private University',
      'Johannes Kepler Universität Linz',
      'Karl Landsteiner Privatuniversität für Gesundheitswissenschaften',
      'Karl-Franzens-Universität Graz',
      'Katholische Privat-Universität Linz',
      'Kirchliche Pädagogische Hochschule Edith Stein',
      'Kirchliche Pädagogische Hochschule Graz',
      'Kirchliche Pädagogische Hochschule Wien/Krems',
      'Lauder Business School',
      'MCI Management Center Innsbruck',
      'Medizinische Universität Graz',
      'Medizinische Universität Innsbruck',
      'Medizinische Universität Wien',
      'MODUL University Vienna',
      'Montanuniversität Leoben',
      'Musik und Kunst Privatuniversität der Stadt Wien',
      'New Design University Privatuniversität',
      'Pädagogische Hochschule Kärnten',
      'Pädagogische Hochschule Niederösterreich',
      'Pädagogische Hochschule Oberösterreich',
      'Pädagogische Hochschule Salzburg',
      'Pädagogische Hochschule Steiermark',
      'Pädagogische Hochschule Tirol',
      'Pädagogische Hochschule Vorarlberg',
      'Pädagogische Hochschule Wien',
      'Paracelsus Medizinische Privatuniversität',
      'Paris-Lodron-Universität Salzburg',
      'Private Pädagogische Hochschule Burgenland',
      'Private Pädagogische Hochschule der Diözese Linz',
      'Private Universität für Gesundheitswissenschaften, Medizinische Informatik und Technik',
      'Privatuniversität Schloss Seeburg',
      'Sigmund Freud Privatuniversität Wien',
      'Technische Universität Graz',
      'Technische Universität Wien',
      'Universität für angewandte Kunst Wien',
      'Universität für Bodenkultur Wien',
      'Universität für Künstlerische und Industrielle Gestaltung Linz',
      'Universität für Musik und darstellende Kunst Graz',
      'Universität für Musik und darstellende Kunst Wien',
      'Universität Innsbruck',
      'Universität Mozarteum Salzburg',
      'Universität Wien',
      'Veterinärmedizinische Universität Wien',
      'Webster Vienna Private University',
      'Wirtschaftsuniversität Wien',
    ],
  },
  {
    groupLabel: 'Italy',
    options: [
      'Istituto Universitario di Studi Superiori',
      'Libera Università di Bolzano',
      'Libera Università di Lingue e Comunicazione IULM',
      'Libera Università Internazionale degli Studi Sociali Guido',
      'Libera Università Maria SS. Assunta',
      'Link Campus University',
      'LIUC Università Cattaneo',
      'Politecnico di Bari',
      'Politecnico di Milano',
      'Politecnico di Torino',
      'Sapienza Università di Roma',
      'Scuola IMT Alti Studi Lucca',
      'Scuola Internazionale Superiore di Studi Avanzati',
      'Scuola Normale Superiore di Pisa',
      "Scuola Superiore di Studi Universitari e Perfezionamento Sant'Anna",
      'UniCamillus University',
      "Università Ca' Foscari di Venezia",
      'Università Campus Bio-Medico di Roma',
      'Università Cattolica del Sacro Cuore',
      'Università Commerciale Luigi Bocconi',
      'Università degli Studi del Molise',
      'Università degli Studi del Piemonte Orientale',
      'Università degli Studi del Sannio di Benevento',
      'Università degli Studi della Basilicata',
      'Università degli Studi della Campania Luigi Vanvitelli',
      'Università degli Studi della Tuscia',
      "Università degli Studi dell'Aquila",
      "Università degli Studi dell'Insubria",
      'Università degli Studi di Bari Aldo Moro',
      'Università degli Studi di Bergamo',
      'Università degli Studi di Bologna',
      'Università degli Studi di Brescia',
      'Università degli Studi di Cagliari',
      'Università degli Studi di Camerino',
      'Università degli Studi di Cassino e del Lazio Meridionale',
      'Università degli Studi di Catania',
      'Università degli Studi di Enna Kore',
      'Università degli Studi di Ferrara',
      'Università degli Studi di Firenze',
      'Università degli Studi di Foggia',
      'Università degli Studi di Genova',
      'Università degli Studi di Macerata',
      'Università degli Studi di Messina',
      'Università degli Studi di Milano',
      'Università degli Studi di Milano-Bicocca',
      'Università degli Studi di Modena e Reggio Emilia',
      'Università degli Studi di Napoli "L\'Orientale"',
      'Università degli Studi di Napoli Federico II',
      'Università degli Studi di Napoli Parthenope',
      'Università degli Studi di Padova',
      'Università degli Studi di Palermo',
      'Università degli Studi di Parma',
      'Università degli Studi di Pavia',
      'Università degli Studi di Perugia',
      'Università degli Studi di Pisa',
      'Università degli Studi di Roma Foro Italico',
      'Università degli Studi di Roma Tor Vergata',
      'Università degli Studi di Salerno',
      'Università degli Studi di Sassari',
      'Università degli Studi di Scienze Gastronomiche',
      'Università degli Studi di Siena',
      'Università degli Studi di Teramo',
      'Università degli Studi di Torino',
      'Università degli Studi di Trento',
      'Università degli Studi di Trieste',
      'Università degli Studi di Udine',
      'Università degli Studi di Urbino Carlo Bo',
      'Università degli Studi di Verona',
      "Università degli Studi G. d'Annunzio Chieti-Pescara",
      'Università degli Studi Internazionali di Roma',
      'Università degli Studi Magna Graecia di Catanzaro',
      'Università degli Studi Mediterranea di Reggio Calabria',
      'Università degli Studi Roma Tre',
      'Università degli Studi Suor Orsola Benincasa',
      'Università del Salento',
      'Università della Calabria',
      "Università della Valle d'Aosta",
      'Università Europea di Roma',
      'Università HUMANITAS di Milano',
      'Università Iuav di Venezia',
      'Università LUM Jean Monnet',
      'Università per stranieri Dante Alighieri di Reggio Calabria',
      'Università per Stranieri di Perugia',
      'Università per Stranieri di Siena',
      'Università Politecnica delle Marche',
      'Università Vita-Salute San Raffaele',
    ],
  },
  {
    groupLabel: 'Romania',
    options: [
      'Academia de Muzica Gheorghe Dima',
      'Academia de Studii Economice din Bucuresti',
      'Institutul de Administrare a Afacerilor din Bucuresti',
      'Scoala Nationala de Studii Politice si Administrative',
      'Universitatea 1 Decembrie 1918 din Alba Iulia',
      'Universitatea Agora din Oradea',
      'Universitatea Alexandru Ioan Cuza din Iasi',
      'Universitatea Alma Mater Sibiu',
      'Universitatea Andrei Saguna',
      'Universitatea Apollonia din Iasi',
      'Universitatea Artifex din Bucuresti',
      'Universitatea Athenaeum din Bucuresti',
      'Universitatea Aurel Vlaicu din Arad',
      'Universitatea Avram Iancu din Cluj-Napoca',
      'Universitatea Babes-Bolyai',
      'Universitatea Bioterra din Bucuresti',
      'Universitatea Bogdan Voda din Cluj-Napoca',
      'Universitatea Constantin Brâncoveanu',
      'Universitatea Constantin Brâncusi',
      'Universitatea Crestina Dimitrie Cantemir',
      'Universitatea Crestina Partium',
      'Universitatea Danubius Galati',
      'Universitatea de Arhitectura si Urbanism Ion Mincu din Bucuresti',
      'Universitatea de Arta si Design din Cluj-Napoca',
      'Universitatea de Arte din Târgu Mures',
      'Universitatea de Arte George Enescu Iasi',
      'Universitatea de Medicina si Farmacie Carol Davila din Bucuresti',
      'Universitatea de Medicina si Farmacie din Craiova',
      'Universitatea de Medicina si Farmacie Gr. T. Popa',
      'Universitatea de Medicina si Farmacie Iuliu Hatieganu',
      'Universitatea de Medicina si Farmacie Victor Babes din Timisoara',
      'Universitatea de Medicina, Farmacie, Stiinte si Tehnologie "George Emil Palade" din Targu Mures',
      'Universitatea de Stiinte Agricole si Medicina Veterinara a Banatului Timisoara',
      'Universitatea de Stiinte Agricole si Medicina Veterinara din Cluj-Napoca',
      'Universitatea de Stiinte Agricole si Medicina Veterinara din Iasi',
      'Universitatea de Stiinte Agronomice si Medicina Veterinara din Bucuresti',
      'Universitatea de Vest din Timisoara',
      'Universitatea de Vest Vasile Goldis din Arad',
      'Universitatea Dimitrie Cantemir',
      'Universitatea din Bucuresti',
      'Universitatea din Craiova',
      'Universitatea din Oradea',
      'Universitatea din Petrosani',
      'Universitatea din Pitesti',
      'Universitatea Dunarea de Jos din Galati',
      'Universitatea Ecologica din Bucuresti',
      'Universitatea Emanuel din Oradea',
      'Universitatea Europeana "Dragan" din Lugoj ',
      'Universitatea George Bacovia Bacau',
      'Universitatea HYPERION',
      'Universitatea Lucian Blaga din Sibiu',
      'Universitatea Maritima din Constanta',
      'Universitatea Mihail Kogalniceanu',
      'Universitatea Nationala de Arta Teatrala si Cinematografica Ion Luca Caragiale',
      'Universitatea Nationala de Arte din Bucuresti',
      'Universitatea Nationala de Educatie Fizica si Sport',
      'Universitatea Nationala de Muzica din Bucuresti',
      'Universitatea Nicolae Titulescu din Bucuresti',
      'Universitatea Ovidius Constanta',
      'Universitatea Petre Andrei din Iasi',
      'Universitatea Petrol-Gaze din Ploiesti',
      'Universitatea Politehnica din Bucuresti',
      'Universitatea Politehnica din Timisoara',
      'Universitatea Româna de stiinte si Arte Gheorghe Cristea',
      'Universitatea Româno-Americana',
      'Universitatea Româno-Germana din Sibiu',
      'Universitatea Sapientia',
      'Universitatea Spiru Haret',
      'Universitatea Stefan cel Mare Suceava',
      'Universitatea Technica din Cluj-Napoca',
      'Universitatea Tehnica de Constructii Bucuresti',
      'Universitatea Tehnica Gheorghe Asachi din Iasi',
      'Universitatea Tibiscus din Timisoara',
      'Universitatea Titu Maiorescu',
      'Universitatea Transilvania din Brasov',
      'Universitatea Valahia din Târgoviste',
      'Universitatea Vasile Alecsandri din Bacau',
    ],
  },
  {
    groupLabel: 'Ukraine',
    options: [
      'National Technical University of Ukraine Kyiv Polytechnic Institute',
      'Taras Shevchenko National University of Kyiv',
      'National University of Kyiv-Mohyla Academy',
      'Ivan Franko National University of Lviv',
      'Lviv Polytechnic National University',
      'Borys Grinchenko Kyiv University',
      'National Pedagogical Dragomanov University',
      'National Aviation University',
      'National University of Life and Environmental Sciences of Ukraine',
      'V. N. Karazin Kharkiv National University',
      'Sumy State University',
      'Zaporizhzhya National University',
      'National Mining University',
      'Donetsk National Technical University',
      'West Ukrainian National University',
      'V.I. Vernadsky Crimean Federal University',
      'National Aerospace University',
      'Tavria State Agrotechnological University',
      'Kharkiv National University of Radioelectronics',
      'Melitopol State Pedagogical University',
      'Ukrainian Catholic University',
      'Dnipropetrovsk National University',
      'Uzhhorod National University',
      'Odessa National University',
      'Ternopil State Medical University',
      'Precarpathian National University',
      'Mykolayiv National University',
      'Kyiv National University of Trade and Economics',
      'Sevastopol State University',
      'Yaroslav Mudryi National Law University',
      'National University of Cherkasy',
      'National Technical University Kharkiv Polytechnic Institute',
      'Chernihiv National University of Technology',
      'Simon Kuznets Kharkiv National University of Economics',
      'Vinnytsia National Technical University',
      'Zhytomyr Ivan Franko State University',
      'National University of Pharmacy',
      'National University of Ostroh Academy',
      'Yuriy Fedkovych Chernivtsi National University',
      'Cherkasy State Technological University',
      'O.M. Beketov National University of Urban Economy in Kharkiv',
      'Bukovinian State Medical University',
      'Kyiv National Economic University',
      'National University of Food Technologies',
      'KROK University',
      "Vasyl' Stus Donetsk National University",
      'Zaporizhzhya National Technical University',
      'Ternopil National Technical University',
      'Zhytomyr State Technological University',
      'Uman State Pedagogical University',
      'O.O. Bogomolets National Medical University',
      'Ternopil Volodymyr Hnatiuk National Pedagogical University',
      'Kyiv National University of Construction and Architecture',
      'Zaporizhia State Medical University',
      'V.I. Vernadsky Taurida National University',
      'Lviv National Medical University',
      'Kherson State University',
      'Volodymyr Vynnychenko Central Ukrainian State Pedagogical University',
      'Kyiv National University of Culture and Arts',
      'Dnipropetrovsk National University of Railway Transport',
      'National University Yuri Kondratyuk Poltava Polytechnic',
      'Ivano-Frankivsk National Technical University of Oil and Gas',
      'Kharkiv National University of Internal Affairs',
      'Lesya Ukrainka Volyn National University',
      'Khmelnytskyi National University',
      'Odessa National Polytechnic University',
      'National University of Water and Environmental Engineering',
      'Kamyanets-Podilsky Ivan Ohienko National University',
      'South Ukrainian National Pedagogical University',
      'Kharkiv National Pedagogical University',
      'Dnipro State Medical University',
      'National University Odesa Law Academy',
      'Kyiv National University of Technologies and Design',
      'Vinnitsa National Medical University',
      'Luhansk Taras Shevchenko National University',
      'Lutsk National Technical University',
      'Poltava V. G. Korolenko National Pedagogical University',
      'National University of Physical Education and Sports of Ukraine',
      'Odessa National Economics University',
      'Pryazovskyi State Technical University',
      'Polissia National University',
      'Kharkiv National University of Civil Engineering and Architecture',
      'Ivano-Frankivsk National Medical University',
      'State University of Telecommunications',
      'Lviv State University of Physical Culture',
      'Odessa National Academy of Food Technologies',
      'Kremenchuk Mykhailo Ostrohradskyi National University',
      'Prydniprovska State Academy of Civil Engineering and Architecture',
      'Berdyansk State Pedagogical University',
      'East Ukrainian National University',
      'Mykolayiv National Agrarian University',
      'Poltava State Agrarian Academy',
      'Vinnytsia National Agrarian University',
      'Ukrainian Medical Stomatological Academy',
      'Dnepropetrovsk State University of Internal Affairs',
      'Izmail State University of Humanities',
      'National Metallurgical Academy of Ukraine',
      'Donetsk State University of Management',
      'Ukrainian Engineering and Pedagogical Academy',
      'Lviv National Academy of Arts',
      'State Agrarian and Engineering University in Podilia',
    ],
  },
  {
    groupLabel: 'Israel',
    options: [
      'Afeka Tel Aviv Academic College of Engineering',
      'Ariel University',
      'Bar-Ilan University',
      'Ben-Gurion University of the Negev',
      'Bezalel Academy of Arts and Design',
      'College of Law and Business Ramat Gan',
      'Galilee International Management Institute',
      'Hadassah Academic College',
      'Hebrew University of Jerusalem',
      'Holon Institute of Technology',
      'Jerusalem College of Engineering',
      'Jerusalem College of Technology',
      'Machon Lander',
      'Max Stern Academic College of Emek Yezreel',
      'Netanya Academic College',
      'Ono Academic College',
      'ORT Braude College',
      'Peres Academic Center',
      'Ruppin Academic Center',
      'Sami Shamoon College of Engineering',
      'Sapir College',
      'Schechter Institute of Jewish Studies',
      "Sha'arei Mishpat College",
      'Shenkar College of Engineering and Design',
      'Technion - Israel Institute of Technology',
      'Tel Aviv University',
      'Tel-Hai Academic College',
      'The Academic College of Tel-Aviv-Yaffo',
      'The College of Management - Academic Studies',
      'The Interdisciplinary Center',
      'The Jerusalem Academy of Music and Dance',
      'University of Haifa',
      'Weizmann Institute of Science',
    ],
  },
  {
    groupLabel: 'Türkiye',
    options: [
      'Anadolu Üniversitesi',
      'Bogaziçi Üniversitesi',
      'Istanbul Üniversitesi',
      'Orta Dogu Teknik Üniversitesi',
      'Istanbul Teknik Üniversitesi',
      'Ankara Üniversitesi',
      'Marmara Üniversitesi',
      'Hacettepe Üniversitesi',
      'Bilkent Üniversitesi',
      'Dokuz Eylül Üniversitesi',
      'Ege Üniversitesi',
      'Gazi Üniversitesi',
      'Atatürk Üniversitesi',
      'Sabanci Üniversitesi',
      'Yildiz Teknik Üniversitesi',
      'Koç Üniversitesi',
      'Selçuk Üniversitesi',
      'Sakarya Üniversitesi',
      'Akdeniz Üniversitesi',
      'Uludag Üniversitesi',
      'Pamukkale Üniversitesi',
      'Istanbul Bilgi Üniversitesi',
      'Çukurova Üniversitesi',
      'Erciyes Üniversitesi',
      'Kocaeli Üniversitesi',
      'Ondokuz Mayis Üniversitesi',
      'Istanbul Aydin Üniversitesi',
      'Mugla Sitki Koçman Üniversitesi',
      'Eskisehir Osmangazi Üniversitesi',
      'Süleyman Demirel Üniversitesi',
      'Karadeniz Teknik Üniversitesi',
      'Baskent Üniversitesi',
      'Yeditepe Üniversitesi',
      'Karabük Üniversitesi',
      'Sivas Cumhuriyet Üniversitesi',
      'Firat Üniversitesi',
      'Kadir Has Üniversitesi',
      'Izmir Yüksek Teknoloji Enstitüsü',
      'Istanbul Kültür Üniversitesi',
      'Gaziantep Üniversitesi',
      'Atilim Üniversitesi',
      'Afyon Kocatepe Üniversitesi',
      'Inönü Üniversitesi',
      'Necmettin Erbakan Üniversitesi',
      'Trakya Üniversitesi	',
      'Kirikkale Üniversitesi',
      'Ankara Yildirim Beyazit Üniversitesi',
      'Mersin Üniversitesi',
      'Bahçesehir Üniversitesi',
      'Çankaya Üniversitesi',
    ],
  },
  {
    groupLabel: 'Saudi Arabia',
    options: [
      'Al Baha University',
      'Al Jouf University',
      'Al Yamamah University',
      'Alfaisal University',
      'Al-Imam Muhammad Ibn Saud Islamic University',
      'Dar Al Uloom University',
      'Dar Al-Hekma University',
      'Effat University',
      'Fahad Bin Sultan University',
      'Imam Abdulrahman Bin Faisal University',
      'Institute of Public Administration, Saudi Arabia',
      'Islamic University of Madinah',
      'Jazan University',
      'King AbdulAziz University',
      'King Abdullah University of Science and Technology',
      'King Fahd University of Petroleum and Minerals',
      'King Faisal University',
      'King Khalid University',
      'King Saud bin Abdulaziz University for Health Sciences',
      'King Saud University',
      'Majmaah University',
      'Najran University',
      'Northern Borders University',
      'Prince Mohammad Bin Fahd University',
      'Prince Sattam Bin Abdulaziz University',
      'Prince Sultan University',
      'Princess Nora bint Abdulrahman University',
      'Qassim University',
      'Shaqra University',
      'Taibah University',
      'Taif University',
      'Umm Al-Qura University',
      'University of Bisha',
      'University of Business and Technology',
      'University of Hafr Al Batin',
      "University of Ha'il",
      'University of Jeddah',
      'University of Tabuk',
    ],
  },
  {
    groupLabel: 'Qatar',
    options: [
      'Qatar University',
      'Hamad Bin Khalifa University',
      'Weill Cornell Medicine - Qatar',
      'Virginia Commonwealth University School of the Arts in Qatar',
      'College of the North Atlantic - Qatar',
      'Carnegie Mellon University in Qatar',
      'Texas A&M University at Qatar',
      'Northwestern University in Qatar',
      'Georgetown University in Qatar',
      'Doha Institute for Graduate Studies',
      'University of Calgary in Qatar',
      'HEC Paris in Qatar',
      'University College London Qatar',
    ],
  },
  {
    groupLabel: 'United Arab Emirates',
    options: [
      'Abu Dhabi Polytechnic',
      'Abu Dhabi School of Management',
      'Abu Dhabi University',
      'Ajman University',
      'Al Ain University',
      'Al Dar University College',
      'Al Falah University',
      'Al Ghurair University',
      'Al Qasimia University',
      'Al Wasl University',
      'American College of Dubai',
      'American University in Dubai',
      'American University in the Emirates',
      'American University of Ras Al Khaimah',
      'American University of Sharjah',
      'Amity University Dubai',
      'Canadian University of Dubai',
      'City University College of Ajman',
      'Dubai Medical College',
      'Dubai Pharmacy College',
      'Emirates Aviation University',
      'Emirates College for Advanced Education',
      'Emirates Institute for Banking and Financial Studies',
      'European University College Dubai',
      'Fatima College of Health Sciences',
      'Gulf Medical University',
      'Higher Colleges of Technology',
      'Imam Malik College for Islamic Sharia and Law',
      'Institute of Management Technology Dubai',
      'Islamic Azad University U.A.E. Branch',
      'Jumeira University',
      'Khalifa University',
      'Khawarizmi International College',
      'Liwa College of Technology',
      'Manipal University, Duba',
      'Middlesex University Dubai',
      'Mohamed Bin Zayed University for Humanities',
      'Mohamed bin Zayed University of Artificial Intelligence',
      'Mohammed Bin Rashid School of Government',
      'Mohammed Bin Rashid University of Medicine and Health Sciences',
      'Murdoch University Dubai',
      'New York Institute of Technology Abu Dhabi',
      'New York University Abu Dhabi',
      'Ras al-Khaimah Medical and Health Sciences University',
      'Rochester Institute of Technology, Dubai',
      'Shaheed Zulfikar Ali Bhutto Institute of Science and Technology Dubai',
      'Skyline University College',
      'The British University in Dubai',
      'The Emirates Academy of Hospitality Management',
      'Umm Al Quwain University',
      'United Arab Emirates University',
      'Université Paris-Sorbonne Abou Dhabi',
      'Université Saint-Joseph de Dubai',
      'University College of Mother and Family Sciences',
      'University of Balamand Dubai',
      'University of Birmingham Dubai',
      'University of Duba',
      'University of Fujairah',
      'University of Science and Technology of Fujairah',
      'University of Sharjah',
      'University of Wollongong in Dubai',
      'Zayed University',
    ],
  },
  {
    groupLabel: 'India',
    options: [
      'Aligarh Muslim University',
      'All India Institute of Medical Sciences Delhi',
      'Amity University',
      'Amrita Vishwa Vidyapeetham',
      'Anna University',
      'APJ Abdul Kalam Technological University',
      'Ashoka University',
      'Baba Ghulam Shah Badhshah University',
      'Banaras Hindu University',
      'Bangalore University',
      'Birla Institute of Technology',
      'Birla Institute of Technology and Science',
      'Chandigarh University',
      'Chaudhary Charan Singh Haryana Agricultural University',
      'Chaudhary Charan Singh University',
      'Chennai Mathematical Institute',
      'Chhatrapati Shahu Ji Maharaj University',
      'Chitkara University, Punjab',
      'Christ University',
      'Cochin University of Science and Technology',
      'CSK Himachal Pradesh Krishi Vishvavidyalaya',
      'Delhi Technological University',
      'Devi Ahilya Vishwavidyalaya',
      'Dr. A.P.J. Abdul Kalam Technical University',
      'Gandhi Institute of Technology and Management',
      'Gauhati University',
      'Gujarat Technological University',
      'Gulbarga University',
      'Guru Gobind Singh Indraprastha University',
      'Indian Institute of Foreign Trade',
      'Indian Institute of Information Technology Allahabad',
      'Indian Institute of Science',
      'Indian Institute of Science Education and Research, Pune',
      'Indian Institute of Technology Bombay',
      'Indian Institute of Technology Delhi',
      'Indian Institute of Technology Gandhinagar',
      'Indian Institute of Technology Guwahati',
      'Indian Institute of Technology Hyderabad',
      'Indian Institute of Technology Kanpur',
      'Indian Institute of Technology Kharagpur',
      'Indian Institute of Technology Madras',
      'Indian Institute of Technology Roorkee',
      'Indian Maritime University',
      'Indian School of Mines',
      'Indian Statistical Institute',
      'International Institute of Information Technology Bangalore',
      'International Institute of Information Technology, Hyderabad',
      'Jadavpur University',
      'Jain University',
      'Jamia Millia Islamia',
      'Jawaharlal Nehru Technological University',
      'Jawaharlal Nehru University',
      'KIIT University',
      'Kurukshetra University',
      'Lalit Narayan Mithila University',
      'Lovely Professional University',
      'Madurai Kamaraj University',
      'Mahatma Gandhi University',
      'Manipal Academy of Higher Education',
      'Maulana Abul Kalam Azad University of Technology, West Bengal',
      'Narendra Dev University of Agriculture and Technology',
      'Narsee Monjee Institute of Management and Higher Studies',
      'National Institute of Design',
      'National Institute of Fashion Technology',
      'National Institute of Mental Health and Neuro Sciences',
      'National Institute of Technology, Calicut',
      'National Institute of Technology, Karnataka',
      'National Institute of Technology, Rourkela',
      'National Institute of Technology, Silchar',
      'National Institute of Technology, Tiruchirappalli',
      'National Law School of India University',
      'Nirma University',
      'O.P. Jindal Global University',
      'Osmania University',
      'Panjab University',
      'Patna University',
      'Post Graduate Institute of Medical Education and Research',
      'Rajiv Gandhi Proudyogiki Vishwavidyalaya',
      'SASTRA University',
      'Savitribai Phule Pune University',
      'Sharda University',
      'SRM Institute of Science and Technology',
      'Tamil Nadu Agricultural University',
      'Tamil Nadu Veterinary and Animal Sciences University',
      'Tata Institute of Fundamental Research',
      'Tata Institute of Social Sciences',
      'Thapar Institute of Engineering and Technology',
      'The Maharaja Sayajirao University of Baroda',
      'University of Allahabad',
      'University of Calcutta',
      'University of Delhi',
      'University of Engineering and Management, Kolkata',
      'University of Hyderabad',
      'University of Kerala',
      'University of Lucknow',
      'University of Madras',
      'University of Mumbai',
      'University of Mysore',
      'University of Petroleum and Energy Studies',
      'University of Rajasthan',
      'Vellore Institute of Technology',
      'Visvesvaraya Technological University',
    ],
  },
  {
    groupLabel: 'Japan',
    options: [
      'Akita University',
      'Aoyama Gakuin University',
      'Chiba University',
      'Chuo University',
      'Doshisha University',
      'Ehime University',
      'Fujita Health University',
      'Fukuoka University',
      'Gakushuin University',
      'Gifu University',
      'Globis University',
      'Gunma University',
      'Hirosaki University',
      'Hiroshima University',
      'Hitotsubashi University',
      'Hokkaido University',
      'Hosei University',
      'International Christian University',
      'Japan Advanced Institute of Science and Technology',
      'Juntendo University',
      'Kagawa University',
      'Kagoshima University',
      'Kanagawa University',
      'Kanazawa University',
      'Kansai University',
      'Keio University',
      'Kindai University',
      'Kitasato University',
      'Kobe University',
      'Kokugakuin University',
      'Kumamoto University',
      'Kurume University',
      'Kwansei Gakuin University',
      'Kyoto Institute of Technology',
      'Kyoto Sangyo University',
      'Kyoto University',
      'Kyushu Institute of Technology',
      'Kyushu University',
      'Meiji Gakuin University',
      'Meiji University',
      'Mie University',
      'Musashino Art University',
      'Musashino University',
      'Nagaoka University of Technology',
      'Nagasaki University',
      'Nagoya Institute of Technology',
      'Nagoya University',
      'Nanzan University',
      'Nara Institute of Science and Technology',
      'Nihon University',
      'Niigata University',
      'Ochanomizu University',
      'Oita University',
      'Okayama University',
      'Osaka City University',
      'Osaka Kyoiku University',
      'Osaka Prefecture University',
      'Osaka University',
      'Rikkyo University',
      'Ritsumeikan Asia Pacific University',
      'Ritsumeikan University',
      'Ryukoku University',
      'Saga University',
      'Saitama University',
      'Sapporo Medical University',
      'Seikei University',
      'Shibaura Institute of Technology',
      'Shimane University',
      'Shinshu University',
      'Shizuoka University',
      'Sophia University',
      'Tama Art University',
      'The University of Electro-Communications',
      'The University of Tokyo',
      'Toho University',
      'Tohoku University',
      'Tokai University',
      'Tokyo Gakugei University',
      'Tokyo Institute of Technology',
      'Tokyo Keizai University',
      'Tokyo Medical and Dental University',
      'Tokyo Medical University',
      'Tokyo Metropolitan University',
      'Tokyo University of Agriculture and Technology',
      'Tokyo University of Foreign Studies',
      'Tokyo University of Science',
      'Tokyo University of the Arts',
      'Tottori University',
      'Toyo University',
      'University of Aizu',
      'University of Miyazaki',
      'University of the Ryukyus',
      'University of Toyama',
      'University of Tsukuba',
      'University of Yamanashi',
      'Waseda University',
      'Yamagata University',
      'Yamaguchi University',
      'Yokohama City University',
      'Yokohama National University',
    ],
  },
  {
    groupLabel: 'South Korea',
    options: [
      'Ajou University',
      'Andong National University',
      'Anyang University',
      'Busan University of Foreign Studies',
      'CHA University',
      'Changwon National University',
      'Cheongju University',
      'Chonnam National University',
      'Chosun University',
      'Chung-Ang University',
      'Chungbuk National University',
      'Chungnam National University',
      'Daegu University',
      'Dankook University',
      'Dong-A University',
      'Dong-Eui University',
      'Dongguk University',
      'Dongseo University',
      "Duksung Women's University",
      'Ewha Womans University',
      'Gachon University',
      'Gwangju University',
      'Gyeongsang National University',
      'Hallym University',
      'Hanbat National University',
      'Handong Global University',
      'Hankuk University of Foreign Studies',
      'Hannam University',
      'Hanseo University',
      'Hansung University',
      'Hanyang University',
      'Hongik University',
      'Hoseo University',
      'Inha University',
      'Inje University',
      'Jeju National University',
      'Jeonbuk National University',
      'Jeonju University',
      'KAIST Daejeon',
      'Kangwon National University',
      'Keimyung University',
      'Kongju National University',
      'Konkuk University',
      'Konyang University',
      'Kookmin University',
      'Korea Aerospace University',
      'Korea Maritime and Ocean University',
      'Korea National University of Education',
      'Korea Polytechnic University',
      'Korea University',
      'Korea University of Technology and Education',
      'Korean National University of Arts',
      'Kumoh National University of Technology',
      'Kunsan National University',
      'Kwangwoon University',
      'Kyonggi University',
      'Kyung Hee University',
      'Kyungnam University',
      'Kyungpook National University',
      'Kyungsung University',
      'Mokpo National University',
      'Mokwon University',
      'Myongji University',
      'Nambu University',
      'Namseoul University',
      'Pai Chai University',
      'Pohang University of Science and Technology',
      'Pukyong National University',
      'Pusan National University',
      'Sangji University',
      'Sangmyung University',
      'Sejong University',
      'Semyung University',
      'Seokyeong University',
      'Seoul National University',
      'Seoul National University of Science and Technology',
      "Seoul Women's University",
      'Seowon University',
      'Silla University',
      'Sogang University',
      "Sookmyung Women's University",
      'Soonchunhyang University',
      'Soongsil University',
      'Sun Moon University',
      'Sunchon National University',
      'Sungkyunkwan University',
      'Sungshin University',
      'The Catholic University of Korea',
      'The University of Suwon',
      'Ulsan National Institute of Science and Technology',
      'University of Incheon',
      'University of Science and Technology Korea',
      'University of Seoul',
      'University of Ulsan',
      'Wonkwang University',
      'Woosong University',
      'Woosuk University',
      'Yeungnam University',
      'Yong-In University',
      'Yonsei University',
    ],
  },
  {
    groupLabel: 'China',
    options: [
      'Beihang University',
      'Beijing Foreign Studies University',
      'Beijing Forestry University',
      'Beijing Institute of Technology',
      'Beijing Jiaotong University',
      'Beijing Language and Culture University',
      'Beijing Normal University',
      'Beijing University of Chemical Technology',
      'Beijing University of Posts and Telecommunications',
      'Capital Normal University',
      'Central China Normal University',
      'Central Conservatory of Music',
      'Central South University',
      'Central University of Finance and Economics',
      "Chang'an University",
      'China Agricultural University',
      'China Europe International Business School',
      'China University of Geosciences Wuhan',
      'China University of Mining and Technology',
      'Chongqing University',
      'Communication University of China',
      'Dalian University of Foreign Languages',
      'Dalian University of Technology',
      'Donghua University',
      'East China Normal University',
      'East China University of Science and Technology',
      'Fudan University',
      'Fujian Normal University',
      'Fuzhou University',
      'Guangdong University of Foreign Studies',
      'Guangdong University of Technology',
      'Guangxi Normal University',
      'Guangzhou University',
      'Harbin Engineering University',
      'Harbin Institute of Technology',
      'Hefei University of Technology',
      'Huaqiao University',
      'Huazhong Agricultural University',
      'Huazhong University of Science and Technology',
      'Hunan Normal University',
      'Hunan University',
      'Jiangsu University',
      'Jilin University',
      'Jinan University',
      'Lanzhou University',
      'Nanjing Agricultural University',
      'Nanjing Medical University',
      'Nanjing Normal University',
      'Nanjing University',
      'Nanjing University of Aeronautics and Astronautics',
      'Nanjing University of Posts and Telecommunications',
      'Nanjing University of Science and Technology',
      'Nankai University',
      'North China Electric Power University',
      'Northeast Normal University',
      'Northeastern University, China',
      'Northwest A&F University',
      'Northwest University, China',
      'Northwestern Polytechnical University',
      'Ocean University of China',
      'Peking University',
      'Qingdao University',
      'Qingdao University of Science and Technology',
      'Renmin University of China',
      'Shaanxi Normal University',
      'Shandong University',
      'Shanghai International Studies University',
      'Shanghai Jiao Tong University',
      'Shanghai University',
      'Shanghai University of Finance and Economics',
      'Shantou University',
      'Shenzhen University',
      'Sichuan University',
      'Soochow University',
      'South China Agricultural University',
      'South China Normal University',
      'South China University of Technology',
      'South University of Science and Technology',
      'Southeast University',
      'Southwest Jiaotong University',
      'Southwestern University of Finance and Economics',
      'Sun Yat-Sen University',
      'The University of Nottingham Ningbo, China',
      'Tianjin University',
      'Tongji University',
      'Tsinghua University',
      'University of Electronic Science and Technology of China',
      'University of Science and Technology Beijing',
      'University of Science and Technology of China',
      'University of the Chinese Academy of Sciences',
      'Wuhan University',
      'Wuhan University of Technology',
      "Xi'an Jiaotong University",
      'Xiamen University',
      'XiangTan University',
      'Xidian University',
      'Yanshan University',
      'Zhejiang University',
      'Zhengzhou University',
      'Zhongyuan University of Technology',
    ],
  },
  {
    groupLabel: 'Hong Kong',
    options: [
      'Chu Hai College of Higher Education',
      'City University of Hong Kong',
      'Hong Kong Baptist University',
      'Hong Kong Shue Yan University',
      'Lingnan University',
      'The Chinese University of Hong Kong',
      'The Education University of Hong Kong',
      'The Hong Kong Polytechnic University',
      'The Hong Kong University of Science and Technology',
      'The Open University of Hong Kong',
      'The University of Hong Kong',
    ],
  },
  {
    groupLabel: 'Singapore',
    options: [
      'LASALLE College of the Arts Singapore',
      'Nanyang Academy of Fine Arts',
      'Nanyang Technological University',
      'National University of Singapore',
      'Singapore Institute of Technology',
      'Singapore Management University',
      'Singapore University of Social Sciences',
      'Singapore University of Technology and Design',
    ],
  },
  {
    groupLabel: 'Australia',
    options: [
      'Australian Catholic University',
      'Australian National University',
      'Bond University',
      'Central Queensland University',
      'Charles Darwin University',
      'Charles Sturt University',
      'Curtin University',
      'Deakin University',
      'Edith Cowan University',
      'Federation University Australia',
      'Flinders University',
      'Griffith University',
      'James Cook University',
      'La Trobe University',
      'Macquarie University',
      'Monash University',
      'Murdoch University',
      'Queensland University of Technology',
      'RMIT University',
      'Southern Cross University',
      'Swinburne University of Technology',
      'The University of Adelaide',
      'The University of Melbourne',
      'The University of New South Wales',
      'The University of Newcastle',
      'The University of Notre Dame Australia',
      'The University of Queensland',
      'The University of Sydney',
      'The University of Western Australia',
      'Torrens University Australia',
      'University of Canberra',
      'University of New England',
      'University of South Australia',
      'University of Southern Queensland',
      'University of Tasmania',
      'University of Technology Sydney',
      'University of the Sunshine Coast',
      'University of Wollongong',
      'Victoria University',
      'Western Sydney University',
    ],
  },
  {
    groupLabel: 'New Zealand',
    options: [
      'Auckland University of Technology',
      'Lincoln University, New Zealand',
      'Massey University',
      'University of Auckland',
      'University of Canterbury',
      'University of Otago',
      'University of Waikato',
      'Victoria University of Wellington',
    ],
  },
  {
    groupLabel: 'South Africa',
    options: [
      'Cape Peninsula University of Technology',
      'Central University of Technology',
      'Durban University of Technology',
      'Mangosuthu University of Technology',
      'Nelson Mandela University',
      'North-West University',
      'Rhodes University',
      'Sefako Makgatho Health Sciences University',
      'Sol Plaatje University Kimberley',
      'Tshwane University of Technology',
      'Universiteit Stellenbosch',
      'University of Cape Town',
      'University of Fort Hare',
      'University of Johannesburg',
      'University of KwaZulu-Natal',
      'University of Limpopo Mankweng',
      'University of Mpumalanga',
      'University of Pretoria Pretoria',
      'University of the Free State',
      'University of the Western Cape',
      'University of the Witwatersrand',
      'University of Venda',
      'University of Zululand',
      'Vaal University of Technology',
      'Walter Sisulu University',
    ],
  },
];

const UNIVERSITY_AFFILIATION_OPTIONS = UNIVERSITY_OPTIONS.map((x) => ({
  groupLabel: x.groupLabel,
  options: listToSelectOptions(x.options),
}));

export default UNIVERSITY_AFFILIATION_OPTIONS;
