import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import { INCUBATOR_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/IncubatorOptions';
import { MAX_INCUBATORS } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.HasIncubator] &&
    values[StartupFieldNames.HasIncubator] === true ? (
    <Field
      className="custom-select"
      component={CustomSelect}
      components={{ Menu: LimitedMenu }}
      isClearable
      isMulti
      label="What accelerators or incubators have you been part of?"
      maxCount={MAX_INCUBATORS}
      name={StartupFieldNames.Incubators}
      options={INCUBATOR_OPTIONS}
      placeholder="Select accelators or incubators..."
    />
  ) : (
    <></>
  );
}

const IncubatorsField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.Incubators]: yup
      .array()
      .of(yup.string())
      .nullable()
      .when(StartupFieldNames.HasIncubator, {
        is: (hasIncubator: boolean) => hasIncubator,
        then: (schema) =>
          schema
            .of(yup.string())
            .typeError('Must be a valid incubator')
            .max(
              MAX_INCUBATORS,
              `At most ${MAX_INCUBATORS} incubators are allowed`,
            ),
      }),
  }),
};

export default IncubatorsField;
