export enum GenAiTransactionTypes {
  FeedbackIcp = 'FeedbackIcp',
  FeedbackCompanyDescription = 'FeedbackCompanyDescription',
  MessageInvestorOutreach = 'MessageInvestorOutreach',
}

export function getGenAiTransactionCost(
  transactionType: GenAiTransactionTypes
): number {
  if (
    transactionType === GenAiTransactionTypes.FeedbackCompanyDescription ||
    transactionType === GenAiTransactionTypes.FeedbackIcp ||
    transactionType === GenAiTransactionTypes.MessageInvestorOutreach
  ) {
    return 1;
  }

  return 0;
}
