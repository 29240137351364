import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { ProductCategory } from '/../libs/shared-types/src/constants/ProductCategory';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { enumToSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';

const InvProductCategoriesField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      isClearable
      isMulti
      label="What categories of products do you invest in?"
      name={InvestorFieldNames.InvProductCategories}
      options={enumToSelectOptions(ProductCategory)}
      placeholder="Select categories..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.InvProductCategories]: yup
      .array()
      .of(yup.string())
      .nullable(),
  }),
};

export default InvProductCategoriesField;
