import React, { useContext, useEffect, useState } from 'react';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import Toast from '/src/components/notifications/Toast';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import { useParams } from 'react-router-dom';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import SubscriptionTiersSection from './Pricing/SubscriptionTiersSection';
import { InvestorTiers, StartupTiers } from './Pricing/Tiers';
import { BillingFrequency } from '/../libs/shared-types/src/constants/BillingFrequency';
import { isPaidTier } from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { handleCallStripe } from '../../../services/StripeService';
import ManagePaidSubscriptionCard from './ManageSubscriptionCard';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import Logger from '/src/services/logger';

const SUCCESS_URL_SLUG = 'success';

function BillingRoute() {
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();
  const { subscriptionTier, accountType, hasUsedTrial } = useContext(
    AccountMetadataContext,
  );
  const { successState } = useParams();

  async function onUpgradeButtonClicked(
    billingFrequency: BillingFrequency,
    selectedSubscriptionTier: SubscriptionTiers,
  ) {
    if (successState === SUCCESS_URL_SLUG) {
      Logger.error('User has already successfully updated subscription.');
      return;
    }

    const result = await handleCallStripe(
      billingFrequency,
      selectedSubscriptionTier,
    );
    setToastConfig(result);
  }

  useEffect(() => {
    if (successState === SUCCESS_URL_SLUG) {
      setToastConfig({
        title: 'Success! You are now subscribed!',
        message: 'Enjoy Flowlie Pro!',
        isError: false,
      });
    }
  }, [successState]);

  if (!accountType) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner size={14} color="blue" />
      </div>
    );
  }

  return (
    <main>
      {successState === SUCCESS_URL_SLUG &&
        (subscriptionTier === SubscriptionTiers.StartupFree ||
          subscriptionTier === SubscriptionTiers.InvestorFree) && (
          <section className="max-w-4xl space-y-6 py-24 sm:px-6 sm:py-24 lg:px-8">
            <div className="relative isolate overflow-hidden bg-white px-6 py-12 shadow ring-1 ring-gray-200 sm:rounded-3xl sm:px-16">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">
                Congrats on upgrading your subscription! 🚀
              </h2>
              <p className="mt-3 text-lg font-medium leading-8 text-gray-900">
                Refresh the page to get access to all Pro features
              </p>
              <button
                className="app-button--primary mt-3"
                onClick={() => window.location.reload()}
              >
                <ArrowPathIcon className="mr-2 size-[1rem]" />
                Refresh
              </button>
            </div>
          </section>
        )}

      {successState !== SUCCESS_URL_SLUG &&
        accountType === AccountTypes.Startup &&
        subscriptionTier === SubscriptionTiers.StartupFree && (
          <SubscriptionTiersSection
            currentTier={subscriptionTier}
            hasUsedTrial={hasUsedTrial || false}
            // description="From your first angel investment to Series B, Flowlie supports early-stage founders with scalable solutions and expert guidance tailored to your fundraising journey."
            description="With both self-serve and dedicated support options, Flowlie’s Fundraising Hub is designed to help early-stage founders optimize their capital raising and investor reporting from the first individual angel check all the way to an institutionally-led Series B."
            footnote=""
            header="Simple plans to maximize your fundraising potential"
            tiers={StartupTiers}
            onUpgradeSubscription={onUpgradeButtonClicked}
          />
        )}
      {successState !== SUCCESS_URL_SLUG &&
        accountType === AccountTypes.Investor &&
        subscriptionTier === SubscriptionTiers.InvestorFree && (
          <SubscriptionTiersSection
            currentTier={subscriptionTier}
            hasUsedTrial={hasUsedTrial || false}
            description="Elevate your dealflow due dilegence with our dedicated, data-driven tools for the modern investor."
            footnote=""
            header="Streamlined Dealflow Management. Transparent Pricing."
            tiers={InvestorTiers}
            onUpgradeSubscription={onUpgradeButtonClicked}
          />
        )}

      {subscriptionTier && isPaidTier(subscriptionTier) && (
        <ManagePaidSubscriptionCard />
      )}

      <Toast
        isShown={toastConfig !== undefined}
        onClose={() => setToastConfig(undefined)}
        title={toastConfig?.title ?? ''}
        isError={toastConfig?.isError}
        text={toastConfig?.message ?? ''}
      />
    </main>
  );
}

export default BillingRoute;
