import React from 'react';
import * as yup from 'yup';
import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';

import FormField from '/src/interfaces/FormField';
import BooleanSelectInput from '../../inputs/BooleanSelectInput';

const IsRoundInformationCorrectField: FormField = {
  fieldComponent: (
    <BooleanSelectInput
      label="Is the round information correct?"
      name={RecordInvestmentFieldNames.IsRoundInformationCorrect}
    />
  ),
  validation: yup.object({
    [RecordInvestmentFieldNames.IsRoundInformationCorrect]: yup
      .bool()
      .required('Required'),
  }),
};

export default IsRoundInformationCorrectField;
