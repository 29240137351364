import React from 'react';
import * as yup from 'yup';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import PercentageInput from '../../inputs/PercentageInput';

const GrossMarginField: FormField = {
  fieldComponent: (
    <PercentageInput
      label="Gross Margin"
      name={StartupFieldNames.GrossMargin}
      tooltip="The percentage of each dollar of revenue that the company retains as gross profit. It’s calculated as: (Total Revenue - Cost of Goods Sold) / Total Revenue"
    />
  ),
  validation: yup.object({
    [StartupFieldNames.GrossMargin]: yup
      .number()
      .typeError('Must be a number')
      .min(0, 'Must be a value between 0 and 100')
      .max(100, 'Must be a value between 0 and 100'),
  }),
};

export default GrossMarginField;
