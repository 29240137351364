import React from 'react';
import * as yup from 'yup';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import BooleanSelectInput from '/src/components/inputs/BooleanSelectInput';

const HasPreviousBankruptcyField: FormField = {
  fieldComponent: (
    <BooleanSelectInput
      label="Has the company, you, or any key executives ever filed for bankruptcy?"
      name={StartupFieldNames.HasPreviousBankruptcies}
      isClearable
    />
  ),
  validation: yup.object({
    [StartupFieldNames.HasPreviousBankruptcies]: yup.boolean().nullable(),
  }),
};

export default HasPreviousBankruptcyField;
