import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import { DeckChangeTypes } from '/../libs/shared-types/src/constants/DeckChangeTypes';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { enumToSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import { SelectOption } from '/../libs/shared-types/src/types/SelectOptions';
import { ArrowLongUpIcon } from '@heroicons/react/20/solid';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  const isUploadingDeck =
    values[StartupFieldNames.DeckMustValidate] &&
    values[StartupFieldNames.Deck] &&
    values[StartupFieldNames.Deck] instanceof File &&
    values;

  const nextMajorVersion = `${values.latestDeckVersion.major + 1}.0`;
  const nextMinorVersion = `${values.latestDeckVersion.major}.${
    values.latestDeckVersion.minor + 1
  }`;

  const formatOptionLabel = ({ label }: SelectOption): JSX.Element => (
    <div className="flex items-center space-x-6">
      <div>{label} Revision</div>
      <span className="inline-flex items-center space-x-1 rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
        <ArrowLongUpIcon className="h-3.5 w-3.5" />
        <span className="leading-none">
          Version&nbsp;
          {label === DeckChangeTypes.Major && nextMajorVersion}
          {label === DeckChangeTypes.Minor && nextMinorVersion}
        </span>
      </span>
    </div>
  );

  return (
    isUploadingDeck && (
      <Field
        className="custom-select"
        component={CustomSelect}
        closeMenuOnSelect
        formatOptionLabel={formatOptionLabel}
        label="Is this a major or minor revision to the deck?"
        secondaryLabel="It will determine how to increment your deck version, and helps investors stay up to date with your latest deck changes"
        name={StartupFieldNames.DeckChangeType}
        options={enumToSelectOptions(DeckChangeTypes)}
        placeholder="Select option..."
      />
    )
  );
}

const DeckChangeTypeField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.DeckChangeType]: yup
      .string()
      .when(StartupFieldNames.DeckMustValidate, {
        is: (hasChanged: boolean) => hasChanged === true,
        then: (schema) => schema.required('Required'),
      }),
  }),
};

export default DeckChangeTypeField;
