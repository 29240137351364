import React from 'react';

function LiveOnOnePagerTag() {
  return (
    <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
      <svg
        className="-ml-0.5 mr-1.5 h-2 w-2 animate-pulse text-green-500"
        fill="currentColor"
        viewBox="0 0 8 8"
      >
        <circle cx={4} cy={4} r={3} />
      </svg>
      Live on One-Pager
    </span>
  );
}

export default LiveOnOnePagerTag;
