import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import { ContactPreferences } from '/../libs/shared-types/src/constants/ContactPreferences';
import { MAX_CONTACT_PREFERENCE } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import { capitalize, isBlank } from '/src/util/formatting/strings';
import FormField from '/src/interfaces/FormField';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';
import CustomSelect from '../../inputs/CustomSelect';
import { enumToSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[InvestorFieldNames.IsOpenToColdInbound] ? (
    <>
      <Field
        className="custom-select"
        component={CustomSelect}
        components={{ Menu: LimitedMenu }}
        isClearable
        isMulti
        label="How do you prefer founders reach out to you?"
        // NOTE: The secondary label is using unicode ("\u0430" is the character "a") because Safari otherwise tries to autocomplete the field
        // This sucks and does make the label less accessible by screen readers, but there is no other solution at this time
        secondaryLabel={
          'Selecting “Em\u0430il” will publicly display your em\u0430il on your public profile.'
        }
        maxCount={MAX_CONTACT_PREFERENCE}
        name={InvestorFieldNames.PreferenceContact}
        options={enumToSelectOptions(ContactPreferences)}
        placeholder="Select preference..."
      />
    </>
  ) : (
    <></>
  );
}

const PreferenceContactField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.PreferenceContact]: yup
      .array()
      .of(yup.string())
      .test({
        name: 'linkedInMustBeDefined',
        exclusive: false,
        params: {},
        message: `You must add a ${capitalize(
          InvestorFieldNames.LinkedIn,
        )} social handle`,
        test(value) {
          if (
            value?.includes(capitalize(InvestorFieldNames.LinkedIn)) &&
            isBlank(this.parent[InvestorFieldNames.LinkedIn])
          ) {
            return false;
          }
          return true;
        },
      })
      .test({
        name: 'twitterMustBeDefined',
        exclusive: false,
        params: {},
        message: `You must add a ${capitalize(
          InvestorFieldNames.Twitter,
        )} social handle`,
        test(value) {
          if (
            value?.includes(capitalize(InvestorFieldNames.Twitter)) &&
            isBlank(this.parent[InvestorFieldNames.Twitter])
          ) {
            return false;
          }
          return true;
        },
      })
      .test({
        name: 'websiteMustBeDefined',
        exclusive: false,
        params: {},
        message: `You must add a ${capitalize(
          InvestorFieldNames.Website,
        )} link`,
        test(value) {
          if (
            value?.includes(capitalize(InvestorFieldNames.Website)) &&
            isBlank(this.parent[InvestorFieldNames.Website])
          ) {
            return false;
          }
          return true;
        },
      })
      .when(InvestorFieldNames.IsOpenToColdInbound, {
        is: (isOpenToColdInbound: boolean) => isOpenToColdInbound,
        then: (schema) =>
          schema
            .of(yup.string())
            .min(1, 'At least one is required')
            .required('This field is required'),
      }),
  }),
};

export default PreferenceContactField;
