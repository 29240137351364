import {
  Form,
  Formik,
  FormikHelpers,
  FormikValues,
  useFormikContext,
} from 'formik';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import FormStep from '/src/interfaces/FormStep';
import { ROUND_MANAGER_ROUND_FIELD_MAPPING } from './RoundFormFieldMapping';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import { Round } from '/../libs/shared-types/src/types/model/Round';
import Logger from '/src/services/logger';
import { getLatestClosedRound } from '/src/util/rounds';
import { formatDatePickerInput } from '/src/util/formatting/dates';
import WarnBeforeExit from '/src/components/utility/WarnBeforeExit';
import confetto from 'confetto';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';
import { PercentSold } from './RoundStatistics';
import isInputShown from './InputShown';
import { SlideUp } from '/src/components/animations/Slide';
import { formatRoundDisplayName } from '/../libs/shared-types/src/extensions/RoundExtensions';
import { buildRound } from './BuildRound';
import PremiumCTA from '../../../components/PremiumCTA';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { MARK_SALES_CALL } from '/src/constants/Marketing/CalendlyLinks';

const steps: FormStep[] = [
  {
    label: "Let's open a new round!",
    secondaryLabel:
      "Your startup's stage will be automatically updated once you finish opening the round. Keep in mind that you will be able to review and update any details of the round later.",
    fields: [
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.IsBridge],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.RoundStage],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.BridgeNumber],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.RaiseTarget],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.RaisedAmount],
    ],
    isOptional: false,
  },
  {
    label: "Exciting! Let's get some context on the structure.",
    secondaryLabel:
      "If you are not sure about your target round structure, you can select 'To Be Determined' for the first question. Remember, you can update these later.",
    fields: [
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.RoundType],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.SafeType],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.HasLeadInvestor],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.HasTermSheet],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.EstimatedCloseDate],
    ],
    isOptional: false,
  },
  {
    label: "Almost there, let's add the terms of the round.",
    secondaryLabel:
      'If you only have preliminary terms or ideal ones, add them below. You can adjust any of these later.',
    fields: [
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.ValuationCap],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.ValuationCapType],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.Discount],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.HasRightsMfn],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.Interest],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.ConversionTerm],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.Prepayment],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.PricePerShare],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.LiquidationPreference],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[
        RoundFieldNames.LiquidationParticipation
      ],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.AntidilutionRights],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.HasRightsPayToPlay],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.HasRightsDragAlong],
    ],
    isOptional: false,
  },
  {
    label: 'Just a few final details.',
    secondaryLabel:
      'Knowing what types of investors you are targeting and how long the money you raise will last is critical.',
    fields: [
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.OpenToInvestorTypes],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.RunwayTarget],
    ],
    isOptional: false,
  },
];

interface FormStepButtonsProps {
  stepIndex: number;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  onClose: () => void;
  isLastStep: () => boolean;
  currentStepValidationSchema: any;
}
function FormStepButtons({
  stepIndex,
  setStepIndex,
  onClose,
  isLastStep,
  currentStepValidationSchema,
}: FormStepButtonsProps): JSX.Element {
  const { isValid, dirty, isSubmitting, values, validateForm } =
    useFormikContext<any>();

  useEffect(() => {
    // The validation schema has changed, so validate the form to refresh the state of the buttons
    validateForm();
  }, [currentStepValidationSchema]);

  return (
    <div className="mt-6 flex flex-row-reverse">
      <button
        className="app-button--primary"
        disabled={!isValid || !dirty || isSubmitting}
        type="submit"
      >
        {isLastStep()
          ? `Open ${formatRoundDisplayName(
              values[RoundFieldNames.RoundStage],
              values[RoundFieldNames.IsBridge],
              values[RoundFieldNames.BridgeNumber],
            )} round`
          : 'Next'}
        {isSubmitting && (
          <div className="mr-2 w-2">
            <LoadingSpinner color="white" />
          </div>
        )}
      </button>
      <button
        className="app-button--neutral mr-2"
        disabled={isSubmitting}
        onClick={
          stepIndex > 0
            ? () => {
                setStepIndex((s) => s - 1);
              }
            : onClose
        }
        type="button"
      >
        {stepIndex > 0 ? 'Back' : 'Cancel'}
      </button>
    </div>
  );
}

interface OpenNewRoundDialogProps {
  onClose: () => void;
  onSuccess: () => void;
  round: any;
  roundHistory: RoundView[];
  onSubmit: (values: Round) => Promise<any>;
}

function OpenNewRoundDialog({
  onClose,
  onSuccess,
  round,
  roundHistory,
  onSubmit,
}: OpenNewRoundDialogProps): JSX.Element {
  const [initialValues] = useState<any>({
    ...formatRoundForForm(round),
    [RoundFieldNames.RoundHistory]: roundHistory,
  });
  const { subscriptionTier } = useContext(AccountMetadataContext);

  const [isCompleted, setIsCompleted] = useState(false);

  // Set initial form state
  const [stepIndex, setStepIndex] = useState(0);
  const [currentStepValidationSchema, setCurrentStepValidationSchema] =
    useState(
      steps[0].fields
        .map((field) => field.validation)
        .reduce((merged, schema) => merged.concat(schema)),
    );

  function getActualSteps(values: FormikValues) {
    return steps.filter((x, index) => {
      if (
        values[RoundFieldNames.RoundType] === RoundTypes.ToBeDetermined &&
        index === 2
      ) {
        return false;
      }
      return true;
    });
  }

  function isLastStep(values: FormikValues) {
    return stepIndex === getActualSteps(values).length - 1;
  }

  const latestClosedRoundStage = getLatestClosedRound(roundHistory)?.roundStage;

  function formatRoundWithDateInput(roundToFormat: Round) {
    return {
      ...roundToFormat,
      [RoundFieldNames.EstimatedCloseDate]: formatDatePickerInput(
        roundToFormat[RoundFieldNames.EstimatedCloseDate] ?? 0,
      ),
    };
  }

  function formatRoundForForm(roundToFormat: Round) {
    return {
      ...formatRoundWithDateInput(roundToFormat),
      [RoundFieldNames.BridgeNumber]: roundToFormat.bridgeNumber?.toString(),
      [RoundFieldNames.IsEditable]: 'true',
    };
  }

  async function onSubmitWrapper(
    values: any,
    { setSubmitting }: FormikHelpers<any>,
  ): Promise<void> {
    try {
      setSubmitting(true);
      await onSubmit(values);
      setIsCompleted(true);
    } catch (error: any) {
      Logger.error(error);
      onClose();
    } finally {
      setSubmitting(false);
    }
  }

  const [showOffer, setShowOffer] = useState(false);
  useEffect(() => {
    if (subscriptionTier === SubscriptionTiers.StartupPremium) {
      return;
    }

    const timer = setTimeout(() => {
      setShowOffer(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isCompleted) {
      confetto();
    }
  }, [isCompleted]);

  useEffect(() => {
    // Set the validation schema for the current step
    setCurrentStepValidationSchema(
      steps[stepIndex].fields
        .map((field) => field.validation)
        .reduce((merged, schema) => merged.concat(schema)),
    );
  }, [stepIndex]);

  return (
    <main className="bg-white p-4 sm:w-screen sm:max-w-3xl sm:p-7">
      {isCompleted && (
        <div className="mt-3 w-full text-center sm:mt-0">
          <h3 className="mb-6 text-lg font-medium leading-6 text-gray-900">
            Hurray! Your new fundraising round has been opened succesfully!
          </h3>
          <p className="my-6 text-gray-800">
            Your one-pager will now include the details of your new round.
          </p>
          <p className="my-6 text-gray-700">
            As you raise capital, remember to come back to the Round Manager to
            record your progress, update any details, and keep prospective
            investors informed about your traction.
          </p>
          <button
            className="app-button--primary"
            onClick={() => {
              onSuccess();
              onClose();
            }}
            type="button"
          >
            View Round
          </button>
        </div>
      )}

      {!isCompleted && (
        <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
          <Formik
            initialValues={initialValues}
            validationSchema={currentStepValidationSchema}
            onSubmit={async (values, helpers) => {
              if (isLastStep(values)) {
                await onSubmitWrapper(values, helpers);
              } else {
                setStepIndex((s) => s + 1);
                Object.keys(values).forEach((fieldName) =>
                  helpers.setFieldTouched(fieldName, false),
                );
              }
            }}
          >
            {({ values, dirty }) => (
              <Form>
                <header className="mb-6">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="modal-headline"
                  >
                    {getActualSteps(values)[stepIndex].label}
                  </h3>
                  <p className="mt-3 text-sm text-gray-800">
                    {getActualSteps(values)[stepIndex].secondaryLabel}
                  </p>
                </header>
                <WarnBeforeExit isEnabled={dirty} />
                <section className="space-y-3">
                  {getActualSteps(values)[stepIndex].fields.map(
                    (field, index) => (
                      <Fragment key={index}>{field.fieldComponent}</Fragment>
                    ),
                  )}
                </section>
                {isInputShown('percentSold', values) && stepIndex === 2 && (
                  <div className="mt-2">
                    <PercentSold round={buildRound(values)} />
                  </div>
                )}

                <FormStepButtons
                  stepIndex={stepIndex}
                  setStepIndex={setStepIndex}
                  onClose={onClose}
                  isLastStep={() => isLastStep(values)}
                  currentStepValidationSchema={currentStepValidationSchema}
                />

                <SlideUp show={showOffer}>
                  <div className="mt-5">
                    <PremiumCTA
                      id="cta_open_round_consultation"
                      href={MARK_SALES_CALL}
                      content="Are you unsure how to best structure your round? Let an
                    expert help you! See if you qualify for our premium services, book a free call now."
                    />
                  </div>
                </SlideUp>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </main>
  );
}

export default OpenNewRoundDialog;
