import { SubscriptionTiers } from '../constants/SubscriptionTiers';

export enum StartupPaidFeatures {
  AccessManagerInvestors = 'AccessManagerInvestors',
  AccessManagerViewSessions = 'AccessManagerViewSessions',
  DataRoom = 'DataRoom',
  DeckVersionHistory = 'DeckVersionHistory',
  DilutionCalculator = 'DilutionCalculator',
  DiscountsAndPerks = 'DiscountsAndPerks',
  InvestorDetailSharingHistory = 'InvestorDetailSharingHistory',
  InvestorFitScore = 'InvestorFitScore',
  InvestorFragments = 'InvestorFragments',
  InvestorLists = 'InvestorLists',
  InvestorPipeline = 'InvestorPipeline',
  InvestorRelationship = 'InvestorRelationship',
  Persons = 'Persons',
  ViewSessionCsvExport = 'ViewSessionCsvExport',
}

export enum InvestorPaidFeatures {
  DealFitScore = 'DealFitScore',
}

export function hasFeatureAccess(
  feature: StartupPaidFeatures | InvestorPaidFeatures,
  subscriptionTier: SubscriptionTiers | undefined
) {
  switch (feature) {
    case StartupPaidFeatures.AccessManagerInvestors:
    case StartupPaidFeatures.AccessManagerViewSessions:
    case StartupPaidFeatures.DataRoom:
    case StartupPaidFeatures.DeckVersionHistory:
    case StartupPaidFeatures.DilutionCalculator:
    case StartupPaidFeatures.DiscountsAndPerks:
    case StartupPaidFeatures.InvestorDetailSharingHistory:
    case StartupPaidFeatures.InvestorFitScore:
    case StartupPaidFeatures.InvestorFragments:
    case StartupPaidFeatures.InvestorLists:
    case StartupPaidFeatures.InvestorPipeline:
    case StartupPaidFeatures.InvestorRelationship:
    case StartupPaidFeatures.Persons:
    case StartupPaidFeatures.ViewSessionCsvExport:
      return (
        subscriptionTier === SubscriptionTiers.StartupPro ||
        subscriptionTier === SubscriptionTiers.StartupPremium
      );
    case InvestorPaidFeatures.DealFitScore:
      return (
        subscriptionTier === SubscriptionTiers.InvestorPro ||
        subscriptionTier === SubscriptionTiers.InvestorTeam
      );
    default:
      return false;
  }
}

export function isPaidTier(subscriptionTier: SubscriptionTiers | undefined) {
  if (
    subscriptionTier === SubscriptionTiers.StartupPro ||
    subscriptionTier === SubscriptionTiers.StartupPremium ||
    subscriptionTier === SubscriptionTiers.InvestorPro ||
    subscriptionTier === SubscriptionTiers.InvestorTeam
  ) {
    return true;
  }

  return false;
}
