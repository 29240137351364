import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import FormikInput from '/src/components/inputs/FormikInput';
import websiteSchema from '/src/constants/validation/websiteSchema';

// This field is a copy of FounderFieldArray.LinkedIn
// It is used for covenience at Complete Profile
const FounderLinkedInField: FormField = {
  fieldComponent: (
    <Field
      component={FormikInput}
      label="Your LinkedIn URL (Optional)"
      secondaryLabel="Highly recommended because investors always want to learn about your professional background"
      name={StartupFieldNames.FounderLinkedIn}
      type="text"
    />
  ),
  validation: yup.object({
    [StartupFieldNames.FounderLinkedIn]: websiteSchema(false),
  }),
};

export default FounderLinkedInField;
