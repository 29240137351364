import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { INV_ROUND_TYPE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { FormatOptionLabelMeta } from 'react-select';
import { TooltipSelectOption } from '/../libs/shared-types/src/types/SelectOptions';
import FormatOptionWithTooltipLabel from '../../inputs/ReactSelectAdditions/formatOptionWithTooltipLabel';

const InvTypesField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      formatOptionLabel={(
        option: TooltipSelectOption,
        formatOptionLabelMeta: FormatOptionLabelMeta<any>
      ) => (
        <FormatOptionWithTooltipLabel
          option={option}
          formatOptionLabelMeta={formatOptionLabelMeta}
        />
      )}
      isClearable
      isMulti
      label="Investment Types"
      name={InvestorFieldNames.InvTypes}
      options={INV_ROUND_TYPE_OPTIONS}
      placeholder="Select round types..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.InvTypes]: yup
      .array()
      .of(yup.string())
      .min(1, 'At least one is required')
      .required('This field is required'),
  }),
};

export default InvTypesField;
