import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { PROFESSIONAL_ACCREDITATIONS_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/ProfessionalAccreditationsOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';
import { MAX_PROFESSIONAL_ACCREDITATIONS } from '/../libs/shared-types/src/constants/MultiSelectLimits';

const ProfessionalAccreditationsField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      components={{ Menu: LimitedMenu }}
      isClearable
      isMulti
      label="Professional Accreditations"
      maxCount={MAX_PROFESSIONAL_ACCREDITATIONS}
      name={InvestorFieldNames.ProfessionalAccreditations}
      options={PROFESSIONAL_ACCREDITATIONS_OPTIONS}
      placeholder="Select..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.ProfessionalAccreditations]: yup
      .array()
      .of(yup.string())
      .nullable()
      .max(
        MAX_PROFESSIONAL_ACCREDITATIONS,
        `At most ${MAX_PROFESSIONAL_ACCREDITATIONS} professional accreditations are allowed`
      ),
  }),
};

export default ProfessionalAccreditationsField;
