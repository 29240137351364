import React from 'react';
import { ArrowLongDownIcon, ArrowLongUpIcon } from '@heroicons/react/20/solid';
import { SortOrder } from '/src/interfaces/Sortable';

const SortIcon = ({ order }: { order: SortOrder }): JSX.Element => {
  if (order === SortOrder.Asc) {
    return (
      <span title="Sort Ascending" className="flex items-center">
        <ArrowLongUpIcon className="h-5 w-5" aria-hidden="true" />
        <div className="-ml-1 -space-y-1 text-[0.5rem]">
          <div>a</div>
          <div>z</div>
        </div>
      </span>
    );
  }
  return (
    <span title="Sort Descending" className="flex items-center">
      <ArrowLongDownIcon className="h-5 w-5" aria-hidden="true" />
      <div className="-ml-1 -space-y-1 text-[0.5rem]">
        <div>z</div>
        <div>a</div>
      </div>
    </span>
  );
};

export default SortIcon;
