import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { STARTUP_IND_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/StartupIndustryOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import { MAX_INVESTOR_EXP_INDUSTRY } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';

const ExpIndustryField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      components={{ Menu: LimitedMenu }}
      isClearable
      isMulti
      label="Industry Expertise"
      maxCount={MAX_INVESTOR_EXP_INDUSTRY}
      name={InvestorFieldNames.ExpIndustry}
      options={STARTUP_IND_OPTIONS}
      placeholder="Select expertise..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.ExpIndustry]: yup
      .array()
      .of(yup.string())
      .nullable()
      .max(
        MAX_INVESTOR_EXP_INDUSTRY,
        `At most ${MAX_INVESTOR_EXP_INDUSTRY} industry expertise are allowed`,
      ),
  }),
};

export default ExpIndustryField;
