import React from 'react';
import * as yup from 'yup';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import BooleanSelectInput from '../../inputs/BooleanSelectInput';

const HasIncubatorField: FormField = {
  fieldComponent: (
    <BooleanSelectInput
      label="Has your company ever participated in an accelerator or incubator?"
      name={StartupFieldNames.HasIncubator}
      isClearable
    />
  ),
  validation: yup.object({
    [StartupFieldNames.HasIncubator]: yup.boolean().nullable(),
  }),
};

export default HasIncubatorField;
