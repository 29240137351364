import React from 'react';
import * as yup from 'yup';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import BooleanSelectInput from '/src/components/inputs/BooleanSelectInput';

const HasPendingLawsuitsField: FormField = {
  fieldComponent: (
    <BooleanSelectInput
      label="Do you currently have any pending lawsuits?"
      name={StartupFieldNames.HasPendingLawsuits}
      isClearable
    />
  ),
  validation: yup.object({
    [StartupFieldNames.HasPendingLawsuits]: yup.boolean().nullable(),
  }),
};

export default HasPendingLawsuitsField;
