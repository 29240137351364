import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import MoneyInput from '/src/components/inputs/MoneyInput';
import {
  PRICE_PER_SHARE_MAX,
  PRICE_PER_SHARE_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.PricePerShare, values) ? (
    <MoneyInput
      label="Price Per Share"
      disabled={values[RoundFieldNames.IsEditable] === 'false'}
      name={RoundFieldNames.PricePerShare}
      allowDecimals
      decimalsLimit={4}
    />
  ) : (
    <></>
  );
}

const PricePerShareField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.PricePerShare]: yup
      .number()
      .typeError('Must be a number')
      .min(PRICE_PER_SHARE_MIN, `Must be at least $ ${PRICE_PER_SHARE_MIN}`)
      .max(
        PRICE_PER_SHARE_MAX,
        `Cannot be greater than $ ${PRICE_PER_SHARE_MAX}`
      ),
  }),
};

export default PricePerShareField;
