import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import BooleanSelectInput from '/src/components/inputs/BooleanSelectInput';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.IsBridge, values) ? (
    <BooleanSelectInput
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      label="Are you raising a Bridge or an Extension round?"
      name={RoundFieldNames.IsBridge}
      tooltip="A Bridge round is a smaller financing round between two Primary rounds (such as Seed, Series A or B)"
    />
  ) : (
    <></>
  );
}

const IsBridgeField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.IsBridge]: yup.bool().required('Required'),
  }),
};

export default IsBridgeField;
