import React from 'react';
import * as yup from 'yup';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import NumericInput from '../../inputs/NumericInput';
import {
  COMPLETED_PILOTS_MAX,
  COMPLETED_PILOTS_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';

const PilotsCountFieldField: FormField = {
  fieldComponent: (
    <NumericInput
      label="Completed & Ongoing Pilots"
      name={StartupFieldNames.PilotsCount}
    />
  ),
  validation: yup.object({
    [StartupFieldNames.PilotsCount]: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .min(COMPLETED_PILOTS_MIN, `Cannot be less than ${COMPLETED_PILOTS_MIN}`)
      .max(
        COMPLETED_PILOTS_MAX,
        `Cannot be greater than ${COMPLETED_PILOTS_MAX}`
      ),
  }),
};

export default PilotsCountFieldField;
