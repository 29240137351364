import { ClockIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { formatMilliSecondsAsTimeDuration } from '/src/util/formatting/dates';

interface TimeDurationStringProps {
  timeMs: number;
}

/**
 * Converts milliseconds into string format 'dd hh mm ss' and display up the most significant unit of measure
 * @param timeMs Time in milliseconds
 * @returns
 * @example millisecondsAsDaysHoursMinutesSeconds(3 * DAY_MS + 0 * HOUR_MS + 5 * MINUTE_MS + 67 * 1000)
 * => "3d 0h 6m 7s"
 * @example millisecondsAsDaysHoursMinutesSeconds(0 * DAY_MS + 2 * HOUR_MS + 4 * MINUTE_MS + 0 * 1000)
 * => "2h 4m 0s"
 */
function TimeDurationString({ timeMs }: TimeDurationStringProps): JSX.Element {
  return (
    <div className="flex items-center space-x-1.5">
      <ClockIcon className="mb-0.5 h-4 w-4 opacity-70" />
      <span>{formatMilliSecondsAsTimeDuration(timeMs)}</span>
    </div>
  );
}

export default TimeDurationString;
