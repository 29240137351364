import { listToSelectOptions } from '../../extensions/SelectOptionsExtensions';

export const ENTITY_TYPE_UNINCORPORATED = 'Unincorporated';
export const ENTITY_TYPE_C_CORP = 'C-Corp';

const ENTITY_TYPE_VALUES = [
  {
    groupLabel: ENTITY_TYPE_UNINCORPORATED,
    options: [ENTITY_TYPE_UNINCORPORATED],
  },
  {
    groupLabel: 'United States',
    options: [
      ENTITY_TYPE_C_CORP,
      'S-Corp',
      'Limited Liability Company (LLC)',
      'Limited Partnership (LP)',
      'General Partnership (GP)',
      'Sole Proprietorship',
    ],
  },
  {
    groupLabel: 'Canada',
    options: [
      'Private Corporation',
      'Public Corporation',
      'Extra-Provincial Corporation',
      'Limited Partnership (LP)',
      'General Partnership (GP)',
      'Sole Proprietorship (SP)',
      'Cooperative',
    ],
  },
  {
    groupLabel: 'Mexico',
    options: [
      'Stock Corporation (SA)',
      'Stock Corporation of Variable Capital (SA de CV)',
      'Limited Liability Company (SRL)',
      'Simplified Shares Company (SAS)',
      'Stock Corporation for Investment Promotion (SAPI)',
      'Civil Enterprise (SC)',
    ],
  },
  {
    groupLabel: 'Cayman Islands',
    options: [
      'Ordinary Resident Company',
      'Ordinary Non-Resident Company',
      'Exempt Limited Duration Company (LDC)',
      'Exempt Segregated Portfolio Company (SPC)',
      'Exempt Special Economic Zone Company',
      'Foreign Company',
      'Limited Liability Company (LLC)',
    ],
  },
  {
    groupLabel: 'Ireland',
    options: [
      'Private Company Limited by Shares (LTD)',
      'Designated Activity Company (DAC)',
      'Company Limited by Guarantee (CLG)',
      'Public Limited Company (PLC)',
      'Unlimited Company (ULC)',
      'Societas Europaea Company (SE)',
    ],
  },
  {
    groupLabel: 'United Kingdom',
    options: [
      'Private Limited Company (Ltd)',
      'Public Limited Company (PLC)',
      'General Partnership',
      'Limited Liability Partnership (LLP)',
    ],
  },
  {
    groupLabel: 'Luxembourg',
    options: [
      'Private Limited Liability Company (SARL)',
      'Public Limited Liability Company (SA)',
      'European Company (SE)',
      'Partnership Limited by Shares (SECA)',
      'General Partnership (SENC)',
      'Limited Partnership (SCS)',
    ],
  },
  {
    groupLabel: 'France',
    options: [
      'Simplified Joint Stock Company (SAS)',
      'Limited Liability Company (SARL)',
      'Individual Entrepreneur Limited Liability Company (EIRL)',
      'Single-Person Limited Liability Company (EURL)',
      'Private Limited Liability Company (SELARL)',
      'Public Limited Company (SA)',
      'General Partnership (SNC)',
    ],
  },
  {
    groupLabel: 'Germany',
    options: [
      'Limited Liability Company (GmbH)',
      'Limited Liability Entrepreneurial Company (Mini GmbH)',
      'Joint Stock Corporation (AG)',
      'Partnership Limited by Shares (KGaA)',
      'Limited Partnership (KG)',
      'General Partnership (OHG)',
    ],
  },
  {
    groupLabel: 'Poland',
    options: ['Limited Liability Company (Sp. z.o.o.)'],
  },
  {
    groupLabel: 'Estonia',
    options: [
      'Private Limited Company (OU)',
      'Public Limited Company (AS)',
      'Limited Partnership (UU)',
      'General Partnership (TU)',
      'Commercial Association (Uhistu)',
    ],
  },
  {
    groupLabel: 'Romania',
    options: [
      'Limited Liability Company (SRL)',
      'Joint-Stock Company (SA)',
      'Collective-Name Company (SNC)',
      'Sole Proprietorship (PFA)',
      'Limited Partnership (SCS)',
    ],
  },
  {
    groupLabel: 'Israel',
    options: [
      'Private Company (Osek)',
      'Limited Private Company',
      'Limtied Public Company',
      'Partnership',
      'Cooperative',
    ],
  },
  {
    groupLabel: 'United Arab Emirates',
    options: [
      'Offshore Company',
      'Limited Liability Company (LLC)',
      'General Partnership Company',
      'Limited Partnership Company',
      'Joint Venture (JV)',
      'Sole Proprietorship',
      'Private Shareholding Company',
    ],
  },
  {
    groupLabel: 'Nigeria',
    options: [
      'Sole Proprietorship',
      'Private Limited Company (LTD)',
      'Public Limited Company (PLC)',
      'Private Unlimited Company',
    ],
  },
  {
    groupLabel: 'Kenya',
    options: [
      'Private Limited Company',
      'Public Limited Company (PLC)',
      'Limited Liability Partnership (LLP)',
    ],
  },
  {
    groupLabel: 'South Africa',
    options: [
      'Sole Proprietorship',
      'Partnership',
      'Private Company (Pty Ltd)',
      'Public Company (Ltd)',
    ],
  },
  {
    groupLabel: 'Singapore',
    options: [
      'Private Limited Company',
      'Exempt Private Limited Company',
      'Public Limited Company',
      'Limited Partnership (LP)',
      'Limited Liability Partnership (LLP)',
      'General Partnership (GP)',
    ],
  },
  {
    groupLabel: 'Australia',
    options: [
      'Proprietary Limited Company (Pty Ltd)',
      'Australian Public Company (ASX)',
      'Sole Trader',
      'Partnership',
    ],
  },
];

export const ENTITY_TYPE_OPTIONS = ENTITY_TYPE_VALUES.map((x) => ({
  groupLabel: x.groupLabel,
  options: listToSelectOptions(x.options),
}));
