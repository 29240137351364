import { RoundStages } from '../RoundStages';
import { DataRoomTaskGroups } from './DataRoomTaskGroups';
import { DataRoomTasks } from './DataRoomTasks';

interface DataRoomGroupedTasks {
  group: DataRoomTaskGroups;
  tasks: DataRoomTasks[];
}

const DataRoomTasksMap_AngelOrPreSeed: DataRoomGroupedTasks[] = [
  {
    group: DataRoomTaskGroups.Team,
    tasks: [
      DataRoomTasks.OrgChart,
      DataRoomTasks.DetailedFounderTeamOrLeadershipBios,
      DataRoomTasks.EmploymentContracts,
      DataRoomTasks.AdvisorAgreements,
      DataRoomTasks.FounderShareAgreements,
      DataRoomTasks.EmployeeOptionsAgreements,
      DataRoomTasks.AgencyAndPartnerContracts,
    ],
  },
  {
    group: DataRoomTaskGroups.Company,
    tasks: [
      DataRoomTasks.CapTable,
      DataRoomTasks.IncorporationDocuments,
      DataRoomTasks.TaxIdNumber,
      DataRoomTasks.BusinessLicenses,
    ],
  },
  {
    group: DataRoomTaskGroups.FinancialsAndKpis,
    tasks: [
      DataRoomTasks.KPIsHistoricalAndForecast,
      DataRoomTasks.BalanceSheet,
      DataRoomTasks.IncomeStatementOrPL,
    ],
  },
  {
    group: DataRoomTaskGroups.CurrentRound,
    tasks: [
      DataRoomTasks.DraftInvestmentDocuments,
      DataRoomTasks.QSBSPotential,
    ],
  },
  {
    group: DataRoomTaskGroups.Product,
    tasks: [
      DataRoomTasks.ProductOverviewDeck,
      DataRoomTasks.ProductDemoVideo,
      DataRoomTasks.TechStackOverviewDiagram,
      DataRoomTasks.ProductRoadmap,
    ],
  },
];

const DataRoomTasksMap_Seed: DataRoomGroupedTasks[] = [
  {
    group: DataRoomTaskGroups.Team,
    tasks: [
      DataRoomTasks.OrgChart,
      DataRoomTasks.DetailedFounderTeamOrLeadershipBios,
      DataRoomTasks.EmploymentContracts,
      DataRoomTasks.AdvisorAgreements,
      DataRoomTasks.FounderShareAgreements,
      DataRoomTasks.EmployeeOptionsAgreements,
      DataRoomTasks.AgencyAndPartnerContracts,
    ],
  },
  {
    group: DataRoomTaskGroups.ClientsAndPartnerships,
    tasks: [
      DataRoomTasks.SalesPresentationsOrDecks,
      DataRoomTasks.SalesPipeline,
      DataRoomTasks.MajorClientContractsOrLOIs,
      DataRoomTasks.MajorPartnershipAgreements,
    ],
  },
  {
    group: DataRoomTaskGroups.Company,
    tasks: [
      DataRoomTasks.CapTable,
      DataRoomTasks.ESOPDocumentation,
      DataRoomTasks.BoardOfDirectorsBios,
      DataRoomTasks.IncorporationDocuments,
      DataRoomTasks.BylawsOrArticlesOfGovernance,
      DataRoomTasks.TaxIdNumber,
      DataRoomTasks.BusinessLicenses,
      DataRoomTasks.PatentsAndTrademarks,
      DataRoomTasks.InsuranceDocuments,
    ],
  },
  {
    group: DataRoomTaskGroups.PressAndAwards,
    tasks: [
      DataRoomTasks.AllAwards,
      DataRoomTasks.AllPressCoverage,
      DataRoomTasks.AllPodcastAppearances,
    ],
  },
  {
    group: DataRoomTaskGroups.FinancialsAndKpis,
    tasks: [
      DataRoomTasks.KPIsHistoricalAndForecast,
      DataRoomTasks.FinancialModelAndBudget,
      DataRoomTasks.BalanceSheet,
      DataRoomTasks.IncomeStatementOrPL,
      DataRoomTasks.TaxFilings,
      DataRoomTasks.DebtDocuments,
    ],
  },
  {
    group: DataRoomTaskGroups.CurrentRound,
    tasks: [
      DataRoomTasks.DraftInvestmentDocuments,
      DataRoomTasks.QSBSPotential,
      DataRoomTasks.HistoricalInvestorUpdates,
    ],
  },
  {
    group: DataRoomTaskGroups.Product,
    tasks: [
      DataRoomTasks.ProductOverviewDeck,
      DataRoomTasks.ProductDemoVideo,
      DataRoomTasks.ProductRoadmap,
      DataRoomTasks.TechStackOverviewDiagram,
    ],
  },
];

const DataRoomTasksMap_SeriesAAndLater: DataRoomGroupedTasks[] = [
  {
    group: DataRoomTaskGroups.Team,
    tasks: [
      DataRoomTasks.OrgChart,
      DataRoomTasks.DetailedFounderTeamOrLeadershipBios,
      DataRoomTasks.EmploymentContracts,
      DataRoomTasks.AdvisorAgreements,
      DataRoomTasks.AgencyAndPartnerContracts,
    ],
  },
  {
    group: DataRoomTaskGroups.ClientsAndPartnerships,
    tasks: [
      DataRoomTasks.SalesPresentationsOrDecks,
      DataRoomTasks.SalesPipeline,
      DataRoomTasks.MajorClientContractsOrLOIs,
      DataRoomTasks.MajorPartnershipAgreements,
    ],
  },
  {
    group: DataRoomTaskGroups.Company,
    tasks: [
      DataRoomTasks.CapTable,
      DataRoomTasks.ESOPDocumentation,
      DataRoomTasks.BoardOfDirectorsBios,
      DataRoomTasks.IncorporationDocuments,
      DataRoomTasks.BylawsOrArticlesOfGovernance,
      DataRoomTasks.TaxIdNumber,
      DataRoomTasks.BusinessLicenses,
      DataRoomTasks.PatentsAndTrademarks,
      DataRoomTasks.InsuranceDocuments,
    ],
  },
  {
    group: DataRoomTaskGroups.PressAndAwards,
    tasks: [
      DataRoomTasks.AllAwards,
      DataRoomTasks.AllPressCoverage,
      DataRoomTasks.AllPodcastAppearances,
    ],
  },
  {
    group: DataRoomTaskGroups.FinancialsAndKpis,
    tasks: [
      DataRoomTasks.KPIsHistoricalAndForecast,
      DataRoomTasks.FinancialModelAndBudget,
      DataRoomTasks.BalanceSheet,
      DataRoomTasks.IncomeStatementOrPL,
      DataRoomTasks.CashFlowStatement,
      DataRoomTasks.TaxFilings,
      DataRoomTasks.DebtDocuments,
    ],
  },
  {
    group: DataRoomTaskGroups.CurrentRound,
    tasks: [
      DataRoomTasks.DraftInvestmentDocuments,
      DataRoomTasks.QSBSPotential,
      DataRoomTasks.HistoricalInvestorUpdates,
    ],
  },
  {
    group: DataRoomTaskGroups.Product,
    tasks: [
      DataRoomTasks.ProductOverviewDeck,
      DataRoomTasks.ProductDemoVideo,
      DataRoomTasks.ProductRoadmap,
      DataRoomTasks.TechStackOverviewDiagram,
    ],
  },
];

export function getDataRoomGroupedTasksPerRoundStage(roundStage: RoundStages) {
  switch (roundStage) {
    case RoundStages.Angel:
    case RoundStages.PreSeed:
      return DataRoomTasksMap_AngelOrPreSeed;

    case RoundStages.Seed:
      return DataRoomTasksMap_Seed;

    case RoundStages.SeriesA:
    case RoundStages.SeriesB:
    case RoundStages.SeriesC:
    case RoundStages.SeriesD:
    case RoundStages.SeriesE:
    case RoundStages.SeriesF:
      return DataRoomTasksMap_SeriesAAndLater;

    default:
      return [];
  }
}

/**
 * Convenience function to get all tasks for a given round stage, in a flat array.
 * @param roundStage
 * @returns
 */
export function getDataRoomTasksPerRoundStage(roundStage: RoundStages) {
  return getDataRoomGroupedTasksPerRoundStage(roundStage)
    .map((x) => x.tasks)
    .flat();
}
