import React from 'react';
import DebugRender from '/src/components/utility/DebugRender';
import { FieldProps, getIn } from 'formik';

function FormikDebugger({ field, form }: FieldProps): JSX.Element {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const value = getIn(form.values, field.name);
  return (
    <DebugRender className="px-4 text-2xs">
      <span title={field.name} className="flex flex-row justify-between">
        <pre>Value: {JSON.stringify(value)}</pre>
        <pre>Value Type: {typeof value}</pre>
      </span>
      <span className="flex flex-row justify-between">
        <pre>Error: {error}</pre>
        <pre>Touched: {touched?.toString()}</pre>
      </span>
    </DebugRender>
  );
}

export default FormikDebugger;
