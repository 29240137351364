import React from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FlowlieF } from '../../FlowlieIcons';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import SquaredLogo from '../../SquaredLogo';

interface AuthDialogWrapperConfig {
  title: string;
  subTitle: string;
  imageSrc: string;
  imageAlt: string;
}

interface AuthDialogProps {
  wrapperConfig: AuthDialogWrapperConfig;
  showXButton: boolean;
  children: JSX.Element | JSX.Element[];
  isOpen: boolean;
  onClose?: () => void;
}

function AuthDialogWrapper({
  showXButton,
  children,
  isOpen,
  onClose,
  wrapperConfig,
}: AuthDialogProps): JSX.Element {
  return (
    <ModalWrapper open={isOpen} onClose={onClose}>
      <div className="bg-white sm:w-[30rem] sm:max-w-xl">
        <header className="flowlie-bg-gradient relative flex h-14">
          {showXButton && (
            <button
              type="button"
              onClick={onClose}
              className="absolute right-4 top-4"
            >
              <XMarkIcon className="h-6 w-6 text-white hover:text-blue-700" />
            </button>
          )}
          {wrapperConfig.imageSrc && (
            <div className="absolute top-1/4 left-1/2 flex -translate-x-1/2 transform items-center justify-center rounded-md shadow-xl">
              <SquaredLogo
                alt={wrapperConfig.imageAlt}
                src={wrapperConfig.imageSrc}
                containerClassName="size-20"
              />
            </div>
          )}
        </header>

        <main className="px-4 pb-4 sm:px-7">
          <div className="mb-4 mt-14 w-full">
            <Dialog.Title className="text-md text-center font-medium">
              {wrapperConfig.title}
            </Dialog.Title>
            <Dialog.Description className="pt-1 text-center text-sm font-light">
              {wrapperConfig.subTitle}
            </Dialog.Description>
          </div>

          <section>{children}</section>

          <footer className="mt-4 flex w-full flex-row justify-center text-center text-xs">
            Powered by &nbsp;
            <FlowlieF height={4} />
            &nbsp;
            <span className="font-medium tracking-wide">flowlie</span>
          </footer>
        </main>
      </div>
    </ModalWrapper>
  );
}

export default AuthDialogWrapper;
