import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, Navigate, Link } from 'react-router-dom';
import {
  ComparableStartupAccessManagerView,
  ComparableStartupAccessManagerViewSessionView,
  StartupAccessManagerFieldFilters,
  StartupAccessManagerPaginatedCollectionResponse,
  StartupAccessManagerViewSessionPaginatedCollectionResponse,
} from '/../libs/shared-types/src/types/view/StartupAccessManagerView';
import {
  StartupGetInvestorsEngagedCsv,
  StartupGetViewSessionsCsv,
  StartupGetDealViewSessions,
  StartupInvestorAccess,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import { toDashCase } from '/src/util/formatting/strings';
import API from '/src/middleware/API';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import Logger from '/src/services/logger';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import TabsHeader from '/src/components/tabs/TabsHeader';
import { EXAMPLE_INVESTOR_STARTUP_ACCESS_MANAGER_ENTRY } from '/../libs/shared-types/src/constants/UserActivation/MockData/ExampleInvestor';
import { PaginatedRequestParams } from '/../libs/shared-types/src/types/view/APIResponse';
import usePaginatedUrlsParams from '/src/hooks/usePaginatedUrlParams';
import AccessManagerInvestors, {
  defaultSortKeyInvestorAccess,
} from './AccessManagerInvestors';
import AccessManagerViewSessions, {
  defaultSortKeyViewSessions,
} from './AccessManagerViewSessions';
import { SortOrder } from '/../libs/shared-types/src/constants/SortOrder';
import { PublicProfilePrivacySetting } from '/../libs/shared-types/src/constants/PublicProfilePrivacySetting';
import Alert from '/src/components/notifications/Alert';
import {
  NOT_FOUND_ROUTE,
  STARTUP_FLOWLINK_MANAGER_ROUTE,
} from '/src/constants/Routes';
import { getPrivacySetting } from '/src/services/GetPrivacySetting';
import saveAs from 'file-saver';

function AccessManagerRoute(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [stageOptions, setStageOptions] = useState<string[]>([]);
  const [flowlinkPrivacySetting, setFlowlinkPrivacySetting] =
    useState<PublicProfilePrivacySetting>();
  const [viewSessionData, setViewSessionData] =
    useState<StartupAccessManagerViewSessionPaginatedCollectionResponse>();

  const [investorAccessData, setInvestorAccessData] =
    useState<StartupAccessManagerPaginatedCollectionResponse>();

  const { joyrideActivationTourState, setJoyrideActivationTourState } =
    useContext(SharedStateContext);

  const {
    page,
    perPage,
    sortOrder,
    sortKey,
    filter,
    fieldFilters,
    setFieldFilters,
  } = usePaginatedUrlsParams();

  const tabs = [
    { name: 'View Sessions', stat: viewSessionData?.stats.totalViewSessions },
    {
      name: 'Investors Engaged',
      stat: investorAccessData?.stats.uniqueInvestors,
    },
  ];

  async function fetchStartupAccessManager(
    args: Partial<
      PaginatedRequestParams<
        ComparableStartupAccessManagerView,
        StartupAccessManagerFieldFilters
      >
    >,
  ) {
    try {
      setIsSearchLoading(true);
      const requestParams = {
        sortKey: defaultSortKeyInvestorAccess,
        ...investorAccessData,
        ...args,
      };

      const url = StartupInvestorAccess.buildEndpoint(undefined, requestParams);

      const data =
        await API.get<StartupAccessManagerPaginatedCollectionResponse>(url);

      if (
        localStorage.getItem(
          LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING,
        )
      ) {
        data.results.splice(
          0,
          0,
          EXAMPLE_INVESTOR_STARTUP_ACCESS_MANAGER_ENTRY,
        );
        data.totalCount = data.results.length;
      }

      setFieldFilters(data.fieldFilters);
      setInvestorAccessData(data);
      setStageOptions(data.startupRoundOptions);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsSearchLoading(false);
    }
  }

  async function fetchViewSessionAccessData(
    args: Partial<
      PaginatedRequestParams<
        ComparableStartupAccessManagerViewSessionView,
        StartupAccessManagerFieldFilters
      >
    >,
  ) {
    try {
      const requestParams = {
        sortKey: defaultSortKeyViewSessions,
        ...viewSessionData,
        ...args,
      };

      const url = StartupGetDealViewSessions.buildEndpoint(
        undefined,
        requestParams,
      );

      const data =
        await API.get<StartupAccessManagerViewSessionPaginatedCollectionResponse>(
          url,
        );
      setFieldFilters(data.fieldFilters);
      setViewSessionData(data);
    } catch (error: any) {
      Logger.error(error);
    }
  }

  async function handleFilterInvestorAccess(filter: string) {
    try {
      await fetchStartupAccessManager({ filter, page: 1 });
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function handleFilterDealViewSessions(filter: string) {
    try {
      await fetchViewSessionAccessData({ filter, page: 1 });
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function handleFilterInvestorAccessByRoundStage(roundStage: string) {
    try {
      await fetchStartupAccessManager({
        fieldFilters: { roundStage },
        page: 1,
      });
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function handleFilterDealViewSessionsByRoundStage(roundStage: string) {
    try {
      await fetchViewSessionAccessData({
        fieldFilters: { roundStage },
        page: 1,
      });
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function loadInitialStartupAccessManager() {
    try {
      setIsLoading(true);
      await fetchStartupAccessManager({
        page,
        perPage,
        sortKey: (sortKey ??
          defaultSortKeyInvestorAccess) as keyof ComparableStartupAccessManagerView,
        sortOrder: !sortOrder ? SortOrder.Desc : sortOrder,
        filter,
      });

      await fetchViewSessionAccessData({
        page,
        perPage,
        sortKey: (sortKey ??
          defaultSortKeyViewSessions) as keyof ComparableStartupAccessManagerViewSessionView,
        sortOrder: !sortOrder ? SortOrder.Desc : sortOrder,
        filter,
      });
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleGetStartupViewSessionsCsv() {
    const blob = await API.getCsv(
      StartupGetViewSessionsCsv.buildEndpoint(undefined, {
        sortKey: defaultSortKeyViewSessions,
        ...viewSessionData,
      }),
    );
    if (!blob) {
      return;
    }
    saveAs(blob, 'View Sessions.csv');
  }

  async function handleGetInvestorsEngagedCsv() {
    const blob = await API.getCsv(
      StartupGetInvestorsEngagedCsv.buildEndpoint(undefined, {
        sortKey: defaultSortKeyInvestorAccess,
        ...investorAccessData,
      }),
    );
    if (!blob) {
      return;
    }
    saveAs(blob, 'Engaged Investors.csv');
  }

  async function fetchAndSetPrivacySetting() {
    try {
      const privacy = await getPrivacySetting();
      setFlowlinkPrivacySetting(privacy);
    } catch {
      Logger.error('Error fetching privacy setting');
    }
  }

  useEffect(() => {
    loadInitialStartupAccessManager();
    fetchAndSetPrivacySetting();
  }, []);

  useEffect(() => {
    const isNotResumeTour =
      isLoading ||
      !investorAccessData ||
      !localStorage.getItem(
        LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING,
      );

    if (isNotResumeTour) {
      return;
    }

    setJoyrideActivationTourState({
      ...joyrideActivationTourState,
      run: true,
    });
  }, [investorAccessData, isLoading]);

  return (
    <main>
      <header>
        <h2 className="page-title">Track FlowLink Views</h2>
        <Alert
          alertType="Info"
          canDismiss={true}
          color="blue"
          content={
            <ul className="list-inside list-disc">
              <li>
                When you send someone your FlowLink and they view your one-pager
                they will show up here, so you can monitor their activity.
              </li>
              <li>
                Remember: Your deck, one-pager, and round information will not
                be visible to anyone unless you send them your{' '}
                <Link
                  to={STARTUP_FLOWLINK_MANAGER_ROUTE}
                  className="hyperlink underline"
                >
                  FlowLink
                </Link>
              </li>
              {flowlinkPrivacySetting ===
                PublicProfilePrivacySetting.Public && (
                <li>
                  Your privacy setting is set to Public, so anyone who clicks on
                  your FlowLink will be shown as an anonymous visitor. Manage
                  privacy settings in&nbsp;
                  <Link
                    to={STARTUP_FLOWLINK_MANAGER_ROUTE}
                    className="hyperlink underline"
                  >
                    FlowLink manager.
                  </Link>
                </li>
              )}
            </ul>
          }
          isShown
          onClose={() => false}
        />
        <TabsHeader tabs={tabs} />
      </header>
      <section className="my-4">
        {isLoading && (
          <PageLoadingSpinner message="Gathering your biggest supporters... 🙌" />
        )}

        {!isLoading && investorAccessData && (
          <>
            <Routes>
              {['', tabs[0].name].map((path) => (
                <Route
                  key={path}
                  path={toDashCase(path)}
                  element={
                    <AccessManagerViewSessions
                      apiResponse={viewSessionData}
                      handleGetStartupViewSessionsCsv={
                        handleGetStartupViewSessionsCsv
                      }
                      isSearchLoading={isSearchLoading}
                      stageOptions={stageOptions}
                      handleFilter={handleFilterDealViewSessions}
                      handleFilterByRoundStage={
                        handleFilterDealViewSessionsByRoundStage
                      }
                      fieldFilters={fieldFilters}
                      fetchStartupAccessManager={fetchViewSessionAccessData}
                    />
                  }
                />
              ))}
              <Route
                path={toDashCase(tabs[1].name)}
                element={
                  <AccessManagerInvestors
                    handleGetInvestorsEngagedCsv={handleGetInvestorsEngagedCsv}
                    apiResponse={investorAccessData}
                    isSearchLoading={isSearchLoading}
                    stageOptions={stageOptions}
                    handleFilter={handleFilterInvestorAccess}
                    handleFilterByRoundStage={
                      handleFilterInvestorAccessByRoundStage
                    }
                    fieldFilters={fieldFilters}
                    fetchStartupAccessManager={fetchStartupAccessManager}
                  />
                }
              />
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          </>
        )}
      </section>
    </main>
  );
}

export default AccessManagerRoute;
