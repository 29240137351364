import React, { useCallback } from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import {
  formatImageAddress,
  shortenWebUrl,
} from '/src/util/formatting/strings';
import {
  HiddenFitScore,
  generateFitScoreTag,
  generateTag,
} from '/src/util/generateTagCloud';
import { dayDiff, formatDaysUntil } from '/src/util/formatting/dates';
import { roundIsInGracePeriod } from '/src/util/rounds';
import { isMinDate } from '/src/util/time';
import { ScreeningDealSummaryView } from '/../libs/shared-types/src/types/view/AggregatedDeals';
import { abbreviateNumber } from '/src/util/formatting/numbers';
import { calculateAmountRemaining } from '/../libs/shared-types/src/util/RoundCalculations';
import DateString from '/src/components/utility/DateString';
import CopyTextButton from '/src/components/CopyTextButton';
import Tooltip from '/src/components/utility/Tooltip';
import SquaredLogo from '/src/components/SquaredLogo';

interface ScreeningDealTableRowProps {
  deal: ScreeningDealSummaryView;
  onAcceptDeal: (dealId: string) => void;
  onPassDeal: (deal: ScreeningDealSummaryView) => void;
  hasFitScoreAccess?: boolean;
}

function ScreeningDealTableRow({
  deal,
  onAcceptDeal,
  onPassDeal,
  hasFitScoreAccess = false,
}: ScreeningDealTableRowProps): JSX.Element {
  const navigate = useNavigate();
  const handleOnClick = useCallback(
    () => navigate(`/app/deal-detail/${deal.startupId}`),
    [history],
  );

  function handlePassButtonClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void {
    event.stopPropagation();
    onPassDeal(deal);
  }

  function handleAcceptButtonClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void {
    event.stopPropagation();
    onAcceptDeal(deal.startupId);
  }
  const amountRemaining = calculateAmountRemaining(
    deal.currentRound?.raiseTarget ?? 0,
    deal.currentRound?.raisedAmount ?? 0,
  );

  return (
    <tr
      onClick={handleOnClick}
      className="cursor-pointer hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
    >
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <SquaredLogo
              containerClassName="size-10"
              src={formatImageAddress(deal.startupLogoKey)}
              alt={`${deal.startupName} logo`}
            />
          </div>
          <div className="ml-2 max-w-[160px]">
            <div className="app-table-row-text--bold">{deal.startupName}</div>
            <div className="flex w-full text-sm text-gray-500">
              <a
                className="block truncate hover:underline"
                target="_blank"
                rel="noopener noreferrer"
                href={deal.website}
                onClick={(e) => e.stopPropagation()}
              >
                {shortenWebUrl(deal.website)}
              </a>
              <CopyTextButton text={deal.website} className="ml-1" />
            </div>
          </div>
        </div>
      </td>
      <td className="app-table-row-item">{generateTag(deal.currentStage)}</td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">{deal.sharedByName}</div>

        <div className="app-table-row-text--light">
          <DateString date={deal.sharedByDate} tooltipPosition="bottom" />
        </div>
      </td>
      <td className="app-table-row-item break-words">
        <div className="app-table-row-text">{deal.message ?? ''}</div>
      </td>
      <td className="app-table-row-item">
        {generateTag(deal.isCurrentRoundOpen ? 'Open' : 'Closed', true)}
        {deal.isCurrentRoundOpen &&
          !isMinDate(deal.currentRound?.estimatedCloseDate) && (
            <div className="app-table-row-text--light">
              Closing&nbsp;
              {Math.abs(dayDiff(deal.currentRound?.estimatedCloseDate)) > 1 &&
                'in '}
              {formatDaysUntil(deal.currentRound?.estimatedCloseDate)}
            </div>
          )}
        {!deal.isCurrentRoundOpen &&
          deal.currentRound &&
          roundIsInGracePeriod(deal.currentRound) && (
            <div className="app-table-row-text--light flex">
              Action Required
              <Tooltip
                tooltipText={
                  <div className="text-sm font-normal">
                    <p className="text-base font-medium leading-6">
                      The founder has just closed their{' '}
                      {deal?.currentRound?.roundStage} round.
                    </p>
                    <p className="my-2">
                      You must decide on whether to pass or invest by&nbsp;
                      <span className="font-medium">
                        {deal?.currentRound?.gracePeriodEndsOn &&
                          deal?.currentRound?.gracePeriodEndsOn.toLocaleString()}
                        .
                      </span>
                    </p>
                    <p>
                      If you do not take any action Flowlie will automatically
                      pass the deal for you.
                    </p>
                  </div>
                }
              />
            </div>
          )}
      </td>

      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {deal.isCurrentRoundOpen &&
          deal.currentRound?.raiseTarget !== undefined
            ? `$${abbreviateNumber(deal.currentRound?.raiseTarget)}`
            : '-'}
          {deal.isCurrentRoundOpen &&
            deal.currentRound?.raiseTarget !== undefined && (
              <div className="app-table-row-text--light">
                {amountRemaining >= 0
                  ? `$${abbreviateNumber(amountRemaining)} Remaining`
                  : 'Oversubscribed'}
              </div>
            )}
        </div>
      </td>
      <td
        className="app-table-row-item"
        title={
          deal.hasEverBeenPassed
            ? 'You have already passed this deal at a previous round'
            : 'This is the first time you received this deal'
        }
      >
        {generateTag(deal.hasEverBeenPassed ? 'Previously Passed' : 'New')}
      </td>
      <td className="app-table-row-item">
        {hasFitScoreAccess ? (
          generateFitScoreTag(deal.fitScore)
        ) : (
          <HiddenFitScore />
        )}
      </td>
      <td className="whitespace-normal px-3 py-3 text-right">
        <span className="relative inline-flex rounded-md shadow-sm">
          <div className="tooltip-wrapper">
            <span className="tooltip tooltip-top w-max">Pass Deal</span>
            <button
              type="button"
              onClick={handlePassButtonClick}
              className="relative -ml-px inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
            >
              <span className="sr-only">Pass Deal</span>
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>

          <div className="tooltip-wrapper">
            <span className="tooltip tooltip-top w-max">Accept Deal</span>
            <button
              type="button"
              onClick={handleAcceptButtonClick}
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
            >
              <span className="sr-only">Accept Deal</span>
              <CheckIcon className="h-5 w-5" />
            </button>
          </div>
        </span>
      </td>
    </tr>
  );
}

export default ScreeningDealTableRow;
