import React, { useContext, useEffect, useRef, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';

import { Link } from 'react-router-dom';
import { LOGIN_ROUTE, SIGNUP_ROUTE } from '/src/constants/Routes';
import Alert from '/src/components/notifications/Alert';
import AUTH_FIELD_MAPPING from './AuthFormFieldMap';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import AuthFormTypes from '/src/enums/AuthFormTypes';
import DebugRender from '/src/components/utility/DebugRender';
import FormField from '/src/interfaces/FormField';
import GoogleComponent from '/src/components/GoogleAuth';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Logger from '/src/services/logger';
import AccountTypeForm from './AccountTypeForm';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';
import ReCAPTCHA from 'react-google-recaptcha';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { localStorageGetDebugMode } from '/src/middleware/LocalStorage';

const REACT_APP_RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY ?? '';

interface AuthFormProps {
  authenticateOnSubmit: (formValues: any) => void;
  formType: AuthFormTypes;
  showUrlRedirectLinks?: boolean;
  preFilledValues?: any;
}

/**
 * This component is used to render the form at Log In or Sign Up pages
 * @param param Configuration parameters
 * @returns A form that allows to Log In or Sign Up
 */
function AuthForm({
  authenticateOnSubmit,
  formType,
  showUrlRedirectLinks = true,
  preFilledValues,
}: AuthFormProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState('');
  const [submitText, setSubmitText] = useState('');
  const { unconfirmedAccountType, setUnconfirmedAccountType } = useContext(
    AuthenticationContext,
  );
  const [isContinuingWithEmail, setIsContinuingWithEmail] = useState(false);
  const [fields, setFields] = useState<FormField[]>([]);
  const [initialValues, setInitialValues] = useState({});
  const toggleAlert = () => setErrorMessage('');
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  function configureForm(type: AuthFormTypes): void {
    switch (type) {
      case AuthFormTypes.Login: {
        setSubmitText('Log In');
        const emptyForm = {
          [AuthFieldNames.Email]: preFilledValues
            ? preFilledValues[AuthFieldNames.Email]
            : '',
          [AuthFieldNames.Password]: '',
        };
        setInitialValues(emptyForm);
        const lognInFields = [
          AUTH_FIELD_MAPPING[AuthFieldNames.Email],
          AUTH_FIELD_MAPPING[AuthFieldNames.Password],
        ];
        setFields(lognInFields);
        break;
      }
      case AuthFormTypes.Signup: {
        setSubmitText('Sign Up');
        const emptyForm = {
          [AuthFieldNames.FirstName]: preFilledValues
            ? preFilledValues[AuthFieldNames.FirstName]
            : '',
          [AuthFieldNames.LastName]: preFilledValues
            ? preFilledValues[AuthFieldNames.LastName]
            : '',
          [AuthFieldNames.Email]: preFilledValues
            ? preFilledValues[AuthFieldNames.Email]
            : '',
          [AuthFieldNames.PasswordCreate]: '',
          [AuthFieldNames.AcceptLegal]: false,
        };
        setInitialValues(emptyForm);
        const signUpFields = [
          AUTH_FIELD_MAPPING[AuthFieldNames.FirstName],
          AUTH_FIELD_MAPPING[AuthFieldNames.LastName],
          AUTH_FIELD_MAPPING[AuthFieldNames.Email],
          AUTH_FIELD_MAPPING[AuthFieldNames.PasswordCreate],
          AUTH_FIELD_MAPPING[AuthFieldNames.AcceptLegal],
        ];
        setFields(signUpFields);
        break;
      }
      default: {
        break;
      }
    }
  }

  async function onSubmit(
    inputValues: any,
    { setSubmitting }: FormikHelpers<any>,
  ): Promise<void> {
    try {
      setSubmitting(true);

      if (formType === AuthFormTypes.Signup) {
        if (reCaptchaRef && reCaptchaRef.current) {
          const token = await reCaptchaRef.current.executeAsync();
          reCaptchaRef.current.reset();
          inputValues[AuthFieldNames.ReCaptchaToken] = token;
        }
      }
      await authenticateOnSubmit(inputValues);
    } catch (error: any) {
      Logger.error(error);
      setErrorMessage(error);
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    configureForm(formType);
  }, []);

  if (fields.length === 0) {
    return <></>;
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={fields
        .map((field) => field?.validation)
        .reduce((merged, schema) => merged.concat(schema))}
      onSubmit={onSubmit}
    >
      {({ values, errors, isSubmitting, isValid, dirty }) => (
        <Form className="flex w-full flex-col">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div className="min-h-[200px] min-w-[22rem]">
              {formType === AuthFormTypes.Signup && !unconfirmedAccountType && (
                <div className="mb-10 mt-8">
                  <AccountTypeForm
                    setUnconfirmedAccountType={setUnconfirmedAccountType}
                  />
                </div>
              )}

              {(formType !== AuthFormTypes.Signup ||
                (formType === AuthFormTypes.Signup &&
                  unconfirmedAccountType)) && (
                <section>
                  {unconfirmedAccountType &&
                    formType === AuthFormTypes.Signup && (
                      <div className="absolute left-4 top-4">
                        <button
                          type="button"
                          className="flex items-center text-sm font-medium text-gray-600 hover:text-gray-800"
                          onClick={() => {
                            setUnconfirmedAccountType(undefined);
                            setIsContinuingWithEmail(false);
                          }}
                        >
                          <ArrowLeftIcon className="mr-1.5 h-4 w-4" />
                          Back
                        </button>
                      </div>
                    )}

                  {!isContinuingWithEmail && (
                    <div className="flex flex-col space-y-4">
                      <GoogleComponent />
                      <div className="flex justify-center text-sm text-gray-900">
                        Or continue with
                      </div>
                      {formType === AuthFormTypes.Signup && (
                        <>
                          <button
                            onClick={() => setIsContinuingWithEmail(true)}
                            className="flex w-full items-center justify-center space-x-3 rounded-md border bg-white px-3 py-2 text-gray-800 shadow hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
                          >
                            <span className="text-sm font-semibold leading-6">
                              Email & Password
                            </span>
                          </button>
                        </>
                      )}
                    </div>
                  )}

                  {(isContinuingWithEmail ||
                    formType === AuthFormTypes.Login) &&
                    fields.map((field) => (
                      <div key={fields.indexOf(field)}>
                        {field.fieldComponent}
                      </div>
                    ))}

                  {(isContinuingWithEmail ||
                    formType === AuthFormTypes.Login) && (
                    <div className="mt-4 grid justify-items-center">
                      <button
                        className="button btn-primary min-w-full"
                        type="submit"
                        disabled={
                          isSubmitting ||
                          (formType === AuthFormTypes.Signup &&
                            (!isValid || !dirty))
                        }
                      >
                        {submitText}
                        {isSubmitting && (
                          <div className="ml-1">
                            <LoadingSpinner color="white" />
                          </div>
                        )}
                      </button>
                    </div>
                  )}

                  {formType === AuthFormTypes.Signup &&
                    !localStorageGetDebugMode() && (
                      <ReCAPTCHA
                        sitekey={REACT_APP_RECAPTCHA_KEY}
                        ref={reCaptchaRef}
                        size="invisible"
                        badge="bottomright"
                      />
                    )}

                  <div className="my-2">
                    <Alert
                      color="red"
                      alertType="Error"
                      content={errorMessage}
                      isShown={errorMessage !== ''}
                      onClose={toggleAlert}
                    />
                  </div>
                </section>
              )}

              {showUrlRedirectLinks && formType === AuthFormTypes.Login && (
                <div className="mt-5 flex-none text-center text-sm font-medium">
                  First time here?&nbsp;
                  <Link className="hyperlink" to={SIGNUP_ROUTE}>
                    Create an Account
                  </Link>
                </div>
              )}

              {showUrlRedirectLinks && formType === AuthFormTypes.Signup && (
                <div className="mt-5 min-w-full flex-none text-center text-sm font-medium">
                  Already have an account?&nbsp;
                  <Link className="hyperlink" to={LOGIN_ROUTE}>
                    Log In
                  </Link>
                </div>
              )}
            </div>
          </div>

          <DebugRender className="mb-4">
            <pre>Cannot submit if any of the following is true:</pre>
            {/* <pre>{`is not Valid: ${!isValid}`}</pre> */}
            <pre>{`isSubmitting: ${isSubmitting}`}</pre>
            {/* <pre>{`not modified yet: ${!dirty}`}</pre> */}
          </DebugRender>
          <DebugRender>
            <pre>Formik Values:</pre>
            <pre className="mb-8">{JSON.stringify(values, null, 2)}</pre>
            <pre>Formik Errors:</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
          </DebugRender>
        </Form>
      )}
    </Formik>
  );
}

export default AuthForm;
