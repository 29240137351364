import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import { IMPACT_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

const ImpactsField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      isClearable
      isMulti
      label="Which of the following apply to you?"
      name={StartupFieldNames.Impacts}
      options={IMPACT_OPTIONS}
      placeholder="Select option..."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.Impacts]: yup
      .array()
      .of(yup.string())
      .test(
        'notApplicableOnly',
        `"${IMPACT_OPTIONS[0].value}" cannot be combined with other options`,
        (impacts): boolean => {
          if (impacts?.includes(IMPACT_OPTIONS[0].value)) {
            // Return false to indicate the test failed
            return impacts.length === 1;
          }
          return true;
        },
      )
      .min(1, 'At least one is required')
      .required('This field is required'),
  }),
};

export default ImpactsField;
