import React from 'react';

interface ListSummaryCardProps {
  headerTitle?: string;
  dataObj: { [key: string]: any };
  classElm?: { [key: string]: string };
  headerData?: string;
  children?: JSX.Element | null;
  notificationObj?: { [key: string]: string | boolean | number };
}

/* Reusable component that displays a list, shows notification, or both */
function ListSummaryCard({
  headerTitle = '',
  dataObj = {},
  classElm = {},
  headerData = '',
  children,
  notificationObj = {},
}: ListSummaryCardProps): JSX.Element | null {
  const dataObjKeysArray =
    dataObj && typeof dataObj === 'object' ? Object.keys(dataObj) : [];
  const {
    title = '',
    headerContainer = '',
    bodyContainer = '',
    keyElms = '',
    valElm = '',
    elmContainer = '',
    notificationContainer = '',
    notificationLabel = '',
  } = classElm;
  const { notificationLabelText = '', showNotification = false } =
    notificationObj;

  if (!(dataObjKeysArray.length > 0) && !showNotification) return null;

  return (
    <>
      {(headerTitle || headerData) && (
        <div
          className={`border-gray-200 bg-white px-4 
      py-5 sm:px-6 ${headerContainer}`}
        >
          {headerTitle && <h3 className={title}>{headerTitle}</h3>}
          {children}
        </div>
      )}
      {showNotification ? (
        <div
          className={`border-b border-gray-200 bg-white 
      px-4 py-5 ${notificationContainer}`}
        >
          <div className={notificationLabel}>{notificationLabelText}</div>
        </div>
      ) : (
        <>
          <div
            className={`h-auto space-y-4 border-b border-gray-200 bg-white
          px-6 py-5 sm:px-6 ${bodyContainer}`}
          >
            {dataObjKeysArray.map(
              (keyObj) =>
                dataObj &&
                keyObj &&
                dataObj[keyObj] && (
                  <div
                    key={keyObj}
                    className={`flex justify-between align-middle ${elmContainer}`}
                  >
                    <span className={keyElms}>{`${keyObj}`}</span>
                    <span className={valElm}>{dataObj[keyObj]}</span>
                  </div>
                )
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ListSummaryCard;
