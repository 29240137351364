import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { LinkIcon } from '@heroicons/react/20/solid';
import generateSocialHandles from '/src/util/generateSocialHandles';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { SocialHandle } from '/../libs/shared-types/src/types/model/SocialHandle';
import { localStorageGetAuthenticatedAccountType } from '/src/middleware/LocalStorage';
import { generateTagCloud } from '/src/util/generateTagCloud';
import Alert from '/src/components/notifications/Alert';
import {
  AngelList,
  LinkedIn,
  Medium,
  Substack,
  Twitter,
} from '/src/components/CustomIcons';
import CopyTextButton from '/src/components/CopyTextButton';
import { PublicInvestor } from '/../libs/shared-types/src/types/view/investor/PublicInvestor';
import { InternalInvestor } from '/../libs/shared-types/src/types/view/investor/InternalInvestor';
import { ContactPreferences } from '/../libs/shared-types/src/constants/ContactPreferences';

export const TAGS = {
  OpenToColdInbound: (
    <span
      key="Open"
      className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
    >
      <svg
        className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400"
        fill="currentColor"
        viewBox="0 0 8 8"
      >
        <circle cx={4} cy={4} r={3} />
      </svg>
      Open to Cold Outreach
    </span>
  ),
  NotOpenToColdInbound: (
    <span
      key="ClosingSoon"
      title="The founder estimates they will be closing the round within a week."
      className="inline-flex items-center rounded-full bg-orange-100 px-2.5 py-0.5 text-xs font-medium text-orange-800"
    >
      Not Open to Cold Outreach
    </span>
  ),
};

function getInvestorSocialHandles(
  angelList: string | undefined,
  linkedIn: string | undefined,
  otherUrl: string | undefined,
  twitter: string | undefined,
  medium: string | undefined,
  substack: string | undefined,
): SocialHandle[] {
  const socialHandles: SocialHandle[] = [];
  if (angelList) {
    socialHandles.push({
      icon: <AngelList />,
      name: 'AngelList',
      url: angelList,
    });
  }
  if (linkedIn) {
    socialHandles.push({ icon: <LinkedIn />, name: 'LinkedIn', url: linkedIn });
  }
  if (medium) {
    socialHandles.push({ icon: <Medium />, name: 'Medium', url: medium });
  }
  if (substack) {
    socialHandles.push({ icon: <Substack />, name: 'Substack', url: substack });
  }
  if (twitter) {
    socialHandles.push({ icon: <Twitter />, name: 'Twitter', url: twitter });
  }
  if (otherUrl) {
    socialHandles.push({
      icon: <LinkIcon className="hyperlink h-6 w-5" />,
      name: 'Other',
      url: otherUrl,
    });
  }
  return socialHandles;
}

interface InvestorDetailContactCardProps {
  investor: InternalInvestor | PublicInvestor;
  isConnected: boolean;
}

function InvestorDetailContactCard({
  investor,
  isConnected,
}: InvestorDetailContactCardProps): JSX.Element {
  const socialHandles = getInvestorSocialHandles(
    investor.angelList,
    investor.linkedIn,
    investor.otherUrl,
    investor.twitter,
    investor.medium,
    investor.substack,
  );

  return (
    <section>
      <div className="rounded-lg bg-white shadow">
        <div className="flex items-center justify-between px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Contact
          </h3>
          {investor.isOpenToColdInbound
            ? TAGS.OpenToColdInbound
            : TAGS.NotOpenToColdInbound}
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 xl:grid-cols-2">
            {investor.preferenceContact &&
              investor.preferenceContact.length > 0 && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Contact Preference
                  </dt>
                  <dd className="mt-1 text-sm">
                    {generateTagCloud(investor.preferenceContact)}
                  </dd>
                </div>
              )}
            {investor.preferenceFollowUp !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Follow Up Preference
                </dt>
                <dd className="mt-1 text-sm">
                  {investor.preferenceFollowUp
                    ? 'Yes, please follow up'
                    : 'No, will get back to you'}
                </dd>
              </div>
            )}
            {investor.email &&
              (investor.preferenceContact?.includes(ContactPreferences.Email) ||
                isConnected) && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="hyperlink mt-1 flex items-center text-sm">
                    <a className="truncate" href={`mailto:${investor.email}`}>
                      {investor.email}
                    </a>
                    <CopyTextButton text={investor.email} className="ml-2" />
                  </dd>
                </div>
              )}
            {isConnected && investor.phone && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Phone</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {
                    // When every user on prod has migrated to the international phone number format
                    // we can remove this conditional logic
                    investor.phone && investor.phone[0] === '+'
                      ? formatPhoneNumberIntl(investor.phone)
                      : investor.phone // This is for backward compatibility
                  }
                </dd>
              </div>
            )}
            {investor.website && (
              <div className="sm:col-span-2 2xl:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Website</dt>
                <dd className="hyperlink mt-1 text-sm">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={investor.website}
                  >
                    {investor.website}
                  </a>
                </dd>
              </div>
            )}
            {socialHandles.length > 0 && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Social Handles
                </dt>
                <span className="mt-1 flex flex-wrap space-x-2">
                  {generateSocialHandles(socialHandles)}
                </span>
              </div>
            )}
            {localStorageGetAuthenticatedAccountType() ===
              AccountTypes.Startup && (
              <div className="col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  <Alert
                    alertType="Warning"
                    canDismiss={false}
                    color="yellow"
                    content={
                      investor.isOpenToColdInbound
                        ? `Before reaching out cold to ${investor.firstName}, please review their Fit Score, investment industries, investment stages, check size, and geographic constraints. You will have a much higher chance of success if your outreach is targeted and you include your FlowLink as part of the message.`
                        : `This investor is not open to receiving cold inbound from founders and chose not to share their contact information publicly. Your best next step is to find a person who knows ${investor.firstName} and can provide a warm introduction to them. Remember that you will have a much higher chance of success if your outreach is targeted.`
                    }
                    isShown
                    onClose={() => false}
                  />
                </dt>
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
}

export default InvestorDetailContactCard;
