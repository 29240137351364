import React from 'react';
import * as yup from 'yup';
import FormikInput from '/src/components/inputs/FormikInput';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import FormField from '/src/interfaces/FormField';
import { Field } from 'formik';
import { Link } from 'react-router-dom';
import { FORGOT_PASSWORD_ROUTE } from '/src/constants/Routes';

function FieldWrapper(): JSX.Element {
  return (
    <div className="relative">
      <Field
        component={FormikInput}
        label="Password"
        name={AuthFieldNames.Password}
        type="password"
      />

      <div className="absolute bottom-0 text-xs">
        <Link className="hyperlink font-medium" to={FORGOT_PASSWORD_ROUTE}>
          Forgot your password?
        </Link>
      </div>
    </div>
  );
}

/**
 * This field is used when a password must be enetered to authenticate,
 * such as at Log In
 */
const PasswordField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [AuthFieldNames.Password]: yup.string().required('Required'),
  }),
};

export default PasswordField;
