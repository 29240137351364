import React from 'react';
import * as yup from 'yup';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import BooleanSelectInput from '/src/components/inputs/BooleanSelectInput';

const HasPreviousConvictionsField: FormField = {
  fieldComponent: (
    <BooleanSelectInput
      label="Have you ever been convicted for breaking international, federal, state, or local law?"
      name={StartupFieldNames.HasPreviousConvictions}
      isClearable
    />
  ),
  validation: yup.object({
    [StartupFieldNames.HasPreviousConvictions]: yup.boolean().nullable(),
  }),
};

export default HasPreviousConvictionsField;
