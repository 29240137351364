import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import websiteSchema from '/src/constants/validation/websiteSchema';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';

const AngelListField: FormField = {
  fieldComponent: (
    <Field
      component={FormikInput}
      label="AngelList URL"
      name={InvestorFieldNames.AngelList}
      type="text"
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.AngelList]: websiteSchema(false),
  }),
};

export default AngelListField;
