import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import FormikInput from '/src/components/inputs/FormikInput';
import { ROLE_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';

// This field is a copy of FounderFieldArray.Role
// It is used for covenience at Complete Profile
const FounderRoleField: FormField = {
  fieldComponent: (
    <Field
      component={FormikInput}
      label="What is your role?"
      name={StartupFieldNames.FounderRole}
      type="text"
    />
  ),
  validation: yup.object({
    [StartupFieldNames.FounderRole]: yup
      .string()
      .max(ROLE_LENGTH_MAX, `Must be at most ${ROLE_LENGTH_MAX} characters`)
      .required('Required'),
  }),
};

export default FounderRoleField;
