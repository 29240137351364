import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import MailToLink from '../../utility/MailToLink';

const EmailField: FormField = {
  fieldComponent: (
    <Field
      component={FormikInput}
      label="Email"
      secondaryLabel="Your email will be visible to other investors you connect with on Flowlie and to the founders whose companies you access."
      name={InvestorFieldNames.Email}
      type="email"
      disabled
      tooltip={
        <p>
          This field is locked, please contact{' '}
          <MailToLink
            linkText="support@flowlie.com"
            params={{
              to: 'support@flowlie.com',
              subject: 'I need to update my Account Email',
            }}
            className="underline hover:text-blue-400"
          />{' '}
          if you need to update it
        </p>
      }
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.Email]: yup
      .string()
      .max(255, 'Must be at most 255 characters')
      .email('A valid email is required')
      .required('Required'),
  }),
};

export default EmailField;
