import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BILLING_ROUTE } from '../constants/Routes';
import { LockOpenIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import { AccountMetadataContext } from '../contexts/AccountMetadataContext';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';

interface SubscriptionCTAPillProps {
  text?: string;
  tooltipText?: string;
  id: string;
}

export function SubscriptionCTAPill({
  text = 'Upgrade to Pro',
  tooltipText,
  id,
}: SubscriptionCTAPillProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const mainContent = (
    <div onFocus={() => null} title={tooltipText} id={id}>
      <Link to={BILLING_ROUTE} onClick={(event) => event.stopPropagation()}>
        <div className="min-w-max rounded-2xl bg-purple-700">
          <div className="group relative flex items-center justify-center rounded-2xl bg-white/5 py-1 px-3 text-center text-sm text-white shadow-xl">
            <LockClosedIcon className="mr-1 h-4 w-4 group-hover:hidden" />
            <LockOpenIcon className="mr-1 hidden h-4 w-4 group-hover:block" />
            <p>{text}</p>
            <svg
              className="mt-0 ml-2 -mr-1 stroke-white stroke-2"
              fill="none"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              aria-hidden="true"
            >
              <path
                className="opacity-0 transition group-hover:opacity-100"
                d="M0 5h7"
              ></path>
              <path
                className="transition group-hover:translate-x-[3px]"
                d="M1 1l4 4-4 4"
              ></path>
            </svg>
          </div>
        </div>
      </Link>
    </div>
  );
  return (
    ((subscriptionTier === SubscriptionTiers.InvestorFree ||
      subscriptionTier === SubscriptionTiers.StartupFree) &&
      mainContent) || <></>
  );
}
