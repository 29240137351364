import React from 'react';
import * as yup from 'yup';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import { STARTUP_NAME_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import FormField from '/src/interfaces/FormField';
import TextInput from '../../inputs/TextInput';
import { ENTITY_TYPE_UNINCORPORATED } from '/../libs/shared-types/src/constants/SelectOptions/EntityTypeOptions';
import { useFormikContext } from 'formik';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.LegalEntityType] &&
    values[StartupFieldNames.LegalEntityType] !== ENTITY_TYPE_UNINCORPORATED ? (
    <TextInput
      label="Company's Legal Name"
      name={StartupFieldNames.LegalName}
      placeholder="Company name as it appears on legal docs"
      secondaryLabel="This is your full legal company name, not the marketing or DBA name"
    />
  ) : (
    <></>
  );
}

const LegalNameField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.LegalName]: yup
      .string()
      .max(
        STARTUP_NAME_LENGTH_MAX,
        `Must be at most ${STARTUP_NAME_LENGTH_MAX} characters`,
      )
      .when(StartupFieldNames.LegalEntityType, {
        is: (entityType: string) => entityType !== ENTITY_TYPE_UNINCORPORATED,
        then: (schema) => schema.required('Required'),
      }),
  }),
};

export default LegalNameField;
