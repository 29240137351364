import { FormikValues } from 'formik';
import {
  CognitoUploadPublic,
  InvestorUpdate,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { Investor } from '/../libs/shared-types/src/types/model/Investor';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import API from '/src/middleware/API';
import { updateHeapInvestorUser } from '/src/middleware/Heap';
import { Posthog } from '/src/middleware/Posthog';
import { uploadFile } from '/src/util/FileHelpers';
import { removeUnsetOptionalValues } from '/src/util/forms';

export async function updateInvestor(
  formValues: FormikValues
): Promise<string> {
  const updatedData = removeUnsetOptionalValues(formValues);

  const newProfilePicKey = await uploadFile(
    updatedData[InvestorFieldNames.ProfilePic],
    CognitoUploadPublic.buildEndpoint()
  );
  if (newProfilePicKey) {
    updatedData[InvestorFieldNames.ProfilePicKey] = newProfilePicKey;
  }

  // Build the Investor object that must be posted
  const investorProfile: Investor = {
    ...updatedData,
  };

  try {
    await API.put(InvestorUpdate.buildEndpoint(), investorProfile);
    const posthogClient = new Posthog();
    posthogClient.addUserProperties({
      company_name: investorProfile.firm,
      sub_type: Posthog.getInvestorSubType(investorProfile.type),
    });
    updateHeapInvestorUser(investorProfile);
    return await Promise.resolve('Success');
  } catch (error: any) {
    throw Error(error.message);
  }
}
