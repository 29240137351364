import React from 'react';

import { generateTag, StrengthTag } from '/src/util/generateTagCloud';
import { formatDate, howLongBetween } from '/src/util/formatting/dates';
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/20/solid';
import { InvestorActivityTabView } from '../../../../types/view/InvestorActivityTabView';
import { RELATIONSHIP_STRENGTH_OPTIONS } from '/../libs/shared-types/src/constants/Strength';
import EmptyState from '/src/components/notifications/EmptyState';

interface InvestorDetailRelationshipCardProps {
  relationship: InvestorActivityTabView['relationship'];
  updateRelationship: () => void;
}

function InvestorDetailRelationshipCard({
  relationship,
  updateRelationship,
}: InvestorDetailRelationshipCardProps): JSX.Element {
  const isEmpty =
    relationship &&
    !relationship.connectedOn &&
    !relationship.lastContactedOn &&
    !relationship.strength &&
    relationship.isIntroNeeded === undefined;

  if (!relationship || isEmpty) {
    return (
      <EmptyState
        title="You have not set any relationship information about this investor"
        subTitle=""
        icon={<></>}
        actionButton={
          <button
            type="button"
            className="app-button--primary"
            onClick={updateRelationship}
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Update relationship
          </button>
        }
      />
    );
  }

  return (
    <section>
      <div className="rounded-lg bg-white shadow">
        <div className="flex items-center justify-between px-4 py-5 sm:px-6">
          <div className="flex flex-col">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Relationship Summary
            </h3>
            <span className="text-sm text-gray-500">
              Last updated on {formatDate(relationship.updatedOn)}
            </span>
          </div>

          <button
            className="app-button--neutral flex items-center"
            onClick={updateRelationship}
          >
            <PencilSquareIcon className="mr-2 size-[1rem]" aria-hidden="true" />
            <span>Update</span>
          </button>
        </div>

        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            {relationship.connectedOn && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Connection since
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {howLongBetween(relationship.connectedOn)}
                </dd>
              </div>
            )}

            {relationship.lastContactedOn && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Last contact
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {howLongBetween(relationship.lastContactedOn)}
                </dd>
              </div>
            )}

            {relationship.strength && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Relationship
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <StrengthTag
                    strength={relationship.strength}
                    options={RELATIONSHIP_STRENGTH_OPTIONS}
                  />
                </dd>
              </div>
            )}

            {relationship.isIntroNeeded !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Intro Needed
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {generateTag(relationship.isIntroNeeded ? 'Yes' : 'No')}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
}

export default InvestorDetailRelationshipCard;
