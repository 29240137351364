import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import FormikInput from '/src/components/inputs/FormikInput';
import { formatCurrentDate } from '/src/util/formatting/dates';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.EstimatedCloseDate, values) ? (
    <Field
      component={FormikInput}
      disabled={values[RoundFieldNames.IsEditable] === 'false'}
      label="When are you planning to close this round by?"
      name="estimatedCloseDate"
      type="date"
      min={formatCurrentDate()}
      placeholder="mm/dd/yyyy"
      secondaryLabel="An estimate is ok. This gives investors an idea of how near you are to closing the round."
    />
  ) : (
    <></>
  );
}

const EstimatedCloseDateField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.EstimatedCloseDate]: yup
      .date()
      .typeError('Please provide an Estimated Close Date')
      .min(new Date(), 'Must be after today')
      .required('Required'),
  }),
};

export default EstimatedCloseDateField;
