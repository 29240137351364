import React, { useEffect, useState } from 'react';

import { GRACE_PERIOD } from '/../libs/shared-types/src/constants/time';
import { roundUpToCronInterval } from '/src/util/time';
import { Round } from '/../libs/shared-types/src/types/model/Round';
import API from '/src/middleware/API';
import DateString from '/src/components/utility/DateString';
import Logger from '/src/services/logger';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import Alert from '/src/components/notifications/Alert';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import ActiveRound from './ActiveRound';
import { buildRound } from './BuildRound';
import { emptyRound } from './emptyRound';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import {
  StartupCloseRound,
  StartupGet,
  StartupGetCurrentRound,
  StartupGetRoundHistory,
  StartupNewRound,
  StartupUpdateRound,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import EmptyState from '/src/components/notifications/EmptyState';
import { FolderPlusIcon, PlusIcon } from '@heroicons/react/24/outline';
import OpenNewRoundDialog from './OpenNewRoundDialog';
import { PrivateStartup } from '/../libs/shared-types/src/types/view/startup/PrivateStartup';

function getCloseRoundMessage(deadline: Date): JSX.Element {
  return (
    <div className="my-6 space-y-4">
      <p>
        Please review and update your round data before closing the round.{' '}
        <span className="font-medium">
          You will not be able to make changes after you close the round.
        </span>
      </p>
      <p>
        All investors who received your company via Flowlie at the current round
        will be forced to make a decision on whether to pass or invest by&nbsp;
        <span className="font-medium">{deadline.toLocaleString()}.</span>
      </p>
      <p>
        If they do not take any action Flowlie will automatically pass the deal
        for them.
      </p>
      <p>
        You should close a round only when you have concluded your raise and
        received the full investment amount from all investors.
      </p>
    </div>
  );
}

interface CurrentRoundManagerProps {
  onRoundUpdated: () => void;
}
function CurrentRoundManager({
  onRoundUpdated,
}: CurrentRoundManagerProps): JSX.Element {
  const [currentRound, setCurrentRound] = useState<RoundView | undefined>();
  const [startup, setStartup] = useState<PrivateStartup>();
  const [roundHistory, setRoundHistory] = useState<RoundView[]>([]);
  const [confirmCloseRoundModalOpen, setConfirmCloseRoundModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState<boolean>(false);
  const [isEditingRound, setIsEditingRound] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  function toggleConfirmCloseRoundModal() {
    setConfirmCloseRoundModalOpen(!confirmCloseRoundModalOpen);
    setErrorMessage('');
  }

  const createNewRound = async (values: Round) => {
    const cleanRound = buildRound(values);
    try {
      await API.post(StartupNewRound.buildEndpoint(), cleanRound);
      onRoundUpdated();
    } catch (error: any) {
      Logger.error(error);
    }
  };

  const updateRound = async (values: Round) => {
    const cleanRound = buildRound(values);

    try {
      await API.put(StartupUpdateRound.buildEndpoint(), cleanRound);
      // Re-fetch to refresh UI and reset form state
      // Not the best... can we avoid this making another request?
      await fetchActiveRound();
      onRoundUpdated();
    } catch (error: any) {
      Logger.error(error);
      return error;
    }
  };

  const fetchActiveRound = async () => {
    try {
      const round: RoundView = await API.get(
        StartupGetCurrentRound.buildEndpoint(),
      );
      if (round === null) {
        return;
      }

      setCurrentRound(round);

      // Show alert if the round is in grace period
      setIsAlertShown(round.isInGracePeriod);
    } catch (error: any) {
      Logger.error(error);
    }
  };

  const fetchRoundHistory = async () => {
    try {
      const result: RoundView[] = await API.get(
        StartupGetRoundHistory.buildEndpoint(),
      );
      setRoundHistory(result);
    } catch (error: any) {
      Logger.error(error);
    }
  };

  async function fetchStartup() {
    try {
      const data = await API.get<PrivateStartup>(StartupGet.buildEndpoint());
      setStartup(data);
    } catch (error) {
      Logger.error(error);
    }
  }

  const closeRound = async () => {
    try {
      await API.put(StartupCloseRound.buildEndpoint(), {});
      // Re-fetch active round to refresh the UI
      await fetchActiveRound();
      setConfirmCloseRoundModalOpen(false);
    } catch (error: any) {
      Logger.error(error);
      setErrorMessage(error.message);
    }
  };

  const onOpenRoundSuccess = () => {
    fetchActiveRound();
  };

  useEffect(() => {
    const initialFetch = async () => {
      setIsLoading(true);
      await fetchRoundHistory();
      await fetchActiveRound();
      await fetchStartup();
      setIsLoading(false);
    };

    initialFetch();
  }, []);

  const gracePeriodAlertContent = (
    <div className="text-sm font-normal">
      <p className="text-base font-medium leading-6">
        Your last round closed&nbsp;
        <DateString date={currentRound?.closedOn} />
      </p>
      <p>
        To allow your prospective investors to decide on whether to pass or
        invest, you will not be able to open a new round until&nbsp;
        <span className="font-medium">
          {currentRound?.gracePeriodEndsOn &&
            currentRound.gracePeriodEndsOn.toLocaleString()}
          .
        </span>
      </p>
    </div>
  );

  if (isLoading) {
    return (
      <PageLoadingSpinner message="Raising for the next big milestone... 🚀" />
    );
  }

  return (
    <section>
      {currentRound && currentRound.isOpen && (
        <>
          <ActiveRound
            flowLink={startup?.flowLinks[0]}
            isEditable={isEditingRound}
            onSubmit={updateRound}
            round={currentRound}
            roundHistory={roundHistory}
            setIsEditable={setIsEditingRound}
            toggleConfirmCloseRoundModal={toggleConfirmCloseRoundModal}
          />

          <ModalWrapper
            open={confirmCloseRoundModalOpen}
            onClose={toggleConfirmCloseRoundModal}
          >
            <SimpleDialog
              onCancel={toggleConfirmCloseRoundModal}
              onPrimaryAction={closeRound}
              title="Are you sure you want to close the round? This action is irreversible."
              text={getCloseRoundMessage(
                roundUpToCronInterval(new Date(Date.now() + GRACE_PERIOD)),
              )}
              primaryAction="Close Round"
              color="red"
              errorMessage={errorMessage}
            />
          </ModalWrapper>
        </>
      )}

      {(!currentRound || !currentRound.isOpen) && (
        <>
          <Alert
            alertType="Warning"
            canDismiss={false}
            color="yellow"
            content={gracePeriodAlertContent}
            isShown={isAlertShown}
            onClose={() => setIsAlertShown(false)}
          />

          {!isEditingRound && (
            <EmptyState
              title="No fundraising round currently open"
              subTitle="Open a new round to add the fundraising information to your one-pager and track progress"
              icon={
                <FolderPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
              }
              actionButton={
                <button
                  type="button"
                  onClick={() => setIsEditingRound(true)}
                  className="app-button--primary"
                  disabled={currentRound?.isInGracePeriod}
                >
                  <PlusIcon
                    className="-ml-0.5 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Open Round
                </button>
              }
            />
          )}

          {isEditingRound && (
            <ModalWrapper
              open={isEditingRound}
              onClose={() => setIsEditingRound(true)}
            >
              <OpenNewRoundDialog
                onClose={() => setIsEditingRound(false)}
                onSuccess={() => onOpenRoundSuccess()}
                round={emptyRound}
                roundHistory={roundHistory}
                onSubmit={createNewRound}
              />
            </ModalWrapper>
          )}
        </>
      )}
    </section>
  );
}

export default CurrentRoundManager;
