import React from 'react';
import DebugRender from './DebugRender';
import LoadingSpinner, { ColorOptions, SizeOptions } from './LoadingSpinner';

interface PageLoadingSpinnerProps {
  message?: string;
  color?: ColorOptions;
  size?: SizeOptions;
}

function PageLoadingSpinner({
  message = 'Working on it... 💪',
  color = 'blue',
  size = 7,
}: PageLoadingSpinnerProps): JSX.Element {
  return (
    <div className="mt-10 flex flex-col items-center justify-center">
      <LoadingSpinner color={color} size={size} />
      <p className="mt-3 text-base font-medium text-gray-700 dark:text-gray-300">
        {message}
      </p>
      <DebugRender>
        <p className="-mt-6 bg-gray-100 text-2xl font-medium text-gray-700 dark:bg-gray-900 dark:text-gray-300">
          Putting on the safety vest...&nbsp;🦺
        </p>
      </DebugRender>
    </div>
  );
}

export default PageLoadingSpinner;
