export enum HighlightType {
  Financial = 'Financial',
  ProductAndMetrics = 'Product & Metrics',
  PressAndAwards = 'Press & Awards',
  Partnerships = 'Partnerships',
}

export const getHighlightTypeTooltip = (highlightType: string): string => {
  switch (highlightType) {
    case HighlightType.Financial:
      return 'Such as revenue milestones, profitability, paid pilots, and revenue retention rates.';
    case HighlightType.ProductAndMetrics:
      return 'Such as product launches and non-financial KPIs like user growth and activity.';
    case HighlightType.PressAndAwards:
      return 'Such as major press hits, awards, prizes, licenses, and grants.';
    case HighlightType.Partnerships:
      return 'Such as distribution partnerships, key supply relationships, or integration partners.';
    default:
      return '';
  }
};
