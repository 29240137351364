import React from 'react';
import * as yup from 'yup';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import BooleanSelectInput from '../../inputs/BooleanSelectInput';

const FounderIsTechnicalField: FormField = {
  fieldComponent: (
    <BooleanSelectInput
      name={StartupFieldNames.FounderIsTechnical}
      label="Are you a technical founder?"
      secondaryLabel="Investors want to know who in the team has technical skills."
      tooltip="Technical founders are the people who write code, engineer devices, formulate substances, or do any other technical work."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.FounderIsTechnical]: yup.boolean().required('Required'),
  }),
};

export default FounderIsTechnicalField;
