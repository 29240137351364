import { GroupedSelectOption } from '../../types/SelectOptions';

const REASONS_FOR_PASSING = [
  { value: 'Not within thesis (stage)', label: 'Not within thesis (stage)' },
  {
    value: 'Not within thesis (industry)',
    label: 'Not within thesis (industry)',
  },
  {
    value: 'Not within thesis (business model)',
    label: 'Not within thesis (business model)',
  },
  {
    value: 'Not within thesis (geography)',
    label: 'Not within thesis (geography)',
  },
  {
    value: 'Not within target check size',
    label: 'Not within target check size',
  },
  {
    value: 'Not within valuation target',
    label: 'Not within valuation target',
  },
  {
    value: 'Deal structure not compelling',
    label: 'Deal structure not compelling',
  },
  {
    value: 'No available cash for investment',
    label: 'No available cash for investment',
  },
  { value: 'Round moved too fast', label: 'Round moved too fast' },
  {
    value: 'Conflict with portfolio company',
    label: 'Conflict with portfolio company',
  },
  { value: 'Relationship gone cold', label: 'Relationship gone cold' },
  { value: 'Not VC-backable', label: 'Not VC-backable' },
  { value: 'TAM too small', label: 'TAM too small' },
  { value: 'TAM growth too small', label: 'TAM growth too small' },
  { value: 'Traction too small', label: 'Traction too small' },
  { value: 'Revenue growth too low', label: 'Revenue growth too low' },
  { value: 'Market too crowded', label: 'Market too crowded' },
  { value: 'Weak Go-To market strategy', label: 'Weak Go-To market strategy' },
  { value: 'Value prop not compelling', label: 'Value prop not compelling' },
  { value: 'No product-market fit', label: 'No product-market fit' },
  { value: 'No founder-market fit', label: 'No founder-market fit' },
  { value: 'Unexperienced team', label: 'Unexperienced team' },
  { value: 'Sales cycle too long', label: 'Sales cycle too long' },
  {
    value: 'Easily replicable / Not defensible',
    label: 'Easily replicable / Not defensible',
  },
  { value: 'Technical risk too high', label: 'Technical risk too high' },
  {
    value: 'Regulatory and legal concerns',
    label: 'Regulatory and legal concerns',
  },
  { value: 'Too capital intensive', label: 'Too capital intensive' },
];

/**
 * Select options for Startup Users when Passing on an Investor
 */
export const REASONS_FOR_PASSING_ON_INVESTOR = [
  {
    value: 'No industry and business model expertise',
    label: 'No industry and business model expertise',
  },
  {
    value: 'No significant value-add (talent, clients, growth)',
    label: 'No significant value-add (talent, clients, growth)',
  },
  {
    value: 'Not well-connected with other investors',
    label: 'Not well-connected with other investors',
  },
  {
    value: 'Not famous and well-recognized enough',
    label: 'Not famous and well-recognized enough',
  },
  {
    value: 'Slow-moving and unresponsive',
    label: 'Slow-moving and unresponsive',
  },
  {
    value: 'Too aggressive or unfavorable terms',
    label: 'Too aggressive or unfavorable terms',
  },
  {
    value: 'Round full and we chose other investors',
    label: 'Round full and we chose other investors',
  },
  { value: 'Rude or bad personality', label: 'Rude or bad personality' },
  {
    value: 'Dishonest or questionable ethics',
    label: 'Dishonest or questionable ethics',
  },
  { value: 'Never reached out to them', label: 'Never reached out to them' },
];

export const NOT_CURRENTLY_RAISING_REASON = {
  value: 'Not currently raising',
  label: 'Not currently raising',
};

export const PIPELINE_GROUPED_REASONS_FOR_PASSING: GroupedSelectOption[] = [
  {
    groupLabel: 'Investor passed on us',
    options: REASONS_FOR_PASSING,
  },
  {
    groupLabel: 'We passed on the investor',
    options: REASONS_FOR_PASSING_ON_INVESTOR,
  },
];

/**
 * Select options for Investor Users when Passing on a deal
 */
export const REASONS_FOR_PASSING_ON_DEAL = REASONS_FOR_PASSING.concat(
  NOT_CURRENTLY_RAISING_REASON
);

export const UNSOLICITED_SHARE_REASON = {
  value: 'Unsolicited share',
  label: 'Unsolicited share',
};
export const AUTOMATIC_PASS_REASON = { value: 'Ignored', label: 'Ignored' };
export const AUTOMATIC_DOUBLE_PASSED_REASON = {
  value: 'Not received at this round',
  label: 'Not received at this round',
};

export const ALL_REASONS_FOR_PASSING_ON_DEAL = REASONS_FOR_PASSING.concat([
  UNSOLICITED_SHARE_REASON,
  NOT_CURRENTLY_RAISING_REASON,
])
  .concat([AUTOMATIC_PASS_REASON])
  .concat([AUTOMATIC_DOUBLE_PASSED_REASON]);
