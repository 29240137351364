import { GroupedSelectOption } from '/../libs/shared-types/src/types/SelectOptions';
import React from 'react';

// This is used for react select, to render the label of Grouped options
const formatGroupLabel = (data: GroupedSelectOption): JSX.Element => (
  <div className="flex items-center justify-between">
    <span className="text-base">{data.groupLabel}</span>
    <span className="rounded-full border bg-gray-50 px-1 py-0 text-black">
      {data.options.length}
    </span>
  </div>
);

export default formatGroupLabel;
