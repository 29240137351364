import React, { createContext, useEffect, useState } from 'react';
import { AccountTypes } from '../../../libs/shared-types/src/constants/AccountTypes';
import { Invitation } from '../../../libs/shared-types/src/types/model/UnconfirmedUser';
import TypedLocalStorage from '../middleware/TypedLocalStorage';

enum AuthenticationStorageKeys {
  Invitation = 'invitation',
  UnconfirmedAccountType = 'unconfirmedAccountType',
}

interface AuthenticationStorageType {
  [AuthenticationStorageKeys.Invitation]: Invitation;
  [AuthenticationStorageKeys.UnconfirmedAccountType]: AccountTypes;
}

export interface AuthenticationContextType {
  unconfirmedAccountType: AccountTypes | undefined;
  setUnconfirmedAccountType: React.Dispatch<
    React.SetStateAction<AccountTypes | undefined>
  >;
  invitation: Invitation | undefined;
  setInvitation: React.Dispatch<React.SetStateAction<Invitation | undefined>>;
}

const AuthenticationContext = createContext<AuthenticationContextType>(
  {} as AuthenticationContextType
);

const AuthenticationContextProvider = ({
  children,
}: {
  children: string | JSX.Element[] | JSX.Element;
}): JSX.Element => {
  const [storage] = useState(
    new TypedLocalStorage<AuthenticationStorageType>()
  );
  const [unconfirmedAccountType, setUnconfirmedAccountType] = useState<
    AccountTypes | undefined
  >(null as unknown as undefined);
  const [invitation, setInvitation] = useState<Invitation | undefined>(
    null as unknown as undefined
  );

  useEffect(() => {
    const existingInvitation =
      storage.getItem(AuthenticationStorageKeys.Invitation, 'safe') ??
      undefined;
    setInvitation(existingInvitation);

    const existingUnconfirmedAccountType =
      storage.getItem(
        AuthenticationStorageKeys.UnconfirmedAccountType,
        'safe'
      ) ?? undefined;
    setUnconfirmedAccountType(existingUnconfirmedAccountType);
  }, []);

  useEffect(() => {
    if (invitation === null) {
      return;
    }

    if (invitation) {
      storage.setItem(AuthenticationStorageKeys.Invitation, invitation);
      return;
    }

    if (invitation === undefined) {
      storage.removeItem(AuthenticationStorageKeys.Invitation);
      return;
    }
  }, [invitation]);

  useEffect(() => {
    if (unconfirmedAccountType === null) {
      return;
    }

    if (unconfirmedAccountType) {
      storage.setItem(
        AuthenticationStorageKeys.UnconfirmedAccountType,
        unconfirmedAccountType
      );
    }

    if (unconfirmedAccountType === undefined) {
      storage.removeItem(AuthenticationStorageKeys.UnconfirmedAccountType);
    }
  }, [unconfirmedAccountType]);

  return (
    <AuthenticationContext.Provider
      value={{
        unconfirmedAccountType,
        setUnconfirmedAccountType,
        invitation,
        setInvitation,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationContext, AuthenticationContextProvider };
