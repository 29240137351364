/* eslint-disable no-underscore-dangle */
import React from 'react';
import { ConnectionRequestAccountData } from '/../libs/shared-types/src/types/view/APIResponse';
import API from '/src/middleware/API';
import PaginatedTable from '/src/components/table/PaginatedTable';
import ConnectionRequestsTableRow from './ConnectionRequestsTableRow';
import { SortOrder } from '/src/interfaces/Sortable';
import Logger from '/src/services/logger';
import {
  InvestorRequestsAccept,
  InvestorRequestsCancel,
  InvestorRequestsReject,
} from '/../libs/shared-types/src/constants/ApiRoutes';

interface ConnectionRequestsTableProps {
  contacts: ConnectionRequestAccountData[];
  onRefresh: () => void;
  onSort: (key: string, order: SortOrder) => void;
  isOutgoing?: boolean;
}

function ConnectionRequestsTable({
  contacts,
  onRefresh,
  onSort,
  isOutgoing = false,
}: ConnectionRequestsTableProps): JSX.Element {
  // Accept or Reject the contact request
  async function sendChoice(id: string, accept: boolean) {
    try {
      await API.put(
        accept
          ? InvestorRequestsAccept.buildEndpoint()
          : InvestorRequestsReject.buildEndpoint(),
        {
          requestId: id,
        }
      );

      // Refresh the collection
      onRefresh();
    } catch (error: any) {
      Logger.error(error);
    }
  }

  async function sendCancel(id: string) {
    try {
      await API.put(InvestorRequestsCancel.buildEndpoint(), {
        requestId: id,
      });
      // Refresh the collection
      onRefresh();
    } catch (error: any) {
      Logger.error(error);
    }
  }

  const onAccept = (id: string) => sendChoice(id, true);
  const onReject = (id: string) => sendChoice(id, false);

  const onCancel = (id: string) => {
    sendCancel(id);
  };

  const headerValues = [
    { element: <span>Name</span>, sortKey: 'name' },
    { element: <span>Email</span> },
    { element: <span>Type</span>, sortKey: 'type' },
    { element: <span>Firm & Role</span>, sortKey: 'firm' },
    { element: <span>Message</span> },
    { element: <span>Discovered From</span> },
    { element: <span>Requested</span>, sortKey: 'createdOn' },
    { element: <span className="sr-only">Action</span> },
  ];
  return (
    <section>
      <PaginatedTable
        headers={headerValues}
        onSort={onSort}
        rowComponents={contacts.map((contact) => (
          <ConnectionRequestsTableRow
            onAccept={onAccept}
            onReject={onReject}
            onCancel={onCancel}
            isOutgoing={isOutgoing}
            key={contact.id}
            contact={contact}
          />
        ))}
      />
    </section>
  );
}

export default ConnectionRequestsTable;
