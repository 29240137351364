import React from 'react';
import { Field } from 'formik';
import { BOOLEAN_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import CustomSelect from './CustomSelect';

export default function BooleanSelectInput(props: any): JSX.Element {
  return (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      options={BOOLEAN_OPTIONS}
      placeholder="Select option..."
      {...props}
    />
  );
}
