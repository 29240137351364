import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import { MAX_STARTUP_IP_PROTECTIONS } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import { IP_PROTECTIONS_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';

const IpProtectionsField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      components={{ Menu: LimitedMenu }}
      isClearable
      isMulti
      label="IP protections received or in the process of receiving"
      maxCount={MAX_STARTUP_IP_PROTECTIONS}
      name={StartupFieldNames.IpProtections}
      options={IP_PROTECTIONS_OPTIONS}
      placeholder="Select IP Protections..."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.IpProtections]: yup
      .array()
      .of(yup.string())
      .max(
        MAX_STARTUP_IP_PROTECTIONS,
        `At most ${MAX_STARTUP_IP_PROTECTIONS} IP Protections are allowed`
      )
      .nullable(),
  }),
};

export default IpProtectionsField;
