import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { toDashCase } from '/src/util/formatting/strings';
import FlowlinkManager from '/src/routes/common/Flowlink&ReferralsManager/FlowlinkManager';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';

function InvestorFlowLink(): JSX.Element {
  const tabs = [{ name: 'My Link' }];

  return (
    <main>
      <header>
        <h2 className="page-title">Your FlowLink</h2>
      </header>

      <section className="my-4">
        <Routes>
          {['', tabs[0].name].map((path) => (
            <Route
              key={path}
              path={toDashCase(path)}
              element={<FlowlinkManager />}
            />
          ))}

          <Route path="*" element={<Navigate to={NOT_FOUND_ROUTE} replace />} />
        </Routes>
      </section>
    </main>
  );
}

export default InvestorFlowLink;
