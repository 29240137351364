/* eslint-disable react/self-closing-comp */
import React from 'react';
import { Link } from 'react-router-dom';

import { EnvelopeIcon } from '@heroicons/react/24/outline';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import { LOGIN_ROUTE } from '/src/constants/Routes';

const HeyLogo = () => (
  <svg
    width="32px"
    height="18px"
    viewBox="0 0 81.63 37.39"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m80.85 6.27h-4.41a1.78 1.78 0 0 0 -1.57 1.11l-1 2.88-.8 2.23-1.12 3.23a5.7 5.7 0 0 1 -.47 1.11 5.29 5.29 0 0 1 -.48-1.12l-1.07-3.21c-.21-.62-.56-1.62-.78-2.24l-1-2.88a1.78 1.78 0 0 0 -1.58-1.11h-16.96a1.18 1.18 0 0 0 -1.18 1.18v6.73a1.19 1.19 0 0 1 -1.19 1.19h-4.92a1.2 1.2 0 0 1 -1.19-1.19v-6.73a1.18 1.18 0 0 0 -1.13-1.18h-4.1a1.19 1.19 0 0 0 -1.19 1.18v3.11c2.18 2.78 2.18 7.84 0 15.38v3.59a1.19 1.19 0 0 0 1.19 1.18h4.1a1.18 1.18 0 0 0 1.18-1.18v-7.34a1.19 1.19 0 0 1 1.14-1.19h4.92a1.18 1.18 0 0 1 1.19 1.18v7.34a1.18 1.18 0 0 0 1.18 1.18h13.83a1.18 1.18 0 0 0 1.18-1.18v-3.04a1.18 1.18 0 0 0 -1.18-1.18h-7.34a1.19 1.19 0 0 1 -1.18-1.19v-2a1.19 1.19 0 0 1 1.18-1.19h4.43a1.18 1.18 0 0 0 1.18-1.18v-3.04a1.18 1.18 0 0 0 -1.18-1.18h-4.43a1.19 1.19 0 0 1 -1.18-1.19v-1.46a1.18 1.18 0 0 1 1.18-1.19h6a2 2 0 0 1 1.67 1.08l3.89 8.45a6.29 6.29 0 0 1 .5 2.26v6.06a1.18 1.18 0 0 0 1.18 1.18h4.1a1.18 1.18 0 0 0 1.19-1.18v-6.06a6.5 6.5 0 0 1 .49-2.26l6.42-13.87a.7.7 0 0 0 -.69-1.07z"
      fill="purple"
    ></path>
    <path
      d="m29 10.43a8.92 8.92 0 0 0 -4.12 2.45c-1.31-4.6-4.13-11.56-6.88-12.7a2 2 0 0 0 -1.84.08c-1.72 1-2.84 4.22-1.86 10.32-3.78-6.92-5.1-6.91-5.71-6.92a1.49 1.49 0 0 0 -1.36.93c-.41.91-1.23 3.75.77 9.86-2.41-3.3-3.15-3.72-4-3.68a1.51 1.51 0 0 0 -1.28.87c-.72 1.36-.64 4.78 1.6 9.88a4.59 4.59 0 0 0 -2.76-1.14 1.65 1.65 0 0 0 -1.33.85c-.91 1.67 1 6.36 3.36 9.54 1.91 2.61 5.74 6.62 11.3 6.62a12.54 12.54 0 0 0 2.32-.22 1 1 0 0 0 -.39-2c-4.62.9-8.1-1.64-10.21-3.92-3.15-3.41-4.53-7.51-4.61-8.76.66.27 2.56 1.39 6.26 6.13a1 1 0 0 0 1.65-1.18c-5.2-7.89-5.71-12.67-5.54-14.28 1.53 1.56 5.35 7.28 8.23 11.6a1 1 0 0 0 1.37.31 1 1 0 0 0 .37-1.36c-6-11-5.79-16-5.44-17.68.9.91 3.29 4.08 8.32 15a1 1 0 0 0 1.31.51 1 1 0 0 0 .57-1.29c-4.88-13.25-2.76-17.74-1.95-18.25 1.05 0 4 5.06 5.84 11.69.11.41.22.81.32 1.2a6.38 6.38 0 0 0 -.6 1.44c-1 3.67-2 12-.07 14.29a1.86 1.86 0 0 0 1.62.69 2.63 2.63 0 0 0 1.92-1.18c1.79-2.61.74-9.16-.69-14.89a7.53 7.53 0 0 1 4-2.87 2 2 0 0 1 2 .48c.82.92 2.25 4.36-2.09 16.49a1 1 0 0 0 1.92.68c3.46-9.67 4-15.9 1.69-18.52a3.94 3.94 0 0 0 -4.01-1.07zm-4.5 18.57a.64.64 0 0 1 -.34.29c-.75-.89-.75-6.16.07-10.48 1.55 7.47.65 9.66.27 10.19z"
      fill="purple"
    ></path>
  </svg>
);

const GmailLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32px"
    height="18px"
    viewBox="52 42 88 66"
  >
    <path fill="#4285f4" d="M58 108h14V74L52 59v43c0 3.32 2.69 6 6 6" />
    <path fill="#34a853" d="M120 108h14c3.32 0 6-2.69 6-6V59l-20 15" />
    <path fill="#fbbc04" d="M120 48v26l20-15v-8c0-7.42-8.47-11.65-14.4-7.2" />
    <path fill="#ea4335" d="M72 74V48l24 18 24-18v26L96 92" />
    <path
      fill="#c5221f"
      d="M52 51v8l20 15V48l-5.6-4.2c-5.94-4.45-14.4-.22-14.4 7.2"
    />
  </svg>
);

const OutlookLogo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="24"
    height="25"
    viewBox="0 0 103.17322 104.31332"
    enableBackground="new 0 0 190 50"
    className="mx-1"
  >
    <path
      d="m 64.566509,22.116383 v 20.404273 l 7.130526,4.489881 c 0.188058,0.05485 0.595516,0.05877 0.783574,0 L 103.16929,26.320259 c 0,-2.44867 -2.28412,-4.203876 -3.573094,-4.203876 H 64.566509 z"
      fill="#0072c6"
    />
    <path
      d="m 64.566509,50.13308 6.507584,4.470291 c 0.916782,0.673874 2.021622,0 2.021622,0 -1.100922,0.673874 30.077495,-20.035993 30.077495,-20.035993 v 37.501863 c 0,4.082422 -2.61322,5.794531 -5.551621,5.794531 H 64.562591 V 50.13308 z"
      id="path5"
      fill="#0072c6"
    />
    <g transform="matrix(3.9178712,0,0,3.9178712,-13.481403,-41.384473)">
      <path
        d="m 11.321,20.958 c -0.566,0 -1.017,0.266 -1.35,0.797 -0.333,0.531 -0.5,1.234 -0.5,2.109 0,0.888 0.167,1.59 0.5,2.106 0.333,0.517 0.77,0.774 1.31,0.774 0.557,0 0.999,-0.251 1.325,-0.753 0.326,-0.502 0.49,-1.199 0.49,-2.09 0,-0.929 -0.158,-1.652 -0.475,-2.169 -0.317,-0.516 -0.75,-0.774 -1.3,-0.774 z"
        fill="#0072c6"
      />
      <path
        d="m 3.441,13.563 v 20.375 l 15.5,3.25 V 10.563 l -15.5,3 z m 10.372,13.632 c -0.655,0.862 -1.509,1.294 -2.563,1.294 -1.027,0 -1.863,-0.418 -2.51,-1.253 C 8.094,26.4 7.77,25.312 7.77,23.97 c 0,-1.417 0.328,-2.563 0.985,-3.438 0.657,-0.875 1.527,-1.313 2.61,-1.313 1.023,0 1.851,0.418 2.482,1.256 0.632,0.838 0.948,1.942 0.948,3.313 10e-4,1.409 -0.327,2.545 -0.982,3.407 z"
        fill="#0072c6"
      />
    </g>
  </svg>
);

const YahooLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Yahoo!"
    role="img"
    viewBox="0 0 512 512"
    fill="#fff"
    width="24"
    height="25"
    className="mx-1"
  >
    <rect width="512" height="512" rx="15%" fill="#5f01d1" />
    <g fill="#fff">
      <path d="M203 404h-62l25-59-69-165h63l37 95 37-95h62m58 76h-69l62-148h69" />
      <circle cx="303" cy="308" r="38" />
    </g>
  </svg>
);

function SignupSuccessPage(): JSX.Element {
  const name = localStorage.getItem(LocalStorageKeysConst.ACCOUNT_FIRST_NAME);
  return (
    <main className="flex w-full flex-row justify-between">
      <section className="mr-10 flex flex-col py-8 px-8 md:py-48">
        <EnvelopeIcon className="h-16 w-16 text-gray-400" />
        <h1 className="font-gray-800 text-4xl font-bold">
          Thank you for signing up
          {name ? `, ${name}!` : '!'}
        </h1>
        <h2 className="mt-6 text-xl font-medium text-gray-600">
          Please check your email for the verification link and then&nbsp;
          <Link className="hyperlink" to={LOGIN_ROUTE}>
            log in
          </Link>
          &nbsp;to Flowlie to complete your profile.
        </h2>
        <p className="text-xl text-gray-600">
          If you didn&apos;t receive it right away, please check your spam
          folder.
        </p>
        <section className="my-10 grid grid-flow-col grid-rows-4 gap-8 md:grid-rows-2 lg:grid-rows-1">
          <a
            target="_blank"
            rel="noopenner noreferrer"
            className="flex w-56 flex-row items-center justify-center rounded-md border border-gray-200 py-3 px-2"
            href="https://mail.google.com/mail/"
          >
            <GmailLogo />
            <span className="mx-2">Open Gmail</span>
          </a>
          <a
            target="_blank"
            rel="noopenner noreferrer"
            className="flex w-56 flex-row items-center justify-center rounded-md border border-gray-200 py-3 px-2"
            href="https://outlook.office.com/mail/"
          >
            <OutlookLogo />
            <span className="mx-2">Open Outlook</span>
          </a>
          <a
            target="_blank"
            rel="noopenner noreferrer"
            className="flex w-56 flex-row items-center justify-center rounded-md border border-gray-200 py-3 px-2"
            href="https://mail.yahoo.com/"
          >
            <YahooLogo />
            <span className="mx-2">Open Yahoo</span>
          </a>
          <a
            target="_blank"
            rel="noopenner noreferrer"
            className="flex w-56 flex-row items-center justify-center rounded-md border border-gray-200 py-3 px-2"
            href="https://app.hey.com/"
          >
            <HeyLogo />
            <span className="mx-2">Open Hey</span>
          </a>
        </section>
      </section>
      <section className="flex h-screen w-2/6 bg-gradient-to-b from-green-400 via-blue-300 to-blue-500 md:w-1/6"></section>
    </main>
  );
}

export default SignupSuccessPage;
