import { CognitoUserSession } from 'amazon-cognito-identity-js';
import Pool from '../Userpool';
import Logger from '/src/services/logger';

export async function refreshTokens(): Promise<CognitoUserSession> {
  return new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession((error: Error, session: CognitoUserSession | null) => {
        if (session) {
          const refreshToken = session.getRefreshToken();
          user.refreshSession(refreshToken, (err, sesh) => {
            if (err) {
              reject(err);
            } else {
              resolve(sesh);
            }
          });
        } else {
          reject(error);
        }
      });
    }
  });
}

export function getSession(): Promise<CognitoUserSession> {
  return new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user != null) {
      user.getSession((err: Error, session: CognitoUserSession | null) => {
        if (err) {
          reject(err);
        } else if (session) {
          if (!session.isValid()) {
            reject(Error('Invalid session'));
          } else {
            resolve(session);
          }
        }
      });
    } else {
      Logger.warn('Cognito.ts - getSession() User not found.');
    }
  });
}
