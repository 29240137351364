import React from 'react';

import { numberWithCommas } from '/src/util/formatting/numbers';
import { dayDiff } from '/src/util/formatting/dates';
import { isMinDate } from '/src/util/time';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import {
  PercentFunded,
  RemainingAllocation,
  RemainingTime,
} from '../routes/startup/RoundManager/RoundStatistics';
import DateString from './utility/DateString';
import { isIncludedString, isRoundOpen } from '../util/rounds';
import { generateTagCloud } from '../util/generateTagCloud';

export const ROUND_TAGS = {
  Open: (
    <span
      key="Open"
      className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
    >
      <svg
        className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400"
        fill="currentColor"
        viewBox="0 0 8 8"
      >
        <circle cx={4} cy={4} r={3} />
      </svg>
      Open
    </span>
  ),
  ClosingSoon: (
    <span
      key="ClosingSoon"
      title="The founder estimates they will be closing the round within a week."
      className="inline-flex items-center rounded-full bg-orange-100 px-2.5 py-0.5 text-xs font-medium text-orange-800"
    >
      Closing Soon
    </span>
  ),
  Oversubscribed: (
    <span
      key="Oversubscribed"
      title="This round currently has more commitments than the available allocation set by the founder."
      className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
    >
      Oversubscribed
    </span>
  ),
  QsbsPotential: (
    <span
      key="QsbsPotential"
      title="This round's equity has the potential to qualify as QSBS as of today's date. This is not legal advice, please consult a tax professional."
      className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
    >
      QSBS Potential
    </span>
  ),
  DownRound: (
    <span
      key="DownRound"
      title="This round's valuation is lower than the previous round's valuation."
      className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800"
    >
      Down Round
    </span>
  ),
  LeadSecured: (
    <span
      key="LeadSecured"
      title="The founder has secured the commitment of a lead investor."
      className="inline-flex items-center rounded-full bg-teal-100 px-2.5 py-0.5 text-xs font-medium text-teal-800"
    >
      Lead Secured
    </span>
  ),
  TermSheetSigned: (
    <span
      key="TermSheetSigned"
      title="The founder has signed a term sheet."
      className="inline-flex items-center rounded-full bg-teal-100 px-2.5 py-0.5 text-xs font-medium text-teal-800"
    >
      Term Sheet Signed
    </span>
  ),
  BridgeRound: (
    <span
      key="BridgeRound"
      title="This financing round is a bridge round"
      className="bg-orange-100-100 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
    >
      Bridge
    </span>
  ),
  ExtensionRound: (
    <span
      key="ExtensionRound"
      title="This financing round is an extension of the previous round, at the same equivalent post-money valuation."
      className="bg-orange-100-100 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
    >
      Extension
    </span>
  ),
};

export function getRoundInsightsTags(round: RoundView): JSX.Element[] {
  const tags = [];

  if (isMinDate(round.closedOn) && dayDiff(round.estimatedCloseDate) <= 7) {
    tags.push(ROUND_TAGS.ClosingSoon);
  }

  if (round.raisedAmount > round.raiseTarget) {
    tags.push(ROUND_TAGS.Oversubscribed);
  }

  if (round.hasQsbsPotential) {
    tags.push(ROUND_TAGS.QsbsPotential);
  }

  if (isMinDate(round.closedOn) && round.hasTermSheet) {
    tags.push(ROUND_TAGS.TermSheetSigned);
  }

  if (isMinDate(round.closedOn) && round.hasLeadInvestor) {
    tags.push(ROUND_TAGS.LeadSecured);
  }

  if (round.isDownRound) {
    tags.push(ROUND_TAGS.DownRound);
  }

  if (round.isBridge && !round.isExtension) {
    tags.push(ROUND_TAGS.BridgeRound);
  }

  if (round.isExtension) {
    tags.push(ROUND_TAGS.ExtensionRound);
  }

  return tags;
}

interface RoundCardProps {
  round: RoundView;
}

function RoundCard({ round }: RoundCardProps): JSX.Element {
  return (
    <div className="mb-4 rounded-lg bg-white shadow">
      <div className="rounded-lg bg-white px-4 py-5">
        <div className="flex flex-wrap justify-between sm:flex-nowrap">
          <div className="flex flex-col">
            <h3 className="text-lg font-medium leading-6 text-gray-900 md:flex">
              {round.roundDisplayName}
              &nbsp;Round
            </h3>
            <span className="space-x-2">
              {isMinDate(round.closedOn) && ROUND_TAGS.Open}
              {getRoundInsightsTags(round)}
            </span>
          </div>
          <div className="shrink-0 sm:text-right">
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {!isMinDate(round.closedOn) && (
                <>
                  Closed&nbsp;
                  <DateString date={round.closedOn} tooltipPosition="bottom" />
                </>
              )}
              {isMinDate(round.closedOn) && !isMinDate(round.modifiedOn) && (
                <>
                  Updated&nbsp;
                  <DateString
                    date={round.modifiedOn}
                    tooltipPosition="bottom"
                  />
                </>
              )}
            </p>
          </div>
        </div>
      </div>

      {isRoundOpen(round) && (
        <div className="grid grid-cols-2 grid-rows-2 gap-y-2 px-4 pb-4 sm:px-6 lg:grid-cols-4 lg:grid-rows-1">
          <PercentFunded round={round} />
          <RemainingAllocation round={round} />
          <RemainingTime round={round} />
        </div>
      )}
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Type of Round</dt>
            <dd className="mt-1 text-sm font-medium text-gray-900">
              {round.roundType}&nbsp;{round.safeType}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Amount Raised or Committed
            </dt>
            <dd className="mt-1 text-sm font-medium text-gray-900">
              $&nbsp;
              {numberWithCommas(round.raisedAmount)}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Raise Target</dt>
            <dd className="mt-1 text-sm font-medium text-gray-900">
              $&nbsp;
              {numberWithCommas(round.raiseTarget)}
            </dd>
          </div>

          {round.valuationCap && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {round.valuationCapType} Valuation Cap
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                $&nbsp;
                {numberWithCommas(round.valuationCap)}
              </dd>
            </div>
          )}

          {round.pricePerShare !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Price Per Share
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                $&nbsp;
                {numberWithCommas(round.pricePerShare, 4)}
              </dd>
            </div>
          )}

          {round.discount !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Discount Rate
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {round.discount}
                &nbsp;%
              </dd>
            </div>
          )}

          {round.hasRightsMfn !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Most Favorable Nation Rights
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {isIncludedString(round.hasRightsMfn)}
              </dd>
            </div>
          )}

          {round.interest !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Interest Rate
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {round.interest}
                &nbsp;%
              </dd>
            </div>
          )}

          {round.conversionTerm && round.conversionTerm >= 1 && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Conversion Term
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {round.conversionTerm}
                &nbsp;
                {round.conversionTerm > 1 && 'Months'}
                {round.conversionTerm === 1 && 'Month'}
              </dd>
            </div>
          )}

          {round.prepayment !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Prepayment</dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {round.prepayment}
              </dd>
            </div>
          )}

          {round.liquidationPreference !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Liquidation Preference
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {round.liquidationPreference}x&nbsp;
                {round.liquidationPreference > 0 &&
                  round.liquidationParticipation !== undefined &&
                  round.liquidationParticipation}
              </dd>
            </div>
          )}

          {round.hasRightsPayToPlay !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Pay-To-Play Rights
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {isIncludedString(round.hasRightsPayToPlay)}
              </dd>
            </div>
          )}

          {round.antidilutionRights !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Anti-dilution Rights
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {round.antidilutionRights}
              </dd>
            </div>
          )}

          {round.hasRightsDragAlong !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Drag-Along Rights
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {isIncludedString(round.hasRightsDragAlong)}
              </dd>
            </div>
          )}

          {round.runwayTarget && round.runwayTarget > 0 && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Target Runway
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {round.runwayTarget} months
              </dd>
            </div>
          )}

          {round.openToInvestorTypes !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Open to the following investors only
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {generateTagCloud(round.openToInvestorTypes)}
              </dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
}

export default RoundCard;
