import React from 'react';
import Tooltip from '../utility/Tooltip';

interface BasicStatisticProps {
  label: string;
  statistic: number | string;
  tooltip?: string | JSX.Element;
  suffix?: string;
}

function BasicStatistic({
  label,
  statistic,
  tooltip,
  suffix,
}: BasicStatisticProps): JSX.Element {
  return (
    <div className="rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">
        <dt className="flex flex-row justify-between text-sm font-medium text-gray-500">
          <span>{label}</span>
          {tooltip && (
            <Tooltip width="w-64" position="left" tooltipText={tooltip} />
          )}
        </dt>
        <dd className="mt-1 text-3xl font-semibold text-gray-900">
          {statistic}
          {suffix && (
            <span className="ml-2 text-xl font-normal text-gray-800">
              {suffix}
            </span>
          )}
        </dd>
      </div>
    </div>
  );
}

export default BasicStatistic;
