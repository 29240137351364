import { AccountTypes } from '../constants/AccountTypes';
import { LinkTypes } from '../constants/LinkshareTypes';
import { OnboardingFlows } from '../constants/OnboardingFlows';

export function getOnboardingFlow(
  unconfirmedAccountType: string,
  invitedByAccountType?: AccountTypes,
  tokenType?: LinkTypes
): OnboardingFlows {
  if (
    unconfirmedAccountType === AccountTypes.Investor &&
    !invitedByAccountType &&
    !tokenType
  ) {
    return OnboardingFlows.InvestorViaSignup;
  }

  if (
    unconfirmedAccountType === AccountTypes.Startup &&
    !invitedByAccountType &&
    !tokenType
  ) {
    return OnboardingFlows.StartupViaSignup;
  }

  if (
    invitedByAccountType === AccountTypes.Investor &&
    tokenType === LinkTypes.Dealshare &&
    unconfirmedAccountType === AccountTypes.Investor
  ) {
    return OnboardingFlows.InvestorViaDealShareLink;
  }

  if (
    invitedByAccountType === AccountTypes.Investor &&
    tokenType === LinkTypes.Flowlink &&
    unconfirmedAccountType === AccountTypes.Investor
  ) {
    return OnboardingFlows.InvestorViaInvestorFlowLink;
  }

  if (
    invitedByAccountType === AccountTypes.Startup &&
    tokenType === LinkTypes.Flowlink &&
    unconfirmedAccountType === AccountTypes.Investor
  ) {
    return OnboardingFlows.InvestorViaStartupFlowLink;
  }

  if (
    invitedByAccountType === AccountTypes.Investor &&
    tokenType === LinkTypes.Dealshare &&
    unconfirmedAccountType === AccountTypes.Startup
  ) {
    return OnboardingFlows.StartupViaDealShareLink;
  }

  if (
    invitedByAccountType === AccountTypes.Investor &&
    tokenType === LinkTypes.Flowlink &&
    unconfirmedAccountType === AccountTypes.Startup
  ) {
    return OnboardingFlows.StartupViaInvestorFlowLink;
  }

  if (
    invitedByAccountType === AccountTypes.Startup &&
    tokenType === LinkTypes.Flowlink &&
    unconfirmedAccountType === AccountTypes.Startup
  ) {
    return OnboardingFlows.StartupViaStartupFlowLink;
  }

  if (
    invitedByAccountType === AccountTypes.Investor &&
    tokenType === LinkTypes.Dealshare &&
    !unconfirmedAccountType
  ) {
    return OnboardingFlows.UnknownViaDealShareLink;
  }

  if (
    invitedByAccountType === AccountTypes.Investor &&
    tokenType === LinkTypes.Flowlink &&
    !unconfirmedAccountType
  ) {
    return OnboardingFlows.UnknownViaInvestorFlowLink;
  }

  if (
    invitedByAccountType === AccountTypes.Startup &&
    tokenType === LinkTypes.Flowlink &&
    !unconfirmedAccountType
  ) {
    return OnboardingFlows.UnknownViaStartupFlowLink;
  }

  return OnboardingFlows.UnknownViaSignup;
}
