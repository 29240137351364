import {
  SharedGetInvestorById,
  StartupAddInvestorsToLists,
  StartupAddInvestorToLists,
  StartupDeleteInvestorFragment,
  StartupGetCurrentRound,
  StartupGetInvestorLists,
  StartupInvestorPipelineAddInvestors,
  StartupRemoveInvestorFromInvestorList,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '../middleware/API';
import RoundView from '../types/view/RoundView';
import Logger from './logger';
import { InvestorIdentifier } from '../types/view/APIResponse';
import { InvestorDetailView } from '../types/view/InvestorDetailView';
import { InvestorDataType } from '../constants/InvestorDataType';
import { InvestorList } from '../types/model/InvestorList';
import { InvestorFragment } from '../types/model/InvestorFragment';

export const fetchActiveRound = async () => {
  try {
    const round: RoundView = await API.get(
      StartupGetCurrentRound.buildEndpoint(),
    );
    if (round === null) {
      return;
    }
    return round;
  } catch (error: any) {
    Logger.error(error);
  }
};

export const addInvestorsToPipeline = async (
  investors: InvestorIdentifier[],
) => {
  let isSuccess = false;
  let errorMessage = '';
  try {
    await API.put(StartupInvestorPipelineAddInvestors.buildEndpoint(), {
      investors,
    });
    isSuccess = true;
  } catch (error: any) {
    errorMessage = error?.message || '';
  } finally {
    return { isSuccess, error: errorMessage };
  }
};

export const getInvestorData = async ({ id }: { id: string }) => {
  let isSuccess = false;
  let data = undefined;
  try {
    const investorData = await API.get<InvestorDetailView>(
      SharedGetInvestorById.buildEndpoint(id),
    );
    isSuccess = true;
    data = investorData;
  } catch (error: any) {
  } finally {
    return { isSuccess, data };
  }
};

export const addInvestorToTargetList = async (
  investorId: string,
  investorDataType: InvestorDataType,
  investorListIds: string[],
) => {
  let isSuccess = false;
  try {
    await API.put(StartupAddInvestorToLists.buildEndpoint(), {
      investorId: investorId,
      investorDataType: investorDataType,
      investorListIds: investorListIds,
    });
    isSuccess = true;
  } catch (error) {
    Logger.error(error);
  } finally {
    return { isSuccess };
  }
};

export const addManyInvestorToTargetList = async ({
  investors,
  investorListIds,
}: {
  investors: InvestorIdentifier[];
  investorListIds: string[];
}) => {
  let isSuccess = false;

  try {
    await API.put(StartupAddInvestorsToLists.buildEndpoint(), {
      investors,
      investorListsIds: investorListIds,
    });
    isSuccess = true;
  } catch (error) {
    Logger.error(error);
  } finally {
    return { isSuccess };
  }
};

export const removeInvestorListFromTargetList = async ({
  listId,
  investorIds,
}: {
  listId: string;
  investorIds: string[];
}) => {
  let isSuccess = false;

  try {
    await API.post(StartupRemoveInvestorFromInvestorList.buildEndpoint(), {
      listId,
      investorIds,
    });
    isSuccess = true;
  } catch (error) {
    Logger.error(error);
  } finally {
    return { isSuccess };
  }
};

export const fetchInvestorLists = async () => {
  let isSuccess = false;
  let data = undefined;
  try {
    const investorLists = await API.get<InvestorList[]>(
      StartupGetInvestorLists.buildEndpoint(),
    );
    isSuccess = true;
    data = investorLists;
  } catch (error: any) {
    Logger.error(error.message);
  } finally {
    return { isSuccess, data };
  }
};

export const deleteFragmentInvestor = async (investorId: string) => {
  let isSuccess = false;
  let errorMessage = '';
  try {
    await API.delete<InvestorFragment>(
      `${StartupDeleteInvestorFragment.buildEndpoint(investorId)}`,
    );
    isSuccess = true;
  } catch (error: any) {
    Logger.error(error.message);
    errorMessage = error.message;
  } finally {
    return { isSuccess, error: errorMessage };
  }
};
