import React from 'react';
import { generateTagCloud } from '/src/util/generateTagCloud';
import { PublicInvestor } from '/../libs/shared-types/src/types/view/investor/PublicInvestor';

interface InvestorDetailValueAddCardProps {
  investor: PublicInvestor;
}

function InvestorDetailValueAddCard({
  investor,
}: InvestorDetailValueAddCardProps): JSX.Element {
  if (
    (investor.expFunctional && investor.expFunctional.length > 0) ||
    (investor.expIndustry && investor.expIndustry.length > 0)
  ) {
    return (
      <section aria-labelledby="startup-general-information-title">
        <div className="rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Value Add
            </h3>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              {investor.expFunctional && investor.expFunctional.length > 0 && (
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">
                    Functional Expertise
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateTagCloud(investor.expFunctional)}
                  </dd>
                </div>
              )}
              {investor.expIndustry && investor.expIndustry.length > 0 && (
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">
                    Industry Expertise
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateTagCloud(investor.expIndustry)}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </div>
      </section>
    );
  }
  return <></>;
}

export default InvestorDetailValueAddCard;
