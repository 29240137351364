import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import {
  VALUATION_CAP_MAX,
  VALUATION_CAP_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import MoneyInput from '/src/components/inputs/MoneyInput';
import {
  isPricedRoundType,
  shouldHaveValuationCap,
} from '/../libs/shared-types/src/extensions/RoundExtensions';
import { numberWithCommas } from '/src/util/formatting/numbers';
import { ValuationCapType } from '/../libs/shared-types/src/constants/ValuationCapType';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  const label = isPricedRoundType(values[RoundFieldNames.RoundType])
    ? 'Valuation'
    : 'Valuation Cap';

  return isInputShown(RoundFieldNames.ValuationCap, values) ? (
    <MoneyInput
      label={label}
      disabled={values[RoundFieldNames.IsEditable] === 'false'}
      name={RoundFieldNames.ValuationCap}
      placeholder={values.raiseTarget ? values.raiseTarget.toString() : '0'}
      tooltip={`The maximum valuation at which the current round investment will convert into equity. A lower ${label} means investors receive more company shares.`}
    />
  ) : (
    <></>
  );
}

const ValuationCapField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.ValuationCap]: yup
      .number()
      .nullable()
      .typeError('Must be a number')
      .when(RoundFieldNames.RoundType, {
        is: (roundType: string) =>
          [
            RoundTypes.PricedPreferred,
            RoundTypes.PricedCommon,
            RoundTypes.ConvertibleNote,
            RoundTypes.Safe,
            RoundTypes.CrowdSafe,
            RoundTypes.Asa,
          ].includes(roundType as RoundTypes),
        then: (schema) =>
          schema
            .typeError('Must be a number')
            .test(
              'onlyThousandMultiples',
              'Only multiples of $1000 are accepted',
              (value): boolean => {
                if (value && value > 0 && value % 1000 !== 0) {
                  // Return false to indicate the test failed
                  return false;
                }
                return true;
              },
            )
            .min(
              VALUATION_CAP_MIN,
              `Must be greater than $ ${VALUATION_CAP_MIN}`,
            )
            .max(
              VALUATION_CAP_MAX,
              `Cannot be greater than $ ${numberWithCommas(VALUATION_CAP_MAX)}`,
            )
            .when(RoundFieldNames.ValuationCapType, {
              is: (capType: string) => capType === ValuationCapType.PostMoney,
              then: (schema) =>
                schema.moreThan(
                  yup.ref(RoundFieldNames.RaiseTarget),
                  'Must be greater than the Raise Target',
                ),
            })
            .test(
              'valuationCapRequired',
              'Required',
              (
                valuationCapType,
                context: yup.TestContext<yup.AnyObject>,
              ): boolean => {
                if (
                  shouldHaveValuationCap(context.parent) &&
                  valuationCapType === undefined
                ) {
                  return false;
                }
                return true;
              },
            ),
      }),
  }),
};

export default ValuationCapField;
