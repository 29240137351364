import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import websiteSchema from '/src/constants/validation/websiteSchema';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import { ContactPreferences } from '/../libs/shared-types/src/constants/ContactPreferences';
import { useLocation } from 'react-router-dom';
import { COMPLETE_PROFILE_ROUTE } from '/src/constants/Routes';

function FieldWrapper(): JSX.Element {
  const { values, errors } = useFormikContext<any>();
  const { pathname } = useLocation();

  const isCompleteProfile = pathname.includes(COMPLETE_PROFILE_ROUTE);
  const hasError = errors[InvestorFieldNames.LinkedIn];

  return !isCompleteProfile ||
    hasError ||
    values[InvestorFieldNames.PreferenceContact].includes(
      ContactPreferences.LinkedIn
    ) ? (
    <Field
      component={FormikInput}
      label="LinkedIn URL"
      name={InvestorFieldNames.LinkedIn}
      type="text"
    />
  ) : (
    <></>
  );
}

const LinkedInField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.LinkedIn]: websiteSchema(false),
  }),
};

export default LinkedInField;
