import React from 'react';
import CopyTextButton from '/src/components/CopyTextButton';

export interface CellEmailProps {
  email?: string;
}

function CellEmail({ email }: CellEmailProps) {
  return (
    <>
      {email ? (
        <div className="app-table-row-text">
          <a
            className="truncate hover:underline"
            href={`mailto:${email}`}
            onClick={(e) => e.stopPropagation()}
          >
            {email}
          </a>
          <CopyTextButton text={email} className="absolute ml-1" />
        </div>
      ) : (
        <div className="app-table-row-text">-</div>
      )}
    </>
  );
}

export default CellEmail;
