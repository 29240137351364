import React from 'react';
import { CalendarDaysIcon } from '@heroicons/react/20/solid';

interface PremiumCTAProps {
  id: string;
  href: string;
  icon?: JSX.Element;
  content: string | JSX.Element;
}

function PremiumCTA({
  id,
  href,
  icon = <CalendarDaysIcon className="h-6 w-6 text-white" />,
  content,
}: PremiumCTAProps) {
  return (
    <div className="bg-white shadow sm:rounded-md">
      <a
        target="_blank"
        rel="noopener noreferrer"
        id={id}
        href={href}
        className="flex space-x-2 hover:text-purple-600 hover:underline"
      >
        <div className="bg-purple-600 p-2 sm:rounded-l-md">
          <div className="flex h-full items-center justify-center">{icon}</div>
        </div>
        <span className="p-2 font-medium">{content}</span>
      </a>
    </div>
  );
}

export default PremiumCTA;
