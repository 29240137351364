import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import {
  RAISE_TARGET_MIN,
  RAISE_TARGET_MAX,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import MoneyInput from '/src/components/inputs/MoneyInput';
import {
  roundHelper,
  getHelperTextAvgRaiseTargetRanges,
} from '../RoundHelpers';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  const helper = roundHelper.find(
    (x) =>
      x.roundStage === values[RoundFieldNames.RoundStage] &&
      x.isBridge === (values[RoundFieldNames.IsBridge] === true)
  );

  const secondaryLabel = helper
    ? getHelperTextAvgRaiseTargetRanges(helper)
    : '';

  return isInputShown(RoundFieldNames.RaiseTarget, values) ? (
    <MoneyInput
      disabled={values[RoundFieldNames.IsEditable] === 'false'}
      label="How much money are you planning to raise?"
      name={RoundFieldNames.RaiseTarget}
      secondaryLabel={secondaryLabel}
      tooltip="The total amount of money you intend to raise during the current financing round."
    />
  ) : (
    <></>
  );
}

const RaiseTargetField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.RaiseTarget]: yup
      .number()
      .typeError('Must be a number')
      .min(RAISE_TARGET_MIN, `Must be at least $ ${RAISE_TARGET_MIN}`)
      .test(
        'onlyThousandMultiples',
        'Only multiples of $1000 are accepted',
        (value): boolean => {
          if (value && value > 0 && value % 1000 !== 0) {
            // Return false to indicate the test failed
            return false;
          }
          return true;
        }
      )
      .max(RAISE_TARGET_MAX, `Cannot be greater than $ ${RAISE_TARGET_MAX}`)
      .required('Required'),
  }),
};

export default RaiseTargetField;
