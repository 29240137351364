/* eslint-disable no-await-in-loop */
import { FormikValues } from 'formik';
import { CognitoUploadPublic } from '/../libs/shared-types/src/constants/ApiRoutes';
import { REVENUE_TYPE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import { Founder } from '/../libs/shared-types/src/types/model/Founder';
import FounderFieldNames, {
  FOUNDER_URL_FIELD_NAMES,
} from '/src/enums/FounderFieldNames';
import { ENTITY_TYPE_UNINCORPORATED } from '/../libs/shared-types/src/constants/SelectOptions/EntityTypeOptions';
import StartupFieldNames, {
  STARTUP_URL_FIELDS,
} from '/src/enums/StartupFieldNames';
import { uploadFile } from '/src/util/FileHelpers';
import { updateUrlPrefix } from '/src/util/forms';

/**
 * Cleans the form values and handled the upload of a new profile picture
 * @param formValues
 * @returns
 */
export async function cleanFoundersData(
  formValues: FormikValues
): Promise<Founder[]> {
  const founders = formValues[StartupFieldNames.Founders];
  const cleanFounders: Founder[] = [];
  if (!Array.isArray(founders)) {
    return cleanFounders;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const founder of founders) {
    let cleanFounder = {
      ...founder,
    };

    if (!cleanFounder[FounderFieldNames.CompaniesFoundedCount]) {
      cleanFounder[FounderFieldNames.PreviousBackingAmount] = undefined;
      cleanFounder[FounderFieldNames.PreviousExitAmount] = undefined;
    }

    // All clearable fields must be set to undefined if not explicitly defined
    Object.keys(cleanFounder).forEach((key: any) => {
      if (
        (Array.isArray(cleanFounder[key]) && !cleanFounder[key].length) ||
        cleanFounder[key] === ''
      ) {
        cleanFounder[key] = undefined;
      }
    });

    // Ensure that all url fields start with http:// or https://
    cleanFounder = updateUrlPrefix(cleanFounder, FOUNDER_URL_FIELD_NAMES);

    // Upload profile Picture
    // If the form contains a File, then the user inputted it
    const newProfilePicKey = await uploadFile(
      founder[FounderFieldNames.ProfilePic],
      CognitoUploadPublic.buildEndpoint()
    );
    if (newProfilePicKey) {
      cleanFounder[FounderFieldNames.ProfilePicKey] = newProfilePicKey;
    }

    cleanFounders.push(cleanFounder);
  }
  return Promise.resolve(cleanFounders);
}

/**
 * Some combinations of values are not allowed. This function removes the invalid data from the formikValues.
 * @param formValues
 * @returns A valid set formikValues for a Startup
 */
export function cleanStartupData(formValues: FormikValues): any {
  let cleanValues: FormikValues = {
    ...formValues,

    // Remove any selected Incubators, if HasIncubator is false
    [StartupFieldNames.Incubators]: formValues[StartupFieldNames.HasIncubator]
      ? formValues[StartupFieldNames.Incubators]
      : null,

    // Remove any legal entity details if the entity type is Unincorporated
    [StartupFieldNames.LegalName]:
      formValues[StartupFieldNames.LegalEntityType] ===
      ENTITY_TYPE_UNINCORPORATED
        ? null
        : formValues[StartupFieldNames.LegalName],
    [StartupFieldNames.LegalFormationState]:
      formValues[StartupFieldNames.LegalEntityType] ===
      ENTITY_TYPE_UNINCORPORATED
        ? null
        : formValues[StartupFieldNames.LegalFormationState],
    [StartupFieldNames.CompanyType]:
      formValues[StartupFieldNames.LegalEntityType] ===
      ENTITY_TYPE_UNINCORPORATED
        ? null
        : formValues[StartupFieldNames.CompanyType],

    // Remove hasInventory, if revenueType is not Transactional
    [StartupFieldNames.HasInventory]:
      formValues[StartupFieldNames.RevenueType] ===
      REVENUE_TYPE_OPTIONS[5].value
        ? formValues[StartupFieldNames.HasInventory]
        : null,
    // Remove monthly GMV, if revenueType is not Transactional
    [StartupFieldNames.RevenueMonthlyGmv]:
      formValues[StartupFieldNames.RevenueType] ===
      REVENUE_TYPE_OPTIONS[5].value
        ? formValues[StartupFieldNames.RevenueMonthlyGmv]
        : null,
    // Remove AVG Take rate, if revenueType is not Transactional
    [StartupFieldNames.AvgTakeRate]:
      formValues[StartupFieldNames.RevenueType] ===
      REVENUE_TYPE_OPTIONS[5].value
        ? formValues[StartupFieldNames.AvgTakeRate]
        : null,

    // Remove all customers metrics, if HasCustomers is false
    [StartupFieldNames.CustomerCount]: formValues[
      StartupFieldNames.HasCustomers
    ]
      ? formValues[StartupFieldNames.CustomerCount]
      : null,
    [StartupFieldNames.BookingsAmount]: formValues[
      StartupFieldNames.HasCustomers
    ]
      ? formValues[StartupFieldNames.BookingsAmount]
      : null,
    [StartupFieldNames.CustomerGrowthRateMom]: formValues[
      StartupFieldNames.HasCustomers
    ]
      ? formValues[StartupFieldNames.CustomerGrowthRateMom]
      : null,
    [StartupFieldNames.CustomerRetentionRateMom]: formValues[
      StartupFieldNames.HasCustomers
    ]
      ? formValues[StartupFieldNames.CustomerRetentionRateMom]
      : null,

    // Remove all user counts and metrics, if HasUsers is false
    [StartupFieldNames.UserCount]: formValues[StartupFieldNames.HasUsers]
      ? formValues[StartupFieldNames.UserCount]
      : null,
    [StartupFieldNames.MonthlyActiveUserCount]: formValues[
      StartupFieldNames.HasUsers
    ]
      ? formValues[StartupFieldNames.MonthlyActiveUserCount]
      : null,
    [StartupFieldNames.WeeklyActiveUserCount]: formValues[
      StartupFieldNames.HasUsers
    ]
      ? formValues[StartupFieldNames.WeeklyActiveUserCount]
      : null,
    [StartupFieldNames.DailyActiveUserCount]: formValues[
      StartupFieldNames.HasUsers
    ]
      ? formValues[StartupFieldNames.DailyActiveUserCount]
      : null,
    [StartupFieldNames.UserGrowthRateMom]: formValues[
      StartupFieldNames.HasUsers
    ]
      ? formValues[StartupFieldNames.UserGrowthRateMom]
      : null,
    [StartupFieldNames.UserRetentionRateMom]: formValues[
      StartupFieldNames.HasUsers
    ]
      ? formValues[StartupFieldNames.UserRetentionRateMom]
      : null,
  };

  cleanValues = updateUrlPrefix(cleanValues, STARTUP_URL_FIELDS);

  return cleanValues;
}
