import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import { validateNumeric } from '/src/components/utility/Validators';
import FormField from '/src/interfaces/FormField';
import PercentageInput from '../../inputs/PercentageInput';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.HasCustomers] ? (
    <PercentageInput
      label="Customer Growth (MoM)"
      name={StartupFieldNames.CustomerGrowthRateMom}
    />
  ) : (
    <></>
  );
}

const CustomerGrowthRateMomField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.CustomerGrowthRateMom]: validateNumeric(
      'SIMPLE_PERCENTAGE'
    ).concat(yup.number().nullable()),
  }),
};

export default CustomerGrowthRateMomField;
