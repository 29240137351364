import React, { useState } from 'react';
import { DeckVersionView } from '/../libs/shared-types/src/types/view/DeckVersionView';
import DateString from '/src/components/utility/DateString';
import {
  ArrowDownTrayIcon,
  ArrowsPointingOutIcon,
  BoltIcon,
} from '@heroicons/react/20/solid';
import DebugRender from '/src/components/utility/DebugRender';
import BackendPaginatedTable, {
  PaginatedTableProps,
  isRowBlurred,
} from '/src/components/table/BackendPaginatedTable';
import { joinClassNames } from '/src/util/formatting/strings';
import saveAs from 'file-saver';
import { StartupRequestDeckFeedback } from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import PdfViewer2 from '/src/components/PdfViewer2';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import Toast from '/src/components/notifications/Toast';
import LiveOnOnePagerTag from '/src/components/Tags/LiveOnOnePagerTag';

function downloadDeck(deck: DeckVersionView) {
  API.getPdf(deck.signedUrl).then((res) => {
    res
      .blob()
      .then((data: Blob) => {
        saveAs(data, deck.fileName);
      })
      .catch((error: any) => {
        Logger.error(error);
      });
  });
}

interface DeckVersionsTableRowProps {
  deckVersionView: DeckVersionView;
  className: string;
  isRowBlurred: boolean;
  canAccessDeckVersionHistory: boolean;
  onRequestDeckFeedback: (deckId: string) => void;
  onViewDeck: React.Dispatch<React.SetStateAction<string>>;
}

function DeckVersionsTableRow({
  deckVersionView,
  className,
  isRowBlurred,
  canAccessDeckVersionHistory,
  onRequestDeckFeedback,
  onViewDeck,
}: DeckVersionsTableRowProps) {
  return (
    <tr
      className={joinClassNames(
        'bg-white',
        !isRowBlurred ? '' : 'pointer-events-none select-none',
        className,
      )}
    >
      <td className="app-table-row-item">
        <div className="app-table-row-text space-x-2">
          <span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
            Version {deckVersionView.version.major}.
            {deckVersionView.version.minor}
          </span>
          {deckVersionView.isLatestVersion && <LiveOnOnePagerTag />}
        </div>
        <div className="app-table-row-text--light ml-1 mt-0.5">
          <DateString date={deckVersionView.createdOn} />
        </div>
        <DebugRender>
          <div
            title={'File name: ' + deckVersionView.fileName}
            className="app-table-row-text--light max-w-[140px] truncate"
          >
            {deckVersionView.fileName}
          </div>
        </DebugRender>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {deckVersionView.pageCount > 0 ? deckVersionView.pageCount : '-'}
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="text-[0.78rem] text-gray-900 dark:text-white">
          {deckVersionView.changeReason}
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {deckVersionView.totalViewCount}
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {deckVersionView.uniqueViewersCount}
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {deckVersionView.downloadCount}
        </div>
      </td>
      <td className="space-x-2">
        <button
          type="button"
          onClick={() => downloadDeck(deckVersionView)}
          className="mt-1 inline-flex items-center rounded-full border border-transparent bg-blue-600 px-3 py-1 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          title={`Download ${deckVersionView.fileName}`}
        >
          <ArrowDownTrayIcon className="mr-2 h-4 w-4" />
          Download
        </button>
        <button
          type="button"
          onClick={() => onViewDeck(deckVersionView.signedUrl)}
          className="inline-flex items-center rounded-full border border-transparent bg-blue-600 px-3 py-1 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          title={`View ${deckVersionView.fileName}`}
        >
          <ArrowsPointingOutIcon className="mr-2 h-4 w-4" />
          View
        </button>
        <DebugRender className="inline-flex">
          <button
            type="button"
            onClick={() => onRequestDeckFeedback(deckVersionView._id)}
            disabled={!canAccessDeckVersionHistory}
            className="inline-flex items-center rounded-full border border-transparent bg-purple-600 px-3 py-1 text-xs font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
            title={`Get feedback for ${deckVersionView.fileName}`}
          >
            <BoltIcon className="mr-2 h-4 w-4" />
            Get AI-Feedback
          </button>
        </DebugRender>
      </td>
    </tr>
  );
}

interface DeckVersionsTableProps extends PaginatedTableProps {
  deckVersionViews: DeckVersionView[];
  canAccessDeckVersionHistory: boolean;
}

function DeckVersionsTable({
  deckVersionViews,
  refreshData,
  parentPage,
  parentTotalCount,
  parentSortOrder,
  parentSortedColumn,
  parentFilter,
  parentPerPage,
  headers,
  parentTotalPages,
  blurRowsAfterIndex,
  canAccessDeckVersionHistory,
}: DeckVersionsTableProps) {
  const [deckUrlToView, setDeckUrlToView] = useState('');
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();

  async function requestDeckFeedback(deckId: string) {
    try {
      await API.put(StartupRequestDeckFeedback.buildEndpoint(), { deckId });
      setToastConfig({
        isError: false,
        message:
          'We will send the results to your email, it may take a few minutes',
        title: 'Feedback requested!',
      });
      setTimeout(() => setToastConfig(undefined), 5000);
    } catch (error: any) {
      Logger.error(error);
      setToastConfig({
        isError: true,
        message: error.message,
        title: 'Feedback cannot be requested',
      });
    }
  }

  const rowBlurClass = (index: number) =>
    isRowBlurred(parentPage, index, blurRowsAfterIndex)
      ? 'transform-gpu blur'
      : '';

  return (
    <section>
      <BackendPaginatedTable
        headers={headers}
        refreshData={refreshData}
        parentPage={parentPage}
        parentTotalCount={parentTotalCount}
        parentSortOrder={parentSortOrder}
        parentSortedColumn={parentSortedColumn}
        parentTotalPages={parentTotalPages}
        parentFilter={parentFilter}
        parentPerPage={parentPerPage}
        rowComponents={deckVersionViews.map((data, index) => (
          <DeckVersionsTableRow
            deckVersionView={data}
            key={data._id}
            className={joinClassNames(rowBlurClass(index))}
            isRowBlurred={isRowBlurred(parentPage, index, blurRowsAfterIndex)}
            canAccessDeckVersionHistory={canAccessDeckVersionHistory}
            onRequestDeckFeedback={requestDeckFeedback}
            onViewDeck={setDeckUrlToView}
          />
        ))}
      />

      <ModalWrapper
        open={deckUrlToView !== ''}
        onClose={() => setDeckUrlToView('')}
      >
        <main className="min-h-[380px] w-screen max-w-7xl bg-white p-0 sm:p-2">
          <PdfViewer2 file={deckUrlToView} />
        </main>
      </ModalWrapper>

      <Toast
        isShown={toastConfig !== undefined}
        onClose={() => setToastConfig(undefined)}
        title={toastConfig?.title ?? ''}
        isError={toastConfig?.isError}
        text={toastConfig?.message ?? ''}
      />
    </section>
  );
}

export default DeckVersionsTable;
