import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import PercentageInput from '../../inputs/PercentageInput';
import {
  USER_RETENTION_RATE_MOM_MAX,
  USER_RETENTION_RATE_MOM_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.HasCustomers] ? (
    <PercentageInput
      label="Customer Retention (MoM)"
      name={StartupFieldNames.CustomerRetentionRateMom}
    />
  ) : (
    <></>
  );
}

const CustomerRetentionRateMomField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.CustomerRetentionRateMom]: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .min(
        USER_RETENTION_RATE_MOM_MIN,
        `Must be greater than or equal to ${USER_RETENTION_RATE_MOM_MIN}%`
      )
      .max(
        USER_RETENTION_RATE_MOM_MAX,
        `Cannot be greater than ${USER_RETENTION_RATE_MOM_MAX}%`
      ),
  }),
};

export default CustomerRetentionRateMomField;
