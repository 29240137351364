import Logger from '../services/logger';

export function copyToClipboard(text: string): void {
  navigator.clipboard.writeText(text).then(
    () => null,
    (err) => {
      Logger.error('Could Not Copy to Clipboard', err);
    }
  );
}
