import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { ENTITY_TYPE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/EntityTypeOptions';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import formatGroupLabel from '/src/components/inputs/ReactSelectAdditions/selectFormatGroupLabel';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

const LegalEntityTypeField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      groupLabelFormat={formatGroupLabel}
      label="Legal Entity Type"
      name={StartupFieldNames.LegalEntityType}
      options={ENTITY_TYPE_OPTIONS}
      placeholder="Select legal entity type..."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.LegalEntityType]: yup.string().required('Required'),
  }),
};

export default LegalEntityTypeField;
