import React, { useState } from 'react';
import { BoltIcon } from '@heroicons/react/20/solid';
import { StartupAiFeedbackModal } from './StartupForm/StartupAiFeedbackModal';
import { GenAiTransactionTypes } from '/../libs/shared-types/src/constants/GenAiTransactionTypes';

interface GenAiButtonProps {
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  tooltipText: string;
}

export function GenAiButton({
  disabled = false,
  onClick,
  text,
  tooltipText,
}: GenAiButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className="inline-flex items-center rounded-full border border-transparent bg-purple-600 px-3 py-1 text-xs font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
      title={tooltipText}
    >
      <BoltIcon className="mr-2 h-4 w-4 animate-pulse" />
      {text}
    </button>
  );
}

interface GenAiButtonWithModalProps extends Omit<GenAiButtonProps, 'onClick'> {
  feedbackType: GenAiTransactionTypes;
  valueToGetFeedbackOn: string;
}

export function GenAiButtonWithModal({
  disabled = false,
  feedbackType,
  text,
  tooltipText,
  valueToGetFeedbackOn,
}: GenAiButtonWithModalProps) {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  return (
    <>
      <GenAiButton
        disabled={disabled}
        onClick={() => setIsFeedbackModalOpen(true)}
        text={text}
        tooltipText={tooltipText}
      />

      <StartupAiFeedbackModal
        currentValue={valueToGetFeedbackOn}
        isOpen={isFeedbackModalOpen}
        setIsOpen={setIsFeedbackModalOpen}
        feedbackType={feedbackType}
      />
    </>
  );
}
