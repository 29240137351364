import React, { useContext, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AggregateDealShareRequest } from '/../libs/shared-types/src/types/view/APIResponse';
import {
  fetchConnectionRequests,
  fetchOutgoingDealShareRequests,
  fetchReceivedDealShareRequests,
} from '/src/services/InvestorRequests';
import { InvestorRequestsContext } from '/src/contexts/InvestorRequestsContext';
import { SUCCESS } from '/src/constants/SuccessMessages';
import { toDashCase } from '/src/util/formatting/strings';
import API from '/src/middleware/API';
import DealAccessRequestsMadeTable from './DealAccessRequestsMadeTable';
import DealAccessRequestsReceivedTable from './DealAccessRequestsReceivedTable';
import Logger from '/src/services/logger';
import PageHeader from '/src/components/PageHeader';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import TabsHeader from '/src/components/tabs/TabsHeader';
import Toast from '/src/components/notifications/Toast';
import SearchBar from '/src/components/inputs/SearchBar';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';
import {
  InvestorRequestsAccept,
  InvestorRequestsReject,
} from '/../libs/shared-types/src/constants/ApiRoutes';

function DealAccessRequests(): JSX.Element {
  const [requestsMade, setRequestsMade] = useState<AggregateDealShareRequest[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isActionTakenAlertShown, setIsActionTakenAlertShown] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [userQuery, setUserQuery] = useState('');
  const { accessRequests, setAccessRequests, setConnectionRequests } =
    useContext(InvestorRequestsContext);
  const tabs = [
    { name: 'Requests Received', stat: accessRequests?.length },
    { name: 'Requests Made', stat: requestsMade.length },
  ];

  async function queryRequestsMade(query: string) {
    try {
      setIsSearchLoading(true);
      setUserQuery(query);
      const data = await fetchOutgoingDealShareRequests(query);
      setRequestsMade(data.reverse());
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsSearchLoading(false);
    }
  }

  async function queryRequestsReceived(query: string) {
    try {
      setIsSearchLoading(true);
      setUserQuery(query);
      const data = await fetchReceivedDealShareRequests(query);
      setAccessRequests(data.reverse());
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsSearchLoading(false);
    }
  }

  async function fetchRequestsMade() {
    try {
      setIsLoading(true);
      const data = await fetchOutgoingDealShareRequests();
      setRequestsMade(data.reverse());
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchRequestsReceived() {
    try {
      setIsLoading(true);
      const data = await fetchReceivedDealShareRequests();
      setAccessRequests(data.reverse());
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function showAlert() {
    setIsActionTakenAlertShown(true);
    window.setTimeout(() => setIsActionTakenAlertShown(false), 8000);
  }

  async function onAcceptRequest(requestId: string) {
    try {
      await API.put(InvestorRequestsAccept.buildEndpoint(), { requestId });
      const connectionRequests = await fetchConnectionRequests();
      setConnectionRequests(connectionRequests);
      setAccessRequests(
        (accessRequests ?? []).filter(
          (request: AggregateDealShareRequest) =>
            request.request._id !== requestId
        )
      );
      setToastMessage('The deal was shared');
      showAlert();
    } catch (error: any) {
      Logger.error(error);
    }
  }

  async function onRejectRequest(requestId: string) {
    try {
      await API.put(InvestorRequestsReject.buildEndpoint(), { requestId });
      setAccessRequests(
        (accessRequests ?? []).filter(
          (request: AggregateDealShareRequest) =>
            request.request._id !== requestId
        )
      );
      setToastMessage('You rejected their request');
      showAlert();
    } catch (error: any) {
      Logger.error(error);
    }
  }

  React.useEffect(() => {
    fetchRequestsMade();
    fetchRequestsReceived();
  }, []);

  return (
    <main>
      <header>
        <PageHeader title="Access Requests" />
        <TabsHeader tabs={tabs} />
      </header>
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {isLoading && (
            <PageLoadingSpinner message="Loading requests to access your deals... 🤙" />
          )}
          <Routes>
            {['', tabs[0].name].map((path) => (
              <Route
                key={path}
                path={toDashCase(path)}
                element={
                  <div>
                    <div className="mb-3 flex flex-row items-center">
                      <SearchBar
                        isDebounce
                        placeholder="Search Requests Received"
                        onQueryChange={queryRequestsReceived}
                      />
                      {isSearchLoading && <LoadingSpinner color="blue" />}
                    </div>
                    <DealAccessRequestsReceivedTable
                      isLoading={isLoading}
                      isQuerying={userQuery !== ''}
                      requests={accessRequests ?? []}
                      onAcceptRequest={onAcceptRequest}
                      onRejectRequest={onRejectRequest}
                    />
                  </div>
                }
              />
            ))}

            <Route
              path={toDashCase(tabs[1].name)}
              element={
                <div>
                  <div className="mb-3 flex flex-row items-center">
                    <SearchBar
                      isDebounce
                      placeholder="Search Requests Made"
                      onQueryChange={queryRequestsMade}
                    />
                    {isSearchLoading && <LoadingSpinner color="blue" />}
                  </div>
                  <DealAccessRequestsMadeTable
                    isLoading={isLoading}
                    isQuerying={userQuery !== ''}
                    requests={requestsMade}
                  />
                </div>
              }
            />
            <Route
              path="*"
              element={<Navigate to={NOT_FOUND_ROUTE} replace />}
            />
          </Routes>
        </div>
      </div>
      <Toast
        isShown={isActionTakenAlertShown}
        onClose={() => setIsActionTakenAlertShown(false)}
        title={SUCCESS}
        isError={false}
        text={toastMessage}
      />
    </main>
  );
}

export default DealAccessRequests;
