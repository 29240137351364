import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { LOGIN_ROUTE } from '/src/constants/Routes';

interface WaitScreenProps {
  isLoading: boolean;
}

function GoogleWaitScreen({ isLoading }: WaitScreenProps): JSX.Element {
  const navigate = useNavigate();
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      {isLoading ? (
        <>
          <h1 className="font-gray-800 text-4xl font-bold">
            Logging you in... 🚀
          </h1>
          <div className="font-gray-800 py-4 text-2xl font-medium">
            <LoadingSpinner color="blue" size={8} />
          </div>
        </>
      ) : (
        <>
          <h1 className="font-gray-800 text-4xl font-bold">
            Hmm ... something went wrong
          </h1>
          <button
            type="button"
            onClick={() => navigate(LOGIN_ROUTE)}
            className="button my-4"
          >
            Take me back to log in
          </button>
        </>
      )}
    </div>
  );
}

export default GoogleWaitScreen;
