import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import { FIRM_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import { capitalize, isBlank } from '/src/util/formatting/strings';

export function FirmInput({ label }: { label?: string }): JSX.Element {
  return (
    <Field
      component={FormikInput}
      label={label ?? 'Firm'}
      name={InvestorFieldNames.Firm}
      type="text"
    />
  );
}

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  const label = `Firm ${
    values[InvestorFieldNames.Type] === InvestorTypes.Angel ? '(Optional)' : ''
  }`;

  return values[InvestorFieldNames.Type] && <FirmInput label={label} />;
}

const FirmField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.Firm]: yup
      .string()
      .nullable()
      .max(FIRM_LENGTH_MAX, `Must be at most ${FIRM_LENGTH_MAX} characters`)
      .test({
        name: 'bothFirmAndRoleMustBeSet',
        exclusive: false,
        params: {},
        message: `You must add a ${capitalize(InvestorFieldNames.Role)} too`,
        test(value) {
          if (value && isBlank(this.parent[InvestorFieldNames.Role])) {
            return false;
          }
          return true;
        },
      })
      .when(InvestorFieldNames.Type, {
        is: (type: string | undefined) => type && type !== InvestorTypes.Angel,
        then: (schema) => schema.required('Required'),
        // otherwise: (schema) => schema,
      }),
    // .when(InvestorFieldNames.Type, ([type]: string[], schema) =>
    //   type?.length > 0 && type !== InvestorTypes.Angel
    //     ? schema.required('Required')
    //     : schema
    // )
  }),
};

export default FirmField;
