import React from 'react';
import * as yup from 'yup';
import FormikInput from '/src/components/inputs/FormikInput';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import FormField from '/src/interfaces/FormField';
import { Field } from 'formik';
import passwordSchema from '/src/constants/validation/passwordSchema';

/**
 * This field is used when a new password is being created,
 * such as at Sign Up and at Reset Password
 */
const PasswordCreateField: FormField = {
  fieldComponent: (
    <Field
      component={FormikInput}
      label="Password"
      name={AuthFieldNames.PasswordCreate}
      type="password"
    />
  ),
  validation: yup.object({
    [AuthFieldNames.PasswordCreate]: passwordSchema
      .notOneOf(
        [yup.ref(AuthFieldNames.Email)],
        'Password cannot be the same as email'
      )
      .required('Required'),
  }),
};

export default PasswordCreateField;
