import React from 'react';
import { abbreviateNumber } from '../util/formatting/numbers';
import { getLatestValuationCap as getLatestValuation } from '../util/rounds';
import BasicStatistic from './cards/BasicStatistic';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';

interface RoundHistoryKpisProps {
  roundHistory: RoundView[];
}

function RoundHistoryKpis({
  roundHistory,
}: RoundHistoryKpisProps): JSX.Element {
  const latestValuation = getLatestValuation(roundHistory);
  return (
    <section className="mb-4 grid w-full grid-cols-2 gap-3 lg:grid-cols-3 lg:gap-5">
      <BasicStatistic
        label="Total Funding Rounds"
        statistic={roundHistory.length}
      />
      <BasicStatistic
        label="Total Raised to Date"
        statistic={`$ ${abbreviateNumber(
          roundHistory
            .map((round) => round.raisedAmount)
            .reduce((x, y) => x + y)
        )}`}
      />
      {latestValuation.valuationCap && (
        <div className="col-span-2 lg:col-span-1">
          <BasicStatistic
            label="Last Valued At"
            statistic={`$ ${abbreviateNumber(latestValuation.valuationCap)}`}
            suffix={latestValuation.valuationCapType}
          />
        </div>
      )}
    </section>
  );
}

export default RoundHistoryKpis;
