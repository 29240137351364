import React, { useState } from 'react';
import { FieldProps, getIn } from 'formik';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { FileType } from '/src/constants/FileUploadTypes';
import { getMaxFileSize } from '/src/util/FileHelpers';
import FormikDebugger from './FormikDebugger';
import FormikErrorMessage from './FormikErrorMessage';
import DebugRender from '/src/components/utility/DebugRender';
import { formatImageAddress } from '/src/util/formatting/strings';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import SquaredLogo from '../SquaredLogo';

function getPreviewImage(previewUrl: string | undefined): JSX.Element {
  if (previewUrl) {
    return (
      <SquaredLogo
        containerClassName="mx-auto size-20 border-2 border-solid border-gray-300"
        src={previewUrl}
        alt="Preview"
      />
    );
  }
  return <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />;
}

interface ImageInputProps extends FieldProps {
  canRemoveFile?: boolean;
  disabled?: boolean;
  label: string;
}

const ImageInput = ({
  canRemoveFile = false,
  field,
  form,
  disabled = false,
  label,
  meta,
}: ImageInputProps): JSX.Element => {
  const [imageReaderResult, setImageReaderResult] = useState<any>();
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  function onInputValueChange(): void {
    form.setFieldValue(`${field.name}MustValidate`, true);
    form.setFieldTouched(field.name, true);
    form.setFieldValue(`${field.name}Key`, null);
  }

  function deleteFileDataAndResetPreview(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    event.preventDefault();
    event.stopPropagation();

    form.setFieldValue(field.name, null);
    onInputValueChange();
    // Reset the file reader result to clear the preview
    setImageReaderResult(null);
  }

  const readFileDataAndUpdatePreview = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      form.setFieldValue(field.name, file);
      onInputValueChange();
      // Store the file reader result so that the preview can be updated
      setImageReaderResult(reader.result);
      setIsLoadingFile(false);
    };

    reader.readAsDataURL(file);
    setIsLoadingFile(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const input = e.target as HTMLInputElement;
    const file = input.files ? input.files[0] : null;

    if (!file) {
      return;
    }

    readFileDataAndUpdatePreview(file);
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    // This click handler is needed for Safari compatibility
    // Safari buttons do not receive focus, so the onBlur would never fire
    const input = event.currentTarget;
    input.focus();
  };

  function getFile() {
    return field.value;
  }

  function getFileKey() {
    return getIn(form.values, `${field.name}Key`);
  }

  // Re-render component whenever the Formik field value changes
  // Remember, the field contains the File data, not the file key
  React.useEffect(() => {
    // Must reset the ImageReaderResult if:
    // - there is no File but there is a key,
    // - OR there is no File, no key, but there is a file in the Reader
    if (
      (!getFile() && getFileKey()) ||
      (!getFile() && !getFileKey() && imageReaderResult)
    ) {
      setImageReaderResult(null);
      return;
    }

    if (getFile()) {
      readFileDataAndUpdatePreview(field.value);
    }
  }, [field.value]);

  return (
    <div>
      <label htmlFor={field.name} className="input-label">
        {label}
        <div className="mt-2 flex justify-center rounded-md border-2 border-solid border-gray-300 px-6 pt-5 pb-6">
          <div className="space-y-1 text-center">
            {!isLoadingFile &&
              getPreviewImage(
                imageReaderResult ?? formatImageAddress(getFileKey()),
              )}

            {isLoadingFile && (
              <div className="mx-auto flex h-20 w-20 content-center items-center justify-center rounded-full border-2 border-solid border-gray-300 bg-gray-200">
                <LoadingSpinner size={8} color="slate" />
              </div>
            )}

            <div className="flex justify-center text-sm text-gray-600">
              <div className="relative cursor-pointer rounded-md bg-white font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500">
                <span>Click to upload a file ({FileType.Image})</span>
                <input
                  accept={FileType.Image.toString()}
                  className="sr-only"
                  disabled={disabled}
                  id={field.name}
                  name={field.name}
                  onBlur={() => form.setFieldTouched(field.name, true)}
                  onChange={handleChange}
                  onClick={handleClick}
                  type="file"
                />
              </div>
            </div>
            <p className="text-xs text-gray-500">
              {`File up to ${getMaxFileSize(FileType.Image)}MB`}
            </p>

            {canRemoveFile && (getFileKey() || getFile()) && (
              <button
                type="button"
                className="mt-6 cursor-pointer rounded-md bg-white text-sm font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500"
                onClick={deleteFileDataAndResetPreview}
              >
                Remove File
              </button>
            )}
          </div>
        </div>
      </label>
      <FormikErrorMessage field={field} form={form} meta={meta} />
      <FormikDebugger field={field} form={form} meta={meta} />
      <DebugRender className="max-w-md truncate px-4 text-2xs">
        <pre>imageReaderResult: {JSON.stringify(imageReaderResult)}</pre>
        <pre>
          {field.name}:{JSON.stringify(getIn(form.values, `${field.name}`))}
        </pre>
        <pre>
          {field.name}Key:
          {JSON.stringify(getIn(form.values, `${field.name}Key`))}
        </pre>
        <pre>
          {field.name}MustValidate:
          {JSON.stringify(getIn(form.values, `${field.name}MustValidate`))}
        </pre>
      </DebugRender>
    </div>
  );
};

export default ImageInput;
