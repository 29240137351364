import { Deal, PriorityItem, RatingItem } from '../../../types/model/Deal';
import {
  FitScoreLabels,
  FitScoreReasonType,
} from '../../../types/model/FitScore';
import { Stage, StageStatus } from '../../../types/model/Stage';
import {
  ActiveDealSummaryView,
  AggregatedActiveDeal,
  AggregatedDeal,
  DealDetailData,
  DealSummaryView,
  ScreeningDealSummaryView,
} from '../../../types/view/AggregatedDeals';
import {
  InvestorAggregateAccessDataView,
  ShareHistory,
} from '../../../types/view/APIResponse';
import { InvestorActivityView } from '../../../types/view/InvestorActivityView';
import { DealStatusTypes } from '../../DealStatusTypes';
import { ShareTypes } from '../../ShareTypes';
import { DAY_MS } from '../../time';
import { EXAMPLE_INVESTOR } from './ExampleInvestor';
import { EXAMPLE_CURRENT_ROUND, EXAMPLE_STARTUP } from './ExampleStartup';

export const EXAMPLE_BOARD_STAGES: Stage[] = [
  {
    _id: 'Stage1',
    name: 'FIRST CALL',
    duration: 3 * DAY_MS,
  },
  {
    _id: 'Stage2',
    name: 'SECOND CALL',
    duration: 3 * DAY_MS,
  },
  {
    _id: 'Stage3',
    name: 'DUE DILIGENCE',
    duration: 5 * DAY_MS,
  },
  {
    _id: 'Stage4',
    name: 'DOCS & WIRE',
    duration: 2 * DAY_MS,
  },
];

const BOARD_STAGE_STATUS: StageStatus = {
  name: EXAMPLE_BOARD_STAGES[0].name,
  duration: EXAMPLE_BOARD_STAGES[0].duration,
  // durationLeft: EXAMPLE_BOARD_STAGES[0].duration - 1, // This broke the api compilation.
  createdOn: new Date('2022-10-29T22:02:03.795Z'),
};

const EXAMPLE_DEAL: Deal = {
  // _id: "63390a756a216768325e5753",
  startupId: EXAMPLE_STARTUP._id,
  createdOn: new Date('2022-10-02T03:50:13.012Z'),
  receivedFrom: [
    {
      // _id: "63390a756a216768325e5754",
      accountId: EXAMPLE_INVESTOR._id,
      createdOn: new Date('2022-10-02T03:50:13.012Z'),
      isInvestor: true,
      message: 'Welcome to Flowlie! Check out this example deal',
      shareType: ShareTypes.DealshareInApp,
    },
  ],
  investorActivity: {
    statusHistory: [
      {
        status: DealStatusTypes.Screening,
        statusChangedOn: new Date('2022-10-02T03:50:13.012Z'),
        isAutomaticAction: true,
      },
      {
        status: DealStatusTypes.Active,
        statusChangedOn: new Date('2022-10-29T22:02:03.795Z'),
        isAutomaticAction: false,
        boardStageHistory: [
          {
            name: BOARD_STAGE_STATUS.name,
            createdOn: BOARD_STAGE_STATUS.createdOn,
          },
        ],
      },
    ],
    priorityHistory: [],
    ratingHistory: [],
  },
};

const SHARE_HISTORY: ShareHistory[] = [
  {
    message: 'Welcome to Flowlie! Check out this example deal',
    name: EXAMPLE_INVESTOR.firstName + ' ' + EXAMPLE_INVESTOR.lastName,
    sharedOn: new Date('2022-10-02T03:50:13.012Z'),
    shareType: ShareTypes.DealshareInApp,
  },
];

const PRIORITY: PriorityItem = {
  createdOn: new Date('2022-10-02T03:50:13.012Z'),
  priority: -1,
  isAutomaticAction: true,
};

const RATING: RatingItem = {
  createdOn: new Date('2022-10-02T03:50:13.012Z'),
  rating: -1,
  isAutomaticAction: true,
};

const INVESTOR_AGGREGATE_ACCES_DATA_VIEW: InvestorAggregateAccessDataView = {
  receivedFrom: [
    {
      accountId: EXAMPLE_INVESTOR._id,
      picKey: EXAMPLE_INVESTOR.profilePicKey,
      name: EXAMPLE_INVESTOR.firstName + ' ' + EXAMPLE_INVESTOR.lastName,
      userType: 'VC',
      roundStage: 'Pre-Seed',
      roundDisplayName: 'Pre-Seed',
      shareType: ShareTypes.DealshareInApp,
      sharedOn: new Date('2022-10-02T03:50:13.012Z'),
      currentStatus: {
        status: DealStatusTypes.Active,
        statusChangedOn: new Date('2022-12-03T23:20:30.463Z'),
        isAutomaticAction: false,
      },
      firm: EXAMPLE_INVESTOR.firm,
      role: EXAMPLE_INVESTOR.role,
      investedAt: ['Pre-Seed'],
      message: 'Welcome to Flowlie! Check out this example deal',
    },
  ],
  sharedWith: [],
};

const INVESTOR_ACTIVITY_VIEW: InvestorActivityView = {
  lastViewedOn: new Date('2023-02-08T01:38:04.342Z'),
  totalViewTime: 42000,
  deckStats: [],
  hasAccessedLatestDeck: true,
  statusHistory: [
    {
      status: DealStatusTypes.Screening,
      statusChangedOn: new Date('2022-10-02T03:50:13.012Z'),
      roundDisplayName: 'Pre-Seed',
      stage: 'Pre-Seed',
      hasQsbsPotential: false,
      roundParticipation: 0,
      boardStageHistory: [],
    },
    {
      status: DealStatusTypes.Active,
      statusChangedOn: new Date('2022-10-29T22:02:03.795Z'),
      boardStageHistory: [
        {
          name: BOARD_STAGE_STATUS.name,
          createdOn: BOARD_STAGE_STATUS.createdOn,
        },
      ],
      roundDisplayName: 'Pre-Seed',
      stage: 'Pre-Seed',
      hasQsbsPotential: false,
      roundParticipation: 0,
    },
  ],
  priorityHistoryPerRound: [
    {
      roundDisplayName: 'Angel',
      roundOpenedOn: new Date('2022-04-09T23:18:35.519Z'),
      priorities: [
        {
          createdOn: new Date('2022-04-25T00:00:00.000Z'),
          priority: -1,
          isAutomaticAction: true,
        },
      ],
    },
    {
      roundDisplayName: 'Pre-Seed',
      roundOpenedOn: new Date('2022-05-08T20:32:32.397Z'),
      priorities: [
        {
          createdOn: new Date('2022-10-02T03:50:13.012Z'),
          priority: -1,
          isAutomaticAction: true,
        },
      ],
    },
  ],
  ratingHistoryPerRound: [
    {
      roundDisplayName: 'Angel',
      roundOpenedOn: new Date('2022-04-09T23:18:35.519Z'),
      ratings: [
        {
          createdOn: new Date('2022-04-25T00:00:00.000Z'),
          rating: -1,
          isAutomaticAction: true,
        },
      ],
    },
    {
      roundDisplayName: 'Pre-Seed',
      roundOpenedOn: new Date('2022-05-08T20:32:32.397Z'),
      ratings: [
        {
          createdOn: new Date('2022-10-02T03:50:13.012Z'),
          rating: -1,
          isAutomaticAction: true,
        },
      ],
    },
  ],
};

export const EXAMPLE_STARTUP_AGGREGATED_DEAL: AggregatedDeal = {
  isRoundOpen: true,
  currentDealStatus: {
    status: DealStatusTypes.Screening,
    statusChangedOn: new Date(Date.now() - DAY_MS),
  },
  currentRound: EXAMPLE_CURRENT_ROUND,
  deal: EXAMPLE_DEAL,
  startup: EXAMPLE_STARTUP,
  shareHistory: SHARE_HISTORY,
  fitScore: {
    score: 90,
    label: FitScoreLabels.VeryHigh,
    reasons: [
      {
        type: FitScoreReasonType.Good,
        text: 'invests in Fin-Tech',
      },
      {
        type: FitScoreReasonType.Good,
        text: 'invests primarily in Seed rounds and sometimes leads',
      },
      {
        type: FitScoreReasonType.Agnostic,
        text: 'invests anywhere across the globe',
      },
      {
        type: FitScoreReasonType.Bad,
        text: "doesn't prefer B2C models",
      },
    ],
  },
  priority: PRIORITY,
  rating: RATING,
};

export const EXAMPLE_STARTUP_AGGREGATED_ACTIVE_DEAL: AggregatedActiveDeal = {
  ...EXAMPLE_STARTUP_AGGREGATED_DEAL,
  isPortfolioDeal: false,
  activeBoardStage: BOARD_STAGE_STATUS,
};

export const EXAMPLE_STARTUP_DEAL_SUMMARY_VIEW: DealSummaryView = {
  canBeUnsolicitedShare: false,
  createdOn: EXAMPLE_DEAL.createdOn,
  currentDealStatusType: DealStatusTypes.Screening,
  currentRound: EXAMPLE_CURRENT_ROUND,
  currentStage: EXAMPLE_STARTUP.currentStage,
  geography: EXAMPLE_STARTUP.geography,
  industries: EXAMPLE_STARTUP.industries,
  rating: RATING,
  startupId: EXAMPLE_STARTUP._id,
  startupLogoKey: EXAMPLE_STARTUP.logoKey,
  startupName: EXAMPLE_STARTUP.name,
  startupPublicProfilePrivacySetting: EXAMPLE_STARTUP.flowLink.privacySetting,
  website: EXAMPLE_STARTUP.website,
};

export const EXAMPLE_STARTUP_SCREENING_DEAL_SUMMARY_VIEW: ScreeningDealSummaryView =
  {
    ...EXAMPLE_STARTUP_DEAL_SUMMARY_VIEW,
    message: SHARE_HISTORY[0].message,
    sharedByDate: new Date(),
    sharedByName: `${EXAMPLE_INVESTOR.firstName} ${EXAMPLE_INVESTOR.lastName}`,
    fitScore: {
      score: 90,
      label: FitScoreLabels.VeryHigh,
      reasons: [
        {
          type: FitScoreReasonType.Good,
          text: 'invests in Fin-Tech',
        },
        {
          type: FitScoreReasonType.Good,
          text: 'invests primarily in Seed rounds and sometimes leads',
        },
        {
          type: FitScoreReasonType.Agnostic,
          text: 'invests anywhere across the globe',
        },
        {
          type: FitScoreReasonType.Bad,
          text: "doesn't prefer B2C models",
        },
      ],
    },
    isCurrentRoundOpen: true,
    hasEverBeenPassed: false,
  };

export const EXAMPLE_STARTUP_ACTIVE_DEAL_SUMMARY_VIEW: ActiveDealSummaryView = {
  ...EXAMPLE_STARTUP_DEAL_SUMMARY_VIEW,
  currentDealStatusType: DealStatusTypes.Active,
  currentActiveBoardStage: BOARD_STAGE_STATUS,
  isPortfolioDeal: false,
  priority: PRIORITY,
};

export const EXAMPLE_DEAL_DETAIL_DATA: DealDetailData = {
  ...EXAMPLE_STARTUP_AGGREGATED_DEAL,
  aggregateSharingData: INVESTOR_AGGREGATE_ACCES_DATA_VIEW,
  investorActivity: INVESTOR_ACTIVITY_VIEW,
  investments: [],
  dealSummaryView: EXAMPLE_STARTUP_DEAL_SUMMARY_VIEW,
};
