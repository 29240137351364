import React from 'react';
import { useLocation } from 'react-router-dom';
import { toDashCase } from '/src/util/formatting/strings';
import CustomNavLink from '../CustomNavLink';
import { getRouteBasePath } from '/src/util/formatting/routes';

interface SecondaryTabsHeaderProps {
  tabNames: string[];
}

function SecondaryTabsHeader({
  tabNames,
}: SecondaryTabsHeaderProps): JSX.Element {
  const { pathname } = useLocation();

  function generateTabs() {
    return (
      <nav className="flex items-center space-x-2" aria-label="Tabs">
        <p className="text-base font-medium text-gray-500 sm:text-sm">Show:</p>
        {tabNames.map((tab) => (
          <CustomNavLink
            activeClassName="app-table-filter--active"
            className="cursor-pointer text-base sm:text-sm"
            fullPath={`${getRouteBasePath(pathname, tabNames)}/${toDashCase(
              tab
            )}`}
            inactiveClassName="app-table-filter"
            isFirstTab={tabNames.indexOf(tab) === 0 ?? false}
            key={tab}
            to={toDashCase(tab)}
          >
            {tab}
          </CustomNavLink>
        ))}
      </nav>
    );
  }

  return <div className="block">{generateTabs()}</div>;
}

export default SecondaryTabsHeader;
