import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import { validateFileSize, validateFileType } from '/src/util/FileHelpers';
import { FileType } from '/src/constants/FileUploadTypes';
import FormField from '/src/interfaces/FormField';
import ImageInput from '/src/components/inputs/ImageInput';

function FieldWrapper(): JSX.Element {
  return (
    <Field
      canRemoveFile
      component={ImageInput}
      label="Upload a profile picture"
      name={InvestorFieldNames.ProfilePic}
    />
  );
}

const ProfilePicField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.ProfilePic]: yup
      .mixed()
      .when(`${InvestorFieldNames.ProfilePic}MustValidate`, {
        is: (hasChanged: boolean) => hasChanged === true,
        then: (schema) =>
          schema
            .nullable()
            .test(
              'fileFormat',
              'Unsupported file format',
              (value: any) =>
                !value ||
                (value instanceof File &&
                  validateFileType(value, FileType.Image)),
            )
            .test(
              'fileSize',
              'Uploaded file is too big',
              (value: any) =>
                !value ||
                (value instanceof File &&
                  validateFileSize(value.size, FileType.Image)),
            ),
      }),
  }),
};
export default ProfilePicField;
