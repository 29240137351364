import React from 'react';

import FounderInfo from '/src/components/FounderInfo';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import VideoPlayer from '/src/components/utility/VideoPlayer';
import { getPluralSuffix } from '/src/util/formatting/numbers';

interface DealDetailFoundersCardProps {
  startup: PublicStartup;
}

function DealDetailFoundersCard({
  startup,
}: DealDetailFoundersCardProps): JSX.Element {
  return (
    <section aria-labelledby="startup-founders-information-title">
      <div className="rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Founders
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-y-12">
            {startup.founders.map((founder) => (
              <FounderInfo key={founder.email} founder={founder} />
            ))}
            {startup.teamPresentationVideoUrl && (
              <div className="col-span-1 max-w-[50%]">
                <dt className="sr-only text-sm font-medium text-gray-500">
                  Meet the founder{getPluralSuffix(startup.founders.length)}
                </dt>
                <VideoPlayer
                  videoTitle={`Meet the founder${getPluralSuffix(
                    startup.founders.length
                  )} of ${startup.name}!`}
                  urlSrc={startup.teamPresentationVideoUrl}
                />
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
}

export default DealDetailFoundersCard;
