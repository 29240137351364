import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { NewInvestorFirstLogInSteps } from '/src/components/UserActivation/NewInvestorFirstLoginTour';
import { NewStartupFirstLogInSteps } from '/src/components/UserActivation/NewStartupFirstLoginTour';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import Logger from '/src/services/logger';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';

function StartActivationTourRoute(): JSX.Element {
  const navigate = useNavigate();

  const {
    joyrideActivationTourState,
    setJoyrideActivationTourState,
    setIsActivationTourStopped,
  } = useContext(SharedStateContext);

  const { accountType } = useContext(AccountMetadataContext);

  useEffect(() => {
    const isTourRunning = localStorage.getItem(
      LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING
    );
    if (isTourRunning) {
      return;
    }

    Logger.info('Give me tour', joyrideActivationTourState);
    let tourSteps = [];
    if (accountType === AccountTypes.Investor) {
      tourSteps = NewInvestorFirstLogInSteps;
    } else if (accountType === AccountTypes.Startup) {
      tourSteps = NewStartupFirstLogInSteps;
    } else {
      return;
    }

    setIsActivationTourStopped(false);
    navigate(tourSteps[0].redirectUrl);
    setJoyrideActivationTourState({
      ...joyrideActivationTourState,
      run: true,
    });
    localStorage.setItem(
      LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING,
      'true'
    );

    Logger.info('After', joyrideActivationTourState);
  }, []);

  return <></>;
}

export default StartActivationTourRoute;
