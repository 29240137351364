import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { HowOftenType } from '/../libs/shared-types/src/constants/HowOftenType';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { enumToSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';

const TakesBoardSeatsField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      closeMenuOnSelect
      isClearable
      label="Do you take board seats?"
      name={InvestorFieldNames.TakesBoardSeats}
      options={enumToSelectOptions(HowOftenType)}
      placeholder="Select option..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.TakesBoardSeats]: yup.string().nullable(),
  }),
};

export default TakesBoardSeatsField;
