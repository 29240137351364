import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import { BOOLEAN_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';
import { getHelperTextIsLeadRequired, roundHelper } from '../RoundHelpers';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  const helper = roundHelper.find(
    (x) =>
      x.roundStage === values[RoundFieldNames.RoundStage] &&
      x.isBridge === (values[RoundFieldNames.IsBridge] === true),
  );
  const secondaryLabel = helper ? getHelperTextIsLeadRequired(helper) : '';

  return isInputShown(RoundFieldNames.HasLeadInvestor, values) ? (
    <Field
      className="custom-select"
      closeMenuOnSelect
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      component={CustomSelect}
      label="Has a lead investor formally committed?"
      name={RoundFieldNames.HasLeadInvestor}
      options={BOOLEAN_OPTIONS}
      tooltip="A lead investor is an investor who commits to invest a significant portion of the round (40-60% of the total raise) and plays a central role in helping you get the rest of the round closed."
      secondaryLabel={secondaryLabel}
    />
  ) : (
    <></>
  );
}

const HasLeadInvestorField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.HasLeadInvestor]: yup
      .string()
      .nullable()
      .when(RoundFieldNames.RoundType, {
        is: (roundType: string) => roundType !== RoundTypes.ToBeDetermined,
        then: (schema) => schema.required('Required'),
      }),
  }),
};

export default HasLeadInvestorField;
