import API from '../middleware/API';
import {
  InvestorUpdateDealPriority,
  InvestorUpdateDealRating,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { Priority } from '/../libs/shared-types/src/constants/Priority';
import { StarRating } from '/../libs/shared-types/src/constants/StarRating';
import {
  PriorityItem,
  RatingItem,
} from '/../libs/shared-types/src/types/model/Deal';

export async function updateDealPriority(
  priority: Priority,
  startupId: string
) {
  const updatedPriority = await API.put<PriorityItem>(
    InvestorUpdateDealPriority.buildEndpoint(),
    {
      startupId,
      priority,
    }
  );

  return updatedPriority;
}

export async function updateDealRating(rating: StarRating, startupId: string) {
  const updatedRating = await API.put<RatingItem>(
    InvestorUpdateDealRating.buildEndpoint(),
    {
      startupId,
      rating,
    }
  );

  return updatedRating;
}
