import React, { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { joinClassNames } from '/src/util/formatting/strings';
import {
  SubscriptionTiers,
  SubscriptionTiersNameMap,
} from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { SubscriptionTierView } from './Tiers';
import { BillingFrequency } from '/../libs/shared-types/src/constants/BillingFrequency';
import { MARK_SALES_CALL } from '../../../../constants/Marketing/CalendlyLinks';

const billingFrequencies = [
  { label: BillingFrequency.Monthly, priceSuffix: '/month' },
  { label: BillingFrequency.Annually, priceSuffix: '/year' },
];

interface SubscriptionTiersSectionProps {
  currentTier: SubscriptionTiers;
  hasUsedTrial: boolean;
  description: string;
  footnote: string;
  header: string;
  onUpgradeSubscription: (
    billingFrequency: BillingFrequency,
    subscriptionTier: SubscriptionTiers,
  ) => void;
  tiers: SubscriptionTierView[];
}

export default function SubscriptionTiersSection({
  currentTier,
  hasUsedTrial,
  description,
  footnote,
  header,
  onUpgradeSubscription,
  tiers,
}: SubscriptionTiersSectionProps) {
  const [frequency, setFrequency] = useState(billingFrequencies[0]);

  return (
    <div className="mt-6 pb-10">
      <div className="mx-auto max-w-[90rem] px-6 lg:px-8">
        <div className="mx-auto max-w-7xl text-center">
          <h2 className="sr-only text-base font-semibold leading-7 text-blue-600">
            Manage Subscription
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {header}
          </p>
        </div>
        <p className="mx-auto mt-6 text-center text-lg leading-8 text-gray-600">
          {description}
          {!hasUsedTrial && (
            <>
              <br />
              Try 14 days for free.
            </>
          )}
        </p>
        <div className="mt-12 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full bg-white p-1 text-center text-sm font-medium leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">
              Payment frequency
            </RadioGroup.Label>
            {billingFrequencies.map((option) => (
              <RadioGroup.Option
                key={option.label}
                value={option}
                className={({ checked }) =>
                  joinClassNames(
                    checked
                      ? 'bg-blue-600 text-white'
                      : 'text-gray-500 hover:bg-blue-100',
                    'cursor-pointer rounded-full px-2.5 py-1',
                  )
                }
              >
                <span>
                  {option.label}{' '}
                  {option.label === BillingFrequency.Annually &&
                    currentTier === SubscriptionTiers.StartupFree &&
                    '(3 months free)'}
                </span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 xl:mx-0 xl:max-w-none xl:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={joinClassNames(
                tier.isMostPopular
                  ? 'ring-2 ring-gray-200'
                  : 'ring-1 ring-gray-200',
                'rounded-3xl bg-white p-8 shadow xl:p-10',
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={joinClassNames(
                    tier.isMostPopular ? 'text-blue-600' : 'text-gray-900',
                    'text-2xl font-semibold leading-8',
                  )}
                >
                  {SubscriptionTiersNameMap.get(tier.name)}
                </h3>
                {tier.isMostPopular ? (
                  <p className="rounded-full bg-blue-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-blue-600">
                    Most popular
                  </p>
                ) : null}
              </div>
              <p className="mt-4 min-h-[70px] text-sm leading-6 text-gray-600">
                {tier.description}
              </p>
              {tier.price[frequency.label] && (
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    {tier.price[frequency.label]}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    /month
                  </span>
                  {!tier.name?.includes(
                    SubscriptionTiersNameMap.get(
                      SubscriptionTiers.StartupFree,
                    ) ?? '',
                  ) &&
                    frequency.label === BillingFrequency.Annually &&
                    tier.annualBillingDiscount > 0 && (
                      <span className="ml-3 rounded bg-blue-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-blue-600">
                        -{tier.annualBillingDiscount}%
                      </span>
                    )}
                </p>
              )}
              {tier.name?.includes(
                SubscriptionTiersNameMap.get(SubscriptionTiers.StartupPro) ??
                  '',
              ) &&
                frequency.label === BillingFrequency.Annually && (
                  <span className="text-sm text-gray-600">
                    Billed annually at {tier.price.annuallyTotal}
                  </span>
                )}
              {!tier.price[frequency.label] && (
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    Let&apos;s talk
                  </span>
                </p>
              )}

              {tier.name.includes(
                SubscriptionTiersNameMap.get(
                  SubscriptionTiers.StartupPremium,
                ) ?? '',
              ) && (
                <a
                  aria-describedby={tier.id}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={MARK_SALES_CALL}
                  className={joinClassNames(
                    tier.isMostPopular
                      ? 'bg-blue-600 text-white shadow-sm hover:bg-blue-500'
                      : 'text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300',
                    'mt-6 block w-full rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600',
                  )}
                >
                  Check availability & apply
                </a>
              )}
              {!tier.name.includes(
                SubscriptionTiersNameMap.get(
                  SubscriptionTiers.StartupPremium,
                ) ?? '',
              ) && (
                <button
                  type="button"
                  onClick={() => {
                    tier.name.includes(
                      SubscriptionTiersNameMap.get(
                        SubscriptionTiers.StartupPro,
                      ) ?? '',
                    )
                      ? onUpgradeSubscription(frequency.label, tier.name)
                      : null;
                  }}
                  aria-describedby={tier.id}
                  className={joinClassNames(
                    tier.isMostPopular
                      ? 'bg-blue-600 text-white shadow-sm hover:bg-blue-500'
                      : 'text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300',
                    'mt-6 block w-full rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600',
                  )}
                >
                  {tier.name !== currentTier && hasUsedTrial && 'Upgrade'}
                  {tier.name !== currentTier &&
                    !hasUsedTrial &&
                    'Try 14 Days For Free!'}
                  {tier.name === currentTier && 'Current Plan'}
                </button>
              )}
              <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {tier.features.map((feature, index) => (
                  <li
                    key={feature.name}
                    className={joinClassNames(
                      'flex gap-x-3',
                      index === 0 && feature.name.startsWith('All')
                        ? 'font-bold'
                        : '',
                    )}
                  >
                    <CheckIcon
                      className="h-6 w-5 flex-none text-blue-600"
                      aria-hidden="true"
                    />
                    <span>
                      {feature.type && (
                        <p className="inline-flex h-fit rounded bg-indigo-600/10 px-2 py-0.5 text-2xs font-semibold leading-5 text-indigo-600">
                          {feature.type.toUpperCase()}
                        </p>
                      )}{' '}
                      {feature.name}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <p className="mt-10 text-center text-sm text-gray-500">
        Have a question? Email us at&nbsp;
        <a
          href="mailto:support@flowlie.com"
          className="underline hover:text-blue-500"
        >
          support@flowlie.com
        </a>
      </p>

      <p className="mt-6 text-center text-sm text-gray-500">{footnote}</p>
    </div>
  );
}
