import { DataRoomTaskView } from '../../types/view/DataRoomTaskView';
import { RoundStages } from '../RoundStages';
import { DataRoomTasks } from './DataRoomTasks';

export function getDataRoomTaskView(
  taskId: DataRoomTasks,
  roundStage: RoundStages
): Pick<DataRoomTaskView, 'id' | 'label' | 'tooltip' | 'isOptional'> {
  switch (taskId) {
    case DataRoomTasks.AdvisorAgreements:
      return {
        id: DataRoomTasks.AdvisorAgreements,
        label: 'Advisor Agreements',
        tooltip:
          "All agreements signed between your company and advisors, regardless if they are paid or unpaid, receive equity or don't.",
        isOptional: false,
      };
    case DataRoomTasks.AgencyAndPartnerContracts:
      return {
        id: DataRoomTasks.AgencyAndPartnerContracts,
        label: 'Agency and Partner Contracts',
        tooltip:
          'All contracts with agencies and other service providers you are currentlly partnering with or have done so in the past. Ex: software development, marketing, etc.',
        isOptional: [
          RoundStages.Angel,
          RoundStages.PreSeed,
          RoundStages.Seed,
        ].includes(roundStage),
      };
    case DataRoomTasks.AllAwards:
      return {
        id: DataRoomTasks.AllAwards,
        label: 'All Awards',
        tooltip:
          'PDFs of all the awards received by the company or its founders in relation to their work for the company. Ex: Inc 5000, Forbes 30U30, etc.',
        isOptional: false,
      };
    case DataRoomTasks.AllPodcastAppearances:
      return {
        id: DataRoomTasks.AllPodcastAppearances,
        label: 'All Podcast Appearances',
        tooltip:
          'A document with links to all podcast episodes in which one of the founders is a guest speaker in.',
        isOptional: ![RoundStages.Angel].includes(roundStage),
      };
    case DataRoomTasks.AllPressCoverage:
      return {
        id: DataRoomTasks.AllPressCoverage,
        label: 'All Press Coverage',
        tooltip:
          'A document with links to all press and media coverage of the company or its founders in relation to their work for the company. For gated publications, also upload a PDF of the article.',
        isOptional: ![RoundStages.Angel].includes(roundStage),
      };
    case DataRoomTasks.BalanceSheet:
      return {
        id: DataRoomTasks.BalanceSheet,
        label: 'Balance Sheet (for each year)',
        tooltip:
          'Accounting Balance Sheet as of the last day of the fiscal year of the company. Upload one for each year the company has been in business.',
        isOptional: false,
      };
    case DataRoomTasks.BoardOfDirectorsBios:
      return {
        id: DataRoomTasks.BoardOfDirectorsBios,
        label: 'Board of Directors Bios',
        tooltip:
          'A document with brief (1-2 paragraphs) bios for each member of the Board of Directors. Make sure to include links to their LinkedIn and their company website, if applicable.',
        isOptional: false,
      };
    case DataRoomTasks.BusinessLicenses:
      return {
        id: DataRoomTasks.BusinessLicenses,
        label: 'Business Licenses',
        tooltip:
          'All business licenses required for your company to do business in your jurisdiction. This includes foreign qualification certificates for businesses incorporated in a different state.',
        isOptional: [RoundStages.Angel, RoundStages.PreSeed].includes(
          roundStage
        ),
      };
    case DataRoomTasks.BylawsOrArticlesOfGovernance:
      return {
        id: DataRoomTasks.BylawsOrArticlesOfGovernance,
        label: 'Bylaws or Articles of Governance',
        tooltip:
          "All documents, if multiple, outlining the company's bylaws and governance structure as filed with the respective state of incorporation. Include original and amended versions, if applicable.",
        isOptional: false,
      };
    case DataRoomTasks.CapTable:
      return {
        id: DataRoomTasks.CapTable,
        label: 'Cap Table',
        tooltip:
          'A spreadsheet or table showing the equity ownership by shareholder in the company. Ideally, this is exported from your cap table management software such as Carta, AngelList, or Cake Equity.',
        isOptional: false,
      };
    case DataRoomTasks.CashFlowStatement:
      return {
        id: DataRoomTasks.CashFlowStatement,
        label: 'Cash Flow Statement (for each year)',
        tooltip:
          'Accounting Statement of Cash Flows for the full fiscal year of the company. Upload one for each year the company has been in business.',
        isOptional: false,
      };
    case DataRoomTasks.DebtDocuments:
      return {
        id: DataRoomTasks.DebtDocuments,
        label: 'Debt Documents',
        tooltip:
          'All documents, contracts, and agreements signed with debt providers for any debt currently outstanding clearly showing the terms. If several providers, create a folder for each.',
        isOptional: ![RoundStages.Angel].includes(roundStage),
      };
    case DataRoomTasks.DetailedFounderTeamOrLeadershipBios:
      return {
        id: DataRoomTasks.DetailedFounderTeamOrLeadershipBios,
        label: 'Detailed Founder Team / Leadership Bios',
        tooltip:
          'A document with detailed (2-3 paragraphs) bios for each founder or member of the executive team. Make sure you showcase relevant experiences and demonstrate founder-market fit.',
        isOptional: false,
      };
    case DataRoomTasks.DraftInvestmentDocuments:
      return {
        id: DataRoomTasks.DraftInvestmentDocuments,
        label: 'Draft Investment Documents',
        tooltip:
          'A draft copy of the SAFE, Convertible Note, or Term Sheet used in relation to this transaction. Include only if the terms have been set for the round.',
        isOptional: true,
      };
    case DataRoomTasks.ESOPDocumentation:
      return {
        id: DataRoomTasks.ESOPDocumentation,
        label: 'ESOP Documentation',
        tooltip:
          'Copies of each Equity Stock Option Plan ever created by the company to issue stock options to their employees.',
        isOptional: [RoundStages.Seed].includes(roundStage),
      };
    case DataRoomTasks.EmployeeOptionsAgreements:
      return {
        id: DataRoomTasks.EmployeeOptionsAgreements,
        label: 'Employee Options Agreements',
        tooltip:
          'Copies of each agreement that has been signed between the company and any employee in which stock options have been issued. Make sure vesting information is clearly visible.',
        isOptional: false,
      };
    case DataRoomTasks.EmploymentContracts:
      return {
        id: DataRoomTasks.EmploymentContracts,
        label: 'Leadership Employment Contracts',
        tooltip:
          'Copies of the employment contracts for each founder or member of the executive team.',
        isOptional: false,
      };
    case DataRoomTasks.FinancialModelAndBudget:
      return {
        id: DataRoomTasks.FinancialModelAndBudget,
        label: 'Financial Model & Budget',
        tooltip:
          "A spreadsheet forecasting your company's financial performance (revenues and expenses) based on current data and assumptions. For early stage companies, 2-3 years is the appropiate future lenght.",
        isOptional: false,
      };
    case DataRoomTasks.FounderShareAgreements:
      return {
        id: DataRoomTasks.FounderShareAgreements,
        label: 'Founder Share Agreements',
        tooltip:
          'Copies of each agreement that has been signed between the company and any founder in which shares or stock options have been issued. Make sure vesting information is clearly visible.',
        isOptional: false,
      };
    case DataRoomTasks.HistoricalInvestorUpdates:
      return {
        id: DataRoomTasks.HistoricalInvestorUpdates,
        label: 'Historical Investor Updates',
        tooltip:
          'All previously sent investor updates, in chronological order.',
        isOptional: false,
      };
    case DataRoomTasks.IncomeStatementOrPL:
      return {
        id: DataRoomTasks.IncomeStatementOrPL,
        label: 'Income Statement / P&L (for each year)',
        tooltip:
          'Accounting Income Statement or Profit & Loss Statement for the full fiscal year of the company. Upload one for each year the company has been in business.',
        isOptional: false,
      };
    case DataRoomTasks.IncorporationDocuments:
      return {
        id: DataRoomTasks.IncorporationDocuments,
        label: 'Incorporation Documents',
        tooltip:
          "All documents, if multiple, proving the company's incorporation as filed with the respective state of incorporation. Include original and amended versions, if applicable.",
        isOptional: false,
      };
    case DataRoomTasks.InsuranceDocuments:
      return {
        id: DataRoomTasks.InsuranceDocuments,
        label: 'Insurance Documents',
        tooltip:
          'All documents, contracts, and agreements signed with insurance providers (general liability, directors & officers, cyber, etc.) clearly showing the terms. If several providers, create a folder for each.',
        isOptional: [RoundStages.Seed].includes(roundStage),
      };
    case DataRoomTasks.KPIsHistoricalAndForecast:
      return {
        id: DataRoomTasks.KPIsHistoricalAndForecast,
        label: 'KPIs Historical & Forecast',
        tooltip:
          "A spreadsheet showcasing the company's historical Key Performance Indicators (KPIs) by week or by month and a forecast of those KPIs 12 to 24 months into the future.",
        isOptional: false,
      };
    case DataRoomTasks.MajorClientContractsOrLOIs:
      return {
        id: DataRoomTasks.MajorClientContractsOrLOIs,
        label: 'Major Client Contracts or LOIs',
        tooltip:
          'Copies of all major client contracts ($100K or more) or Letters of Intent (LOIs) to purchase the product in the near future. Include only copies signed by both parties.',
        isOptional: false,
      };
    case DataRoomTasks.MajorPartnershipAgreements:
      return {
        id: DataRoomTasks.MajorPartnershipAgreements,
        label: 'Major Partnership Agreements',
        tooltip:
          'Copies of all major partnership agreements (national, international or of high significance) signed by both parties. This can include distribution, promotion, or other rights your company has negotiated.',
        isOptional: false,
      };
    case DataRoomTasks.OrgChart:
      return {
        id: DataRoomTasks.OrgChart,
        label: 'Org Chart',
        tooltip:
          'A document outlining the organizational chart as a tree with all employees mapped to their respective manager. Include title, committment (full-time/part-time/intern) and work location under each employee.',
        isOptional: false,
      };
    case DataRoomTasks.PatentsAndTrademarks:
      return {
        id: DataRoomTasks.PatentsAndTrademarks,
        label: 'Patents & Trademarks',
        tooltip:
          'Copies of all patents and trademarks as filed with the respective patent and trademark office. Ensure it is clear whether they have been issued or currently pending.',
        isOptional: [RoundStages.Seed].includes(roundStage),
      };
    case DataRoomTasks.ProductDemoVideo:
      return {
        id: DataRoomTasks.ProductDemoVideo,
        label: 'Product Demo Video',
        tooltip:
          'A short (3-5 minutes) video walking through the product functionality and clearly explaing what a customer can do with it. For digital products, tools like Loom, ScreenRec and Vidyard are preferred. ',
        isOptional: false,
      };
    case DataRoomTasks.ProductOverviewDeck:
      return {
        id: DataRoomTasks.ProductOverviewDeck,
        label: 'Product Overview Deck',
        tooltip:
          "A PDF deck presenting the product's core functionalities with images to serve as an alternative to watching the Product Demo Video.",
        isOptional: false,
      };
    case DataRoomTasks.ProductRoadmap:
      return {
        id: DataRoomTasks.ProductRoadmap,
        label: 'Product Roadmap',
        tooltip:
          'A document outlining the product roadmap, features currently in development, as well as features planned in the next 6-12 months.',
        isOptional: [RoundStages.Angel, RoundStages.PreSeed].includes(
          roundStage
        ),
      };
    case DataRoomTasks.QSBSPotential:
      return {
        id: DataRoomTasks.QSBSPotential,
        label: 'QSBS or EIS/SEIS Potential',
        tooltip:
          'Documentation proving that investing in your company currently qualifies as a Qualified Small Business Stock (QSBS), Enterprise Investment Schemes (EIS), Seed Enterprise Investment Schemes (SEIS), or similar tax relief program.',
        isOptional: true,
      };
    case DataRoomTasks.SalesPipeline:
      return {
        id: DataRoomTasks.SalesPipeline,
        label: 'Sales Pipeline',
        tooltip:
          'A spreadsheet or PDF deck showcasing the state of the current sales pipeline emphasising major deals. Only include this if you are selling to enterprise buisnesses.',
        isOptional: ![RoundStages.Angel].includes(roundStage),
      };
    case DataRoomTasks.SalesPresentationsOrDecks:
      return {
        id: DataRoomTasks.SalesPresentationsOrDecks,
        label: 'Sales Presentations or Decks',
        tooltip:
          'Any sales presentations, decks, brochures, or other materials used in client communications. If selling multiple products to multiple customer types, provide a sample of most relevant.',
        isOptional: false,
      };
    case DataRoomTasks.TaxIdNumber:
      return {
        id: DataRoomTasks.TaxIdNumber,
        label: 'Tax ID Number',
        tooltip:
          "Documentation of your company's tax ID number as issued by your respective tax auhtority. For US companies, this would be the Employer Identification Number (EIN) issued by the IRS.",
        isOptional: false,
      };
    case DataRoomTasks.TaxFilings:
      return {
        id: DataRoomTasks.TaxFilings,
        label: 'Tax Filings (for each year)',
        tooltip:
          'Copies of all tax filings including federal, state, or local. Create a folder for each year the company has been in business.',
        isOptional: false,
      };
    case DataRoomTasks.TechStackOverviewDiagram:
      return {
        id: DataRoomTasks.TechStackOverviewDiagram,
        label: 'Tech Stack Overview Diagram',
        tooltip:
          'A document outlining all the technologies used as part of your product. Ensure all vendors are clearly marked and any open-source technology is labeled as such.',
        isOptional: [RoundStages.Angel, RoundStages.PreSeed].includes(
          roundStage
        ),
      };
    default:
      throw new Error(`Unknown task ID: ${taskId}`);
  }
}
