import React from 'react';

/**
 *
 * @param children React Children to get the Bounding Boxes of.
 * @returns An object where each key is the key of the child, and the value is the DOMRect of the child.
 */
function getBoundingBoxesForDomNodes(children: any): {
  [key: string]: DOMRect;
} {
  const boundingBoxes: { [key: string]: DOMRect } = {};

  React.Children.forEach(children, (child) => {
    const domNode: HTMLDivElement | null = child.ref.current;
    if (!domNode) {
      return;
    }
    const nodeBoundingBox: DOMRect = domNode.getBoundingClientRect();

    boundingBoxes[child.key] = nodeBoundingBox;
  });

  return boundingBoxes;
}

export default getBoundingBoxesForDomNodes;
