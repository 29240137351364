import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { BOOLEAN_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

function FieldWrapper(): JSX.Element {
  return (
    <>
      <Field
        className="custom-select"
        closeMenuOnSelect
        component={CustomSelect}
        label="Are you an accredited investor?"
        name={InvestorFieldNames.IsAccredited}
        options={BOOLEAN_OPTIONS}
        placeholder="Select option..."
      />
      <a
        className="hyperlink text-sm"
        target="_blank"
        rel="noopener noreferrer"
        href="https://flowlie.zendesk.com/hc/en-us/articles/4402777985811-What-is-an-accredited-investor-"
      >
        What is an accredited investor?
      </a>
    </>
  );
}

const IsAccreditedField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.IsAccredited]: yup.bool().required('Required'),
  }),
};

export default IsAccreditedField;
