import { ElementBorderColor } from '../types/Utility/Color';

export enum InvestorPipelineSystemStages {
  ToReachOut = 'TO REACH OUT',
  Committed = 'COMMITTED',
  Wired = 'WIRED',
  NotInvested = 'NOT INVESTED',
}

export const FINAL_INVESTOR_PIPELINE_STAGES: string[] = [
  InvestorPipelineSystemStages.Wired,
  InvestorPipelineSystemStages.NotInvested,
];

export const InvestorPipelineSystemStagesColorsMap = new Map<
  string,
  ElementBorderColor
>([
  [
    InvestorPipelineSystemStages.ToReachOut,
    {
      bgColor: 'bg-gray-300',
      textColor: 'text-gray-700',
      borderColor: 'border-gray-400',
    },
  ],
  [
    InvestorPipelineSystemStages.Committed,
    {
      bgColor: 'bg-lime-400',
      textColor: 'text-lime-700',
      borderColor: 'border-lime-400',
    },
  ],
  [
    InvestorPipelineSystemStages.Wired,
    {
      bgColor: 'bg-green-400',
      textColor: 'text-green-700',
      borderColor: 'border-green-400',
    },
  ],
  [
    InvestorPipelineSystemStages.NotInvested,
    {
      bgColor: 'bg-red-300',
      textColor: 'text-red-800',
      borderColor: 'border-red-300',
    },
  ],
]);
