import React from 'react';
import * as yup from 'yup';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import {
  INITIAL_OWNERSHIP_TARGET_RANGE_MAX,
  INITIAL_OWNERSHIP_TARGET_RANGE_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import { numberWithCommas } from '/src/util/formatting/numbers';
import FormField from '/src/interfaces/FormField';
import PercentageInput from '../../inputs/PercentageInput';
import { useFormikContext } from 'formik';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[InvestorFieldNames.HasInitialOwnershipTarget] ? (
    <PercentageInput
      allowDecimals={false}
      label="Minimum Initial Ownership Target"
      name={InvestorFieldNames.InitialOwnershipTargetMin}
    />
  ) : (
    <></>
  );
}

const InitialOwnershipTargetMinField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.InitialOwnershipTargetMin]: yup
      .number()
      .nullable()
      .integer()
      .typeError('Must be a number')
      .when(InvestorFieldNames.HasInitialOwnershipTarget, {
        is: (hasInitialOwnershipTarget: boolean) => hasInitialOwnershipTarget,
        then: (schema) => schema.required('This field is required'),
      })
      .min(
        INITIAL_OWNERSHIP_TARGET_RANGE_MIN,
        `Must be at least ${numberWithCommas(
          INITIAL_OWNERSHIP_TARGET_RANGE_MIN,
        )}%`,
      )
      .max(
        INITIAL_OWNERSHIP_TARGET_RANGE_MAX,
        `Cannot be greater than ${numberWithCommas(
          INITIAL_OWNERSHIP_TARGET_RANGE_MAX,
        )}%`,
      ),
  }),
};

export default InitialOwnershipTargetMinField;
