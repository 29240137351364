import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import { PRODUCT_STATUS_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { FormatOptionLabelMeta } from 'react-select';
import { TooltipSelectOption } from '../../../types/SelectOptions';
import FormatOptionWithTooltipLabel from '../../inputs/ReactSelectAdditions/formatOptionWithTooltipLabel';

const ProductStatusField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      formatOptionLabel={(
        option: TooltipSelectOption,
        formatOptionLabelMeta: FormatOptionLabelMeta<any>
      ) => (
        <FormatOptionWithTooltipLabel
          option={option}
          formatOptionLabelMeta={formatOptionLabelMeta}
        />
      )}
      isClearable
      label="Product Status"
      secondaryLabel="What is the current status of your product or service?"
      name={StartupFieldNames.ProductStatus}
      options={PRODUCT_STATUS_OPTIONS}
      placeholder="Select product status..."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.ProductStatus]: yup.string().nullable(),
  }),
};

export default ProductStatusField;
