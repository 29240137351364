import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { isValidPhoneNumber } from 'react-phone-number-input';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import FormikPhoneInput from '../../inputs/FormikPhoneInput';

const AccountPhoneField: FormField = {
  fieldComponent: (
    <Field
      component={FormikPhoneInput}
      label="Phone Number"
      name={StartupFieldNames.AccountPhone}
      placeholder="International format, ex +1 1231231234"
      secondaryLabel="Your phone number will not be displayed publicly to other users."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.AccountPhone]: yup
      .string()
      .test(
        'isValidNumber',
        'Invalid phone number length',
        (phone): boolean => {
          if (phone && !isValidPhoneNumber(phone?.toString())) {
            // Return false to indicate the test failed
            return false;
          }
          return true;
        }
      )
      .typeError('Enter a valid phone number, only digits'),
  }),
};

export default AccountPhoneField;
