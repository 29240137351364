import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { HowOftenType } from '/../libs/shared-types/src/constants/HowOftenType';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { enumToSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';

const LeadsRoundsField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      closeMenuOnSelect
      label="Do you lead rounds?"
      name={InvestorFieldNames.LeadsRounds}
      options={enumToSelectOptions(HowOftenType)}
      placeholder="Select option.."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.LeadsRounds]: yup.string().required('Required'),
  }),
};

export const leadsRoundsFieldOptional: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      closeMenuOnSelect
      label="Lead Beahvior"
      secondaryLabel="Do they lead rounds?"
      name={InvestorFieldNames.LeadsRounds}
      options={enumToSelectOptions(HowOftenType)}
      placeholder="Select option.."
      isClearable
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.LeadsRounds]: yup.string(),
  }),
};

export default LeadsRoundsField;
