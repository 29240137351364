import React, { useState } from 'react';
import {
  InboxIcon,
  ViewColumnsIcon,
  HandThumbDownIcon,
  BriefcaseIcon,
} from '@heroicons/react/24/outline';

import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import DateString from '/src/components/utility/DateString';
import { DealStatusView } from '/../libs/shared-types/src/types/view/DealStatusView';

function GetTimelineIcon(type: string): JSX.Element {
  switch (type) {
    case DealStatusTypes.Screening:
      return (
        <div>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-500 ring-8 ring-white">
            <InboxIcon className="h-5 w-8 text-white" />
          </span>
        </div>
      );
    case DealStatusTypes.Active:
      return (
        <div>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 ring-8 ring-white">
            <ViewColumnsIcon className="h-5 w-8 text-white" />
          </span>
        </div>
      );
    case DealStatusTypes.Passed:
      return (
        <div>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-red-500 ring-8 ring-white">
            <HandThumbDownIcon className="h-5 w-8 text-white" />
          </span>
        </div>
      );
    case DealStatusTypes.Portfolio:
      return (
        <div>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-green-600 ring-8 ring-white">
            <BriefcaseIcon className="h-5 w-8 text-white" />
          </span>
        </div>
      );
    default:
      return <div>Type not supported.</div>;
  }
}

interface Item {
  type: string;
  label: string;
  date: Date;
  isLast: boolean;
}

function InvestorActivityTimelineItem({ type, label, date, isLast }: Item) {
  return (
    <li>
      <div className="relative pb-8">
        {!isLast && (
          <span
            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        )}
        <div className="relative flex space-x-3">
          {GetTimelineIcon(type)}
          <div className="flex min-w-0 flex-1 items-center justify-between space-x-4">
            <div>
              <p className="text-base font-medium text-gray-900">{label}</p>
            </div>
            <div className="whitespace-nowrap text-right text-sm text-gray-500">
              <DateString date={date} tooltipPosition="top" />
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

interface InvestorActivityTimelineProps {
  statusHistory: DealStatusView[];
}

function getLabel(status: DealStatusView) {
  switch (status.status) {
    case DealStatusTypes.Screening:
      return `${status.status}`;
    case DealStatusTypes.Active:
      return `${status.status}`;
    case DealStatusTypes.Passed:
      return `Passed at ${status.roundDisplayName}`;
    case DealStatusTypes.Portfolio:
      return `Invested at ${status.roundDisplayName}`;
    default:
      return '';
  }
}

function InvestorActivityTimeline({
  statusHistory,
}: InvestorActivityTimelineProps): JSX.Element {
  const [statusHistoryData] = useState(
    statusHistory
      .sort((x, y) => y.statusChangedOn.getTime() - x.statusChangedOn.getTime())
      .slice(0, 10)
  );

  return (
    <aside
      aria-labelledby="timeline-title"
      className="lg:col-span-1 lg:col-start-3"
    >
      <div className="rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="timeline-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Timeline
          </h2>
        </div>

        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="mt-2 flow-root">
            <ul className="-mb-8">
              {statusHistoryData.map((status) => (
                <InvestorActivityTimelineItem
                  type={status.status}
                  key={status.statusChangedOn.toISOString()}
                  label={getLabel(status)}
                  date={status.statusChangedOn}
                  isLast={
                    statusHistoryData.indexOf(status) ===
                    statusHistoryData.length - 1
                  }
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default InvestorActivityTimeline;
