import React from 'react';
import { PortfolioDealSummaryView } from '/../libs/shared-types/src/types/view/AggregatedDeals';
import BasicStatistic from '/src/components/cards/BasicStatistic';
import { MoicExplanation } from '/src/constants/Tooltips';
import { numberWithCommas } from '/src/util/formatting/numbers';

interface PortfolioKpiCardsProps {
  deals: PortfolioDealSummaryView[];
}

function PortfolioKpiCards({ deals }: PortfolioKpiCardsProps): JSX.Element {
  const allInvestments =
    deals.length > 0 ? deals.map((deal) => deal.investments).flat() : [];
  const investedCapital =
    deals.length > 0
      ? allInvestments
          .map((investment) => investment.amount)
          .reduce((sum, amount) => sum + amount)
      : 0;
  const estimatedValue =
    deals.length > 0
      ? allInvestments
          .map((investment) => investment.estimatedValue)
          .reduce((sum, amount) => sum + amount)
      : 0;
  const moic = deals.length > 0 ? estimatedValue / investedCapital : 0;

  return (
    <section className="mb-4 grid w-full grid-cols-2 gap-5 lg:grid-cols-4">
      <BasicStatistic
        label="Total Investments"
        statistic={allInvestments.length}
        tooltip="Total number of investments in the portfolio."
      />
      <BasicStatistic
        label="Invested Capital"
        statistic={`$ ${numberWithCommas(investedCapital)}`}
        tooltip="Total invested amounts across all deals in the portfolio."
      />
      <BasicStatistic
        label="Estimated Value"
        statistic={`$ ${numberWithCommas(Math.ceil(estimatedValue))}`}
        tooltip="Estimated value of all investments. This estimate may be overstated as it may not include dilution due to later rounds."
      />
      <BasicStatistic
        label="MOIC"
        statistic={`${moic.toFixed(2)}x`}
        tooltip={MoicExplanation}
      />
    </section>
  );
}

export default PortfolioKpiCards;
