/* eslint-disable max-len */
import { Menu } from '@headlessui/react';
import {
  PresentationChartLineIcon,
  HandThumbDownIcon,
  ShareIcon,
} from '@heroicons/react/20/solid';
import React from 'react';
import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import { Round } from '/../libs/shared-types/src/types/model/Round';
import DropdownButton from './DropdownButton';
import { joinClassNames } from '/src/util/formatting/strings';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';

type ButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children: any;
  isPrimary?: boolean;
};

function Button({ onClick, children, isPrimary }: ButtonProps): JSX.Element {
  let baseStyle =
    'inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500';

  if (isPrimary) {
    baseStyle += ' text-white bg-blue-600 hover:bg-blue-700';
  } else {
    baseStyle += ' text-gray-700 bg-white hover:bg-gray-50';
  }

  return (
    <button onClick={onClick} type="button" className={baseStyle}>
      {children}
    </button>
  );
}

interface DealActionButtonsProps {
  accept: () => void;
  currentRound: Round | undefined;
  label: string;
  moveToActive: () => void;
  pass: () => void;
  recordInvestment: () => void;
  share: () => void;
}

function DealActionButtons({
  accept,
  currentRound,
  label,
  moveToActive,
  pass,
  recordInvestment,
  share,
}: DealActionButtonsProps): JSX.Element {
  const isCurrentRoundOpen = currentRound !== undefined;
  // The round is investable if: it is open, AND the type is NOT 'To be determined'
  const isCurrentRoundInvestable =
    isCurrentRoundOpen && currentRound?.roundType !== RoundTypes.ToBeDetermined;

  const getRecordInvestmentTooltipText = () => {
    if (!isCurrentRoundOpen) {
      return 'This startup is not raising a round';
    }

    if (!isCurrentRoundInvestable) {
      return 'The founder has not set the terms of the round yet';
    }

    return '';
  };

  switch (label) {
    case DealStatusTypes.Screening:
      return (
        <>
          <Button onClick={pass}>Pass</Button>
          <Button onClick={accept} isPrimary>
            Accept
          </Button>
        </>
      );
    case DealStatusTypes.Active:
      return (
        <>
          <DropdownButton label="Actions">
            <Menu.Item>
              {({ active }) => (
                <button
                  // eslint-disable-next-line no-nested-ternary
                  title={getRecordInvestmentTooltipText()}
                  onClick={recordInvestment}
                  type="button"
                  disabled={!isCurrentRoundInvestable}
                  className={joinClassNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex w-full items-center px-4 py-2 text-sm'
                  )}
                >
                  <PresentationChartLineIcon
                    className={joinClassNames(
                      isCurrentRoundInvestable
                        ? 'group-hover:text-gray-500'
                        : '',
                      'mr-3 h-5 w-5 text-gray-400'
                    )}
                    aria-hidden="true"
                  />
                  Record Investment
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={pass}
                  type="button"
                  className={joinClassNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex w-full items-center px-4 py-2 text-sm'
                  )}
                >
                  <HandThumbDownIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Pass
                </button>
              )}
            </Menu.Item>
          </DropdownButton>
          <Button onClick={share} isPrimary>
            <ShareIcon className="mr-2 h-4 w-4" />
            Share
          </Button>
        </>
      );
    case DealStatusTypes.Passed:
      return (
        <>
          <Button onClick={moveToActive}>Move To Active</Button>
          <Button onClick={share} isPrimary>
            <ShareIcon className="mr-2 h-4 w-4" />
            Share
          </Button>
        </>
      );
    case DealStatusTypes.Portfolio:
      return (
        <>
          <Button onClick={share} isPrimary>
            <ShareIcon className="mr-2 h-4 w-4" />
            Share
          </Button>
        </>
      );
    default:
      return <div>Invalid buttons</div>;
  }
}

export default DealActionButtons;
