import { SelectOption, TooltipSelectOption } from '../types/SelectOptions';

/**
 * Takes an array of react select options (this could have
 * group labels or not) and flattens to a string array
 * { label: string, value: any }[] => string[]
 */
export const flattenOptionsToList = (options: any): string[] => {
  if (options.length === 0) {
    return options;
  }
  const hasGroupLabel = options[0].groupLabel !== undefined;
  if (hasGroupLabel) {
    // Flatten to 1Dimensional array of {value, label}
    return options
      .map((x: any) => x.options.flat())
      .flat()
      .map((x: any) => x.value);
  }
  // It's already a 1Dimensional array
  return options.map((x: any) => x.value);
};

/**
 * Takes an array of SelectOptions[] or GroupedSelectOptions[] and flattens out
 * any group labels
 * @returns An array of SelectOptions[]
 */
export const flattenGroupLabelsFromOptions = (options: any): any => {
  if (options.length === 0) {
    return options;
  }
  const hasGroupLabel = options[0].groupLabel !== undefined;
  if (hasGroupLabel) {
    // Flatten to 1Dimensional array of {value, label}
    return options.map((x: any) => x.options.flat()).flat();
  }
  // It's already a 1Dimensional array
  return options;
};

export const groupLabelsToList = (options: any): string[] =>
  options.map((x: any) => x.groupLabel);

export const enumToList = (enumAsObject: any): string[] =>
  Object.values(enumAsObject);

export const listToSelectOptions = (list: string[]): SelectOption[] => {
  const options = list.map((x: string) => Object({ label: x, value: x }));
  return options;
};

export const enumToSelectOptions = (enumAsObject: any): SelectOption[] => {
  const enumList = enumToList(enumAsObject);
  const options = listToSelectOptions(enumList);
  return options;
};

export function enumToTooltipSelectOptions(
  enumAsObject: any,
  tooltipGetter: (value: string) => string
): TooltipSelectOption[] {
  const options = listToSelectOptions(enumToList(enumAsObject));
  const tooltipOptions = options.map((x) => ({
    value: x.value,
    label: x.label,
    tooltipText: tooltipGetter(x.value),
  }));

  return tooltipOptions;
}
