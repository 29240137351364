import React from 'react';

import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import { InvestorActivityView } from '/../libs/shared-types/src/types/view/InvestorActivityView';
import { generateTag, generateTagCloud } from '/src/util/generateTagCloud';
import DateString from '/src/components/utility/DateString';
import { numberWithCommas } from '/src/util/formatting/numbers';
import InvestorActivityTimeline from '../../InvestorDetail/cards/InvestorActivityTimeline';
import DealDetailRatingPrioritySummaryCard from '../cards/DealDetailRatingPrioritySummaryCard';
import { DealStatusView } from '/../libs/shared-types/src/types/view/DealStatusView';
import { getInvestmentRights } from '/src/util/investment';

interface DealDetailActionsTabProps {
  investorActivity?: InvestorActivityView;
}

function DealDetailActivityTab({
  investorActivity,
}: DealDetailActionsTabProps): JSX.Element {
  const statusHistory = [...(investorActivity?.statusHistory ?? [])].sort(
    // Order Descending
    (x, y) => y.statusChangedOn.getTime() - x.statusChangedOn.getTime()
  );

  const hasDealBeenPassedOrPortfolio = () =>
    statusHistory?.some(
      (x: DealStatusView) =>
        x.status === DealStatusTypes.Passed ||
        x.status === DealStatusTypes.Portfolio
    );

  return (
    <div className="mx-auto mt-8 grid max-w-full grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
      <main className="space-y-6 lg:col-span-2 lg:col-start-1">
        {!hasDealBeenPassedOrPortfolio() && (
          <p>
            When you invest or pass a deal, you will see the reason listed here
          </p>
        )}

        {hasDealBeenPassedOrPortfolio() && (
          <>
            {statusHistory
              ?.filter(
                (status) =>
                  status.status === DealStatusTypes.Passed ||
                  status.status === DealStatusTypes.Portfolio
              )
              .map((status) => (
                <div
                  key={status.statusChangedOn.getTime()}
                  className="rounded-lg bg-white shadow"
                >
                  <div className="px-4 py-5 sm:px-6">
                    <div className="flex flex-row items-center justify-between">
                      <h3 className="flex items-center text-lg font-medium leading-6 text-gray-900">
                        {status.status === DealStatusTypes.Portfolio
                          ? 'Invested'
                          : status.status}
                        &nbsp;at&nbsp;
                        {status.roundDisplayName}
                        {status.investment?.isLead && (
                          <span className="ml-2 inline-flex items-center rounded-full bg-teal-100 px-2.5 text-xs font-medium text-teal-800">
                            Lead Investor
                          </span>
                        )}
                        {status.hasQsbsPotential && (
                          <span
                            title="This round's equity has the potential to qualify as QSBS as of today’s date. This is not legal advice, please consult a tax professional."
                            className="mx-2 inline-flex items-center rounded-full bg-purple-100 px-2.5 text-xs font-medium text-purple-800"
                          >
                            QSBS Potential
                          </span>
                        )}
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        <DateString date={status.statusChangedOn} />
                      </p>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    {status.status === DealStatusTypes.Passed ? (
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 2xl:grid-cols-3">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Reasons
                          </dt>
                          <dd className="mt-1">
                            {!status.reasons && '-'}
                            {status.reasons &&
                              status.reasons.map((reason) =>
                                generateTag(reason)
                              )}
                          </dd>
                        </div>
                        {status.feedback && (
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Feedback
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {status.feedback}
                            </dd>
                          </div>
                        )}
                        {status.investorNote && (
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Personal Note
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {status.investorNote}
                            </dd>
                          </div>
                        )}
                      </dl>
                    ) : status.status === DealStatusTypes.Portfolio &&
                      status.investment ? (
                      <dl className="grid grid-cols-3 gap-x-4 gap-y-8 2xl:grid-cols-3">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Reasons
                          </dt>
                          <dd className="mt-1">
                            {status.investment &&
                              status.investment.reasons.map((reason) =>
                                generateTag(reason)
                              )}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Amount
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            ${numberWithCommas(status.investment.amount)}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Round Participation
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {status.roundParticipation.toFixed(2)}%
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt
                            className="text-sm font-medium text-gray-500"
                            title="Amount of carry charged to you"
                          >
                            Carry Charged
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {status.investment.carryCharged}%
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Board Participation
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {status.investment.boardParticipation}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Investor Updates
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {status.investment.investorUpdatesFrequency}
                          </dd>
                        </div>
                        <div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500">
                            Rights
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {generateTagCloud(
                              getInvestmentRights(status.investment)
                            )}
                          </dd>
                        </div>
                      </dl>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ))}
          </>
        )}
      </main>

      <aside className="flex flex-col space-y-6">
        {investorActivity && (
          <DealDetailRatingPrioritySummaryCard
            ratingHistoryPerRound={investorActivity.ratingHistoryPerRound}
            priorityHistoryPerRound={investorActivity.priorityHistoryPerRound}
          />
        )}
        {statusHistory && (
          <InvestorActivityTimeline statusHistory={statusHistory} />
        )}
      </aside>
    </div>
  );
}

export default DealDetailActivityTab;
