import React, { useContext } from 'react';
import BasicStatistic from '/src/components/cards/BasicStatistic';
import { formatMilliSecondsAsTimeDuration } from '/src/util/formatting/dates';
import { PaginatedRequestParams } from '/../libs/shared-types/src/types/view/APIResponse';
import SearchBar from '/src/components/inputs/SearchBar';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import DropdownSelect from '/src/components/DropdownSelect';
import Alert from '/src/components/notifications/Alert';
import { Link } from 'react-router-dom';
import { STARTUP_FLOWLINK_MANAGER_ROUTE } from '/src/constants/Routes';
import AccessManagerViewSessionsTable from './AccessManagerViewSessionsTable';
import {
  ComparableStartupAccessManagerViewSessionView,
  StartupAccessManagerViewSessionPaginatedCollectionResponse,
} from '/../libs/shared-types/src/types/view/StartupAccessManagerView';
import { TableHeader } from '/src/interfaces/TableHeader';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import EmptyState from '/src/components/notifications/EmptyState';
import { EyeSlashIcon } from '@heroicons/react/24/outline';
import { CsvDownloadButton } from './CsvDownloadButton';

export const defaultSortKeyViewSessions =
  'lastViewedOn' as keyof ComparableStartupAccessManagerViewSessionView;

function getTableHeaders(canAccessViewSessions: boolean) {
  const accessManagerViewSessionsTableHeaders: TableHeader<ComparableStartupAccessManagerViewSessionView>[] =
    [
      {
        sortKey: canAccessViewSessions ? 'name' : undefined,
        element: <span>Name</span>,
      },
      {
        sortKey: canAccessViewSessions ? defaultSortKeyViewSessions : undefined,
        element: (
          <span title="Latest time your profile was viewed">Accessed</span>
        ),
      },
      {
        sortKey: canAccessViewSessions ? 'totalViewTime' : undefined,
        element: (
          <span title="Total time spent viewing your profile">Duration</span>
        ),
      },
      {
        sortKey: canAccessViewSessions ? 'deckViewed' : undefined,
        element: <span title="Which deck version was viewed">Deck Viewed</span>,
      },
      {
        sortKey: canAccessViewSessions ? 'dataRoomViewStatus' : undefined,
        element: (
          <span title="Whether the data room was viewed">Data Room Viewed</span>
        ),
      },
      {
        element: (
          <span title="Additional information about the view session">
            Insights
          </span>
        ),
      },
      {
        sortKey: canAccessViewSessions ? 'receivedFrom' : undefined,
        element: (
          <span title="Who shared your company with this viewer">
            Received From
          </span>
        ),
      },
      {
        element: (
          <span className="sr-only" title="Import Investor">
            Actions
          </span>
        ),
      },
    ];
  return accessManagerViewSessionsTableHeaders;
}

interface AccessManagerViewSessionsProps {
  apiResponse?: StartupAccessManagerViewSessionPaginatedCollectionResponse;
  handleGetStartupViewSessionsCsv: () => void;
  isSearchLoading: boolean;
  stageOptions: string[];
  handleFilter: (filter: string) => Promise<void>;
  handleFilterByRoundStage: (roundStage: string) => Promise<void>;
  fieldFilters: any;
  fetchStartupAccessManager: (
    args: Partial<PaginatedRequestParams<any, any>>,
  ) => Promise<void>;
}

function AccessManagerViewSessions({
  apiResponse,
  isSearchLoading,
  stageOptions,
  handleFilter,
  handleFilterByRoundStage,
  handleGetStartupViewSessionsCsv,
  fieldFilters,
  fetchStartupAccessManager,
}: AccessManagerViewSessionsProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const canAccessViewSessions = hasFeatureAccess(
    StartupPaidFeatures.AccessManagerViewSessions,
    subscriptionTier,
  );

  const canDownloadCsv = hasFeatureAccess(
    StartupPaidFeatures.ViewSessionCsvExport,
    subscriptionTier,
  );

  if (!apiResponse) {
    return <></>;
  }

  return (
    <>
      <dl className="my-4 grid w-full grid-cols-2 gap-5 lg:grid-cols-5">
        <BasicStatistic
          label="Total Views"
          statistic={apiResponse.stats.viewSessions}
          suffix={
            apiResponse.stats.uniqueViewers > 0
              ? `(${apiResponse.stats.uniqueViewers} unique)`
              : ''
          }
        />
        <BasicStatistic
          label="Avg. Duration"
          statistic={formatMilliSecondsAsTimeDuration(
            apiResponse.stats.avgDuration,
          )}
        />
        <BasicStatistic
          label="Avg. Deck View Time"
          statistic={formatMilliSecondsAsTimeDuration(
            apiResponse.stats.avgDeckViewTime,
          )}
        />
        <BasicStatistic
          label="Deck Downloads"
          statistic={apiResponse.stats.deckDownloadCount}
        />
        <BasicStatistic
          label="Data Room Views"
          statistic={apiResponse.stats.dataRoomViewCount}
        />
      </dl>

      {apiResponse.totalCount === 0 && apiResponse.filter === '' && (
        <EmptyState
          title="No one has viewed your one-pager yet"
          subTitle="Every time someone views your one-pager their session will be recorded here."
          icon={<EyeSlashIcon className="mx-auto h-12 w-12 text-gray-400" />}
          actionButton={
            <Link
              to={STARTUP_FLOWLINK_MANAGER_ROUTE}
              className="app-button--primary"
            >
              Share your deck & one-pager
            </Link>
          }
        />
      )}

      {(apiResponse.totalCount > 0 || apiResponse.filter !== '') && (
        <>
          <div className="mb-3 flex flex-row items-center justify-between">
            <span className="flex items-center">
              <SearchBar
                isDebounce
                placeholder="Search View Sessions"
                onQueryChange={handleFilter}
                initialValue={apiResponse.filter}
                isDisabled={!canAccessViewSessions}
              />
              {isSearchLoading && <LoadingSpinner color="blue" />}
            </span>

            <div className="flex flex-row space-x-2">
              <DropdownSelect
                onSelectionChange={handleFilterByRoundStage}
                initialValue={fieldFilters['roundStage'] ?? ''}
                label="Round Stage"
                items={stageOptions}
              />
              <CsvDownloadButton
                handleDownload={handleGetStartupViewSessionsCsv}
                tooltip="Export to CSV a list of all view sessions on your one-pager & deck"
                isDisabled={!canDownloadCsv}
              />
            </div>
          </div>
          <AccessManagerViewSessionsTable
            headers={getTableHeaders(canAccessViewSessions)}
            refreshData={fetchStartupAccessManager}
            parentPage={apiResponse.page}
            parentTotalCount={apiResponse.totalCount}
            parentSortOrder={apiResponse.sortOrder}
            parentSortedColumn={apiResponse.sortKey}
            parentFilter={apiResponse.filter}
            parentPerPage={apiResponse.perPage}
            parentTotalPages={apiResponse.totalPages}
            viewSessionViews={apiResponse.results}
            blurRowsAfterIndex={canAccessViewSessions ? undefined : 4}
          />
        </>
      )}

      {apiResponse.totalCount > 0 && apiResponse.totalCount < 5 && (
        <div className="my-4">
          <Alert
            color="blue"
            alertType="Info"
            content={
              <p>
                <Link
                  className="hyperlink font-bold underline"
                  to={STARTUP_FLOWLINK_MANAGER_ROUTE}
                >
                  Send your FlowLink
                </Link>{' '}
                to investors to securely share your deck and company one-pager
              </p>
            }
            isShown={true}
            canDismiss={false}
          />
        </div>
      )}
    </>
  );
}

export default AccessManagerViewSessions;
