import { clsx, ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Utility function to merge Tailwind CSS classes with conditional class names.
 *
 * This function combines the functionality of `clsx` and `tailwind-merge`.
 * It takes in multiple class name inputs, which can be strings, arrays, or objects,
 * and returns a string of merged class names. The `twMerge` function is used to handle
 * Tailwind CSS class conflicts, ensuring that the final class string is optimized
 * and free of conflicts.
 *
 * @param {...ClassValue[]} inputs - A list of class values that can be strings, arrays, or objects.
 *                                   The values will be processed by `clsx` to conditionally combine
 *                                   class names and then passed to `twMerge` to handle Tailwind CSS
 *                                   class merging.
 * @returns {string} - A single string of merged class names, optimized for Tailwind CSS.
 *
 * @example
 * // Basic usage
 * const className = cn('bg-red-500', 'text-white', { 'font-bold': isActive });
 * // Result: 'bg-red-500 text-white font-bold' (assuming isActive is true)
 *
 * @example
 * // Handling conflicting Tailwind classes
 * const className = cn('bg-red-500', 'bg-blue-500');
 * // Result: 'bg-blue-500'
 *
 * @example
 * // Handling conflicting Tailwind classes
 * const className = cn('bg-red-400', 'bg-blue-500');
 * // Result: 'bg-blue-500'
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
