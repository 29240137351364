//
// ##### All the following select options should not be modified lightly ######
//
// The options are sorted, Ask before modifying the order of any options.
// This is the source of truth, but the back end is using this options too.
//

import { enumToTooltipSelectOptions } from '../../extensions/SelectOptionsExtensions';
import { TooltipSelectOption } from '../../types/SelectOptions';
import { RoundTypes, getRoundTypeTooltip } from '../RoundTypes';
import { STARTUP_GEO_OPTIONS } from './StartupGeographyOptions';
import { STARTUP_IND_OPTIONS } from './StartupIndustryOptions';

export const INVESTOR_LOCATION_OPTIONS = STARTUP_GEO_OPTIONS.filter(
  (x) => x.groupLabel !== 'Remote First'
);

const STATE_OPTIONS_NORTH_AMERICA = [
  {
    groupLabel: 'United States',
    options: [
      { value: 'Alabama', label: 'Alabama' },
      { value: 'Alaska', label: 'Alaska' },
      { value: 'Arizona', label: 'Arizona' },
      { value: 'Arkansas', label: 'Arkansas' },
      { value: 'California', label: 'California' },
      { value: 'Colorado', label: 'Colorado' },
      { value: 'Connecticut', label: 'Connecticut' },
      { value: 'Delaware', label: 'Delaware' },
      { value: 'District of Columbia', label: 'District of Columbia' },
      { value: 'Florida', label: 'Florida' },
      { value: 'Georgia', label: 'Georgia' },
      { value: 'Hawaii', label: 'Hawaii' },
      { value: 'Idaho', label: 'Idaho' },
      { value: 'Illinois', label: 'Illinois' },
      { value: 'Indiana', label: 'Indiana' },
      { value: 'Iowa', label: 'Iowa' },
      { value: 'Kansas', label: 'Kansas' },
      { value: 'Kentucky', label: 'Kentucky' },
      { value: 'Louisiana', label: 'Louisiana' },
      { value: 'Maine', label: 'Maine' },
      { value: 'Maryland', label: 'Maryland' },
      { value: 'Massachusetts', label: 'Massachusetts' },
      { value: 'Michigan', label: 'Michigan' },
      { value: 'Minnesota', label: 'Minnesota' },
      { value: 'Mississippi', label: 'Mississippi' },
      { value: 'Missouri', label: 'Missouri' },
      { value: 'Montana', label: 'Montana' },
      { value: 'Nebraska', label: 'Nebraska' },
      { value: 'Nevada', label: 'Nevada' },
      { value: 'New Hampshire', label: 'New Hampshire' },
      { value: 'New Jersey', label: 'New Jersey' },
      { value: 'New Mexico', label: 'New Mexico' },
      { value: 'New York', label: 'New York' },
      { value: 'North Carolina', label: 'North Carolina' },
      { value: 'North Dakota', label: 'North Dakota' },
      { value: 'Ohio', label: 'Ohio' },
      { value: 'Oklahoma', label: 'Oklahoma' },
      { value: 'Oregon', label: 'Oregon' },
      { value: 'Pennsylvania', label: 'Pennsylvania' },
      { value: 'Rhode Island', label: 'Rhode Island' },
      { value: 'South Carolina', label: 'South Carolina' },
      { value: 'South Dakota', label: 'South Dakota' },
      { value: 'Tennessee', label: 'Tennessee' },
      { value: 'Texas', label: 'Texas' },
      { value: 'Utah', label: 'Utah' },
      { value: 'Vermont', label: 'Vermont' },
      { value: 'Virginia', label: 'Virginia' },
      { value: 'Washington', label: 'Washington' },
      { value: 'West Virginia', label: 'West Virginia' },
      { value: 'Wisconsin', label: 'Wisconsin' },
      { value: 'Wyoming', label: 'Wyoming' },
    ],
  },
  {
    groupLabel: 'Canada',
    options: [
      { value: 'Alberta', label: 'Alberta' },
      { value: 'British Columbia', label: 'British Columbia' },
      { value: 'Manitoba', label: 'Manitoba' },
      { value: 'New Brunswick', label: 'New Brunswick' },
      {
        value: 'Newfoundland and Labrador',
        label: 'Newfoundland and Labrador',
      },
      { value: 'Northwest Territories', label: 'Northwest Territories' },
      { value: 'Nova Scotia', label: 'Nova Scotia' },
      { value: 'Nunavut', label: 'Nunavut' },
      { value: 'Ontario', label: 'Ontario' },
      { value: 'Prince Edward Island', label: 'Prince Edward Island' },
      { value: 'Quebec', label: 'Quebec' },
      { value: 'Saskatchewan', label: 'Saskatchewan' },
      { value: 'Yukon', label: 'Yukon' },
    ],
  },
];

export const STATE_OPTIONS = STATE_OPTIONS_NORTH_AMERICA.concat(
  STARTUP_GEO_OPTIONS.filter(
    (x) =>
      x.groupLabel === 'Central & South America' ||
      x.groupLabel === 'Western & Northern Europe' ||
      x.groupLabel === 'Central & Eastern Europe' ||
      x.groupLabel === 'Middle East & Northern Africa' ||
      x.groupLabel === 'Western & Central Africa' ||
      x.groupLabel === 'Eastern & Southern Africa' ||
      x.groupLabel === 'Western & Central Asia' ||
      x.groupLabel === 'Southeast Asia & Oceania'
  )
);

export const FUNCTIONAL_EXPERTISE_OPTIONS = [
  { value: 'Accounting & Bookkeeping', label: 'Accounting & Bookkeeping' },
  { value: 'Biomedical Engineering', label: 'Biomedical Engineering' },
  { value: 'Branding & Social Media', label: 'Branding & Social Media' },
  { value: 'Business Development', label: 'Business Development' },
  { value: 'Communications & PR', label: 'Communications & PR' },
  { value: 'Community Building', label: 'Community Building' },
  { value: 'Corporate Finance', label: 'Corporate Finance' },
  { value: 'Crowdfunding', label: 'Crowdfunding' },
  { value: 'Customer Experience', label: 'Customer Experience' },
  { value: 'Data Science & Analytics', label: 'Data Science & Analytics' },
  { value: 'Debt Issuance', label: 'Debt Issuance' },
  { value: 'Design & UI/UX', label: 'Design & UI/UX' },
  { value: 'Electrical Engineering', label: 'Electrical Engineering' },
  { value: 'Food Science', label: 'Food Science' },
  { value: 'Government', label: 'Government' },
  { value: 'Growth Hacking', label: 'Growth Hacking' },
  { value: 'Hardware Engineering', label: 'Hardware Engineering' },
  { value: 'Hiring', label: 'Hiring' },
  { value: 'Human Resources', label: 'Human Resources' },
  { value: 'Law', label: 'Law' },
  { value: 'Management & Leadership', label: 'Management & Leadership' },
  { value: 'Marketing & Advertising', label: 'Marketing & Advertising' },
  { value: 'Mechanical Engineering', label: 'Mechanical Engineering' },
  { value: 'Operations', label: 'Operations' },
  { value: 'Optical Engineering', label: 'Optical Engineering' },
  { value: 'Pricing', label: 'Pricing' },
  { value: 'Product Management', label: 'Product Management' },
  { value: 'Research', label: 'Research' },
  { value: 'Robotics', label: 'Robotics' },
  { value: 'Sales', label: 'Sales' },
  { value: 'Software Engineering', label: 'Software Engineering' },
  { value: 'VC Fundraising', label: 'VC Fundraising' },
];

export const IP_PROTECTIONS_OPTIONS = [
  { value: 'Patents', label: 'Patents' },
  { value: 'Copyrights', label: 'Copyrights' },
  { value: 'Trademarks', label: 'Trademarks' },
];

export const PRONOUNS_OPTIONS = [
  { value: 'he/him', label: 'he/him' },
  { value: 'she/her', label: 'she/her' },
  { value: 'they/them', label: 'they/them' },
  { value: 'xe/xem', label: 'xe/xem' },
  { value: 'ze/zir', label: 'ze/zir' },
  { value: 'ze/hir', label: 'ze/hir' },
];

export const IMPACT_OPTIONS = [
  { value: 'Not Applicable', label: 'Not Applicable' },
  { value: 'Female founder/C-suite', label: 'Female founder/C-suite' },
  { value: 'Black founder/C-suite', label: 'Black founder/C-suite' },
  { value: 'Latinx founder/C-suite', label: 'Latinx founder/C-suite' },
  { value: 'AAPI founder/C-suite', label: 'AAPI founder/C-suite' },
  {
    value: 'Native American founder/C-suite',
    label: 'Native American founder/C-suite',
  },
  { value: 'LGBTQIA+ founder/C-suite', label: 'LGBTQIA+ founder/C-suite' },
  { value: 'Veteran founder/C-suite', label: 'Veteran founder/C-suite' },
  { value: 'Student founder/C-suite', label: 'Student founder/C-suite' },
  { value: 'PhD founder/C-suite', label: 'PhD founder/C-suite' },
  { value: 'Dropout founder/C-suite', label: 'Dropout founder/C-suite' },
  { value: 'GenZ founder/C-suite', label: 'GenZ founder/C-suite' },
  { value: 'Immigrant founder/C-suite', label: 'Immigrant founder/C-suite' },
  { value: 'United Nations SDGs focus', label: 'United Nations SDGs focus' },
  { value: 'Social impact', label: 'Social impact' },
  { value: 'Children impact', label: 'Children impact' },
  { value: 'Environmental impact', label: 'Environmental impact' },
  { value: 'Cultural impact', label: 'Cultural impact' },
];

export const BUSINESS_MODEL_OPTIONS = [
  {
    value: 'B2C',
    label: 'B2C',
    tooltipText: 'Selling to individual consumers',
  },
  {
    value: 'B2B SMB',
    label: 'B2B SMB',
    tooltipText: 'Selling to small and medium-sized businesses',
  },
  {
    value: 'B2B Enterprise',
    label: 'B2B Enterprise',
    tooltipText: 'Selling to large enterprises',
  },
  {
    value: 'B2B NFP & NGO',
    label: 'B2B NFP & NGO',
    tooltipText:
      'Selling to not-for-profits and non-governmental organizations',
  },
  {
    value: 'B2B2B',
    label: 'B2B2B',
    tooltipText:
      'Selling infrastructure to businesses selling to other businesses',
  },
  {
    value: 'B2B2C',
    label: 'B2B2C',
    tooltipText:
      'Selling infrastructure to businesses selling to other individual consumers',
  },
  {
    value: 'B2Gov',
    label: 'B2Gov',
    tooltipText: 'Selling to governments and governmental organizations',
  },
  {
    value: 'Marketplaces & Platforms',
    label: 'Marketplaces & Platforms',
    tooltipText: 'Facilitating transactions between buyers and sellers',
  },
];

// Do not change order. It is referenced in QSBS potential
export const COMPANY_TYPE_OPTIONS = [
  { value: 'Operating Company', label: 'Operating Company' },
  { value: 'Holding Company', label: 'Holding Company' },
  { value: 'Investing Company', label: 'Investing Company' },
];

export const PRODUCT_STATUS_OPTIONS = [
  {
    value: 'Idea/Concept',
    label: 'Idea/Concept',
    tooltipText: 'You have not started working on your idea',
  },
  {
    value: 'Building MVP',
    label: 'Building MVP',
    tooltipText:
      'You have started working on your idea but do not have an MVP ready',
  },
  {
    value: 'Working Prototype',
    label: 'Working Prototype',
    tooltipText: 'You have an MVP ready but it is not launched',
  },
  {
    value: 'Private Beta',
    label: 'Private Beta',
    tooltipText: 'Your MVP is launched with a private selection of users',
  },
  {
    value: 'Public Beta',
    label: 'Public Beta',
    tooltipText: 'Your MVP is launched and anyone can access it',
  },
  {
    value: 'Product Launched',
    label: 'Product Launched',
    tooltipText:
      'You have launched a product that people are using and paying for',
  },
  {
    value: 'Scaling & Growth',
    label: 'Scaling & Growth',
    tooltipText: 'Your product has reached PMF and you are building to scale',
  },
];

export const INV_PRODUCT_STAGE_OPTIONS = [
  { value: 'Pre-Product (Idea/Concept)', label: 'Pre-Product (Idea/Concept)' },
  {
    value: 'Pre-Revenue (MVP in Market)',
    label: 'Pre-Revenue (MVP in Market)',
  },
  {
    value: 'Post-Revenue (Early Traction)',
    label: 'Post-Revenue (Early Traction)',
  },
  {
    value: 'Post-Revenue ($500K - $5M ARR)',
    label: 'Post-Revenue ($500K - $5M ARR)',
  },
  {
    value: 'Scaling & Growth ($5M+ ARR)',
    label: 'Scaling & Growth ($5M+ ARR)',
  },
];

/**
 * IMPORTANT! Some validation logic depends on the order of these options.
 * If the array changes, make sure to update all logic that depends on it.
 * Note: Some fields depend on 'Transactional' being selected
 * ...this pattern is stupid btw
 */
export const REVENUE_TYPE_OPTIONS: TooltipSelectOption[] = [
  {
    value: 'Advertising-Based',
    label: 'Advertising-Based',
    tooltipText: 'Per-Ad, Per-Search (ex: LinkedIn, Snapchat, Indeed)',
  },
  {
    value: 'Affiliate Model',
    label: 'Affiliate Model',
    tooltipText:
      'Commissions, Rewards (ex: Credit Karma, Nerdwallet, Awin, CJ Affiliate, ClickBank)',
  },
  {
    value: 'One-Off Purchases',
    label: 'One-Off Purchases',
    tooltipText:
      'E-commerce, DTC, CPG (ex: Zara, Nike, GAP, Oatly, La Croix, Beyond Meat)',
  },
  {
    value: 'Recurring Subscription',
    label: 'Recurring Subscription',
    tooltipText: 'Monthly or Annual (ex: Zoom, Atlassian, Notion, Slack)',
  },
  {
    value: 'Services & Consulting',
    label: 'Services & Consulting',
    tooltipText:
      'Engagements, Projects (ex: Accenture, Cognizant, Capgemini, Infosys)',
  },
  {
    value: 'Transactional',
    label: 'Transactional',
    tooltipText:
      'Marketplaces, Take Rates, Interchange (ex: Stripe, Uber, eBay, Shopify, Coinbase)',
  },
  {
    value: 'Usage-Based',
    label: 'Usage-Based',
    tooltipText:
      'Volume pay-as-you-go (ex: Snowflake, Twillio, AWS, DigitalOcean)',
  },
];

export const EMPLOYEE_COUNT_OPTIONS = [
  { value: '1', label: '1' },
  { value: '2-10', label: '2-10' },
  { value: '11-25', label: '11-25' },
  { value: '26-50', label: '26-50' },
  { value: '51-100', label: '51-100' },
  { value: '101-250', label: '101-250' },
  { value: '251-500', label: '251-500' },
  { value: '501-1000', label: '501-1000' },
  { value: '1000+', label: '1000+' },
];

export const FOUNDER_COMMITMENT_OPTIONS = [
  { value: 'Full-Time', label: 'Full-Time' },
  { value: 'Part-Time', label: 'Part-Time' },
  { value: 'Advisory', label: 'Advisory' },
];

export const EDUCATION_LEVEL_OPTIONS = [
  {
    value: 'Some high school, no degree',
    label: 'Some high school, no degree',
  },
  {
    value: 'High school graduate or equivalent',
    label: 'High school graduate or equivalent',
  },
  {
    value: 'Some college credit, no degree',
    label: 'Some college credit, no degree',
  },
  { value: "Associate's degree", label: "Associate's degree" },
  { value: "Bachelor's degree", label: "Bachelor's degree" },
  { value: "Master's degree", label: "Master's degree" },
  { value: 'Professional degree', label: 'Professional degree' },
  { value: 'Doctorate degree', label: 'Doctorate degree' },
  { value: 'Post-Doctoral studies', label: 'Post-Doctoral studies' },
];

export const YEARLY_AVG_INVESTMENT_COUNT_OPTIONS = [
  { value: '1-5', label: '1-5' },
  { value: '6-10', label: '6-10' },
  { value: '11-20', label: '11-20' },
  { value: '20+', label: '20+' },
];

// Investor Geographies should not contain "Remote First"
export const INV_GEO_OPTIONS = [
  { value: 'Any Geography', label: 'Any Geography' },
].concat(
  STARTUP_GEO_OPTIONS.filter((x) => x.groupLabel !== 'Remote First').map((x) =>
    Object({ value: x.groupLabel, label: x.groupLabel })
  )
);

export const INV_IND_ANY_INDUSTRY = 'Any Industry';
export const INV_IND_OPTIONS = [
  { value: INV_IND_ANY_INDUSTRY, label: INV_IND_ANY_INDUSTRY },
].concat(STARTUP_IND_OPTIONS);

export const BOOLEAN_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
] as const;

export const NOT_SET_OPTION = {
  value: null,
  label: 'Not Set',
} as const;

export const BOOLEAN_WITH_UNSET_OPTIONS = [...BOOLEAN_OPTIONS, NOT_SET_OPTION];

// We do not support this for the foreseable future
// export const ROUND_OFFERING_OPTIONS = [
//   { value: 'Reg. A Tier 1', label: 'Reg. A Tier 1' },
//   { value: 'Reg. A Tier 2', label: 'Reg. A Tier 2' },
//   { value: 'Reg. D 504', label: 'Reg. D 504' },
//   { value: 'Reg. D 506(b)', label: 'Reg. D 506(b)' },
//   { value: 'Reg. D 506(c)', label: 'Reg. D 506(c)' },
//   { value: 'Reg. CF', label: 'Reg. CF' },
// ];

export const INV_ROUND_TYPE_OPTIONS = enumToTooltipSelectOptions(
  RoundTypes,
  getRoundTypeTooltip
).filter((x) => x.value !== 'To Be Determined');

export const REASONS_FOR_INVESTING = [
  { value: 'Team', label: 'Team' },
  { value: 'Problem', label: 'Problem' },
  { value: 'Solution', label: 'Solution' },
  { value: 'Traction', label: 'Traction' },
  { value: 'Market Size', label: 'Market Size' },
  { value: 'Business Model', label: 'Business Model' },
  { value: 'Go-To-Market Strategy', label: 'Go-To-Market Strategy' },
  { value: 'Capital Efficiency', label: 'Capital Efficiency' },
  { value: 'Competitive Landscape', label: 'Competitive Landscape' },
  { value: 'Product', label: 'Product' },
  { value: 'Technology & IP', label: 'Technology & IP' },
  { value: 'Customer Feedback', label: 'Customer Feedback' },
  { value: 'Geographical Focus', label: 'Geographical Focus' },
  { value: 'Mission & Values', label: 'Mission & Values' },
  { value: 'Impact', label: 'Impact' },
  { value: 'Exit Strategy', label: 'Exit Strategy' },
  { value: 'Other Investors', label: 'Other Investors' },
  { value: 'Advisors', label: 'Advisors' },
];
