import React from 'react';
import * as yup from 'yup';
import FormikInput from '/src/components/inputs/FormikInput';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import FormField from '/src/interfaces/FormField';
import { Field } from 'formik';

const LastNameField: FormField = {
  fieldComponent: (
    <Field
      component={FormikInput}
      label="Last Name"
      name={AuthFieldNames.LastName}
      type="text"
    />
  ),
  validation: yup.object({
    [AuthFieldNames.LastName]: yup
      .string()
      .max(40, 'Must be at most 40 characters')
      .required('Required'),
  }),
};

export default LastNameField;
