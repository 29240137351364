import React from 'react';

export interface CellFirmAndRoleProps {
  firm?: string;
  role?: string;
}

function CellFirmAndRole({ firm, role }: CellFirmAndRoleProps) {
  return (
    <>
      {firm && role ? (
        <>
          <div className="app-table-row-text">{firm}</div>
          <div className="app-table-row-text--light">{role}</div>
        </>
      ) : (
        <div className="app-table-row-text">-</div>
      )}
    </>
  );
}

export default CellFirmAndRole;
