import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import StartupMultiStepForm from '/src/components/StartupForm/StartupMultiStepForm';
import {
  ACCOUNT_CHOICE_ROUTE,
  ADMIN_ROUTE,
  COMPLETE_PROFILE_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  PUBLIC_NOT_FOUND_ROUTE,
  SHARE_LINK_DATA_ACCESS_ROUTE,
  SIGNUP_ROUTE,
  SIGNUP_SUCCESS_ROUTE,
} from '/src/constants/Routes';
import InvestorMultiStepForm from '/src/components/InvestorForm/InvestorMultiStepForm';
import AppEntryPoint from './AppEntryPoint';
import AccountChoiceRoute from '/src/routes/common/Choice/AccountChoiceRoute';
import LinkShareEntryPoint from '/src/routes/common/LinkShareEntryPoint/LinkShareEntryPoint';
import SignupRoute from '/src/routes/common/Signup/SignupRoute';
import VerifyEmail from '/src/routes/common/VerifyEmail/VerifyEmail';
import VerifySession from '/src/routes/common/VerifySession/VerifySession';
import GoogleCognitoTokensExchange from './common/GoogleAuth/GoogleCognitoTokensExchange';
import SignupSuccessPage from './common/Signup/SignupSuccess';
import { HelmetProvider } from 'react-helmet-async';
import LoginRoute from './common/Login/LoginRoute';
import ShareLinkDataAccessRoute from './common/ShareLinkDataAccessError';
import ForgotPasswordRoute from '/src/routes/common/Login/ForgotPasswordRoute';
import PublicNotFoundRoute from './common/NotFound/PublicNotFoundRoute';
import AdminEntrypoint from '../components/utility/AdminEntrypoint';
import AdminRoutes from './AdminRoutes';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import PublicInvestorList from './common/PublicInvestorList/PublicInvestorList';

export default (
  <HelmetProvider>
    <Routes>
      <Route path={LOGIN_ROUTE} element={<LoginRoute />} />
      <Route path={SIGNUP_ROUTE} element={<SignupRoute />} />
      <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPasswordRoute />} />
      <Route
        path={SHARE_LINK_DATA_ACCESS_ROUTE}
        element={<ShareLinkDataAccessRoute />}
      />
      <Route
        path={`${LinkTypes.Flowlink}/:linkShareToken/*`}
        element={<LinkShareEntryPoint />}
      />
      <Route
        path={`${LinkTypes.Dealshare}/:linkShareToken/*`}
        element={<LinkShareEntryPoint />}
      />
      <Route
        path={`${LinkTypes.InvestorList}/:linkShareToken/*`}
        element={<PublicInvestorList />}
      />
      <Route
        path="/verifyEmail/:codeParameter/:userName/"
        element={<VerifyEmail />}
      />
      <Route
        path="/verify-email/:codeParameter/:userName"
        element={<VerifyEmail />}
      />
      <Route
        path="/deal-view-session/verify/:verificationToken"
        element={<VerifySession />}
      />
      <Route path={SIGNUP_SUCCESS_ROUTE} element={<SignupSuccessPage />} />
      <Route
        path="/exchange-tokens"
        element={<GoogleCognitoTokensExchange />}
      />
      <Route
        path={`${COMPLETE_PROFILE_ROUTE}/startup/*`}
        element={<StartupMultiStepForm />}
      />
      <Route
        path={`${COMPLETE_PROFILE_ROUTE}/investor/*`}
        element={<InvestorMultiStepForm />}
      />
      <Route path={ACCOUNT_CHOICE_ROUTE} element={<AccountChoiceRoute />} />
      <Route path="/app/*" element={<AppEntryPoint />} />
      <Route path={PUBLIC_NOT_FOUND_ROUTE} element={<PublicNotFoundRoute />} />
      <Route
        path={`${ADMIN_ROUTE}/*`}
        element={<AdminEntrypoint>{AdminRoutes}</AdminEntrypoint>}
      />
      <Route path="*" element={<Navigate to={LOGIN_ROUTE} />} />
    </Routes>
  </HelmetProvider>
);
