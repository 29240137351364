import React from 'react';
import {
  getPluralSuffix,
  numberWithCommas,
} from '/src/util/formatting/numbers';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import { REVENUE_TYPE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';

function formatLtvCacRatio(startup: PublicStartup): string {
  const value = startup.lifeTimeValue! / startup.customerAcquisitionCost!;
  if (value >= 0.01) {
    return value.toFixed(2);
  }
  return 'Less than .01';
}

interface DealDetailFinanceCardProps {
  startup: PublicStartup;
}

function DealDetailFinancialCard({
  startup,
}: DealDetailFinanceCardProps): JSX.Element {
  if (
    startup.revenue === undefined &&
    startup.avgTakeRate === undefined &&
    startup.revenueMonthlyGmv === undefined &&
    startup.revenueGrowth === undefined &&
    startup.bookingsAmount === undefined &&
    startup.netRevenueRetention === undefined &&
    startup.netRevenueLastYear === undefined &&
    startup.netRevenueYTD === undefined &&
    startup.grossMargin === undefined &&
    startup.netMargin === undefined &&
    startup.avgOrderValue === undefined &&
    startup.avgRevenuePerUser === undefined &&
    startup.customerAcquisitionCost === undefined &&
    startup.lifeTimeValue === undefined &&
    startup.netMonthlyBurn === undefined &&
    startup.runway === undefined
  ) {
    return <></>;
  }

  return (
    <section>
      <div className="rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Financial Traction & Unit Economics
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            {startup.revenue !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {startup.revenueType === REVENUE_TYPE_OPTIONS[3].value
                    ? 'Monthly Recurring Revenue (MRR)'
                    : 'Monthly Revenue'}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`$ ${numberWithCommas(startup.revenue)}`}
                </dd>
              </div>
            )}

            {startup.avgTakeRate !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Average Take Rate
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`${startup.avgTakeRate.toFixed(2)} %`}
                </dd>
              </div>
            )}

            {startup.revenueMonthlyGmv !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Monthly GMV
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`$ ${numberWithCommas(startup.revenueMonthlyGmv)}`}
                </dd>
              </div>
            )}

            {startup.revenueGrowth !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Revenue Growth (MoM)
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`${numberWithCommas(startup.revenueGrowth)} %`}
                </dd>
              </div>
            )}

            {startup.bookingsAmount !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Bookings and Signed Contracts
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`$ ${numberWithCommas(startup.bookingsAmount)}`}
                </dd>
              </div>
            )}

            {startup.netRevenueRetention !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Net Revenue Retention
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`${numberWithCommas(startup.netRevenueRetention)} %`}
                </dd>
              </div>
            )}

            {startup.netRevenueLastYear !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Net Revenue (Last Year)
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`$ ${numberWithCommas(startup.netRevenueLastYear)}`}
                </dd>
              </div>
            )}
            {startup.netRevenueYTD !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Net Revenue (YTD)
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`$ ${numberWithCommas(startup.netRevenueYTD)}`}
                </dd>
              </div>
            )}

            {startup.netMonthlyBurn !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Net Monthly Burn
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`$ ${numberWithCommas(startup.netMonthlyBurn)}`}
                </dd>
              </div>
            )}
            {startup.runway !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Current Runway
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`${startup.runway} month${getPluralSuffix(startup.runway)}`}
                </dd>
              </div>
            )}

            {startup.grossMargin !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Gross Margin
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`${numberWithCommas(startup.grossMargin)} %`}
                </dd>
              </div>
            )}

            {startup.netMargin !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Net Margin
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`${numberWithCommas(startup.netMargin)} %`}
                </dd>
              </div>
            )}

            {startup.avgOrderValue !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Average Order Value
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`$ ${numberWithCommas(startup.avgOrderValue)}`}
                </dd>
              </div>
            )}

            {startup.avgRevenuePerUser !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Average Revenue Per User
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`$ ${numberWithCommas(startup.avgRevenuePerUser)}`}
                </dd>
              </div>
            )}

            {startup.customerAcquisitionCost !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Customer Acquisition Cost
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`$ ${numberWithCommas(startup.customerAcquisitionCost)}`}
                </dd>
              </div>
            )}

            {startup.customerAcquisitionCost !== undefined &&
              startup.lifeTimeValue !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    LTV/CAC Ratio
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {formatLtvCacRatio(startup)}
                  </dd>
                </div>
              )}

            {startup.lifeTimeValue !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Lifetime Value
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`$ ${numberWithCommas(startup.lifeTimeValue)}`}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
}

export default DealDetailFinancialCard;
