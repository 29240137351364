import { listToSelectOptions } from '../../extensions/SelectOptionsExtensions';

const INCUBATORS = [
  '406 Labs',
  '500 Startups',
  'Accelerace',
  'Accelerator Centre',
  'Ai2 Incubator',
  'Alchemist Accelerator',
  'AlphaLab',
  'Amplify LA',
  'AngelPad',
  'Antler',
  'ATDC',
  'AWS Accelerator',
  'Bain & Company',
  'Barclays Accelerator',
  'Berkeley SkyDeck',
  'Betaworks',
  'Braid Theory',
  'Built in Miami Accelerator',
  'Capital Factory',
  'CFBI Accelerator',
  'CivTech Scotland',
  'Comcast SportsTech',
  'Creative Destruction Labs',
  'DivInc',
  'Dreamit',
  'EIT Digital Accelerator',
  'Element 46',
  'EllisDon ConTech Accelerator',
  'Embarc Collective',
  'Endeavor',
  'Entrepreneurs Roundtable Accelerator',
  'EvoNexus',
  'Expa',
  'FIS Fintech Accelerator',
  'FK Accelerate',
  'Founder Friendly Labs',
  'Founder Institute',
  'Founders Factory',
  'gener8tor Accelerator',
  'German Accelerator',
  'Google Launchpad Accelerator',
  'Grand Central Tech',
  'HAX by SOSV',
  'HF0',
  'IndieBio by SOSV',
  'Innovation Works',
  'Intelak',
  'Lair East Labs',
  'Lanzadera Accelerator',
  'Launch Alaska',
  'Launchpad LA',
  'Le Camp Quebec',
  'Lightship Accelerator',
  'LUISS EnLabs',
  'Marl Accelerator',
  'MassChallenge',
  'Microsoft Accelerator',
  'MuckerLab',
  'Navigate Accelerator',
  'NEC-X',
  'Neo Accelerator',
  'Newchip Accelerator',
  'Next Canada',
  'NextCycle',
  'Nextgrid',
  'ODX',
  'On Deck Founders',
  'Orange Fab',
  'Orbit Startups by SOSV',
  'OXO Labs',
  'Parallel18',
  'PAX Momentum',
  'Plug and Play',
  'Prepare 4 VC',
  'RevTech Labs',
  'Rockstart',
  'RubikHub',
  'Seed Spot',
  'Seedstars Acceleration',
  'Sequoia Arc',
  'Space3ac',
  'StartOut',
  'Startup Chile',
  'Startup Farm',
  'StartUp Health',
  'Startup Mexico',
  'Startup Peru',
  'Startup Warrior',
  'StartX',
  'Station F',
  'Sting',
  'Strata Accelerator',
  'Tampa Bay Wave',
  'Techstars',
  'The Brandery',
  'The DMZ',
  'The Yield Lab',
  'Toilet Board Coalition',
  'Travel Startups Incubator',
  'Union Kitchen Accelerator',
  'URBAN-X',
  'Venture 24 Inc.',
  'Venture Hive',
  'Venture Kick',
  'Wayra',
  'WEVE Acceleration',
  'xEdu',
  'XRC Labs',
  'Y Combinator',
];

export const INCUBATOR_OPTIONS = listToSelectOptions(INCUBATORS);
