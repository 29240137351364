import {
  InvestorRequestsNew,
  SharedLinkSharingConsumeFlowlink,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import { RequestTypes } from '/../libs/shared-types/src/constants/RequestTypes';
import { LinkShareResponse } from '/../libs/shared-types/src/types/view/APIResponse';
import API from '/src/middleware/API';

function consumeFlowlink(
  token: string,
  message?: string,
  discoveredFrom?: string
) {
  return API.post<LinkShareResponse>(
    SharedLinkSharingConsumeFlowlink.buildEndpoint(),
    {
      token,
      message,
      discoveredFrom,
    }
  );
}

function consumeDealshareLink(token: string) {
  return API.post<LinkShareResponse>(InvestorRequestsNew.buildEndpoint(), {
    type: RequestTypes.DealShare,
    newRequestData: {
      token,
    },
  });
}

function consumeLink(
  token: string,
  linkType: LinkTypes,
  message?: string,
  discoveredFrom?: string
): Promise<LinkShareResponse> {
  if (linkType === LinkTypes.Flowlink) {
    return consumeFlowlink(token, message, discoveredFrom);
  }

  if (linkType === LinkTypes.Dealshare) {
    return consumeDealshareLink(token);
  }
  throw Error('An error occurred while consuming the link');
}

export default consumeLink;
