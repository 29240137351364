import React from 'react';
import {
  InboxArrowDownIcon,
  PresentationChartLineIcon,
  HandThumbDownIcon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline';

import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import { joinClassNames } from '/src/util/formatting/strings';

interface DealStatusLabelProps {
  label: string;
}

function DealStatusLabel({ label }: DealStatusLabelProps): JSX.Element {
  let icon;
  let colors;
  switch (label) {
    case DealStatusTypes.Screening:
      icon = <InboxArrowDownIcon className="h-4 w-4" />;
      colors = 'bg-gray-300 text-gray-700 focus:ring-gray-300';
      break;
    case DealStatusTypes.Active:
      icon = <ViewColumnsIcon className="h-4 w-4" />;
      colors = 'bg-blue-300 text-blue-700 focus:ring-blue-300';
      break;
    case DealStatusTypes.Portfolio:
      icon = <PresentationChartLineIcon className="h-4 w-4" />;
      colors = 'bg-green-300 text-green-700 focus:ring-green-300';
      break;
    case DealStatusTypes.Passed:
      icon = <HandThumbDownIcon className="h-4 w-4" />;
      colors = 'bg-red-100 text-red-800 focus:ring-red-300';
      break;
    default:
      icon = <InboxArrowDownIcon className="h-4 w-4" />;
      colors = 'bg-gray-300 text-gray-700 focus:ring-gray-300';
  }
  return (
    <div
      className={joinClassNames(
        colors,
        'inline-flex justify-center rounded-md px-2.5 py-1 text-xs font-bold tracking-wider shadow-sm',
        'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100'
      )}
    >
      {icon}
      <p className="ml-2 font-bold">{label.toUpperCase()}</p>
    </div>
  );
}

export default DealStatusLabel;
