import { isMinDate } from './time';
import { Round } from '/../libs/shared-types/src/types/model/Round';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import { shouldHaveValuationCap } from '/../libs/shared-types/src/extensions/RoundExtensions';

export interface RoundValuation {
  valuationCap: number | undefined;
  valuationCapType: string | undefined;
}

/**
 * Finds a round that correlates by date with a deal status update time
 * @param roundHistory List of rounds to search through
 * @param statusTime The time that the status you are looking to correlate was modified
 * @returns The round the status corresponds to or undefined if there is no
 * round that matches
 */
export function roundAtStatus(
  roundHistory: RoundView[],
  statusTime: Date
): RoundView | undefined {
  return roundHistory
    .sort((a, b) => a.openedOn.getTime() - b.openedOn.getTime())
    .find(
      (round) =>
        (isMinDate(round.closedOn) ||
          round.gracePeriodEndsOn.getTime() > statusTime.getTime()) &&
        round.openedOn.getTime() < statusTime.getTime()
    );
}

export function roundIsInGracePeriod(round: Round): boolean {
  return (
    !isMinDate(round.closedOn) &&
    !isMinDate(round.gracePeriodEndsOn) &&
    round.gracePeriodEndsOn.getTime() > Date.now()
  );
}

export function getLatestClosedRound(
  roundHistory: RoundView[] | undefined
): RoundView | undefined {
  if (!roundHistory || roundHistory.length === 0) {
    return undefined;
  }

  const sortedDescending = [...roundHistory]
    .filter((x) => !isRoundOpen(x))
    .sort((x, y) => y.openedOn.getTime() - x.openedOn.getTime());
  return sortedDescending.length > 0 ? sortedDescending[0] : undefined;
}

export function getLatestValuationCap(
  roundHistory: RoundView[] | undefined
): RoundValuation {
  const noValuation = { valuationCap: undefined, valuationCapType: undefined };
  if (!roundHistory || roundHistory.length === 0) {
    return noValuation;
  }

  const sortedDescending = [...roundHistory].sort(
    (x, y) => y.openedOn.getTime() - x.openedOn.getTime()
  );

  const latestRound = sortedDescending[0];
  // Check if the latest round has a valuation cap
  if (shouldHaveValuationCap(latestRound)) {
    return {
      valuationCap: latestRound.valuationCap,
      valuationCapType: latestRound.valuationCapType,
    };
  }

  // Check the penultimate round
  if (
    sortedDescending.length > 1 &&
    shouldHaveValuationCap(sortedDescending[1])
  ) {
    return {
      valuationCap: sortedDescending[1].valuationCap,
      valuationCapType: sortedDescending[1].valuationCapType,
    };
  }

  return noValuation;
}

export function isRoundOpen(round: Round): boolean {
  return isMinDate(round.closedOn);
}

export function isRoundClosedAndPastGracePeriod(round: Round): boolean {
  return !isRoundOpen(round) && !roundIsInGracePeriod(round);
}

export function isIncludedString(hasRights: boolean): string {
  return hasRights ? 'Included' : 'Not Included';
}
