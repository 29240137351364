import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import { PublicProfilePrivacySetting } from '/../libs/shared-types/src/constants/PublicProfilePrivacySetting';

export interface AuthDialogConfig {
  consumerAccountType: AccountTypes | undefined;
  startupName: string;
  startupAccountFirstName: string;
  sharerAccountType: AccountTypes;
  sharerFirstName: string;
  role?: string;
  firm?: string;
  companyLogo: string;
}

export interface AuthDialogData {
  footerText?: string;
  sharerFirm?: string;
  sharerRole?: string;
  subTitle: string;
  title: string;
}

export function configureForm(
  linkType: LinkTypes,
  privacySetting: PublicProfilePrivacySetting,
  authConfig: AuthDialogConfig,
): AuthDialogData {
  const data: AuthDialogData = {
    subTitle: '',
    title: '',
  };

  const investorTitleText =
    authConfig.firm && authConfig.role
      ? `${authConfig.role} at ${authConfig.firm}`
      : 'Angel';

  if (
    authConfig.sharerAccountType === AccountTypes.Investor &&
    authConfig.consumerAccountType === AccountTypes.Investor
  ) {
    data.title = `${authConfig.sharerFirstName}, ${investorTitleText}, is inviting you to connect on Flowlie.`;
    data.subTitle = 'You must create an account or log in to connect';
    return data;
  }

  if (
    authConfig.sharerAccountType === AccountTypes.Investor &&
    authConfig.consumerAccountType === AccountTypes.Startup
  ) {
    data.title = `Share your deck and company information with ${authConfig.sharerFirstName}, ${investorTitleText}`;
    data.subTitle =
      'You must create an account or log in to pitch your startup';
    return data;
  }

  switch (linkType) {
    case LinkTypes.Flowlink:
      switch (privacySetting) {
        case PublicProfilePrivacySetting.RequireNameAndEmail:
          data.title = `${authConfig.sharerFirstName} from ${authConfig.startupName} asks for your name and email to view their deck and company one-pager`;
          data.subTitle = 'Please enter your information below';
          data.footerText = `Your name and email will be shared with ${authConfig.startupAccountFirstName}, the founder of ${authConfig.startupName}`;
          break;

        case PublicProfilePrivacySetting.RequireFlowlieAccount:
          data.title = `${authConfig.sharerFirstName} from ${authConfig.startupName} asks you to log in to share their deck and company one-pager with you`;
          data.subTitle = 'Please log in or create an account to continue';
          break;

        default:
          break;
      }
      break;
    case LinkTypes.Dealshare:
      data.title = `${authConfig.sharerFirstName} (${investorTitleText}) shared ${authConfig.startupName} with you`;

      switch (privacySetting) {
        case PublicProfilePrivacySetting.RequireNameAndEmail:
          data.subTitle = 'Please enter your information below';
          data.footerText = `Your name and email will be shared with ${authConfig.startupAccountFirstName}, the founder of ${authConfig.startupName}`;
          break;

        case PublicProfilePrivacySetting.RequireFlowlieAccount:
          data.subTitle = 'Please log in or create an account to continue';
          break;

        default:
          break;
      }
      break;
    default:
      break;
  }

  return data;
}
