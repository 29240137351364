import React from 'react';

import { useNavigate } from 'react-router-dom';
import {
  abbreviateNumber,
  getPluralSuffix,
} from '/src/util/formatting/numbers';
import {
  formatImageAddress,
  shortenWebUrl,
} from '/src/util/formatting/strings';
import { generateTag } from '/src/util/generateTagCloud';
import DealActionsDropdown from '/src/components/DealActionsDropdown';
import {
  DealSummaryView,
  PortfolioDealSummaryView,
} from '/../libs/shared-types/src/types/view/AggregatedDeals';
import DateString from '/src/components/utility/DateString';
import CopyTextButton from '/src/components/CopyTextButton';
import SquaredLogo from '/src/components/SquaredLogo';

interface PortfolioDealProps {
  deal: PortfolioDealSummaryView;
  onShareDeal: (deal: DealSummaryView) => void;
}

function PortfolioCompaniesTableRow({
  deal,
  onShareDeal,
}: PortfolioDealProps): JSX.Element {
  const navigate = useNavigate();
  const handleOnClick = React.useCallback(
    () => navigate(`/app/deal-detail/${deal.startupId}`),
    [history],
  );
  const blockEvent = (event: any) => event.stopPropagation();

  const totalInvested = deal.investments
    .map((investment) => investment.amount)
    .reduce((a, b) => a + b);

  const totalEstimatedValue = deal.investments
    .map((investment) => investment.estimatedValue)
    .reduce((a, b) => (a ?? 0) + (b ?? 0));

  const totalMoic = totalEstimatedValue / totalInvested;

  return (
    <tr onClick={handleOnClick} className="cursor-pointer hover:bg-gray-100">
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <SquaredLogo
              containerClassName="size-10"
              src={formatImageAddress(deal.startupLogoKey)}
              alt={`${deal.startupName} logo`}
            />
          </div>
          <div className="ml-2 max-w-[160px]">
            <div className="app-table-row-text--bold">{deal.startupName}</div>
            <div className="flex w-full text-sm text-gray-500">
              <a
                className="block truncate hover:underline"
                target="_blank"
                rel="noopener noreferrer"
                href={deal.website}
                onClick={(e) => e.stopPropagation()}
              >
                {shortenWebUrl(deal.website)}
              </a>
              <CopyTextButton text={deal.website} className="ml-1" />
            </div>
          </div>
        </div>
      </td>
      <td className="app-table-row-item">{generateTag(deal.currentStage)}</td>
      <td className="app-table-row-item">
        {generateTag(deal.investments[deal.investments.length - 1].roundStage)}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          ${abbreviateNumber(totalInvested)}
          <div className="app-table-row-text--light">
            {deal.investments.length} investment
            {getPluralSuffix(deal.investments.length)}
          </div>
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          ${abbreviateNumber(totalEstimatedValue)}
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">{totalMoic.toFixed(2)}x</div>
      </td>
      <td className="app-table-row-item">
        {generateTag(deal.investments[0].boardParticipation)}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          <DateString
            date={deal.investments[deal.investments.length - 1].investedOn}
          />
        </div>
      </td>
      <td
        className="whitespace-nowrap px-3 py-3 text-right text-sm font-medium"
        onClick={blockEvent}
        onKeyDown={blockEvent}
        role="gridcell"
      >
        <DealActionsDropdown
          deal={deal}
          handleMoveToActive={() => null}
          handlePass={() => null}
          handleRecordInvestment={() => null}
          handleShare={onShareDeal}
        />
      </td>
    </tr>
  );
}

export default PortfolioCompaniesTableRow;
