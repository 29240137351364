export const MAX_ANTI_INVESTOR_INDUSTRIES = 10;
export const MAX_CONTACT_PREFERENCE = 3;
export const MAX_DISCARDED_REASONS = 3;
export const MAX_INCUBATORS = 3;
export const MAX_INVESTMENT_REASONS = 3;
export const MAX_INVESTOR_EXP_FUNCTIONAL = 3;
export const MAX_INVESTOR_EXP_INDUSTRY = 3;
export const MAX_INVESTOR_GEOGRAPHIES = 10;
export const MAX_INVESTOR_INDUSTRIES = 20;
export const MAX_NATIONALITIES = 3;
export const MAX_PASSED_REASONS = 3;
export const MAX_PROFESSIONAL_ACCREDITATIONS = 5;
export const MAX_STARTUP_BUSINESS_MODELS = 3;
export const MAX_STARTUP_INDUSTRIES = 2;
export const MAX_STARTUP_IP_PROTECTIONS = 3;
export const MAX_UNIVERSITY_AFFILIATIONS = 3;
export const MIN_INVESTOR_BUSINESS_MODELS = 1;
export const MIN_INVESTOR_GEOGRAPHIES = 1;
export const MIN_INVESTOR_IMPACTS = 1;
export const MIN_INVESTOR_INDUSTRIES = 1;
export const MIN_INVESTOR_STAGES = 1;
export const MIN_INVESTOR_TYPES = 1;
export const MIN_STARTUP_BUSINESS_MODELS = 1;
export const MIN_STARTUP_IMPACTS = 1;
export const MIN_STARTUP_INDUSTRIES = 1;
