import { ElementColor } from '../Utility/Color';

export enum FitScoreLabels {
  VeryLow = 'Very Low',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  VeryHigh = 'Very High',
}

export enum FitScoreReasonType {
  Good = 'Good',
  Agnostic = 'Agnostic',
  Bad = 'Bad',
}

export interface FitScoreReason {
  type: FitScoreReasonType;
  text: string;
}

export interface FitScore {
  score: number;
  label: FitScoreLabels;
  reasons: FitScoreReason[];
}

export function getFitLabelColor(fitLabel: FitScoreLabels): ElementColor {
  if (fitLabel === FitScoreLabels.VeryLow) {
    return { bgColor: 'bg-red-100', textColor: 'text-red-800' };
  }

  if (fitLabel === FitScoreLabels.Low) {
    return { bgColor: 'bg-orange-100', textColor: 'text-orange-800' };
  }

  if (fitLabel === FitScoreLabels.Medium) {
    return { bgColor: 'bg-yellow-100', textColor: 'text-yellow-800' };
  }

  if (fitLabel === FitScoreLabels.High) {
    return { bgColor: 'bg-teal-100', textColor: 'text-teal-800' };
  }

  return { bgColor: 'bg-green-100', textColor: 'text-green-800' };
}

export function getFitScoreColor(fitScore: FitScore): ElementColor {
  return getFitLabelColor(fitScore.label);
}
