export enum RoundTypes {
  ToBeDetermined = 'To Be Determined',
  PricedPreferred = 'Priced Round - Preferred',
  PricedCommon = 'Priced Round - Common',
  ConvertibleNote = 'Convertible Note',
  Safe = 'SAFE',
  CrowdSafe = 'Crowd SAFE',
  Asa = 'ASA',
}

export function getRoundTypeTooltip(roundType: string): string {
  switch (roundType) {
    case RoundTypes.ToBeDetermined:
      return 'Select this if you are not sure what round type you will raise';
    case RoundTypes.PricedPreferred:
      return 'A round in which the company issues and sells preferred equity at a specified price-per-share to investors';
    case RoundTypes.PricedCommon:
      return 'A round in which the company issues and sells common equity at a specified price-per-share to investors';
    case RoundTypes.ConvertibleNote:
      return 'A convertible debt instrument that can accrue interest prior to its conversion into equity';
    case RoundTypes.Safe:
      return 'A Simple Agreement for Future Equity, most commonly found in the USA';
    case RoundTypes.CrowdSafe:
      return 'A Simple Agreement for Future Equity used in equity crowdfunding, most commonly found in the USA';
    case RoundTypes.Asa:
      return 'An Advance Subscription Agreement, a SAFE-equivalent in the UK';
    default:
      return '';
  }
}
