import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import { PREPAYMENT_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/PrepaymentOptions';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.Prepayment, values) ? (
    <Field
      className="custom-select"
      closeMenuOnSelect
      isClearable
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      component={CustomSelect}
      label="Prepayment"
      name={RoundFieldNames.Prepayment}
      options={PREPAYMENT_OPTIONS}
      placeholder="Select..."
      tooltip="A “Prepayment” provision entitles the founder to pay back the convertible note before the conversion term with or without the approval of the holders of the note."
    />
  ) : (
    <></>
  );
}

const PrepaymentField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.Prepayment]: yup.string(),
  }),
};

export default PrepaymentField;
