import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import formatGroupLabel from '/src/components/inputs/ReactSelectAdditions/selectFormatGroupLabel';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { STATE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import { ENTITY_TYPE_UNINCORPORATED } from '/../libs/shared-types/src/constants/SelectOptions/EntityTypeOptions';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.LegalEntityType] &&
    values[StartupFieldNames.LegalEntityType] !== ENTITY_TYPE_UNINCORPORATED ? (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      groupLabelFormat={formatGroupLabel}
      isClearable
      label="State of Formation"
      name={StartupFieldNames.LegalFormationState}
      options={STATE_OPTIONS}
      placeholder="Select where your company was formed..."
    />
  ) : (
    <></>
  );
}

const LegalFormationStateField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.LegalFormationState]: yup
      .string()
      .when(StartupFieldNames.LegalEntityType, {
        is: (entityType: string) => entityType !== ENTITY_TYPE_UNINCORPORATED,
        then: (schema) => schema.required('Required'),
      }),
  }),
};

export default LegalFormationStateField;
