import API from '../middleware/API';
import Logger from './logger';
import {
  PublicDealShareMetadata,
  PublicFlowlinkMetadata,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import { LinkShareMetadata } from '/../libs/shared-types/src/types/view/LinkShareMetadata';

export async function getLinkShareMetadata(
  shareToken: string,
  linkType: LinkTypes
) {
  try {
    if (linkType === LinkTypes.Flowlink) {
      return API.get<LinkShareMetadata>(
        PublicFlowlinkMetadata.buildEndpoint(shareToken)
      );
    }

    if (linkType === LinkTypes.Dealshare) {
      return API.get<LinkShareMetadata>(
        PublicDealShareMetadata.buildEndpoint(shareToken)
      );
    }
  } catch (error) {
    Logger.error(error);
    return undefined;
  }
}
