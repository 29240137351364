import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import websiteSchema from '/src/constants/validation/websiteSchema';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import { ContactPreferences } from '/../libs/shared-types/src/constants/ContactPreferences';
import { useLocation } from 'react-router-dom';
import { COMPLETE_PROFILE_ROUTE } from '/src/constants/Routes';

function FieldWrapper(): JSX.Element {
  const { values, errors } = useFormikContext<any>();
  const { pathname } = useLocation();

  const isCompleteProfile = pathname.includes(COMPLETE_PROFILE_ROUTE);
  const hasError = errors[InvestorFieldNames.Twitter];

  return !isCompleteProfile ||
    hasError ||
    values[InvestorFieldNames.PreferenceContact].includes(
      ContactPreferences.Twitter
    ) ? (
    <Field
      component={FormikInput}
      label="Twitter URL"
      name={InvestorFieldNames.Twitter}
      type="text"
    />
  ) : (
    <></>
  );
}

const TwitterField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.Twitter]: websiteSchema(false),
  }),
};
export default TwitterField;
