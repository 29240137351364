import { SubscriptionTiers } from '../../constants/SubscriptionTiers';
import { AccountTypes } from '../../constants/AccountTypes';

export enum SubscriptionBillingInterval {
  Month = 'month',
  Year = 'year',
  Week = 'week',
  Day = 'day',
}

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
}

export interface Subscription {
  accountType: AccountTypes;
  createdOn: Date;
  credits: number;
  currentTier: SubscriptionTiers;
  flowlieAccountId: string;
  hasUsedTrial: boolean;
  stripeCustomerId: string;
  stripeProductId?: string;
  stripeSubscriptionBillingInterval?: SubscriptionBillingInterval;
  stripeSubscriptionBillingIntervalCount?: number;
  stripeSubscriptionCancelAt?: Date;
  stripeSubscriptionCurrentPeriodEnd?: Date;
  stripeSubscriptionId?: string;
  stripeSubscriptionStatus?: SubscriptionStatus;
  updatedOn: Date;
}
