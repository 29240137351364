import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import { FileType } from '/src/constants/FileUploadTypes';
import { validateFileSize, validateFileType } from '/src/util/FileHelpers';
import FormField from '/src/interfaces/FormField';
import ImageInput from '/src/components/inputs/ImageInput';

function FormContextWrapper(): JSX.Element {
  return (
    <Field
      component={ImageInput}
      label="Upload your company logo"
      name={StartupFieldNames.Logo}
    />
  );
}

const LogoField: FormField = {
  fieldComponent: <FormContextWrapper />,
  validation: yup.object({
    [StartupFieldNames.Logo]: yup
      .mixed()
      .when(StartupFieldNames.LogoMustValidate, {
        is: (hasChanged: boolean) => hasChanged === true,
        then: (schema) =>
          schema
            .required('Required')
            .test(
              'fileFormat',
              'Unsupported file format',
              (value: any) => value && validateFileType(value, FileType.Image),
            )
            .test(
              'fileSize',
              'Uploaded file is too big',
              (value: any) =>
                value && validateFileSize(value.size, FileType.Image),
            ),
      }),
  }),
};

export default LogoField;
