import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import { LONG_TEXTAREA_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import TextCharacterCounter from '../../TextCharacterCounter';
import { GenAiButtonWithModal } from '../../GenAiButton';
import { GenAiTransactionTypes } from '/../libs/shared-types/src/constants/GenAiTransactionTypes';

function FieldWithCharCount(): JSX.Element {
  const { values } = useFormikContext<any>();

  return (
    <>
      <div className="relative">
        <span className="absolute top-2 right-0">
          <GenAiButtonWithModal
            feedbackType={GenAiTransactionTypes.FeedbackIcp}
            valueToGetFeedbackOn={values[StartupFieldNames.Icp]}
            text={'Improve'}
            tooltipText={'Let Flowlie AI suggest improvements'}
            disabled={
              values[StartupFieldNames.Icp] === undefined ||
              values[StartupFieldNames.Icp].trim() === ''
            }
          />
        </span>
        <Field
          component={FormikInput}
          boxType="textarea"
          customStyle="h-28 max-h-28"
          label="What is your ideal customer profile and target buyer persona?"
          secondaryLabel="Investors want to understand who you are trying to sell your product or service to. Be specific."
          name={StartupFieldNames.Icp}
          tooltip="An ICP is a description of a company or person that you believe to be a perfect fit for the products or services you sell. Describe who they are in as much detail as you can. What are the problems they need help with?"
          type="text"
          placeholder="Example: “Flowlie helps early-stage founders without a network in VC close their fundraising rounds faster. We focus on founders who participated in an incubator or accelerator program outside of a major VC hub (like SF and NYC).”"
        />
        <TextCharacterCounter
          textLength={values[StartupFieldNames.Icp]?.length ?? 0}
          maxLength={LONG_TEXTAREA_LENGTH_MAX}
        />
      </div>
    </>
  );
}

const IcpField: FormField = {
  fieldComponent: <FieldWithCharCount />,
  validation: yup.object({
    [StartupFieldNames.Icp]: yup
      .string()
      .max(
        LONG_TEXTAREA_LENGTH_MAX,
        `Must be at most ${LONG_TEXTAREA_LENGTH_MAX} characters`
      ),
  }),
};

export default IcpField;
