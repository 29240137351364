import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { joinClassNames } from '../util/formatting/strings';
import { numberWithCommas } from '../util/formatting/numbers';

interface CustomNavLinkProps {
  activeClassName: string;
  children: string | JSX.Element | JSX.Element[];
  className: string;
  inactiveClassName: string;
  isFirstTab?: boolean;
  to: string;
  fullPath: string;
  stat?: number;
}

function CustomNavLink({
  to,
  className,
  activeClassName,
  inactiveClassName,
  isFirstTab = false,
  children,
  fullPath,
  stat,
}: CustomNavLinkProps): JSX.Element {
  const location = useLocation();

  // The first tab will be considered active if the url is shorter than the TO prop by 1 parameter
  // NOTE: this logic may be too simple to support more advanced navigation?
  const isActive = location.pathname.includes(fullPath)
    ? true
    : isFirstTab &&
      location.pathname?.split('/').length === fullPath?.split('/').length - 1;

  const allClassNames = joinClassNames(
    className,
    isActive ? activeClassName : inactiveClassName,
  );

  return (
    <NavLink
      className={({ isActive }) =>
        isActive ? joinClassNames(className, activeClassName) : allClassNames
      }
      to={to}
    >
      {children}
      {stat ? (
        <span
          className={joinClassNames(
            isActive
              ? 'bg-blue-100 text-blue-600'
              : 'bg-gray-200 text-gray-900',
            'ml-3 rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
          )}
        >
          {numberWithCommas(stat)}
        </span>
      ) : null}
    </NavLink>
  );
}

export default CustomNavLink;
