import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { isValidPhoneNumber } from 'react-phone-number-input';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import FormikPhoneInput from '../../inputs/FormikPhoneInput';

const PhoneField: FormField = {
  fieldComponent: (
    <Field
      component={FormikPhoneInput}
      label="Phone Number"
      secondaryLabel="Your phone number will be visible only to the other investors you connect with on Flowlie and will never be displayed to founders."
      name={InvestorFieldNames.Phone}
      placeholder="International format, ex +1 1231231234"
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.Phone]: yup
      .string()
      .test(
        'isValidNumber',
        'Invalid phone number length',
        (phone): boolean => {
          if (phone && !isValidPhoneNumber(phone?.toString())) {
            // Return false to indicate the test failed
            return false;
          }
          return true;
        }
      )
      .typeError('Enter a valid phone number, only digits'),
  }),
};

export default PhoneField;
