import React from 'react';
import * as yup from 'yup';
import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';

import FormField from '/src/interfaces/FormField';
import BooleanSelectInput from '../../inputs/BooleanSelectInput';

const HasRightsMfnField: FormField = {
  fieldComponent: (
    <BooleanSelectInput
      label="Do you have Most Favorable Nation Rights?"
      name={RecordInvestmentFieldNames.HasRightsMfn}
      tooltip="Most Favorable Nation Rights (known as MFN) are reflected in the purchase documents or the respective side letters and give you the same rights and benefits received by later investors if those rights and benefits are more favorable than those originally agreed upon."
    />
  ),
  validation: yup.object({
    [RecordInvestmentFieldNames.HasRightsMfn]: yup.bool().required('Required'),
  }),
};

export default HasRightsMfnField;
