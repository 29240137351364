import React from 'react';
import { components } from 'react-select';

// Disable onMouse events to increase performance
const OptimizedOption = ({ children, ...props }: any) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option {...newProps} className="optimized-option">
      {children}
    </components.Option>
  );
};

export default OptimizedOption;
