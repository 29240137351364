import React from 'react';
import * as yup from 'yup';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import { validateNumeric } from '/src/components/utility/Validators';
import FormField from '/src/interfaces/FormField';
import MoneyInput from '../../inputs/MoneyInput';

const NetMonthlyBurnField: FormField = {
  fieldComponent: (
    <MoneyInput
      name={StartupFieldNames.NetMonthlyBurn}
      label="Net Monthly Burn"
      tooltip="The amount of money a company loses each month as it spends on overhead and operating expenses, and invests in growth, subtracted by the revenue it generates."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.NetMonthlyBurn]: validateNumeric(
      'RECURRING_MONETARY_MAX'
    ),
  }),
};

export default NetMonthlyBurnField;
