import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import { ROLE_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import { capitalize, isBlank } from '/src/util/formatting/strings';

export function RoleInput({ label }: { label?: string }): JSX.Element {
  return (
    <Field
      component={FormikInput}
      label={label ?? 'Role'}
      name={InvestorFieldNames.Role}
      type="text"
    />
  );
}

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  const label = `Role ${
    values[InvestorFieldNames.Type] === InvestorTypes.Angel ? '(Optional)' : ''
  }`;
  return values[InvestorFieldNames.Type] && <RoleInput label={label} />;
}

const RoleField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.Role]: yup
      .string()
      .nullable()
      .max(ROLE_LENGTH_MAX, `Must be at most ${ROLE_LENGTH_MAX} characters`)
      .test({
        name: 'bothFirmAndRoleMustBeSet',
        exclusive: false,
        params: {},
        message: `You must add a ${capitalize(InvestorFieldNames.Firm)} too`,
        test(value) {
          if (value && isBlank(this.parent[InvestorFieldNames.Firm])) {
            return false;
          }
          return true;
        },
      })
      .when(InvestorFieldNames.Type, {
        is: (type: string) => type?.length > 0 && type !== InvestorTypes.Angel,
        then: (schema) => schema.required('Required'),
      }),
  }),
};

export default RoleField;
