export const ANTIDILUTION_RIGHTS_OPTIONS = [
  {
    value: 'Weighted Average (Narrow-based)',
    label: 'Weighted Average (Narrow-based)',
  },
  {
    value: 'Weighted Average (Broad-based)',
    label: 'Weighted Average (Broad-based)',
  },
  { value: 'Full Ratchet', label: 'Full Ratchet' },
  { value: 'Not Included', label: 'Not Included' },
];
