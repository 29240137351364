import React from 'react';
import { GoogleIcon } from './CustomIcons';

function GoogleAuth(): JSX.Element {
  const googleCognitoLink: string =
    process.env.REACT_APP_GOOGLE_COGNITO_SIGNIN || '';

  return (
    <a
      href={googleCognitoLink}
      className="flex w-full items-center justify-center space-x-3 rounded-md border bg-white px-3 py-2 text-gray-800 shadow hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
    >
      <GoogleIcon className="h-5 w-5" />
      <span className="text-sm font-semibold leading-6">
        Continue with Google
      </span>
    </a>
  );
}

export default GoogleAuth;
