import React from 'react';

export interface ButtonProps {
  children: any;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  btnType?: 'button' | 'submit';
  isDisabled?: boolean;
  className?: string;
}

function Button({
  children,
  className,
  onClick,
  btnType,
  isDisabled,
}: ButtonProps): JSX.Element {
  return (
    <button
      type={btnType === 'submit' ? 'submit' : 'button'}
      className={className ?? 'button'}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
}

export default Button;
