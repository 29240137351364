import { ShareTypes } from '/../libs/shared-types/src/constants/ShareTypes';

export default function sharedTypeMap(type: ShareTypes) {
  switch (type) {
    case ShareTypes.DealshareInApp:
      return 'In-App DealShare';
    case ShareTypes.DealshareLink:
      return 'DealShare Link';
    case ShareTypes.FlowlinkInvestor:
      return 'Your FlowLink';
    case ShareTypes.FlowlinkStartup:
      return 'Founder FlowLink';
    default:
      return 'Unknown';
  }
}
