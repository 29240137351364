import React from 'react';
import * as yup from 'yup';
import { Field, FieldArray, useFormikContext, FormikValues } from 'formik';
import FormField from '/src/interfaces/FormField';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormikInput from '/src/components/inputs/FormikInput';
import CustomSelect from '/src/components/inputs/CustomSelect';
import DebugRender from '/src/components/utility/DebugRender';
import { STARTUP_HIGHLIGHTS_LENGTH_MAX } from '/../libs/shared-types/src/constants/ObjectArrayLengthRanges';
import { HIGHLIGHT_TITLE_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import { Disclosure } from '@headlessui/react';
import { FormatOptionLabelMeta } from 'react-select';
import HighlightFieldNames from '/src/enums/HighlightFieldNames';
import {
  convertDatePickerInputToUtc,
  formatCurrentDate,
} from '/src/util/formatting/dates';
import { enumToTooltipSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import {
  HighlightType,
  getHighlightTypeTooltip,
} from '/../libs/shared-types/src/constants/HighlightType';
import { TooltipSelectOption } from '../../../types/SelectOptions';
import FormatOptionWithTooltipLabel from '../../inputs/ReactSelectAdditions/formatOptionWithTooltipLabel';
import TextCharacterCounter from '../../TextCharacterCounter';
import Logger from '/src/services/logger';
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid';

const validationSchema = yup.object().shape({
  [StartupFieldNames.Highlights]: yup
    .array()
    .of(
      yup.object().shape({
        // these inner constraints take precedence
        [HighlightFieldNames.AchievedOn]: yup
          .date()
          .test('greaterThanFoundedOn', function (achievedOn) {
            // Hacky way to access the foundedOn value from the parent of the parent
            let foundedOnString;
            let fromArray = [];
            if (this.options && Array.isArray((this.options as any).from)) {
              fromArray = (this.options as any).from;
            }

            if (
              fromArray.length > 1 &&
              fromArray[1] &&
              fromArray[1].value &&
              fromArray[1].value[StartupFieldNames.FoundedOn]
            ) {
              foundedOnString = fromArray[1].value[StartupFieldNames.FoundedOn];
            }

            if (!foundedOnString || isNaN(Date.parse(foundedOnString))) {
              // If the foundedOn date is not set, then we can't compare it
              // We should never get here
              Logger.error(
                'foundedOn date is not set in HighlightsFieldArray validation'
              );
              return true;
            }

            const foundedOn = convertDatePickerInputToUtc(
              new Date(foundedOnString)
            );
            if (achievedOn && achievedOn.getTime() < foundedOn.getTime()) {
              return this.createError({
                message: `Must be after the company foundation date: ${foundedOn.toLocaleDateString()}`,
                path: this.path,
              });
            }
            return true;
          })
          .max(new Date(), 'Future dates are not allowed')
          .required('Required'),
        [HighlightFieldNames.Title]: yup
          .string()
          .max(
            HIGHLIGHT_TITLE_LENGTH_MAX,
            `Must be at most ${HIGHLIGHT_TITLE_LENGTH_MAX} characters`
          )
          .required('Required'),

        [HighlightFieldNames.Type]: yup.string().required('Required'),
      })
    )
    .nullable()
    .max(
      STARTUP_HIGHLIGHTS_LENGTH_MAX,
      `At most ${STARTUP_HIGHLIGHTS_LENGTH_MAX} highlights are allowed`
    ),
});

const emptyHighlight = {
  [HighlightFieldNames.AchievedOn]: '',
  [HighlightFieldNames.Title]: '',
  [HighlightFieldNames.Type]: '',
};

function HighlightsFields(): JSX.Element {
  const { values, errors, touched } = useFormikContext<any>();

  return (
    <div>
      <FieldArray name={StartupFieldNames.Highlights}>
        {({ remove, push }) => (
          <div>
            {values[StartupFieldNames.Highlights].length > 0 &&
              values[StartupFieldNames.Highlights].map(
                (highlight: FormikValues, index: number) => (
                  <section
                    className="my-4 rounded-lg border bg-slate-50 p-4"
                    key={values[StartupFieldNames.Highlights].indexOf(
                      highlight
                    )}
                  >
                    <div className="flex flex-row space-x-4">
                      <Field
                        className="custom-select"
                        closeMenuOnSelect
                        component={CustomSelect}
                        formatOptionLabel={(
                          option: TooltipSelectOption,
                          formatOptionLabelMeta: FormatOptionLabelMeta<any>
                        ) => (
                          <FormatOptionWithTooltipLabel
                            option={option}
                            formatOptionLabelMeta={formatOptionLabelMeta}
                          />
                        )}
                        label="Category"
                        secondaryLabel="What kind of highlight is it?"
                        name={`${StartupFieldNames.Highlights}[${index}].${HighlightFieldNames.Type}`}
                        options={enumToTooltipSelectOptions(
                          HighlightType,
                          getHighlightTypeTooltip
                        )}
                        placeholder="Select category..."
                      />

                      <Field
                        component={FormikInput}
                        label="When did you achieve it?"
                        secondaryLabel="An estimate date is ok."
                        max={formatCurrentDate()}
                        name={`${StartupFieldNames.Highlights}[${index}].${HighlightFieldNames.AchievedOn}`}
                        placeholder="mm/dd/yyyy"
                        type="date"
                      />
                    </div>

                    <>
                      <Field
                        component={FormikInput}
                        label="Title"
                        secondaryLabel="Describe the highlight in a very short sentence, like a bullet point"
                        name={`${StartupFieldNames.Highlights}[${index}].${HighlightFieldNames.Title}`}
                        type="text"
                      />
                      <TextCharacterCounter
                        textLength={
                          values[StartupFieldNames.Highlights][index][
                            HighlightFieldNames.Title
                          ]?.length ?? 0
                        }
                        maxLength={HIGHLIGHT_TITLE_LENGTH_MAX}
                      />
                    </>
                    <button
                      type="button"
                      className="app-button--neutral mt-4 flex items-center space-x-2"
                      onClick={() => remove(index)}
                    >
                      <TrashIcon className="h-4 w-4" />
                      <span className="pt-0.5">Remove</span>
                    </button>
                  </section>
                )
              )}
            {
              // Display error for outer validation
              typeof errors[StartupFieldNames.Highlights] === 'string' && (
                <p className="app-error-message">
                  {errors[StartupFieldNames.Highlights]?.toString()}
                </p>
              )
            }

            <button
              type="button"
              className="button mt-8"
              disabled={
                values[StartupFieldNames.Highlights].length ===
                STARTUP_HIGHLIGHTS_LENGTH_MAX
              }
              onClick={() => push(emptyHighlight)}
            >
              <PlusIcon className="mr-1.5 inline-flex h-5 w-5" />
              Add New Highlight
            </button>
          </div>
        )}
      </FieldArray>

      <DebugRender className="my-4 flex flex-col space-y-2 text-xs">
        <Disclosure>
          <Disclosure.Button className="app-button--neutral">
            <pre>Highlights Values:</pre>
          </Disclosure.Button>
          <Disclosure.Panel>
            <pre>
              {JSON.stringify(values[StartupFieldNames.Highlights], null, 2)}
            </pre>
          </Disclosure.Panel>
        </Disclosure>

        <Disclosure>
          <Disclosure.Button className="app-button--neutral">
            <pre>Highlights Errors:</pre>
          </Disclosure.Button>
          <Disclosure.Panel>
            <pre>
              {JSON.stringify(errors[StartupFieldNames.Highlights], null, 2)}
            </pre>
          </Disclosure.Panel>
        </Disclosure>

        <Disclosure>
          <Disclosure.Button className="app-button--neutral">
            <pre>Highlights Touched:</pre>
          </Disclosure.Button>
          <Disclosure.Panel>
            <pre>
              {JSON.stringify(touched[StartupFieldNames.Highlights], null, 2)}
            </pre>
          </Disclosure.Panel>
        </Disclosure>
      </DebugRender>
    </div>
  );
}

const HighlightsFieldArray: FormField = {
  fieldComponent: <HighlightsFields />,
  validation: validationSchema,
};

export default HighlightsFieldArray;
