import React from 'react';
import { Field, useFormikContext } from 'formik';
import CustomNumericInput from './CustomNumericInput';

export default function YearsInput(props: any): JSX.Element {
  const { values } = useFormikContext<any>();

  return (
    <Field
      className="input"
      component={CustomNumericInput}
      step={1}
      maxLength={2}
      suffix={values[props.name] === '1' ? ' year' : ' years'}
      {...props}
    />
  );
}
