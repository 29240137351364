import React from 'react';
import { Field } from 'formik';
import CustomSelect from './CustomSelect';
import { PRIORITY_OPTIONS } from '/src/routes/investor/DealDetail/PrioritySelect';
import { SelectOption } from '../../types/SelectOptions';
import { Priority } from '/../libs/shared-types/src/constants/Priority';

export default function PrioritySelectInput(props: any): JSX.Element {
  return (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      formatOptionLabel={({ label, value }: SelectOption) => {
        return (
          <span className="flex flex-row space-x-2 items-center truncate text-sm font-normal">
            {
              PRIORITY_OPTIONS.find(
                (x) => x.priority === (value as unknown as Priority),
              )?.icon
            }
            <span>{label}</span>
          </span>
        );
      }}
      options={PRIORITY_OPTIONS.map((option) => ({
        value: option.priority,
        label: option.label,
      }))}
      placeholder="Add priority..."
      {...props}
    />
  );
}
