import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

import { addHeapUserProperty } from '/src/middleware/Heap';
import { MEDIUM_TEXTAREA_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import API from '/src/middleware/API';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormikInput from '/src/components/inputs/FormikInput';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import RATING_OPTIONS from '/../libs/shared-types/src/constants/SelectOptions/RatingOptions';
import ZendeskSupportRequestTypes from '/../libs/shared-types/src/constants/ZendeskSupportRequestTypes';
import TextCharacterCounter from '/src/components/TextCharacterCounter';
import { Posthog } from '/src/middleware/Posthog';
import { SharedZendeskNewSupportRequest } from '/../libs/shared-types/src/constants/ApiRoutes';

interface FeedbackFormProps {
  onSubmit: () => void;
}

function FeedbackForm({ onSubmit }: FeedbackFormProps): JSX.Element {
  const initialValues = {
    rating: '',
    comment: '',
  };
  const validationSchema = yup.object({
    rating: yup.number().required('Required'),
    comment: yup
      .string()
      .max(
        MEDIUM_TEXTAREA_LENGTH_MAX,
        `Must be at most ${MEDIUM_TEXTAREA_LENGTH_MAX} characters`
      ),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);

        addHeapUserProperty({ activationRating: values.rating });
        const posthogClient = new Posthog();
        posthogClient.addUserProperties({
          user_activation_score: parseInt(values.rating, 10),
        });

        if (values.comment.length > 0) {
          // Post to Zendesk to create a support request
          await API.post(SharedZendeskNewSupportRequest.buildEndpoint(), {
            comment: values.comment,
            subject: 'New User First Login Activation',
            type: ZendeskSupportRequestTypes.Question,
          });
        }

        setSubmitting(false);
        onSubmit();
      }}
    >
      {({ values, isValid, isSubmitting }) => (
        <Form>
          <Field
            className="custom-select"
            closeMenuOnSelect
            component={CustomSelect}
            label="Do you understand how to get started on Flowlie?"
            name="rating"
            options={RATING_OPTIONS}
            placeholder="Select option..."
          />
          {/* <>
            <Field
              component={FormikInput}
              boxType="textarea"
              customStyle="h-28 max-h-28"
              label="Do you still have any questions? (Optional)"
              name="comment"
              type="text"
            />
            <TextCharacterCounter
              textLength={values.comment?.length ?? 0}
              maxLength={MEDIUM_TEXTAREA_LENGTH_MAX}
            />
          </> */}

          <div className="mt-4 flex justify-end">
            <button
              id="newUserActivation-SubmitButton-Feedback"
              type="submit"
              className="app-button--primary"
              disabled={!isValid || isSubmitting}
            >
              Submit
              {isSubmitting && (
                <div className="ml-2">
                  <LoadingSpinner color="white" />
                </div>
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default FeedbackForm;
