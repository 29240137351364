/* eslint-disable no-param-reassign */
import React, { Fragment, useState } from 'react';
import {
  Formik,
  Form,
  FormikHelpers,
  useFormikContext,
  FormikValues,
} from 'formik';
import confetto from 'confetto';

import { DealSummaryView } from '/../libs/shared-types/src/types/view/AggregatedDeals';
import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import { Investment } from '/../libs/shared-types/src/types/model/Deal';
import API from '/src/middleware/API';
import emptyRecordInvestment from '/src/components/RecordInvestmentForm/EmptyRecordInvestment';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Logger from '/src/services/logger';
import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';
import steps from '/src/components/RecordInvestmentForm/RecordInvestmentForm';
import WarnBeforeExit from '/src/components/utility/WarnBeforeExit';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import { numberWithCommas } from '/src/util/formatting/numbers';
import { generateTag, generateTagCloud } from '/src/util/generateTagCloud';
import { getInvestmentRights } from '/src/util/investment';
import {
  InvestorEmailInvestmentUpdates,
  InvestorUpdateDealStatus,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { isIncludedString } from '/src/util/rounds';

interface RoundReviewCardProps {
  round: RoundView;
  investment: Investment;
}

function RoundReviewCard({
  round,
  investment,
}: RoundReviewCardProps): JSX.Element {
  return (
    <div className="pt-5">
      <section className="relative rounded-xl border border-gray-200 py-4 px-6">
        <header className="absolute -top-4 left-2">
          {generateTag(round.roundDisplayName)}
        </header>
        <dl className="mx-1 mt-2 grid grid-cols-2 gap-x-4 gap-y-5">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Type of Round</dt>
            <dd className="mt-1 text-sm font-medium text-gray-900">
              {round.roundType}&nbsp;{round.safeType}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Your Investment
            </dt>
            <dd className="mt-1 text-sm font-medium text-gray-900">
              $&nbsp;
              {numberWithCommas(investment.amount)}
              {investment.isLead && (
                <span className="ml-2 inline-flex items-center rounded-full bg-teal-100 px-2.5 py-0.5 text-xs font-medium text-teal-800">
                  Lead Investor
                </span>
              )}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Raise Target</dt>
            <dd className="mt-1 text-sm font-medium text-gray-900">
              $&nbsp;
              {numberWithCommas(round.raiseTarget)}
            </dd>
          </div>

          {round.valuationCap && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {round.valuationCapType} Valuation Cap
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                $&nbsp;
                {numberWithCommas(round.valuationCap)}
              </dd>
            </div>
          )}

          <div className="sm:col-span-1">
            <dt
              className="text-sm font-medium text-gray-500"
              title="Amount of carry charged to you"
            >
              Carry Charged
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {investment.carryCharged}%
            </dd>
          </div>

          {round.pricePerShare !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Price Per Share
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                $&nbsp;
                {numberWithCommas(round.pricePerShare, 4)}
              </dd>
            </div>
          )}

          {round.discount !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Discount Rate
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {round.discount}
                &nbsp;%
              </dd>
            </div>
          )}

          {round.hasRightsMfn !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Most Favorable Nation Rights
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {isIncludedString(round.hasRightsMfn)}
              </dd>
            </div>
          )}

          {round.interest !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Interest Rate
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {round.interest}
                &nbsp;%
              </dd>
            </div>
          )}

          {round.conversionTerm && round.conversionTerm >= 1 && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Conversion Term
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {round.conversionTerm}
                &nbsp;
                {round.conversionTerm > 1 && 'Months'}
                {round.conversionTerm === 1 && 'Month'}
              </dd>
            </div>
          )}

          {round.prepayment !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Prepayment</dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                $&nbsp;
                {round.prepayment}
              </dd>
            </div>
          )}

          {round.liquidationPreference !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Liquidation Preference
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {round.liquidationPreference}x&nbsp;
                {round.liquidationPreference > 0 &&
                  round.liquidationParticipation !== undefined &&
                  round.liquidationParticipation}
              </dd>
            </div>
          )}

          {round.hasRightsPayToPlay !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Pay-To-Play Rights
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {isIncludedString(round.hasRightsPayToPlay)}
              </dd>
            </div>
          )}

          {round.antidilutionRights !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Anti-dilution Rights
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {round.antidilutionRights}
              </dd>
            </div>
          )}

          {round.hasRightsDragAlong !== undefined && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Drag-Along Rights
              </dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">
                {isIncludedString(round.hasRightsDragAlong)}
              </dd>
            </div>
          )}

          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Your Rights</dt>
            <dd className="mt-1 text-sm font-medium text-gray-900">
              {generateTagCloud(getInvestmentRights(investment))}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Your Board Participation
            </dt>
            <dd className="mt-1 text-sm font-medium text-gray-900">
              {investment.boardParticipation}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Your Investor Updates
            </dt>
            <dd className="mt-1 text-sm font-medium text-gray-900">
              {investment.investorUpdatesFrequency}
            </dd>
          </div>
        </dl>
      </section>
    </div>
  );
}

interface FormStepButtonsProps {
  stepIndex: number;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  onClose: () => void;
  isLastStep: () => boolean;
  currentStepValidationSchema: any;
}
function FormStepButtons({
  stepIndex,
  setStepIndex,
  onClose,
  isLastStep,
  currentStepValidationSchema,
}: FormStepButtonsProps): JSX.Element {
  const { isValid, dirty, isSubmitting, values, validateForm } =
    useFormikContext<any>();

  React.useEffect(() => {
    // The validation schema has changed, so validate the form to refresh the state of the buttons
    validateForm();
  }, [currentStepValidationSchema]);

  return (
    <div className="mt-2 flex flex-row-reverse">
      <button
        className="app-button--primary"
        disabled={!isValid || !dirty || isSubmitting}
        type="submit"
      >
        {(stepIndex === 2 &&
          values[RecordInvestmentFieldNames.IsRoundInformationCorrect]) ||
        isLastStep()
          ? 'Confirm'
          : 'Next'}
        {isSubmitting && (
          <div className="mr-2 w-2">
            <LoadingSpinner color="white" />
          </div>
        )}
      </button>
      <button
        className="app-button--neutral mr-2"
        disabled={isSubmitting}
        onClick={
          stepIndex > 0
            ? () => {
                setStepIndex((s) => s - 1);
              }
            : onClose
        }
        type="button"
      >
        {stepIndex > 0 ? 'Back' : 'Cancel'}
      </button>
    </div>
  );
}

interface RecordInvestmentDialogProps {
  onClose: () => void;
  onSuccess: () => void;
  deal: DealSummaryView;
}

function RecordInvestmentDialog({
  onClose,
  onSuccess,
  deal,
}: RecordInvestmentDialogProps): JSX.Element {
  const [isCompleted, setIsCompleted] = useState(false);

  // Set initial form state
  const [stepIndex, setStepIndex] = useState(0);
  const [currentStepValidationSchema, setCurrentStepValidationSchema] =
    useState(
      steps[0].fields
        .map((field) => field.validation)
        .reduce((merged, schema) => merged.concat(schema))
    );

  function isLastStep() {
    return stepIndex === steps.length - 1;
  }

  function buildInvestment(values: FormikValues): Investment {
    const investment: Investment = {
      // NOTE: Numbers are being parsed just so that
      // they can be rendered in the RoundReviewCard
      amount: parseInt(values[RecordInvestmentFieldNames.Amount], 10),
      boardParticipation: values[RecordInvestmentFieldNames.BoardParticipation],
      carryCharged: parseFloat(values[RecordInvestmentFieldNames.CarryCharged]),
      hasRightsInformation:
        values[RecordInvestmentFieldNames.HasRightsInformation],
      hasRightsMfn: values[RecordInvestmentFieldNames.HasRightsMfn],
      hasRightsProRata: values[RecordInvestmentFieldNames.HasRightsProRata],
      investorUpdatesFrequency:
        values[RecordInvestmentFieldNames.InvestorUpdatesFrequency],
      isLead: values[RecordInvestmentFieldNames.IsLead],
      reasons: values[RecordInvestmentFieldNames.Reasons],
    };

    return investment;
  }

  const recordInvestment = async (values: FormikValues) => {
    delete values.isRoundInformationCorrect;
    delete values.incorrectRoundFeedback;

    const investment: Investment = buildInvestment(values);

    return API.put(InvestorUpdateDealStatus.buildEndpoint(), {
      startupId: deal.startupId,
      status: DealStatusTypes.Portfolio,
      investment,
      dealRating: values[RecordInvestmentFieldNames.DealRating],
    });
  };

  async function onSubmit(
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ): Promise<void> {
    setSubmitting(true);
    const message = values[RecordInvestmentFieldNames.IncorrectRoundFeedback];
    if (
      !values[RecordInvestmentFieldNames.IsRoundInformationCorrect] &&
      message
    ) {
      API.post(InvestorEmailInvestmentUpdates.buildEndpoint(), {
        startupId: deal.startupId,
        message,
      });
    }
    await recordInvestment(values)
      .then(() => {
        setIsCompleted(true);
      })
      .catch((error: any) => {
        onClose();
        Logger.error(error);
      });

    setSubmitting(false);
  }

  React.useEffect(() => {
    if (isCompleted) {
      confetto();
    }
  }, [isCompleted]);

  React.useEffect(() => {
    // Set the validation schema for the current step
    setCurrentStepValidationSchema(
      steps[stepIndex].fields
        .map((field) => field.validation)
        .reduce((merged, schema) => merged.concat(schema))
    );
  }, [stepIndex]);

  return (
    <main className="bg-white p-4 sm:w-screen sm:max-w-3xl sm:p-7">
      {isCompleted && (
        <div className="mt-3 w-full text-center sm:mt-0">
          <h3 className="mb-6 text-lg font-medium leading-6 text-gray-900">
            Hurray! The investment has been recorded succesfully!
          </h3>
          <p className="my-6 text-gray-800">
            You can review the details in your Portfolio.
          </p>
          <button
            className="app-button--primary"
            onClick={() => {
              onSuccess();
              onClose();
            }}
            type="button"
          >
            OK
          </button>
        </div>
      )}

      {!isCompleted && (
        <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
          <h3
            className="mb-6 text-lg font-medium leading-6 text-gray-900"
            id="modal-headline"
          >
            Record Investment for {deal.startupName}
          </h3>
          <Formik
            initialValues={{
              ...emptyRecordInvestment,
              [RecordInvestmentFieldNames.DealRating]: deal.rating.rating,
            }}
            validationSchema={currentStepValidationSchema}
            onSubmit={async (values, helpers) => {
              if (
                isLastStep() ||
                (stepIndex === 2 &&
                  values[RecordInvestmentFieldNames.IsRoundInformationCorrect])
              ) {
                await onSubmit(values, helpers);
                setIsCompleted(true);
              } else {
                setStepIndex((s) => s + 1);
                Object.keys(values).forEach((fieldName) =>
                  helpers.setFieldTouched(fieldName, false)
                );
              }
            }}
          >
            {({ values, dirty }) => (
              <Form>
                <WarnBeforeExit isEnabled={dirty} />
                {stepIndex === 0 && (
                  <>
                    {steps[stepIndex].fields.map((field, index) => (
                      <Fragment key={index}>{field.fieldComponent}</Fragment>
                    ))}
                  </>
                )}

                {stepIndex === 1 && (
                  <>
                    {steps[stepIndex].fields.map((field, index) => (
                      <Fragment key={index}>{field.fieldComponent}</Fragment>
                    ))}
                  </>
                )}

                {stepIndex === 2 && deal.currentRound && (
                  <section className="space-y-4">
                    <p>{steps[stepIndex].label}</p>
                    <RoundReviewCard
                      round={deal.currentRound}
                      investment={buildInvestment(values)}
                    />

                    {steps[stepIndex].fields.map(
                      (field) => field.fieldComponent
                    )}
                  </section>
                )}

                {stepIndex === 3 &&
                  !values[
                    RecordInvestmentFieldNames.IsRoundInformationCorrect
                  ] && (
                    <>
                      {steps[stepIndex].fields.map(
                        (field) => field.fieldComponent
                      )}
                    </>
                  )}

                <FormStepButtons
                  stepIndex={stepIndex}
                  setStepIndex={setStepIndex}
                  onClose={onClose}
                  isLastStep={isLastStep}
                  currentStepValidationSchema={currentStepValidationSchema}
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
    </main>
  );
}

export default RecordInvestmentDialog;
