import React, { useState } from 'react';
import { Testimonial } from './TestimonialsData';
import TestimonialCard from './TestimonialCard';
import { joinClassNames } from '/src/util/formatting/strings';
import useInterval from '/src/hooks/useInterval';

interface TestimonialCarouselProps {
  testimonials: Testimonial[];
}

function TestimonialCarousel({
  testimonials,
}: TestimonialCarouselProps): JSX.Element {
  const [currentIndex, setCurrentIndex] = useState(0);

  useInterval(
    () =>
      setCurrentIndex(
        (currentIndex) => (currentIndex + 1) % testimonials.length
      ),
    12_000
  );

  return (
    <div className="relative">
      <TestimonialCard testimonial={testimonials[currentIndex]} />
      <div className="absolute inset-x-0 bottom-3 flex items-center justify-center">
        {testimonials.map((testimonial, index) => (
          <button
            key={testimonial.author.name}
            type="button"
            onClick={() => setCurrentIndex(index)}
            className={joinClassNames(
              currentIndex === index ? 'bg-gray-400' : 'bg-gray-300',
              'mx-2 h-2 w-2 rounded-full hover:bg-gray-400'
            )}
          />
        ))}
      </div>
    </div>
  );
}

export default TestimonialCarousel;
