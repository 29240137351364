import React from 'react';
import * as yup from 'yup';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import {
  BOOKINGS_MAX,
  BOOKINGS_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import MoneyInput from '../../inputs/MoneyInput';

function FieldWrapper(): JSX.Element {
  return (
    <MoneyInput
      label="Bookings & Signed Contracts"
      name={StartupFieldNames.BookingsAmount}
    />
  );
}

const BookingsAmountField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.BookingsAmount]: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .min(BOOKINGS_MIN, `Must be greater than $ ${BOOKINGS_MIN}`)
      .max(BOOKINGS_MAX, `Cannot be greater than $ ${BOOKINGS_MAX}`),
  }),
};

export default BookingsAmountField;
