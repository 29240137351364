import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import { SelectOption } from '/../libs/shared-types/src/types/SelectOptions';
import {
  BRIDGE_NUMBER_MAX,
  BRIDGE_NUMBER_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import { getLatestClosedRound } from '/src/util/rounds';

function getBridgeNumberOptions(latestPrimaryStage: string): SelectOption[] {
  const options: SelectOption[] = [];
  const stageNameLabel = latestPrimaryStage.startsWith('Series')
    ? latestPrimaryStage
    : latestPrimaryStage + ' ';
  for (let i = BRIDGE_NUMBER_MIN; i <= BRIDGE_NUMBER_MAX; i += 1) {
    options.push({
      value: i.toString(),
      label: `${stageNameLabel}${i} Bridge`,
    });
  }
  return options;
}

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  const options = getBridgeNumberOptions(values[RoundFieldNames.RoundStage]);
  const roundHistory: RoundView[] = values[RoundFieldNames.RoundHistory];
  const latestClosedRound = getLatestClosedRound(roundHistory);

  let latestClosedBridgeRoundNumber = undefined;
  if (
    latestClosedRound &&
    latestClosedRound.isBridge &&
    latestClosedRound.bridgeNumber &&
    latestClosedRound.roundStage === values[RoundFieldNames.RoundStage]
  ) {
    latestClosedBridgeRoundNumber = latestClosedRound.bridgeNumber;
  }

  return isInputShown(RoundFieldNames.BridgeNumber, values) ? (
    <Field
      className="custom-select"
      closeMenuOnSelect
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      component={CustomSelect}
      label="Which Bridge or Extension are you raising?"
      tooltip={`${
        options[0].label
      } means you are raising your first bridge round at ${
        values[RoundFieldNames.RoundStage]
      }. If you have already raised a bridge round, select the next number.`}
      name={RoundFieldNames.BridgeNumber}
      options={options}
      disabledOptions={
        latestClosedBridgeRoundNumber
          ? options.slice(0, latestClosedBridgeRoundNumber).map((x) => x.value)
          : undefined
      }
    />
  ) : (
    <></>
  );
}

const BridgeNumberField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.BridgeNumber]: yup
      .number()
      .nullable()
      .min(BRIDGE_NUMBER_MIN)
      .max(BRIDGE_NUMBER_MAX)
      .when(RoundFieldNames.IsBridge, {
        is: (isBridge: boolean) => isBridge === true,
        then: (schema) =>
          schema
            .test(
              'bridgeNumberStageAlreadyClosed',
              'You have already raised this bridge round at this stage',
              (
                selectedBridgeNumber,
                context: yup.TestContext<yup.AnyObject>,
              ): boolean => {
                const roundHistory =
                  context.parent[RoundFieldNames.RoundHistory];
                const latestClosedRound = getLatestClosedRound(roundHistory);
                const selectedRoundSage =
                  context.parent[RoundFieldNames.RoundStage];
                if (
                  roundHistory &&
                  latestClosedRound &&
                  latestClosedRound.roundStage === selectedRoundSage &&
                  latestClosedRound.bridgeNumber &&
                  selectedBridgeNumber &&
                  Number(selectedBridgeNumber) <= latestClosedRound.bridgeNumber
                ) {
                  // Return false to indicate the test failed
                  return false;
                }
                return true;
              },
            )
            .required('Required'),
      }),
  }),
};

export default BridgeNumberField;
