import React, { useEffect, useState } from 'react';
import ConnectionRequestsTable from './ConnectionRequestsTable';
import { ConnectionRequestAccountData } from '/../libs/shared-types/src/types/view/APIResponse';
import { ComparableConnectionRequestAccountData } from '/../libs/shared-types/src/types/view/ComparableInvestorConnectionView';
import Alert from '/src/components/notifications/Alert';
import { Sortable, SortOrder } from '/src/interfaces/Sortable';
import { fetchSentConnectionRequests } from '/src/services/InvestorRequests';
import Logger from '/src/services/logger';
import { buildComparableConnectionRequestAccountData } from '/src/util/TableSorting';

function ConnectionManagementRoute({
  setModalOpen,
}: {
  setModalOpen: (value: boolean) => void;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [sentConnectionRequests, setSentConnectionRequests] = useState<
    ConnectionRequestAccountData[]
  >([]);
  const [sortableConnections, setSortableConnections] =
    useState<
      Sortable<
        ConnectionRequestAccountData,
        ComparableConnectionRequestAccountData
      >
    >();

  async function fetchContactRequests() {
    try {
      setIsLoading(true);
      const sentConnections = await fetchSentConnectionRequests();
      const sortableItems = new Sortable<
        ConnectionRequestAccountData,
        ComparableConnectionRequestAccountData
      >(sentConnections, buildComparableConnectionRequestAccountData);
      setSentConnectionRequests(
        sentConnections.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
      );
      setSortableConnections(sortableItems);
      setIsLoading(false);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSort = (sortKey: string, sortOrder: SortOrder) => {
    const sorted = sortableConnections?.sort(
      sortKey as keyof ComparableConnectionRequestAccountData,
      sortOrder
    );
    setSentConnectionRequests(sorted ? [...sorted] : []);
  };

  useEffect(() => {
    fetchContactRequests();
  }, []);

  return (
    <div>
      {(sentConnectionRequests ?? []).length >= 0 && !isLoading && (
        <div className="mb-8">
          <h2 className="mt-3 mb-1 text-xl font-extrabold tracking-tight text-gray-900 dark:text-white lg:my-4">
            Requests Sent
          </h2>
          {sentConnectionRequests.length === 0 && (
            <div className="my-4">
              <Alert
                color="blue"
                alertType="Info"
                content={
                  <p>
                    Hmm... it looks like you have no pending requests sent.{' '}
                    <button
                      type="button"
                      onClick={() => setModalOpen(true)}
                      className="hyperlink font-bold underline"
                    >
                      Invite contacts
                    </button>{' '}
                    from your investor network.
                  </p>
                }
                isShown={true}
                canDismiss={false}
              />
            </div>
          )}

          {sentConnectionRequests.length > 0 && (
            <ConnectionRequestsTable
              onSort={handleSort}
              onRefresh={fetchContactRequests}
              contacts={sentConnectionRequests}
              isOutgoing={true}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ConnectionManagementRoute;
