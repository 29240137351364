import React, { useEffect, useState, useContext, Fragment } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';

import {
  Bars3Icon,
  XMarkIcon,
  QuestionMarkCircleIcon,
  BoltIcon,
  BanknotesIcon,
  AcademicCapIcon,
} from '@heroicons/react/24/outline';
import { buildLinkFromToken } from '/src/util/flowlink';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import AuthService from '/src/services/AuthService';
import Logger from '/src/services/logger';
import flowlieLogo from '/src/res/flowlie_f.svg';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import InvestorActivationTasks from './InvestorActivationTasks';
import { joinClassNames } from '/src/util/formatting/strings';
import {
  InvestorRequestsContext,
  NOTIFICATIONS_ACCESS_REQUESTS,
  NOTIFICATIONS_INVESTOR_CONTACTS,
} from '/src/contexts/InvestorRequestsContext';
import {
  fetchConnectionRequests,
  fetchReceivedDealShareRequests,
} from '/src/services/InvestorRequests';
import { Task } from '/../libs/shared-types/src/types/model/Task';
import { ActivationTaskView } from '/../libs/shared-types/src/types/view/ActivationTaskView';
import { BaseUrls } from '/../libs/shared-types/src/constants/BaseUrls';
import API from '/src/middleware/API';
import { taskToView } from '/src/constants/ActivationTaskMaps/ActivationTasksMap';
import { localStorageGetAccountEmail } from '/src/middleware/LocalStorage';
import AccountDropDownMenu from './AccountDropDownMenu';
import {
  BILLING_ROUTE,
  REFERRALS_ROUTE,
  STARTUP_FUNDRAISING_WORKSHOP,
  START_ACTIVATION_TOUR_ROUTE,
} from '/src/constants/Routes';
import StartupNavigationLinks from './StartupNavigationLinks';
import InvestorNavigationLinks from './InvestorNavigationLinks';
import { SubscriptionTierPill } from '../../SubscriptionTierPill';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { isPaidTier } from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { getFeedbackFormUrl } from '/src/util/urls';
import {
  InvestorUserActivationTasks,
  StartupGetUserActivationTasks,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import StartupActivationTasks from './StartupActivationTasks';
import { ZendeskClient } from '/src/middleware/Zendesk';

interface HelpPopOverProps {
  accountType: AccountTypes;
}

export function HelpPopOver({ accountType }: HelpPopOverProps) {
  const zendeskWidgetId = 'showWidget';
  const repeatActivationTourId = 'repeatActivationTour';
  const navigate = useNavigate();

  const helpLinks = [
    {
      name: 'Give me a tour',
      id: repeatActivationTourId,
      onClick: () => navigate(START_ACTIVATION_TOUR_ROUTE),
    },
    {
      name: "What's new?",
      href: `${BaseUrls.PRODUCTION_SLEEKPLAN}/changelog/`,
    },
    { name: 'Suggest a feature', href: BaseUrls.PRODUCTION_SLEEKPLAN },
    { name: 'Give feedback', href: getFeedbackFormUrl(accountType) },
    {
      name: 'Support chat',
      href: '#',
      id: zendeskWidgetId,
      onClick: () => {
        const client = new ZendeskClient();
        client.activate();
      },
    },
  ];
  return (
    <Popover className="relative">
      <Popover.Button className="inline-flex w-full items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        <span className="flex w-full items-center rounded-md px-2 py-2 font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
          <QuestionMarkCircleIcon className="app-nav-icon" />
          Help
        </span>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-2 flex w-screen max-w-min -translate-x-1/2 px-2">
          <div className="w-56 shrink rounded-md bg-white p-3 shadow-lg ring-1 ring-gray-900/5">
            {helpLinks.map((item) =>
              item.id === zendeskWidgetId ? (
                <button
                  id={item.id}
                  onClick={item.onClick}
                  key={item.name}
                  className="block w-full p-2 text-left text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  {item.name}
                </button>
              ) : item.id === repeatActivationTourId ? (
                <button
                  id={item.id}
                  onClick={item.onClick}
                  key={item.name}
                  className="block w-full p-2 text-left text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  {item.name}
                </button>
              ) : (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block p-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  {item.name}
                </a>
              ),
            )}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

function Navigation({ children }: any): JSX.Element {
  const [showMenu, setShowMenu] = useState(false);
  const openNav = () => setShowMenu(true);
  const closeNav = () => setShowMenu(false);
  const location = useLocation();

  const [tasks, setTasks] = React.useState<ActivationTaskView[]>([]);

  const { accountType, linkShareToken, subscriptionTier, hasUsedTrial } =
    useContext(AccountMetadataContext);

  const flowlink = buildLinkFromToken(linkShareToken ?? '');

  const {
    connectionRequests,
    setConnectionRequests,
    accessRequests,
    setAccessRequests,
  } = useContext(InvestorRequestsContext);

  const notificationsMap = new Map<string, number>();

  if (connectionRequests) {
    notificationsMap.set(
      NOTIFICATIONS_INVESTOR_CONTACTS,
      connectionRequests.length,
    );
  }

  if (accessRequests) {
    notificationsMap.set(NOTIFICATIONS_ACCESS_REQUESTS, accessRequests.length);
  }

  function handleResize() {
    if (window.innerWidth > 767) {
      setShowMenu(false);
    }
  }

  async function fetchAndSetInvestorRequests() {
    if (
      !AuthService.isLoggedIn() ||
      accountType !== AccountTypes.Investor ||
      connectionRequests !== undefined ||
      accessRequests !== undefined
    ) {
      return;
    }
    const dealshareRequests = await fetchReceivedDealShareRequests();
    const fetchedConnectionRequests = await fetchConnectionRequests();
    setAccessRequests(dealshareRequests);
    setConnectionRequests(fetchedConnectionRequests);
  }

  useEffect(() => {
    fetchAndSetInvestorRequests();

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchAndSetInvestorRequests();
  }, [location.pathname]);

  async function fetchActivationTasks() {
    if (!accountType) {
      return;
    }

    let endpoint = '';
    if (accountType === AccountTypes.Investor) {
      endpoint = InvestorUserActivationTasks.buildEndpoint();
    }
    if (accountType === AccountTypes.Startup) {
      endpoint = StartupGetUserActivationTasks.buildEndpoint();
    }

    try {
      const taskList = await API.get<Task[]>(endpoint);

      setTasks(taskList.map((task: Task) => taskToView(task)));
    } catch (error) {
      Logger.error(error);
    }
  }

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      fetchActivationTasks();
    }
  }, [accountType]);

  const sharedLinks = (
    <>
      <nav className="flex-0 mt-3 space-y-1 border-t border-gray-200 px-2 pt-3 dark:border-gray-800">
        {!isPaidTier(subscriptionTier) && (
          <NavLink
            onClick={closeNav}
            to={BILLING_ROUTE}
            className={({ isActive }) =>
              joinClassNames(
                isActive
                  ? 'bg-purple-100 text-purple-900 hover:bg-purple-100 hover:text-purple-900 dark:bg-purple-700 dark:text-purple-100 dark:hover:bg-purple-600 dark:hover:text-white'
                  : 'text-purple-600 hover:bg-purple-50 hover:text-purple-900 dark:text-purple-100 dark:hover:bg-purple-600 dark:hover:text-white',
                'flex items-center rounded-md px-2 py-2 text-sm font-medium',
              )
            }
          >
            <BoltIcon className="mr-2 h-[1.3rem] w-[1.3rem] group-hover:text-purple-500" />
            Upgrade
            {!hasUsedTrial && (
              <span className="ml-1.5 inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-600/20">
                14 Day Free Trial
              </span>
            )}
          </NavLink>
        )}
        {isPaidTier(subscriptionTier) && (
          <NavLink
            onClick={closeNav}
            to={BILLING_ROUTE}
            className={({ isActive }) =>
              joinClassNames(
                isActive
                  ? 'bg-gray-100 text-gray-900 hover:bg-gray-100 hover:text-gray-900 dark:bg-gray-700 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white',
                'flex items-center rounded-md px-2 py-2 text-sm font-medium',
              )
            }
          >
            <BoltIcon className="app-nav-icon" />
            Subscription
          </NavLink>
        )}

        <NavLink
          onClick={closeNav}
          to={REFERRALS_ROUTE}
          className={({ isActive }) =>
            joinClassNames(
              isActive
                ? 'bg-gray-100 text-gray-900 hover:bg-gray-100 hover:text-gray-900 dark:bg-gray-700 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white',
              'flex items-center rounded-md px-2 py-2 text-sm font-medium',
            )
          }
        >
          <BanknotesIcon className="app-nav-icon" />
          Refer & Earn
        </NavLink>

        {accountType === AccountTypes.Startup && (
          <a
            id="cta_fundraising_workshop_at_side_menu"
            href={STARTUP_FUNDRAISING_WORKSHOP}
            target="_blank"
            rel="noopener noreferrer"
            className={joinClassNames(
              'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white',
              'flex items-center rounded-md px-2 py-2 text-sm font-medium',
            )}
          >
            <AcademicCapIcon className="app-nav-icon" />
            Masterclass
            <span className="ml-1.5 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              New
            </span>
          </a>
        )}

        {accountType && <HelpPopOver accountType={accountType} />}
      </nav>
      {accountType === AccountTypes.Investor && (
        <InvestorActivationTasks tasks={tasks} />
      )}
      {accountType === AccountTypes.Startup && (
        <StartupActivationTasks tasks={tasks} />
      )}
    </>
  );

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100 dark:bg-gradient-to-b dark:from-black dark:to-slate-900">
      {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
      <div
        className="md:hidden"
        style={{ display: showMenu ? 'block' : 'none' }}
      >
        <div className="fixed inset-0 z-40 flex">
          <Transition
            show={showMenu}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0">
              <div className="absolute inset-0 bg-gray-600 opacity-75" />
            </div>
          </Transition>

          <Transition
            show={showMenu}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex h-screen w-full max-w-sm flex-1 flex-col bg-white dark:bg-gray-900">
              <div className="absolute right-0 top-0 -mr-12 pt-2">
                <button
                  type="button"
                  onClick={closeNav}
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon className="app-nav-icon" />
                </button>
              </div>

              <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                <div className="flex shrink-0 items-center px-4 dark:text-white">
                  <img
                    className="h-10 w-auto"
                    src={flowlieLogo}
                    alt="flowlie"
                  />
                  <div className="ml-2 flex flex-col">
                    <h1 className="-mb-2 text-xl font-bold tracking-wide">
                      flowlie
                    </h1>
                    <span className="-mb-1 -ml-1">
                      <SubscriptionTierPill />
                    </span>
                  </div>
                </div>

                {accountType === AccountTypes.Investor && (
                  <InvestorNavigationLinks
                    flowlink={flowlink}
                    closeNav={closeNav}
                    notifications={notificationsMap}
                  />
                )}
                {accountType === AccountTypes.Startup && (
                  <StartupNavigationLinks
                    flowlink={flowlink}
                    closeNav={closeNav}
                  />
                )}
                {sharedLinks}
              </div>
              <div className="group flex shrink-0 border-t border-gray-200 p-4 dark:border-gray-800">
                <AccountDropDownMenu email={localStorageGetAccountEmail()} />
              </div>
            </div>
          </Transition>

          {/* Force sidebar to shrink to fit close icon */}
          <div className="w-2 shrink-0" />
        </div>
      </div>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:shrink-0">
        <div className="flex w-64 flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex h-0 flex-1 flex-col border-r border-gray-200 bg-white dark:border-gray-800 dark:bg-gray-900">
            <div className="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
              <div className="flex shrink-0 items-center px-4 dark:text-white">
                <img className="h-12 w-auto" src={flowlieLogo} alt="flowlie" />
                <div className="ml-2 flex flex-col">
                  <h1 className="-mb-2.5 text-2xl font-bold tracking-wide">
                    flowlie
                  </h1>
                  <span className="-mb-1 -ml-1">
                    <SubscriptionTierPill />
                  </span>
                </div>
              </div>

              {accountType === AccountTypes.Investor && (
                <InvestorNavigationLinks
                  flowlink={flowlink}
                  closeNav={closeNav}
                  notifications={notificationsMap}
                />
              )}

              {accountType === AccountTypes.Startup && (
                <StartupNavigationLinks
                  flowlink={flowlink}
                  closeNav={closeNav}
                />
              )}

              {sharedLinks}
            </div>
            <div className="w-100 group flex shrink-0 border-t border-gray-200 p-4 dark:border-gray-800">
              <AccountDropDownMenu email={localStorageGetAccountEmail()} />
            </div>
          </div>
        </div>
      </div>

      {/* Page content and open sidebar button */}
      <div className="flex w-0 flex-1 flex-col overflow-hidden">
        <div className="pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
          <button
            type="button"
            onClick={openNav}
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="app-nav-icon" />
          </button>
        </div>
        {/* Route content here */}
        {children}
      </div>
    </div>
  );
}

export default Navigation;
