/* eslint-disable @typescript-eslint/no-var-requires */
// Sentry initialization should be imported first!
import './services/sentry/instrument';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { EnvironmentTypes } from '/../libs/shared-types/src/constants/EnvironmentTypes';
import { Account } from './contexts/Accounts';
import { SharedState } from './contexts/SharedStateContext';
import routes from './routes/index';
import ScrollToTop from './components/utility/ScrollToTop';
import { createRoot } from 'react-dom/client';

import './css/index.css';
import './css/custom.css';
import { AuthenticationContextProvider } from './contexts/AuthenticationContext';

require('dotenv').config();

if (process.env.REACT_APP_ENV === EnvironmentTypes.Production) {
  const tagManagerArgs = {
    gtmId: 'GTM-KLXXP7W',
  };

  TagManager.initialize(tagManagerArgs);
}

if (process.env.REACT_APP_ENV === EnvironmentTypes.Development) {
  const tagManagerArgs = {
    gtmId: 'GTM-5SR27D7',
  };

  TagManager.initialize(tagManagerArgs);
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  <AuthenticationContextProvider>
    <SharedState>
      <Account>
        <BrowserRouter>
          <ScrollToTop />
          {routes}
        </BrowserRouter>
      </Account>
    </SharedState>
  </AuthenticationContextProvider>
  // </React.StrictMode>
);
