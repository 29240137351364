import React, { ReactNode, useEffect, useState } from 'react';
import { SortOrder } from '/src/interfaces/Sortable';
import TableHead from './BaseTableHead';
import TableRow from './BaseTableRow';
import TableCell from './BaseTableCell';
import { cn } from '/src/util/cn';

export interface BaseTableProps {
  headerStructure: Array<{
    headerKey: string;
    label: string;
    className?: string;
    tooltip?: string;
    isDefaultSortKey?: boolean;
    defaultSortOrder?: SortOrder;
  }>;
  rows: Array<{
    rowKey: string;
    data: Record<string, ReactNode>;
    className?: string;
  }>;
  enableSort?: boolean;
  enableSelect?: boolean;
  tableName: string;
  resetSelectedTrigger?: boolean;
  onSortChange?: ({
    headerKey,
    label,
    headerIndex,
    sort,
  }: {
    headerKey: string;
    label: string;
    headerIndex: number;
    sort: SortOrder | null;
  }) => void;
  onSelectedChange?: (rowsId: string[]) => void;
  onRowClick?: (rowId: string) => void;
}

function BaseTable({
  tableName,
  rows,
  headerStructure,
  resetSelectedTrigger,
  onSortChange,
  onSelectedChange,
  onRowClick,
  enableSort = false,
  enableSelect = false,
}: BaseTableProps) {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  // Method to add a new row
  const addSelectedRow = (rowKey: string) => {
    setSelectedRows((prevRows) => [...prevRows, rowKey]);
  };

  // Method to delete a row by its key
  const removeSelectedRow = (rowKey: string) => {
    setSelectedRows((prevRows) =>
      prevRows.filter((prevRow) => prevRow !== rowKey),
    );
  };

  // Method to clear all rows
  const clearRows = () => {
    setSelectedRows([]);
  };

  // Method to select all rows from a given array
  const selectAllRows = () => {
    setSelectedRows(rows.map((row) => row.rowKey));
  };
  // Method to toggle the selection state of a row
  const toggleSelectedRow = (rowKey: string) => {
    const isSelected = selectedRows.some((prevRowKey) => prevRowKey === rowKey);
    if (isSelected) {
      removeSelectedRow(rowKey);
    } else {
      addSelectedRow(rowKey);
    }
  };

  useEffect(() => {
    if (onSelectedChange) {
      onSelectedChange(selectedRows);
    }
  }, [selectedRows]);

  useEffect(() => {
    clearRows();
  }, [resetSelectedTrigger]);

  const isAnyRowSelected = rows.some((row) =>
    selectedRows.some((selectedRow) => row.rowKey === selectedRow),
  );

  return (
    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
      <TableHead
        headerStructure={headerStructure}
        selectAll={selectAllRows}
        clear={clearRows}
        tableName={tableName}
        areAllSelected={rows.every((row) =>
          selectedRows.some((selectedRow) => row.rowKey === selectedRow),
        )}
        isAnySelected={isAnyRowSelected}
        enableSort={enableSort}
        enableSelect={enableSelect}
        onSortChange={onSortChange}
        defaultHeaderSort={headerStructure.findIndex((x) => x.isDefaultSortKey)}
        defaultSortOrder={
          headerStructure.find((x) => x.isDefaultSortKey)?.defaultSortOrder ??
          SortOrder.Asc
        }
      />
      <tbody className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
        {rows.length === 0 && (
          <tr>
            <td
              colSpan={headerStructure.length}
              className="w-100 text-center text-sm text-gray-600 dark:text-gray-200"
            >
              <p className="my-4">No results found</p>
            </td>
          </tr>
        )}
        {rows.map(({ rowKey, data: rowData, className }, index) => {
          const sortedCells = headerStructure.map(
            ({ headerKey }) => rowData[headerKey],
          );
          return (
            <TableRow
              isChecked={selectedRows.some(
                (selectedRowKey) => selectedRowKey === rowKey,
              )}
              key={`${rowKey}-${index}`}
              rowKey={rowKey}
              enableSelect={enableSelect}
              toggleSelectedRow={toggleSelectedRow}
              isAnySelected={isAnyRowSelected}
              className={cn(
                className,
                onRowClick ? 'cursor-pointer hover:bg-gray-100' : '',
              )}
            >
              {sortedCells.map((cell, index) => (
                <TableCell
                  key={`${rowKey}-${index}`}
                  onRowClick={onRowClick}
                  rowKey={rowKey}
                  className=""
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </tbody>
    </table>
  );
}

export default BaseTable;
