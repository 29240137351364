import React, { useEffect, useState, useContext } from 'react';
import { ScreeningDealSummaryView } from '/../libs/shared-types/src/types/view/AggregatedDeals';
import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import API from '/src/middleware/API';
import PageHeader from '/src/components/PageHeader';
import Logger from '/src/services/logger';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import PassDialog from '../ActiveDealflow/dialogs/PassDialog';
import ShareConsumptionContext from '/src/contexts/ShareConsumptionContext';
import ScreeningDealTableRow from './ScreeningDealTableRow';
import { ComparableScreeningDealSummaryView } from '/src/interfaces/Comparable';
import { TableHeader } from '/src/interfaces/TableHeader';
import SearchBar from '/src/components/inputs/SearchBar';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import {
  PaginatedCollectionResponse,
  PaginatedRequestParams,
} from '/../libs/shared-types/src/types/view/APIResponse';
import usePaginatedUrlsParams from '/src/hooks/usePaginatedUrlParams';
import BackendPaginatedTable from '/src/components/table/BackendPaginatedTable';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import { EXAMPLE_STARTUP_SCREENING_DEAL_SUMMARY_VIEW } from '/../libs/shared-types/src/constants/UserActivation/MockData/ExampleDeal';
import { InboxArrowDownIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/20/solid';
import { ModalConfig } from '/src/interfaces/ModalConfig';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import ShareFlowLinkDialogContent from '../InvestorFlowLink/ShareFlowLinkDialogContent';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  InvestorPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import {
  InvestorDealflowScreening,
  InvestorUpdateDealStatus,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import EmptyState from '/src/components/notifications/EmptyState';

const defaultSortKey = 'name' as keyof ComparableScreeningDealSummaryView;
const tableHeaders: TableHeader<ComparableScreeningDealSummaryView>[] = [
  { sortKey: defaultSortKey, element: <span>Company</span> },
  { sortKey: 'stage', element: <span>Stage</span> },
  { sortKey: 'sharedByDate', element: <span>Shared By</span> },
  { element: <span>Message</span> },
  { sortKey: 'round', element: <span>Round</span> },
  { sortKey: 'raising', element: <span>Raising</span> },
  { element: <span>Insights</span> },
  { sortKey: 'fitScore', element: <span>Fit</span> },
  { element: <span className="sr-only">Actions</span> },
];

enum ModalActions {
  ShareWithFounders,
  PassDeal,
}

function ScreeningRoute(): JSX.Element {
  const [apiResponse, setApiResponse] =
    useState<
      PaginatedCollectionResponse<
        ScreeningDealSummaryView,
        ComparableScreeningDealSummaryView
      >
    >();
  const [selectedDeal, setSelectedDeal] = useState<ScreeningDealSummaryView>();
  const [isLoading, setIsLoading] = useState(false);
  const { joyrideActivationTourState, setJoyrideActivationTourState } =
    useContext(SharedStateContext);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [modalConfig, setModalConfig] = useState<ModalConfig<ModalActions>>({
    isOpen: false,
  });
  const openModal = (dialog: ModalActions) =>
    setModalConfig({ isOpen: true, dialog });
  const closeModal = () => setModalConfig({ isOpen: false });
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const hasFitScoreAccess = hasFeatureAccess(
    InvestorPaidFeatures.DealFitScore,
    subscriptionTier
  );

  const linkShareConsumedSuccessfully = useContext(ShareConsumptionContext);

  function ModalContent(): JSX.Element {
    switch (modalConfig.dialog) {
      case ModalActions.ShareWithFounders:
        return (
          <ShareFlowLinkDialogContent
            shareToAccountType={AccountTypes.Startup}
          />
        );
      case ModalActions.PassDeal:
        if (!selectedDeal) {
          return <></>;
        }
        return (
          <PassDialog
            onClose={closeModal}
            onSuccess={loadInitialScreeningDeals}
            deal={selectedDeal}
          />
        );
      default:
        return <></>;
    }
  }

  async function onAcceptDeal(id: string) {
    try {
      if (!apiResponse) {
        return;
      }
      await API.put(InvestorUpdateDealStatus.buildEndpoint(), {
        startupId: id,
        status: DealStatusTypes.Active,
      });

      setApiResponse({
        ...apiResponse,
        results: apiResponse.results.filter(
          (deal: ScreeningDealSummaryView) => deal.startupId !== id
        ),
        totalCount: apiResponse.totalCount - 1,
      });
    } catch (error: any) {
      Logger.error(error);
    }
  }

  function onPassDeal(deal: ScreeningDealSummaryView) {
    setSelectedDeal(deal);
    openModal(ModalActions.PassDeal);
  }

  async function fetchScreeningDeals(
    args: Partial<PaginatedRequestParams<ComparableScreeningDealSummaryView>>
  ) {
    try {
      setIsSearchLoading(true);
      const requestParams = {
        sortKey: defaultSortKey,
        ...apiResponse,
        ...args,
      };
      const url = InvestorDealflowScreening.buildEndpoint(
        undefined,
        requestParams
      );
      const data = await API.get<
        PaginatedCollectionResponse<
          ScreeningDealSummaryView,
          ComparableScreeningDealSummaryView
        >
      >(url);

      setApiResponse(data);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsSearchLoading(false);
    }
  }

  const { page, perPage, sortOrder, sortKey, filter } =
    usePaginatedUrlsParams();

  async function loadInitialScreeningDeals() {
    try {
      setIsLoading(true);
      await fetchScreeningDeals({
        page,
        perPage,
        sortKey: (sortKey ??
          defaultSortKey) as keyof ComparableScreeningDealSummaryView,
        sortOrder,
        filter,
      });
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleFilter(filter: string) {
    try {
      await fetchScreeningDeals({ filter, page: 1 });
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  useEffect(() => {
    if (linkShareConsumedSuccessfully === true) {
      loadInitialScreeningDeals();
    }
  }, [linkShareConsumedSuccessfully]);

  useEffect(() => {
    loadInitialScreeningDeals();
  }, []);

  // Event listener for handling Activation Tour
  useEffect(() => {
    if (
      isLoading ||
      !apiResponse ||
      !localStorage.getItem(
        LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING
      )
    ) {
      // Do not resume the tour yet
      return;
    }

    // Add mock data
    apiResponse.results.splice(
      0,
      0,
      EXAMPLE_STARTUP_SCREENING_DEAL_SUMMARY_VIEW
    );
    apiResponse.totalCount = apiResponse.results.length;

    // Resume joyride tour
    setJoyrideActivationTourState({
      ...joyrideActivationTourState,
      run: true,
    });
  }, [apiResponse, isLoading]);

  return (
    <div className="joyride-screeningFirstRow flex flex-col">
      <PageHeader
        title="Screening"
        itemsCount={apiResponse?.totalCount}
        itemLabel="deal"
      />

      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {isLoading && (
            <PageLoadingSpinner message="Loading billion dollar opportunities... 💰" />
          )}

          {!isLoading && apiResponse && (
            <>
              {apiResponse.totalCount === 0 && apiResponse.filter === '' && (
                <EmptyState
                  title="No deals to screen"
                  subTitle="Get started by sharing your FlowLink with a founder."
                  icon={
                    <InboxArrowDownIcon className="mx-auto h-12 w-12 text-gray-400" />
                  }
                  actionButton={
                    <button
                      type="button"
                      className="app-button--primary"
                      onClick={() => openModal(ModalActions.ShareWithFounders)}
                    >
                      <PlusIcon
                        className="-ml-0.5 mr-1.5 h-5 w-5"
                        aria-hidden="true"
                      />
                      Add New Deal
                    </button>
                  }
                />
              )}

              {(apiResponse.totalCount > 0 || apiResponse.filter !== '') && (
                <>
                  <div className="mb-3 flex flex-row items-center">
                    <SearchBar
                      isDebounce
                      placeholder="Search Screening Deals"
                      onQueryChange={handleFilter}
                      initialValue={apiResponse.filter}
                    />
                    {isSearchLoading && <LoadingSpinner color="blue" />}
                  </div>
                  <BackendPaginatedTable
                    headers={tableHeaders}
                    rowComponents={apiResponse.results.map((deal) => (
                      <ScreeningDealTableRow
                        key={deal.startupId}
                        deal={deal}
                        onPassDeal={onPassDeal}
                        onAcceptDeal={onAcceptDeal}
                        hasFitScoreAccess={hasFitScoreAccess}
                      />
                    ))}
                    refreshData={fetchScreeningDeals}
                    parentPage={apiResponse.page}
                    parentTotalCount={apiResponse.totalCount}
                    parentSortOrder={apiResponse.sortOrder}
                    parentSortedColumn={apiResponse.sortKey}
                    parentFilter={apiResponse.filter}
                    parentPerPage={apiResponse.perPage}
                    parentTotalPages={apiResponse.totalPages}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>

      <ModalWrapper open={modalConfig.isOpen} onClose={() => closeModal()}>
        <ModalContent />
      </ModalWrapper>
    </div>
  );
}

export default ScreeningRoute;
