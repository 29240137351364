import ConnectionMessageField from './fields/ConnectionMessageField';
import LinkDiscoveryField from './fields/LinkDiscoveryField';
import InvestorFlowlinkFormFieldNames from '/src/enums/InvestorFlowlinkFormFieldNames';
import FormField from '/src/interfaces/FormField';

const INVESTOR_FLOWLINK_FORM_FIELD_MAPPING: { [key: string]: FormField } = {
  [InvestorFlowlinkFormFieldNames.LinkDiscovery]: LinkDiscoveryField,
  [InvestorFlowlinkFormFieldNames.ConnectionMessage]: ConnectionMessageField,
};

export default INVESTOR_FLOWLINK_FORM_FIELD_MAPPING;
