import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { MAX_NATIONALITIES } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';
import NATIONALITY_OPTIONS from '/../libs/shared-types/src/constants/SelectOptions/NationalityOptions';
import formatNationalityOptionLabel from '../../inputs/ReactSelectAdditions/formatNationalityOptionLabel';

const NationalitiesField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      components={{ Menu: LimitedMenu }}
      formatOptionLabel={formatNationalityOptionLabel}
      isClearable
      isMulti
      label="What nationalities do you identify with?"
      maxCount={MAX_NATIONALITIES}
      name={InvestorFieldNames.Nationalities}
      options={NATIONALITY_OPTIONS}
      placeholder="Search countries..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.Nationalities]: yup
      .array()
      .of(yup.string())
      .nullable()
      .max(
        MAX_NATIONALITIES,
        `At most ${MAX_NATIONALITIES} nationalities are allowed`
      ),
  }),
};

export default NationalitiesField;
