import React from 'react';
import * as yup from 'yup';
import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';
import FormField from '/src/interfaces/FormField';
import PercentageInput from '../../inputs/PercentageInput';
import {
  CARRY_CHARGED_MIN,
  CARRY_CHARGED_MAX,
} from '/../libs/shared-types/src/constants/NumericRanges';

const CarryChargedField: FormField = {
  fieldComponent: (
    <PercentageInput
      label="If any, how much carry are you charged?"
      name={RecordInvestmentFieldNames.CarryCharged}
      tooltip="Were you charged any carried interest for this investment, payable to another person from the profit generated by the capital you invested in this company?"
    />
  ),
  validation: yup.object({
    [RecordInvestmentFieldNames.CarryCharged]: yup
      .number()
      .typeError('Must be a number')
      .min(CARRY_CHARGED_MIN, `Carry cannot be less than ${CARRY_CHARGED_MIN}%`)
      .max(
        CARRY_CHARGED_MAX,
        `Carry cannot be greater than ${CARRY_CHARGED_MAX}%`
      )
      .required('Required'),
  }),
};

export default CarryChargedField;
