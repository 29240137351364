import React from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { getColor } from '/src/util/colorLookup';
import { formatImageAddress } from '/src/util/formatting/strings';
import { ConnectionRequestAccountData } from '/../libs/shared-types/src/types/view/APIResponse';
import DateString from '/src/components/utility/DateString';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import { generateTag } from '/src/util/generateTagCloud';

interface ConnectionRequestsTableRowProps {
  onAccept: (id: string) => void;
  onReject: (id: string) => void;
  onCancel: (id: string) => void;
  isOutgoing?: boolean;
  contact: ConnectionRequestAccountData;
}

function ConnectionRequestsTableRow({
  onAccept,
  onReject,
  onCancel,
  isOutgoing,
  contact,
}: ConnectionRequestsTableRowProps): JSX.Element {
  const acceptRejectButtons = (
    <td className="app-table-row-item flex flex-col items-end">
      <span className="relative z-0 inline-flex rounded-md shadow-sm">
        <div className="tooltip-wrapper">
          <span className="tooltip tooltip-top w-max">Reject Request</span>
          <button
            type="button"
            onClick={() => onReject(contact.requestId)}
            className="relative -ml-px inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
          >
            <span className="sr-only">Reject</span>
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        <div className="tooltip-wrapper">
          <span className="tooltip tooltip-top w-max">Accept Request</span>
          <button
            type="button"
            onClick={() => onAccept(contact.requestId)}
            className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
          >
            <span className="sr-only">Accept</span>
            <CheckIcon className="h-5 w-5" />
          </button>
        </div>
      </span>
    </td>
  );

  const cancelButton = (
    <td className="app-table-row-item flex flex-col items-end">
      <div className="tooltip-wrapper">
        <span className="tooltip tooltip-top w-max">Cancel Request</span>
        <button
          type="button"
          onClick={() => onCancel(contact.requestId)}
          className="app-button--neutral"
        >
          Cancel
        </button>
      </div>
    </td>
  );

  return (
    <tr>
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <UserAvatarWithInitials
              containerStyles="h-10 w-10"
              firstName={contact.firstName}
              lastName={contact.lastName}
              textStyles="text-xl"
              imgAlt="Investor Profile"
              imgSrc={formatImageAddress(contact.profilePicKey)}
            />
          </div>
          <div className="app-table-row-text--bold ml-2">{`${contact.firstName} ${contact.lastName}`}</div>
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">{contact.email}</div>
      </td>
      <td className="whitespace-nowrap px-4 py-4">
        <span
          className={`app-tag ${getColor(contact.type).bgColor} ${
            getColor(contact.type).textColor
          }`}
        >
          {contact.type}
        </span>
      </td>
      <td className="app-table-row-item">
        {contact.firm && contact.role ? (
          <>
            <div className="app-table-row-text">{contact.firm}</div>
            <div className="app-table-row-text--light">{contact.role}</div>
          </>
        ) : (
          <div className="app-table-row-text">-</div>
        )}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">{contact.message}</div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {contact.discoveredFrom ? generateTag(contact.discoveredFrom) : '-'}
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          <DateString date={contact.createdOn} tooltipPosition="bottom" />
        </div>
      </td>
      {isOutgoing ? cancelButton : acceptRejectButtons}
    </tr>
  );
}

export default ConnectionRequestsTableRow;
