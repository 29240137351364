import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import { MAX_STARTUP_INDUSTRIES } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import { STARTUP_IND_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/StartupIndustryOptions';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';

const IndustriesField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      components={{ Menu: LimitedMenu }}
      isClearable
      isMulti
      label="What industries do you operate in?"
      maxCount={MAX_STARTUP_INDUSTRIES}
      name={StartupFieldNames.Industries}
      options={STARTUP_IND_OPTIONS}
      placeholder="Select industries..."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.Industries]: yup
      .array()
      .of(yup.string())
      .max(
        MAX_STARTUP_INDUSTRIES,
        `At most ${MAX_STARTUP_INDUSTRIES} industries are allowed`,
      )
      .min(1, 'At least one is required')
      .required('This field is required'),
  }),
};

export default IndustriesField;
