import { PresentationChartLineIcon } from '@heroicons/react/20/solid';
import React from 'react';

import { abbreviateNumber } from '/src/util/formatting/numbers';
import {
  formatImageAddress,
  shortenWebUrl,
} from '/src/util/formatting/strings';
import { formatDaysUntil, dayDiff } from '/src/util/formatting/dates';
import { generateTag, generateTagCloud } from '/src/util/generateTagCloud';
import { isMinDate } from '/src/util/time';
import { roundIsInGracePeriod, isRoundOpen } from '/src/util/rounds';
import DealActionsDropdown from '/src/components/DealActionsDropdown';
import {
  ActiveDealSummaryView,
  DealSummaryView,
} from '/../libs/shared-types/src/types/view/AggregatedDeals';
import ActiveStageDurationLeft from '../DealDetail/ActiveStageDurationLeft';
import StarRatingSelect from '../DealDetail/StarRatingSelect';
import { StarRating } from '/../libs/shared-types/src/constants/StarRating';
import PrioritySelect from '../DealDetail/PrioritySelect';
import { Priority } from '/../libs/shared-types/src/constants/Priority';
import CopyTextButton from '/src/components/CopyTextButton';
import SquaredLogo from '/src/components/SquaredLogo';
import { calculateAmountRemaining } from '/../libs/shared-types/src/util/RoundCalculations';

interface ActiveDealProps {
  deal: ActiveDealSummaryView;
  onPassDeal: (deal: DealSummaryView) => void;
  onRecordInvestment: (deal: DealSummaryView) => void;
  onShareDeal: (deal: DealSummaryView) => void;
  handleUpdateDealPriority: (
    deal: ActiveDealSummaryView,
    newRating: Priority,
  ) => void;
  handleUpdateDealRating: (
    deal: ActiveDealSummaryView,
    newRating: StarRating,
  ) => void;
}

function ActiveDeal({
  deal,
  onPassDeal,
  onRecordInvestment,
  onShareDeal,
  handleUpdateDealPriority,
  handleUpdateDealRating,
}: ActiveDealProps): JSX.Element {
  const amountRemaining = calculateAmountRemaining(
    deal.currentRound?.raiseTarget ?? 0,
    deal.currentRound?.raisedAmount ?? 0,
  );
  const isCurrentRoundOpen =
    deal.currentRound && isRoundOpen(deal.currentRound);

  function dealPriorityOnSelect(newPriority: Priority) {
    handleUpdateDealPriority(deal, newPriority);
  }

  function dealRatingOnSelect(newRating: StarRating) {
    handleUpdateDealRating(deal, newRating);
  }

  return (
    <>
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <figure className="relative">
              <SquaredLogo
                containerClassName="size-10"
                src={formatImageAddress(deal.startupLogoKey)}
                alt={`${deal.startupName} logo`}
              />
              {deal.isPortfolioDeal && (
                <span
                  className="absolute -top-1.5 -left-1.5 rounded-full bg-green-300 p-0.5"
                  title="This deal is in your portfolio"
                >
                  <PresentationChartLineIcon className="h-4 w-4 text-green-700" />
                </span>
              )}
            </figure>
          </div>
          <div className="ml-2 max-w-[160px]">
            <div className="app-table-row-text--bold">{deal.startupName}</div>
            <div className="flex w-full text-sm text-gray-500">
              <a
                className="block truncate hover:underline"
                target="_blank"
                rel="noopener noreferrer"
                href={deal.website}
                onClick={(e) => e.stopPropagation()}
              >
                {shortenWebUrl(deal.website)}
              </a>
              <CopyTextButton text={deal.website} className="ml-1" />
            </div>
          </div>
        </div>
      </td>
      <td className="app-table-row-item">{generateTag(deal.currentStage)}</td>
      <td className="app-table-row-item">
        {generateTagCloud(deal.industries)}
      </td>
      <td className="app-table-row-item">
        {generateTag(isCurrentRoundOpen ? 'Open' : 'Closed', true)}
        {isCurrentRoundOpen &&
          !isMinDate(deal.currentRound?.estimatedCloseDate) && (
            <div className="app-table-row-text--light">
              Closing&nbsp;
              {Math.abs(dayDiff(deal.currentRound?.estimatedCloseDate)) > 1 &&
                'in '}
              {formatDaysUntil(deal.currentRound?.estimatedCloseDate)}
            </div>
          )}
        {!isCurrentRoundOpen &&
          deal.currentRound &&
          roundIsInGracePeriod(deal.currentRound) && (
            <div className="app-table-row-text--light">Action Required</div>
          )}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {deal.currentRound?.raiseTarget !== undefined
            ? `$${abbreviateNumber(deal.currentRound?.raiseTarget)}`
            : '-'}
          {deal.currentRound?.raiseTarget !== undefined && (
            <div className="app-table-row-text--light">
              {amountRemaining >= 0
                ? `$${abbreviateNumber(amountRemaining)} Remaining`
                : 'Oversubscribed'}
            </div>
          )}
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {deal.currentRound ? (
            deal.currentRound.valuationCap ? (
              `$${abbreviateNumber(deal.currentRound.valuationCap)}`
            ) : (
              <span title="The founder has not yet set a valuation">
                To Be Decided
              </span>
            )
          ) : (
            '-'
          )}
          <div className="app-table-row-text--light">
            {deal.currentRound?.valuationCapType}
          </div>
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          <StarRatingSelect
            value={deal.rating.rating}
            onSelect={dealRatingOnSelect}
          />
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          <span className="relative">
            <PrioritySelect
              value={deal.priority.priority}
              onSelect={dealPriorityOnSelect}
            />
            <span className="absolute inset-x-1 ">
              <ActiveStageDurationLeft durationLeft={deal.durationLeft} />
            </span>
          </span>
        </div>
      </td>

      <td
        className="whitespace-nowrap px-3 py-3 text-right text-sm font-medium"
        role="gridcell"
      >
        <DealActionsDropdown
          deal={deal}
          handleMoveToActive={() => null}
          handlePass={onPassDeal}
          handleRecordInvestment={onRecordInvestment}
          handleShare={onShareDeal}
        />
      </td>
    </>
  );
}

export default ActiveDeal;
