import React from 'react';

import FlagEmoji from '/src/components/utility/FlagEmoji';
import { SelectOption } from '/../libs/shared-types/src/types/SelectOptions';

// This is used for react select
const formatNationalityOptionLabel = ({
  label,
  value,
}: SelectOption): JSX.Element => (
  <div className="flex items-center space-x-3">
    <FlagEmoji countryCode={value} className="h-5 w-5" />
    <div>{label}</div>
  </div>
);

export default formatNationalityOptionLabel;
