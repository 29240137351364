import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import Alert from '/src/components/notifications/Alert';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import DebugRender from '/src/components/utility/DebugRender';
import FormField from '/src/interfaces/FormField';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Logger from '/src/services/logger';
import AUTH_FIELD_MAPPING from './AuthFormFieldMap';

interface FieldsWithValues {
  fields: FormField[];
  initialValues: Record<string, any>;
}

const visitorSessionFields: FieldsWithValues = {
  fields: [
    AUTH_FIELD_MAPPING[AuthFieldNames.FirstName],
    AUTH_FIELD_MAPPING[AuthFieldNames.LastName],
    AUTH_FIELD_MAPPING[AuthFieldNames.Email],
  ],
  initialValues: {
    [AuthFieldNames.FirstName]: '',
    [AuthFieldNames.LastName]: '',
    [AuthFieldNames.Email]: '',
  },
};

interface VisitorSessionFormProps {
  handleOnSubmit: (formValues: any) => Promise<void>;
  isLoading: boolean;
}

function VisitorSessionForm({
  handleOnSubmit,
  isLoading,
}: VisitorSessionFormProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState('');
  const toggleAlert = () => setErrorMessage('');

  async function onSubmit(values: any) {
    try {
      await handleOnSubmit(values);
    } catch (error: any) {
      Logger.error(error.message);
      setErrorMessage(error.message);
    }
  }

  return (
    <Formik
      initialValues={visitorSessionFields.initialValues}
      validationSchema={visitorSessionFields.fields
        .map((field: FormField) => field.validation)
        .reduce((merged: any, schema: any) => merged.concat(schema))}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values, errors }) => (
        <Form>
          <div className="px-8 pt-2">
            {visitorSessionFields.fields.map((field, idx) => (
              <div key={idx}>{field.fieldComponent}</div>
            ))}
          </div>
          <div className="my-4 grid justify-items-center px-8">
            <button
              className="auth-button button min-w-full"
              type="submit"
              disabled={isSubmitting}
            >
              View One-pager & Deck
              {isLoading && (
                <div className="ml-1">
                  <LoadingSpinner color="white" />
                </div>
              )}
            </button>
          </div>

          <div className="my-2 px-8">
            <Alert
              color="red"
              alertType="Error"
              content={errorMessage}
              isShown={errorMessage !== ''}
              onClose={toggleAlert}
            />
          </div>

          <DebugRender className="mb-4">
            <pre>Cannot submit if any of the following is true:</pre>
            <pre>{`isSubmitting: ${isSubmitting}`}</pre>
          </DebugRender>
          <DebugRender>
            <pre>Formik Values:</pre>
            <pre className="mb-8">{JSON.stringify(values, null, 2)}</pre>
            <pre>Formik Errors:</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
          </DebugRender>
        </Form>
      )}
    </Formik>
  );
}

export default VisitorSessionForm;
