import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import notFoundGraphic from '/src/res/not_found.svg';
import { localStorageGetAuthenticatedAccountType } from '/src/middleware/LocalStorage';
import { STARTUP_PROFILE_ROUTE, SCREENING_ROUTE } from '/src/constants/Routes';

function NotFoundRoute(): JSX.Element {
  const [defaultRoute, setDefaultRoute] = useState('');
  const [defaultRouteName, setDefaultRouteName] = useState('');

  function getDefaultRoute() {
    if (localStorageGetAuthenticatedAccountType() === AccountTypes.Investor) {
      setDefaultRoute(SCREENING_ROUTE);
      setDefaultRouteName('Screening');
    } else if (
      localStorageGetAuthenticatedAccountType() === AccountTypes.Startup
    ) {
      setDefaultRoute(STARTUP_PROFILE_ROUTE);
      setDefaultRouteName('Profile');
    } else {
      setDefaultRoute('');
    }
  }

  useEffect(() => {
    getDefaultRoute();
  }, []);

  return (
    <div className="my-10 rounded-xl bg-gradient-to-r from-blue-500 to-blue-400 py-8 lg:relative lg:z-10">
      <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8">
        <div className="relative lg:-my-8">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 hidden h-1/2"
          />
          <div className="mx-auto flex max-w-md justify-center px-4 sm:max-w-3xl sm:px-6 lg:h-full lg:p-0">
            <div className="hidden self-center overflow-hidden rounded-xl bg-transparent sm:block sm:w-4/5 sm:aspect-none lg:w-full lg:aspect-none">
              <img
                className="object-cover lg:h-full lg:w-full"
                src={notFoundGraphic}
                alt="Not Found"
              />
            </div>
          </div>
        </div>
        <div className="lg:col-span-2 lg:m-0 lg:pl-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
            <h2 className="text-6xl font-bold text-white">404 Not Found</h2>
            <p className="my-6 text-2xl font-medium text-white">
              We could not find what you were looking for.
            </p>

            <Link
              to={defaultRoute}
              className="text-base font-medium text-gray-100 hover:text-white"
            >
              {`Return to ${defaultRouteName}`}
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFoundRoute;
