import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import { RoundStages } from '/../libs/shared-types/src/constants/RoundStages';
import { Investor } from '/../libs/shared-types/src/types/model/Investor';
import { Startup } from '/../libs/shared-types/src/types/model/Startup';
import { enumToList } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';

enum HeapInvStageOptions {
  Agnostic = 'Agnostic',
  EarlyStage = 'EarlyStage',
  LateStage = 'LateStage',
}

interface HeapUserProperties {
  accountTier: SubscriptionTiers;
  email: string;
  employeesCount: string; // '' for investors
  firm: string; // Startup name, or firm the investor works at, '' for angel
  invStage: HeapInvStageOptions | ''; // '' for startups
  isAccredited: 'Yes' | 'No' | ''; // '' for startups
  leadsRounds: string; // '' for startups
  location: string;
  name: string; // Account full name
  role: string; // '' for angel and 'Founder' for startup
  stage: string; // Only for startups, '' for investors
  type: 'Startup' | InvestorTypes; // Startup, Angel, VC, Corporate VC, Family Office, Syndicator
}

// verifies if we have heap in the browser window (if it was not blocked by an extension)
export const heapInBrowser = () => (window as any).heap !== undefined;

// identifies user to heap in the session by thier MongoDB id
export const heapIdentify = (token: string): void => {
  if (heapInBrowser()) {
    (window as any).heap.identify(token);
  }
};

// resets heap identity on the session
export const heapReset = (): void => {
  if (heapInBrowser()) {
    (window as any).heap.resetIdentity();
  }
};

// adds user properties to heap for this session
export const addHeapUserProperties = (
  properties: Partial<HeapUserProperties>
): void => {
  if (heapInBrowser()) {
    // NOTE *** keep all properties here lowercase
    (window as any).heap.addUserProperties(properties);
  }
};

// adds user properties to heap for this session
export const addHeapUserProperty = (property: any): void => {
  if (heapInBrowser()) {
    (window as any).heap.addUserProperties(property);
  }
};

export function getHeapInvStage(invStages: string[]): HeapInvStageOptions {
  const stageOptions = enumToList(RoundStages);
  const earlyStageBucket = stageOptions.slice(0, 4);
  const lateStageBucket = stageOptions.slice(4);

  if (invStages.every((x) => !stageOptions.includes(x))) {
    throw Error('Invalid stage input');
  }

  if (invStages.every((x) => earlyStageBucket.includes(x))) {
    return HeapInvStageOptions.EarlyStage;
  }
  if (invStages.every((x) => lateStageBucket.includes(x))) {
    return HeapInvStageOptions.LateStage;
  }
  return HeapInvStageOptions.Agnostic;
}

export function updateHeapInvestorUser(investorProfile: Investor): void {
  addHeapUserProperties({
    email: investorProfile.email,
    employeesCount: '',
    firm: investorProfile.firm ?? '',
    invStage: getHeapInvStage(investorProfile.invStages),
    isAccredited: investorProfile.isAccredited ? 'Yes' : 'No',
    leadsRounds: investorProfile.leadsRounds,
    location: investorProfile.location,
    name: `${investorProfile.firstName} ${investorProfile.lastName}`,
    role: investorProfile.role ?? '',
    stage: '',
    type: investorProfile.type,
  });
}

export function updateHeapStartupUser(startupProfile: Startup): void {
  addHeapUserProperties({
    email: startupProfile.accountEmail,
    employeesCount: startupProfile.employeesCount ?? '',
    firm: startupProfile.name,
    invStage: '',
    isAccredited: '',
    leadsRounds: '',
    location: startupProfile.geography,
    name: `${startupProfile.accountFirstName} ${startupProfile.accountLastName}`,
    role: 'Founder',
    stage: startupProfile.currentStage,
    type: 'Startup',
  });
}

export function updateHeapUserSubscriptionTier(
  subscriptionTier: SubscriptionTiers
): void {
  addHeapUserProperties({
    accountTier: subscriptionTier,
  });
}
