import React, { useContext, useState } from 'react';
import { Field, useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import { DealSummaryView } from '/../libs/shared-types/src/types/view/AggregatedDeals';
import { CONTACT_MANAGER_ROUTE } from '/src/constants/Routes';
import { formatImageAddress } from '/src/util/formatting/strings';
import { PublicProfilePrivacySetting } from '/../libs/shared-types/src/constants/PublicProfilePrivacySetting';
import { roundIsInGracePeriod } from '/src/util/rounds';
import { SHORT_TEXTAREA_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import Alert from '/src/components/notifications/Alert';
import API from '/src/middleware/API';
import CopyTextButton from '/src/components/CopyTextButton';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormikInput from '/src/components/inputs/FormikInput';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Logger from '/src/services/logger';
import ShareContactView from '/../libs/shared-types/src/types/view/ShareContactView';
import TextCharacterCounter from '/src/components/TextCharacterCounter';
import TopMatch from './TopMatch';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  InvestorPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { HiddenFitScore } from '/src/util/generateTagCloud';
import RoundImageWithPlaceholder from '/src/components/RoundImageWithPlaceholder';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import { FormatOptionLabelMeta } from 'react-select';
import { FitScoreSelectOption } from '/../libs/shared-types/src/types/SelectOptions';
import FormatOptionsLabelFitScore from '../FormatOptionsLabelFitScore';
import { DEALSHARE_BASE_URL } from '/src/util/urls';
import {
  InvestorGetConnectionMatches,
  InvestorShareDeal,
  SharedLinkSharingGetDealShareLink,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { FitScore } from '/../libs/shared-types/src/types/model/FitScore';

const PrivacySettingAlertContent = (
  privacySetting: PublicProfilePrivacySetting,
): JSX.Element => {
  switch (privacySetting) {
    case PublicProfilePrivacySetting.Public:
      return (
        <span>
          The founder set their visibility to{' '}
          <strong>&quot;Public&quot;</strong> so no information is required to
          view their profile
        </span>
      );
    case PublicProfilePrivacySetting.RequireNameAndEmail:
      return (
        <span>
          The founder set their visibility to{' '}
          <strong>&quot;Soft-gated&quot;</strong> so name and email are required
          to view their profile
        </span>
      );
    case PublicProfilePrivacySetting.RequireFlowlieAccount:
      return (
        <span>
          The founder set their visibility to{' '}
          <strong>&quot;Hard-gated&quot;</strong> so a Flowlie account is
          required to view their profile
        </span>
      );
    default:
      return <></>;
  }
};

const CloseRoundAlertContent = (
  roundName: string,
  deadline: Date,
): JSX.Element => (
  <div className="text-sm font-normal">
    <p className="text-base font-medium leading-6">
      The founder has just closed their {roundName} round.
    </p>
    <p className="my-2">
      All investors who receive this deal now will need to make a decision on
      whether to pass or invest by&nbsp;
      <span className="font-medium">{deadline.toLocaleString()}.</span>
    </p>
    <p>
      If they do not take any action Flowlie will automatically pass the deal
      for them.
    </p>
  </div>
);

const CONTACTS_FIELD_NAME = 'contacts';
const MESSAGE_FIELD_NAME = 'message';
const REQUIRED_MATCH_STRENGTH = 70;

const validationSchema = yup.object({
  [CONTACTS_FIELD_NAME]: yup.array().of(yup.string()).required('Required'),
  [MESSAGE_FIELD_NAME]: yup
    .string()
    .max(SHORT_TEXTAREA_LENGTH_MAX, 'Message is too long'),
});

const initialValues = {
  [MESSAGE_FIELD_NAME]: '',
  [CONTACTS_FIELD_NAME]: [] as string[],
};

interface SelectedContact {
  value: string | undefined;
  label: string;
  fitScore: FitScore;
  firm?: string;
  role?: string;
}

interface ShareDealDialogProps {
  onClose: () => void;
  onSuccess: () => void;
  deal: DealSummaryView;
}

function ShareDealDialog({
  onClose,
  onSuccess,
  deal,
}: ShareDealDialogProps): JSX.Element {
  const [connections, setConnections] = useState<SelectedContact[]>([]);
  const [dealshareLink, setDealshareLink] = useState('Loading...');
  const roundInGracePeriod =
    deal.currentRound !== undefined && roundIsInGracePeriod(deal.currentRound);
  const [isLoading, setIsLoading] = useState(false);
  const [baseContact, setBaseContact] = useState<ShareContactView[]>([]);
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const hasFitScoreAccess = hasFeatureAccess(
    InvestorPaidFeatures.DealFitScore,
    subscriptionTier,
  );

  // Used to detect "Enter" key and ignore its input in text area
  function handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  function buildContactsOptions(contacts: ShareContactView[]) {
    const selectContacts: SelectedContact[] = [];
    contacts.forEach((contact) => {
      selectContacts.push({
        value: contact.id,
        label: `${contact.firstName} ${contact.lastName}`,
        fitScore: contact.fitScore,
        firm: contact.firm,
        role: contact.role,
      });
    });
    setConnections(selectContacts);
  }

  function buildLinkFromToken(token: string) {
    return `${DEALSHARE_BASE_URL}${token}`;
  }

  async function fetchDealshareLink() {
    try {
      const { token } = await API.get(
        SharedLinkSharingGetDealShareLink.buildEndpoint(deal.startupId),
      );
      setDealshareLink(buildLinkFromToken(token));
    } catch (error) {
      Logger.error(error);
    }
  }

  async function fetchConnections() {
    setIsLoading(true);
    try {
      const contacts: ShareContactView[] = await API.get(
        InvestorGetConnectionMatches.buildEndpoint(deal.startupId),
      );
      setBaseContact(contacts);
      buildContactsOptions(contacts);
    } catch (error: any) {
      Logger.error(error);
    }
    setIsLoading(false);
  }

  const shareDealInApp = async (values: any) => {
    const { message } = values;
    const { contacts } = values;

    return API.post(InvestorShareDeal.buildEndpoint(), {
      message,
      contactIds: contacts,
      dealId: deal.startupId,
    })
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        Logger.error(error);
      })
      .finally(() => {
        onClose();
      });
  };

  const formikProps = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await shareDealInApp(values);
      setSubmitting(false);
    },
  });

  function filteredList() {
    const existingChoices = formikProps.values.contacts ?? [];
    return baseContact.filter(
      // contact not in existing choices
      (contact) =>
        !existingChoices.includes(contact.id ?? '') &&
        contact.fitScore.score >= REQUIRED_MATCH_STRENGTH,
    );
  }

  function handleAddSuggestion(investorId: string) {
    const existingChoices = formikProps.values.contacts ?? [];
    formikProps.setFieldValue(CONTACTS_FIELD_NAME, [
      ...existingChoices,
      investorId,
    ]);
  }

  const extractTopPicks = () =>
    filteredList()
      .filter((pick) => !pick.isReceivedFrom && !pick.isSharedWith)
      .slice(0, 4);

  const customOptionsFilter = (
    connection: {
      label: string;
      value: string;
      data: {
        firm?: string;
        role?: string;
        match: number;
      };
    },
    input: string,
  ) => {
    if (input) {
      const lowerCaseInput = input.toLowerCase();
      return (
        connection.data.firm?.toLowerCase().includes(lowerCaseInput) ||
        connection.data.role?.toLowerCase().includes(lowerCaseInput) ||
        connection.label.toLowerCase().includes(lowerCaseInput)
      );
    }
    return true;
  };

  React.useEffect(() => {
    fetchDealshareLink();
    fetchConnections();
  }, []);

  return (
    <main className="bg-white p-4 sm:w-screen sm:max-w-3xl sm:p-7">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <h3
          className="text-lg font-medium leading-6 text-gray-900"
          id="modal-headline"
        >
          Share {deal.startupName}
        </h3>

        {deal.currentRound && (
          <Alert
            alertType="Warning"
            color="yellow"
            content={CloseRoundAlertContent(
              deal.currentRound.roundStage,
              deal.currentRound.gracePeriodEndsOn,
            )}
            isShown={roundInGracePeriod}
          />
        )}

        <section className="my-6">
          <p className="text-base">
            DealShare Link
            {/* <span className="ml-2">{generateTag('Expires in 14 days')}</span> */}
          </p>
          <div className="mt-1 flex rounded-md shadow-sm">
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
              <input
                type="text"
                name="link"
                id="link"
                className="app-secure-link-input"
                value={dealshareLink}
                readOnly
              />
            </div>
            <CopyTextButton
              text={dealshareLink}
              className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
          </div>
          {deal.startupPublicProfilePrivacySetting && (
            <div className="mt-4">
              <Alert
                alertType="Info"
                color="blue"
                content={PrivacySettingAlertContent(
                  deal.startupPublicProfilePrivacySetting,
                )}
                isShown
                canDismiss={false}
              />
            </div>
          )}
        </section>

        <div className="relative my-4">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-sm text-gray-500">
              Or share directly on Flowlie
            </span>
          </div>
        </div>

        <div className="w-100 mb-4 flex flex-col">
          <h3 className="text-base">Top Matches</h3>

          {isLoading && (
            <div className="my-10 flex flex-col items-center justify-center">
              <LoadingSpinner color="blue" size={7} />
              <p className="mt-3 text-base font-medium text-gray-700">
                Searching for the best matches&nbsp;...&nbsp;🚀
              </p>
            </div>
          )}

          {!isLoading && !hasFitScoreAccess && (
            <div className="w-100 mt-2 flex justify-center text-center">
              <div className="mx-1 w-full max-w-[164px] flex-1 cursor-pointer rounded-md border border-gray-300 bg-white py-2 transition-all hover:border-2 hover:border-blue-500">
                <div className="relative mb-2">
                  <RoundImageWithPlaceholder
                    containerStyles="h-16 w-16 mx-auto"
                    text="?"
                    textStyles="text-2xl"
                    imgAlt="Hidden investor recommendation"
                  />
                  <div className="absolute -bottom-2 right-7 text-2xs">
                    <HiddenFitScore />
                  </div>
                </div>

                <p className="my-2 text-sm font-medium text-gray-800">
                  {'Get deal share recommendations'}
                </p>

                <SubscriptionCTAPill id="cta_dealshare_recommendations" />
              </div>
            </div>
          )}

          {!isLoading && hasFitScoreAccess && extractTopPicks().length > 0 && (
            <div className="w-100 mt-2 flex flex-row justify-center">
              {extractTopPicks().map((contact) => (
                <TopMatch
                  key={contact.id}
                  fitScore={contact.fitScore}
                  name={`${contact.firstName} ${contact.lastName}`}
                  firm={contact.firm}
                  role={contact.role}
                  type={contact.type}
                  picUrl={formatImageAddress(contact.profilePicKey)}
                  onAdd={handleAddSuggestion}
                  id={contact.id ?? ''}
                />
              ))}
            </div>
          )}

          {!isLoading &&
            hasFitScoreAccess &&
            extractTopPicks().length === 0 && (
              <section className="mt-2 text-center">
                <p className="text-sm text-gray-800">
                  No top matches found for this deal
                </p>
                <Link className="hyperlink text-sm" to={CONTACT_MANAGER_ROUTE}>
                  Add new contacts from Contact Manager
                </Link>
              </section>
            )}
        </div>

        <FormikProvider value={formikProps}>
          <form onSubmit={formikProps.handleSubmit}>
            <Field
              className="custom-select"
              component={CustomSelect}
              formatOptionLabel={(
                option: FitScoreSelectOption,
                formatOptionLabelMeta: FormatOptionLabelMeta<any>,
              ) => (
                <FormatOptionsLabelFitScore
                  option={option}
                  formatOptionLabelMeta={formatOptionLabelMeta}
                  hasFitScoreAccess={hasFitScoreAccess}
                />
              )}
              isClearable
              isMulti
              label="Select your contacts"
              name={CONTACTS_FIELD_NAME}
              options={connections}
              placeholder="Select contacts..."
              menuPortalTarget={document.querySelector('body')}
              value={formikProps.values.contacts}
              onChange={formikProps.handleChange}
              filterOption={customOptionsFilter}
            />

            <Field
              component={FormikInput}
              boxType="textarea"
              name={MESSAGE_FIELD_NAME}
              customStyle="h-28"
              label="You can add a message:"
              type="text"
              onKeyDown={handleKeyDown}
              value={formikProps.values.message}
              onChange={formikProps.handleChange}
            />
            <TextCharacterCounter
              textLength={formikProps.values.message.length}
              maxLength={SHORT_TEXTAREA_LENGTH_MAX}
            />

            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="app-button--primary"
                disabled={
                  !formikProps.isValid ||
                  !formikProps.dirty ||
                  formikProps.isSubmitting
                }
              >
                Send
                {formikProps.isSubmitting && (
                  <div className="mr-2 w-2">
                    <LoadingSpinner color="white" />
                  </div>
                )}
              </button>
              <button
                onClick={onClose}
                type="button"
                className="app-button--neutral mr-2"
              >
                Cancel
              </button>
            </div>
          </form>
        </FormikProvider>
      </div>
    </main>
  );
}

export default ShareDealDialog;
