import React from 'react';

function parseRow(row: string, csvColumnDelimiter: string) {
  const cells = [];
  let currentCell = '';
  let quotedMode = false;

  for (let i = 0; i < row.length; i++) {
    const prevChar = i === 0 ? '' : row.charAt(i - 1);
    const currentChar = row.charAt(i);

    if (quotedMode) {
      if (currentChar === '"') {
        quotedMode = false;
      } else {
        currentCell += currentChar;
      }
    } else {
      if (currentChar === csvColumnDelimiter) {
        cells.push(currentCell);
        currentCell = '';
      } else if (currentChar === '"') {
        quotedMode = true;
        if (prevChar === '"') {
          currentCell += '"';
        }
      } else {
        currentCell += currentChar;
      }
    }
  }
  cells.push(currentCell);

  return cells;
}

/**
 * Converts CSV to HTML Table
 */
export function parseCsvToRowsAndColumn(
  csvText: string,
  csvColumnDelimiter = '\t',
  csvRowDelimiter = '\n',
) {
  const rows = csvText.split(csvRowDelimiter);
  if (!rows || rows.length === 0) {
    return [];
  }

  return rows.map((row) => parseRow(row, csvColumnDelimiter));
}

interface CsvToHtmlTableProps {
  renderCell?: (column: string, colIdx: number, rowIdx: number) => any;
  data?: string;
  rowKey?: (row: string, rowIdx: number) => string;
  colKey?: (col: string, colIdx: number, rowIdx: number) => string;
  hasHeader?: boolean;
  csvDelimiter?: string;
  tableClassName?: string;
  tableHeadClassName?: string;
  tableRowClassName?: string;
  tableColumnClassName?: string;
}

const CsvToHtmlTable = ({
  renderCell,
  data = '',
  rowKey = (row, rowIdx) => `row-${rowIdx}`,
  colKey = (col, colIdx, rowIdx) => `col-${colIdx}`,
  hasHeader = true,
  csvDelimiter = '\t',
  tableClassName = 'min-w-full divide-y divide-gray-300 border border-gray-300 border-1',
  tableHeadClassName = 'whitespace-nowrap divide-x divide-gray-200 bg-gray-100',
  tableRowClassName = 'divide-x divide-gray-200 even:bg-gray-50',
  tableColumnClassName = 'whitespace-nowrap p-4 text-sm text-gray-500',
}: CsvToHtmlTableProps) => {
  const rowsWithColumns = parseCsvToRowsAndColumn(data.trim(), csvDelimiter);
  let headerRow = undefined;
  if (hasHeader) {
    headerRow = rowsWithColumns.splice(0, 1)[0];
  }

  const renderTableHeader = (row: any[]) => {
    if (row && row.map) {
      return (
        <thead>
          <tr className={tableHeadClassName}>
            {row.map((column, i) => (
              <th
                className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                key={`header-${i}`}
              >
                {column} ({i})
              </th>
            ))}
          </tr>
        </thead>
      );
    }
  };

  const renderTableBody = (rows: any[]) => {
    if (rows && rows.map) {
      return (
        <tbody className="divide-y divide-gray-200 bg-white">
          {rows.map((row, rowIdx) => (
            <tr
              className={tableRowClassName}
              key={typeof rowKey === 'function' ? rowKey(row, rowIdx) : rowIdx}
            >
              {row.map &&
                row.map((column: any, colIdx: number) => (
                  <td
                    className={tableColumnClassName}
                    key={
                      typeof rowKey === 'function'
                        ? colKey(row, colIdx, rowIdx)
                        : column[colKey as any]
                    }
                  >
                    {typeof renderCell === 'function'
                      ? renderCell(column, colIdx, rowIdx)
                      : column}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      );
    }
  };

  return (
    <table className={`csv-html-table ${tableClassName}`}>
      {headerRow && renderTableHeader(headerRow)}
      {renderTableBody(rowsWithColumns)}
    </table>
  );
};

export default CsvToHtmlTable;
