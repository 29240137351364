import { AuthenticationContextType } from '../contexts/AuthenticationContext';
import { Posthog } from '../middleware/Posthog';
import { isMinDate } from '../util/time';
import {
  getUnconfirmedUserByEmail,
  updateUnconfirmedUser,
} from './UnconfirmedUser';
import { validateToken } from './ValidateToken';
import { parseFlowlieAuthToken } from './jwt';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { PublicLogIn } from '/../libs/shared-types/src/constants/ApiRoutes';
import { EnvironmentTypes } from '/../libs/shared-types/src/constants/EnvironmentTypes';
import { getOnboardingFlow } from '/../libs/shared-types/src/extensions/OnboardingFlowsExtensions';
import { UnconfirmedUser } from '/../libs/shared-types/src/types/model/UnconfirmedUser';
import { UserMetadata } from '/../libs/shared-types/src/types/view/UserMetadata';
import {
  CONFIRM_THROUGH_EMAIL_ERROR,
  GENERIC_LOGIN_ERROR,
  INCORRECT_PASSWORD,
  LOGIN_WITH_GOOGLE,
} from '/src/constants/ErrorMessages';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import { AuthStatus } from '/src/enums/AuthStatus';
import API from '/src/middleware/API';
import { heapIdentify } from '/src/middleware/Heap';
import {
  localStorageSetAccountData,
  localStorageSetAccountTypeAndAuthenticationToken,
  localStorageSetSubscriptionTier,
} from '/src/middleware/LocalStorage';

/**
 * Compares a share token in local storage to a share token in an
 * unconfirmed user, validates them and determines which
 * one should be used, and then syncs that token between local
 * storage and the unconfirmed user in the database.
 */
export async function validateAndSyncShareTokens(
  unconfirmedUser: UnconfirmedUser,
  authenticationContext: AuthenticationContextType
): Promise<AuthStatus> {
  if (
    !authenticationContext.invitation &&
    !unconfirmedUser.invitation?.shareToken
  ) {
    return AuthStatus.COMPLETE_ONBOARDING;
  }

  const parsedContextInvitationToken = await validateToken(
    authenticationContext.invitation?.shareToken
  );
  const parsedUnconfirmedUserInvitationToken = await validateToken(
    unconfirmedUser.invitation?.shareToken
  );

  if (parsedContextInvitationToken) {
    // Update the unconfirmed user with the latest data from context
    await updateUnconfirmedUser(
      unconfirmedUser.email,
      authenticationContext.invitation?.shareToken,
      authenticationContext.unconfirmedAccountType ??
        unconfirmedUser.accountType, // We cannot rely on the setState, so default to whatever value is in the DB
      authenticationContext.invitation?.discoveredFrom,
      authenticationContext.invitation?.message
    );

    return Promise.resolve(AuthStatus.COMPLETE_ONBOARDING);
  }

  if (parsedUnconfirmedUserInvitationToken && unconfirmedUser.invitation) {
    // We are using the data from the unconfirmedUser in the database
    // So nothing to update in Mongo.
    // Instead update the Context with the existing unconfirmed user data
    authenticationContext.setInvitation(unconfirmedUser.invitation);
    authenticationContext.setUnconfirmedAccountType(
      unconfirmedUser.accountType
    );

    return Promise.resolve(AuthStatus.COMPLETE_ONBOARDING);
  }

  if (!parsedContextInvitationToken && !parsedUnconfirmedUserInvitationToken) {
    // If we get here, both invitation token are NOT Valid. So proceed as if the user had publicly signed up (with not invitation)
    // -> Unset any invitedBy data from the unconfirmed user
    // -> Keep the unconfirmed user account type as is in the DB
    await updateUnconfirmedUser(
      unconfirmedUser.email,
      undefined,
      unconfirmedUser.accountType,
      undefined,
      undefined
    );
    return Promise.resolve(AuthStatus.COMPLETE_ONBOARDING);
  }

  throw Error('This account cannot log in. Data may be corrupted');
}

/**
 * Takes user metadata and sets up all the neccesary local
 * storage values when a user logs in, and it also cleans up
 * potentially stale information
 */
export function setupLocalStorageUponLogin(user: UserMetadata): void {
  if (user.token === null || user.accountType === null) {
    throw Error('Invalid user data');
  }

  localStorageSetAccountTypeAndAuthenticationToken(
    user.accountType,
    user.token
  );
  localStorage.setItem(
    LocalStorageKeysConst.LAST_AUTH_RELOAD,
    Date.now().toString()
  );

  localStorageSetAccountData(user.email, user.firstName, user.lastName);

  localStorageSetSubscriptionTier(user.subscriptionTier);
}

export const loginUser = async (
  formValues: any,
  cognitoAuthenticate: (email: string, password: string) => void,
  authenticationContext: AuthenticationContextType
): Promise<AuthStatus> => {
  try {
    if (process.env.REACT_APP_ENV !== EnvironmentTypes.Local) {
      await cognitoAuthenticate(
        formValues[AuthFieldNames.Email],
        formValues[AuthFieldNames.Password]
      );
    }

    const posthogClient = new Posthog();

    const existingCompletedUser = await API.get<UserMetadata>(
      PublicLogIn.buildEndpoint(formValues[AuthFieldNames.Email])
    );

    if (existingCompletedUser) {
      setupLocalStorageUponLogin(existingCompletedUser);

      const parsedToken = parseFlowlieAuthToken(existingCompletedUser.token);
      heapIdentify(parsedToken.userInfo._id);

      posthogClient.identify(formValues[AuthFieldNames.Email]);
      posthogClient.addUserProperties({
        name: `${existingCompletedUser.firstName} ${existingCompletedUser.lastName}`,
        email: existingCompletedUser.email,
        type: existingCompletedUser.accountType,
        invited_by_name:
          existingCompletedUser.unconfirmedUser?.invitation?.fullName,
        company_name: existingCompletedUser.companyName,
        mongoid_onboarded: existingCompletedUser.id,
        mongoid_unconfirmed: existingCompletedUser.unconfirmedUser?._id,
        sub_type:
          existingCompletedUser.accountType === AccountTypes.Startup
            ? AccountTypes.Startup
            : Posthog.getInvestorSubType(existingCompletedUser.investorType),
        onboarding_flow: getOnboardingFlow(
          existingCompletedUser.accountType,
          existingCompletedUser.unconfirmedUser?.invitation?.accountType,
          existingCompletedUser.unconfirmedUser?.invitation?.linkType
        ),
      });
      return await Promise.resolve(AuthStatus.FULL_ACCOUNT);
    }

    const unconfirmedUser = await getUnconfirmedUserByEmail(
      formValues[AuthFieldNames.Email]
    );

    if (!unconfirmedUser) {
      // The user has never signed up before
      // But we will show a generic error message
      throw Error(INCORRECT_PASSWORD);
    }

    if (!isMinDate(unconfirmedUser.completedOn)) {
      throw Error(GENERIC_LOGIN_ERROR);
    }

    posthogClient.identify(unconfirmedUser.email);

    localStorageSetAccountData(
      unconfirmedUser.email,
      unconfirmedUser.firstName,
      unconfirmedUser.lastName
    );

    // We have to check if the invitation token is valid and matches the one in the DB  (if any)
    return await validateAndSyncShareTokens(
      unconfirmedUser,
      authenticationContext
    );
  } catch (error: any) {
    const errorString = JSON.stringify(error.message || error).replaceAll(
      '"',
      ''
    );
    if (errorString === 'User is not confirmed.') {
      return Promise.reject(CONFIRM_THROUGH_EMAIL_ERROR);
    }
    if (errorString === 'Incorrect username or password.') {
      return Promise.reject(INCORRECT_PASSWORD);
    }
    if (errorString.includes('PreAuthentication failed')) {
      return Promise.reject(LOGIN_WITH_GOOGLE);
    }
    return Promise.reject(errorString);
  }
};
