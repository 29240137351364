import React from 'react';
import {
  HiddenFitScore,
  generateFitScoreTag,
} from '/src/util/generateTagCloud';
import { FormatOptionLabelMeta } from 'react-select';
import { FitScoreSelectOption } from '/../libs/shared-types/src/types/SelectOptions';

interface FormatOptionsLabelFitScoreProps {
  option: FitScoreSelectOption;
  formatOptionLabelMeta: FormatOptionLabelMeta<any>;
  hasFitScoreAccess: boolean;
}

// This is used for react select
const FormatOptionsLabelFitScore = ({
  option,
  formatOptionLabelMeta,
  hasFitScoreAccess,
}: FormatOptionsLabelFitScoreProps): JSX.Element => (
  <div className="flex items-center justify-between">
    <div className="flex items-center">
      {option.label}
      {option.role && option.firm && (
        <span className="ml-4 inline-flex pt-1 text-xs font-medium text-gray-700">
          {option.role} at {option.firm}
        </span>
      )}
    </div>
    {option.fitScore.score >= 0 && hasFitScoreAccess && (
      <div className="text-xs">{generateFitScoreTag(option.fitScore)}</div>
    )}
    {!hasFitScoreAccess && formatOptionLabelMeta.context === 'menu' && (
      <HiddenFitScore fixedMatch={80} />
    )}
  </div>
);

export default FormatOptionsLabelFitScore;
