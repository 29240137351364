import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import NumericInput from '../../inputs/NumericInput';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.HasUsers] ? (
    <NumericInput
      label="Weekly Active Users"
      name={StartupFieldNames.WeeklyActiveUserCount}
    />
  ) : (
    <></>
  );
}

const WeeklyActiveUserCountField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.WeeklyActiveUserCount]: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .min(0)
      .when(StartupFieldNames.MonthlyActiveUserCount, {
        is: (userCount: number) => userCount !== undefined && userCount > 0,
        then: (schema) =>
          schema.max(
            yup.ref(StartupFieldNames.MonthlyActiveUserCount),
            'You cannot have more weekly users than monthly users',
          ),
      }),
  }),
};

export default WeeklyActiveUserCountField;
