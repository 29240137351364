import {
  PublicGetStartupDeck,
  SharedGetStartupDeckById,
  VisitorGetStartupDeck,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { PublicProfilePrivacySetting } from '../../../../libs/shared-types/src/constants/PublicProfilePrivacySetting';
import { PublicStartup } from '../../types/view/startup/PublicStartup';
import AuthService from '../AuthService';
export function getDeckFetchUrl(startup: PublicStartup) {
  const id = startup._id;

  if (startup.flowLink.privacySetting === PublicProfilePrivacySetting.Public) {
    // fetch using public
    return PublicGetStartupDeck.buildEndpoint(id);
  }

  if (
    startup.flowLink.privacySetting ===
      PublicProfilePrivacySetting.RequireFlowlieAccount &&
    AuthService.isLoggedIn()
  ) {
    // fetch using Flowlie account
    return SharedGetStartupDeckById.buildEndpoint(id);
  }

  if (
    startup.flowLink.privacySetting ===
      PublicProfilePrivacySetting.RequireNameAndEmail &&
    AuthService.isVisitorSession()
  ) {
    // fetch using visitor session
    return VisitorGetStartupDeck.buildEndpoint();
  }

  if (
    startup.flowLink.privacySetting ===
      PublicProfilePrivacySetting.RequireNameAndEmail &&
    AuthService.isLoggedIn()
  ) {
    // fetch using Flowlie account
    return SharedGetStartupDeckById.buildEndpoint(id);
  }

  return undefined;
}
