import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Stage } from '/../libs/shared-types/src/types/model/Stage';
import { joinClassNames } from '/src/util/formatting/strings';
import { MIN_DATE } from '/../libs/shared-types/src/constants/MinimumDate';

function getStageByName(boardStages: Stage[], stageName: string): Stage {
  const stage = boardStages.find((x) => x.name === stageName);
  if (!stage) {
    // Stage Name not found in the current board stages
    // Board Stages must have been edited
    // We will mock it up as a stage then
    return {
      _id: Math.random().toString(),
      name: stageName,
    };
  }
  return stage;
}

function isFinalStage(boardStages: Stage[], stage: Stage): boolean {
  return boardStages[boardStages.length - 1] === stage;
}

function isFirstStage(boardStages: Stage[], stage: Stage): boolean {
  return boardStages[0] === stage;
}

interface DealDetailActiveStageSelectorProps {
  boardStages: Stage[];
  currentStageName: string;
  handleActiveBoardStageChange: (stageName: string) => void;
  showStageEditor: () => void;
}

function DealDetailActiveStageSelector({
  boardStages,
  currentStageName,
  handleActiveBoardStageChange,
  showStageEditor,
}: DealDetailActiveStageSelectorProps): JSX.Element {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className={joinClassNames(
          // eslint-disable-next-line no-nested-ternary
          isFirstStage(
            boardStages,
            getStageByName(boardStages, currentStageName),
          )
            ? 'bg-slate-300 text-slate-700 hover:bg-slate-400 focus:ring-slate-300'
            : isFinalStage(
                  boardStages,
                  getStageByName(boardStages, currentStageName),
                )
              ? 'bg-green-700 text-white hover:bg-green-800 focus:ring-green-700'
              : 'bg-blue-700 text-white hover:bg-blue-800 focus:ring-blue-700',
          'inline-flex w-full justify-center rounded-md px-2.5 py-1.5 text-xs font-bold tracking-wider shadow-sm',
          'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100',
        )}
      >
        {currentStageName?.toUpperCase()}
        <ChevronUpDownIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-52 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          {boardStages.filter((x) => x.name !== currentStageName).length >
            0 && (
            <div className="py-1">
              {boardStages
                .filter((x) => x.name !== currentStageName)
                .map((stage) => (
                  <Menu.Item
                    key={stage._id}
                    as={Fragment}
                    disabled={stage.name === currentStageName}
                  >
                    {({ active }) => (
                      <button
                        onClick={() => handleActiveBoardStageChange(stage.name)}
                        type="button"
                        key={stage._id}
                        name={stage.name}
                        id={stage._id}
                        className={joinClassNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          stage.name === currentStageName ? 'opacity-75' : '',
                          'group flex w-full items-center px-2 py-1.5',
                        )}
                      >
                        <span
                          className={joinClassNames(
                            // eslint-disable-next-line no-nested-ternary
                            isFirstStage(boardStages, stage)
                              ? 'bg-slate-200 text-slate-700'
                              : isFinalStage(boardStages, stage)
                                ? 'bg-green-100 text-green-800'
                                : 'bg-blue-100 text-blue-800',
                            'inline-flex items-center rounded-sm px-2.5 py-0.5 text-2xs font-semibold tracking-wide',
                          )}
                        >
                          {stage.name.toUpperCase()}
                        </span>
                      </button>
                    )}
                  </Menu.Item>
                ))}
            </div>
          )}
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={joinClassNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex w-full px-3 py-1.5 text-xs',
                  )}
                  onClick={showStageEditor}
                >
                  {boardStages.filter((x) => x.name !== currentStageName)
                    .length === 0
                    ? 'Add Active Stages'
                    : 'Manage Stages'}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default DealDetailActiveStageSelector;
