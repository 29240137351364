import React, { useState } from 'react';
import SearchBar from '/src/components/inputs/SearchBar';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import ImportInvestorDialog from '../../InvestorDatabase/ImportInvestorDialog';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import {
  PaginatedCollectionResponse,
  PaginatedRequestParams,
} from '/../libs/shared-types/src/types/view/APIResponse';
import {
  StartupInvestorPipelineSearchInvestors,
  StartupInvestorListSearchInvestors,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import {
  InvestorFragmentView,
  ComparableInvestorFragmentView,
} from '/../libs/shared-types/src/types/view/InvestorFragmentView';
import { Combobox } from '@headlessui/react';
import {
  formatImageAddress,
  joinClassNames,
} from '/src/util/formatting/strings';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  UsersIcon,
  BriefcaseIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import VerifiedInvestorBadge from '/src/components/Badges/VerifiedInvestorBadge';
import { getColor } from '/src/util/colorLookup';
import { displayMoneyRange } from '/src/util/formatting/numbers';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import { InvestorFragment } from '/../libs/shared-types/src/types/model/InvestorFragment';
import SocialHandles, { SocialHandleName } from '/src/components/SocialHandles';

type AddInvestorDialogProps =
  | {
      onAddInvestor: (
        investorId: string,
        investorDataType: InvestorDataType,
        shouldCloseModal: boolean,
      ) => void;
      addIn: 'pipeline';
      listId?: undefined;
    }
  | {
      onAddInvestor: (
        investorId: string,
        investorDataType: InvestorDataType,
        shouldCloseModal: boolean,
      ) => void;
      addIn: 'targetList';
      listId: string;
    };

function AddInvestorDialog({
  onAddInvestor,
  addIn,
  listId,
}: AddInvestorDialogProps): JSX.Element {
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isCreatingInvestor, setIsCreatingInvestor] = useState(false);
  const [apiResponse, setApiResponse] =
    useState<
      PaginatedCollectionResponse<
        InvestorFragmentView,
        ComparableInvestorFragmentView
      >
    >();
  const [query, setQuery] = useState<string>('');
  const [selectedInvestors, setSelectedInvestors] = useState<
    InvestorFragmentView[]
  >([]);

  // buttton labels
  const addInvestorLabel =
    addIn === 'pipeline' ? 'Add to Pipeline' : 'Add to Target List';
  const createAndAddInvestorLabel =
    addIn === 'pipeline'
      ? 'Create Investor and Add to Pipeline'
      : 'Create Investor and Add to Target List';

  async function fetchInvestors(
    args: Partial<PaginatedRequestParams<ComparableInvestorFragmentView>>,
  ) {
    try {
      setIsSearchLoading(true);
      const requestParams = {
        ...apiResponse,
        ...args,
      };

      let url: string | null = null;

      if (addIn === 'pipeline') {
        url = StartupInvestorPipelineSearchInvestors.buildEndpoint(
          undefined,
          requestParams,
        );
      } else {
        url = StartupInvestorListSearchInvestors.buildEndpoint(
          listId,
          requestParams,
        );
      }

      const data =
        await API.get<
          PaginatedCollectionResponse<
            InvestorFragmentView,
            ComparableInvestorFragmentView
          >
        >(url);

      setApiResponse(data);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsSearchLoading(false);
    }
  }

  async function handleFilter(filter: string) {
    setQuery(filter);
    try {
      await fetchInvestors({ filter, page: 1 });
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  return (
    <div className="bg-white p-4 sm:w-screen sm:max-w-[90rem] sm:p-7">
      <div className="space-y-10 divide-y sm:min-h-[12rem] xl:grid xl:grid-cols-2 xl:gap-x-10 xl:space-y-0 xl:divide-x xl:divide-y-0 xl:divide-solid">
        <section className="col-span-1">
          <h4 className="text-lg font-medium leading-6 text-gray-900">
            Add from Flowlie database or your imported investors
          </h4>
          <p className="text-sm text-gray-600">
            Search investors by name, email, or firm
          </p>

          <Combobox onChange={(investor: InvestorFragmentView) => {}}>
            {({ activeOption }) => (
              <>
                <span className="mt-8 flex items-center">
                  <SearchBar
                    isDebounce
                    placeholder="Search..."
                    onQueryChange={handleFilter}
                  />
                  {isSearchLoading && <LoadingSpinner color="blue" />}
                </span>

                {query !== '' &&
                  apiResponse &&
                  apiResponse.results.length > 0 && (
                    <Combobox.Options
                      as="div"
                      static
                      hold
                      className="flex transform-gpu divide-x divide-gray-100"
                    >
                      <div
                        className={joinClassNames(
                          'min-w-0 flex-auto scroll-py-4 overflow-y-auto px-2 py-4',
                          activeOption ? '' : '',
                        )}
                      >
                        <div className="-mx-2 text-sm text-gray-700">
                          {apiResponse.results
                            .filter((x) => !selectedInvestors.includes(x))
                            .map((investor) => (
                              <Combobox.Option
                                as="div"
                                key={investor._id}
                                value={investor}
                                className={({ active }) =>
                                  joinClassNames(
                                    'flex cursor-default select-none items-center rounded-md p-2',
                                    active ? 'bg-gray-100 text-gray-900' : '',
                                  )
                                }
                              >
                                {({ active }) => (
                                  <>
                                    <UserAvatarWithInitials
                                      containerStyles="size-8"
                                      firstName={investor.firstName}
                                      lastName={investor.lastName}
                                      textStyles="text-base"
                                      imgAlt={
                                        'Profile pic for ' + investor.name
                                      }
                                      imgSrc={formatImageAddress(
                                        investor.profilePicKey,
                                      )}
                                    />
                                    <span className="ml-3 flex flex-auto truncate">
                                      {investor.name}
                                      <span
                                        className={joinClassNames(
                                          'ml-1.5 inline-flex items-center rounded px-1.5 text-2xs font-medium leading-5',
                                          getColor(investor.type).bgColor,
                                          getColor(investor.type).textColor,
                                        )}
                                      >
                                        {investor.type}
                                      </span>
                                    </span>
                                    {active && (
                                      <ChevronRightIcon
                                        className="ml-3 h-5 w-5 flex-none text-gray-400"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                        </div>
                      </div>

                      {activeOption && (
                        <div className="hidden w-[60%] flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                          <div className="flex-none space-y-1 p-6 text-center">
                            <UserAvatarWithInitials
                              containerStyles="size-16 mx-auto"
                              firstName={activeOption.firstName}
                              lastName={activeOption.lastName}
                              textStyles="text-3xl"
                              imgAlt={'Profile pic for ' + activeOption.name}
                              imgSrc={formatImageAddress(
                                activeOption.profilePicKey,
                              )}
                            />
                            <h2 className="mt-3 flex items-center justify-center font-semibold text-gray-900">
                              <span>{activeOption.name}</span>
                              {activeOption.isVerified && (
                                <VerifiedInvestorBadge />
                              )}
                              {activeOption.linkedIn && (
                                <span className="ml-2">
                                  <SocialHandles
                                    socialUrls={[
                                      {
                                        name: SocialHandleName.LinkedIn,
                                        url: activeOption.linkedIn,
                                      },
                                    ]}
                                  />
                                </span>
                              )}
                            </h2>
                            <div className="flex items-center justify-center">
                              <BriefcaseIcon className="h-5 w-5 text-gray-600" />
                              <p className="truncate pl-1 text-sm font-medium text-gray-700">
                                {activeOption.role && activeOption.firm
                                  ? `${activeOption.role} @ ${activeOption.firm}`
                                  : 'Angel Investor'}
                              </p>
                            </div>

                            {activeOption.location && (
                              <div className="flex items-center justify-center">
                                <MapPinIcon className="h-5 w-5 text-gray-600" />
                                <p className="truncate pl-1 text-sm font-medium text-gray-600">
                                  {activeOption.location}
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="flex flex-auto flex-col p-6">
                            <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                              <dt className="col-end-1 font-semibold text-gray-900">
                                Email
                              </dt>
                              <dd>{activeOption.email ?? '-'}</dd>
                              <dt className="col-end-1 font-semibold text-gray-900">
                                Website
                              </dt>
                              <dd className="truncate">
                                {activeOption.website ? (
                                  <a
                                    href={activeOption.website}
                                    className="hyperlink"
                                  >
                                    {activeOption.website}
                                  </a>
                                ) : (
                                  '-'
                                )}
                              </dd>

                              <dt className="col-end-1 font-semibold text-gray-900">
                                Check Size
                              </dt>
                              <dd>
                                {activeOption.checkRangeMin !== undefined &&
                                activeOption.checkRangeMax !== undefined
                                  ? displayMoneyRange(
                                      activeOption.checkRangeMin,
                                      activeOption.checkRangeMax,
                                    )
                                  : '-'}
                              </dd>

                              <dt className="col-end-1 font-semibold text-gray-900">
                                Leads Rounds
                              </dt>
                              <dd>
                                {activeOption.leadsRounds !== undefined
                                  ? activeOption.leadsRounds
                                  : '-'}
                              </dd>
                            </dl>
                            <button
                              type="button"
                              className="app-button--primary mt-6"
                              onClick={() => {
                                setSelectedInvestors([
                                  ...selectedInvestors,
                                  activeOption,
                                ]);
                                onAddInvestor(
                                  activeOption._id,
                                  activeOption.investorDataType,
                                  false,
                                );
                              }}
                            >
                              {addInvestorLabel}
                            </button>
                          </div>
                        </div>
                      )}
                    </Combobox.Options>
                  )}

                {query !== '' && apiResponse?.results.length === 0 && (
                  <div className="px-6 py-14 text-center text-sm sm:px-14">
                    <UsersIcon
                      className="mx-auto h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <p className="mt-4 font-semibold text-gray-900">
                      No investors found
                    </p>
                    <p className="mt-2 text-gray-500">
                      We couldn’t find anything with that term. Please try
                      again.
                    </p>
                  </div>
                )}
              </>
            )}
          </Combobox>
        </section>

        <section className="col-span-1 pt-10 xl:px-10 xl:pt-0">
          {!isCreatingInvestor && (
            <div>
              <h4 className="text-lg font-medium leading-6 text-gray-900">
                Add a non-Flowlie investor
              </h4>
              <p className="text-sm text-gray-600">
                Manually add a new investor who is not in the Flowlie database
              </p>

              <button
                type="button"
                onClick={() => setIsCreatingInvestor(true)}
                className="app-button--primary mt-8"
              >
                {createAndAddInvestorLabel}
              </button>
            </div>
          )}

          {isCreatingInvestor && (
            <div className="-m-7">
              <ImportInvestorDialog
                onCancel={() => setIsCreatingInvestor(false)}
                onSave={(investorFragment: InvestorFragment) =>
                  onAddInvestor(
                    investorFragment._id,
                    InvestorDataType.Fragment,
                    true,
                  )
                }
              />
            </div>
          )}
        </section>
      </div>
    </div>
  );
}

export default AddInvestorDialog;
