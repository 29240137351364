import React from 'react';

interface EmptyStateProps {
  title: string;
  subTitle: string;
  icon: JSX.Element;
  actionButton: JSX.Element;
}

function EmptyState({
  title,
  subTitle,
  icon,
  actionButton,
}: EmptyStateProps): JSX.Element {
  return (
    <div className="w-full rounded-md bg-white py-8 text-center shadow">
      {icon}
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{subTitle}</p>
      <div className="mt-6">{actionButton}</div>
    </div>
  );
}

export default EmptyState;
