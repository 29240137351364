import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import { BOOLEAN_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.HasTermSheet, values) ? (
    <Field
      className="custom-select"
      closeMenuOnSelect
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      component={CustomSelect}
      label="Have you signed a term sheet?"
      name={RoundFieldNames.HasTermSheet}
      options={BOOLEAN_OPTIONS}
      tooltip="A term sheet is a legal agreement between an investor and you that defines the terms of the round for that investor and all other participants"
      secondaryLabel="You would normally sign a term sheet only if you have a lead investor"
    />
  ) : (
    <></>
  );
}

const HasTermSheetField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.HasTermSheet]: yup
      .string()
      .nullable()
      .when(RoundFieldNames.RoundType, {
        is: (roundType: string) => roundType !== RoundTypes.ToBeDetermined,
        then: (schema) => schema.required('Required'),
      }),
  }),
};

export default HasTermSheetField;
