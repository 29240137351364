import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { createFilter } from 'react-select';
import { MAX_UNIVERSITY_AFFILIATIONS } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import UNIVERSITY_AFFILIATION_OPTIONS from '/../libs/shared-types/src/constants/SelectOptions/UniversityOptions';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';
import OptimizedOption from '../../inputs/ReactSelectAdditions/OptimizedOption';

const UniversityAffiliationsField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      classNamePrefix="optimized-select"
      component={CustomSelect}
      components={{
        Option: OptimizedOption,
        Menu: LimitedMenu,
      }}
      filterOption={createFilter({ ignoreAccents: false })}
      isClearable
      isMulti
      label="Universities Attended"
      maxCount={MAX_UNIVERSITY_AFFILIATIONS}
      name={InvestorFieldNames.UniversityAffiliations}
      options={UNIVERSITY_AFFILIATION_OPTIONS}
      placeholder="Search universities..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.UniversityAffiliations]: yup
      .array()
      .of(yup.string())
      .nullable()
      .max(
        MAX_UNIVERSITY_AFFILIATIONS,
        `At most ${MAX_UNIVERSITY_AFFILIATIONS} university affiliations are allowed`,
      ),
  }),
};

export default UniversityAffiliationsField;
