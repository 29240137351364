import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import websiteSchema from '/src/constants/validation/websiteSchema';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';

const ProductDemoVideoField: FormField = {
  fieldComponent: (
    <Field
      component={FormikInput}
      label="Link your product demo video"
      secondaryLabel="Anything that shows investors how your product works. It should be less than 3 minutes."
      name={StartupFieldNames.ProductDemoVideoUrl}
      type="text"
    />
  ),
  validation: yup.object({
    [StartupFieldNames.ProductDemoVideoUrl]: websiteSchema(false),
  }),
};

export default ProductDemoVideoField;
