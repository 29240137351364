import React from 'react';
import { SelectOption } from '/../libs/shared-types/src/types/SelectOptions';
import { getStars } from '/src/routes/investor/DealDetail/StarRatingSelect';

// This is used for react select
function formatStarRatingOptionLabel({
  label,
  value,
}: SelectOption): JSX.Element {
  return (
    <div className="flex items-center space-x-3">
      <span className="flex flex-row space-x-0.5 truncate text-xs font-normal">
        {Number(value) > 0 ? (
          getStars(Number(value))
        ) : (
          <span className="text-sm">No rating</span>
        )}
      </span>
    </div>
  );
}

export default formatStarRatingOptionLabel;
