import React, { useContext, useEffect, useState } from 'react';
import {
  LocalStorageAccountData,
  localStorageGetAccountData,
} from '/src/middleware/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { LinkShareMetadata } from '/../libs/shared-types/src/types/view/LinkShareMetadata';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import Logger from '/src/services/logger';
import {
  formatImageAddress,
  joinClassNames,
} from '/src/util/formatting/strings';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import DebugRender from '/src/components/utility/DebugRender';
import AccountChoiceMessage from './AccountChoiceMessage';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import { getLinkShareMetadata } from '/src/services/LinkShareMetadata';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';
import { getUnconfirmedUserByEmail } from '/src/services/UnconfirmedUser';
import { LOGIN_ROUTE } from '/src/constants/Routes';
import SquaredLogo from '/src/components/SquaredLogo';

function AccountChoiceRoute(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [onboardingAccount, setOnboardingAccount] =
    useState<LocalStorageAccountData>();
  const {
    unconfirmedAccountType,
    setUnconfirmedAccountType,
    invitation,
    setInvitation,
  } = useContext(AuthenticationContext);
  const [linkShareMetadata, setLinkShareMetadata] =
    useState<LinkShareMetadata>();
  const navigate = useNavigate();

  async function fetchLinkOriginator() {
    try {
      if (!invitation) {
        return;
      }
      const { shareToken, linkType } = invitation;
      const user = await getLinkShareMetadata(shareToken, linkType);
      setLinkShareMetadata(user);
    } catch (error: any) {
      Logger.error(error);
      // The invitation shareToken must have expired, so unset the invitation
      setInvitation(undefined);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchUnconfirmedUser() {
    const accountData = localStorageGetAccountData();
    setOnboardingAccount(accountData);

    const unconfirmedUser = await getUnconfirmedUserByEmail(
      accountData.accountEmail,
    );

    if (!unconfirmedUser) {
      navigate(LOGIN_ROUTE);
      return;
    }

    setUnconfirmedAccountType(unconfirmedUser.accountType);

    if (unconfirmedUser.invitation) {
      setInvitation(unconfirmedUser.invitation);
    } else {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchLinkOriginator();
  }, [invitation]);

  useEffect(() => {
    setIsLoading(true);

    try {
      fetchUnconfirmedUser();
    } catch (error: any) {
      Logger.error('Choice route error:', error.message);
      navigate('/');
    }
  }, []);

  if (isLoading) {
    return <PageLoadingSpinner />;
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center sm:bg-gray-100">
      <DebugRender className="px-4 text-2xs">
        <pre>
          onBoardingAccount: <br /> {JSON.stringify(onboardingAccount, null, 2)}
          <br />
          unconfirmedAccountType:{' '}
          {JSON.stringify(unconfirmedAccountType, null, 2)}
          <br />
          invitation: <br /> {JSON.stringify(invitation, null, 2)}
        </pre>
        <span className="flex justify-between">
          <pre>
            <b>{unconfirmedAccountType}</b>
            &nbsp;Signing up via <b>{invitation?.linkType}</b>
          </pre>
          <pre>(shareToken: {invitation?.shareToken})</pre>
        </span>
      </DebugRender>

      {!isLoading && (
        <main className="m-auto max-w-7xl rounded-lg bg-white px-3 py-12 text-center sm:p-12 sm:shadow lg:w-2/3 xl:w-1/2">
          <section className="relative mb-8 flex">
            <div
              className={joinClassNames(
                'absolute -top-20 left-1/2 flex -translate-x-1/2 transform items-center justify-center shadow-xl',
                linkShareMetadata?.startup &&
                  invitation?.linkType === LinkTypes.Flowlink
                  ? 'rounded-md'
                  : 'rounded-full',
              )}
            >
              <figure className="relative">
                {linkShareMetadata?.investor &&
                  invitation?.linkType === LinkTypes.Flowlink && (
                    <>
                      <UserAvatarWithInitials
                        containerStyles="h-20 w-20"
                        firstName={linkShareMetadata.investor.firstName}
                        lastName={linkShareMetadata.investor.lastName}
                        textStyles="text-3xl"
                        imgAlt="Investor Profile"
                        imgSrc={formatImageAddress(
                          linkShareMetadata?.investor.profilePicKey,
                        )}
                      />
                      <span
                        className="absolute inset-0 rounded-full shadow-inner"
                        aria-hidden="true"
                      />
                    </>
                  )}

                {linkShareMetadata?.startup &&
                  invitation?.linkType === LinkTypes.Flowlink && (
                    <SquaredLogo
                      containerClassName="size-20"
                      src={formatImageAddress(
                        linkShareMetadata.startup.logoKey,
                      )}
                      alt={`${linkShareMetadata.startup.name} logo`}
                    />
                  )}

                {linkShareMetadata?.investor &&
                  invitation?.linkType === LinkTypes.Dealshare &&
                  linkShareMetadata?.startup && (
                    <div className="flex flex-row">
                      <UserAvatarWithInitials
                        containerStyles="h-20 w-20"
                        firstName={linkShareMetadata.investor.firstName}
                        lastName={linkShareMetadata.investor.lastName}
                        textStyles="text-3xl"
                        imgAlt="Investor Profile"
                        imgSrc={formatImageAddress(
                          linkShareMetadata?.investor.profilePicKey,
                        )}
                      />
                      <SquaredLogo
                        containerClassName="size-20 my-1 mx-2"
                        src={formatImageAddress(
                          linkShareMetadata.startup.logoKey,
                        )}
                        alt={`${linkShareMetadata.startup.name} logo`}
                      />
                    </div>
                  )}
              </figure>
            </div>
          </section>
          {onboardingAccount && (
            <AccountChoiceMessage
              linkShareMetadata={linkShareMetadata}
              onboardingAccount={onboardingAccount}
              shareToken={invitation?.shareToken}
              linkType={invitation?.linkType}
            />
          )}
        </main>
      )}
    </div>
  );
}

export default AccountChoiceRoute;
