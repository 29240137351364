import { getPluralSuffix } from '/src/util/formatting/numbers';
import React from 'react';

interface PageHeaderProps {
  itemsCount?: number;
  itemLabel?: string;
  title: string;
  sideContent?: JSX.Element;
}

const PageHeader = ({
  title,
  itemsCount = 0,
  itemLabel = '',
  sideContent,
}: PageHeaderProps): JSX.Element => (
  <>
    <h2 className="mt-3 flex items-center text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white lg:my-6">
      {title}
      {itemsCount > 0 && (
        <span className="ml-3 inline-flex items-center rounded-md bg-blue-100 px-2.5 py-0.5 text-sm font-bold text-blue-800">
          {itemsCount} {itemLabel}
          {getPluralSuffix(itemsCount)}
        </span>
      )}
      {/* put the side content on the left side */}
      <span className="px-2">{sideContent}</span>
    </h2>
  </>
);

export default PageHeader;
