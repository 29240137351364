import React from 'react';
import * as yup from 'yup';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import { validateNumeric } from '/src/components/utility/Validators';
import FormField from '/src/interfaces/FormField';
import MoneyInput from '../../inputs/MoneyInput';

const LifeTimeValueField: FormField = {
  fieldComponent: (
    <MoneyInput
      name={StartupFieldNames.LifeTimeValue}
      label="Life Time Value"
      tooltip="Prediction of the total revenue attributed to the entire future relationship with an average customer."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.LifeTimeValue]: validateNumeric(
      'RECURRING_MONETARY_MAX'
    ),
  }),
};

export default LifeTimeValueField;
