import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import { enumToTooltipSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import {
  SafeType,
  getSafeTypeTooltip,
} from '/../libs/shared-types/src/constants/SafeType';
import { FormatOptionLabelMeta } from 'react-select';
import { TooltipSelectOption } from '../../../../types/SelectOptions';
import FormatOptionWithTooltipLabel from '/src/components/inputs/ReactSelectAdditions/formatOptionWithTooltipLabel';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.SafeType, values) ? (
    <Field
      className="custom-select"
      closeMenuOnSelect
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      component={CustomSelect}
      formatOptionLabel={(
        option: TooltipSelectOption,
        formatOptionLabelMeta: FormatOptionLabelMeta<any>,
      ) => (
        <FormatOptionWithTooltipLabel
          option={option}
          formatOptionLabelMeta={formatOptionLabelMeta}
        />
      )}
      label="What type of SAFE do you plan to use?"
      secondaryLabel="Over 90% of SAFE rounds are done on a Capped SAFE"
      name={RoundFieldNames.SafeType}
      options={enumToTooltipSelectOptions(SafeType, getSafeTypeTooltip)}
      placeholder="Select..."
    />
  ) : (
    <></>
  );
}

const SafeTypeField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.SafeType]: yup
      .string()
      .nullable()
      .when(RoundFieldNames.RoundType, {
        is: (roundType: string) => roundType === RoundTypes.Safe,
        then: (schema) => schema.required('Required'),
      }),
  }),
};

export default SafeTypeField;
