export const PROFESSIONAL_ACCREDITATIONS_OPTIONS = [
  { value: 'CAIA', label: 'CAIA' },
  { value: 'CFA', label: 'CFA' },
  { value: 'CFE', label: 'CFE' },
  { value: 'CFP', label: 'CFP' },
  { value: 'CIA', label: 'CIA' },
  { value: 'CIIA', label: 'CIIA' },
  { value: 'CIMA', label: 'CIMA' },
  { value: 'CMA', label: 'CMA' },
  { value: 'CPA', label: 'CPA' },
  { value: 'CPWA', label: 'CPWA' },
  { value: 'CTP', label: 'CTP' },
  { value: 'FRM', label: 'FRM' },
  { value: 'JD', label: 'JD' },
  { value: 'MD', label: 'MD' },
  { value: 'Series 22', label: 'Series 22' },
  { value: 'Series 4', label: 'Series 4' },
  { value: 'Series 57', label: 'Series 57' },
  { value: 'Series 6', label: 'Series 6' },
  { value: 'Series 63', label: 'Series 63' },
  { value: 'Series 65', label: 'Series 65' },
  { value: 'Series 66', label: 'Series 66' },
  { value: 'Series 7', label: 'Series 7' },
  { value: 'Series 79', label: 'Series 79' },
  { value: 'Series 82', label: 'Series 82' },
  { value: 'Series 9/10', label: 'Series 9/10' },
];
