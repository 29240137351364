import React from 'react';
import {
  formatImageAddress,
  splitOnWhiteSpace,
} from '/src/util/formatting/strings';
import { getColor } from '/src/util/colorLookup';
import DateString from '/src/components/utility/DateString';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import { AggregatedInvestorReferral } from '/../libs/shared-types/src/types/view/APIResponse';

interface ReferredInvestorTableRowProps {
  referral: AggregatedInvestorReferral;
}

function ReferredInvestorTableRow({
  referral,
}: ReferredInvestorTableRowProps): JSX.Element {
  return (
    <tr>
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <UserAvatarWithInitials
              containerStyles="h-10 w-10"
              firstName={splitOnWhiteSpace(referral.name)[0]}
              lastName={splitOnWhiteSpace(referral.name)[1]}
              textStyles="text-xl"
              imgAlt="Investor Profile"
              imgSrc={formatImageAddress(referral.profilePicKey)}
            />
          </div>
          <div className="ml-2">
            <div className="app-table-row-text--bold">{referral.name}</div>
            <div className="app-table-row-text--light">{referral.email}</div>
          </div>
        </div>
      </td>
      <td className="app-table-row-item">
        <span
          className={`app-tag ${getColor(referral.type).bgColor} ${
            getColor(referral.type).textColor
          }`}
        >
          {referral.type}
        </span>
      </td>
      <td className="app-table-row-item">
        {referral.firm && referral.role ? (
          <>
            <div className="app-table-row-text">{referral.firm}</div>
            <div className="app-table-row-text--light">{referral.role}</div>
          </>
        ) : (
          <div className="app-table-row-text">-</div>
        )}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          <DateString date={referral.joinedOn} />
        </div>
      </td>
    </tr>
  );
}

export default ReferredInvestorTableRow;
