import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import { STARTUP_GEO_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/StartupGeographyOptions';
import formatGroupLabel from '/src/components/inputs/ReactSelectAdditions/selectFormatGroupLabel';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

const GeographyField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      groupLabelFormat={formatGroupLabel}
      component={CustomSelect}
      label="Where is your headquarters?"
      name={StartupFieldNames.Geography}
      options={STARTUP_GEO_OPTIONS}
      placeholder="Select geography..."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.Geography]: yup.string().required('Required'),
  }),
};

export default GeographyField;
