import React from 'react';

import { ActivationTaskView } from '/../libs/shared-types/src/types/view/ActivationTaskView';
import ActivationTaskAction from '../../../constants/ActivationTaskMaps/ActivationTasksMap';
import { formatDate } from '/src/util/formatting/dates';

function ActivationChecklistItem({
  completedOn,
  name,
  id,
  isCompleted,
}: ActivationTaskView): JSX.Element {
  return (
    <div
      className="relative flex items-start font-medium text-gray-700 dark:text-gray-200"
      title={isCompleted ? `Completed on: ${formatDate(completedOn)}` : ''}
    >
      <div className="flex h-5 items-center">
        <input
          id={`activation task: ${name}`}
          aria-describedby="task-description"
          name={name}
          type="checkbox"
          checked={isCompleted}
          readOnly
          className="h-4 w-4 rounded border-gray-300 text-blue-600"
        />
      </div>

      <ActivationTaskAction taskId={id} isCompleted={isCompleted} />
    </div>
  );
}

interface InvestorActivationTasksProps {
  tasks: ActivationTaskView[];
}

function InvestorActivationTasks({
  tasks,
}: InvestorActivationTasksProps): JSX.Element {
  const isAllTasksCompleted = tasks.every((x) => x.isCompleted);
  if (isAllTasksCompleted) {
    return <></>;
  }

  return (
    <section className="mt-3 border-t border-gray-200 px-4 dark:border-gray-800">
      <h3 className="pt-4 pb-3 text-sm font-medium text-gray-700 dark:text-gray-100">
        Get the most out of Flowlie!
      </h3>
      <div
        className="space-y-2"
        role="group"
        aria-labelledby="projects-headline"
      >
        {tasks.map((item) => (
          <ActivationChecklistItem
            redirectLink={item.redirectLink}
            key={item.id}
            id={item.id}
            name={item.name}
            completedOn={item.completedOn}
            isCompleted={item.isCompleted}
          />
        ))}
      </div>
    </section>
  );
}

export default InvestorActivationTasks;
