import InvestorFieldNames from '/src/enums/InvestorFieldNames';

const emptyInvestor = {
  [InvestorFieldNames.AngelList]: '',
  [InvestorFieldNames.AntiInvIndustries]: '',
  [InvestorFieldNames.CheckRangeMax]: '',
  [InvestorFieldNames.CheckRangeMin]: '',
  [InvestorFieldNames.Email]: '',
  [InvestorFieldNames.ExpFunctional]: '',
  [InvestorFieldNames.ExpIndustry]: '',
  [InvestorFieldNames.ExpInvestingYears]: '',
  [InvestorFieldNames.Firm]: '',
  [InvestorFieldNames.HasInitialOwnershipTarget]: '',
  [InvestorFieldNames.HighestEducationLevel]: '',
  [InvestorFieldNames.InitialOwnershipTargetMax]: '',
  [InvestorFieldNames.InitialOwnershipTargetMin]: '',
  [InvestorFieldNames.IntroBio]: '',
  [InvestorFieldNames.InvBusinessModels]: '',
  [InvestorFieldNames.InvGeographies]: '',
  [InvestorFieldNames.InvImpacts]: '',
  [InvestorFieldNames.InvIndustries]: '',
  [InvestorFieldNames.InvProductCategories]: '',
  [InvestorFieldNames.InvProductStage]: '',
  [InvestorFieldNames.InvStages]: '',
  [InvestorFieldNames.InvStagesFollowOn]: '',
  [InvestorFieldNames.InvTypes]: '',
  [InvestorFieldNames.IsAccredited]: '',
  [InvestorFieldNames.IsIncludedInInvestorDbForStartups]: '',
  [InvestorFieldNames.IsOpenToColdInbound]: '',
  [InvestorFieldNames.LeadsRounds]: '',
  [InvestorFieldNames.LinkedIn]: '',
  [InvestorFieldNames.Location]: '',
  [InvestorFieldNames.Medium]: '',
  [InvestorFieldNames.Nationalities]: '',
  [InvestorFieldNames.OtherUrl]: '',
  [InvestorFieldNames.Phone]: '',
  [InvestorFieldNames.PreferenceContact]: '',
  [InvestorFieldNames.PreferenceFollowUp]: '',
  [InvestorFieldNames.ProfessionalAccreditations]: '',
  [InvestorFieldNames.ProfilePic]: undefined,
  [InvestorFieldNames.ProfilePicKey]: '',
  [InvestorFieldNames.Pronouns]: '',
  [InvestorFieldNames.Role]: '',
  [InvestorFieldNames.Substack]: '',
  [InvestorFieldNames.TakesBoardSeats]: '',
  [InvestorFieldNames.TaxState]: '',
  [InvestorFieldNames.Twitter]: '',
  [InvestorFieldNames.Type]: '',
  [InvestorFieldNames.UniversityAffiliations]: '',
  [InvestorFieldNames.Website]: '',
  [InvestorFieldNames.YearlyAvgInvCount]: '',
  profilePicMustValidate: true, // Used as flag to validate file
};

export default emptyInvestor;
