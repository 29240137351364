import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LinkShareMetadata } from '/../libs/shared-types/src/types/view/LinkShareMetadata';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import { PublicInvestor } from '/../libs/shared-types/src/types/view/investor/PublicInvestor';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { OnboardingFlows } from '/../libs/shared-types/src/constants/OnboardingFlows';
import { getOnboardingFlow } from '/../libs/shared-types/src/extensions/OnboardingFlowsExtensions';
import { LocalStorageAccountData } from '/src/middleware/LocalStorage';
import { updateUnconfirmedUser } from '/src/services/UnconfirmedUser';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';
import { COMPLETE_PROFILE_ROUTE } from '/src/constants/Routes';

function getInvestorFirmOrType(investor: PublicInvestor | undefined) {
  if (!investor) {
    return '';
  }
  return investor.type === InvestorTypes.Angel
    ? '(Angel)'
    : `(${investor.firm})`;
}

interface TargetedMessage {
  header: string;
  body: string;
  callToAction: string;
  disclaimer: string;
}

/**
 *
 * @param linkShareMetadata The data about the account that is inviting the new user
 * @param onboardingAccount The data about the account that is onboarding
 * @param linkType The type of link the new user signed up from
 * @returns An object with the strings to display in the first step of the onboarding form
 */
function getTargetedMessage(
  onboardingAccount: LocalStorageAccountData,
  unconfirmedAccountType: AccountTypes | undefined,
  linkShareMetadata?: LinkShareMetadata,
  linkType?: LinkTypes
): TargetedMessage {
  let header = '';
  let body = '';
  let callToAction = '';
  let disclaimer = '';

  let invitedByAccountType = undefined;
  if (linkShareMetadata?.investor !== undefined) {
    invitedByAccountType = AccountTypes.Investor;
  } else if (linkShareMetadata?.startup !== undefined) {
    invitedByAccountType = AccountTypes.Startup;
  }

  const onboardingFlow = getOnboardingFlow(
    unconfirmedAccountType ?? '',
    invitedByAccountType,
    linkType
  );

  switch (onboardingFlow) {
    case OnboardingFlows.InvestorViaDealShareLink:
      header = `Hi ${onboardingAccount.accountFirstName}!`;
      body = `Please take a few minutes to fill out this form so we can connect and you can get full access to ${linkShareMetadata?.startup?.name}.`;
      callToAction =
        'Once you create your profile, you will be able to collaborate with me and also use Flowlie as your customized deal intake form and AI-enhanced pipeline.';
      disclaimer = `Your information will be shared with ${
        linkShareMetadata?.investor?.firstName
      } ${getInvestorFirmOrType(
        linkShareMetadata?.investor
      )} and, if your request is accepted, with ${
        linkShareMetadata?.startup?.accountFirstName
      } the founder of ${linkShareMetadata?.startup?.name}`;
      break;

    case OnboardingFlows.InvestorViaInvestorFlowLink:
      header = `Hi ${onboardingAccount.accountFirstName}!`;
      body =
        'Please take a few minutes to fill out this form so we can connect and share deals.';
      callToAction =
        'Once you create your profile, you will be able to collaborate with me and also use Flowlie as your customized deal intake form and AI-enhanced pipeline.';
      disclaimer = `Your information will be shared with ${
        linkShareMetadata?.investor?.firstName
      } ${getInvestorFirmOrType(linkShareMetadata?.investor)}`;
      break;

    case OnboardingFlows.InvestorViaSignup:
      header = `Hi ${onboardingAccount.accountFirstName}, we just need some basic information on your investment preferences.`;
      body =
        'Once you create your profile, you will be able to automatically collect decks as well as screen, track, and share deals.';
      callToAction =
        'We’ll filter your dealflow based on your investment preferences and thesis, and surface the deals that are the best fit for you and your network.';
      break;

    case OnboardingFlows.InvestorViaStartupFlowLink:
      header = `You just hit the jackpot ${onboardingAccount.accountFirstName}!`;
      body = `Once you create your profile, you will be able to see all of ${linkShareMetadata?.startup?.name}’s information and use Flowlie as your customized deal intake form and AI-enhanced pipeline.`;
      callToAction =
        'Collect and track round details, company information, and financial data from founders in collaboration with your co-investment network.';
      disclaimer = `Your information will be shared with ${linkShareMetadata?.startup?.accountFirstName}, founder of ${linkShareMetadata?.startup?.name}`;
      break;

    case OnboardingFlows.StartupViaDealShareLink:
      header = `You just hit the jackpot ${onboardingAccount.accountFirstName}!`;
      body = `Founders like ${linkShareMetadata?.startup?.accountFirstName} use Flowlie to manage their entire fundraising process end-to-end.`;
      callToAction =
        'Upload your deck and create your company one-pager, then share your information with potential investors, track round progress, communicate updates, and discover new investors.';
      break;

    case OnboardingFlows.StartupViaInvestorFlowLink:
      header = `Hi ${onboardingAccount.accountFirstName}!`;
      body =
        'Please take a few minutes to fill out this form so I can review your deck and company details.';
      callToAction =
        'Once you submit your information, you can share your company one-pager with other potential investors, track round progress, communicate updates, and discover new investors.';
      disclaimer = `Your information will be shared with ${
        linkShareMetadata?.investor?.firstName
      } ${getInvestorFirmOrType(linkShareMetadata?.investor)}`;
      break;

    case OnboardingFlows.StartupViaSignup:
      header = `Hi ${onboardingAccount.accountFirstName}, we just need some basic information (you can edit this later)`;
      body =
        'We’ll ask a few questions to create your company one-pager and get you set up with the Fundraising Hub.';
      callToAction =
        'From there, you’ll be able to further plan your round, create investment materials, share them with investors, and manage your fundraise.';
      disclaimer = `Your one-pager and deck will stay private until you decide to share them with others`;
      break;

    case OnboardingFlows.StartupViaStartupFlowLink:
      header = `You just hit the jackpot ${onboardingAccount.accountFirstName}!`;
      body = `Founders like ${linkShareMetadata?.startup?.accountFirstName} use Flowlie to manage their entire fundraising process end-to-end.`;
      callToAction =
        'We’ll ask a few questions to create your company one-pager and get you set up with the Fundraising Hub. Then, you’ll be able to further plan your round, create investment materials, share them with investors, and manage your fundraise.';
      break;

    case OnboardingFlows.UnknownViaDealShareLink:
      header = `Hi ${onboardingAccount.accountFirstName}!`;
      body = `Excited to share more about ${linkShareMetadata?.startup?.name} with you.`;
      callToAction = 'What best describes you?';
      break;

    case OnboardingFlows.UnknownViaInvestorFlowLink:
      header = `Hi ${onboardingAccount.accountFirstName}!`;
      body = 'Excited to connect with you.';
      callToAction = 'What best describes you?';
      break;

    case OnboardingFlows.UnknownViaSignup:
      header = `Hi ${onboardingAccount.accountFirstName}!`;
      body = `Welcome to Flowlie.`;
      callToAction = 'What best describes you?';
      disclaimer = `Your profile will stay private unless you decide to share it with others`;
      break;

    case OnboardingFlows.UnknownViaStartupFlowLink:
      header = `Hi ${onboardingAccount.accountFirstName}!`;
      body = `Excited to share more about ${linkShareMetadata?.startup?.name} with you.`;
      callToAction = 'What best describes you?';
      break;

    default:
      break;
  }

  return {
    header,
    body,
    callToAction,
    disclaimer,
  };
}

interface AccountChoiceMessageProps {
  linkShareMetadata?: LinkShareMetadata;
  onboardingAccount: LocalStorageAccountData;
  shareToken?: string;
  linkType?: LinkTypes;
}

function AccountChoiceMessage({
  linkShareMetadata,
  onboardingAccount,
  shareToken,
  linkType,
}: AccountChoiceMessageProps): JSX.Element {
  const { unconfirmedAccountType, setUnconfirmedAccountType, invitation } =
    useContext(AuthenticationContext);
  const [content, setContent] = useState<TargetedMessage>(
    getTargetedMessage(
      onboardingAccount,
      unconfirmedAccountType,
      linkShareMetadata,
      linkType
    )
  );

  async function updateUnconfirmedUserAccountType(
    newUnconfirmedAccountType: AccountTypes
  ) {
    await updateUnconfirmedUser(
      onboardingAccount.accountEmail,
      shareToken,
      newUnconfirmedAccountType,
      invitation?.discoveredFrom,
      invitation?.message
    );

    setUnconfirmedAccountType(newUnconfirmedAccountType);
  }

  useEffect(() => {
    setContent(
      getTargetedMessage(
        onboardingAccount,
        unconfirmedAccountType,
        linkShareMetadata,
        linkType
      )
    );
  }, [unconfirmedAccountType, invitation, linkShareMetadata, linkType]);

  return (
    <>
      <h2 className="login-signup-header text-2xl">{content.header}</h2>
      <p className="text-xl font-semibold text-gray-700">{content.body}</p>
      <p className="mt-6 text-xl text-gray-700">{content.callToAction}</p>
      <section className="my-10 flex flex-col space-y-8">
        {unconfirmedAccountType === AccountTypes.Startup && (
          <>
            <Link
              className="self-center"
              to={`${COMPLETE_PROFILE_ROUTE}/startup`}
            >
              <button type="button" className="button">
                Continue
              </button>
            </Link>
            <button
              type="button"
              className="hyperlink self-center"
              onClick={() => setUnconfirmedAccountType(undefined)}
            >
              Not a Founder? Switch account type
            </button>
          </>
        )}
        {unconfirmedAccountType === AccountTypes.Investor && (
          <>
            <Link
              className="self-center"
              to={`${COMPLETE_PROFILE_ROUTE}/investor`}
            >
              <button type="button" className="button">
                Continue
              </button>
            </Link>
            <button
              type="button"
              className="hyperlink self-center"
              onClick={() => setUnconfirmedAccountType(undefined)}
            >
              Not an Investor? Switch account type
            </button>
          </>
        )}
        {!unconfirmedAccountType && (
          <>
            <button
              type="button"
              className="button self-center"
              onClick={async () => {
                await updateUnconfirmedUserAccountType(AccountTypes.Startup);
              }}
            >
              I am a Founder
            </button>
            <button
              type="button"
              className="button self-center"
              onClick={async () => {
                await updateUnconfirmedUserAccountType(AccountTypes.Investor);
              }}
            >
              I am an Investor
            </button>
          </>
        )}
      </section>
      <p className="text-center text-sm text-gray-600">{content.disclaimer}</p>
    </>
  );
}

export default AccountChoiceMessage;
