import React, { useEffect, useState } from 'react';
import DeckVersionHistory, {
  defaultDeckVersionHistorySortKey,
} from './DeckVersionHistory';
import DeckForm from './DeckForm';
import {
  ComparableDeckVersionView,
  DeckVersionHistoryFieldFilters,
  DeckVersionHistoryPaginatedCollectionResponse,
} from '/../libs/shared-types/src/types/view/DeckVersionView';
import { PaginatedRequestParams } from '../../../types/view/APIResponse';
import { StartupGetDeckVersionHistory } from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import usePaginatedUrlsParams from '/src/hooks/usePaginatedUrlParams';
import { SortOrder } from '/src/interfaces/Sortable';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';

function DeckManagerRoute(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [stageOptions, setStageOptions] = useState<string[]>([]);
  const [deckVersionData, setDeckVersionData] =
    useState<DeckVersionHistoryPaginatedCollectionResponse>();

  const {
    page,
    perPage,
    sortOrder,
    sortKey,
    filter,
    fieldFilters,
    setFieldFilters,
  } = usePaginatedUrlsParams();

  async function fetchDeckVersionHistory(
    args: Partial<
      PaginatedRequestParams<
        ComparableDeckVersionView,
        DeckVersionHistoryFieldFilters
      >
    >,
  ) {
    try {
      setIsSearchLoading(true);
      const requestParams = {
        sortKey: defaultDeckVersionHistorySortKey,
        ...deckVersionData,
        ...args,
      };

      const url = StartupGetDeckVersionHistory.buildEndpoint(
        undefined,
        requestParams,
      );

      const data =
        await API.get<DeckVersionHistoryPaginatedCollectionResponse>(url);
      setFieldFilters(data.fieldFilters);
      setDeckVersionData(data);
      setStageOptions(data.startupRoundOptions);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsSearchLoading(false);
    }
  }

  async function handleFilter(filter: string) {
    try {
      await fetchDeckVersionHistory({ filter, page: 1 });
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function handleFilterByRoundStage(roundStage: string) {
    try {
      await fetchDeckVersionHistory({
        fieldFilters: { roundStage },
        page: 1,
      });
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function loadInitialDeckVersions() {
    try {
      setIsLoading(true);
      await fetchDeckVersionHistory({
        page,
        perPage,
        sortKey: (sortKey ??
          defaultDeckVersionHistorySortKey) as keyof ComparableDeckVersionView,
        sortOrder: !sortOrder ? SortOrder.Desc : sortOrder,
        filter,
      });
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadInitialDeckVersions();
  }, []);

  if (isLoading) {
    return <PageLoadingSpinner message="Loading all your decks... 🙌" />;
  }

  if (!deckVersionData) {
    return <></>;
  }

  return (
    <main>
      <header className="sticky top-0 z-20 bg-gray-100 pb-0">
        <div className="relative flex max-w-full items-center justify-between">
          <h3 className="page-title">Manage Pitch Deck</h3>
        </div>
      </header>

      <DeckForm
        onDeckAdded={() =>
          fetchDeckVersionHistory({
            page,
            perPage,
            sortKey: (sortKey ??
              defaultDeckVersionHistorySortKey) as keyof ComparableDeckVersionView,
            sortOrder: !sortOrder ? SortOrder.Desc : sortOrder,
            filter,
          })
        }
      />

      <section className="mb-8 rounded-lg">
        <DeckVersionHistory
          apiResponse={deckVersionData}
          isSearchLoading={isSearchLoading}
          stageOptions={stageOptions}
          handleFilter={handleFilter}
          handleFilterByRoundStage={handleFilterByRoundStage}
          fieldFilters={fieldFilters}
          fetchData={fetchDeckVersionHistory}
        />
      </section>
    </main>
  );
}

export default DeckManagerRoute;
