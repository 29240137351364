import React from 'react';

import {
  abbreviateNumber,
  numberWithCommas,
} from '/src/util/formatting/numbers';
import { formatImageAddress } from '/src/util/formatting/strings';
import { generateTag } from '/src/util/generateTagCloud';
import { PortfolioDealSummaryView } from '/../libs/shared-types/src/types/view/AggregatedDeals';
import { InvestmentView } from '/../libs/shared-types/src/types/view/InvestmentView';
import {
  getAbbreviatedInvestmentRights,
  getInvestmentRights,
} from '/src/util/investment';
import { useNavigate } from 'react-router-dom';
import DateString from '/src/components/utility/DateString';
import SquaredLogo from '/src/components/SquaredLogo';

interface InvestmentsTableRowProps {
  investment: InvestmentView;
  deal: PortfolioDealSummaryView;
}

function InvestmentsTableRow({
  investment,
  deal,
}: InvestmentsTableRowProps): JSX.Element {
  const navigate = useNavigate();
  const handleOnClick = React.useCallback(
    () => navigate(`/app/deal-detail/${deal.startupId}/activity`),
    [history],
  );

  return (
    <tr onClick={handleOnClick} className="cursor-pointer hover:bg-gray-100">
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <SquaredLogo
              containerClassName="size-10"
              src={formatImageAddress(deal.startupLogoKey)}
              alt={`${deal.startupName} logo`}
            />
          </div>
          <div className="app-table-row-text--bold ml-4">
            {deal.startupName}
          </div>
        </div>
      </td>
      <td className="app-table-row-item">
        {generateTag(investment.roundDisplayName)}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {investment.roundType}&nbsp;{investment.safeType}
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          ${abbreviateNumber(investment.amount)}
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {investment.pricePerShare
            ? `$${numberWithCommas(investment.pricePerShare, 4)}`
            : '-'}
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          ${abbreviateNumber(investment.valuationCap!)}
          <div className="app-table-row-text--light">
            {investment.valuationCapType!}
          </div>
        </div>
      </td>
      <td className="app-table-row-item">
        {getAbbreviatedInvestmentRights(investment).map((abr, i) =>
          generateTag(abr, false, getInvestmentRights(investment)[i]),
        )}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {investment.moic ? investment.moic.toFixed(2) : '-'}
          {investment.moic && 'x'}
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          <DateString date={investment.investedOn} />
        </div>
      </td>
    </tr>
  );
}

export default InvestmentsTableRow;
