import React from 'react';

interface TooltipProps {
  tooltipText: string | JSX.Element;
  position?: 'left' | 'top' | 'right' | 'bottom';
  width?: string;
}

function Tooltip({
  tooltipText,
  position = 'right',
  width = 'w-60',
}: TooltipProps): JSX.Element {
  return (
    <span className="tooltip-wrapper ml-2">
      <span className={`tooltip normal-case tooltip-${position} ${width} ml-2`}>
        {tooltipText}
      </span>
      <div className="flex h-3 w-3 items-center justify-center rounded-full bg-gray-700 p-2 text-xs text-white">
        ?
      </div>
    </span>
  );
}

export default Tooltip;
