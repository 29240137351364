export const BaseUrls = {
  LOCAL_API: 'http://localhost:3005/api',
  LOCAL_APP: 'http://localhost:3000',

  DEVELOPMENT_API: 'https://api-dev.flowlie.com/api',
  DEVELOPMENT_APP: 'https://www.dev.flowlie.com',
  DEVELOPMENT_BUCKET_URL: 'https://flowlie-app-dev.s3.us-east-2.amazonaws.com/',

  PRODUCTION_API: 'https://api.flowlie.com/api',
  PRODUCTION_APP: 'https://app.flowlie.com',
  PRODUCTION_PROMO: 'https://www.flowlie.com',
  PRODUCTION_BUCKET_URL: 'https://flowlie-app-prod.s3.us-east-2.amazonaws.com/',
  PRODUCTION_SLEEKPLAN: 'https://flowlie.sleekplan.app',
  PRPODUCTION_ACCEPTABLE_USE: 'https://www.flowlie.com/legal/acceptable-use',
  PRPODUCTION_COOKIES: 'https://www.flowlie.com/legal/cookies',
  PRPODUCTION_PRIVACY_POLICY: 'https://www.flowlie.com/legal/privacy',
  PRPODUCTION_TOS: 'https://www.flowlie.com/legal/terms',
  PRODUCTION_INVESTOR_FEEBDACK_FORM:
    'https://docs.google.com/forms/d/e/1FAIpQLSd_eiowmr08Osx41cFqOAHDExYhiNUgAh-W_Sh3vvza0Fw_Bg/viewform?usp=sf_link',
  PRODUCTION_STARTUP_FEEBDACK_FORM:
    'https://docs.google.com/forms/d/e/1FAIpQLSfErZVl-v_E2GRwibHqkZ3t_gQj2yDos9GNQeiyXHmuFlsdmw/viewform?usp=sf_link',
  PRODUCTION_SENDGRID_URL: 'https://api.sendgrid.com/v3',
};
