import React from 'react';
import { useLocation } from 'react-router-dom';
import { toDashCase } from '/src/util/formatting/strings';
import CustomNavLink from '../CustomNavLink';
import { getRouteBasePath } from '/src/util/formatting/routes';

export interface TabHeader {
  name: string;
  stat?: number;
}

interface TabHeaderProps {
  tabs: TabHeader[];
}

function TabsHeader({ tabs }: TabHeaderProps): JSX.Element {
  const { pathname } = useLocation();

  return (
    <div>
      <div className="my-4 block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <CustomNavLink
                activeClassName="app-tab-link--active"
                className="cursor-pointer"
                fullPath={`${getRouteBasePath(
                  pathname,
                  tabs.map((x) => x.name)
                )}/${toDashCase(tab.name)}`}
                inactiveClassName="app-tab-link"
                isFirstTab={tabs.indexOf(tab) === 0}
                key={tab.name}
                stat={tab.stat}
                to={toDashCase(tab.name)}
              >
                {tab.name}
              </CustomNavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

export default TabsHeader;
