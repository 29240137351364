import React from 'react';
import { joinClassNames } from '../util/formatting/strings';

interface SquaredLogoProps {
  src?: string;
  alt: string;
  containerClassName: string;
}

function SquaredLogo({
  src,
  alt,
  containerClassName,
}: SquaredLogoProps): JSX.Element {
  return (
    <>
      <div
        className={joinClassNames(
          containerClassName,
          'rounded-md overflow-hidden flex items-center justify-center',
        )}
      >
        <img
          src={src}
          alt={alt}
          className="object-contain max-h-full max-w-full"
        />
      </div>
    </>
  );
}

export default SquaredLogo;
