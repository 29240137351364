import {
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  DeviceTabletIcon,
} from '@heroicons/react/20/solid';
import React from 'react';
import DebugRender from '../DebugRender';
import { UA_DeviceType } from '/../libs/shared-types/src/constants/UaParserValues';

interface DeviceTypeIconProps {
  className?: string;
  deviceType: string | undefined;
}

function DeviceTypeIcon({
  className = 'h-4 w-4',
  deviceType,
}: DeviceTypeIconProps) {
  if (!deviceType) {
    return <></>;
  }

  switch (deviceType.toLowerCase()) {
    case UA_DeviceType.Desktop:
      return (
        <ComputerDesktopIcon title="Desktop device" className={className} />
      );
    case UA_DeviceType.Mobile:
      return (
        <DevicePhoneMobileIcon title="Mobile device" className={className} />
      );
    case UA_DeviceType.Tablet:
      return <DeviceTabletIcon title="Tablet device" className={className} />;
    default:
      return (
        <DebugRender>
          <span>{deviceType}</span>
        </DebugRender>
      );
  }
}

export default DeviceTypeIcon;
