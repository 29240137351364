import React from 'react';
import * as yup from 'yup';
import FormField from '/src/interfaces/FormField';
import YearsInput from '../../inputs/YearsInput';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import {
  EXP_INVESTING_YEARS_MAX,
  EXP_INVESTING_YEARS_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import { getPluralSuffix } from '/src/util/formatting/numbers';

const ExpInvestingYearsField: FormField = {
  fieldComponent: (
    <YearsInput
      label="Years of VC investing experience"
      name={InvestorFieldNames.ExpInvestingYears}
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.ExpInvestingYears]: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .integer('Must be an integer')
      .min(
        EXP_INVESTING_YEARS_MIN,
        `Must be greater than or equal to ${EXP_INVESTING_YEARS_MIN} year${getPluralSuffix(
          EXP_INVESTING_YEARS_MIN
        )}`
      )
      .max(
        EXP_INVESTING_YEARS_MAX,
        `Cannot be greater than ${EXP_INVESTING_YEARS_MAX} year${getPluralSuffix(
          EXP_INVESTING_YEARS_MAX
        )}`
      ),
  }),
};

export default ExpInvestingYearsField;
