import React from 'react';
import * as yup from 'yup';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import PercentageInput from '../../inputs/PercentageInput';
import {
  NET_REVENUE_RETENTION_MAX,
  NET_REVENUE_RETENTION_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';

const NetRevenueRetentionField: FormField = {
  fieldComponent: (
    <PercentageInput
      label="Net Revenue Retention"
      name={StartupFieldNames.NetRevenueRetention}
      tooltip="The change in recurring revenue from existing customers over a given period of time, taking into account expansions, contractions, and churn. It’s calculated as: [(Starting MRR + expansion MRR - contraction MRR - churned MRR) / Starting MRR] * 100"
    />
  ),
  validation: yup.object({
    [StartupFieldNames.NetRevenueRetention]: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .min(
        NET_REVENUE_RETENTION_MIN,
        `Must be greater than or equal to ${NET_REVENUE_RETENTION_MIN}%`
      )
      .max(
        NET_REVENUE_RETENTION_MAX,
        `Cannot be greater than ${NET_REVENUE_RETENTION_MAX}%`
      ),
  }),
};

export default NetRevenueRetentionField;
