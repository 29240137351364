import React, { createRef, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { AnyObject, ObjectSchema } from 'yup';
import { formatDatePickerInput } from '/src/util/formatting/dates';
import { Disclosure } from '@headlessui/react';
import { PROFILE_PUBLIC, PROFILE_SAVED } from '/src/constants/SuccessMessages';
import API from '/src/middleware/API';
import DebugRender from '/src/components/utility/DebugRender';
import emptyStartup from './EmptyStartup';
import FormCard, { FormSection } from '../FormCard';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Logger from '/src/services/logger';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import STARTUP_FIELD_MAPPING from './StartupFormFieldMapping';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import Toast from '../notifications/Toast';
import { cleanStartupData } from './StartupFormValuesCleaner';
import WarnBeforeExit from '/src/components/utility/WarnBeforeExit';
import { Link, useNavigate } from 'react-router-dom';
import {
  STARTUP_FLOWLINK_MANAGER_ROUTE,
  STARTUP_FUNDRAISING_WORKSHOP,
  STARTUP_PROFILE_PREVIEW_ROUTE,
} from '/src/constants/Routes';
import { updateStartup } from '/src/services/UpdateStartup';
import { EyeIcon } from '@heroicons/react/20/solid';
import { DealActionModals, ModalConfig } from '/src/interfaces/ModalConfig';
import ModalWrapper from '../notifications/ModalWrapper';
import ShareProfileDialogContent from '/src/routes/startup/ProfilePreview/ShareProfileDialogContent';
import Alert from '../notifications/Alert';
import { PrivateStartup } from '/../libs/shared-types/src/types/view/startup/PrivateStartup';
import { StartupGet } from '/../libs/shared-types/src/constants/ApiRoutes';
import { joinClassNames } from '/src/util/formatting/strings';
import { AcademicCapIcon } from '@heroicons/react/24/solid';
import { SlideLeft } from '../animations/Slide';
import PremiumCTA from '../PremiumCTA';

interface FormSectionCompanion extends FormSection {
  description: string[];
}

const sections: FormSectionCompanion[] = [
  {
    title: 'General',
    description: [
      'Your company one-pager starts here. Presenting the general facts about your company allows investors to understand if you are a fit for their thesis and whether they should read further.',
      'The most important item is the Brief Company Description. A great description explains what your company does clearly and comprehensively. Writing one is hard so let the Flowlie AI improve your existing one.',
      'Remember: Sending a FlowLink instead of just a deck significantly increases the chance of getting a meeting with your target investor.',
    ],
    fields: [
      STARTUP_FIELD_MAPPING[StartupFieldNames.Logo],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Name],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Website],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Description],
      STARTUP_FIELD_MAPPING[StartupFieldNames.FoundedOn],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Industries],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Geography],
      STARTUP_FIELD_MAPPING[StartupFieldNames.BusinessModels],
      STARTUP_FIELD_MAPPING[StartupFieldNames.CurrentStage],
      STARTUP_FIELD_MAPPING[StartupFieldNames.EmployeesCount],
      STARTUP_FIELD_MAPPING[StartupFieldNames.HasIncubator],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Incubators],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Impacts],
    ],
  },
  {
    title: 'Product & Market',
    description: [
      'Investors want to understand what your company does and who you are selling to. This section of the one-pager allows them to learn more about your product and service.',
      'Founders who include a product demo and have a well-written ICP see an increase in converting cold emails to meetings when sending their FlowLink. A great ICP description can be difficult to write so let the Flowlie AI improve your existing one.',
    ],
    fields: [
      STARTUP_FIELD_MAPPING[StartupFieldNames.ProductType],
      STARTUP_FIELD_MAPPING[StartupFieldNames.ProductStatus],
      STARTUP_FIELD_MAPPING[StartupFieldNames.PrimaryGrowthDriver],
      STARTUP_FIELD_MAPPING[StartupFieldNames.RevenueType],
      STARTUP_FIELD_MAPPING[StartupFieldNames.HasInventory],
      STARTUP_FIELD_MAPPING[StartupFieldNames.TotalAddressableMarket],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Icp],
      STARTUP_FIELD_MAPPING[StartupFieldNames.ProductDemoVideoUrl],
    ],
  },
  {
    title: 'Legal',
    description: [
      'An often overlooked part of fundraising communication is the legal component. Investors need to understand if they can invest in you in the first place. ',
      'Filling out this section properly ensures you are not wasting any time on the ones who cannot invest.',
    ],
    fields: [
      STARTUP_FIELD_MAPPING[StartupFieldNames.LegalEntityType],
      STARTUP_FIELD_MAPPING[StartupFieldNames.LegalName],
      STARTUP_FIELD_MAPPING[StartupFieldNames.LegalFormationState],
      STARTUP_FIELD_MAPPING[StartupFieldNames.CompanyType],
      STARTUP_FIELD_MAPPING[StartupFieldNames.IpProtections],
      STARTUP_FIELD_MAPPING[StartupFieldNames.HasPendingLawsuits],
      STARTUP_FIELD_MAPPING[StartupFieldNames.HasPreviousBankruptcies],
      STARTUP_FIELD_MAPPING[StartupFieldNames.HasPreviousConvictions],
    ],
  },
  {
    title: 'Customer Traction',
    description: [
      'Choose one or more traction metrics to highlight on your one-pager. Focus on the ones that would make a great first impression. ',
      'If you don’t see the metric that best demonstrates your non-financial traction, please write it in the additional text field at the bottom of the section.',
    ],
    fields: [
      STARTUP_FIELD_MAPPING[StartupFieldNames.HasUsers],
      STARTUP_FIELD_MAPPING[StartupFieldNames.UserCount],
      STARTUP_FIELD_MAPPING[StartupFieldNames.MonthlyActiveUserCount],
      STARTUP_FIELD_MAPPING[StartupFieldNames.WeeklyActiveUserCount],
      STARTUP_FIELD_MAPPING[StartupFieldNames.DailyActiveUserCount],
      STARTUP_FIELD_MAPPING[StartupFieldNames.UserGrowthRateMom],
      STARTUP_FIELD_MAPPING[StartupFieldNames.UserRetentionRateMom],
      STARTUP_FIELD_MAPPING[StartupFieldNames.HasCustomers],
      STARTUP_FIELD_MAPPING[StartupFieldNames.CustomerCount],
      STARTUP_FIELD_MAPPING[StartupFieldNames.CustomerGrowthRateMom],
      STARTUP_FIELD_MAPPING[StartupFieldNames.CustomerRetentionRateMom],
      STARTUP_FIELD_MAPPING[StartupFieldNames.PilotsCount],
      STARTUP_FIELD_MAPPING[StartupFieldNames.NetPromoterScore],
      STARTUP_FIELD_MAPPING[StartupFieldNames.AdditionalTractionInfo],
    ],
  },
  {
    title: 'Financial Traction & Unit Economics',
    description: [
      'Similar to your non-financial KPIs, choose one or more traction metrics to highlight on your one-pager. Focus on the ones that will make a great first impression and don’t feel the need to share too much.',
      'Remember: Your goal in sending your FlowLink to an investor is to get them interested in meeting you to discuss the opportunity further.',
    ],
    fields: [
      STARTUP_FIELD_MAPPING[StartupFieldNames.RevenueMonthlyGmv],
      STARTUP_FIELD_MAPPING[StartupFieldNames.AvgTakeRate],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Revenue],
      STARTUP_FIELD_MAPPING[StartupFieldNames.RevenueGrowth],
      STARTUP_FIELD_MAPPING[StartupFieldNames.BookingsAmount],
      STARTUP_FIELD_MAPPING[StartupFieldNames.NetRevenueRetention],
      STARTUP_FIELD_MAPPING[StartupFieldNames.NetRevenueLastYear],
      STARTUP_FIELD_MAPPING[StartupFieldNames.NetRevenueYTD],
      STARTUP_FIELD_MAPPING[StartupFieldNames.NetMonthlyBurn],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Runway],
      STARTUP_FIELD_MAPPING[StartupFieldNames.GrossMargin],
      STARTUP_FIELD_MAPPING[StartupFieldNames.NetMargin],
      STARTUP_FIELD_MAPPING[StartupFieldNames.AvgOrderValue],
      STARTUP_FIELD_MAPPING[StartupFieldNames.AvgRevenuePerUser],
      STARTUP_FIELD_MAPPING[StartupFieldNames.CustomerAcquisitionCost],
      STARTUP_FIELD_MAPPING[StartupFieldNames.LifeTimeValue],
    ],
  },
  {
    title: 'Primary Contact',
    description: [
      'Clearly identify the person whose contact information will be visible to the investors you send your FlowLink to. The primary contact will be defaulted to the person who created the account. If you’d like to change that, please contact our support team.',
      'If you have a calendar link such as Calendly or similar, make sure to include it so investors have a simple way to book a meeting.',
    ],
    fields: [
      STARTUP_FIELD_MAPPING[StartupFieldNames.AccountFirstName],
      STARTUP_FIELD_MAPPING[StartupFieldNames.AccountLastName],
      STARTUP_FIELD_MAPPING[StartupFieldNames.AccountEmail],
      STARTUP_FIELD_MAPPING[StartupFieldNames.AccountPhone],
      STARTUP_FIELD_MAPPING[StartupFieldNames.CalendarLink],
    ],
  },
  {
    title: 'Company Socials',
    description: [
      'Make it easy for investors to research your company by providing them with your official socials.',
    ],
    fields: [
      STARTUP_FIELD_MAPPING[StartupFieldNames.AngelList],
      STARTUP_FIELD_MAPPING[StartupFieldNames.CrunchBase],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Discord],
      STARTUP_FIELD_MAPPING[StartupFieldNames.LinkedIn],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Twitter],
      STARTUP_FIELD_MAPPING[StartupFieldNames.OtherUrl],
    ],
  },
  {
    title: 'Founders',
    description: [
      'The team behind the company is equally if not more important than the product or service itself. This section of the one-pager is your chance to present to investors why you are the right people to build this company.',
      'Investors are looking to understand as much as possible about your team and their background. Make it easy for them by providing all the information you can along with their LinkedIn URL for further research.',
    ],
    fields: [
      STARTUP_FIELD_MAPPING[StartupFieldNames.TeamPresentationVideoUrl],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Founders],
    ],
  },
  {
    title: 'Company Highlights',
    description: [
      'Add a variety of highlights to showcase the key milestones and achievements your company has reached since its founding. Prioritize financial and product & metrics since those are the ones that can speak the loudest about your fundability.',
      'Investors want to understand your company’s trajectory and how fast you executed your vision. Include only important highlights and don’t feel any pressure if you cannot find more than 2 or 3.',
    ],
    fields: [STARTUP_FIELD_MAPPING[StartupFieldNames.Highlights]],
  },
];

function StartupEditProfileForm(): JSX.Element {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>({});
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [showInfoAlert, setShowInfoAlert] = useState(true);
  const [originalLogoKey, setOriginalLogoKey] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [submitCount, setSubmitCount] = useState(0);
  const [validation, setValidation] =
    useState<ObjectSchema<any, AnyObject, any, any>>();
  const toggleShowSaveToast = () => setShowSaveAlert(!showSaveAlert);
  const [modalConfig, setModalConfig] = useState<ModalConfig<DealActionModals>>(
    {
      isOpen: false,
    },
  );

  const sectionRefs = sections.reduce((section: any, value) => {
    section[value.title] = createRef();
    return section;
  }, {});

  function ModalContent(): JSX.Element {
    switch (modalConfig.dialog) {
      case DealActionModals.Share:
        return <ShareProfileDialogContent startup={initialValues} />;
      default:
        return <></>;
    }
  }

  async function fetchStartup() {
    try {
      setIsLoading(true);
      const data = await API.get<PrivateStartup>(StartupGet.buildEndpoint());

      const startup = {
        ...emptyStartup,
        ...data,
        deckMustValidate: false,
        [StartupFieldNames.FoundedOn]: formatDatePickerInput(data.foundedOn),
        logoMustValidate: false,
        highlights: (data.highlights ?? []).map((highlight) => ({
          ...highlight,
          achievedOn: formatDatePickerInput(highlight.achievedOn),
        })),
      };
      setOriginalLogoKey(data.logoKey);
      setInitialValues(startup);
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSubmit(
    values: any,
    { setSubmitting }: FormikHelpers<any>,
  ): Promise<void> {
    // YOU MUST CHECK IF THE DATA BEING SUBMITTED IS VALID
    // For example, certain fields are mutually exclusive
    const cleanValues = cleanStartupData(values);

    try {
      setSubmitting(true);
      await updateStartup(cleanValues, originalLogoKey);
      setShowSaveAlert(true);
      setTimeout(() => setShowSaveAlert(false), 3000);
    } catch (error) {
      Logger.error(error);
    } finally {
      // After submitting, refresh the form
      setSubmitCount(submitCount + 1);
      setSubmitting(false);
    }
  }

  React.useEffect(() => {
    fetchStartup();
    setValidation(
      sections
        .map((section) => section.fields)
        .flat()
        .map((field) => field.validation)
        .reduce((merged, schema) => merged.concat(schema)),
    );
  }, [submitCount]);

  if (isLoading && submitCount === 0) {
    return <PageLoadingSpinner />;
  }

  return (
    <Formik
      initialValues={initialValues}
      initialTouched={{
        [StartupFieldNames.AvgTakeRate]: true,
        [StartupFieldNames.RevenueMonthlyGmv]: true,
      }}
      enableReinitialize
      validateOnMount
      validationSchema={validation}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, errors, resetForm, isSubmitting, isValid, dirty }) => (
        <Form>
          <WarnBeforeExit isEnabled={dirty} />
          <header className="sticky top-0 z-20 bg-gray-100 pb-2">
            <div className="relative flex max-w-full items-center justify-between">
              <h3 className="page-title">Edit One-Pager</h3>
              <div className="flex items-center space-x-3">
                <button
                  type="button"
                  onClick={() => resetForm()}
                  className="app-button--neutral"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="app-button--green"
                  disabled={!dirty || isSubmitting}
                  title={
                    errors
                      ? 'Some values are not valid, you must correct all errors before saving'
                      : ''
                  }
                >
                  Save
                  {isSubmitting && (
                    <div className="ml-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>

                <button
                  type="button"
                  onClick={() => navigate(STARTUP_PROFILE_PREVIEW_ROUTE)}
                  className="app-button--primary"
                  disabled={dirty}
                  title={dirty ? 'Save or cancel your changes first' : ''}
                >
                  <EyeIcon className="mr-2 h-4 w-4" />
                  Preview
                </button>
              </div>
            </div>
            <Alert
              alertType="Info"
              canDismiss={true}
              color="blue"
              content={
                <p>
                  Your deck, one-pager, and round information{' '}
                  <b>
                    will not be shared with anyone unless you send them your{' '}
                    <Link
                      to={STARTUP_FLOWLINK_MANAGER_ROUTE}
                      className="hyperlink underline"
                    >
                      FlowLink
                    </Link>
                  </b>
                  . Complete your one-pager before sharing it with investors to
                  communicate all the details they need.
                </p>
              }
              isShown={showInfoAlert}
              onClose={() => setShowInfoAlert(false)}
            />
          </header>

          <div className="flex flex-col sm:max-w-[52rem]">
            {sections.map((section) => (
              <section key={section.title} ref={sectionRefs[section.title]}>
                <FormCard
                  title={section.title}
                  fields={section.fields}
                  headerStyles={joinClassNames(
                    showInfoAlert ? 'top-[8.8rem]' : 'top-[5rem]',
                  )}
                  description={section.description}
                />
              </section>
            ))}

            <aside
              className={joinClassNames(
                showInfoAlert ? 'top-[8.8rem]' : 'top-[5rem]',
                'fixed right-12 mt-6 hidden max-w-[340px] 2xl:block',
              )}
            >
              <nav className="bg-white p-0.5 shadow sm:rounded-md">
                <header className="border-b border-gray-200 px-4 py-5 sm:px-6">
                  <h4 className="text-lg font-medium leading-6 text-gray-900">
                    One-pager Sections
                  </h4>
                </header>
                <ol className="list-inside list-decimal space-y-2 px-4 py-5 sm:px-6">
                  {sections.map((section) => (
                    <li key={section.title}>
                      <button
                        type="button"
                        className="hyperlink"
                        onClick={() => {
                          sectionRefs[section.title].current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'nearest',
                          });
                        }}
                      >
                        {section.title}
                      </button>
                    </li>
                  ))}
                </ol>
              </nav>

              <SlideLeft>
                <div className="mt-4">
                  <PremiumCTA
                    id="cta_fundraising_workshop_at_one_pager"
                    href={STARTUP_FUNDRAISING_WORKSHOP}
                    content={
                      <span className="p-2 font-medium">
                        Are you a first-time founder?
                        <br />
                        Join our Fundraising Masterclass!
                      </span>
                    }
                    icon={<AcademicCapIcon className="h-6 w-6 text-white" />}
                  />
                </div>
              </SlideLeft>
            </aside>

            <ModalWrapper
              open={modalConfig.isOpen}
              onClose={() => setModalConfig({ isOpen: false })}
            >
              <ModalContent />
            </ModalWrapper>

            {/* Debug Formik Values */}
            <DebugRender className="top- my-4 flex flex-col space-y-2 text-xs">
              <Disclosure>
                <Disclosure.Button className="app-button--neutral">
                  <pre>Can Submit Form:</pre>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <pre>Cannot submit if any of the following is true:</pre>
                  <pre>{`is not Valid: ${!isValid}`}</pre>
                  <pre>{`isSubmitting: ${isSubmitting}`}</pre>
                  <pre>{`not modified yet: ${!dirty}`}</pre>
                </Disclosure.Panel>
              </Disclosure>

              <Disclosure>
                <Disclosure.Button className="app-button--neutral">
                  <pre>Formik Values:</pre>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <pre className="mb-24">{JSON.stringify(values, null, 2)}</pre>
                </Disclosure.Panel>
              </Disclosure>

              <Disclosure>
                <Disclosure.Button className="app-button--neutral">
                  <pre>Formik File Upload:</pre>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <pre className="mb-6">
                    {values.logo
                      ? JSON.stringify(
                          {
                            fileName: values.logo.name,
                            type: values.logo.type,
                            size: `${values.logo.size} bytes`,
                          },
                          null,
                          2,
                        )
                      : null}
                  </pre>
                  <pre className="mb-6">
                    {values.deck
                      ? JSON.stringify(
                          {
                            fileName: values.deck.name,
                            type: values.deck.type,
                            size: `${values.deck.size} bytes`,
                          },
                          null,
                          2,
                        )
                      : null}
                  </pre>
                </Disclosure.Panel>
              </Disclosure>

              <Disclosure>
                <Disclosure.Button className="app-button--neutral">
                  <pre>Formik Errors:</pre>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <pre className="mb-24">{JSON.stringify(errors, null, 2)}</pre>
                </Disclosure.Panel>
              </Disclosure>
            </DebugRender>
          </div>

          <div className="z-20">
            <Toast
              isShown={showSaveAlert}
              onClose={toggleShowSaveToast}
              title={PROFILE_SAVED}
              text={PROFILE_PUBLIC}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default StartupEditProfileForm;
