import { FormikValues } from 'formik';

export function removeUnsetOptionalValues(data: any): any {
  // reset values that were cleared to null, so they can be serialized
  // and then interpreted by our backend and removed from the database

  const updatedValues = { ...data };
  Object.keys(updatedValues).forEach((key: string) => {
    if (
      (Array.isArray(updatedValues[key]) && !updatedValues[key].length) ||
      updatedValues[key] === ''
    ) {
      updatedValues[key] = null;
    }
  });
  return updatedValues;
}

/**
 * Used to clean up URL fields, to ensure that they are prefixed with the https protocol
 * @param values The values from Formik
 * @param urlFieldNames The names of the fields that represent URLs
 * @returns The cleaned up values, where each url field not starting with http:// or https://
 * is updated with the prefix https://
 */
export function updateUrlPrefix(
  values: FormikValues,
  urlFieldNames: string[],
): FormikValues {
  urlFieldNames.forEach((x) => {
    const urlValue: string = values[x];
    if (
      !urlValue ||
      urlValue.startsWith('http://') ||
      urlValue.startsWith('https://')
    ) {
      return;
    }
    values[x] = `https://${urlValue.trim()}`;
  });
  return values;
}
