const LocalStorageKeysConst = {
  // always used when signed in
  ACCOUNT_EMAIL: 'accountEmail',
  ACCOUNT_FIRST_NAME: 'accountFirstName',
  ACCOUNT_LAST_NAME: 'accountLastName',
  FLOWLIE_TOKEN: 'flowlieToken',
  ACC_TYPE: 'accType',
  LAST_AUTH_RELOAD: 'LAST_AUTH_RELOAD',
  LOGIN_DATE: 'LOGIN_DATE',
  COGNITO_ID: 'cognitoAuthId',
  SUBSCRIPTION_TIER: 'subscriptionTier',

  // user onboarding
  FLOWLINK: 'flowlink',

  // misc
  DEBUG_MODE: 'debugMode',
  IS_ADMIN: 'isAdmin',
  IS_DARK_THEME: 'isDarkTheme',

  // startup activation
  NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING: 'newUserFirstLoginTourIsRunning',

  // Investor settings
  INVESTOR_ACTIVE_VIEW: 'investorActiveView',

  GOOGLE_ACCOUNT_PICTURE: 'googleAccountPicture',
};

export default LocalStorageKeysConst;
