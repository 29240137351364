import React from 'react';
import * as yup from 'yup';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import MoneyInput from '../../inputs/MoneyInput';
import {
  AVERAGE_ORDER_VALUE_MIN,
  AVERAGE_ORDER_VALUE_MAX,
} from '/../libs/shared-types/src/constants/NumericRanges';

const AvgOrderValueField: FormField = {
  fieldComponent: (
    <MoneyInput
      label="Average Order Value"
      name={StartupFieldNames.AvgOrderValue}
    />
  ),
  validation: yup.object({
    [StartupFieldNames.AvgOrderValue]: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .min(
        AVERAGE_ORDER_VALUE_MIN,
        `Must be at least $ ${AVERAGE_ORDER_VALUE_MIN}`
      )
      .max(
        AVERAGE_ORDER_VALUE_MAX,
        `Cannot be greater than $ ${AVERAGE_ORDER_VALUE_MAX}`
      ),
  }),
};

export default AvgOrderValueField;
