import React, { useEffect, useState } from 'react';
import Graph, { Data } from 'react-graph-vis';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const GRAPH_OPTIONS = {
  physics: {
    solver: 'repulsion',
    repulsion: {
      nodeDistance: 200,
      springLength: 250,
      springConstant: 0.01,
      damping: 0.09,
      centralGravity: 0.5,
    },
  },

  layout: {
    hierarchical: false,
    improvedLayout: true,
  },
  edges: {
    color: '#000000',
  },
  nodes: {
    size: 50,
  },
  height: '900px',
};

function NetworkGraph({ graphData }: Data): JSX.Element {
  const { height, width } = useWindowDimensions();

  const graphOptions = {
    ...GRAPH_OPTIONS,
    height: (height * 0.8).toString(),
  };

  const events = {
    select: (event: any) => {
      const { nodes, edges } = event;
    },
  };

  return (
    <div>
      {graphData ? (
        <Graph
          key={Date.now()}
          graph={graphData}
          options={graphOptions}
          events={events}
        />
      ) : (
        <div>The graph has no data</div>
      )}
    </div>
  );
}

export default NetworkGraph;
