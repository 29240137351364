import React, { useContext } from 'react';
import { AccountMetadataContext } from '../contexts/AccountMetadataContext';
import { Link } from 'react-router-dom';
import { BILLING_ROUTE } from '../constants/Routes';
import { SubscriptionTiersNameMap } from '/../libs/shared-types/src/constants/SubscriptionTiers';

export function SubscriptionTierPill(): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);

  if (!subscriptionTier) {
    return <></>;
  }

  return (
    <Link
      to={BILLING_ROUTE}
      className="relative flex w-fit items-center justify-center overflow-hidden rounded-2xl bg-white/5 py-1 px-1 text-center text-white shadow-black/5 before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_4s_infinite] before:border-blue-100/50 before:bg-gradient-to-r before:from-transparent before:via-blue-100/50 before:to-transparent"
    >
      <div className="inline-flex min-w-max items-center rounded-2xl bg-gradient-to-r from-green-400/50 to-blue-400/50 px-2 pt-1 pb-0.5 text-2xs font-medium tracking-wide text-blue-800 ring-1 ring-inset ring-indigo-700/10">
        {SubscriptionTiersNameMap.get(subscriptionTier)} plan
      </div>
    </Link>
  );
}
