import { SharedCompleteActivationTask } from '/../libs/shared-types/src/constants/ApiRoutes';
import { InvestorActivationTasks } from '/../libs/shared-types/src/constants/UserActivation/InvestorActivationTasks';
import { StartupActivationTasks } from '/../libs/shared-types/src/constants/UserActivation/StartupActivationTasks';
import API from '../middleware/API';

export async function completeActivationTask(
  taskId: StartupActivationTasks | InvestorActivationTasks
) {
  await API.put(SharedCompleteActivationTask.buildEndpoint(), {
    taskId: taskId,
  });
}
