import React, { useState, useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { AccountContext } from '/src/contexts/Accounts';
import { loginUser } from '/src/services/LoginUser';
import { ACCOUNT_CHOICE_ROUTE } from '/src/constants/Routes';
import Alert from '/src/components/notifications/Alert';
import AuthenticationForm from '/src/components/Authentication/AuthForm';
import AuthFormTypes from '/src/enums/AuthFormTypes';
import AuthService from '/src/services/AuthService';
import { AuthStatus } from '/src/enums/AuthStatus';
import { localStorageGetAuthenticatedAccountType } from '/src/middleware/LocalStorage';
import Logger from '/src/services/logger';
import { GENERIC_LOGIN_ERROR } from '/src/constants/ErrorMessages';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';
import { getHomepageForAccountType } from '/src/util/urls';
import flowlieLogo from '/src/res/flowlie_f.svg';

interface FormValues {
  email: string;
  password: string;
}

function LoginRoute(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { cognitoAuthenticate } = useContext(AccountContext);
  const authenticationContext = useContext(AuthenticationContext);
  const toggleAlert = () => setErrorMessage('');
  const navigate = useNavigate();

  const handleLoginUser = async (formValues: FormValues) => {
    try {
      const loginResult = await loginUser(
        formValues,
        cognitoAuthenticate,
        authenticationContext
      );

      if (loginResult === AuthStatus.COMPLETE_ONBOARDING) {
        navigate(ACCOUNT_CHOICE_ROUTE);
        return;
      }

      if (loginResult === AuthStatus.FULL_ACCOUNT) {
        const url = getHomepageForAccountType();

        if (!url) {
          const accountType = localStorageGetAuthenticatedAccountType();
          Logger.error('accountType not recognized', accountType);
          setErrorMessage(GENERIC_LOGIN_ERROR);
          return;
        }
        navigate(url);
      }
    } catch (errorString: any) {
      setErrorMessage(errorString);
    }
  };

  if (AuthService.isLoggedIn()) {
    return <Navigate to={getHomepageForAccountType()} />;
  }

  return (
    <main className="flex h-screen w-full items-center justify-center bg-gray-50">
      <a
        href="https://www.flowlie.com"
        className="absolute top-8 mx-auto flex shrink-0 items-center px-4 dark:text-white"
      >
        <img className="h-10 w-auto" src={flowlieLogo} alt="flowlie" />
        <div className="ml-2 flex flex-col">
          <h1 className="-mb-1 text-xl font-bold tracking-wide">flowlie</h1>
        </div>
      </a>
      <div className="relative w-full max-w-md">
        <div className="absolute top-40 -left-4 hidden h-72 w-72 animate-blob rounded-full bg-green-300 opacity-70 mix-blend-multiply blur-xl filter sm:block" />
        <div className="animation-delay-2000 absolute top-8 -right-4 hidden h-72 w-72 animate-blob rounded-full bg-blue-300 opacity-70 mix-blend-multiply blur-xl filter sm:block" />
        <div className="animation-delay-4000 absolute -bottom-8 left-20 hidden h-72 w-72 animate-blob rounded-full bg-teal-300 opacity-70 mix-blend-multiply blur-xl filter sm:block" />
        <div className="relative p-4 sm:rounded-xl sm:bg-white/70 sm:p-8 sm:shadow-xl">
          <h3 className="mb-2 text-center text-3xl font-bold text-gray-800">
            Log In
          </h3>
          <AuthenticationForm
            authenticateOnSubmit={handleLoginUser}
            formType={AuthFormTypes.Login}
          />
          <Alert
            color="red"
            alertType="Error"
            content={errorMessage}
            isShown={errorMessage !== ''}
            onClose={toggleAlert}
          />
        </div>
      </div>
    </main>
  );
}

export default LoginRoute;
