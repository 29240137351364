enum RoundFieldNames {
  ConversionTerm = 'conversionTerm',
  Discount = 'discount',
  EstimatedCloseDate = 'estimatedCloseDate',
  HasLeadInvestor = 'hasLeadInvestor',
  HasTermSheet = 'hasTermSheet',
  Interest = 'interest',
  IsBridge = 'isBridge',
  RaiseTarget = 'raiseTarget',
  RaisedAmount = 'raisedAmount',
  RoundStage = 'roundStage',
  RoundType = 'roundType',
  ValuationCap = 'valuationCap',
  ValuationCapType = 'valuationCapType',

  AntidilutionRights = 'antidilutionRights',
  BridgeNumber = 'bridgeNumber',
  HasRightsDragAlong = 'hasRightsDragAlong',
  HasRightsMfn = 'hasRightsMfn',
  HasRightsPayToPlay = 'hasRightsPayToPlay',
  LiquidationParticipation = 'liquidationParticipation',
  LiquidationPreference = 'liquidationPreference',
  OpenToInvestorTypes = 'openToInvestorTypes',
  Prepayment = 'prepayment',
  PricePerShare = 'pricePerShare',
  RunwayTarget = 'runwayTarget',
  SafeType = 'safeType',

  // The following are only for utility
  IsEditable = 'isEditable',
  RoundHistory = 'roundHistory',
}

export default RoundFieldNames;
