import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Form, Formik, FormikValues, useFormikContext } from 'formik';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import {
  getOwnershipValue,
  getPostMoney,
  getDilutionAmount,
  getDilutedOwnership,
} from '/src/util/vcFormulas';
import { abbreviateNumber } from '/src/util/formatting/numbers';
import API from '/src/middleware/API';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import { isRoundOpen } from '/src/util/rounds';
import confetto from 'confetto';
import PercentageInput from '/src/components/inputs/PercentageInput';
import MoneyInput from '/src/components/inputs/MoneyInput';
import { StartupGetRoundHistory } from '/../libs/shared-types/src/constants/ApiRoutes';
import { SlideUp } from '/src/components/animations/Slide';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { joinClassNames } from '/src/util/formatting/strings';

function DilutionCalculator(): JSX.Element {
  const { values } = useFormikContext<FormikValues>();

  const currentOwnershipValue = getOwnershipValue(
    Number(values.ownership),
    Number(values.currentValuation),
  );

  const postMoneyValuation = getPostMoney(
    Number(values.preMoneyValuation),
    Number(values.raiseTarget),
  );

  const dilutionAmount = getDilutionAmount(
    Number(values.raiseTarget),
    Number(postMoneyValuation),
  );

  const dilutedOwnership = getDilutedOwnership(
    Number(values.ownership),
    dilutionAmount,
  );

  const futureOwnershipValue = getOwnershipValue(
    dilutedOwnership,
    postMoneyValuation,
  );

  const paperGain = futureOwnershipValue - currentOwnershipValue;

  useEffect(() => {
    if (postMoneyValuation > 1_000_000_000) {
      confetto();
    }
  }, [postMoneyValuation]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap items-center space-x-3">
        <span>If I own</span>
        <span className="w-20">
          <PercentageInput
            className="input font-bold"
            name="ownership"
            maxLength={5}
            step={1}
          />
        </span>
        <span>
          of a company <b>last valued</b> at
        </span>
        <span className="w-40">
          <MoneyInput
            className="input font-bold"
            name="currentValuation"
            step={100_000}
          />
        </span>
        <span>
          my stake is worth <b>$ {abbreviateNumber(currentOwnershipValue)}</b>
        </span>
      </div>

      <div className="flex flex-wrap items-center space-x-3">
        <span>If I raise</span>
        <span className="w-40">
          <MoneyInput
            className="input font-bold"
            name="raiseTarget"
            step={100_000}
          />
        </span>
        <span>
          at a <b>pre-money valuation</b> of
        </span>
        <span className="w-40">
          <MoneyInput
            className="input font-bold"
            name="preMoneyValuation"
            step={100_000}
          />
        </span>
        <span>
          (which is a{' '}
          <b>
            $ {abbreviateNumber(postMoneyValuation)} post-money valuation
            {postMoneyValuation >= 1_000_000_000 && (
              <span className="text-2xl"> 🦄</span>
            )}
          </b>
          )
        </span>
      </div>

      <div className="mt-6 mb-4 flex flex-row items-center space-x-3">
        <span>
          I will be diluted by <b>{dilutionAmount.toPrecision(4)}%</b> (
          {abbreviateNumber(values.raiseTarget)}/
          {abbreviateNumber(postMoneyValuation)}
          ), so I will then own <b>{dilutedOwnership.toPrecision(4)}%</b> of the
          company.
        </span>
      </div>

      <div className="flex flex-row items-center space-x-3">
        <span>
          My new stake will be worth{' '}
          <b>$ {abbreviateNumber(futureOwnershipValue)}</b>, making{' '}
          {
            // Check if the gain can be approximated to 0
            abbreviateNumber(Math.abs(paperGain)) === '0' && (
              <>
                <b>no gain on paper</b> <span className="text-2xl">🥶</span>
              </>
            )
          }
          {abbreviateNumber(Math.abs(paperGain)) !== '0' && paperGain < 0 && (
            <>
              a <b>loss on paper of $ {abbreviateNumber(paperGain)}</b>{' '}
              <span className="text-2xl">📉</span>
            </>
          )}
          {abbreviateNumber(Math.abs(paperGain)) !== '0' && paperGain > 0 && (
            <>
              a <b>gain on paper of $ {abbreviateNumber(paperGain)}</b>{' '}
              <span className="text-2xl">📈</span>
            </>
          )}
        </span>
      </div>
    </div>
  );
}

function StartupDilutionCalculatorRoute(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [currentRaiseTarget, setCurrentRaiseTarget] = useState<number>();
  const [latestValuation, setLatestValuation] = useState<number>();
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const hasAccess = hasFeatureAccess(
    StartupPaidFeatures.DilutionCalculator,
    subscriptionTier,
  );

  function getRoundHistory() {
    setIsLoading(true);
    API.get(StartupGetRoundHistory.buildEndpoint()).then(
      (data: RoundView[]) => {
        const roundHistory = data.reverse();
        if (roundHistory.length > 1) {
          // If the latest round is open, pre-fill form with raise target
          if (isRoundOpen(roundHistory[0])) {
            setCurrentRaiseTarget(roundHistory[0].raiseTarget);
          }

          // If the there exist a closed round, pre-fill form with post cap
          if (roundHistory.length > 1 && roundHistory[1].estimatedPostCap) {
            setLatestValuation(roundHistory[1].estimatedPostCap);
          }
        }

        setIsLoading(false);
      },
    );
  }

  const initialValues = {
    currentValuation: latestValuation ?? 2_000_000,
    ownership: 20,
    preMoneyValuation: 7_500_000,
    raiseTarget: currentRaiseTarget ?? 2_500_000,
  };

  useEffect(getRoundHistory, []);

  return (
    <main>
      <h2 className="page-title">Dilution Calculator</h2>
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {isLoading && (
            <PageLoadingSpinner message="Ready to plan the next raise? 💰" />
          )}

          {!isLoading && (
            <section className="relative">
              <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={() => {}}
              >
                {({ resetForm, dirty }) => (
                  <>
                    <Form className="my-4 rounded-lg bg-white shadow">
                      <div className="relative flex max-w-full items-center justify-between px-6 py-3">
                        <h3 className="h4">
                          How does my stake get diluted when I raise?
                        </h3>
                        <button
                          type="button"
                          onClick={() => resetForm()}
                          className="app-button--neutral"
                          disabled={!dirty}
                        >
                          Reset
                        </button>
                      </div>
                      <section
                        className={joinClassNames(
                          'border-t border-gray-200 px-8 py-6',
                          hasAccess
                            ? ''
                            : 'blur-[1.5px] select-none pointer-events-none',
                        )}
                      >
                        <DilutionCalculator />
                      </section>
                    </Form>
                  </>
                )}
              </Formik>

              {!hasAccess && (
                <SlideUp as={Fragment}>
                  <div className="absolute top-[50%] sm:top-[30%] left-0 right-0 z-30 sm:w-[60%] space-y-4 rounded-lg border-2 border-gray-200 bg-white p-8 shadow-md sm:left-[20%] ">
                    <p className="font-medium">
                      Understand how your ownership stake changes with each
                      fundraising round
                    </p>
                    <p>
                      With a plug and play calculator that is very easy to use,
                      just enter your numbers and view exactly how your
                      valuation and capital raised affect your equity.
                    </p>

                    <div className="w-max">
                      <SubscriptionCTAPill id="cta_discounts_and_perks" />
                    </div>
                  </div>
                </SlideUp>
              )}
            </section>
          )}
        </div>
      </div>
    </main>
  );
}

export default StartupDilutionCalculatorRoute;
