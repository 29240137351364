import React, { useState } from 'react';
import { PaperClipIcon } from '@heroicons/react/20/solid';
import {
  ArrowsPointingOutIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';

import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import DateString from '/src/components/utility/DateString';
import { StartupOnePagerFeatures } from '/../libs/shared-types/src/constants/StartupOnePagerFeatures';
import { getLatestDeck } from '/../libs/shared-types/src/extensions/DeckExtensions';

export interface DealDetailDeckCardProps {
  startup: PublicStartup;
  hasAccessedLatestDeck?: boolean;
  onDownloadDeck: () => void;
  onViewDeck: () => void;
}

function DealDetailDeckCard({
  startup,
  onDownloadDeck,
  onViewDeck,
  hasAccessedLatestDeck,
}: DealDetailDeckCardProps): JSX.Element {
  const [latestDeck] = useState(getLatestDeck(startup));

  return (
    <section aria-labelledby="startup-deck">
      <div className="rounded-lg bg-white shadow">
        <div className="flex flex-wrap justify-between px-4 py-5 align-middle sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Deck</h3>
          <div className="flex items-center text-gray-500">
            <span className="mr-1 text-sm">Last updated</span>
            <b className="text-sm">
              <DateString
                date={latestDeck.createdOn}
                tooltipPosition="bottom"
              />
            </b>
          </div>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <dd className="my-1 text-sm text-gray-900">
                <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                  <li className="flex flex-wrap items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex items-center">
                      <PaperClipIcon className="h-5 w-5 shrink-0 text-gray-400" />
                      <span className="ml-2 break-all">
                        {latestDeck.fileName}
                      </span>
                    </div>
                    {hasAccessedLatestDeck === false && (
                      <span
                        key="New Version"
                        className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
                      >
                        New Version
                      </span>
                    )}
                  </li>
                </ul>
              </dd>
            </div>

            <div className="flex flex-wrap justify-end space-x-3 space-y-1 sm:col-span-2">
              {!startup.flowLink.excludedFromOnePager.includes(
                StartupOnePagerFeatures.DeckDownload,
              ) && (
                <button
                  type="button"
                  onClick={onDownloadDeck}
                  className="mt-1 inline-flex items-center rounded-full border border-transparent bg-blue-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  <ArrowDownTrayIcon className="mr-2 h-4 w-4" />
                  Download
                </button>
              )}
              <button
                type="button"
                onClick={onViewDeck}
                className="inline-flex items-center rounded-full border border-transparent bg-blue-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <ArrowsPointingOutIcon className="mr-2 h-4 w-4" />
                View
              </button>
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
}

export default DealDetailDeckCard;
