import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface QueryType<T> {
  fromString: (str: string | null) => T | null;
  toString: (t: T | null) => string;
}

const StringType: QueryType<string> = {
  fromString: (str: string | null) => str,
  toString: (t: string | null) => t ?? '',
};

const NumberType: QueryType<number> = {
  fromString: (str: string | null) => (str === null ? null : Number(str)),
  toString: (t: number | null) => String(t),
};

const ObjectType: QueryType<object> = {
  fromString: (str: string | null) => (!str ? null : JSON.parse(str)),
  toString: (t: object | null) => JSON.stringify(t),
};

const QueryTypes = { StringType, NumberType, ObjectType };

/**
 * Hook for a query param. Must be used in the context of react-router.
 *
 * Passing null unsets the query param.
 *
 * Note that this hook will return null if the query param
 * is not defined in the URL, even though this is not fully expressed
 * in the types.
 *
 * @param {string} paramName name of the param to update
 */
const useQueryParam = <T>(
  paramName: string,
  queryType: QueryType<T>
): [T | undefined, (newValue: T) => void] => {
  const [_, setSearchParams] = useSearchParams();

  const initialValue = new URLSearchParams(window.location.search).get(
    paramName
  );
  const [paramValue, setParamValue] = useState<any>(
    initialValue === null ? undefined : initialValue
  );

  const setParam = (newValue: T | null) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (newValue != null) {
      const decoded = queryType.toString(newValue);
      searchParams.set(paramName, decoded);
      setSearchParams(Object.fromEntries(searchParams));
      setParamValue(decoded);
    } else {
      searchParams.delete(paramName);
      setSearchParams(Object.fromEntries(searchParams));
      setParamValue(null);
    }
  };

  const value = queryType.fromString(paramValue);

  return [value ?? undefined, setParam];
};

export { QueryTypes, useQueryParam };
