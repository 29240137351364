import React from 'react';

import { Round } from '/../libs/shared-types/src/types/model/Round';
import {
  dayDiff,
  formatDate,
  formatDaysUntil,
} from '/src/util/formatting/dates';
import {
  abbreviateNumber,
  numberWithCommas,
} from '/src/util/formatting/numbers';
import { joinClassNames } from '/src/util/formatting/strings';
import {
  getEstimatedPostCap,
  getEstimatedRaise,
} from '/../libs/shared-types/src/extensions/RoundExtensions';
import { ValuationCapType } from '/../libs/shared-types/src/constants/ValuationCapType';
import { Disclosure } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import {
  calculateAmountRemaining,
  calculatePercentFunded,
} from '/../libs/shared-types/src/util/RoundCalculations';
import {
  DilutionMetric,
  getRoundDilutionAnalysis,
  RoundDilutionAnalysis,
} from '/../libs/shared-types/src/util/RoundDilution';
import {
  RevenueMultipleMetric,
  getRevenueMultipleAnalysis,
  RoundRevenueMultipleAnalysis,
} from '/../libs/shared-types/src/util/RevenueMultiple';
import { SafeType } from '/../libs/shared-types/src/constants/SafeType';

const PLACEHOLDER = '___';

interface StatisticsProps {
  round: Round;
  monthlyRevenue?: number;
}

export function PercentFunded({ round }: StatisticsProps): JSX.Element {
  const percentFunded = calculatePercentFunded(
    round.raisedAmount,
    round.raiseTarget,
  );

  return (
    <>
      <div>
        <dt className="truncate text-sm font-medium text-gray-500">Funded</dt>
        <dd className="flex items-baseline">
          <div className="text-base font-semibold text-gray-900">
            {isNaN(round.raisedAmount) || isNaN(round.raiseTarget) ? (
              '-'
            ) : (
              <>{percentFunded} %</>
            )}
          </div>
        </dd>
      </div>
    </>
  );
}

export function LeadInvestor({ round }: StatisticsProps): JSX.Element {
  return (
    <>
      <div>
        <dt className="truncate text-sm font-medium text-gray-500">
          Lead Investor
        </dt>
        <dd className="flex items-baseline">
          <div className="flex items-center text-base font-semibold text-gray-900">
            {round.hasLeadInvestor && (
              <CheckCircleIcon className="mr-1 h-[1rem] w-[1rem] text-green-600" />
            )}

            <span>{round.hasLeadInvestor ? 'Secured' : 'Not Secured'}</span>
          </div>
        </dd>
      </div>
    </>
  );
}

export function TermSheet({ round }: StatisticsProps): JSX.Element {
  return (
    <>
      <div>
        <dt className="truncate text-sm font-medium text-gray-500">
          Term Sheet
        </dt>
        <dd className="flex items-baseline">
          <div className="flex items-center text-base font-semibold text-gray-900">
            {round.hasTermSheet && (
              <CheckCircleIcon className="mr-1 h-[1rem] w-[1rem] text-green-600" />
            )}
            <span>{round.hasTermSheet ? 'Signed' : 'Not Signed'}</span>
          </div>
        </dd>
      </div>
    </>
  );
}

export function DilutionAnalysis({
  dilution,
}: {
  dilution: RoundDilutionAnalysis;
}): JSX.Element {
  return (
    <div>
      <div
        className={joinClassNames(
          'flex w-fit items-center space-x-1 rounded-lg px-3 py-1 text-left text-sm font-medium',
          dilution.color.bgColor,
          dilution.color.textColor,
        )}
      >
        {dilution.dilutionMetric === DilutionMetric.FairDilution ||
        dilution.dilutionMetric === DilutionMetric.GreatDilution ? (
          <CheckCircleIcon className="size-4" />
        ) : (
          <ExclamationTriangleIcon className="size-4" />
        )}
        <span>{dilution.dilutionMetric}</span>
      </div>
      <div className="px-4 pb-2 pt-3 text-sm text-gray-500">
        {dilution.explanation}
        <div className="font-medium">{dilution.additionalInfo}</div>
      </div>
    </div>
  );
}

export function PercentSold({ round }: StatisticsProps): JSX.Element {
  if (round.valuationCapType === undefined) {
    return <></>;
  }

  const dilutionAnalysis = getRoundDilutionAnalysis(round);
  const estimatedPostCap = getEstimatedPostCap(round);
  const estimatedRaise = getEstimatedRaise(round);

  if (dilutionAnalysis === undefined) {
    return (
      <div>
        <span className="font-medium text-gray-700">
          Planning to sell around <b>{PLACEHOLDER} %</b> of the company at this
          round.
        </span>
        <p className="pb-2 pt-3 text-sm text-gray-500">
          Add the raise target and valuation cap to calculate dilution.
        </p>
      </div>
    );
  }

  const displayValues = {
    estimatedRaise: estimatedRaise
      ? abbreviateNumber(estimatedRaise)
      : PLACEHOLDER,
    estimatedPostCap: estimatedPostCap
      ? abbreviateNumber(estimatedPostCap)
      : PLACEHOLDER,
    percentSold: dilutionAnalysis.percentSold.toFixed(1),
    raiseTarget: round.raiseTarget
      ? abbreviateNumber(round.raiseTarget)
      : PLACEHOLDER,
    valuationCap: round.valuationCap
      ? abbreviateNumber(round.valuationCap)
      : PLACEHOLDER,
  };

  return (
    <>
      <div>
        <span className="font-medium text-gray-700">
          Planning to sell around <b>{displayValues.percentSold}%</b> of the
          company at this round.
        </span>

        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={joinClassNames(
                  'flex justify-between rounded-lg px-3 py-1 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75',
                  open ? 'bg-blue-200' : 'bg-blue-100',
                )}
              >
                <InformationCircleIcon className="mr-1 mt-0.5 h-4 w-4" />
                <span>Show me how it is calculated</span>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-2 pt-3 text-sm text-gray-500">
                Divide the amount of money you have raised or intend to raise,
                whichever is larger{' '}
                <span>
                  (<b>$ {displayValues.estimatedRaise}</b>)
                </span>
                , by the estimated valuation of your company after receiving the
                investment{' '}
                <span>
                  (<b>$ {displayValues.estimatedPostCap}</b>
                  {round.valuationCapType === ValuationCapType.PreMoney && (
                    <>
                      {' '}
                      which is $ {displayValues.valuationCap}{' '}
                      {round.valuationCapType} + ${' '}
                      {displayValues.estimatedRaise} raise
                    </>
                  )}
                  ){' '}
                </span>
                <div className="mt-2 font-medium">
                  {displayValues.estimatedRaise} /{' '}
                  {displayValues.estimatedPostCap} = {displayValues.percentSold}
                  %
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <div className="mt-2">
          <DilutionAnalysis dilution={dilutionAnalysis} />
        </div>
      </div>
    </>
  );
}

export function RevenueMultipleAnalysis({
  revenueMultipleAnalysis,
}: {
  revenueMultipleAnalysis: RoundRevenueMultipleAnalysis;
}): JSX.Element {
  return (
    <div>
      <div
        className={joinClassNames(
          'flex w-fit items-center space-x-1 rounded-lg px-3 py-1 text-left text-sm font-medium',
          revenueMultipleAnalysis.color.bgColor,
          revenueMultipleAnalysis.color.textColor,
        )}
      >
        {revenueMultipleAnalysis.revenueMultipleMetric ===
        RevenueMultipleMetric.AppropriateMultiple ? (
          <CheckCircleIcon className="size-4" />
        ) : (
          <ExclamationTriangleIcon className="size-4" />
        )}
        <span>{revenueMultipleAnalysis.revenueMultipleMetric}</span>
      </div>
      <div className="px-4 pb-2 pt-3 text-sm text-gray-500">
        {revenueMultipleAnalysis.explanation}
        <div className="font-medium">
          {revenueMultipleAnalysis.additionalInfo}
        </div>
      </div>
    </div>
  );
}

export function RevenueMultiple({
  round,
  monthlyRevenue,
}: StatisticsProps): JSX.Element {
  if (round.safeType === SafeType.Uncapped) {
    return <></>;
  }

  if (!monthlyRevenue) {
    return (
      <div>
        <span className="font-medium text-gray-700">
          Raising at a revenue multiple of <b>{PLACEHOLDER} x</b>.
        </span>
        <p className="pb-2 pt-3 text-sm text-gray-500">
          Update your company one-pager with the Monthly Recurring Revenue to
          view your round&apos;s revenue multiple.
        </p>
      </div>
    );
  }

  const revenueMultipleAnalysis = getRevenueMultipleAnalysis(
    round,
    monthlyRevenue,
  );
  const estimatedPostCap = getEstimatedPostCap(round);

  if (revenueMultipleAnalysis === undefined || estimatedPostCap === undefined) {
    return (
      <div>
        <span className="font-medium text-gray-700">
          Raising at a revenue multiple of <b>{PLACEHOLDER} x</b>.
        </span>
        <p className="pb-2 pt-3 text-sm text-gray-500">
          Add the valuation cap to calculate the revenue multiple.
        </p>
      </div>
    );
  }

  const estimatedRaise = getEstimatedRaise(round);
  const annualizedRevenue = monthlyRevenue * 12;

  const displayValues = {
    annualizedRevenue: annualizedRevenue
      ? abbreviateNumber(annualizedRevenue)
      : PLACEHOLDER,
    estimatedRaise: estimatedRaise
      ? abbreviateNumber(estimatedRaise)
      : PLACEHOLDER,
    estimatedPostCap: estimatedPostCap
      ? abbreviateNumber(estimatedPostCap)
      : PLACEHOLDER,
    monthlyRevenue: monthlyRevenue
      ? abbreviateNumber(monthlyRevenue)
      : PLACEHOLDER,
    revenueMultiple: revenueMultipleAnalysis.revenueMultiple
      ? revenueMultipleAnalysis.revenueMultiple.toFixed(1)
      : PLACEHOLDER,
    valuationCap: round.valuationCap
      ? abbreviateNumber(round.valuationCap)
      : PLACEHOLDER,
  };
  return (
    <>
      <div>
        <span className="font-medium text-gray-700">
          Raising at a revenue multiple of{' '}
          <b>{displayValues.revenueMultiple}x</b>.
        </span>

        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={joinClassNames(
                  'flex justify-between rounded-lg px-3 py-1 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75',
                  open ? 'bg-blue-200' : 'bg-blue-100',
                )}
              >
                <InformationCircleIcon className="mr-1 mt-0.5 h-4 w-4" />
                <span>Show me how it is calculated</span>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-2 pt-3 text-sm text-gray-500">
                Divide the estimated valuation of your company after receiving
                the investment
                <span>
                  {' '}
                  (<b>$ {displayValues.estimatedPostCap}</b>
                  {round.valuationCapType === ValuationCapType.PreMoney && (
                    <>
                      {' '}
                      which is $ {displayValues.valuationCap}{' '}
                      {round.valuationCapType} + ${' '}
                      {displayValues.estimatedRaise} raise
                    </>
                  )}
                  ){' '}
                </span>
                by the annualized revenue
                <span>
                  {' '}
                  (<b>$ {displayValues.annualizedRevenue}</b> which is ${' '}
                  {displayValues.monthlyRevenue} * 12 months)
                </span>
                <div className="mt-2 font-medium">
                  {displayValues.estimatedPostCap} /{' '}
                  {displayValues.annualizedRevenue} ={' '}
                  {displayValues.revenueMultiple}x
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <div className="mt-2">
          <RevenueMultipleAnalysis
            revenueMultipleAnalysis={revenueMultipleAnalysis}
          />
        </div>
      </div>
    </>
  );
}

export function RemainingAllocation({ round }: StatisticsProps): JSX.Element {
  const amountRemaining = calculateAmountRemaining(
    round.raiseTarget,
    round.raisedAmount,
  );

  return (
    <>
      <div>
        <dt className="truncate text-sm font-medium text-gray-500">
          Remaining Allocation
        </dt>
        <dd className="flex items-baseline">
          <div className="text-base font-semibold text-gray-900">
            {round.raiseTarget.toString() === '' ||
            round.raisedAmount.toString() === '' ? (
              '-'
            ) : (
              <>
                $ {numberWithCommas(amountRemaining > 0 ? amountRemaining : 0)}
              </>
            )}
          </div>
        </dd>
      </div>
    </>
  );
}

export function RemainingTime({ round }: StatisticsProps): JSX.Element {
  const closeDate = Date.parse(round.estimatedCloseDate.toString());
  const daysUntilCloseDate = formatDaysUntil(new Date(closeDate));
  const daysCount = dayDiff(new Date(closeDate));

  return (
    <>
      <div>
        <dt className="truncate text-sm font-medium text-gray-500">
          Closing In
        </dt>
        <dd className="flex w-full items-baseline">
          <span className="tooltip-wrapper">
            <span className="tooltip tooltip-bottom">
              {!isNaN(closeDate) && formatDate(new Date(closeDate))}
            </span>
            <div
              className={joinClassNames(
                daysCount <= 0 ? 'text-red-600' : 'text-gray-900',
                'flex items-center text-base font-semibold',
              )}
            >
              {daysCount <= 0 && (
                <ExclamationTriangleIcon className="mr-1 h-[1rem] w-[1rem]" />
              )}
              <span>
                {daysUntilCloseDate === 'NaN day' ? '-' : daysUntilCloseDate}
              </span>
            </div>
          </span>
        </dd>
      </div>
    </>
  );
}
