import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { STATE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import formatGroupLabel from '/src/components/inputs/ReactSelectAdditions/selectFormatGroupLabel';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

const TaxStateField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      isClearable
      groupLabelFormat={formatGroupLabel}
      label="Which state are you taxed in?"
      name={InvestorFieldNames.TaxState}
      options={STATE_OPTIONS}
      placeholder="Select location..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.TaxState]: yup.string().nullable(),
  }),
};

export default TaxStateField;
