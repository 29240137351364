import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import {
  shouldHaveValuationCap,
  isPricedRoundType,
} from '/../libs/shared-types/src/extensions/RoundExtensions';
import { ValuationCapType } from '/../libs/shared-types/src/constants/ValuationCapType';
import { enumToSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  const roundType = values[RoundFieldNames.RoundType];
  // ONLY for SAFE and Convertible Note rounds
  const secondaryLabel =
    roundType === RoundTypes.Safe || roundType === RoundTypes.ConvertibleNote
      ? 'Post-Money is most common and is more investor-friendly.'
      : '';

  const tooltip =
    roundType === RoundTypes.Safe || roundType === RoundTypes.ConvertibleNote
      ? 'Pre-Money valuation cap is more founder-friendly but less common (Subsequent SAFEs and CNs before conversion will dilute the investors). Post-Money valuation cap is more investor-friendly but tends to be the standard (Subsequent SAFEs and CNs before conversion will not dilute the investors).'
      : 'Pre-money is the valuation of your company prior to the investment round, while post-money is the value of your company after the investment round';

  return isInputShown(RoundFieldNames.ValuationCapType, values) ? (
    <Field
      className="custom-select"
      closeMenuOnSelect
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      component={CustomSelect}
      label={
        isPricedRoundType(roundType) ? 'Valuation Type' : 'Valuation Cap Type'
      }
      name={RoundFieldNames.ValuationCapType}
      options={enumToSelectOptions(ValuationCapType)}
      placeholder="Select..."
      tooltip={tooltip}
      secondaryLabel={secondaryLabel}
    />
  ) : (
    <></>
  );
}

const ValuationCapTypeField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.ValuationCapType]: yup
      .string()
      .nullable()
      .test(
        'valuationCapTypeRequired',
        'Required',
        (
          selectedValuationCapType,
          context: yup.TestContext<yup.AnyObject>,
        ): boolean => {
          if (
            shouldHaveValuationCap(context.parent) &&
            selectedValuationCapType === undefined
          ) {
            return false;
          }
          return true;
        },
      ),
  }),
};

export default ValuationCapTypeField;
