import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import { LONG_TEXTAREA_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import TextCharacterCounter from '../../TextCharacterCounter';

function FieldWithCharCount(): JSX.Element {
  const { values } = useFormikContext<any>();

  return (
    <>
      <Field
        component={FormikInput}
        boxType="textarea"
        customStyle="h-28 max-h-28"
        label="Intro Bio"
        name={InvestorFieldNames.IntroBio}
        type="text"
        placeholder="Type a short and simple introduction of yourself..."
      />
      <TextCharacterCounter
        textLength={values[InvestorFieldNames.IntroBio]?.length ?? 0}
        maxLength={LONG_TEXTAREA_LENGTH_MAX}
      />
    </>
  );
}

const IntroBioField: FormField = {
  fieldComponent: <FieldWithCharCount />,
  validation: yup.object({
    [InvestorFieldNames.IntroBio]: yup
      .string()
      .nullable()
      .max(
        LONG_TEXTAREA_LENGTH_MAX,
        `Must be at most ${LONG_TEXTAREA_LENGTH_MAX} characters`
      ),
  }),
};

export default IntroBioField;
