import React from 'react';
import * as yup from 'yup';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import PercentageInput from '../../inputs/PercentageInput';

const NetMarginField: FormField = {
  fieldComponent: (
    <PercentageInput
      label="Net Margin"
      name={StartupFieldNames.NetMargin}
      tooltip="The percentage of revenue a company keeps as profit after accounting for all costs, taxes, and expenses. It’s calculated as: Net Profit / Total Revenue"
    />
  ),
  validation: yup.object({
    [StartupFieldNames.NetMargin]: yup
      .number()
      .min(0, 'Must be a value between 0 and 100')
      .max(100, 'Must be a value between 0 and 100')
      .when(StartupFieldNames.GrossMargin, {
        is: (grossMargin: number) => grossMargin >= 0,
        then: (schema) =>
          schema
            .typeError('Must be a number')
            .lessThan(
              yup.ref(StartupFieldNames.GrossMargin),
              'Must be less than the Gross Margin',
            ),
      }),
  }),
};

export default NetMarginField;
