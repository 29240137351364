import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import PercentageInput from '/src/components/inputs/PercentageInput';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import {
  INTEREST_MAX,
  INTEREST_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.Interest, values) ? (
    <PercentageInput
      disabled={values[RoundFieldNames.IsEditable] === 'false'}
      label="Interest Rate"
      name={RoundFieldNames.Interest}
      tooltip="The annual interest rate investors receive as long as the convertible note is outstanding"
      secondaryLabel="Typically between 4% and 8%"
    />
  ) : (
    <></>
  );
}

const InterestField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.Interest]: yup
      .number()
      .nullable()
      .when(RoundFieldNames.RoundType, {
        is: (roundType: string) => RoundTypes.ConvertibleNote === roundType,
        then: (schema) =>
          schema
            .typeError('Must be a number')
            .min(
              INTEREST_MIN,
              `Interest rate cannot be less than ${INTEREST_MIN}%`,
            )
            .max(
              INTEREST_MAX,
              `Interest rate cannot be higher than ${INTEREST_MAX}%`,
            )
            .required('Required'),
      }),
  }),
};

export default InterestField;
