import React, { useContext } from 'react';
import {
  ComparableDeckVersionView,
  DeckVersionHistoryFieldFilters,
  DeckVersionHistoryPaginatedCollectionResponse,
} from '/../libs/shared-types/src/types/view/DeckVersionView';
import DeckVersionsTable from './DeckVersionsTable';
import { PaginatedRequestParams } from '../../../types/view/APIResponse';
import { TableHeader } from '/src/interfaces/TableHeader';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import DropdownSelect from '/src/components/DropdownSelect';
import SearchBar from '/src/components/inputs/SearchBar';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Alert from '/src/components/notifications/Alert';

export const defaultDeckVersionHistorySortKey =
  'version' as keyof ComparableDeckVersionView;

function getTableHeaders(canAccessDeckHistory: boolean) {
  const tableHeaders: TableHeader<ComparableDeckVersionView>[] = [
    {
      sortKey: canAccessDeckHistory
        ? defaultDeckVersionHistorySortKey
        : undefined,
      element: <span>Version</span>,
    },
    {
      sortKey: canAccessDeckHistory ? 'pageCount' : undefined,
      element: <span>Pages</span>,
    },
    {
      element: <span>Changes</span>,
    },
    {
      sortKey: canAccessDeckHistory ? 'totalViewCount' : undefined,
      element: <span title="Total number of deck views">Views</span>,
    },
    {
      sortKey: canAccessDeckHistory ? 'uniqueViewersCount' : undefined,
      element: (
        <span title="Total number of unique viewers">Unique Viewers</span>
      ),
    },
    {
      sortKey: canAccessDeckHistory ? 'downloadCount' : undefined,
      element: <span title="Total number of downloads">Downloads</span>,
    },
    {
      element: <span className="sr-only">View & Download</span>,
    },
  ];
  return tableHeaders;
}

interface DeckVersionHistoryProps {
  apiResponse: DeckVersionHistoryPaginatedCollectionResponse;
  isSearchLoading: boolean;
  stageOptions: string[];
  handleFilter: (filter: string) => Promise<void>;
  handleFilterByRoundStage: (roundStage: string) => Promise<void>;
  fieldFilters: any;
  fetchData: (
    args: Partial<
      PaginatedRequestParams<
        ComparableDeckVersionView,
        DeckVersionHistoryFieldFilters
      >
    >
  ) => Promise<void>;
}

function DeckVersionHistory({
  apiResponse,
  isSearchLoading,
  stageOptions,
  handleFilter,
  handleFilterByRoundStage,
  fieldFilters,
  fetchData,
}: DeckVersionHistoryProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const canAccessDeckVersionHistory = hasFeatureAccess(
    StartupPaidFeatures.DeckVersionHistory,
    subscriptionTier
  );

  return (
    <>
      <Alert
        alertType={'Warning'}
        color={'yellow'}
        content={`You have not uploaded a deck at ${apiResponse.fieldFilters['roundStage']} round`}
        isShown={apiResponse.results.length === 0 && apiResponse.filter === ''}
      />
      <div className="mt-2 mb-3 flex flex-row items-center justify-between">
        <span className="flex items-center">
          <SearchBar
            isDebounce
            placeholder="Search Decks"
            onQueryChange={handleFilter}
            initialValue={apiResponse.filter}
            isDisabled={!canAccessDeckVersionHistory}
          />
          {isSearchLoading && <LoadingSpinner color="blue" />}
        </span>

        <DropdownSelect
          onSelectionChange={handleFilterByRoundStage}
          initialValue={(fieldFilters as any)['roundStage'] ?? ''}
          label="Round Stage"
          items={stageOptions}
        />
      </div>
      <DeckVersionsTable
        headers={getTableHeaders(canAccessDeckVersionHistory)}
        refreshData={fetchData}
        parentPage={apiResponse.page}
        parentTotalCount={apiResponse.totalCount}
        parentSortOrder={apiResponse.sortOrder}
        parentSortedColumn={apiResponse.sortKey}
        parentFilter={apiResponse.filter}
        parentPerPage={apiResponse.perPage}
        parentTotalPages={apiResponse.totalPages}
        deckVersionViews={apiResponse.results}
        blurRowsAfterIndex={canAccessDeckVersionHistory ? undefined : 1}
        canAccessDeckVersionHistory={canAccessDeckVersionHistory}
      />
    </>
  );
}

export default DeckVersionHistory;
