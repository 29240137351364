import React from 'react';
import ModalWrapper from '../notifications/ModalWrapper';
import SimpleDialog from '../notifications/SimpleDialog';

interface IdlePromptProps {
  open: boolean;
  onClose: () => void;
}

export default function IdlePrompt({
  open,
  onClose,
}: IdlePromptProps): JSX.Element {
  return (
    <ModalWrapper open={open} onClose={onClose}>
      <SimpleDialog
        onCancel={undefined}
        onPrimaryAction={onClose}
        text={''}
        primaryAction="Yes, I'm still here"
        color={'blue'}
        title={'Are you still here?'}
      />
    </ModalWrapper>
  );
}
