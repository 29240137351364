/**
 * Describes all the combinations for Sign Up flows
 */
export enum OnboardingFlows {
  InvestorViaDealShareLink = 'InvestorViaDealShareLink',
  InvestorViaInvestorFlowLink = 'InvestorViaInvestorFlowLink',
  InvestorViaSignup = 'InvestorViaSignup',
  InvestorViaStartupFlowLink = 'InvestorViaStartupFlowLink',
  StartupViaDealShareLink = 'StartupViaDealShareLink',
  StartupViaInvestorFlowLink = 'StartupViaInvestorFlowLink',
  StartupViaSignup = 'StartupViaSignup',
  StartupViaStartupFlowLink = 'StartupViaStartupFlowLink',
  UnknownViaDealShareLink = 'UnknownViaDealShareLink',
  UnknownViaInvestorFlowLink = 'UnknownViaInvestorFlowLink',
  UnknownViaSignup = 'UnknownViaSignup',
  UnknownViaStartupFlowLink = 'UnknownViaStartupFlowLink',
}
