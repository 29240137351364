import React from 'react';
import { SelectOption } from '/../libs/shared-types/src/types/SelectOptions';
import { FormatOptionLabelMeta } from 'react-select';

interface FormatOptionWithTagProps {
  option: SelectOption;
  tagValue: string;
  formatOptionLabelMeta: FormatOptionLabelMeta<any>;
}

function FormatOptionWithTag({
  option,
  tagValue,
  formatOptionLabelMeta,
}: FormatOptionWithTagProps): JSX.Element {
  return (
    <div className="flex items-center justify-between space-x-3">
      <div>{option.label}</div>
      {formatOptionLabelMeta.context === 'menu' && (
        <div className="inline-flex items-center rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
          {tagValue}
        </div>
      )}
    </div>
  );
}

export default FormatOptionWithTag;
