import { RedirectData } from '/src/interfaces/Redirect';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import React, { createContext, useState } from 'react';
import { Props as JoyrideTourProps } from 'react-joyride';

type ShareStateType = {
  cachedPage: RedirectData | null;
  setCachedPage: React.Dispatch<React.SetStateAction<RedirectData | null>>;
  toastConfiguration?: ToastConfiguration | null;
  setToastConfiguration: React.Dispatch<
    React.SetStateAction<ToastConfiguration | null>
  >;
  joyrideActivationTourState: JoyrideTourProps;
  setJoyrideActivationTourState: React.Dispatch<
    React.SetStateAction<JoyrideTourProps>
  >;
  isActivationTourStopped: boolean;
  setIsActivationTourStopped: React.Dispatch<React.SetStateAction<boolean>>;
};

const SharedStateContext = createContext<ShareStateType>({} as ShareStateType);

const INITIAL_STATE: JoyrideTourProps = {
  continuous: true,
  hideBackButton: true,
  hideCloseButton: true,
  run: false,
  steps: [],
  showProgress: true,
  showSkipButton: true,
  stepIndex: 0, // Make the component controlled
};

const SharedState = ({
  children,
}: {
  children: string | JSX.Element[] | JSX.Element;
}): JSX.Element => {
  const [cachedPage, setCachedPage] = useState<RedirectData | null>(null);
  const [toastConfiguration, setToastConfiguration] =
    useState<ToastConfiguration | null>(null);
  const [joyrideActivationTourState, setJoyrideActivationTourState] =
    useState<JoyrideTourProps>(INITIAL_STATE);
  const [isActivationTourStopped, setIsActivationTourStopped] = useState(false);

  return (
    <SharedStateContext.Provider
      value={{
        cachedPage,
        setCachedPage,
        toastConfiguration,
        setToastConfiguration,
        joyrideActivationTourState,
        setJoyrideActivationTourState,
        isActivationTourStopped,
        setIsActivationTourStopped,
      }}
    >
      {children}
    </SharedStateContext.Provider>
  );
};

export { SharedState, SharedStateContext };
