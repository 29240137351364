import React from 'react';

import { CalendarDaysIcon } from '@heroicons/react/20/solid';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import CopyTextButton from '/src/components/CopyTextButton';
import SocialHandles, { SocialHandleName } from '/src/components/SocialHandles';

interface DealDetailContactCardProps {
  startup: PublicStartup;
}

function DealDetailContactCard({
  startup,
}: DealDetailContactCardProps): JSX.Element {
  const socialUrls = [
    { name: SocialHandleName.LinkedIn, url: startup.linkedIn },
    { name: SocialHandleName.Twitter, url: startup.twitter },
    {
      name: SocialHandleName.CrunchBase,
      url: startup.crunchBase,
    },
    { name: SocialHandleName.AngelList, url: startup.angelList },
    { name: SocialHandleName.Discord, url: startup.discord },
    { name: SocialHandleName.Other, url: startup.otherUrl },
  ];

  return (
    <section>
      <div className="rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Primary Contact
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 2xl:grid-cols-3">
            <div className="sm:col-span-2 2xl:col-span-2">
              <dt className="text-sm font-medium text-gray-900">
                {startup.accountFirstName} {startup.accountLastName}
              </dt>
              <dd className="mt-1 flex cursor-pointer items-center text-sm text-blue-600 hover:text-blue-500 hover:underline">
                <a
                  href={`mailto:${startup.accountEmail}`}
                  className="break-all"
                >
                  {startup.accountEmail}
                </a>
                <CopyTextButton text={startup.accountEmail} className="ml-2" />
              </dd>
            </div>
            {startup.calendarLink && (
              <div className="sm:col-span-2 2xl:col-span-1">
                <dd className="mt-1">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={startup.calendarLink}
                    className="mt-1 inline-flex w-full items-center justify-center rounded-full border border-transparent bg-blue-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    <CalendarDaysIcon className="mr-2 h-5 w-5" />
                    Book Meeting
                  </a>
                </dd>
              </div>
            )}
            {socialUrls.filter((x) => x.url).length > 0 && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Company Socials
                </dt>
                <dd className="mt-1">
                  <SocialHandles socialUrls={socialUrls} />
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
}

export default DealDetailContactCard;
