import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import * as yup from 'yup';
import { CognitoUser, ICognitoUserData } from 'amazon-cognito-identity-js';
import { Field, Form, Formik } from 'formik';

import FormikInput from '/src/components/inputs/FormikInput';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import passwordSchema from '/src/constants/validation/passwordSchema';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import Logger from '/src/services/logger';
import UserPool from '/src/Userpool';
import AUTH_FIELD_MAPPING from '/src/components/Authentication/AuthFormFieldMap';
import Alert from '/src/components/notifications/Alert';
import { LOGIN_ROUTE } from '/src/constants/Routes';

const resetValidationSchema = yup.object({
  verificationCode: yup.string().required('Required'),
  password: passwordSchema,
});

const initialValuesEmail = {
  email: '',
};

const initialValuesReset = {
  verificationCode: '',
  password: '',
};

function ForgotPasswordRoute(): JSX.Element {
  const [email, setEmail] = useState('');
  const [canLogin, setCanLogin] = useState(false);
  const [isResetCodeEmailed, setIsResetCodeEmailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const toggleAlert = () => setErrorMessage('');

  const requestConfirm = async (verificationCode: string, password: string) =>
    new Promise((resolve, reject) => {
      const data: ICognitoUserData = { Username: email, Pool: UserPool };
      const user = new CognitoUser(data);
      user.confirmPassword(verificationCode, password, {
        onSuccess: () => {
          resolve(true);
        },
        onFailure: (error) => {
          Logger.error(error);
          reject(error);
        },
      });
    });

  const confirmNewPassword = (verificationCode: string, password: string) => {
    requestConfirm(verificationCode, password)
      .then(() => {
        setCanLogin(true);
      })
      .catch((err) => {
        setErrorMessage(`Could not log you in because: ${err.message}`);
        setCanLogin(false);
      });
  };

  const sendResetCode = (emailAddress: string) => {
    const data: ICognitoUserData = { Username: emailAddress, Pool: UserPool };
    const user = new CognitoUser(data);

    user.forgotPassword({
      onSuccess: () => {
        // successfully initiated reset password request
        setIsResetCodeEmailed(true);
      },
      onFailure: () => {
        setErrorMessage('Please be sure to input your email correctly');
      },
    });
  };

  const emailField = AUTH_FIELD_MAPPING[AuthFieldNames.Email];
  const getLinkForm = (
    <Formik
      initialValues={initialValuesEmail}
      validationSchema={emailField.validation}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setEmail(values.email);
        sendResetCode(values.email);
        setSubmitting(false);
      }}
    >
      {({ dirty, isSubmitting, isValid }) => (
        <Form>
          {emailField.fieldComponent}

          <div className="my-4">
            <button
              type="submit"
              className="button btn-primary min-w-full"
              disabled={!isValid || !dirty || isSubmitting}
            >
              Send Reset Code
              {isSubmitting && (
                <div className="ml-2">
                  <LoadingSpinner color="white" />
                </div>
              )}
            </button>
          </div>
          <Alert
            color="red"
            alertType="Error"
            content={errorMessage}
            isShown={errorMessage !== ''}
            onClose={toggleAlert}
          />
          <div className="text-sm">
            <Link className="hyperlink font-medium" to={LOGIN_ROUTE}>
              Back To Login Page
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );

  const generatePasswordForm = (
    <Formik
      initialValues={initialValuesReset}
      validationSchema={resetValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        confirmNewPassword(values.verificationCode, values.password);
        setSubmitting(false);
      }}
    >
      {({ dirty, isSubmitting, isValid }) => (
        <Form>
          <Field
            component={FormikInput}
            id="verificationCode"
            name="verificationCode"
            label="Reset Code (from email)"
            className="input"
            type="text"
          />
          <Field
            component={FormikInput}
            id="passsword"
            name="password"
            label="New Password"
            className="input"
            type="password"
          />
          <div className="my-4">
            <button
              type="submit"
              className="button btn-primary min-w-full"
              disabled={!isValid || !dirty || isSubmitting}
            >
              Reset Password
              {isSubmitting && (
                <div className="ml-2">
                  <LoadingSpinner color="white" />
                </div>
              )}
            </button>
          </div>
          <Alert
            color="red"
            alertType="Error"
            content={errorMessage}
            isShown={errorMessage !== ''}
            onClose={toggleAlert}
          />
          <Link className="hyperlink text-sm font-medium" to={LOGIN_ROUTE}>
            Back To Login Page
          </Link>
        </Form>
      )}
    </Formik>
  );

  const pageContent = (
    <main className="flex h-screen w-full flex-col items-center justify-center">
      <div className="text-center sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-4 text-3xl font-extrabold text-gray-900">
          {isResetCodeEmailed ? 'Reset Code Sent' : 'Forgot your password?'}
        </h2>
        {isResetCodeEmailed ? (
          <p className="text-sm">
            Check your email for a reset code and enter it here <br />
            along with your new password
          </p>
        ) : (
          <p className="text-sm">
            No worries, we&apos;ll send you a reset code that you can use
            <br /> to create a new password
          </p>
        )}
      </div>

      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-2 px-4 sm:px-10">
          {isResetCodeEmailed && generatePasswordForm}
          {!isResetCodeEmailed && getLinkForm}
        </div>
      </div>
    </main>
  );

  return canLogin ? <Navigate to={LOGIN_ROUTE} /> : pageContent;
}

export default ForgotPasswordRoute;
