import { ActiveDealSummaryView } from '/../libs/shared-types/src/types/view/AggregatedDeals';

export const getDealCountByStage = (
  deals: ActiveDealSummaryView[],
  stageName: string
): number =>
  deals.filter(
    (x) =>
      x.currentActiveBoardStage?.name.toUpperCase() === stageName.toUpperCase()
  ).length;
