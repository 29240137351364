import React from 'react';
import * as yup from 'yup';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import MoneyInput from '../../inputs/MoneyInput';
import {
  AVERAGE_REVENUE_PER_USER_MIN,
  AVERAGE_REVENUE_PER_USER_MAX,
} from '/../libs/shared-types/src/constants/NumericRanges';

const AvgRevenuePerUserField: FormField = {
  fieldComponent: (
    <MoneyInput
      label="Average Revenue Per User"
      name={StartupFieldNames.AvgRevenuePerUser}
    />
  ),
  validation: yup.object({
    [StartupFieldNames.AvgRevenuePerUser]: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .min(
        AVERAGE_REVENUE_PER_USER_MIN,
        `Must be at least $ ${AVERAGE_REVENUE_PER_USER_MIN}`
      )
      .max(
        AVERAGE_REVENUE_PER_USER_MAX,
        `Cannot be greater than $ ${AVERAGE_REVENUE_PER_USER_MAX}`
      ),
  }),
};

export default AvgRevenuePerUserField;
