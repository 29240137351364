import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DateString from '/src/components/utility/DateString';
import { StartupAccessManagerView } from '/../libs/shared-types/src/types/view/StartupAccessManagerView';
import {
  formatImageAddress,
  joinClassNames,
  splitOnWhiteSpace,
} from '/src/util/formatting/strings';
import {
  HiddenFitScore,
  generateFitScoreTag,
  generateTag,
} from '/src/util/generateTagCloud';
import {
  formatDate,
  formatDateTime,
  howLongBetween,
} from '/src/util/formatting/dates';
import BackendPaginatedTable, {
  PaginatedTableProps,
  isRowBlurred,
} from '/src/components/table/BackendPaginatedTable';
import CopyTextButton from '/src/components/CopyTextButton';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import { MIN_DATE } from '/../libs/shared-types/src/constants/MinimumDate';
import TimeDurationString from '/src/components/utility/TimeDurationString';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { INVESTOR_DETAIL } from '/src/constants/Routes';

interface AccessManagerTableRowProps {
  accessManagerEntry: StartupAccessManagerView;
  className: string;
  isRowBlurred: boolean;
  hasFitScoreAccess: boolean;
}

function AccessManagerTableRow({
  accessManagerEntry,
  className,
  isRowBlurred,
  hasFitScoreAccess,
}: AccessManagerTableRowProps): JSX.Element {
  const rowIsClickable = true;
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (rowIsClickable && !isRowBlurred) {
      navigate(`${INVESTOR_DETAIL}/${accessManagerEntry.accountId}`);
    }
  };

  return (
    <tr
      onClick={handleOnClick}
      className={joinClassNames(
        'bg-white',
        rowIsClickable && !isRowBlurred
          ? 'cursor-pointer hover:bg-gray-100'
          : 'pointer-events-none select-none',
        className,
      )}
    >
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <UserAvatarWithInitials
              containerStyles="h-10 w-10"
              firstName={splitOnWhiteSpace(accessManagerEntry.name)[0]}
              lastName={splitOnWhiteSpace(accessManagerEntry.name)[1]}
              textStyles="text-xl"
              imgAlt="Investor Profile"
              imgSrc={formatImageAddress(accessManagerEntry.picKey)}
            />
          </div>
          <div className="ml-2">
            <div className="app-table-row-text--bold">
              {accessManagerEntry.name}
            </div>
            {!isRowBlurred && (
              <div className="text-sm text-gray-500">
                <a
                  className="truncate hover:underline"
                  href={`mailto:${accessManagerEntry.email}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {accessManagerEntry.email}
                </a>
                <CopyTextButton
                  text={accessManagerEntry.email}
                  className="absolute ml-1"
                />
              </div>
            )}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-4 py-4">
        {generateTag(accessManagerEntry.userType, true)}
      </td>
      <td className="app-table-row-item">
        {accessManagerEntry.firm && accessManagerEntry.role ? (
          <>
            <div className="app-table-row-text">{accessManagerEntry.firm}</div>
            <div className="app-table-row-text--light">
              {accessManagerEntry.role}
            </div>
          </>
        ) : (
          <div className="app-table-row-text">-</div>
        )}
      </td>

      <td>
        {hasFitScoreAccess ? (
          <span className="flex flex-row items-center">
            {accessManagerEntry.fitScore !== undefined
              ? generateFitScoreTag(accessManagerEntry.fitScore)
              : '-'}
          </span>
        ) : (
          <span className="flex flex-row items-center">
            <HiddenFitScore />
          </span>
        )}
      </td>

      <td className="app-table-row-item">
        <div className="app-table-row-text">
          <TimeDurationString
            timeMs={accessManagerEntry.totalMillisecondsSpentOnDeal}
          />
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {accessManagerEntry.totalViewCount}
        </div>
      </td>
      <td className="app-table-row-item">
        {accessManagerEntry.lastViewedOn.getTime() === MIN_DATE.getTime() && (
          <div className="app-table-row-text">Never</div>
        )}
        {accessManagerEntry.lastViewedOn.getTime() !== MIN_DATE.getTime() && (
          <>
            <div className="app-table-row-text">
              {howLongBetween(accessManagerEntry.lastViewedOn)}
            </div>
            <div className="app-table-row-text--light">
              {formatDate(accessManagerEntry.lastViewedOn)}
              {' at '}
              {formatDateTime(accessManagerEntry.lastViewedOn)}
            </div>
          </>
        )}
      </td>
    </tr>
  );
}

interface AccessManagerTableInterface extends PaginatedTableProps {
  accessManagerViews: StartupAccessManagerView[];
}
function AccessManagerTable({
  accessManagerViews,
  refreshData,
  parentPage,
  parentTotalCount,
  parentSortOrder,
  parentSortedColumn,
  parentFilter,
  parentPerPage,
  headers,
  parentTotalPages,
  blurRowsAfterIndex,
}: AccessManagerTableInterface): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const hasFitScoreAccess = hasFeatureAccess(
    StartupPaidFeatures.AccessManagerInvestors,
    subscriptionTier,
  );

  const rowBlurClass = (index: number) =>
    isRowBlurred(parentPage, index, blurRowsAfterIndex)
      ? 'transform-gpu blur'
      : '';
  const joyrideClass = (index: number) => {
    return index === 0 && parentPage <= 1
      ? 'joyride-accessManagerFirstRow'
      : '';
  };

  return (
    <section>
      <BackendPaginatedTable
        headers={headers}
        refreshData={refreshData}
        parentPage={parentPage}
        parentTotalCount={parentTotalCount}
        parentSortOrder={parentSortOrder}
        parentSortedColumn={parentSortedColumn}
        parentTotalPages={parentTotalPages}
        parentFilter={parentFilter}
        parentPerPage={parentPerPage}
        rowComponents={accessManagerViews.map((data, index) => (
          <AccessManagerTableRow
            accessManagerEntry={data}
            className={joinClassNames(rowBlurClass(index), joyrideClass(index))}
            key={data.accountId}
            isRowBlurred={isRowBlurred(parentPage, index, blurRowsAfterIndex)}
            hasFitScoreAccess={hasFitScoreAccess}
          />
        ))}
      />
    </section>
  );
}

export default AccessManagerTable;
