import React from 'react';
import * as yup from 'yup';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import {
  RUNWAY_MIN,
  RUNWAY_MAX,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import MonthsInput from '../../inputs/MonthsInput';
import { getPluralSuffix } from '/src/util/formatting/numbers';

const RunwayField: FormField = {
  fieldComponent: (
    <MonthsInput
      label="Current Runway (in months)"
      name={StartupFieldNames.Runway}
    />
  ),
  validation: yup.object({
    [StartupFieldNames.Runway]: yup
      .number()
      .min(
        RUNWAY_MIN,
        `Must be greater than or equal to ${RUNWAY_MIN} month${getPluralSuffix(
          RUNWAY_MIN
        )}`
      )
      .max(
        RUNWAY_MAX,
        `Cannot be greater than ${RUNWAY_MAX} month${getPluralSuffix(
          RUNWAY_MAX
        )}`
      )
      .typeError('Must be a number'),
  }),
};

export default RunwayField;
