import React from 'react';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { Field, useFormikContext } from 'formik';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import { RoundStages } from '/../libs/shared-types/src/constants/RoundStages';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { enumToSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import { COMPLETE_PROFILE_ROUTE } from '/src/constants/Routes';
import Logger from '/src/services/logger';

function FieldWrapper(): JSX.Element {
  const { pathname } = useLocation();
  const { values } = useFormikContext<any>();

  const isCompleteProfile = pathname.includes(COMPLETE_PROFILE_ROUTE);

  const hasRoundHistory =
    values['roundHistory'] && values['roundHistory'].length > 0;

  return (
    <>
      <Field
        className="custom-select"
        closeMenuOnSelect
        component={CustomSelect}
        label="Current Company Stage"
        name={StartupFieldNames.CurrentStage}
        options={enumToSelectOptions(RoundStages)}
        isDisabled={!isCompleteProfile && hasRoundHistory}
        placeholder="Select stage..."
        secondaryLabel={
          isCompleteProfile || !hasRoundHistory
            ? 'What is the fundraising round you are raising or most recently raised?'
            : 'In order to update your stage you must use the Round Manager'
        }
        tooltip={
          isCompleteProfile
            ? `If you have never raised, or are unsure what your company stage is, select ${RoundStages.Angel}`
            : ''
        }
      />
    </>
  );
}

const CurrentStageField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.CurrentStage]: yup.string().required('Required'),
  }),
};

export default CurrentStageField;
