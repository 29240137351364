import React from 'react';
import * as yup from 'yup';
import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';

import FormField from '/src/interfaces/FormField';
import BooleanSelectInput from '../../inputs/BooleanSelectInput';

const HasRightsProRataField: FormField = {
  fieldComponent: (
    <BooleanSelectInput
      label="Do you have Pro-Rata Rights?"
      name={RecordInvestmentFieldNames.HasRightsProRata}
      tooltip="Pro-Rata Rights are reflected in the purchase documents or respective side letters and allow you to maintain your level of percentage ownership at the subsequent financing round by investing more capital."
    />
  ),
  validation: yup.object({
    [RecordInvestmentFieldNames.HasRightsProRata]: yup
      .bool()
      .required('Required'),
  }),
};

export default HasRightsProRataField;
