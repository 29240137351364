// ADMIN ROUTES
export const ADMIN_ROUTE = '/app/admin';
export const ADMIN_SIGN_UP_NEWTORK_ROUTE = '/app/admin/sign-up-network';
export const ADMIN_FIT_SCORE_BENCHMARK_ROUTE = '/app/admin/fit-score-benchmark';
export const ADMIN_UNVERIFIED_INVESTOR_FORM_ROUTE =
  '/app/admin/unverified-investor-form';
export const ADMIN_UNVERIFIED_INVESTOR_UPLOAD_ROUTE =
  '/app/admin/unverified-investor-upload';

// COMMON
export const REFERRALS_ROUTE = '/app/referrals';
export const SETTINGS_ROUTE = '/app/settings';
export const BILLING_ROUTE = '/app/settings/billing';
export const NOT_FOUND_ROUTE = '/app/404';
export const START_ACTIVATION_TOUR_ROUTE = '/app/start-tour';
export const INVESTOR_DETAIL = '/app/investor-detail';
export const UNVERIFIED_INVESTOR_DETAIL = '/app/unverified-investor-detail';

// INVESTOR ROUTES
export const ACTIVE_DEALFLOW_ROUTE = '/app/active-dealflow';
export const CONTACT_MANAGER_ROUTE = '/app/contacts/manage-contacts';
export const INVESTOR_FLOWLINK_MANAGER_ROUTE = '/app/investor-flowlink';
export const INVESTOR_PROFILE_ROUTE = '/app/investor-profile';
export const PASSED_ROUTE = '/app/passed';
export const PORTFOLIO_ROUTE = '/app/portfolio';
export const SCREENING_ROUTE = '/app/screening';
export const INVESTOR_CONTACTS_ROUTE = '/app/contacts';
export const INVESTOR_ACCESS_REQUESTS_ROUTE = '/app/access-requests';

// STARTUP ROUTES
export const STARTUP_FUNDRAISING_WORKSHOP =
  'https://www.flowlie.com/fundraising-workshop/learn-more';
export const STARTUP_ACCESS_MANAGER_ROUTE = '/app/access-manager';
export const STARTUP_DATA_ROOM_ROUTE = '/app/data-room';
export const STARTUP_DECK_MANAGER_ROUTE = '/app/deck-manager';
export const STARTUP_DILUTION_CALCULATOR_ROUTE = '/app/dilution-calculator';
export const STARTUP_FLOWLINK_MANAGER_ROUTE = '/app/startup-flowlink';
export const STARTUP_INVESTOR_LEADS_ROUTE = '/app/investor-leads';
export const STARTUP_MY_NETWORK_ROUTE = '/app/my-network';
export const STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE =
  '/app/investor-fragment-detail';
export const STARTUP_INVESTOR_LIST_DETAIL_ROUTE = '/app/investor-list-detail';
export const STARTUP_PERKS_ROUTE = '/app/perks';
export const STARTUP_PERSON_DETAIL_ROUTE = '/app/person-detail';
export const STARTUP_PROFILE_PREVIEW_ROUTE = '/app/startup-profile/preview';
export const STARTUP_PROFILE_ROUTE = '/app/startup-profile';
export const STARTUP_ROUND_MANAGER_ROUTE = '/app/round-manager';
export const STARTUP_DASHBOARD_ROUTE = '/app/startup-dashboard';

// PUBLIC
export const LOGIN_ROUTE = '/';
export const SIGNUP_ROUTE = '/signup';
export const COMPLETE_PROFILE_ROUTE = '/complete-profile';

export const FORGOT_PASSWORD_ROUTE = '/reset';
export const SHARE_LINK_DATA_ACCESS_ROUTE = '/share-link-error';
export const SIGNUP_SUCCESS_ROUTE = '/signup-success';
export const ACCOUNT_CHOICE_ROUTE = '/account-choice';
export const PUBLIC_NOT_FOUND_ROUTE = '/404';
