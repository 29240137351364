import React from 'react';
import { useNavigate } from 'react-router-dom';

import { InvestorAccessDataReceivedFromView } from '/../libs/shared-types/src/types/view/InvestorAccessDataReceivedFromView';
import {
  formatImageAddress,
  splitOnWhiteSpace,
} from '/src/util/formatting/strings';
import { generateTag, generateTagCloud } from '/src/util/generateTagCloud';
import { isMinDate } from '/src/util/time';
import DateString from '/src/components/utility/DateString';
import PaginatedTable from '/src/components/table/PaginatedTable';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import sharedTypeMap from '/src/util/shareType';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import Tooltip from '/src/components/utility/Tooltip';
import { INVESTOR_DETAIL } from '/src/constants/Routes';

function DealDetailReceivedFromTableRow({
  investorAccessData,
}: {
  investorAccessData: InvestorAccessDataReceivedFromView;
}): JSX.Element {
  // If the row contains the information of a founder it should not be clickable
  const rowIsClickable = investorAccessData.userType !== AccountTypes.Startup;
  const userTypeLabel =
    investorAccessData.userType === AccountTypes.Startup
      ? 'Founder'
      : investorAccessData.userType;
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (rowIsClickable) {
      navigate(`${INVESTOR_DETAIL}/${investorAccessData.accountId}`);
    }
  };

  return (
    <tr
      onClick={handleOnClick}
      className={`bg-white ${rowIsClickable ? 'cursor-pointer hover:bg-gray-100' : ''}`}
    >
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <UserAvatarWithInitials
              containerStyles="h-10 w-10"
              firstName={splitOnWhiteSpace(investorAccessData.name)[0]}
              lastName={splitOnWhiteSpace(investorAccessData.name)[1]}
              textStyles="text-xl"
              imgAlt="Profile"
              imgSrc={formatImageAddress(investorAccessData.picKey)}
            />
          </div>
          <div className="app-table-row-text--bold ml-2">
            {investorAccessData.name}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-4 py-4">
        {generateTag(userTypeLabel, true)}
      </td>
      <td className="app-table-row-item">
        {investorAccessData.firm && investorAccessData.role ? (
          <>
            <div className="app-table-row-text">{investorAccessData.firm}</div>
            <div className="app-table-row-text--light">
              {investorAccessData.role}
            </div>
          </>
        ) : (
          <div className="app-table-row-text">-</div>
        )}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {investorAccessData.message ?? ''}
        </div>
      </td>
      <td className="app-table-row-item">
        <div>
          <div className="app-table-row-text">
            {generateTagCloud(investorAccessData.investedAt ?? [])}
          </div>
        </div>
      </td>
      <td className="app-table-row-item">
        {investorAccessData.currentStatus &&
          !isMinDate(investorAccessData.currentStatus.statusChangedOn) && (
            <div>
              <div className="app-table-row-text">
                {generateTag(investorAccessData.currentStatus.status, true)}
              </div>
              <div className="app-table-row-text--light">
                Since&nbsp;
                <DateString
                  date={investorAccessData.currentStatus.statusChangedOn}
                />
              </div>
            </div>
          )}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          <div className="flex flex-row items-center">
            {generateTag(sharedTypeMap(investorAccessData.shareType))}

            {investorAccessData.discoveredFrom && (
              <Tooltip
                tooltipText={`${investorAccessData.name} discovered you via ${investorAccessData.discoveredFrom}`}
                position="left"
                width="w-48"
              />
            )}
          </div>
        </div>
        <div className="app-table-row-text--light">
          <DateString
            date={investorAccessData.sharedOn}
            tooltipPosition="top"
          />
        </div>
      </td>
    </tr>
  );
}

interface DealDetailReceivedFromTableProps {
  investorAccessData: InvestorAccessDataReceivedFromView[];
}

function DealDetailReceivedFromTable({
  investorAccessData,
}: DealDetailReceivedFromTableProps): JSX.Element {
  const headerValues = [
    { element: <span>Name</span> },
    { element: <span>Type</span> },
    { element: <span>Firm & Role</span> },
    { element: <span>Message</span> },
    { element: <span>Invested At</span> },
    { element: <span>Status</span> },
    { element: <span>Via</span> },
  ];

  return (
    <main>
      <PaginatedTable
        headers={headerValues}
        rowComponents={investorAccessData.map(
          (data: InvestorAccessDataReceivedFromView) => (
            <DealDetailReceivedFromTableRow
              key={data.accountId}
              investorAccessData={data}
            />
          ),
        )}
      />
    </main>
  );
}

export default DealDetailReceivedFromTable;
