import React from 'react';
import { FieldProps, getIn } from 'formik';

/**
 * Displays an error message if the field has an error and is touched
 * @param FormikFieldProps
 * @returns error message component
 */
function FormikErrorMessage({ field, form }: FieldProps): JSX.Element {
  // NOTE: the getIn() helper handles nested field data automagically
  // Grab the error and touched state
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  // Would this be more efficient though?
  // const error = form.errors[field.name];
  // const touched = form.touched[field.name];
  return (
    <>{error && touched && <p className="app-error-message">{error}</p>}</>
  );
}

export default FormikErrorMessage;
