import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import { FileType } from '/src/constants/FileUploadTypes';
import { validateFileSize, validateFileType } from '/src/util/FileHelpers';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import PdfInput from '/src/components/inputs/PdfInput';

function FormContextWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return (
    <>
      <Field
        component={PdfInput}
        label={
          values.deck?.createdOn
            ? 'Upload a new version of your pitch deck'
            : 'Upload your pitch deck'
        }
        name={StartupFieldNames.Deck}
      />
    </>
  );
}

const DeckField: FormField = {
  fieldComponent: <FormContextWrapper />,
  validation: yup.object({
    [StartupFieldNames.Deck]: yup
      .mixed()
      .when(StartupFieldNames.DeckMustValidate, {
        is: (hasChanged: boolean) => hasChanged === true,
        then: (schema) =>
          schema
            .required('Required')
            .test(
              'fileFormat',
              'Unsupported file format',
              (value: any) => value && validateFileType(value, FileType.Pdf),
            )
            .test(
              'fileSize',
              'Uploaded file is too big',
              (value: any) =>
                value && validateFileSize(value.size, FileType.Pdf),
            ),
      }),
  }),
};

export default DeckField;
