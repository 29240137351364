import React, { useContext, useState } from 'react';
import {
  AggregatedStartupReferral,
  AggregatedInvestorReferral,
} from '/../libs/shared-types/src/types/view/APIResponse';

import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import BasicStatistic from '/src/components/cards/BasicStatistic';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import ReferredStartupsTable from './ReferredStartupsTable';
import ReferredInvestorsTable from './ReferredInvestorsTable';
import {
  InvestorGetReferrals,
  InvestorGetReferralsStats,
  StartupGetReferrals,
  StartupGetReferralsStats,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import MailToLink from '/src/components/utility/MailToLink';

interface Referrals {
  startups?: AggregatedStartupReferral[];
  investors?: AggregatedInvestorReferral[];
}

interface ReferralStats {
  referredInvestorsCount?: number;
  referredStartupsCount?: number;
  creditsTotal: number;
  creditsAvailable: number;
}

function ReferralsManager(): JSX.Element {
  const [referrals, setReferrals] = useState<Referrals>();
  const [referralStats, setReferralStats] = useState<ReferralStats>();
  const [isLoading, setIsLoading] = useState(true);
  const { accountType } = useContext(AccountMetadataContext);

  async function fetchReferralStats(accountType: AccountTypes) {
    let endpoint = '';
    if (accountType === AccountTypes.Investor) {
      endpoint = InvestorGetReferralsStats.buildEndpoint();
    }
    if (accountType === AccountTypes.Startup) {
      endpoint = StartupGetReferralsStats.buildEndpoint();
    }

    try {
      const data = await API.get<ReferralStats>(endpoint);
      setReferralStats(data);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchReferrals(accountType: AccountTypes) {
    let endpoint = '';
    if (accountType === AccountTypes.Investor) {
      endpoint = InvestorGetReferrals.buildEndpoint();
    }
    if (accountType === AccountTypes.Startup) {
      endpoint = StartupGetReferrals.buildEndpoint();
    }

    try {
      setIsLoading(true);
      const data = await API.get<Referrals>(endpoint);

      setReferrals(data);
      setIsLoading(false);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if (accountType) {
      fetchReferralStats(accountType);
      fetchReferrals(accountType);
    }
  }, [accountType]);

  return (
    <section className="p-2">
      <h3 className="h3">Summary</h3>
      {isLoading && (
        <PageLoadingSpinner message="Calculating statistics... 🤓" />
      )}
      {!isLoading && referralStats && (
        <dl className="my-4 grid w-full grid-cols-2 gap-5 lg:grid-cols-4">
          {accountType === AccountTypes.Investor && (
            <BasicStatistic
              label="Total Referrals"
              statistic={referralStats.referredInvestorsCount ?? 0}
            />
          )}
          {accountType === AccountTypes.Startup && (
            <BasicStatistic
              label="Total Referrals"
              statistic={referralStats.referredStartupsCount ?? 0}
            />
          )}
          <BasicStatistic
            label="Total Credits"
            statistic={`$ ${referralStats.creditsTotal}`}
            tooltip={`You will receive $10 for each ${
              accountType?.toLowerCase() ?? 'friend'
            } you invite with your FlowLink`}
          />
          <BasicStatistic
            label="Available Credits"
            statistic={`$ ${referralStats.creditsAvailable}`}
            tooltip={
              <p>
                Email{' '}
                <MailToLink
                  linkText="support@flowlie.com"
                  params={{
                    to: 'support@flowlie.com',
                    subject: 'Redeem Flowlie Credits',
                    body: `I have $ ${referralStats.creditsAvailable} in available credits and would like to redeem them for a coupon.`,
                  }}
                  className="underline hover:text-blue-400"
                />{' '}
                to redeem your credits for a coupon you can use at checkout
              </p>
            }
          />
        </dl>
      )}
      <h3 className="h3 mt-10 mb-2">My Referrals</h3>
      {isLoading && (
        <PageLoadingSpinner message="Gathering your referrals... 🙌" />
      )}

      {!isLoading && accountType === AccountTypes.Investor && referrals && (
        <ReferredInvestorsTable referrals={referrals.investors ?? []} />
      )}

      {!isLoading && accountType === AccountTypes.Startup && referrals && (
        <ReferredStartupsTable referrals={referrals.startups ?? []} />
      )}
    </section>
  );
}

export default ReferralsManager;
