import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { EnvironmentTypes } from '/../libs/shared-types/src/constants/EnvironmentTypes';

const poolData = {
  UserPoolId:
    process.env.REACT_APP_ENV === EnvironmentTypes.Production
      ? 'us-east-2_GciUB4Vt7'
      : 'us-east-2_ydjGZsYwr',
  ClientId:
    process.env.REACT_APP_ENV === EnvironmentTypes.Production
      ? '5190t5qmsc0p75bdbpg6idgri4'
      : '4mgi17gri53sm3hbpoc49hgmsc',
};

export default new CognitoUserPool(poolData);
