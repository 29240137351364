/* eslint-disable */
import React, { createContext } from 'react';
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import Pool from '../Userpool';
import LocalStorageKeysConst from '../constants/LocalStorageKeys';
import Logger from '../services/logger';

const AccountContext = createContext<any>(null);

const Account = (props: any) => {
  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err: any, session: any) => {
          if (err) {
            reject();
          } else {
            const attributes: any = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results: any = {};

                  for (let attribute of attributes!) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  resolve(results);
                }
              });
            });

            resolve({
              user,
              ...session,
              ...attributes,
            });
          }
        });
      } else {
        reject();
      }
    });

  const instantiateWithTokens = async (
    refreshToken: any,
    idToken: any,
    accessToken: any,
    Username: any,
    idTokenString: any
  ) => {
    const user = new CognitoUser({ Username, Pool });

    const sessionData = {
      IdToken: idToken,
      AccessToken: accessToken,
      RefreshToken: refreshToken,
      ClockDrift: 0,
    };
    const session = new CognitoUserSession(sessionData);
    user.setSignInUserSession(session);
    localStorage.setItem(LocalStorageKeysConst.COGNITO_ID, idTokenString);
    localStorage.setItem(
      LocalStorageKeysConst.LOGIN_DATE,
      Date.now().toString()
    );
    localStorage.setItem(
      LocalStorageKeysConst.LAST_AUTH_RELOAD,
      Date.now().toString()
    );
  };

  const cognitoAuthenticate = async (Username: any, Password: any) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          const accessToken = user
            .getSignInUserSession()!
            .getIdToken()
            .getJwtToken();

          localStorage.setItem(
            LocalStorageKeysConst.LOGIN_DATE,
            Date.now().toString()
          );
          localStorage.setItem(LocalStorageKeysConst.COGNITO_ID, accessToken);

          resolve(data);
        },

        onFailure: (err) => {
          Logger.error('onFailure:', err); // eslint-disable-line
          reject(err);
        },

        newPasswordRequired: (data) => {
          resolve(data);
        },
      });
    });

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  };

  const refreshTokens = async () => {
    await getSession();
  };

  const verifyEmail = async (Username: any, code: any) => {
    const user = new CognitoUser({ Username, Pool });
    const verifyPromise = await new Promise((resolve, reject) => {
      user.confirmRegistration(code, true, function (err, result) {
        if (err) {
          Logger.error(err);
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
    return verifyPromise;
  };

  return (
    <AccountContext.Provider
      value={
        {
          cognitoAuthenticate,
          getSession,
          logout,
          verifyEmail,
          refreshTokens,
          instantiateWithTokens,
        } as unknown as null
      }
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
