import React, { useEffect, useState } from 'react';
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import Logger from '/src/services/logger';
import TailwindColors from '../../constants/TailwindColors';

type ColorName = Extract<TailwindColors, 'red' | 'yellow' | 'blue'>;

export const alertColorMap: Map<ColorName, AlertColor> = new Map([
  [
    'red',
    {
      iconColor: 'text-red-500',
      contentTextColor: 'text-red-800',
      bgColor: 'bg-red-50',
      borderColor: 'border-red-400',
      hoverBgColor: 'hover:bg-red-100',
      focusRingOffsetColor: 'focus:ring-offset-red-50',
      focusRingColor: 'focus:ring-red-600',
    },
  ],
  [
    'yellow',
    {
      iconColor: 'text-yellow-500',
      contentTextColor: 'text-yellow-800',
      bgColor: 'bg-yellow-50',
      borderColor: 'border-yellow-400',
      hoverBgColor: 'hover:bg-yellow-100',
      focusRingOffsetColor: 'focus:ring-offset-yellow-50',
      focusRingColor: 'focus:ring-yellow-600',
    },
  ],
  [
    'blue',
    {
      iconColor: 'text-blue-500',
      contentTextColor: 'text-blue-800',
      bgColor: 'bg-blue-50',
      borderColor: 'border-blue-400',
      hoverBgColor: 'hover:bg-blue-100',
      focusRingOffsetColor: 'focus:ring-offset-blue-50',
      focusRingColor: 'focus:ring-blue-600',
    },
  ],
]);

interface AlertColor {
  iconColor: string;
  contentTextColor: string;
  bgColor: string;
  borderColor: string;
  hoverBgColor: string;
  focusRingOffsetColor: string;
  focusRingColor: string;
}

function getAlertIcon(alertType: string, color: AlertColor): JSX.Element {
  if (alertType === 'Success') {
    return <CheckCircleIcon className={`h-5 w-5 ${color.iconColor}`} />;
  }
  if (alertType === 'Error') {
    return <ExclamationCircleIcon className={`h-5 w-5 ${color.iconColor}`} />;
  }
  if (alertType === 'Warning') {
    return <ExclamationTriangleIcon className={`h-5 w-5 ${color.iconColor}`} />;
  }
  if (alertType === 'Info') {
    return <InformationCircleIcon className={`h-5 w-5 ${color.iconColor}`} />;
  }

  return <p>No Alert should be displayed. If you see this report a bug.</p>;
}

interface AlertProps {
  alertType: 'Info' | 'Success' | 'Error' | 'Warning';
  canDismiss?: boolean;
  color: ColorName;
  content: string | JSX.Element;
  isShown: boolean;
  onClose?: () => void;
}

function Alert({
  alertType,
  canDismiss = true,
  color,
  content,
  isShown,
  onClose,
}: AlertProps): JSX.Element {
  const [isVisible, setIsVisible] = useState(isShown);
  const alertColor = alertColorMap.get(color);

  useEffect(() => {
    setIsVisible(isShown);
  }, [isShown]);

  if (!alertColor) {
    Logger.error(`alertColorMap does not contain ${color}`);
    return <></>;
  }
  return (
    <>
      {isVisible && (
        <div
          className={`rounded-md ${alertColor.bgColor} border ${alertColor.borderColor} p-4`}
        >
          <div className="flex">
            <div className="shrink-0">
              {getAlertIcon(alertType, alertColor)}
            </div>
            <div className="ml-3">
              <div
                className={`text-sm font-medium ${alertColor.contentTextColor}`}
              >
                {content}
              </div>
            </div>
            {canDismiss && (
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    onClick={() => {
                      if (onClose !== undefined) {
                        onClose();
                      }
                      setIsVisible(false);
                    }}
                    className={`inline-flex ${alertColor.bgColor} rounded-md p-1.5 ${alertColor.iconColor} ${alertColor.hoverBgColor} focus:outline-none focus:ring-2 focus:ring-offset-2 ${alertColor.focusRingOffsetColor} ${alertColor.focusRingColor}`}
                  >
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Alert;
