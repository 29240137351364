import React, { ReactNode } from "react";
import { cn } from "/src/util/cn";

export interface BaseTableCellProps {
  children: ReactNode
  rowKey: string
  className?: string
  onRowClick?: (rowId: string) => void
}

export default function TableCell({ children, rowKey, className, onRowClick }: BaseTableCellProps) {

  const handleRowClick = () => {
    if (onRowClick) {
      onRowClick(rowKey)
    }
  }
  return (
    <td className={cn("app-table-row-item", className)} onClick={handleRowClick}>
      {children}
    </td>
  );
};
