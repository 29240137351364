import React from 'react';
import * as yup from 'yup';
import FormikInput from '/src/components/inputs/FormikInput';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import { Field } from 'formik';
import MailToLink from '../../utility/MailToLink';

const AccountFirstNameField: FormField = {
  fieldComponent: (
    <Field
      component={FormikInput}
      label="First Name"
      name={StartupFieldNames.AccountFirstName}
      type="text"
      disabled
      tooltip={
        <p>
          This field is locked, please contact{' '}
          <MailToLink
            linkText="support@flowlie.com"
            params={{
              to: 'support@flowlie.com',
              subject: 'I need to update my Account First Name',
            }}
            className="underline hover:text-blue-400"
          />{' '}
          if you need to update it
        </p>
      }
    />
  ),
  validation: yup.object({
    [StartupFieldNames.AccountFirstName]: yup
      .string()
      .max(40, 'Must be at most 40 characters')
      .required('Required'),
  }),
};

export default AccountFirstNameField;
