import React from 'react';
import { joinClassNames } from '../util/formatting/strings';

interface TextCharacterCounterProps {
  textLength: number;
  maxLength: number;
}

function TextCharacterCounter({
  textLength,
  maxLength,
}: TextCharacterCounterProps): JSX.Element {
  return (
    <div className="flex justify-end">
      <span
        className={joinClassNames(
          textLength > maxLength ? 'text-red-600' : 'text-gray-800',
          'text-xs'
        )}
      >
        {textLength ?? 0}/{maxLength}
        &nbsp;Characters
      </span>
    </div>
  );
}

export default TextCharacterCounter;
