import React from 'react';
import * as yup from 'yup';
import FormikInput from '/src/components/inputs/FormikInput';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import { Field } from 'formik';
import MailToLink from '../../utility/MailToLink';

const AccountEmailField: FormField = {
  fieldComponent: (
    <Field
      component={FormikInput}
      label="Email"
      name={StartupFieldNames.AccountEmail}
      type="email"
      disabled
      tooltip={
        <p>
          This field is locked, please contact{' '}
          <MailToLink
            linkText="support@flowlie.com"
            params={{
              to: 'support@flowlie.com',
              subject: 'I need to update my Account Email',
            }}
            className="underline hover:text-blue-400"
          />{' '}
          if you need to update it
        </p>
      }
    />
  ),
  validation: yup.object({
    [StartupFieldNames.AccountEmail]: yup
      .string()
      .max(255, 'Must be at most 255 characters')
      .email('A valid email is required')
      .required('Required'),
  }),
};

export default AccountEmailField;
