import StartupFieldNames from '/src/enums/StartupFieldNames';

// NOTE: This must be maintained up to date with StartupFieldNames enum
const emptyStartup = {
  [StartupFieldNames.AccountPhone]: '',
  [StartupFieldNames.AdditionalTractionInfo]: '',
  [StartupFieldNames.AngelList]: '',
  [StartupFieldNames.AvgOrderValue]: '',
  [StartupFieldNames.AvgRevenuePerUser]: '',
  [StartupFieldNames.AvgTakeRate]: '',
  [StartupFieldNames.BookingsAmount]: '',
  [StartupFieldNames.BusinessModels]: '',
  [StartupFieldNames.CalendarLink]: '',
  [StartupFieldNames.CrunchBase]: '',
  [StartupFieldNames.CurrentStage]: '',
  [StartupFieldNames.CustomerAcquisitionCost]: '',
  [StartupFieldNames.CustomerCount]: '',
  [StartupFieldNames.CustomerGrowthRateMom]: '',
  [StartupFieldNames.CustomerRetentionRateMom]: '',
  [StartupFieldNames.DailyActiveUserCount]: '',
  [StartupFieldNames.Discord]: '',
  [StartupFieldNames.Deck]: undefined,
  [StartupFieldNames.Description]: '',
  [StartupFieldNames.EmployeesCount]: '',
  [StartupFieldNames.FoundedOn]: '',
  [StartupFieldNames.FounderIsTechnical]: '',
  [StartupFieldNames.FounderLinkedIn]: '',
  [StartupFieldNames.FounderRole]: '',
  [StartupFieldNames.Founders]: [],
  [StartupFieldNames.Geography]: '',
  [StartupFieldNames.GrossMargin]: '',
  [StartupFieldNames.HasIncubator]: '',
  [StartupFieldNames.HasCustomers]: '',
  [StartupFieldNames.HasUsers]: '',
  [StartupFieldNames.Highlights]: '',
  [StartupFieldNames.Impacts]: '',
  [StartupFieldNames.Incubators]: '',
  [StartupFieldNames.Industries]: '',
  [StartupFieldNames.LegalEntityType]: '',
  [StartupFieldNames.LegalFormationState]: '',
  [StartupFieldNames.LegalName]: '',
  [StartupFieldNames.LifeTimeValue]: '',
  [StartupFieldNames.LinkedIn]: '',
  [StartupFieldNames.Logo]: undefined,
  [StartupFieldNames.MonthlyActiveUserCount]: '',
  [StartupFieldNames.Name]: '',
  [StartupFieldNames.NetMargin]: '',
  [StartupFieldNames.NetMonthlyBurn]: '',
  [StartupFieldNames.NetPromoterScore]: '',
  [StartupFieldNames.NetRevenueLastYear]: '',
  [StartupFieldNames.NetRevenueRetention]: '',
  [StartupFieldNames.NetRevenueYTD]: '',
  [StartupFieldNames.OtherUrl]: '',
  [StartupFieldNames.PilotsCount]: '',
  [StartupFieldNames.PrimaryGrowthDriver]: '',
  [StartupFieldNames.ProductDemoVideoUrl]: '',
  [StartupFieldNames.ProductStatus]: '',
  [StartupFieldNames.RevenueGrowth]: '',
  [StartupFieldNames.RevenueMonthlyGmv]: '',
  [StartupFieldNames.RevenueType]: '',
  [StartupFieldNames.Revenue]: '',
  [StartupFieldNames.Runway]: '',
  [StartupFieldNames.TeamPresentationVideoUrl]: '',
  [StartupFieldNames.TotalAddressableMarket]: '',
  [StartupFieldNames.Twitter]: '',
  [StartupFieldNames.UserCount]: '',
  [StartupFieldNames.UserGrowthRateMom]: '',
  [StartupFieldNames.UserRetentionRateMom]: '',
  [StartupFieldNames.Website]: '',
  [StartupFieldNames.WeeklyActiveUserCount]: '',
  [StartupFieldNames.IpProtections]: '',
  [StartupFieldNames.DeckMustValidate]: true,
  logoKey: '',
  [StartupFieldNames.LogoMustValidate]: true,
};

export default emptyStartup;
