import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import PercentageInput from '../../inputs/PercentageInput';
import {
  USER_GROWTH_RATE_MOM_MIN,
  USER_GROWTH_RATE_MOM_MAX,
} from '/../libs/shared-types/src/constants/NumericRanges';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.HasUsers] ? (
    <PercentageInput
      label="User Growth (MoM)"
      name={StartupFieldNames.UserGrowthRateMom}
    />
  ) : (
    <></>
  );
}

const UserGrowthRateMomField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.UserGrowthRateMom]: yup
      .number()
      .nullable()
      .typeError('Must be a number')
      .min(
        USER_GROWTH_RATE_MOM_MIN,
        `Must be greater than or equal to ${USER_GROWTH_RATE_MOM_MIN}%`
      )
      .max(
        USER_GROWTH_RATE_MOM_MAX,
        `Cannot be greater than ${USER_GROWTH_RATE_MOM_MAX}%`
      ),
  }),
};

export default UserGrowthRateMomField;
