import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import BooleanSelectInput from '/src/components/inputs/BooleanSelectInput';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.HasRightsPayToPlay, values) ? (
    <BooleanSelectInput
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      label="Pay-To-Play Rights"
      name={RoundFieldNames.HasRightsPayToPlay}
      isClearable
      tooltip="A “Pay-to-Play” provision is a requirement for an existing investor to participate in a subsequent investment round pro-rata to avoid the conversion of their Preferred Stock into Common Stock or another less valuable series of Preferred Stock."
    />
  ) : (
    <></>
  );
}

const HasRightsPayToPlayField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.HasRightsPayToPlay]: yup.bool().nullable(),
  }),
};

export default HasRightsPayToPlayField;
