import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { REASONS_FOR_INVESTING } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';
import { MAX_INVESTMENT_REASONS } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';

const ReasonsField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      components={{ Menu: LimitedMenu }}
      isClearable
      isMulti
      label="What are your key investment reasons?"
      maxCount={MAX_INVESTMENT_REASONS}
      name={RecordInvestmentFieldNames.Reasons}
      options={REASONS_FOR_INVESTING}
      placeholder="Select reasons..."
    />
  ),
  validation: yup.object({
    [RecordInvestmentFieldNames.Reasons]: yup
      .array()
      .of(yup.string())
      .max(
        MAX_INVESTMENT_REASONS,
        `At most ${MAX_INVESTMENT_REASONS} reasons are allowed`
      )
      .min(1, 'At least one is required')
      .required('This field is required'),
  }),
};

export default ReasonsField;
