import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import confetto from 'confetto';

import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import ProgressStatus from '/../libs/shared-types/src/constants/ProgressStatusTypes';
import API from '/src/middleware/API';
import {
  getVisitorSessionToken,
  saveVisitorSessionToken,
} from '/src/middleware/SessionStorage';
import { VerificationStatus } from '/../libs/shared-types/src/constants/VerificationStatus';
import Logger from '/src/services/logger';
import { VisitorVerifyDealViewSession } from '/../libs/shared-types/src/constants/ApiRoutes';

function VerifySessionHandler(): JSX.Element {
  const navigate = useNavigate();
  const { verificationToken } = useParams();
  const [verifyProgressStatus, setVerifyProgressStatus] =
    useState<ProgressStatus>(ProgressStatus.Unknown);
  const [verificationStatus, setVerificationStatus] =
    useState<VerificationStatus>(VerificationStatus.Pending);

  async function verifyVisitorSession(verificationToken: string) {
    const cachedSessionToken = getVisitorSessionToken();
    saveVisitorSessionToken(verificationToken);
    try {
      const newVerificationStatus = await API.put<VerificationStatus>(
        VisitorVerifyDealViewSession.buildEndpoint(),
        {}
      );
      setVerifyProgressStatus(ProgressStatus.Success);
      setVerificationStatus(newVerificationStatus);
      if (newVerificationStatus === VerificationStatus.Verified) {
        confetto();
      }

      if (cachedSessionToken) {
        saveVisitorSessionToken(cachedSessionToken);
      }
    } catch (error: any) {
      Logger.error(error);
      setVerifyProgressStatus(ProgressStatus.Failure);
    }
  }

  useEffect(() => {
    if (!verificationToken) {
      setVerifyProgressStatus(ProgressStatus.Failure);
      return;
    }
    setVerifyProgressStatus(ProgressStatus.InProgress);
    verifyVisitorSession(verificationToken);
  }, []);

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      {verifyProgressStatus === ProgressStatus.InProgress && (
        <>
          <h1 className="text-4xl font-bold">Verifying your email...</h1>
          <div className="py-4 text-2xl font-medium">
            <LoadingSpinner color="blue" size={8} />
          </div>
        </>
      )}

      {verifyProgressStatus === ProgressStatus.Success && (
        <>
          {verificationStatus === VerificationStatus.Verified && (
            <>
              <h1 className="text-4xl font-bold">Identity Verified</h1>
              <div className="mt-4">
                <span className="flex h-8 w-8 items-center justify-center rounded-full bg-green-500 ring-8 ring-white">
                  <CheckIcon className="h-5 w-8 text-white" />
                </span>
              </div>
            </>
          )}
          {verificationStatus === VerificationStatus.Fraudulent && (
            <>
              <h1 className="font-gray-800 text-4xl font-bold">
                The session has been marked as Fraudulent
              </h1>
              <div className="mt-4">
                <span className="flex h-8 w-8 items-center justify-center rounded-full bg-green-500 ring-8 ring-white">
                  <CheckIcon className="h-5 w-8 text-white" />
                </span>
              </div>
            </>
          )}
          <p className="mt-4 text-xl">
            Thank you for making Flowlie a more secure and trusted place for
            everyone.
          </p>
        </>
      )}
      {verifyProgressStatus === ProgressStatus.Failure && (
        <>
          <h1 className="font-gray-800 text-4xl font-bold">
            Visitor Session Verification Failed
          </h1>
          <div className="mt-4">
            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-red-500 ring-8 ring-white">
              <XMarkIcon className="h-5 w-8 text-white" />
            </span>
          </div>
          <p className="mt-4 text-xl">Something went wrong...</p>
          <button
            type="button"
            onClick={() => navigate('/')}
            className="button my-4"
          >
            Take me to the home page
          </button>
        </>
      )}
    </div>
  );
}

export default VerifySessionHandler;
