import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { FUNCTIONAL_EXPERTISE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import { MAX_INVESTOR_EXP_FUNCTIONAL } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';

const ExpFunctionalField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      components={{ Menu: LimitedMenu }}
      isClearable
      isMulti
      label="Functional Expertise"
      maxCount={MAX_INVESTOR_EXP_FUNCTIONAL}
      name={InvestorFieldNames.ExpFunctional}
      options={FUNCTIONAL_EXPERTISE_OPTIONS}
      placeholder="Select expertise..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.ExpFunctional]: yup
      .array()
      .of(yup.string())
      .nullable()
      .max(
        MAX_INVESTOR_EXP_FUNCTIONAL,
        `At most ${MAX_INVESTOR_EXP_FUNCTIONAL} functional expertise are allowed`
      ),
  }),
};

export default ExpFunctionalField;
