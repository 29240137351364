import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  ComparableStartupAccessManagerView,
  StartupAccessManagerFieldFilters,
  StartupAccessManagerPaginatedCollectionResponse,
} from '/../libs/shared-types/src/types/view/StartupAccessManagerView';
import AccessManagerTable from './AccessManagerTable';
import DropdownSelect from '/src/components/DropdownSelect';
import BasicStatistic from '/src/components/cards/BasicStatistic';
import SearchBar from '/src/components/inputs/SearchBar';
import Alert from '/src/components/notifications/Alert';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { STARTUP_FLOWLINK_MANAGER_ROUTE } from '/src/constants/Routes';
import { TableHeader } from '/src/interfaces/TableHeader';
import { PaginatedRequestParams } from '/../libs/shared-types/src/types/view/APIResponse';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import EmptyState from '/src/components/notifications/EmptyState';
import { EyeSlashIcon } from '@heroicons/react/24/outline';
import { CsvDownloadButton } from './CsvDownloadButton';

export const defaultSortKeyInvestorAccess =
  'lastViewedOn' as keyof ComparableStartupAccessManagerView;

function getTableHeaders(canAccessInvestors: boolean) {
  const accessManagerTableHeaders: TableHeader<ComparableStartupAccessManagerView>[] =
    [
      {
        sortKey: canAccessInvestors ? 'name' : undefined,
        element: <span>Name</span>,
      },
      {
        sortKey: canAccessInvestors ? 'userType' : undefined,
        element: <span>Type</span>,
      },
      {
        sortKey: canAccessInvestors ? 'firm' : undefined,
        element: <span>Firm & Role</span>,
      },
      {
        sortKey: canAccessInvestors ? 'fitScore' : undefined,
        element: <span>Fit</span>,
      },
      {
        sortKey: canAccessInvestors ? 'totalViewTime' : undefined,
        element: (
          <span title="Total time spent viewing your profile">View Time</span>
        ),
      },
      {
        sortKey: canAccessInvestors ? 'totalViewCount' : undefined,
        element: <span title="Total number of profile views">Views</span>,
      },
      {
        sortKey: canAccessInvestors ? defaultSortKeyInvestorAccess : undefined,
        element: (
          <span title="Latest time your profile was viewed">Last Seen</span>
        ),
      },
    ];
  return accessManagerTableHeaders;
}

interface AccessManagerInvestorsProps {
  apiResponse: StartupAccessManagerPaginatedCollectionResponse;
  handleGetInvestorsEngagedCsv: () => void;
  isSearchLoading: boolean;
  stageOptions: string[];
  handleFilter: (filter: string) => Promise<void>;
  handleFilterByRoundStage: (roundStage: string) => Promise<void>;
  fieldFilters: any;
  fetchStartupAccessManager: (
    args: Partial<
      PaginatedRequestParams<
        ComparableStartupAccessManagerView,
        StartupAccessManagerFieldFilters
      >
    >,
  ) => Promise<void>;
}

function AccessManagerInvestors({
  apiResponse,
  isSearchLoading,
  stageOptions,
  handleFilter,
  handleFilterByRoundStage,
  handleGetInvestorsEngagedCsv,
  fieldFilters,
  fetchStartupAccessManager,
}: AccessManagerInvestorsProps) {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const canAccessInvestors = hasFeatureAccess(
    StartupPaidFeatures.AccessManagerInvestors,
    subscriptionTier,
  );

  const canDownloadCsv = hasFeatureAccess(
    StartupPaidFeatures.ViewSessionCsvExport,
    subscriptionTier,
  );

  return (
    <>
      {apiResponse.totalCount === 0 && apiResponse.filter === '' && (
        <EmptyState
          title="No investors have access to your one-pager yet"
          subTitle="Investors with a Flowlie account who view your one-pager will be listed here."
          icon={<EyeSlashIcon className="mx-auto h-12 w-12 text-gray-400" />}
          actionButton={
            <Link
              to={STARTUP_FLOWLINK_MANAGER_ROUTE}
              className="app-button--primary"
            >
              Share your deck & one-pager
            </Link>
          }
        />
      )}

      {(apiResponse.totalCount > 0 || apiResponse.filter !== '') && (
        <>
          <div className="mb-3 flex flex-row items-center justify-between">
            <span className="flex items-center">
              <SearchBar
                isDebounce
                placeholder="Search Investors"
                onQueryChange={handleFilter}
                initialValue={apiResponse.filter}
                isDisabled={!canAccessInvestors}
              />
              {isSearchLoading && <LoadingSpinner color="blue" />}
            </span>

            <div className="flex flex-row space-x-2">
              <DropdownSelect
                onSelectionChange={handleFilterByRoundStage}
                initialValue={fieldFilters['roundStage'] ?? ''}
                label="Round Stage"
                items={stageOptions}
              />
              <CsvDownloadButton
                handleDownload={handleGetInvestorsEngagedCsv}
                tooltip="Export to CSV a list of all investors who engaged with your one-pager & deck"
                isDisabled={!canDownloadCsv}
              />
            </div>
          </div>
          <AccessManagerTable
            headers={getTableHeaders(canAccessInvestors)}
            refreshData={fetchStartupAccessManager}
            parentPage={apiResponse.page}
            parentTotalCount={apiResponse.totalCount}
            parentSortOrder={apiResponse.sortOrder}
            parentSortedColumn={apiResponse.sortKey}
            parentFilter={apiResponse.filter}
            parentPerPage={apiResponse.perPage}
            parentTotalPages={apiResponse.totalPages}
            accessManagerViews={apiResponse.results}
            blurRowsAfterIndex={canAccessInvestors ? undefined : 4}
          />
        </>
      )}

      {apiResponse.totalCount > 0 && apiResponse.totalCount < 5 && (
        <div className="my-4">
          <Alert
            color="blue"
            alertType="Info"
            content={
              <p>
                <Link
                  className="hyperlink font-bold underline"
                  to={STARTUP_FLOWLINK_MANAGER_ROUTE}
                >
                  Send your FlowLink
                </Link>{' '}
                to investors to securely share your deck and company one-pager
              </p>
            }
            isShown={true}
            canDismiss={false}
          />
        </div>
      )}
    </>
  );
}

export default AccessManagerInvestors;
