import React from 'react'
import { InvestorListItemView } from '../../../types/view/InvestorListView';
import { StrengthTag } from '/src/util/generateTagCloud';
import { RELATIONSHIP_STRENGTH_OPTIONS } from '/../libs/shared-types/src/constants/Strength';

function InvestorCellRelationship({ investor }: { investor: InvestorListItemView }) {

  return (
    <div className="app-table-row-text">
      {investor.relationship && investor.relationship.strength ? (
        <StrengthTag
          strength={investor.relationship.strength}
          options={RELATIONSHIP_STRENGTH_OPTIONS}
        />
      ) : (
        '-'
      )}
    </div>
  )
}


export default InvestorCellRelationship