import * as yup from 'yup';
import {
  lowercaseRegex,
  numberRegex,
  specialCharRegex,
  uppercaseRegex,
} from '../../routes/common/Signup/passwordStrengthRegex';

const passwordSchema = yup
  .string()
  .matches(lowercaseRegex, 'One lowercase required')
  .matches(uppercaseRegex, 'One uppercase required')
  .matches(numberRegex, 'One number required')
  .matches(
    specialCharRegex,
    'One special character from !@#$%^_&*\'=()?<>,.+:;"[]{}/- is required'
  )
  .min(8, 'Minimum 8 characters required');

export default passwordSchema;
