import React, { useEffect, useState } from 'react';
import { FieldProps } from 'formik';
import CurrencyInput from 'react-currency-input-field';
import Tooltip from '/src/components/utility/Tooltip';
import FormikDebugger from './FormikDebugger';
import FormikErrorMessage from './FormikErrorMessage';

function containsAnyLetter(str: string) {
  return /[a-zA-Z]/.test(str);
}

interface CustomNumericInputProps extends FieldProps {
  allowDecimals?: boolean;
  allowNegativeValue?: boolean;
  className?: string;
  // NOTE: Currency must be a ISO 4217 currency code
  // see: https://en.wikipedia.org/wiki/ISO_4217
  currency?: 'USD' | 'GBP' | 'EUR';
  decimalScale?: number;
  decimalsLimit?: number;
  disabled?: boolean;
  fixedDecimalLength?: number;
  label: string;
  // NOTE: Locale must be a BCP 47 language tag,
  // but for the moment we will support only American English
  locale?: 'en-US';
  maxLength?: number;
  placeholder?: string;
  prefix?: string;
  secondaryLabel?: string;
  step?: number;
  suffix?: string;
  tooltip?: string;
}

/**
 * @note The input's value is a string rather than a number
 * @summary This is to allow users to type decimal numbers.
 * If the user wants to type '4.2', when they type '4.' the cast would return 4
 * therefore effectively preventing input of decimal values
 * @remember Transform the value to a number using the Number() constructor if you need to do math
 * @returns An input for numeric values
 */
function CustomNumericInput({
  allowDecimals = false,
  allowNegativeValue = false,
  className,
  currency,
  decimalScale,
  decimalsLimit = 2,
  disabled = false,
  field,
  fixedDecimalLength,
  form,
  label,
  locale = 'en-US',
  maxLength = 12,
  meta,
  placeholder,
  prefix,
  secondaryLabel,
  step,
  suffix,
  tooltip,
}: CustomNumericInputProps): JSX.Element {
  const [displayValue, setDisplayValue] = useState<string | undefined>(
    field.value
  );

  useEffect(() => {
    // Anytime the formik field value changes, update the display value
    setDisplayValue(field.value);
  }, [field.value]);

  return (
    <label htmlFor={field.name} className="input-label">
      <span className="flex flex-row items-center">
        {label}
        {tooltip && <Tooltip tooltipText={tooltip} />}
      </span>
      {secondaryLabel && (
        <p className="text-xs text-gray-800">{secondaryLabel}</p>
      )}
      <CurrencyInput
        allowDecimals={allowDecimals}
        allowNegativeValue={allowNegativeValue}
        className={className}
        decimalScale={decimalScale}
        decimalsLimit={decimalsLimit}
        disableAbbreviations={
          containsAnyLetter(prefix ?? '') || containsAnyLetter(suffix ?? '')
        }
        disabled={disabled}
        fixedDecimalLength={fixedDecimalLength}
        id={field.name}
        intlConfig={{ locale, currency }}
        maxLength={maxLength}
        name={field.name}
        onBlur={() => form.setFieldTouched(field.name, true)}
        onValueChange={(value, name) => {
          if (value === null || value === undefined) {
            form.setFieldValue(field.name, '');
            return;
          }
          form.setFieldValue(field.name, value);
        }}
        placeholder={placeholder}
        prefix={prefix}
        step={step}
        suffix={suffix}
        value={displayValue}
      />
      <FormikErrorMessage field={field} form={form} meta={meta} />
      <FormikDebugger field={field} form={form} meta={meta} />
    </label>
  );
}

export default CustomNumericInput;
