import React from 'react';
import twemoji from 'twemoji';

import NATIONALITY_OPTIONS from '/../libs/shared-types/src/constants/SelectOptions/NationalityOptions';

interface FlagEmojiProps {
  countryCode: string;
  className?: string;
}

function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char: string) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

function FlagEmoji({
  countryCode,
  className = '',
}: FlagEmojiProps): JSX.Element {
  const rootRef = React.createRef<HTMLElement>();

  function parseTwemoji(): void {
    const { current } = rootRef;
    twemoji.parse(current!, {
      folder: 'svg',
      ext: '.svg',
      base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/',
    });
  }

  React.useEffect(() => {
    parseTwemoji();
  }, [countryCode]);

  const content = (
    <div
      aria-label={countryCode}
      title={NATIONALITY_OPTIONS.find((x) => x.value === countryCode)?.label}
      className={className}
    >
      {getFlagEmoji(countryCode)}
    </div>
  );

  return React.createElement('div', { ref: rootRef }, content);
}

export default FlagEmoji;
