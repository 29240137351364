export enum SafeType {
  Capped = 'Capped',
  Uncapped = 'Uncapped',
}

export function getSafeTypeTooltip(safeType: string): string {
  switch (safeType) {
    case SafeType.Capped:
      return 'A SAFE that has a valuation cap at which the investors will convert in the future';
    case SafeType.Uncapped:
      return 'A SAFE that does not have a valuation cap, but may have a discount or a Most Favorable Nation (MFN) clause';
    default:
      return '';
  }
}
