import React from 'react';

import { formatImageAddress } from '/src/util/formatting/strings';
import { Founder } from '/../libs/shared-types/src/types/model/Founder';
import { generateTag, generateTagCloud } from '/src/util/generateTagCloud';
import { LinkedIn, Twitter } from './CustomIcons';
import { SocialHandle } from '/../libs/shared-types/src/types/model/SocialHandle';
import FlagEmoji from './utility/FlagEmoji';
import generateSocialHandles from '/src/util/generateSocialHandles';
import CopyTextButton from './CopyTextButton';
import { MapPinIcon } from '@heroicons/react/24/outline';
import UserAvatarWithInitials from './UserAvatarWithInitials';
import { BACKING_AMOUNT_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/BackingAmountOptions';
import { EXIT_AMOUNT_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/ExitAmountOptions';

function getFounderSocialHandles(
  linkedIn: string | undefined,
  twitter: string | undefined
): SocialHandle[] {
  const socialHandles: SocialHandle[] = [];
  if (linkedIn) {
    socialHandles.push({ icon: <LinkedIn />, name: 'LinkedIn', url: linkedIn });
  }
  if (twitter) {
    socialHandles.push({ icon: <Twitter />, name: 'Twitter', url: twitter });
  }
  return socialHandles;
}

interface FounderInfoProps {
  founder: Founder;
}

function getFlagEmojiMargin(flagIndex: number): string {
  if (flagIndex === 0) {
    return '-mt-10';
  }

  if (flagIndex === 1) {
    return '-mt-9';
  }

  if (flagIndex === 2) {
    return '-mt-8';
  }

  return '';
}

function FounderInfo({ founder }: FounderInfoProps): JSX.Element {
  const socialHandles = getFounderSocialHandles(
    founder.linkedIn,
    founder.twitter
  );

  return (
    <section>
      <div className="flex items-center space-x-3 lg:space-x-4">
        <figure className="relative">
          <UserAvatarWithInitials
            containerStyles="h-16 w-16"
            firstName={founder.firstName}
            lastName={founder.lastName}
            textStyles="text-2xl"
            imgAlt="Founder"
            imgSrc={formatImageAddress(founder.profilePicKey)}
          />
          {founder.nationalities && (
            <span className="absolute -bottom-6 right-0 flex flex-row -space-x-2">
              {founder.nationalities.map((countryCode, index) => (
                <FlagEmoji
                  key={countryCode}
                  className={`mx-1 h-6 w-6 ${getFlagEmojiMargin(index)}`}
                  countryCode={countryCode}
                />
              ))}
            </span>
          )}
        </figure>

        <div className="flex-grow space-y-1 text-sm leading-6">
          <header className="-mb-1 flex flex-row space-x-2">
            <h4 className="text-base font-medium">
              {`${founder.firstName} ${founder.lastName}`}
            </h4>
            {founder.pronouns && (
              <span className="text-sm">({founder.pronouns})</span>
            )}
          </header>
          <p className="text-gray-700">
            {founder.role}
            {founder.commitment && <span>&nbsp;({founder.commitment})</span>}
          </p>
          {founder.location && (
            <div className="flex items-center">
              <MapPinIcon className="h-5 w-5 text-gray-600" />
              <p className="truncate pl-1 text-gray-600">{founder.location}</p>
            </div>
          )}
          <section className="mt-1 flex items-center gap-2 text-blue-600">
            <a className="hyperlink" href={`mailto:${founder.email}`}>
              {founder.email}
            </a>
            <CopyTextButton text={founder.email} />
          </section>
        </div>

        <div>
          <div className="flex flex-wrap justify-end space-x-1">
            {founder.isTechnical && (
              <span
                title="Technical Founder"
                className="m-1 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
              >
                Technical Founder
              </span>
            )}

            {founder.companiesFoundedCount ? (
              <span
                title={
                  'Previously started ' +
                  founder.companiesFoundedCount +
                  ' companies'
                }
                className="m-1 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
              >
                {founder.companiesFoundedCount > 1
                  ? founder.companiesFoundedCount + 'x '
                  : ''}
                Repeat Founder
              </span>
            ) : (
              <></>
            )}

            {founder.previousBackingAmount &&
              founder.previousBackingAmount !==
                BACKING_AMOUNT_OPTIONS[0].value && (
                <span
                  title={`Previously raised ${founder.previousBackingAmount} in VC funding`}
                  className="m-1 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                >
                  Prev. VC Backed {founder.previousBackingAmount}
                </span>
              )}

            {founder.previousExitAmount &&
              founder.previousExitAmount !== EXIT_AMOUNT_OPTIONS[0].value && (
                <span
                  title={`Previously exited a company for ${founder.previousExitAmount}`}
                  className="m-1 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                >
                  Prev. Exit {founder.previousExitAmount}
                </span>
              )}
          </div>

          {socialHandles.length > 0 && (
            <div>
              <span className="mt-1 flex flex-wrap justify-end space-x-2">
                {generateSocialHandles(socialHandles)}
              </span>
            </div>
          )}
        </div>
      </div>

      <dl className="ml-4 mt-6 grid grid-cols-2 gap-x-4 gap-y-4">
        {founder.yearsOfIndustryExperience && (
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Industry Experience
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {`${founder.yearsOfIndustryExperience} years`}
            </dd>
          </div>
        )}

        {founder.highestEducationLevel && (
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Highest Education Level
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {founder.highestEducationLevel}
            </dd>
          </div>
        )}

        {founder.universityAffiliations &&
          founder.universityAffiliations.length > 0 && (
            <div className="col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                University Affiliation
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {generateTagCloud(founder.universityAffiliations)}
              </dd>
            </div>
          )}

        {founder.about && (
          <div className="col-span-2">
            <dt className="text-sm font-medium text-gray-500">About</dt>
            <dd className="mt-1 text-sm text-gray-900">{founder.about}</dd>
          </div>
        )}
      </dl>
    </section>
  );
}

export default FounderInfo;
