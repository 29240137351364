export enum DataRoomTasks {
  AdvisorAgreements = '6600f8c1033fcd764dd46ae3',
  AgencyAndPartnerContracts = '6600f8f9694d36e75ffb3b77',
  AllAwards = '6600f8fe1446661d6101751f',
  AllPodcastAppearances = '6600f9024519dedaa105f4b9',
  AllPressCoverage = '6600f9056b20cc800edb8a1a',
  BalanceSheet = '6600f9097c9f29bdbe3028f5',
  BoardOfDirectorsBios = '6600f90d1874eb18d53ba8a3',
  BusinessLicenses = '6600f911d023819d11994095',
  BylawsOrArticlesOfGovernance = '6600f914b8bbfe88002d1e91',
  CapTable = '6600f9189004e14e044212f6',
  CashFlowStatement = '6600f91c4131f759a4befca7',
  DebtDocuments = '6600f920b18c6c7fe7a0ec52',
  DetailedFounderTeamOrLeadershipBios = '6600f9233f307a2ea7153062',
  DraftInvestmentDocuments = '6600f9278ddb1babd79f22f3',
  ESOPDocumentation = '6600f92b40d48c8b1df87b6f',
  EmployeeOptionsAgreements = '6600f930ad427bfcaed06edb',
  EmploymentContracts = '6600f93543db2e0a5500b3e9',
  FinancialModelAndBudget = '6600f93803be36080cb7ed87',
  FounderShareAgreements = '6600f93cb712e94d4119505a',
  HistoricalInvestorUpdates = '6600f941fd7b2392d00473f9',
  IncomeStatementOrPL = '6600f94411d5876d10ab5615',
  IncorporationDocuments = '6600f94942e862b5ed3d0337',
  InsuranceDocuments = '6600f94d66f849e998d9d25a',
  KPIsHistoricalAndForecast = '6600f95109c74efd2d235718',
  MajorClientContractsOrLOIs = '6600f95599067894354d8ab2',
  MajorPartnershipAgreements = '6600f9591f292d371902311d',
  OrgChart = '6600f95db3c81d50ab8dc8e6',
  PatentsAndTrademarks = '6600f9619f70b5b805dffe60',
  ProductDemoVideo = '6600f9645d0e052344a3b268',
  ProductOverviewDeck = '6600f969907a38e2089bb6a5',
  ProductRoadmap = '6600f96f24ad9f4a0a17872c',
  QSBSPotential = '6600f972ce20b19a84e14e77',
  SalesPipeline = '6600f9751883fdb9d530be2a',
  SalesPresentationsOrDecks = '6600f979ac7deefc16cec7c2',
  TaxIdNumber = '6600f97db22a576b28c7f660',
  TaxFilings = '6600f98099a593466709919c',
  TechStackOverviewDiagram = '6600f984db96dd777d99059a',
}
