import React from 'react';
import DebugRender from '../DebugRender';
import {
  AndroidOsIcon,
  ChromeOsIcon,
  LinuxOsIcon,
  MacOsIcon,
  WindowsOsIcon,
} from '../../CustomIcons';
import { UA_DeviceOsNames } from '/../libs/shared-types/src/constants/UaParserValues';

interface DeviceOsIconProps {
  className?: string;
  deviceOs: string | undefined;
}

function DeviceOsIcon({ className = 'h-4 w-4', deviceOs }: DeviceOsIconProps) {
  if (!deviceOs) {
    return <></>;
  }

  switch (deviceOs) {
    case UA_DeviceOsNames.MacOS:
    case UA_DeviceOsNames.MacOS_V2:
      return <MacOsIcon className={className} />;
    case UA_DeviceOsNames.iOS:
      return <span title="iOS">iOS</span>;
    case UA_DeviceOsNames.Windows:
    case UA_DeviceOsNames.WindowsMobile:
    case UA_DeviceOsNames.WindowsPhone:
      return <WindowsOsIcon className={className} />;
    case UA_DeviceOsNames.Android:
    case UA_DeviceOsNames.Android_x86:
      return <AndroidOsIcon className={className} />;
    case UA_DeviceOsNames.Linux:
    case UA_DeviceOsNames.VectorLinux:
      return <LinuxOsIcon className={className} />;
    case UA_DeviceOsNames.ChromeOS:
    case UA_DeviceOsNames.ChromiumOS:
      return <ChromeOsIcon className={className} />;
    default:
      return (
        <DebugRender>
          <span>{deviceOs}</span>
        </DebugRender>
      );
  }
}

export default DeviceOsIcon;
