import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from 'react-router-dom';

import API from '/src/middleware/API';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import { PublicProfilePrivacySetting } from '/../libs/shared-types/src/constants/PublicProfilePrivacySetting';
import {
  SIGNUP_ROUTE,
  STARTUP_PROFILE_ROUTE,
  SCREENING_ROUTE,
  SHARE_LINK_DATA_ACCESS_ROUTE,
} from '/src/constants/Routes';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { LinkShareMetadata } from '/../libs/shared-types/src/types/view/LinkShareMetadata';
import {
  getVisitorNameAndEmail,
  removeVisitorSessionToken,
  saveVisitorSessionToken,
} from '/src/middleware/SessionStorage';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import AuthService from '/src/services/AuthService';
import blurredStartup from '/src/res/blurred_startup.jpg';
import DealPreview from './DealPreview';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import AuthDialog from '/src/components/Authentication/AuthDialog/AuthDialog';
import { FormOptions } from '/src/components/Authentication/AuthDialog/FormOptions';
import { localStorageGetAuthenticatedAccountType } from '/src/middleware/LocalStorage';
import { getLinkShareMetadata } from '/src/services/LinkShareMetadata';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';
import { DealDetailTypes } from '/../libs/shared-types/src/constants/DealDetailTypes';
import { DealViewSessionUpdateType } from '/../libs/shared-types/src/types/model/DealViewSession';
import useTrackViewTime from '/src/hooks/useTrackViewTime';
import {
  createNewAuthenticatedDealViewSession,
  createNotAuthenticatedDealViewSession,
  updateDealViewSession,
} from '/src/services/DealViewSession';
import useIdleStatus from '/src/hooks/useIdleStatus';
import IdlePrompt from '/src/components/utility/IdlePrompt';
import {
  ErrorCodes,
  ErrorMessages,
} from '/../libs/shared-types/src/constants/ErrorCodes';
import {
  PublicGetPublicStartup,
  StartupGetByDealShareToken,
  VisitorGetPublicStartup,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { DataRoomAccessStatus } from '/../libs/shared-types/src/constants/DataRoom/DataRoomAccessStatus';
import { verifyDataRoomPassword } from '/src/services/DataRoom';
import Logger from '/src/services/logger';
import { downloadPdf } from '/src/services/File';
import { getDeckFetchUrl } from '/src/services/deck/getDeckFetchUrl';

interface PublicDealContentProps {
  startup: PublicStartup;
  onRequestAccess: () => void;
  linkType: LinkTypes;
  dealViewSessionId: string;
  shareToken: string;
  handleAccessError: (message: string) => void;
}

function PublicDealContent({
  startup,
  onRequestAccess,
  linkType,
  dealViewSessionId,
  shareToken,
  handleAccessError,
}: PublicDealContentProps): JSX.Element {
  const [isViewingDeck, setIsViewingDeck] = useState(false);
  const { activate, isIdle, isIdlePromptShown } = useIdleStatus();

  async function updateDealViewTime() {
    if (!dealViewSessionId || isIdle || isViewingDeck) {
      return;
    }

    const updateStatus = await updateDealViewSession({
      dealViewSessionId,
      startupId: startup._id,
      updateType: DealViewSessionUpdateType.DealViewTime,
      shareToken,
    });
    if (updateStatus?.isError) {
      handleAccessError(updateStatus.message);
    }
  }

  async function updateDeckViewTime() {
    if (!dealViewSessionId || isIdle || !isViewingDeck) {
      return;
    }

    const updateStatus = await updateDealViewSession({
      dealViewSessionId,
      startupId: startup._id,
      updateType: DealViewSessionUpdateType.DeckViewTime,
      shareToken,
    });
    if (updateStatus?.isError) {
      handleAccessError(updateStatus.message);
    }
  }

  async function onOpenDataRoomModal() {
    if (!dealViewSessionId) {
      return;
    }

    await updateDealViewSession({
      dealViewSessionId,
      startupId: startup._id,
      updateType: DealViewSessionUpdateType.DataRoomAccessStatus,
      dataRoomAccessStatus: DataRoomAccessStatus.PasswordRequested,
    });
  }

  async function onVerifyDataRoomPassword(password: string) {
    if (!dealViewSessionId) {
      return {
        isError: true,
        message: 'Deal view session not found',
      };
    }

    return await verifyDataRoomPassword(
      startup._id,
      password,
      dealViewSessionId,
    );
  }

  async function onViewDeck() {
    if (!dealViewSessionId) {
      return;
    }

    setIsViewingDeck(true);
    const updateStatus = await updateDealViewSession({
      dealViewSessionId,
      startupId: startup._id,
      updateType: DealViewSessionUpdateType.DeckViewCount,
      shareToken,
    });
    if (updateStatus?.isError) {
      handleAccessError(updateStatus.message);
    }
  }

  async function onDownloadDeck() {
    if (!dealViewSessionId) {
      return;
    }

    const latestDeck = startup.decks[0];
    try {
      const url = getDeckFetchUrl(startup);

      if (url) {
        const deckUrlResponse = await API.get(url);
        await downloadPdf(deckUrlResponse.url, latestDeck.fileName);
      }

      await updateDealViewSession({
        dealViewSessionId,
        startupId: startup._id,
        updateType: DealViewSessionUpdateType.DeckDownloadCount,
      });
    } catch (error: any) {
      Logger.error(error);
    }
  }

  useTrackViewTime(updateDealViewTime);
  useTrackViewTime(updateDeckViewTime);

  return (
    <>
      <IdlePrompt open={isIdlePromptShown} onClose={activate} />
      <DealPreview
        partialStartup={startup}
        requestAccess={onRequestAccess}
        linkType={linkType}
        onOpenDataRoomModal={onOpenDataRoomModal}
        onVerifyDataRoomPassword={onVerifyDataRoomPassword}
        onViewDeck={onViewDeck}
        onDownloadDeck={onDownloadDeck}
        isViewingDeck={isViewingDeck}
        setIsViewingDeck={setIsViewingDeck}
      />
    </>
  );
}

interface PublicDealContainerProps {
  shareToken: string;
  linkType: LinkTypes;
}

function PublicDealContainer({
  shareToken,
  linkType,
}: PublicDealContainerProps): JSX.Element {
  const { setCachedPage } = useContext(SharedStateContext);
  const [publicStartup, setPublicStartup] = useState<PublicStartup>();
  const [dealViewSessionId, setDealViewSessionId] = useState<string>();
  const [showAuthDialog, setShowAuthDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { setInvitation } = useContext(AuthenticationContext);
  const [linkShareMetadata, setLinkShareMetadata] =
    useState<LinkShareMetadata>();
  const [initialDialogForm, setInitialDialogForm] = useState<FormOptions>(
    FormOptions.VisitorSession,
  );
  const [isLoading, setIsLoading] = useState(false);
  const { unconfirmedAccountType } = useContext(AuthenticationContext);
  const location = useLocation();
  const navigate = useNavigate();

  function handleAccessError(message: string) {
    if (message === ErrorMessages[ErrorCodes.InsufficientDataAccessRights]) {
      setInvitation(undefined);
      navigate(SHARE_LINK_DATA_ACCESS_ROUTE);
    } else {
      setErrorMessage(message);
    }
  }

  async function createDealViewSession() {
    const visitorInfo = getVisitorNameAndEmail();
    let newDealViewSession;

    try {
      if (AuthService.isLoggedIn() && linkType === LinkTypes.Flowlink) {
        // Don't create a deal view session, the authenticated user should have been redirected
        // to deal detail in App already
        return;
      }

      if (AuthService.isLoggedIn() && linkType === LinkTypes.Dealshare) {
        newDealViewSession = await createNewAuthenticatedDealViewSession(
          DealDetailTypes.DealPreview,
          shareToken,
          linkType,
          undefined,
          undefined,
        );
      } else if (visitorInfo) {
        // Create a Visitor deal view session
        newDealViewSession = await createNotAuthenticatedDealViewSession(
          shareToken,
          linkType,
          visitorInfo.firstName,
          visitorInfo.lastName,
          visitorInfo.email,
        );
        if (!newDealViewSession.signedVisitorAuthToken) {
          throw Error('Cannot create a visitor deal view session');
        }
        saveVisitorSessionToken(newDealViewSession.signedVisitorAuthToken);
      } else {
        // Create an Anonymous deal view session
        newDealViewSession = await createNotAuthenticatedDealViewSession(
          shareToken,
          linkType,
          undefined,
          undefined,
          undefined,
        );
      }
      setDealViewSessionId(newDealViewSession?.dealViewSession._id);
    } catch (error: any) {
      handleAccessError(error.message);
    }
  }

  async function fetchStartupBasedOnPrivacySetting(
    privacy: PublicProfilePrivacySetting,
    startupId?: string,
  ) {
    if (AuthService.isLoggedIn() && linkType === LinkTypes.Dealshare) {
      const response = await API.get<PublicStartup>(
        StartupGetByDealShareToken.buildEndpoint(shareToken),
      );
      setPublicStartup(response);
      await createDealViewSession();
      setIsLoading(false);
      return;
    }

    let startupData: PublicStartup;
    switch (privacy) {
      case PublicProfilePrivacySetting.Public:
        removeVisitorSessionToken();
        startupData = await API.get<PublicStartup>(
          PublicGetPublicStartup.buildEndpoint(startupId),
        );
        setPublicStartup(startupData);
        await createDealViewSession();
        setIsLoading(false);
        break;

      case PublicProfilePrivacySetting.RequireNameAndEmail:
        if (AuthService.isVisitorSession()) {
          // In case the visitor refreshes the page, avoid asking again to submit name and email
          try {
            startupData = await API.get<PublicStartup>(
              VisitorGetPublicStartup.buildEndpoint(),
            );
            // the ID of the startup for the existing session doesn't match
            // so we should remove it and proceed as if there is no existing session
            if (startupId && startupId !== startupData._id) {
              removeVisitorSessionToken();
            } else {
              setPublicStartup(startupData);
            }
          } catch (error: any) {
            handleAccessError(error.message);
          }
        }
        setInitialDialogForm(FormOptions.VisitorSession);
        setIsLoading(false);
        break;

      case PublicProfilePrivacySetting.RequireFlowlieAccount:
        setInitialDialogForm(FormOptions.Login);
        setIsLoading(false);
        setCachedPage({ url: location.pathname, accountType: null });
        break;

      default:
        setIsLoading(false);
        break;
    }
  }

  async function fetchDealshareMetadata() {
    try {
      const metadata = await getLinkShareMetadata(
        shareToken,
        LinkTypes.Dealshare,
      );
      if (!metadata || !metadata.privacy || !metadata.startup) {
        return;
      }

      setLinkShareMetadata(metadata);
      fetchStartupBasedOnPrivacySetting(
        metadata.privacy,
        (metadata.startup as any)._id,
      );
    } catch (error: any) {
      navigate(SIGNUP_ROUTE);
    }
  }

  async function fetchFlowlinkMetadata() {
    try {
      const metadata = await getLinkShareMetadata(
        shareToken,
        LinkTypes.Flowlink,
      );
      if (!metadata || !metadata.privacy || !metadata.startup) {
        return;
      }

      setLinkShareMetadata(metadata);
      fetchStartupBasedOnPrivacySetting(
        metadata.privacy,
        (metadata.startup as any)._id,
      );
    } catch (error: any) {
      navigate(SIGNUP_ROUTE);
    }
  }

  function requestAccess() {
    if (!AuthService.isLoggedIn()) {
      setShowAuthDialog(true);
      return;
    }

    const accountType = localStorageGetAuthenticatedAccountType();
    if (accountType === AccountTypes.Investor) {
      navigate(SCREENING_ROUTE);
      return;
    }
    if (accountType === AccountTypes.Startup) {
      navigate(STARTUP_PROFILE_ROUTE);
      return;
    }
    navigate('/app');
  }

  async function onVisitorSessionFormSubmitted() {
    if (!linkShareMetadata || !linkShareMetadata.privacy) {
      return;
    }
    await createDealViewSession();
    await fetchStartupBasedOnPrivacySetting(linkShareMetadata.privacy);
  }

  useEffect(() => {
    if (linkType === LinkTypes.Dealshare) {
      setIsLoading(true);
      fetchDealshareMetadata();
    }

    if (linkType === LinkTypes.Flowlink) {
      setIsLoading(true);
      fetchFlowlinkMetadata();
    }
  }, []);

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner size={14} color="blue" />
      </div>
    );
  }

  if (publicStartup && dealViewSessionId) {
    return (
      <div className="bg-gray-100">
        <Helmet>
          <title>{publicStartup.name} on Flowlie</title>
        </Helmet>
        <div>
          <div className="mx-auto min-h-screen w-full px-6 lg:max-w-[90rem]">
            <PublicDealContent
              startup={publicStartup}
              onRequestAccess={requestAccess}
              linkType={linkType}
              shareToken={shareToken}
              handleAccessError={handleAccessError}
              dealViewSessionId={dealViewSessionId}
            />
          </div>

          {
            // This handles secondary AuthFlow
            linkShareMetadata && (
              <AuthDialog
                canCloseForm
                isOpen={showAuthDialog}
                setIsOpen={setShowAuthDialog}
                linkType={linkType}
                parentError={errorMessage}
                privacySetting={
                  PublicProfilePrivacySetting.RequireFlowlieAccount
                }
                onVisitorSessionCreated={onVisitorSessionFormSubmitted}
                initialForm={
                  linkShareMetadata.privacy ===
                    PublicProfilePrivacySetting.RequireNameAndEmail
                    ? FormOptions.Signup
                    : FormOptions.Login
                }
                config={{
                  consumerAccountType: unconfirmedAccountType,
                  sharerAccountType: AccountTypes.Startup,
                  startupName: linkShareMetadata.startup?.name ?? '',
                  startupAccountFirstName:
                    linkShareMetadata.startup?.accountFirstName ?? '',
                  sharerFirstName:
                    linkShareMetadata.investor?.firstName ??
                    linkShareMetadata.startup?.accountFirstName ??
                    '',
                  companyLogo: linkShareMetadata.startup?.logoKey ?? '',
                  role: linkShareMetadata.investor?.role,
                  firm: linkShareMetadata.investor?.firm,
                }}
              />
            )
          }
        </div>
      </div>
    );
  }

  if (
    (linkShareMetadata &&
      linkShareMetadata.privacy !== PublicProfilePrivacySetting.Public) ||
    (linkShareMetadata && !publicStartup)
  ) {
    return (
      <div>
        <figure className="h-screen w-full">
          <img
            src={blurredStartup}
            className="h-full w-full"
            alt="blurred startup"
          />
        </figure>
        <AuthDialog
          linkType={linkType}
          canCloseForm={false}
          isOpen
          parentError={errorMessage}
          privacySetting={
            linkShareMetadata.privacy ??
            PublicProfilePrivacySetting.RequireFlowlieAccount
          }
          onVisitorSessionCreated={onVisitorSessionFormSubmitted}
          initialForm={initialDialogForm}
          config={{
            consumerAccountType: unconfirmedAccountType,
            sharerAccountType: AccountTypes.Startup,
            startupName: linkShareMetadata.startup?.name ?? '',
            startupAccountFirstName:
              linkShareMetadata.startup?.accountFirstName ?? '',
            sharerFirstName:
              linkShareMetadata.investor?.firstName ??
              linkShareMetadata.startup?.accountFirstName ??
              '',
            companyLogo: linkShareMetadata.startup?.logoKey ?? '',
            role: linkShareMetadata.investor?.role,
            firm: linkShareMetadata.investor?.firm,
          }}
        />
      </div>
    );
  }

  return (
    <div className="flex h-screen items-center justify-center">
      <LoadingSpinner size={14} color="blue" />
    </div>
  );
}

export default PublicDealContainer;
