import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { PRONOUNS_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

const PronounsField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      isClearable
      label="Your pronouns"
      name={InvestorFieldNames.Pronouns}
      options={PRONOUNS_OPTIONS}
      placeholder="Select pronouns..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.Pronouns]: yup.string().nullable(),
  }),
};

export default PronounsField;
