import API from '../middleware/API';
import { getUserIpAddress } from './GetUserIpAddress';
import Logger from './logger';
import {
  InvestorCreateNewDealViewSession,
  PublicCreateNewDealViewSession,
  PublicUpdateDealViewSession,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { DealDetailTypes } from '/../libs/shared-types/src/constants/DealDetailTypes';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import { EXAMPLE_STARTUP } from '/../libs/shared-types/src/constants/UserActivation/MockData/ExampleStartup';
import { ViewerTypes } from '/../libs/shared-types/src/constants/ViewerTypes';
import {
  DealViewSession,
  DealViewSessionUpdate,
} from '/../libs/shared-types/src/types/model/DealViewSession';
import { NewDealViewSessionView } from '/../libs/shared-types/src/types/view/NewDealViewSessionView';

/**
 * Out of app we do not have the startupId, because we must first create the DealViewSession before we can fetch the startup.
 * This results in a fuction signature that is messy.
 * @param dealDetailType The type of deal detail being viewed @see DealDetailTypes
 * @param shareToken If out of app, the link's share token. In app, undefined
 * @param linkType If out of app, the type of link. In app, undefined
 * @param startupId If out of app, undefined. In app, the id of the startup
 * @returns
 */
export async function createNewAuthenticatedDealViewSession(
  dealDetailType: DealDetailTypes,
  shareToken: string | undefined,
  linkType: LinkTypes | undefined,
  startupId: string | undefined,
  requestId: string | undefined
) {
  if (startupId === EXAMPLE_STARTUP._id) {
    return {
      dealViewSession: {
        _id: 'activationTour',
        browser: 'none',
        createdOn: new Date(),
        dealDetailViewTime: 0,
        deckStat: {
          deckId: 'activationTour_deckId',
          downloadCount: 0,
          lastDownloadedOn: new Date(),
          lastViewedOn: new Date(),
          viewCount: 0,
          viewTime: 0,
        },
        deviceOs: 'none',
        deviceType: 'none',
        ipAddress: 'none',
        dealDetailType: DealDetailTypes.DealFull,
        startupId: EXAMPLE_STARTUP._id,
        viewerType: ViewerTypes.Authenticated,
      },
    };
  }
  try {
    const ipAddress = await getUserIpAddress();
    return await API.post<NewDealViewSessionView>(
      InvestorCreateNewDealViewSession.buildEndpoint(),
      {
        ipAddress,
        dealDetailType,
        shareToken,
        linkType,
        startupId,
        requestId,
      }
    );
  } catch (error: any) {
    Logger.error(error);
    return undefined;
  }
}

/**
 *
 * @param shareToken The link's share token
 * @param linkType The type of link
 * @param firstName Required for Visitors, undefined for Anonymous viewers
 * @param lastName Required for Visitors, undefined for Anonymous viewers
 * @param email Required for Visitors, undefined for Anonymous viewers
 */
export async function createNotAuthenticatedDealViewSession(
  shareToken: string,
  linkType: LinkTypes,
  firstName: string | undefined,
  lastName: string | undefined,
  email: string | undefined
) {
  const ipAddress = await getUserIpAddress();
  return await API.post<NewDealViewSessionView>(
    PublicCreateNewDealViewSession.buildEndpoint(),
    {
      ipAddress,
      shareToken,
      linkType,
      firstName,
      lastName,
      email,
    }
  );
}

export async function updateDealViewSession(
  dealViewSessionUpdate: DealViewSessionUpdate
) {
  if (dealViewSessionUpdate.startupId === EXAMPLE_STARTUP._id) {
    return {
      isError: false,
      message: 'Cannot update view stats on example startup',
    };
  }

  try {
    await API.put<DealViewSession>(
      PublicUpdateDealViewSession.buildEndpoint(),
      {
        dealViewSessionUpdate,
      }
    );
    return { isError: false, message: 'Success' };
  } catch (error: any) {
    return { isError: true, message: error.message };
  }
}
