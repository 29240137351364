import {
  PublicVerifyDataRoomPassword,
  StartupDeleteDataRoomLink,
  StartupUpdateDataRoomLink,
  StartupVerifyDataRoomPassword,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '../middleware/API';
import { VerifyLinkPasswordResponse } from '../types/view/APIResponse';

export async function updateDataRoomLink(url: string, password?: string) {
  return await API.put(StartupUpdateDataRoomLink.buildEndpoint(), {
    url,
    password,
  });
}

export async function deleteDataRoomLink() {
  return await API.delete(StartupDeleteDataRoomLink.buildEndpoint());
}

/**
 * Public route that allows anyone with a DealViewSessionId to verify the password of a Data Room.
 * @param startupId
 * @param password
 * @param dealViewSessionId
 * @returns
 */
export async function verifyDataRoomPassword(
  startupId: string,
  password: string,
  dealViewSessionId: string,
): Promise<VerifyLinkPasswordResponse> {
  return await API.post(PublicVerifyDataRoomPassword.buildEndpoint(), {
    startupId,
    password,
    dealViewSessionId,
  });
}

/**
 * To be used only by the Authenticated Startup that owns the Data Room.
 * This is to allow the Founder to verify their own password from the Profile Preview .
 * @param startupId
 * @param password
 * @returns
 */
export async function startupVerifyDataRoomPassword(
  startupId: string,
  password: string,
): Promise<VerifyLinkPasswordResponse> {
  return await API.post(StartupVerifyDataRoomPassword.buildEndpoint(), {
    startupId,
    password,
  });
}
