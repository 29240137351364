import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import { INV_IND_ANY_INDUSTRY } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import { STARTUP_IND_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/StartupIndustryOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import { MAX_ANTI_INVESTOR_INDUSTRIES } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';
import { flattenOptionsToList } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';

function getDisabledOptions(invIndustries: string[]): string[] {
  if (!invIndustries || invIndustries.length === 0) {
    return [];
  }

  const optionsValues = flattenOptionsToList(STARTUP_IND_OPTIONS);

  const disabledOptions = optionsValues.filter((x) =>
    invIndustries.includes(x),
  );
  return disabledOptions;
}

function FieldWrapper() {
  const { values } = useFormikContext<any>();

  return (
    <Field
      className="custom-select"
      component={CustomSelect}
      components={{ Menu: LimitedMenu }}
      isClearable
      isMulti
      label="What industries would you never invest in?"
      maxCount={MAX_ANTI_INVESTOR_INDUSTRIES}
      name={InvestorFieldNames.AntiInvIndustries}
      options={STARTUP_IND_OPTIONS}
      placeholder="Select industries..."
      disabledOptions={getDisabledOptions(
        values[InvestorFieldNames.InvIndustries],
      )}
    />
  );
}

const AntiInvIndustriesField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.AntiInvIndustries]: yup
      .array()
      .of(yup.string())
      .nullable()
      .test(
        'agnosticIsSelected',
        `Since your investment industries is "${INV_IND_ANY_INDUSTRY}", you cannot select anti-investment industries`,
        (antiInvIndustries, context): boolean => {
          const invIndustries =
            context.parent[InvestorFieldNames.InvIndustries];
          if (
            antiInvIndustries &&
            antiInvIndustries.length > 0 &&
            invIndustries?.includes(INV_IND_ANY_INDUSTRY)
          ) {
            // Return false to indicate the test failed
            return false;
          }
          return true;
        },
      )
      .test(
        'invIndustriesOverlapWithAntiInvIndustries',
        'Your primary investment industries overlap with your anti-investment industries',
        (selectedAntiInvIndustries, context): boolean => {
          const invIndustries =
            context.parent[InvestorFieldNames.InvIndustries];
          const disabledOptions = getDisabledOptions(invIndustries);
          if (
            invIndustries &&
            selectedAntiInvIndustries?.some(
              (x) => x && disabledOptions.includes(x),
            )
          ) {
            // Return false to indicate the test failed
            return false;
          }
          return true;
        },
      )
      .max(
        MAX_ANTI_INVESTOR_INDUSTRIES,
        `At most ${MAX_ANTI_INVESTOR_INDUSTRIES} anti-investment industries are allowed`,
      ),
  }),
};

export default AntiInvIndustriesField;
