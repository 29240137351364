import API from '../middleware/API';
import { RequestTypes } from '/../libs/shared-types/src/constants/RequestTypes';
import {
  AggregateDealShareRequest,
  ConnectionRequestAccountData,
} from '/../libs/shared-types/src/types/view/APIResponse';
import ApiUrls from '/src/constants/ApiUrls';

export async function fetchReceivedDealShareRequests(query?: string) {
  const data = await API.get<AggregateDealShareRequest[]>(
    ApiUrls.requests.getIncoming(RequestTypes.DealShare, query)
  );
  return data;
}

export async function fetchOutgoingDealShareRequests(query?: string) {
  const data = await API.get<AggregateDealShareRequest[]>(
    ApiUrls.requests.getOutgoing(RequestTypes.DealShare, query)
  );

  return data;
}

export async function fetchConnectionRequests() {
  const data = await API.get<ConnectionRequestAccountData[]>(
    ApiUrls.requests.getIncoming(RequestTypes.Connection)
  );
  return data;
}

export async function fetchSentConnectionRequests() {
  const data = await API.get<ConnectionRequestAccountData[]>(
    ApiUrls.requests.getOutgoing(RequestTypes.Connection)
  );

  return data;
}
