import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import {
  RUNWAY_MAX,
  RUNWAY_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import MonthsInput from '/src/components/inputs/MonthsInput';
import { getPluralSuffix } from '/src/util/formatting/numbers';
import isInputShown from '../InputShown';
import { getHelperTextRunwayTarget, roundHelper } from '../RoundHelpers';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  const helper = roundHelper.find(
    (x) =>
      x.roundStage === values[RoundFieldNames.RoundStage] &&
      x.isBridge === (values[RoundFieldNames.IsBridge] === true)
  );
  const secondaryLabel = helper ? getHelperTextRunwayTarget(helper) : '';

  return isInputShown(RoundFieldNames.RunwayTarget, values) ? (
    <MonthsInput
      disabled={values[RoundFieldNames.IsEditable] === 'false'}
      label="What is your target runway after this round?"
      tooltip="The Runway represents the number of months a company has before it runs out of cash calculated as Total Cash (post-round) / Average Monthly Expenses."
      secondaryLabel={secondaryLabel}
      name={RoundFieldNames.RunwayTarget}
    />
  ) : (
    <></>
  );
}

const RunwayTargetField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.RunwayTarget]: yup
      .number()
      .min(
        RUNWAY_MIN,
        `Must be greater than or equal to ${RUNWAY_MIN} month${getPluralSuffix(
          RUNWAY_MIN
        )}`
      )
      .max(
        RUNWAY_MAX,
        `Cannot be greater than ${RUNWAY_MAX} month${getPluralSuffix(
          RUNWAY_MAX
        )}`
      )
      .typeError('Must be a number'),
  }),
};

export default RunwayTargetField;
