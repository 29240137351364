import { SubscriptionTiers } from '../constants/SubscriptionTiers';
import getBoolean from '../util/converters/BooleanConverter';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';

export interface LocalStorageAccountData {
  accountEmail: string;
  accountFirstName: string;
  accountLastName: string;
}

/**
 * Safely gets item from local storage
 * @param {string} itemName The item to get from local storge.
 * @throws If there is no item in local storage matching that key
 */
function getItemFromLocalStorage(itemName: string): string {
  const result = localStorage.getItem(itemName);
  if (result && result !== '' && result !== undefined) {
    return result;
  }
  return '';
}

export function localStorageGetAccountData(): LocalStorageAccountData {
  const accountEmail = getItemFromLocalStorage(
    LocalStorageKeysConst.ACCOUNT_EMAIL
  );
  const accountFirstName = getItemFromLocalStorage(
    LocalStorageKeysConst.ACCOUNT_FIRST_NAME
  );
  const accountLastName = getItemFromLocalStorage(
    LocalStorageKeysConst.ACCOUNT_LAST_NAME
  );

  const data = {
    accountEmail,
    accountFirstName,
    accountLastName,
  };

  return data;
}

export function localStorageSetAccountTypeAndAuthenticationToken(
  accountType: AccountTypes,
  token: string
) {
  localStorage.setItem(LocalStorageKeysConst.ACC_TYPE, accountType);
  localStorage.setItem(LocalStorageKeysConst.FLOWLIE_TOKEN, token);
}

export function localStorageSetAccountData(
  accountEmail: string,
  accountFirstName: string,
  accountLastName: string
) {
  localStorage.setItem(LocalStorageKeysConst.ACCOUNT_EMAIL, accountEmail);
  localStorage.setItem(
    LocalStorageKeysConst.ACCOUNT_FIRST_NAME,
    accountFirstName
  );
  localStorage.setItem(
    LocalStorageKeysConst.ACCOUNT_LAST_NAME,
    accountLastName
  );
}

export function localStorageSetAccountFirstLastName(
  accountFirstName: string,
  accountLastName: string
) {
  localStorage.setItem(
    LocalStorageKeysConst.ACCOUNT_FIRST_NAME,
    accountFirstName
  );
  localStorage.setItem(
    LocalStorageKeysConst.ACCOUNT_LAST_NAME,
    accountLastName
  );
}

export function localStorageSetSubscriptionTier(
  subscriptionTier: SubscriptionTiers
) {
  localStorage.setItem(
    LocalStorageKeysConst.SUBSCRIPTION_TIER,
    subscriptionTier
  );
}

export function localStorageGetAccountEmail(): string {
  const accountEmail = getItemFromLocalStorage(
    LocalStorageKeysConst.ACCOUNT_EMAIL
  );
  return accountEmail;
}

export function localStorageGetAuthenticatedAccountType(): string {
  const accountType = localStorage.getItem(LocalStorageKeysConst.ACC_TYPE);
  return accountType ?? '';
}

export function localStorageGetDebugMode(): boolean {
  const debugMode = localStorage.getItem(LocalStorageKeysConst.DEBUG_MODE);
  return getBoolean(debugMode);
}

export function localStorageGetIsDarkTheme(): boolean {
  const isDarkTheme = localStorage.getItem(LocalStorageKeysConst.IS_DARK_THEME);
  return getBoolean(isDarkTheme);
}

export function localStorageGetInvestorActiveView(): boolean {
  const showBoard = sessionStorage.getItem(
    LocalStorageKeysConst.INVESTOR_ACTIVE_VIEW
  );
  return getBoolean(showBoard);
}

export function localStorageSetInvestorActiveView(showBoard: boolean): void {
  sessionStorage.setItem(
    LocalStorageKeysConst.INVESTOR_ACTIVE_VIEW,
    showBoard.toString()
  );
}
