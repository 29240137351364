export enum DataRoomViewStatus {
  NoLink = 'No link provided',
  NotClicked = 'Not clicked',
  Clicked = 'Clicked, no password',
  ClickedWrongPassword = 'Clicked, wrong password',
  Viewed = 'Viewed',
}

export const DataRoomViewStatusMap = new Map([
  [
    DataRoomViewStatus.NoLink,
    {
      color: 'bg-gray-100 text-gray-800',
      tooltip:
        'You did not have a data room link on your company one-pager at the time of this view session',
    },
  ],
  [
    DataRoomViewStatus.NotClicked,
    {
      color: 'bg-gray-100 text-gray-800',
      tooltip: 'The viewer did not click on the data room button',
    },
  ],
  [
    DataRoomViewStatus.Clicked,
    {
      color: 'bg-orange-100 text-orange-800',
      tooltip:
        'The viewer clicked on the data room button but did not enter a password',
    },
  ],
  [
    DataRoomViewStatus.ClickedWrongPassword,
    {
      color: 'bg-red-100 text-red-800',
      tooltip:
        'The viewer clicked on the data room button and entered the wrong password',
    },
  ],
  [
    DataRoomViewStatus.Viewed,
    {
      color: 'bg-green-100 text-green-800',
      tooltip:
        'The viewer clicked on the data room button and entered the correct password',
    },
  ],
]);
