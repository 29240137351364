import React from 'react';
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
import TailwindColors from '../../constants/TailwindColors';
import { joinClassNames } from '/src/util/formatting/strings';
import Alert from './Alert';

type ColorOptions = Extract<TailwindColors, 'red' | 'blue'>;
type DialogStyles = {
  iconBg: string;
  iconColor: string;
  buttonBg: string;
  buttonFocusRing: string;
  buttonHoverBg: string;
  buttonText: string;
};

const ColorClasses = new Map<ColorOptions, DialogStyles>([
  [
    'red',
    {
      iconBg: 'bg-red-100',
      iconColor: 'text-red-600',
      buttonBg: 'bg-red-600',
      buttonFocusRing: 'focus:ring-red-500',
      buttonHoverBg: 'hover:bg-red-700',
      buttonText: 'text-white',
    },
  ],
  [
    'blue',
    {
      iconBg: 'bg-blue-100',
      iconColor: 'text-blue-600',
      buttonBg: 'bg-blue-600',
      buttonFocusRing: 'focus:ring-blue-500',
      buttonHoverBg: 'hover:bg-blue-700',
      buttonText: 'text-white',
    },
  ],
]);

interface SimpleDialogProps {
  onCancel: (() => void) | undefined;
  onPrimaryAction: () => void;
  title: string;
  text: string | JSX.Element;
  primaryAction: string;
  color: ColorOptions;
  iconType?: 'InformationCircle' | 'ExclamationTriangle';
  errorMessage?: string;
}

function SimpleDialog({
  onCancel,
  onPrimaryAction,
  title,
  text,
  primaryAction,
  color,
  iconType = 'ExclamationTriangle',
  errorMessage = '',
}: SimpleDialogProps): JSX.Element {
  const style = ColorClasses.get(color) ?? {
    iconBg: 'bg-gray-100',
    iconColor: 'text-gray-600',
    buttonBg: 'bg-white',
    buttonFocusRing: 'focus:ring-blue-500',
    buttonHoverBg: 'hover:bg-gray-50',
    buttonText: 'text-gray-700',
  };
  return (
    <div className="min-w-[24rem] bg-white p-4 sm:max-w-2xl sm:p-7">
      <div className="sm:flex sm:items-start">
        <div
          className={`mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full ${style.iconBg} sm:mx-0 sm:h-10 sm:w-10`}
        >
          {iconType === 'ExclamationTriangle' && (
            <ExclamationTriangleIcon className={`h-6 w-6 ${style.iconColor}`} />
          )}
          {iconType === 'InformationCircle' && (
            <InformationCircleIcon className={`h-6 w-6 ${style.iconColor}`} />
          )}
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            {title}
          </Dialog.Title>

          <Dialog.Description
            className="mt-2  text-sm text-gray-500"
            as="section"
          >
            {text}
          </Dialog.Description>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          onClick={onPrimaryAction}
          type="button"
          className={joinClassNames(
            'mx-2 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2',
            style.buttonBg,
            style.buttonFocusRing,
            style.buttonHoverBg,
            style.buttonText,
          )}
        >
          {primaryAction}
        </button>
        {onCancel !== undefined && (
          <button
            onClick={onCancel}
            type="button"
            className="app-button--neutral"
          >
            Cancel
          </button>
        )}
      </div>

      <div className="my-4">
        <Alert
          color="red"
          alertType="Error"
          content={errorMessage}
          isShown={errorMessage !== ''}
          canDismiss={false}
        />
      </div>
    </div>
  );
}

export default SimpleDialog;
