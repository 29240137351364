import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { INVESTOR_LOCATION_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import formatGroupLabel from '/src/components/inputs/ReactSelectAdditions/selectFormatGroupLabel';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

const LocationField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      groupLabelFormat={formatGroupLabel}
      label="Where do you live?"
      name={InvestorFieldNames.Location}
      options={INVESTOR_LOCATION_OPTIONS}
      placeholder="Select location..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.Location]: yup.string().required('Required'),
  }),
};

export default LocationField;
