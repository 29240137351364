import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormStep from '/src/interfaces/FormStep';
import INVESTOR_FIELD_MAPPING from './InvestorFormFieldMapping';

const steps: FormStep[] = [
  {
    label: 'What type of investor are you?',
    fields: [
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Type],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Firm],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Role],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.IsAccredited],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Location],
    ],
    isOptional: false,
  },
  {
    label: 'What kind of startups do you invest in?',
    fields: [
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvStages],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvGeographies],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvIndustries],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvBusinessModels],
    ],
    isOptional: false,
  },
  {
    label: 'Can you share more context on your typical investment?',
    fields: [
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvTypes],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.LeadsRounds],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.CheckRangeMin],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.CheckRangeMax],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvImpacts],
    ],
    isOptional: false,
  },
  {
    label: 'Last but not least, how do you want to be contacted?',
    fields: [
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.IsOpenToColdInbound],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.PreferenceContact],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Website],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.LinkedIn],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Twitter],
    ],
    isOptional: false,
  },
];

export default steps;
