import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { INV_GEO_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import { MAX_INVESTOR_GEOGRAPHIES } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';

const InvGeographiesField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      components={{ Menu: LimitedMenu }}
      isClearable
      isMulti
      label="What specific geographic regions do you invest in?"
      maxCount={MAX_INVESTOR_GEOGRAPHIES}
      name={InvestorFieldNames.InvGeographies}
      options={INV_GEO_OPTIONS}
      placeholder="Select geographies..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.InvGeographies]: yup
      .array()
      .of(yup.string())
      .test(
        'agnosticOnly',
        `"${INV_GEO_OPTIONS[0].value}" already includes all geographies`,
        (geographies): boolean => {
          if (geographies?.includes(INV_GEO_OPTIONS[0].value)) {
            // Return false to indicate the test failed
            return geographies.length === 1;
          }
          return true;
        },
      )
      .max(
        MAX_INVESTOR_GEOGRAPHIES,
        `At most ${MAX_INVESTOR_GEOGRAPHIES} investment geographies are allowed`,
      )
      .min(1, 'At least one is required')
      .required('This field is required'),
  }),
};

export default InvGeographiesField;
