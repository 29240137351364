import React from 'react';
import {
  FitScoreLabels,
  getFitLabelColor,
} from '/../libs/shared-types/src/types/model/FitScore';
import TagCell from './TagCell';

interface FitLabelTagCellProps {
  fitLabel: FitScoreLabels | undefined;
}

function FitLabelTagCell({ fitLabel }: FitLabelTagCellProps) {
  if (!fitLabel) {
    return <span className="app-table-row-text">-</span>;
  }

  const color = getFitLabelColor(fitLabel);

  return (
    <TagCell
      type="comparison"
      tag={fitLabel}
      defaultClassName={color.bgColor + ' ' + color.textColor}
    />
  );
}

export default FitLabelTagCell;
