import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import MonthsInput from '/src/components/inputs/MonthsInput';
import {
  CONVERSION_TERM_MAX,
  CONVERSION_TERM_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import { getPluralSuffix } from '/src/util/formatting/numbers';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.ConversionTerm, values) ? (
    <MonthsInput
      disabled={values[RoundFieldNames.IsEditable] === 'false'}
      label="Conversion Term"
      name={RoundFieldNames.ConversionTerm}
      tooltip="The duration of the convertible note before it matures and becomes repayable. If there hasn’t been a conversion yet and the note matures, the investors are free to demand repayment of the principal and accumulated interest"
      secondaryLabel="Typically between 12 and 24 months"
    />
  ) : (
    <></>
  );
}

const ConversionTermField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.ConversionTerm]: yup
      .number()
      .nullable()
      .when(RoundFieldNames.RoundType, {
        is: (roundType: string) => RoundTypes.ConvertibleNote === roundType,
        then: (schema) =>
          schema
            .typeError('Must be a number')
            .min(
              CONVERSION_TERM_MIN,
              `Conversion term cannot be less than ${CONVERSION_TERM_MIN} month${getPluralSuffix(
                CONVERSION_TERM_MIN,
              )}`,
            )
            .max(
              CONVERSION_TERM_MAX,
              `Conversion term cannot be greater than ${CONVERSION_TERM_MAX} month${getPluralSuffix(
                CONVERSION_TERM_MAX,
              )}`,
            )
            .required('Required'),
      }),
  }),
};

export default ConversionTermField;
