import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { toDashCase } from '/src/util/formatting/strings';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';
import ReferralsManager from './ReferralsManager';
import TabsHeader from '/src/components/tabs/TabsHeader';
import ReferralsOffer from './ReferralsOffer';

function Settings() {
  const tabs = [{ name: 'Earn' }, { name: 'My Referrals' }];

  return (
    <main>
      <header>
        <h2 className="page-title">Refer & Earn</h2>
        <TabsHeader tabs={tabs} />
      </header>
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <Routes>
            {['', tabs[0].name].map((path) => (
              <Route
                key={path}
                path={toDashCase(path)}
                element={<ReferralsOffer />}
              />
            ))}

            <Route
              path={toDashCase(tabs[1].name)}
              element={<ReferralsManager />}
            />

            <Route
              path="*"
              element={<Navigate to={NOT_FOUND_ROUTE} replace />}
            />
          </Routes>
        </div>
      </div>
    </main>
  );
}

export default Settings;
