import React, { useContext, useEffect, useState } from 'react';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import { UserPlusIcon as UserPlusIconSolid } from '@heroicons/react/24/solid';
import EmptyState from '/src/components/notifications/EmptyState';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { useNavigate } from 'react-router-dom';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import SearchBar from '/src/components/inputs/SearchBar';
import { Person } from '/../libs/shared-types/src/types/model/Person';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { StartupDeletePerson } from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import {
  STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE,
  STARTUP_PERSON_DETAIL_ROUTE,
} from '/src/constants/Routes';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import Toast from '/src/components/notifications/Toast';
import { ERROR, SUCCESS } from '/src/constants/SuccessMessages';
import {
  ComparablePersonView,
  PersonsPaginatedCollectionResponse,
  PersonView,
} from '../../../types/view/PersonView';
import { PaginatedRequestParams } from '../../../types/view/APIResponse';
import { SortOrder } from '/src/interfaces/Sortable';
import Logger from '/src/services/logger';
import BasePaginatedTable from '/src/components/table/baseTable/BasePaginatedTable';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { PERSON_ACTION, PersonMapper } from './PersonMapper';
import ImportPersonDialog from './ImportPersonDialog';

interface MyNetworkPersonsProps {
  apiResponse?: PersonsPaginatedCollectionResponse;
  isSearchLoading: boolean;
  handleFilter: (filter: string) => Promise<void>;
  fetchPersons: (
    args: Partial<PaginatedRequestParams<ComparablePersonView>>,
  ) => Promise<void>;
}

function MyNetworkPersons({
  apiResponse,
  isSearchLoading,
  handleFilter,
  fetchPersons,
}: MyNetworkPersonsProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();
  const [modalConfig, setModalConfig] = useState<{
    type: '' | 'edit' | 'delete';
    isModalOpen: boolean;
    person: PersonView | undefined;
  }>({
    type: '',
    isModalOpen: false,
    person: undefined,
  });
  const [resetSelectedTrigger, setResetSelectedTrigger] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  function closeModal() {
    setModalConfig({ type: '', isModalOpen: false, person: undefined });
    setErrorMessage('');
  }

  async function handleSavePerson() {
    await fetchPersons({ ...apiResponse });
    closeModal();
  }

  async function handleDeletePerson(investor: PersonView) {
    try {
      await API.delete<Person>(
        `${StartupDeletePerson.buildEndpoint(investor._id)}`,
      );
      await fetchPersons({ ...apiResponse });
      closeModal();
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }

  if (subscriptionTier === SubscriptionTiers.StartupFree) {
    return (
      <div className="mt-6 w-full rounded-md bg-white p-8 shadow">
        <p className="font-medium">
          Import your connections and discover who can help you fundraise
        </p>
        <ul className="mt-4 list-inside list-disc">
          <li>Import all your non-investor connections into Flowlie</li>
          <li>
            Define relationships to find the strongest intro to your target
            investors
          </li>
          <li>All your connections stay private to you</li>
        </ul>
        <p className="my-6">
          Try Flowlie Pro for free for 14 days to unlock the full investor
          database, target lists, and the ability to manage your investor
          pipeline.
        </p>
        <div className="w-max">
          <SubscriptionCTAPill
            id="cta_my_network_persons"
            text="Import your connections"
          />
        </div>
      </div>
    );
  }

  if (!apiResponse) {
    return <></>;
  }

  const onAction = async ({ type, payload }: any) => {
    const refetch = false;

    if (type === PERSON_ACTION.REFRESH || refetch) {
      fetchPersons({ ...apiResponse });
    }

    if (type === PERSON_ACTION.DELETE_RELATIONSHIP) {
      setModalConfig({
        type: 'delete',
        isModalOpen: true,
        person: payload.person,
      });
    }

    if (type === PERSON_ACTION.EDIT_RELATIONSHIP) {
      setModalConfig({
        type: 'edit',
        isModalOpen: true,
        person: payload.person,
      });
    }
  };

  const onSelectedChange = (rowsId: string[]) => {
    setSelectedRows(rowsId);
  };

  const resetSelectedRows = () => {
    setResetSelectedTrigger((prev) => !prev);
  };

  const onRowClick = (rowKey: string) => {
    navigate(`${STARTUP_PERSON_DETAIL_ROUTE}/${rowKey}`);
  };

  const onSortChange = ({
    headerKey,
    sort,
  }: {
    headerKey: string;
    sort: SortOrder | null;
  }) => {
    const params = {
      sortOrder: sort === null ? SortOrder.Asc : sort,
      sortKey: headerKey as keyof ComparablePersonView,
      page: headerKey !== apiResponse?.sortKey ? 1 : undefined,
    };
    fetchPersons(params);
  };

  const onPageChange = async (newPage: number) => {
    try {
      await fetchPersons({ page: newPage });
    } catch (error: any) {
      Logger.error(error.message);
    }
  };

  const onPerPageChange = async (newPerPage: number) => {
    try {
      await fetchPersons({ perPage: newPerPage });
    } catch (error: any) {
      Logger.error(error.message);
    }
  };

  const rows = new PersonMapper(
    !selectedRows.length ? onAction : undefined,
  ).mapAllTo(apiResponse.results);

  return (
    <>
      {apiResponse.totalCount === 0 && apiResponse.filter === '' && (
        <EmptyState
          title="You have not imported any connections yet"
          subTitle="Create and manage connections from your own personal network."
          icon={<UserPlusIcon className="mx-auto h-12 w-12 text-gray-400" />}
          actionButton={
            <button
              type="button"
              className="app-button--primary"
              onClick={() =>
                setModalConfig({
                  type: 'edit',
                  isModalOpen: true,
                  person: undefined,
                })
              }
            >
              Add Connection
            </button>
          }
        />
      )}

      {(apiResponse.totalCount > 0 || apiResponse.filter !== '') && (
        <>
          <div className="mb-3 flex flex-wrap items-center justify-between gap-x-3 gap-y-3">
            <span className="relative flex items-center">
              <SearchBar
                isDebounce
                placeholder="Search Imported connections"
                onQueryChange={handleFilter}
                initialValue={apiResponse.filter}
                isDisabled={false}
              />
              {isSearchLoading && (
                <LoadingSpinner
                  color="blue"
                  className="absolute right-[-2rem]"
                />
              )}
            </span>
            {/* {!!selectedRows.length && (
              <div className="flex-grow space-x-3">
                <button
                  type="button"
                  className="app-button--neutral"
                  onClick={handleAddInvestorsToPipeline}
                  disabled={isAddingToPipeline}
                >
                  <CurrencyDollarIcon className="mr-2 h-5 w-5" />
                  Add to Pipeline
                </button>
                <button
                  type="button"
                  className="app-button--neutral"
                  onClick={() =>
                    setModalConfig({
                      type: 'lists',
                      isModalOpen: true,
                      person: undefined,
                    })
                  }
                  disabled={isAddingToTargetList}
                >
                  <div className="relative mr-2">
                    <QueueListIcon className="h-5 w-5" aria-hidden="true" />
                    <ArrowLongUpIcon
                      className="absolute -bottom-0.5 -right-1 h-3.5 w-3.5 bg-white text-gray-700"
                      aria-hidden="true"
                    />
                  </div>
                  Add to Target Lists
                </button>
              </div>
            )} */}
            <button
              type="button"
              className="app-button--primary"
              onClick={() => {
                handleFilter('');
                setModalConfig({
                  type: 'edit',
                  isModalOpen: true,
                  person: undefined,
                });
              }}
            >
              <UserPlusIconSolid className="mr-2 h-5 w-5" />
              Add Connection
            </button>
          </div>

          <BasePaginatedTable
            tableName="imported-investors-table"
            onSortChange={onSortChange}
            // onSelectedChange={onSelectedChange}
            // onRowClick={onRowClick}
            // resetSelectedTrigger={resetSelectedTrigger}
            // enableSelect
            enableSort
            headerStructure={[
              {
                headerKey: 'name',
                label: 'Name',
              },
              {
                headerKey: 'firm',
                label: 'Firm & Role',
              },
              {
                headerKey: 'email',
                label: 'email',
              },
              {
                headerKey: 'links',
                label: 'links',
              },
              {
                headerKey: 'updatedOn',
                label: 'Updated On',
              },
              {
                headerKey: 'action',
                label: 'Action',
                className: 'sr-only',
              },
            ]}
            rows={rows.map((row) => ({
              rowKey: row.id,
              data: row,
            }))}
            resultCount={apiResponse.totalCount}
            perPage={apiResponse.perPage}
            page={apiResponse.page}
            pageCount={apiResponse.totalPages}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            requestParams={{
              page: apiResponse.page,
              perPage: apiResponse.perPage,
              sortOrder: apiResponse.sortOrder,
              sortKey: apiResponse.sortKey,
              filter: apiResponse.filter,
            }}
          />
        </>
      )}

      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => closeModal()}>
        {modalConfig.type === 'edit' && (
          <ImportPersonDialog
            person={modalConfig.person}
            onCancel={() => closeModal()}
            onSave={handleSavePerson}
          />
        )}
        {modalConfig.type === 'delete' && modalConfig.person && (
          <SimpleDialog
            onCancel={closeModal}
            onPrimaryAction={() => handleDeletePerson(modalConfig.person!)}
            title="Are you sure you want to delete this connection?"
            text="This action cannot be undone."
            primaryAction="Delete Connection"
            color="red"
            errorMessage={errorMessage}
          />
        )}
      </ModalWrapper>
      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.isError ? ERROR : SUCCESS}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
    </>
  );
}

export default MyNetworkPersons;
