import React, { ReactNode } from 'react';
import { cn } from '/src/util/cn';

export interface BaseTableRowProps {
  rowKey: string;
  toggleSelectedRow: (rowKey: string) => void;
  children: ReactNode;
  className?: string;
  enableSelect: boolean;
  isChecked: boolean;
  isAnySelected: boolean;
}

export default function TableRow({
  children,
  className,
  rowKey,
  enableSelect,
  isChecked,
  isAnySelected,
  toggleSelectedRow,
}: BaseTableRowProps) {
  const handleToggleCheckbox = () => {
    toggleSelectedRow(rowKey);
  };

  return (
    <tr className={cn('group/row bg-white', className)}>
      {enableSelect && (
        <td
          className={cn(
            'ml-1 text-center align-middle opacity-0 group-hover/row:opacity-100',
            isAnySelected ? 'opacity-100' : '',
          )}
        >
          <input
            type="checkbox"
            id={`checkbox-${rowKey}`}
            name={`checkbox-${rowKey}`}
            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-0"
            checked={isChecked}
            onChange={handleToggleCheckbox}
          />
        </td>
      )}
      {children}
    </tr>
  );
}
