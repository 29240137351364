import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import { REVENUE_TYPE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import FormatOptionWithTooltipLabel from '../../inputs/ReactSelectAdditions/formatOptionWithTooltipLabel';
import { FormatOptionLabelMeta } from 'react-select';
import { TooltipSelectOption } from '/../libs/shared-types/src/types/SelectOptions';

const RevenueTypeField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      formatOptionLabel={(
        option: TooltipSelectOption,
        formatOptionLabelMeta: FormatOptionLabelMeta<any>
      ) => (
        <FormatOptionWithTooltipLabel
          option={option}
          formatOptionLabelMeta={formatOptionLabelMeta}
        />
      )}
      isClearable
      secondaryLabel="What is the primary way you charge for your product or service?"
      label="Primary Revenue Type"
      name={StartupFieldNames.RevenueType}
      options={REVENUE_TYPE_OPTIONS}
      placeholder="Select revenue type..."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.RevenueType]: yup.string().nullable(),
  }),
};

export default RevenueTypeField;
