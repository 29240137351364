import React, { useEffect, useState } from 'react';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { StartupGetDataRoom } from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import DataRoomLinkForm from './DataRoomLinkForm';
import DataRoomChecklist from './DataRoomChecklist';
import { DataRoomView } from '/../libs/shared-types/src/types/view/APIResponse';

function DataRoomRoute(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [dataRoom, setDataRoom] = useState<DataRoomView>();

  async function fetchDataRoom() {
    try {
      const data = await API.get<DataRoomView>(
        StartupGetDataRoom.buildEndpoint(),
      );
      setDataRoom(data);
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchDataRoom();
  }, []);

  if (isLoading) {
    return <PageLoadingSpinner message="Loading data room... 🗄️" />;
  }

  if (!dataRoom) {
    return <div>Error loading data room</div>;
  }

  return (
    <main>
      <header className="sticky top-0 z-20 bg-gray-100 pb-0">
        <div className="relative flex max-w-full items-center justify-between">
          <h3 className="page-title">Manage Data Room Link</h3>
        </div>
      </header>

      <DataRoomLinkForm
        dataRoomView={dataRoom}
        refreshDataRoom={fetchDataRoom}
      />

      <DataRoomChecklist dataRoomView={dataRoom} />
    </main>
  );
}

export default DataRoomRoute;
