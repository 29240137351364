import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import PercentageInput from '../../inputs/PercentageInput';
import { REVENUE_TYPE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import {
  TAKE_RATE_MAX,
  TAKE_RATE_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.RevenueType] &&
    values[StartupFieldNames.RevenueType] === REVENUE_TYPE_OPTIONS[5].value ? (
    <PercentageInput
      label="Average Take Rate"
      name={StartupFieldNames.AvgTakeRate}
      tooltip="The percentage of GMV/GTV that the marketplace/facilitator retains as revenue."
    />
  ) : (
    <></>
  );
}

const AvgTakeRateField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.AvgTakeRate]: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .min(TAKE_RATE_MIN, `Must be greater than or equal to ${TAKE_RATE_MIN}%`)
      .max(TAKE_RATE_MAX, `Cannot be greater than ${TAKE_RATE_MAX}%`),
  }),
};

export default AvgTakeRateField;
