import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikValues } from 'formik';
import * as yup from 'yup';
import { UserPlusIcon } from '@heroicons/react/24/outline';

import { CONNECTION_SUCCESS } from '/src/constants/SuccessMessages';
import { localStorageGetAccountEmail } from '/src/middleware/LocalStorage';
import API from '/src/middleware/API';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import Alert from '/src/components/notifications/Alert';
import Logger from '/src/services/logger';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { RequestTypes } from '/../libs/shared-types/src/constants/RequestTypes';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import ConnectionMessageField from '/src/components/InvestorFlowlinkForm/fields/ConnectionMessageField';
import InvestorFlowlinkFormFieldNames from '/src/enums/InvestorFlowlinkFormFieldNames';
import {
  InvestorInviteConnections,
  InvestorRequestsNew,
} from '/../libs/shared-types/src/constants/ApiRoutes';

enum FormFields {
  Email = 'inviteContact', // Cannot be set to 'email' because Safari will try to autocomplete
  Message = 'ConnectionMessage', // MUST BE value of InvestorFlowlinkFormFieldNames.ConnectionMessage
}

const validationSchema = yup.object({
  [FormFields.Email]: yup
    .string()
    .email('The email is not valid')
    .test(
      'ownAccountEmail',
      'You cannot input your own email',
      (email): boolean => {
        const ownEmail = localStorageGetAccountEmail();
        if (email === ownEmail) {
          // Return false to indicate the test failed
          return false;
        }
        return true;
      }
    )
    .required('You must enter an email if you want to add a contact'),
  [FormFields.Message]:
    ConnectionMessageField.validation.fields[
      InvestorFlowlinkFormFieldNames.ConnectionMessage
    ],
});

interface AddConnectionDialogProps {
  onClose: () => void;
  onSuccess: (message: string) => void;
}

function AddConnectionDialog({
  onClose,
  onSuccess,
}: AddConnectionDialogProps): JSX.Element {
  const [notFoundModalOpen, setNotFoundModalOpen] = useState(false);
  const [alertIsShown, setAlertIsShown] = useState(false);
  const [alertText, setAlertText] = useState('');
  const toggleNotFoundModal = () => setNotFoundModalOpen(!notFoundModalOpen);

  const inviteToJoinFlowlie = async (
    values: FormikValues,
    resetForm: () => void
  ): Promise<void> => {
    try {
      await API.put(InvestorInviteConnections.buildEndpoint(), {
        email: values[FormFields.Email],
        message: values[FormFields.Message],
      });
      resetForm();
      toggleNotFoundModal();
      onSuccess('Invite Email Sent');
    } catch (error) {
      Logger.error(error);
    }
  };

  function showAlert() {
    setAlertIsShown(true);
    window.setTimeout(() => setAlertIsShown(false), 3000);
  }

  const sendContactRequest = async (
    contactEmail: string,
    message: string,
    resetForm: () => void
  ) => {
    try {
      const response = await API.post(InvestorRequestsNew.buildEndpoint(), {
        type: RequestTypes.Connection,
        newRequestData: {
          email: contactEmail,
          message,
        },
      });
      if (response.error === true) {
        if (response.message === 'User not found') {
          toggleNotFoundModal();
        } else {
          setAlertText(response.message);
          resetForm();
          showAlert();
        }
      } else {
        resetForm();
        onSuccess(CONNECTION_SUCCESS);
      }
    } catch (error: any) {
      if (error.message === 'User not found') {
        toggleNotFoundModal();
      }
      Logger.error(error);
    }
  };

  return (
    <main className="bg-white p-4 sm:w-screen sm:max-w-3xl sm:p-7">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <div className="mb-2">
          <Alert
            alertType="Warning"
            color="yellow"
            content={alertText}
            isShown={alertIsShown}
            onClose={() => setAlertIsShown(false)}
          />
        </div>
        <h3 className="h4 mb-4">Add Contact</h3>
        <Formik
          initialValues={{ [FormFields.Email]: '', [FormFields.Message]: '' }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              await sendContactRequest(
                values[FormFields.Email],
                values[FormFields.Message],
                resetForm
              );
            } catch (error) {
              Logger.error(error);
            }
          }}
        >
          {({
            resetForm,
            values,
            isSubmitting,
            isValid,
            dirty,
            errors,
            touched,
          }) => (
            <Form>
              <label htmlFor={FormFields.Email}>
                {/* Hiding this to prevent safari auto complete 
                <span className="sr-only">Add contact by email</span> */}
                <div className="my-2 flex rounded-md shadow-sm">
                  <div className="relative flex-grow focus-within:z-10">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <UserPlusIcon className="h-5 w-5 text-gray-400" />
                    </div>

                    <Field
                      name={FormFields.Email}
                      type="text"
                      className="w-full rounded-md border-gray-300 pl-10 focus:border-blue-500 focus:ring-blue-500 sm:block sm:text-sm"
                      placeholder="investor@example.com"
                    />
                  </div>
                </div>
              </label>

              <>
                {errors[FormFields.Email] && touched[FormFields.Email] && (
                  <p className="app-error-message">
                    {errors[FormFields.Email]}
                  </p>
                )}
              </>

              {ConnectionMessageField.fieldComponent}

              <ErrorMessage name="contactEmail">
                {(msg) => <p className="app-error-message">{msg}</p>}
              </ErrorMessage>

              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="app-button--primary"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  Connect
                  {isSubmitting && (
                    <div className="mr-2 w-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
                <button
                  onClick={onClose}
                  type="button"
                  className="app-button--neutral mr-2"
                >
                  Cancel
                </button>
              </div>

              <ModalWrapper
                open={notFoundModalOpen}
                onClose={() => setNotFoundModalOpen(false)}
              >
                <SimpleDialog
                  onCancel={() => {
                    toggleNotFoundModal();
                  }}
                  onPrimaryAction={() => inviteToJoinFlowlie(values, resetForm)}
                  title="This email is not on Flowlie"
                  text="You will automatically connect when they create an account"
                  primaryAction="Invite"
                  color="blue"
                />
              </ModalWrapper>
            </Form>
          )}
        </Formik>
      </div>
    </main>
  );
}

export default AddConnectionDialog;
