import { BillingFrequency } from '/../libs/shared-types/src/constants/BillingFrequency';
import { SubscriptionTiers } from '../constants/SubscriptionTiers';
import { ToastConfiguration } from '../interfaces/ToastConfiguration';
import API from '../middleware/API';
import { getPriceId } from '/../libs/shared-types/src/constants/SubscriptionPrices';
import Logger from './logger';

const env = process.env.REACT_APP_ENV;

export async function handleCallStripe(
  billingFrequency: BillingFrequency,
  subscriptionTier: SubscriptionTiers
): // Maybe it is best to return an error code?
Promise<ToastConfiguration> {
  try {
    const priceId = getPriceId(env, billingFrequency, subscriptionTier);
    const url = await API.post('/stripe/checkout', {
      priceId,
    });
    window.open(url, '_self');
    return {
      title: 'Redirecting to Stripe...',
      message: '',
      isError: false,
    };
  } catch (error: any) {
    Logger.error(error);
    if (error.message === 'Subscription already active') {
      return {
        title: 'Already subscribed!',
        message:
          'You are already subscribed to Flowlie. Please refresh the page.',
        isError: true,
      };
    } else {
      return {
        title: 'Something went wrong...',
        message: 'If the issue persists, contact support@flowlie.com',
        isError: true,
      };
    }
  }
}

export async function createStripeCustomerPortal(): Promise<ToastConfiguration> {
  try {
    const url = await API.post('/stripe/create-customer-portal-session', {});
    window.open(url, '_self');
    return {
      title: 'Redirecting to Stripe...',
      message: '',
      isError: false,
    };
  } catch (error: any) {
    Logger.error(error);
    return {
      title: 'Something went wrong...',
      message: 'If the issue persists, contact support@flowlie.com',
      isError: true,
    };
  }
}
