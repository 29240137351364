import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { toDashCase } from '/src/util/formatting/strings';
import TabsHeader from '/src/components/tabs/TabsHeader';
import CurrentRoundManager from './CurrentRoundManager';
import RoundHistory from './RoundHistory';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';
import { StartupGetRoundHistory } from '/../libs/shared-types/src/constants/ApiRoutes';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import InvestorPipeline from './InvestorPipeline/InvestorPipeline';
import { isRoundOpen, roundIsInGracePeriod } from '/src/util/rounds';

function RoundManagerRoute(): JSX.Element {
  const tabs = [{ name: 'Current Round' }, { name: 'Funding History' }];
  const [rounds, setRounds] = useState<RoundView[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const showPipeline =
    rounds.length > 0 &&
    (isRoundOpen(rounds[0]) || roundIsInGracePeriod(rounds[0]));

  async function getRoundHistory() {
    try {
      setIsLoading(rounds.length === 0);
      const data = await API.get<RoundView[]>(
        StartupGetRoundHistory.buildEndpoint(),
      );
      setRounds(data);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getRoundHistory();
  }, []);

  if (isLoading) {
    return (
      <PageLoadingSpinner message="Loading your fundraising history... 💰" />
    );
  }

  return (
    <main>
      <header>
        <h2 className="page-title">Manage Your Fundraising Rounds</h2>

        <TabsHeader tabs={tabs} />
      </header>
      <section className="my-4">
        <Routes>
          {['', tabs[0].name].map((path) => (
            <Route
              key={path}
              path={toDashCase(path)}
              element={
                <div className="space-y-10">
                  <CurrentRoundManager onRoundUpdated={getRoundHistory} />
                  {showPipeline && <InvestorPipeline />}
                </div>
              }
            />
          ))}
          <Route
            path={toDashCase(tabs[1].name)}
            element={<RoundHistory rounds={rounds} />}
          />
          <Route path="*" element={<Navigate to={NOT_FOUND_ROUTE} replace />} />
        </Routes>
      </section>
    </main>
  );
}

export default RoundManagerRoute;
