import React from 'react';
import * as yup from 'yup';
import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';

import FormField from '/src/interfaces/FormField';
import BooleanSelectInput from '../../inputs/BooleanSelectInput';

const IsLeadField: FormField = {
  fieldComponent: (
    <BooleanSelectInput
      label="Are you the lead investor?"
      name={RecordInvestmentFieldNames.IsLead}
      tooltip="Have you participated in this round with the largest amount of capital and set the terms of the deal used by yourself and all the other investors who participated?"
    />
  ),
  validation: yup.object({
    [RecordInvestmentFieldNames.IsLead]: yup.bool().required('Required'),
  }),
};

export default IsLeadField;
