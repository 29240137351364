import React, { useState, MouseEvent, ReactNode, ReactElement } from 'react';
import { InvestorListItemView } from '../../../types/view/InvestorListView';
import { joinClassNames } from '/src/util/formatting/strings';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import {
  StartupInvestorRelationshipUpdate,
  StartupRemoveInvestorFromInvestorList,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import DropdownButton from '/src/components/DropdownButton';
import { Menu } from '@headlessui/react';
import {
  CurrencyDollarIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import { UsersIcon } from '@heroicons/react/20/solid';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { InvestorRelationship } from '../../../types/model/InvestorRelationship';
import { InvestorDataType } from '../../../constants/InvestorDataType';
import {
  TARGET_LIST_DETAILS_ACTION,
  TargetListActionProps,
} from '/src/routes/startup/InvestorListDetail/InvestorListDetailRoute';
import UpdateInvestorRelationshipDialog from '/src/routes/startup/RoundManager/InvestorPipeline/UpdateInvestorRelationshipDialog';

export interface TargetInvestorActionProps {
  investor: InvestorListItemView;
  listId: string;
  onAction: ({ type, payload }: TargetListActionProps) => void;
}

async function updateInvestorRelationship(
  investorId: string,
  investorDataType: InvestorDataType,
  update: Partial<InvestorRelationship>,
) {
  let isSuccess = false;
  try {
    await API.put(StartupInvestorRelationshipUpdate.buildEndpoint(), {
      investorId,
      investorDataType,
      update,
    });
    isSuccess = true;
  } catch (error: any) {
    Logger.error(error.message);
    throw error;
  } finally {
    return { isSuccess };
  }
}

async function onRemoveInvestorFromList({
  listId,
  investorId,
}: {
  listId: string;
  investorId: string;
}) {
  let isSuccess = false;
  try {
    await API.put(StartupRemoveInvestorFromInvestorList.buildEndpoint(), {
      listId: listId,
      investorId: investorId,
    });
    isSuccess = true;
  } catch (error) {
    Logger.error(error);
  } finally {
    return { isSuccess };
  }
}

function InvestorCellTargetDetailAction({
  investor,
  listId,
  onAction,
}: TargetInvestorActionProps) {
  const [modalConfig, setModalConfig] = useState<{
    type: undefined | 'updateInvestorRelationship';
    isOpen: boolean;
    investor: InvestorListItemView | undefined;
  }>({
    type: undefined,
    isOpen: false,
    investor: undefined,
  });

  function closeModal() {
    setModalConfig({ type: undefined, isOpen: false, investor: undefined });
  }

  const handleUpdateInvestorRelationship = (investor: InvestorListItemView) =>
    setModalConfig({
      type: 'updateInvestorRelationship',
      isOpen: true,
      investor,
    });

  return (
    <>
      <div className="app-table-row-text">
        <DropdownButton
          label={'Manage Investor'}
          button={
            <Menu.Button
              className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
              onClick={(e: any) => e.stopPropagation()}
            >
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          }
        >
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleUpdateInvestorRelationship(investor);
                }}
                className={joinClassNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex w-full items-center px-4 py-2 text-sm',
                )}
                title="Update investor relationship"
              >
                <UsersIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                Update Relationship
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  onAction({
                    type: TARGET_LIST_DETAILS_ACTION.ADD_TO_PIPELINE,
                    payload: {
                      investorId: investor.investorId,
                      investorDataType: investor.investorDataType,
                    },
                  });
                }}
                className={joinClassNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex w-full items-center px-4 py-2 text-sm',
                )}
                title="Add investor to Pipeline"
              >
                <CurrencyDollarIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                Add to Pipeline
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={async (e: any) => {
                  e.stopPropagation();
                  const { isSuccess } = await onRemoveInvestorFromList({
                    listId,
                    investorId: investor.investorId,
                  });
                  if (isSuccess) {
                    onAction({
                      type: TARGET_LIST_DETAILS_ACTION.REFRESH,
                    });
                  }
                }}
                className={joinClassNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex w-full items-center px-4 py-2 text-sm',
                )}
                title="Remove investor from this List"
              >
                <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                Remove from List
              </button>
            )}
          </Menu.Item>
        </DropdownButton>
      </div>

      <ModalWrapper open={modalConfig.isOpen} onClose={() => closeModal()}>
        {modalConfig.type === 'updateInvestorRelationship' &&
          modalConfig.investor && (
            <UpdateInvestorRelationshipDialog
              investorName={modalConfig.investor.name}
              relationship={modalConfig.investor.relationship}
              onCancel={closeModal}
              onSave={async (update: Partial<InvestorRelationship>) => {
                if (!modalConfig.investor) {
                  return Promise.resolve();
                }

                const { isSuccess } = await updateInvestorRelationship(
                  modalConfig.investor.investorId,
                  modalConfig.investor.investorDataType,
                  update,
                );
                closeModal();
                if (isSuccess) {
                  onAction({
                    type: TARGET_LIST_DETAILS_ACTION.REFRESH,
                  });
                }
              }}
            />
          )}
      </ModalWrapper>
    </>
  );
}

export default InvestorCellTargetDetailAction;

export interface ActionUpdateInvRelationshipProps {
  active: boolean;
  investor: InvestorListItemView;
  onAction: ({ type, payload }: TargetListActionProps) => void;
}

export function ActionUpdateInvRelationship({
  active,
  investor,
  onAction,
}: ActionUpdateInvRelationshipProps) {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleOnUpdateRelationship = () => {
    setIsOpen(true);
  };

  return (
    <>
      <button
        type="button"
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          handleOnUpdateRelationship();
        }}
        className={joinClassNames(
          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
          'group flex w-full items-center px-4 py-2 text-sm',
        )}
        title="Update investor relationship"
      >
        <UsersIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        Update Relationship
      </button>

      <ModalWrapper open={isOpen} onClose={() => closeModal()}>
        {investor && (
          <UpdateInvestorRelationshipDialog
            investorName={investor.name}
            relationship={investor.relationship}
            onCancel={closeModal}
            onSave={async (update: Partial<InvestorRelationship>) => {
              if (!investor) {
                return;
              }

              const { isSuccess } = await updateInvestorRelationship(
                investor.investorId,
                investor.investorDataType,
                update,
              );

              if (isSuccess) {
                closeModal();
                onAction({
                  type: TARGET_LIST_DETAILS_ACTION.REFRESH,
                });
              }
            }}
          />
        )}
      </ModalWrapper>
    </>
  );
}

export interface ActionAddToPipelineProps {
  active: boolean;
  investor: InvestorListItemView;
  onAction: ({ type, payload }: TargetListActionProps) => void;
}

export function ActionAddToPipeline({
  active,
  investor,
  onAction,
}: ActionAddToPipelineProps) {
  return (
    <button
      type="button"
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onAction({
          type: TARGET_LIST_DETAILS_ACTION.ADD_TO_PIPELINE,
          payload: {
            investorId: investor.investorId,
            investorDataType: investor.investorDataType,
          },
        });
      }}
      className={joinClassNames(
        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
        'group flex w-full items-center px-4 py-2 text-sm',
      )}
      title="Add investor to Pipeline"
    >
      <CurrencyDollarIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      Add to Pipeline
    </button>
  );
}

export interface CellActionProps {
  active: boolean;
  icon: ReactNode;
  title: string;
  onAction: () => void;
}

export function CellAction({ active, title, icon, onAction }: CellActionProps) {
  return (
    <button
      type="button"
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onAction();
      }}
      className={joinClassNames(
        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
        'group flex w-full items-center px-4 py-2 text-sm',
      )}
      title={title}
    >
      {icon}
      {title}
    </button>
  );
}

export interface CellMultiActionProps<T> {
  label: string;
  actions: Array<{ title: string; icon: ReactNode; action: T }>;
  onAction: (action: T) => void;
}

export function CellMultiAction<T>({
  label,
  actions,
  onAction,
}: CellMultiActionProps<T>) {
  return (
    <>
      <div className="app-table-row-text">
        <DropdownButton
          label={label}
          button={
            <Menu.Button
              className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
              onClick={(e: MouseEvent<HTMLButtonElement>) =>
                e.stopPropagation()
              }
            >
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          }
        >
          {actions.map(({ title, icon, action }) => (
            <Menu.Item key={title}>
              {({ active }) => (
                <button
                  type="button"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    onAction(action);
                  }}
                  className={joinClassNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex w-full items-center px-4 py-2 text-sm',
                  )}
                  title={title}
                >
                  {icon}
                  {title}
                </button>
              )}
            </Menu.Item>
          ))}
        </DropdownButton>
      </div>
    </>
  );
}
