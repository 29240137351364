import React from 'react';
import { Field } from 'formik';
import CustomSelect from './CustomSelect';
import { StarRatingSelectOptions } from '/src/routes/investor/DealDetail/StarRatingSelect';
import formatStarRatingOptionLabel from './ReactSelectAdditions/formatStarRatingOptionLabel';

export default function StarRatingSelectInput(props: any): JSX.Element {
  return (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      formatOptionLabel={formatStarRatingOptionLabel}
      options={StarRatingSelectOptions}
      placeholder="Add rating..."
      {...props}
    />
  );
}
