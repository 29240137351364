import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import { ANTIDILUTION_RIGHTS_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/AntidilutionRightsOptions';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.AntidilutionRights, values) ? (
    <Field
      className="custom-select"
      closeMenuOnSelect
      isClearable
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      component={CustomSelect}
      label="Anti-dilution Rights"
      name={RoundFieldNames.AntidilutionRights}
      options={ANTIDILUTION_RIGHTS_OPTIONS}
      placeholder="Select..."
      tooltip="An “Anti-dilution Rights” provision entitles investors to a certain level of economic protection in the case of a subsequent issuance of equity at a purchase price less than the current one."
    />
  ) : (
    <></>
  );
}

const AntidilutionRightsField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.AntidilutionRights]: yup.string(),
  }),
};

export default AntidilutionRightsField;
