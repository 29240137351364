import { VIEW_TIME_UPDATE_INTERVAL_MS } from '/../libs/shared-types/src/constants/time';
import useInterval from './useInterval';

function useTrackViewTime(callback: (viewTime: number) => void): void {
  const intervalHandler = () => callback(VIEW_TIME_UPDATE_INTERVAL_MS);

  useInterval(intervalHandler, VIEW_TIME_UPDATE_INTERVAL_MS);
}

export default useTrackViewTime;
