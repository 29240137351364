import React, { useState } from 'react';
import CopyTextButton from './CopyTextButton';
import { QrCodeIcon } from '@heroicons/react/24/outline';
import QRCodeDialog from './notifications/QRCodeDialog';
import { joinClassNames } from '../util/formatting/strings';

interface CopyLinkInputProps {
  link: string;
  qrCodeConfig?: {
    title?: string;
    allowDownload?: boolean;
    imageSrc?: string;
  };
}

function CopyLinkInput({
  link,
  qrCodeConfig,
}: CopyLinkInputProps): JSX.Element {
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);

  return (
    <>
      <div className="flex flex-grow rounded-md shadow-sm">
        <div className="relative flex w-full flex-grow items-stretch focus-within:z-10">
          <input
            type="text"
            name="link"
            id="link"
            className="app-secure-link-input"
            value={link}
            readOnly
          />
        </div>
        <CopyTextButton
          className={joinClassNames(
            'relative -ml-px inline-flex h-[2.3rem] items-center space-x-2 border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500',
            qrCodeConfig ? '' : 'rounded-r-md',
          )}
          text={link}
        />
        {qrCodeConfig && (
          <button
            title="Show QR code"
            type="button"
            onClick={() => setIsQrModalOpen(true)}
            className="relative -ml-px inline-flex h-[2.3rem] min-w-max items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
          >
            <QrCodeIcon className="h-6 w-6 text-gray-500" />
            <span className="text-xs">QR Code</span>
            <span className="sr-only">Show QR code</span>
          </button>
        )}
      </div>

      {qrCodeConfig && (
        <QRCodeDialog
          isOpen={isQrModalOpen}
          onClose={() => setIsQrModalOpen(false)}
          title={qrCodeConfig.title ?? ''}
          linkText={link}
          allowDownload={qrCodeConfig.allowDownload ?? false}
          imageSrc={qrCodeConfig.imageSrc}
        />
      )}
    </>
  );
}

export default CopyLinkInput;
