import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { INV_PRODUCT_STAGE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

const InvProductStageField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      closeMenuOnSelect
      isClearable
      label="What is the earliest product stage you invest at?"
      name={InvestorFieldNames.InvProductStage}
      options={INV_PRODUCT_STAGE_OPTIONS}
      placeholder="Select product stage..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.InvProductStage]: yup.string().nullable(),
  }),
};

export default InvProductStageField;
