import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  ActiveDealSummaryView,
  DealSummaryView,
} from '/../libs/shared-types/src/types/view/AggregatedDeals';
import { SHARED_SUCCESS, SUCCESS } from '/src/constants/SuccessMessages';
import {
  INVESTOR_FLOWLINK_MANAGER_ROUTE,
  SCREENING_ROUTE,
} from '/src/constants/Routes';
import { ViewColumnsIcon, Bars4Icon } from '@heroicons/react/24/outline';
import ActiveDealsBoard from './ActiveDealsBoard';
import API from '/src/middleware/API';
import EditStagesDialog from './dialogs/EditStagesDialog';
import Logger from '/src/services/logger';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import PassDialog from './dialogs/PassDialog';
import RecordInvestmentDialog from './dialogs/RecordInvestmentDialog';
import ShareDealDialog from './dialogs/ShareDealDialog';
import Toast from '/src/components/notifications/Toast';
import SearchBar from '/src/components/inputs/SearchBar';
import SummaryPanel from './SummaryPanel';
import ActiveDealsGroupedTable from './ActiveDealsGroupedTable';
import {
  Stage,
  StageStatus,
} from '/../libs/shared-types/src/types/model/Stage';
import { getDealCountByStage } from './util';
import PageHeader from '/src/components/PageHeader';
import { DealActionModals, ModalConfig } from '/src/interfaces/ModalConfig';
import {
  localStorageGetInvestorActiveView,
  localStorageSetInvestorActiveView,
} from '/src/middleware/LocalStorage';
import { ComparableActiveDealSummaryView } from '/src/interfaces/Comparable';
import { Sortable, SortOrder } from '/src/interfaces/Sortable';
import { buildComparableActiveDealSummaryView } from '/src/util/TableSorting';
import { TableHeader } from '/src/interfaces/TableHeader';
import { StarRating } from '/../libs/shared-types/src/constants/StarRating';
import { Priority } from '/../libs/shared-types/src/constants/Priority';
import {
  updateDealPriority,
  updateDealRating,
} from '/src/services/DealRatingPriority';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import {
  EXAMPLE_BOARD_STAGES,
  EXAMPLE_STARTUP_ACTIVE_DEAL_SUMMARY_VIEW,
} from '/../libs/shared-types/src/constants/UserActivation/MockData/ExampleDeal';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Alert from '/src/components/notifications/Alert';
import { joinClassNames } from '/src/util/formatting/strings';
import {
  InvestorDealflowActive,
  InvestorDealflowActiveBoardStages,
  InvestorUpdateDealActiveStage,
} from '/../libs/shared-types/src/constants/ApiRoutes';

const headers: TableHeader<ComparableActiveDealSummaryView>[] = [
  { sortKey: 'name', element: <span>Company</span>, width: '' },
  { sortKey: 'stage', element: <span>Stage</span>, width: '' },
  { sortKey: 'industries', element: <span>Industries</span>, width: '' },
  { sortKey: 'round', element: <span>Round</span>, width: '' },
  { sortKey: 'raising', element: <span>Raising</span>, width: '' },
  { sortKey: 'cap', element: <span>Cap</span>, width: '' },
  { sortKey: 'rating', element: <span>Rating</span>, width: '' },
  { sortKey: 'priority', element: <span>Priority</span>, width: '' },
  { element: <span className="sr-only">Actions</span>, width: '' },
];

function ActiveDeaflowRoute(): JSX.Element {
  const [activeDeals, setActiveDeals] = useState<ActiveDealSummaryView[]>([]);
  const [sortableDeals, setSortableDeals] =
    useState<
      Sortable<ActiveDealSummaryView, ComparableActiveDealSummaryView>
    >();
  const [query, setQuery] = useState('');
  const { joyrideActivationTourState, setJoyrideActivationTourState } =
    useContext(SharedStateContext);
  const [showAsBoard, setShowAsBoard] = useState(
    localStorageGetInvestorActiveView(),
  );

  const [selectedDeal, setSelectedDeal] = useState<DealSummaryView>();
  const [selectedStage, setSelectedStage] = useState<Stage | undefined>(
    undefined,
  );

  const location = useLocation();

  const [stageDealCountMap, setStageDealCountMap] = useState<
    Map<string, number>
  >(new Map());

  const [modalConfig, setModalConfig] = useState<ModalConfig<DealActionModals>>(
    {
      isOpen: false,
    },
  );
  const [isSentAlertShown, setIsSentAlertShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [activeBoardStages, setActiveBoardStages] = useState<Stage[]>([]);
  const isSearching = query !== '';
  const [firstDealCount, setFirstDealCount] = useState<number>(-1);

  const activationPromptContent = (
    <ul
      className={joinClassNames(
        'space-y-4',
        firstDealCount < 5 && activeBoardStages.length < 2
          ? 'ml-3 list-decimal '
          : '',
      )}
    >
      {activeBoardStages.length < 2 && (
        <li>
          <button
            type="button"
            className="hyperlink font-bold underline"
            onClick={() => openModal(DealActionModals.EditStages)}
          >
            Update your due diligence stages
          </button>{' '}
          to suit your process
        </li>
      )}
      {firstDealCount < 5 && (
        <li>
          Receive deals by{' '}
          <Link
            to={INVESTOR_FLOWLINK_MANAGER_ROUTE}
            className="hyperlink font-bold underline"
          >
            sharing your FlowLink
          </Link>{' '}
          with founders
        </li>
      )}
    </ul>
  );

  const toggleShowSentAlert = () => setIsSentAlertShown(!isSentAlertShown);
  const closeModal = () => setModalConfig({ isOpen: false });
  const openModal = (dialog: DealActionModals) =>
    setModalConfig({ isOpen: true, dialog });

  function showSentAlert() {
    setIsSentAlertShown(true);
    window.setTimeout(() => setIsSentAlertShown(false), 3000);
  }

  const buildStageDealCountMap = () => {
    const stageDealCounts = new Map<string, number>();
    activeBoardStages.forEach((stage) => {
      const count = getDealCountByStage(activeDeals, stage.name);
      stageDealCounts.set(stage._id, count);
    });
    return stageDealCounts;
  };

  const onPassDeal = (deal: DealSummaryView) => {
    setSelectedDeal(deal);
    openModal(DealActionModals.Pass);
  };

  const onRecordInvestment = (deal: DealSummaryView) => {
    setSelectedDeal(deal);
    openModal(DealActionModals.Invest);
  };

  const onShareDeal = (deal: DealSummaryView) => {
    setSelectedDeal(deal);
    openModal(DealActionModals.Share);
  };

  async function onQueryChange(query: string) {
    setIsSearchLoading(true);
    setQuery(query);
    try {
      const deals = await API.get<ActiveDealSummaryView[]>(
        InvestorDealflowActive.buildEndpoint(undefined, { query }),
      );
      setActiveDeals(deals);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsSearchLoading(false);
    }
  }

  const handleSort = (sortKey: string, sortOrder: SortOrder) => {
    const sorted = sortableDeals?.sort(
      sortKey as keyof ComparableActiveDealSummaryView,
      sortOrder,
    );

    setActiveDeals(sorted ? [...sorted] : []);
  };

  async function handleUpdateDealPriority(
    deal: ActiveDealSummaryView,
    newPriority: Priority,
  ) {
    if (newPriority === deal.priority.priority) {
      // Incoming Deal Priority is the same as the current priority, so nothing to do
      return;
    }

    try {
      const updatedPriority = await updateDealPriority(
        newPriority,
        deal.startupId,
      );
      const updatedDeals = activeDeals.map((x) => {
        if (x.startupId === deal.startupId) {
          return { ...x, priority: updatedPriority };
        }
        return x;
      });
      setActiveDeals(updatedDeals);
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function handleUpdateDealRating(
    deal: ActiveDealSummaryView,
    newRating: StarRating,
  ) {
    if (newRating === deal.rating.rating) {
      // Incoming Deal Rating is the same as the current rating, so nothing to do
      return;
    }

    try {
      const updatedRating = await updateDealRating(newRating, deal.startupId);

      const updatedDeals = activeDeals.map((x) => {
        if (x.startupId === deal.startupId) {
          return { ...x, rating: updatedRating };
        }
        return x;
      });
      setActiveDeals(updatedDeals);
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function fetchActiveDeals(isUpdatingBoard = false) {
    if (!isUpdatingBoard) {
      setIsLoading(true);
    }

    try {
      const deals = await API.get<ActiveDealSummaryView[]>(
        InvestorDealflowActive.buildEndpoint(),
      );
      if (
        localStorage.getItem(
          LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING,
        )
      ) {
        setShowAsBoard(true);
        deals.splice(0, 0, EXAMPLE_STARTUP_ACTIVE_DEAL_SUMMARY_VIEW);
      }
      if (firstDealCount === -1) {
        setFirstDealCount(deals.length);
      }
      setActiveDeals(deals);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsLoading(false);
      setJoyrideActivationTourState({
        ...joyrideActivationTourState,
        run: true,
      });
    }
  }

  async function fetchActiveBoardStages() {
    try {
      const stages = await API.get<Stage[]>(
        InvestorDealflowActiveBoardStages.buildEndpoint(),
      );

      if (
        localStorage.getItem(
          LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING,
        )
      ) {
        // We are showing the investor tour, so we must mock the board stages
        // - if the investor has only 1 stage, replace it with the example stages
        // - otherwise only add the first example stage to the beginning of the array
        if (stages.length <= 1) {
          stages.splice(0, 1, ...EXAMPLE_BOARD_STAGES);
        } else {
          stages.splice(0, 0, EXAMPLE_BOARD_STAGES[0]);
        }
      }

      setActiveBoardStages(stages);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      // Check if we should open the Stage Editor because we are being redirected from Deal Detail
      if (
        !isLoading &&
        location.state &&
        (location.state as any).openDialog === DealActionModals.EditStages
      ) {
        openModal((location.state as any).openDialog);
        // Clear the state so that it does not re-toggle the dialog on page refresh
        window.history.replaceState({}, document.title);
      }
    }
  }

  async function handleActiveBoardStageChange(
    dealStartupId: string,
    newStageName: string,
  ) {
    try {
      await API.put(InvestorUpdateDealActiveStage.buildEndpoint(), {
        startupId: dealStartupId,
        stageName: newStageName,
      });
      fetchActiveDeals(true);
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function handleSaveActiveBoardStages(stages: Stage[]) {
    try {
      const newStages = await API.put<Stage[]>(
        InvestorDealflowActiveBoardStages.buildEndpoint(),
        {
          boardStages: stages,
        },
      );
      closeModal();
      setActiveBoardStages(newStages);
      fetchActiveDeals(true);
    } catch (error: any) {
      Logger.error(error.message);
      throw error;
    }
  }

  useEffect(() => {
    fetchActiveDeals();
    fetchActiveBoardStages();
  }, []);

  useEffect(() => {
    const sortableItems = new Sortable<
      ActiveDealSummaryView,
      ComparableActiveDealSummaryView
    >(activeDeals, buildComparableActiveDealSummaryView);
    setSortableDeals(sortableItems);
    setStageDealCountMap(buildStageDealCountMap());
  }, [activeDeals, activeBoardStages]);

  const handleSelectStage = (stage: Stage | undefined) => {
    setSelectedStage(stage);
  };

  function getModalContent() {
    switch (modalConfig.dialog) {
      case DealActionModals.Invest:
        return (
          selectedDeal && (
            <RecordInvestmentDialog
              onClose={closeModal}
              onSuccess={fetchActiveDeals}
              deal={selectedDeal}
            />
          )
        );
      case DealActionModals.Pass:
        return (
          selectedDeal && (
            <PassDialog
              onClose={closeModal}
              onSuccess={fetchActiveDeals}
              deal={selectedDeal}
            />
          )
        );
      case DealActionModals.Share:
        return (
          selectedDeal && (
            <ShareDealDialog
              onClose={closeModal}
              onSuccess={showSentAlert}
              deal={selectedDeal}
            />
          )
        );
      case DealActionModals.EditStages:
        return (
          <EditStagesDialog
            boardStages={activeBoardStages}
            onCancel={closeModal}
            onSave={handleSaveActiveBoardStages}
            itemsPerStageCountMap={stageDealCountMap}
            header="Manage Active Dealflow Stages"
            secondaryHeader="Update the stages to fit your process. You can set a target duration to indicate how long a deal should stay in each stage. Flowlie will mark any deals that passed the target duration so you can can prioritize them in due diligence."
          />
        );
      default:
        return <></>;
    }
  }

  const emptyContent = (
    <p>
      Hmm... it looks like you have no deals in your active dealflow.&nbsp;
      <Link to={SCREENING_ROUTE} className="hyperlink font-bold underline">
        Head over to Screening
      </Link>
      &nbsp;to accept incoming deals.
    </p>
  );

  return (
    <>
      <div className="flex h-screen flex-col">
        <div className="flex min-w-full flex-grow flex-col align-middle sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <header className="sticky top-0 z-40 justify-between bg-gray-100 lg:flex lg:flex-row">
            <PageHeader
              title="Active Dealflow"
              itemsCount={activeDeals.length}
              itemLabel="deal"
            />

            <aside className="inline-flex items-center space-x-4">
              <section className="flex flex-row items-center">
                {isSearchLoading && <LoadingSpinner color="blue" />}
                <SearchBar
                  isDebounce
                  placeholder={`Search ${selectedStage?.name ?? 'Active Deals'}`}
                  onQueryChange={onQueryChange}
                />
              </section>
              <span className="relative z-0 inline-flex rounded-md shadow-sm">
                <button
                  title="Show as list"
                  type="button"
                  className={`relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 ${
                    showAsBoard
                      ? 'bg-white text-gray-700 hover:bg-gray-50'
                      : 'bg-blue-600 text-white'
                  }`}
                  onClick={() => {
                    setShowAsBoard(false);
                    localStorageSetInvestorActiveView(false);
                    handleSelectStage(undefined);
                  }}
                >
                  <Bars4Icon className="h-5 w-5" />
                </button>
                <button
                  title="Show as board"
                  type="button"
                  className={`relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-4 py-2 text-sm font-medium focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 ${
                    showAsBoard
                      ? 'bg-blue-600 text-white'
                      : 'bg-white text-gray-700 hover:bg-gray-50'
                  }`}
                  onClick={() => {
                    setShowAsBoard(true);
                    localStorageSetInvestorActiveView(true);
                    handleSelectStage(undefined);
                  }}
                >
                  <ViewColumnsIcon className="h-5 w-5" />
                </button>
              </span>

              <button
                type="button"
                className="joyride-activeDealflow app-button--neutral"
                onClick={() => openModal(DealActionModals.EditStages)}
              >
                Manage Stages
              </button>
            </aside>
          </header>
          {isLoading && (
            <PageLoadingSpinner message="Searching for the next unicorn... 🚀" />
          )}

          {!isLoading && activeDeals.length === 0 && query === '' && (
            <Alert
              alertType="Info"
              canDismiss={false}
              color="blue"
              content={emptyContent}
              isShown={true}
            />
          )}

          {!showAsBoard && (activeDeals.length > 0 || query !== '') && (
            <section>
              <div className="sticky top-20 z-20 bg-gray-100 pb-4">
                <SummaryPanel
                  onSelectStage={handleSelectStage}
                  stageStatuses={
                    activeDeals
                      .filter((x) => x.currentActiveBoardStage !== undefined)
                      .map(
                        (deal) => deal.currentActiveBoardStage,
                      ) as StageStatus[]
                  }
                  stages={activeBoardStages}
                  selectedStage={selectedStage}
                />
              </div>
              <ActiveDealsGroupedTable
                activeDeals={activeDeals}
                boardStages={activeBoardStages}
                handlePass={onPassDeal}
                handleRecordInvestment={onRecordInvestment}
                handleUpdateDealPriority={handleUpdateDealPriority}
                handleUpdateDealRating={handleUpdateDealRating}
                handleShare={onShareDeal}
                headers={headers}
                isSearching={isSearching}
                onSort={handleSort}
                selectedStage={selectedStage}
                stageDealCountMap={stageDealCountMap}
              />
              {(firstDealCount < 5 || activeBoardStages.length < 2) && (
                <div className="mt-4 mb-2">
                  <Alert
                    color="blue"
                    alertType="Info"
                    content={activationPromptContent}
                    isShown={true}
                  />
                </div>
              )}
            </section>
          )}

          {showAsBoard && (activeDeals.length > 0 || query !== '') && (
            <section className="relative flex flex-grow overflow-x-scroll">
              <ActiveDealsBoard
                activeDeals={activeDeals}
                boardStages={activeBoardStages}
                handleActiveBoardStageChange={handleActiveBoardStageChange}
                handlePass={onPassDeal}
                handleRecordInvestment={onRecordInvestment}
                handleShare={onShareDeal}
                handleUpdateDealPriority={handleUpdateDealPriority}
                handleUpdateDealRating={handleUpdateDealRating}
                stageDealCountMap={stageDealCountMap}
                isSearching={isSearching}
              />

              {(firstDealCount < 5 || activeBoardStages.length < 2) && (
                <div className="absolute bottom-0 left-0 w-full">
                  <Alert
                    color="blue"
                    alertType="Info"
                    content={activationPromptContent}
                    isShown={true}
                  />
                </div>
              )}
            </section>
          )}
        </div>
      </div>

      <ModalWrapper open={modalConfig.isOpen} onClose={() => closeModal()}>
        {getModalContent()}
      </ModalWrapper>
      <Toast
        isShown={isSentAlertShown}
        onClose={toggleShowSentAlert}
        title={SUCCESS}
        text={SHARED_SUCCESS}
      />
    </>
  );
}

export default ActiveDeaflowRoute;
