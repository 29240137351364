import React from 'react';
import * as yup from 'yup';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import { CHECK_RANGE_MAX } from '/../libs/shared-types/src/constants/NumericRanges';
import { numberWithCommas } from '/src/util/formatting/numbers';
import FormField from '/src/interfaces/FormField';
import MoneyInput from '/src/components/inputs/MoneyInput';

export function checkRangeMaxSchema({ required = false } = {}) {
  let schema = yup
    .number()
    .typeError('Must be a number')
    .min(
      yup.ref(InvestorFieldNames.CheckRangeMin),
      'Cannot be smaller than Minimum Check',
    )
    .max(
      CHECK_RANGE_MAX,
      `Cannot be greater than $ ${numberWithCommas(CHECK_RANGE_MAX)}`,
    )
    .test(
      'onlyHundredMultiples',
      'Only multiples of $100 are accepted',
      (value): boolean => {
        if (value && value > 0 && value % 100 !== 0) {
          // Return false to indicate the test failed
          return false;
        }
        return true;
      },
    );

  if (required) {
    schema = schema.required('Required');
  }

  return schema;
}

const CheckRangeMaxField: FormField = {
  fieldComponent: (
    <MoneyInput
      label="Maximum Check Size"
      name={InvestorFieldNames.CheckRangeMax}
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.CheckRangeMax]: checkRangeMaxSchema({ required: true }),
  }),
};

export const checkRangeMaxFieldOptional: FormField = {
  ...CheckRangeMaxField,
  validation: yup.object({
    [InvestorFieldNames.CheckRangeMax]: checkRangeMaxSchema({
      required: false,
    }).when(InvestorFieldNames.CheckRangeMin, {
      is: (value: string | number) => value !== undefined && value !== '',
      then: (schema) =>
        schema.required('You must add the maximum check range too'),
    }),
  }),
};

export default CheckRangeMaxField;
