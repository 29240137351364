import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import {
  CUSTOMER_COUNT_MAX,
  CUSTOMER_COUNT_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import NumericInput from '../../inputs/NumericInput';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.HasCustomers] ? (
    <NumericInput
      label="Total Customers"
      name={StartupFieldNames.CustomerCount}
    />
  ) : (
    <></>
  );
}

const CustomerCountField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.CustomerCount]: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .min(
        CUSTOMER_COUNT_MIN,
        `Must be greater than or equal to ${CUSTOMER_COUNT_MIN}`
      )
      .max(
        CUSTOMER_COUNT_MAX,
        `Enter ${CUSTOMER_COUNT_MAX} if you have more than ${CUSTOMER_COUNT_MAX} customers`
      ),
  }),
};

export default CustomerCountField;
