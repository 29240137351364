enum InvestorTypes {
  Angel = 'Angel',
  FamilyOffice = 'Family Office',
  Syndicator = 'Syndicator',
  VC = 'VC',
  CorporateVC = 'Corporate VC',
  Accelerator = 'Accelerator',
}

export default InvestorTypes;
