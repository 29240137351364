import React, { useEffect } from 'react';

interface WarnBeforeExitProps {
  isEnabled: boolean;
  message?: string;
}

function WarnBeforeExit({
  isEnabled,
  message = 'Are you sure? Leaving the page may cause data to not be saved',
}: WarnBeforeExitProps): JSX.Element {
  const alertUser = (event: any) => {
    if (event) {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = message;
    }

    return message;
  };

  useEffect(() => {
    if (isEnabled) {
      window.addEventListener('beforeunload', alertUser);
    } else {
      window.removeEventListener('beforeunload', alertUser);
    }

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [isEnabled]);

  return <></>;
}

export default WarnBeforeExit;
