import React, { useEffect, useState } from 'react';
import CopyTextButton from '/src/components/CopyTextButton';
import { fetchFlowlink } from '/src/util/flowlink';
import Logger from '/src/services/logger';
import { ClockIcon } from '@heroicons/react/20/solid';
import { PrivateInvestor } from '/../libs/shared-types/src/types/view/investor/PrivateInvestor';
import API from '/src/middleware/API';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import IframeCanva from '/src/components/utility/IframeCanva';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { InvestorGet } from '/../libs/shared-types/src/constants/ApiRoutes';

interface DialogConfig {
  title: string;
  exampleMessage: string;
  exampleMessageLabel: string;
  flowlinkText: string;
  frameTitle: string;
  frameAvgTime: string;
  frameSrc: string;
}

function getDialogConfig(
  investorType: InvestorTypes,
  flowlinkText: string,
  shareToAccountType: AccountTypes
): DialogConfig {
  const config: DialogConfig = {
    title: '',
    exampleMessage: '',
    exampleMessageLabel: '',
    flowlinkText,
    frameTitle: '',
    frameAvgTime: '',
    frameSrc: '',
  };
  let exampleMessage = '';

  if (shareToAccountType === AccountTypes.Startup) {
    if (investorType === InvestorTypes.Angel) {
      exampleMessage = `Please share your deck and company info via this link so I can review: ${flowlinkText}
By submitting your details here, you enable me to analyze the opportunity more efficiently and effectively while checking if there are any other investors in my network who would be a good fit for you.`;
    } else {
      exampleMessage = `Please share your deck and company info via this link so our team can review: ${flowlinkText}
By submitting your details here, you enable us to analyze the opportunity more efficiently and effectively while checking if there are any other investors in our network who would be a good fit for you.`;
    }

    config.title =
      'Start screening deals by sharing your FlowLink with founders';
    config.exampleMessage = exampleMessage;
    config.exampleMessageLabel =
      'Here is an example message you can send to founders';
    config.frameTitle = 'How the experience looks for founders';
    config.frameAvgTime = 'Average: 3-4 minutes';
    config.frameSrc = 'https://www.canva.com/design/DAFmqquFkdY/view?embed';

    return config;
  }

  if (shareToAccountType === AccountTypes.Investor) {
    if (investorType === InvestorTypes.Angel) {
      exampleMessage = `Connect with me on Flowlie by using this link so I can share more of the right deals with you: ${flowlinkText}
I use Flowlie to process my dealflow and cross-reference each company I'm reviewing with my investor network to make the most impactful introductions.`;
    } else {
      exampleMessage = `Connect with me on Flowlie by using this link so I can share more of the right deals with you: ${flowlinkText}
We use Flowlie to process our dealflow and cross-reference each company we're reviewing with our investor network to make the most impactful introductions.`;
    }

    config.title =
      'Add investors to your network by sharing your FlowLink with them';
    config.exampleMessage = exampleMessage;
    config.exampleMessageLabel =
      'Here is an example message you can send to investors';
    config.frameTitle = 'How the experience looks for investors';
    config.frameAvgTime = 'Average: 2-3 minutes';
    config.frameSrc = 'https://www.canva.com/design/DAFnC_RVtJY/view?embed';

    return config;
  }

  return config;
}

interface ShareFlowLinkDialogContent {
  shareToAccountType: AccountTypes;
}

function ShareFlowLinkDialogContent({
  shareToAccountType,
}: ShareFlowLinkDialogContent): JSX.Element {
  const [dialogConfig, setDialogConfig] = useState<DialogConfig>();

  async function configureDialog() {
    try {
      const link = await fetchFlowlink();
      const privateInvestor = await API.get<PrivateInvestor>(
        InvestorGet.buildEndpoint()
      );

      setDialogConfig(
        getDialogConfig(privateInvestor.type, link, shareToAccountType)
      );
    } catch (error) {
      Logger.error(error);
    }
  }

  useEffect(() => {
    configureDialog();
  }, []);

  if (!dialogConfig) {
    return <></>;
  }

  return (
    <main className="bg-white p-4 sm:w-screen sm:max-w-3xl sm:p-7">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <h3
          className="text-lg font-medium leading-6 text-gray-900"
          id="modal-headline"
        >
          {dialogConfig.title}
        </h3>

        <div className="mt-5 flex rounded-md shadow-sm">
          <div className="relative flex flex-grow items-stretch focus-within:z-10">
            <input
              type="text"
              name="link"
              id="link"
              className="app-secure-link-input"
              value={dialogConfig.flowlinkText}
              readOnly
            />
          </div>
          <CopyTextButton
            text={dialogConfig.flowlinkText}
            className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
        </div>
      </div>

      <section className="mt-8">
        <p className="text-base">{dialogConfig.exampleMessageLabel}</p>
        <div className="relative">
          <textarea
            name="exampleMessage"
            className="input mt-3 min-h-[140px] w-full resize-none text-sm"
            value={dialogConfig.exampleMessage}
            readOnly
          />
          <CopyTextButton
            text={dialogConfig.exampleMessage}
            className="absolute bottom-2 right-2 rounded border border-gray-300 bg-gray-50 p-0.5 text-gray-700 shadow"
          />
        </div>
      </section>

      <section className="mt-8 -mb-4">
        <p className="-mb-3 text-base">
          {dialogConfig.frameTitle}
          <span className="ml-3 inline-flex items-center rounded-full bg-teal-50 px-1.5 py-0.5 text-xs font-medium text-teal-700 ring-1 ring-inset ring-teal-600/10">
            <ClockIcon className="mr-1 h-3 w-3" />
            {dialogConfig.frameAvgTime}
          </span>
        </p>
        <IframeCanva
          frameTitle={dialogConfig.frameTitle}
          frameSrc={dialogConfig.frameSrc}
        />
      </section>
    </main>
  );
}

export default ShareFlowLinkDialogContent;
