/* eslint-disable react/style-prop-object */

import React from 'react';
import flowlieLogo from '/src/res/flowlie_f.svg';
import { getBasePromoUrl } from '/src/util/urls';

type HeightOptions = 4 | 6 | 12;

const HeightClasses = new Map<HeightOptions, string>([
  [4, 'h-4'],
  [6, 'h-6'],
  [12, 'h-12'],
]);

interface LogoProp {
  height: HeightOptions;
}

export function FlowlieF({ height = 12 }: LogoProp): JSX.Element {
  return (
    <img
      className={`${HeightClasses.get(height)} w-auto`}
      src={flowlieLogo}
      alt="flowlie"
    />
  );
}

export function FlowlieName(): JSX.Element {
  return (
    <div className="flex shrink-0 items-center space-x-1">
      <FlowlieF height={4} />
      <h1 className="font-bold tracking-wide">flowlie</h1>
    </div>
  );
}

export function FlowlieBadge(): JSX.Element {
  return (
    <div className="mt-12 grid place-items-center text-sm">
      <a
        href={getBasePromoUrl()}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center space-x-1 rounded-full border bg-white px-6 py-1 hover:border-blue-600"
      >
        <span>Powered by</span>
        <FlowlieName />
      </a>
    </div>
  );
}
