import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import BillingRoute from './BillingRoute';
import { toDashCase } from '/src/util/formatting/strings';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';

function Settings() {
  const tabs = [{ name: 'Billing' }];

  return (
    <main>
      <header>
        {/* <PageHeader title="Settings" /> */}
        {/* <TabsHeader tabs={tabs} /> */}
      </header>
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <Routes>
            {['', tabs[0].name].map((path) => (
              <Route
                key={path}
                path={`${toDashCase(path)}/:successState`}
                element={
                  <div>
                    <BillingRoute />
                  </div>
                }
              />
            ))}

            <Route
              path="*"
              element={<Navigate to={NOT_FOUND_ROUTE} replace />}
            />
          </Routes>
        </div>
      </div>
    </main>
  );
}

export default Settings;
