import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import {
  LIQUIDATION_PREFERENCE_MAX,
  LIQUIDATION_PREFERENCE_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import NumericInput from '/src/components/inputs/NumericInput';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.LiquidationPreference, values) ? (
    <NumericInput
      label="Liquidation Preference"
      disabled={values[RoundFieldNames.IsEditable] === 'false'}
      name={RoundFieldNames.LiquidationPreference}
      allowDecimals
      decimalsLimit={1}
      step={0.5}
      maxLength={4}
      suffix="x"
      tooltip="A “Liquidation Preference” provision entitles the investor to be returned a certain multiple on the original investment before the Common Stock receives any consideration in the case of a liquidation."
    />
  ) : (
    <></>
  );
}

const LiquidationPreferenceField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.LiquidationPreference]: yup
      .number()
      .typeError('Must be a number')
      .min(
        LIQUIDATION_PREFERENCE_MIN,
        `Must be at least ${LIQUIDATION_PREFERENCE_MIN}`
      )
      .max(
        LIQUIDATION_PREFERENCE_MAX,
        `Cannot be greater than ${LIQUIDATION_PREFERENCE_MAX}x`
      ),
  }),
};

export default LiquidationPreferenceField;
