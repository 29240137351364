import React, { useEffect, useRef, useState } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { joinClassNames } from '/src/util/formatting/strings';
import useKeyPress from '/src/hooks/useKeyPress';
import KeyShortcutLabel, { detectOs } from './KeyShortcutLabel';
import { debounce } from '/src/util/debounce';
import { USER_INPUT_DEBOUNCE_DELAY_MS } from '/src/constants/UserInputDebounceDelay';
import { SubscriptionCTAPill } from '../SubscriptionCTA';

const SHORTCUT_KEY = '/';

interface SearchBarProps {
  placeholder: string;
  className?: string;
  onQueryChange: (query: string) => void;
  isDebounce?: boolean;
  debounceDelayMs?: number;
  initialValue?: string;
  isDisabled?: boolean;
}

function SearchBar({
  placeholder,
  className = '',
  onQueryChange,
  isDebounce = false,
  debounceDelayMs = USER_INPUT_DEBOUNCE_DELAY_MS,
  initialValue = '',
  isDisabled = false,
}: SearchBarProps): JSX.Element {
  const searchRef = useRef<HTMLInputElement>(null);
  const [osData] = useState(detectOs());

  const onKeyPress = () => {
    searchRef.current?.focus();
  };

  // Detect Command (or Ctrl) + / as shortcut to focus search bar
  useKeyPress([SHORTCUT_KEY], onKeyPress, osData.modifierKey);

  function clearSearch() {
    if (searchRef.current) {
      searchRef.current.value = '';
      onQueryChange('');
    }
  }

  const debouncedOnQueryChange = isDebounce
    ? debounce(onQueryChange, debounceDelayMs)
    : onQueryChange;

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = initialValue;
    }
  }, []);

  return (
    <div className={joinClassNames(className, 'flex flex-col items-start')}>
      <div className="group relative flex items-center">
        <div className="absolute inset-y-0 left-0 flex py-1.5 pr-1.5">
          <div className="inline-flex items-center space-x-2 px-1.5 font-sans text-sm font-medium text-gray-400">
            <MagnifyingGlassIcon className="h-4 w-4" />
          </div>
        </div>

        <input
          autoComplete="off"
          type="text"
          name="search"
          id="search"
          ref={searchRef}
          disabled={isDisabled}
          onChange={(e) => debouncedOnQueryChange(e.target.value)}
          className="block w-[17rem] truncate rounded-md border-gray-300 py-1.5 pl-[1.5rem] pr-9 shadow-sm focus:border-blue-500 focus:ring-blue-500 group-hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800 sm:text-sm"
          placeholder={placeholder}
        />
        {isDisabled && (
          <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            <SubscriptionCTAPill
              id="cta_search_bar"
              tooltipText="Upgrade to Pro to unlock Search"
            />
          </div>
        )}
        {!isDisabled && (
          <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            {searchRef?.current?.value === '' || !searchRef.current?.value ? (
              <div className="inline-flex items-center px-1.5">
                <KeyShortcutLabel
                  shortcutKey={SHORTCUT_KEY}
                  keyModifier={osData}
                  srLabel="search"
                />
              </div>
            ) : (
              <button
                type="button"
                onClick={clearSearch}
                className="inline-flex items-center px-2 font-sans text-sm font-medium text-gray-400"
              >
                <XMarkIcon className="h-4 w-4" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchBar;
