export enum DealActionModals {
  Pass,
  Share,
  Invest,
  EditStages,
}

export interface ModalConfig<T = DealActionModals> {
  dialog?: T;
  isOpen: boolean;
}
