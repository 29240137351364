import React, { useEffect, useState } from 'react';
import CopyTextButton from '../CopyTextButton';
import ModalWrapper from '../notifications/ModalWrapper';
import LoadingSpinner from '../utility/LoadingSpinner';
import { getCompanyIcpFeedback } from '/src/services/ai/CompanyIcpAssistant';
import { getCompanyDescriptionFeedback } from '/src/services/ai/CompanyDescriptionAssistant';
import { GenAiFeedbackView } from '/../libs/shared-types/src/types/view/GenAiFeedbackView';
import { BoltIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { GenAiTransactionTypes } from '/../libs/shared-types/src/constants/GenAiTransactionTypes';
import { Subscription } from '/../libs/shared-types/src/types/model/Subscription';
import { SharedGetSubscription } from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import MailToLink from '../utility/MailToLink';
import { getPluralSuffix } from '/src/util/formatting/numbers';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { SubscriptionCTAPill } from '../SubscriptionCTA';

interface StartupAiFeedbackModalProps {
  feedbackType: GenAiTransactionTypes;
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  currentValue: string;
}

export function StartupAiFeedbackModal({
  feedbackType,
  isOpen,
  setIsOpen,
  currentValue,
}: StartupAiFeedbackModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingFeedback, setIsFetchingFeedback] = useState(false);
  const [subscription, setSubscription] = useState<Subscription>();
  const [feedback, setFeedback] = useState<GenAiFeedbackView>();
  const [feedbackError, setFeedbackError] = useState<string>();

  async function fetchSubscription() {
    try {
      const data = await API.get<Subscription>(
        SharedGetSubscription.buildEndpoint()
      );
      setSubscription(data);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchFeedback() {
    if (!isOpen || isFetchingFeedback) {
      return;
    }

    try {
      setIsFetchingFeedback(true);

      let feedbackResult: GenAiFeedbackView;
      if (feedbackType === GenAiTransactionTypes.FeedbackCompanyDescription) {
        feedbackResult = await getCompanyDescriptionFeedback();
        setFeedback(feedbackResult);
      } else if (feedbackType === GenAiTransactionTypes.FeedbackIcp) {
        feedbackResult = await getCompanyIcpFeedback();
        setFeedback(feedbackResult);
      }
      fetchSubscription();
    } catch (error: any) {
      Logger.error(error);
      setFeedbackError(error.message);
    } finally {
      setIsFetchingFeedback(false);
    }
  }

  function getModalContentDescription() {
    if (feedbackType === GenAiTransactionTypes.FeedbackCompanyDescription) {
      return 'Company Description';
    }

    if (feedbackType === GenAiTransactionTypes.FeedbackIcp) {
      return 'Ideal Customer Profile';
    }
  }

  useEffect(() => {
    fetchSubscription();
    setFeedbackError(undefined);
  }, [isOpen]);

  return (
    <ModalWrapper open={isOpen} onClose={() => null}>
      <div className="relative flex max-h-[85vh] w-full flex-col space-y-4 overflow-y-auto bg-white p-5 sm:max-w-[70rem]">
        <button
          type="button"
          onClick={() => setIsOpen(false)}
          className="absolute right-3 top-3"
        >
          <XMarkIcon className="h-6 w-6 text-gray-800" />
        </button>
        <h2 className="flex items-center text-2xl font-semibold">
          Let&apos;s review and improve your {getModalContentDescription()}
        </h2>
        <section>
          <h3 className="mb-2 font-medium">
            Current {getModalContentDescription()}
          </h3>
          <p className="rounded-md border border-dashed border-gray-500 p-2">
            {currentValue}
          </p>
        </section>

        {isFetchingFeedback && (
          <div className="mx-auto flex flex-col items-center py-20">
            <LoadingSpinner size={10} color="blue" />
            <p className="mt-2 text-lg">
              Generating Feedback... please wait, it could take up to 1 minute
            </p>
          </div>
        )}

        {!isFetchingFeedback && feedbackError && (
          <div>
            {feedbackError.split('\n').map((line, index) => (
              <p key={index}>
                {index === 0 && (
                  <ExclamationCircleIcon className="mr-1 inline-flex h-5 w-5 text-red-600" />
                )}
                {line}
              </p>
            ))}
          </div>
        )}

        {!isFetchingFeedback && feedback && (
          <>
            <section>
              <h3 className="mb-2 font-semibold">Feedback</h3>
              <p className="rounded-md border border-dashed border-purple-500 p-2">
                {feedback?.feedback}
              </p>
            </section>

            <section>
              <h3 className="mb-2 flex items-center  font-semibold">
                Suggested {getModalContentDescription()}{' '}
              </h3>
              <div className="relative">
                <p className="rounded-md border border-dashed border-purple-500 p-2 pr-6">
                  {feedback?.suggestion}
                </p>
                <CopyTextButton
                  text={feedback?.suggestion}
                  className="absolute bottom-1 right-1 rounded border border-gray-300 bg-gray-50 p-0.5 text-gray-700 shadow"
                />
              </div>
            </section>

            <section>
              <h3 className="mb-2 font-semibold">Reasons for the Change</h3>
              <p className="rounded-md border border-dashed border-purple-500 p-2">
                {feedback?.reasoning}
              </p>
            </section>
          </>
        )}

        {subscription && !isFetchingFeedback && (
          <div>
            <p className="text-lg font-medium leading-8 text-gray-900">
              You have&nbsp;
              <span className="font-medium text-purple-800">
                <BoltIcon className="mr-0.5 -mt-0.5 inline-flex h-3.5 w-3.5" />
                {subscription.credits} Flowlie AI credit
                {getPluralSuffix(subscription.credits)}
              </span>
              &nbsp;remaining.&nbsp;
              {subscription.credits < 1 &&
                subscription.currentTier !== SubscriptionTiers.StartupFree && (
                  <MailToLink
                    linkText={'Get more Flowlie AI credits (25 for $10)'}
                    params={{
                      to: 'support@flowlie.com',
                      subject: 'Purchase Flowlie AI Credits',
                      body: `I would like to purchase additional Flowlie AI credits.`,
                    }}
                    className="hyperlink text-base"
                  />
                )}
              {subscription.credits < 1 &&
                subscription.currentTier === SubscriptionTiers.StartupFree && (
                  <SubscriptionCTAPill
                    id={`cta_${getModalContentDescription()
                      ?.toLowerCase()
                      .replaceAll(' ', '_')}`}
                    text="Get more credits"
                  />
                )}
            </p>

            <button
              className="app-button--primary mt-2"
              onClick={async () => await fetchFeedback()}
              disabled={
                feedbackError !== undefined ||
                isFetchingFeedback ||
                subscription.credits < 1
              }
            >
              Request Feedback {feedback ? 'Again' : ''} (1 Credit)
            </button>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
}
