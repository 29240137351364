import React, { Fragment, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import Toast from '/src/components/notifications/Toast';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import WarnBeforeExit from '/src/components/utility/WarnBeforeExit';
import { PROFILE_PUBLIC, DECK_SAVED } from '/src/constants/SuccessMessages';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import STARTUP_FIELD_MAPPING from '/src/components/StartupForm/StartupFormFieldMapping';
import Logger from '/src/services/logger';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { PrivateStartup } from '/../libs/shared-types/src/types/view/startup/PrivateStartup';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { PaperAirplaneIcon } from '@heroicons/react/20/solid';
import { DealActionModals, ModalConfig } from '/src/interfaces/ModalConfig';
import ShareProfileDialogContent from '../ProfilePreview/ShareProfileDialogContent';
import FormField from '/src/interfaces/FormField';
import { Disclosure } from '@headlessui/react';
import DebugRender from '/src/components/utility/DebugRender';
import DateString from '/src/components/utility/DateString';
import { Deck, DeckToUpload } from '/../libs/shared-types/src/types/model/Deck';
import { uploadDeck } from '/src/util/FileHelpers';
import { getLatestDeck } from '/../libs/shared-types/src/extensions/DeckExtensions';

import {
  StartupGet,
  StartupUpdateDeck,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';

async function addNewDeck(formValues: FormikValues, onDeckAdded: () => void) {
  let deckToUpload: DeckToUpload | null = null;
  // Check if a new deck was uploaded
  if (formValues[StartupFieldNames.Deck] instanceof File) {
    const uploadResult = await uploadDeck(formValues[StartupFieldNames.Deck]);
    if (uploadResult === undefined) {
      // The key is invalid
      throw Error('Error: Deck upload unsucessful');
    }

    deckToUpload = {
      changeReason: formValues[StartupFieldNames.DeckChangeReason],
      fileName: formValues[StartupFieldNames.Deck].name,
      key: uploadResult.key,
      pageCount: uploadResult.pageCount,
      changeType: formValues[StartupFieldNames.DeckChangeType],
    };
  }

  try {
    await API.post(StartupUpdateDeck.buildEndpoint(), { deckToUpload }, true);
    onDeckAdded();
    return await Promise.resolve('Success');
  } catch (error: any) {
    Logger.error('Could not add a new deck version', deckToUpload);
    throw Error(error.message);
  }
}

export type FormSection = {
  title: string;
  fields: FormField[];
  subtitle: string;
  fieldsLayout?: string;
  headerStyles?: string;
};

const sections: FormSection[] = [
  {
    title: 'Deck',
    subtitle: 'Upload a new version of the deck',
    fields: [
      STARTUP_FIELD_MAPPING[StartupFieldNames.Deck],
      STARTUP_FIELD_MAPPING[StartupFieldNames.DeckChangeType],
      STARTUP_FIELD_MAPPING[StartupFieldNames.DeckChangeReason],
    ],
  },
];

interface DeckFormProps {
  onDeckAdded: () => void;
}

function DeckForm({ onDeckAdded }: DeckFormProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [startup, setStartup] = useState<PrivateStartup>();
  const [latestDeck, setLatestDeck] = useState<Deck>();
  const [initialValues, setInitialValues] = useState<any>({});
  const [submitCount, setSubmitCount] = useState(0);
  const [showDeckSaveToast, setShowDeckSaveToast] = useState(false);
  const [modalConfig, setModalConfig] = useState<ModalConfig<DealActionModals>>(
    {
      isOpen: false,
    },
  );

  const openModal = (dialogType: DealActionModals) =>
    setModalConfig({ isOpen: true, dialog: dialogType });
  const closeModal = () => setModalConfig({ isOpen: false });

  function ModalContent(): JSX.Element {
    switch (modalConfig.dialog) {
      case DealActionModals.Share:
        return startup ? (
          <ShareProfileDialogContent startup={startup} />
        ) : (
          <></>
        );
      default:
        return <></>;
    }
  }

  async function onSubmit(
    values: FormikValues,
    { setSubmitting }: FormikHelpers<any>,
  ): Promise<void> {
    try {
      setSubmitting(true);

      await addNewDeck(values, onDeckAdded);

      setShowDeckSaveToast(true);
      setTimeout(() => setShowDeckSaveToast(false), 3000);
    } catch (error) {
      Logger.error(error);
    } finally {
      setSubmitCount((x) => x + 1);
      setSubmitting(false);
    }
  }

  async function fetchStartup() {
    try {
      setIsLoading(true);
      const startup = await API.get<PrivateStartup>(StartupGet.buildEndpoint());
      setStartup(startup);

      const latestDeck = getLatestDeck(startup);
      setLatestDeck(latestDeck);

      const initialValues = {
        latestDeckVersion: latestDeck.version,
        [StartupFieldNames.DeckMustValidate]: false,
        [StartupFieldNames.Deck]: latestDeck,
        [StartupFieldNames.DeckChangeReason]: '',
        [StartupFieldNames.DeckChangeType]: '',
      };

      setInitialValues(initialValues);
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    fetchStartup();
  }, [submitCount]);

  if (isLoading && submitCount === 0) {
    return <PageLoadingSpinner />;
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validateOnMount
        validationSchema={sections
          .map((section) => section.fields)
          .flat()
          .map((field) => field.validation)
          .reduce((merged, schema) => merged.concat(schema))}
        validateOnChange={true}
        onSubmit={onSubmit}
      >
        {({ resetForm, isSubmitting, isValid, dirty, values, errors }) => (
          <Form>
            <WarnBeforeExit isEnabled={dirty} />

            <div className="mx-auto grid max-w-full grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-2">
              <section>
                <div className="mx-1 my-4 bg-white p-0.5 shadow sm:rounded-md">
                  <div className="bg-white sm:rounded-md">
                    <header className="flex items-start justify-between rounded-t-md border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                      <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          {sections[0].title}
                        </h3>

                        {latestDeck && (
                          <div className="flex items-center space-x-2">
                            <span className="mt-1 text-sm text-gray-500">
                              Last updated&nbsp;
                              <b>
                                <DateString
                                  date={latestDeck.createdOn}
                                  tooltipPosition="top"
                                />
                              </b>
                            </span>
                            <span
                              title="Current deck version according to Flowlie's version control"
                              className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800"
                            >
                              Version {latestDeck.version.major}.
                              {latestDeck.version.minor}
                            </span>
                          </div>
                        )}
                      </div>
                      <button
                        type="button"
                        className="app-button--primary"
                        onClick={() => openModal(DealActionModals.Share)}
                      >
                        <PaperAirplaneIcon className="mr-2 h-4 w-4" />
                        Send FlowLink
                      </button>
                    </header>

                    <div className="px-4 py-5 sm:px-6">
                      {sections[0].fields.map((field) => (
                        <Fragment key={sections[0].fields.indexOf(field)}>
                          {field.fieldComponent}
                        </Fragment>
                      ))}
                      {values[StartupFieldNames.DeckMustValidate] === true && (
                        <div className="mt-6 flex items-center justify-end space-x-3">
                          <button
                            type="button"
                            onClick={() => resetForm()}
                            className="app-button--neutral"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="app-button--green"
                            disabled={!dirty || isSubmitting}
                          >
                            Save
                            {isSubmitting && (
                              <div className="ml-2">
                                <LoadingSpinner color="white" />
                              </div>
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="z-20">
              <Toast
                isShown={showDeckSaveToast}
                onClose={() => setShowDeckSaveToast(false)}
                title={DECK_SAVED}
                text={PROFILE_PUBLIC}
              />
            </div>

            {/* Debug Formik Values */}
            <DebugRender className="my-4 flex flex-col space-y-2 text-xs">
              <Disclosure>
                <Disclosure.Button className="app-button--neutral">
                  <pre>Can Submit Form:</pre>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <pre>Cannot submit if any of the following is true:</pre>
                  <pre>{`is not Valid: ${!isValid}`}</pre>
                  <pre>{`isSubmitting: ${isSubmitting}`}</pre>
                  <pre>{`not modified yet: ${!dirty}`}</pre>
                </Disclosure.Panel>
              </Disclosure>

              <Disclosure>
                <Disclosure.Button className="app-button--neutral">
                  <pre>Formik Values:</pre>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <pre className="mb-24">{JSON.stringify(values, null, 2)}</pre>
                </Disclosure.Panel>
              </Disclosure>

              <Disclosure>
                <Disclosure.Button className="app-button--neutral">
                  <pre>Formik File Upload:</pre>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <pre className="mb-6">
                    {values.logo
                      ? JSON.stringify(
                          {
                            fileName: values.logo.name,
                            type: values.logo.type,
                            size: `${values.logo.size} bytes`,
                          },
                          null,
                          2,
                        )
                      : null}
                  </pre>
                  <pre className="mb-6">
                    {values.deck
                      ? JSON.stringify(
                          {
                            fileName: values.deck.name,
                            type: values.deck.type,
                            size: `${values.deck.size} bytes`,
                          },
                          null,
                          2,
                        )
                      : null}
                  </pre>
                </Disclosure.Panel>
              </Disclosure>

              <Disclosure>
                <Disclosure.Button className="app-button--neutral">
                  <pre>Formik Errors:</pre>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <pre className="mb-24">{JSON.stringify(errors, null, 2)}</pre>
                </Disclosure.Panel>
              </Disclosure>
            </DebugRender>
          </Form>
        )}
      </Formik>
      <ModalWrapper open={modalConfig.isOpen} onClose={() => closeModal()}>
        <ModalContent />
      </ModalWrapper>
    </div>
  );
}

export default DeckForm;
