/**
 * A basic implementation of Python's range function to generate a sequence of numbers
 * NOTE: it is not quite like python, because the list is mutable and the stop parameter is inclusive.
 * @param start Optional. An integer number specifying at which position to start. Default is 0
 * @param stop Required. An integer number specifying at which position to stop (included).
 * @param step Optional. An integer number specifying the incrementation. Default is 1
 * @returns An array of numbers
 */
export function range(start: number, stop?: number, step?: number): number[] {
  const sequence: number[] = [];

  // setting up default values for start and stop
  // calling range(i) is equal to calling range(0, i)
  if (stop === undefined) {
    stop = start;
    start = 0;
  }

  if (step === undefined) {
    step = 1;
  }

  if (start > stop) {
    return sequence;
  }

  for (let i = start; i <= stop; i += step) {
    sequence.push(i);
  }

  return sequence;
}
