import React from 'react';
import * as yup from 'yup';
import FormField from '/src/interfaces/FormField';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import BooleanSelectInput from '/src/components/inputs/BooleanSelectInput';
import { useFormikContext } from 'formik';
import isInputShown from '../InputShown';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.HasRightsMfn, values) ? (
    <BooleanSelectInput
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      label="Most Favorable Nation Rights"
      name={RoundFieldNames.HasRightsMfn}
      isClearable
      secondaryLabel="MFN rights are usually present in uncapped SAFEs with no discount."
      tooltip="A “Most Favorable Nation” provision entitles the investor to receive the same terms as the best deal offered to any other investor in the future. If you later sign another SAFE or Convertible Note (CN) while this SAFE or CN is still outstanding at a better valuation, this SAFE or CN will automatically receive those same terms."
    />
  ) : (
    <></>
  );
}

const HasRightsMfnField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.HasRightsMfn]: yup.bool().nullable(),
  }),
};

export default HasRightsMfnField;
