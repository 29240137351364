import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import { COMPANY_TYPE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { ENTITY_TYPE_UNINCORPORATED } from '/../libs/shared-types/src/constants/SelectOptions/EntityTypeOptions';

function formattedTooltip(): JSX.Element {
  return (
    <ul className="list-disc space-y-1 pl-4">
      <li>
        <span className="mr-1 rounded-md bg-slate-100 px-1.5 py-0.5 text-xs text-slate-800">
          MOST COMMON
        </span>
        An operating company, also known as an active business, does all the
        trading such as selling products, entering into contracts, and hiring
        employees.
      </li>
      <li>
        A holding company holds business assets such as other companies, real
        estate or intellectual property.
      </li>
      <li>
        An investment company raises and deploys capital on behalf of its
        clients.
      </li>
    </ul>
  );
}

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.LegalEntityType] &&
    values[StartupFieldNames.LegalEntityType] !== ENTITY_TYPE_UNINCORPORATED ? (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      isClearable
      label="Company Type"
      name={StartupFieldNames.CompanyType}
      options={COMPANY_TYPE_OPTIONS}
      placeholder="Select company type..."
      tooltip={formattedTooltip()}
    />
  ) : (
    <></>
  );
}

const CompanyTypeField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.CompanyType]: yup.string().nullable(),
  }),
};

export default CompanyTypeField;
