import React from 'react';
import { getPluralSuffix } from '/src/util/formatting/numbers';
import { generateTag, generateTagCloud } from '/src/util/generateTagCloud';
import { PublicInvestor } from '/../libs/shared-types/src/types/view/investor/PublicInvestor';
import GaugeProgress from '/src/components/GaugeProgress';
import { InvestorDetailView } from '/../libs/shared-types/src/types/view/InvestorDetailView';
import {
  CheckCircleIcon,
  MinusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import {
  FitScoreReasonType,
  getFitScoreColor,
} from '/../libs/shared-types/src/types/model/FitScore';
import { capitalize } from '/src/util/formatting/strings';

interface InvestorDetailFitScoreCardProps {
  investor: InvestorDetailView;
}

function InvestorDetailFitScoreCard({
  investor,
}: InvestorDetailFitScoreCardProps): JSX.Element {
  if (investor.fitScore === undefined) {
    return <></>;
  }

  return (
    <section aria-labelledby="startup-general-information-title">
      <div className="rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Investor Fit Analysis
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <GaugeProgress
                value={investor.fitScore.score}
                maxValue={100}
                // displayValue={investor.fitScore + '%'}
                label={investor.fitScore.label + ' Fit'}
                color={
                  getFitScoreColor(investor.fitScore).textColor.split(
                    '-',
                  )[1] as any
                }
              />
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Major Factors
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                <ol className="mt-2 space-y-2">
                  {[
                    investor.fitScore.reasons.map((reason, i) => {
                      return (
                        <li key={i} className="flex items-center space-x-1">
                          <span>
                            {reason.type === FitScoreReasonType.Good && (
                              <CheckCircleIcon className="size-5 text-green-500" />
                            )}
                            {reason.type === FitScoreReasonType.Agnostic && (
                              <MinusCircleIcon className="size-5 text-yellow-500" />
                            )}
                            {reason.type === FitScoreReasonType.Bad && (
                              <XCircleIcon className="size-5 text-red-500" />
                            )}
                          </span>
                          <span className="">{capitalize(reason.text)}</span>
                        </li>
                      );
                    }),
                  ]}
                </ol>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
}

export default InvestorDetailFitScoreCard;
