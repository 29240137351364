export function getOwnershipValue(
  ownership: number,
  valuation: number
): number {
  return (ownership * valuation) / 100;
}

export function getPostMoney(preMoney: number, raise: number): number {
  return preMoney + raise;
}

export function getDilutionAmount(raise: number, postMoney: number): number {
  return (raise / postMoney) * 100;
}

export function getDilutedOwnership(
  ownership: number,
  dilutionAmount: number
): number {
  // dilutionAmount : 100 = x : ownership
  // x = dilutionAmount * ownership / 100
  const dilution = (dilutionAmount * ownership) / 100;
  return ownership - dilution;
}
