import React from 'react';

interface MailToParams {
  to: string;
  cc?: string;
  bcc?: string;
  subject?: string;
  body?: string;
}

export function getMailToHref(params: MailToParams) {
  const cleanData = Object.entries({ ...params, to: undefined })
    .filter(([_, value]) => value !== undefined)
    .reduce((obj, [key, value]) => {
      (obj as any)[key] = value ?? '';
      return obj;
    }, {});
  const paramsString = new URLSearchParams(cleanData)
    .toString()
    .replaceAll('+', '%20');
  return `mailto:${params.to}?${paramsString}`;
}

interface MailToLinkProps {
  linkText: string;
  params: MailToParams;
  className?: string;
}

/**
 * Encode Mail parameters in the link, so that the email is prefilled with the given data
 */
function MailToLink({ linkText, params, className }: MailToLinkProps) {
  return (
    <a className={className} href={getMailToHref(params)}>
      {linkText}
    </a>
  );
}

export default MailToLink;
