import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { INVESTOR_UPDATES_FREQUENCY_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/InvestorUpdatesFrequencyOptions';
import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

const InvestorUpdatesFrequencyField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      label="Will you be receiving investor updates?"
      name={RecordInvestmentFieldNames.InvestorUpdatesFrequency}
      options={INVESTOR_UPDATES_FREQUENCY_OPTIONS}
      placeholder="Select..."
    />
  ),
  validation: yup.object({
    [RecordInvestmentFieldNames.InvestorUpdatesFrequency]: yup
      .string()
      .required('Required'),
  }),
};

export default InvestorUpdatesFrequencyField;
