/**
 * @param number Number to check
 * @returns 's' if number is plural
 */
export const getPluralSuffix = (number: number): string =>
  Math.abs(number) > 1 || number === 0 ? 's' : '';

/**
 * Formats a number with commas separating the thousands
 * @param x Number to format
 * @param fixed Optionally apply to toFixed amount
 */
export function numberWithCommas(
  x: number | undefined,
  fixed?: number,
): string {
  if (x === undefined) {
    return '';
  }
  if (fixed) {
    const numWithDecimals = x.toFixed(fixed);
    const integerNumber = numWithDecimals.split('.')[0];
    const decimal = numWithDecimals.split('.')[1];

    return `${Number(integerNumber)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${decimal}`;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Formats a number into its short string form. Maximum 999 million allowed
 * @param num Number to format
 * @returns The number formatted as string with the appropriate suffix
 */
export function abbreviateNumber(num: number | undefined): string {
  if (num === undefined) return '';
  let suffixNum = 0;
  const suffixes = ['', 'K', 'MM', 'B'];
  const absValue = Math.abs(num);
  if (absValue >= 1000 && absValue < 1_000_000) {
    suffixNum = 1;
  } else if (absValue >= 1_000_000 && absValue < 1_000_000_000) {
    suffixNum = 2;
  } else if (absValue >= 1_000_000_000 && absValue < 1_000_000_000_000) {
    suffixNum = 3;
  } else {
    // If the number is larger than 999 billions, don't use any suffix
    suffixNum = 0;
  }

  const shortValue = suffixNum !== 0 ? num / 1000 ** suffixNum : num;
  if (typeof shortValue !== 'number') {
    return '_';
  }

  return (
    shortValue.toFixed(3).toString().replace(/0+$/, '').replace(/\.$/, '') +
    suffixes[suffixNum]
  );
}

export function displayMoneyRange(min: number, max: number): string {
  if (min === max) {
    return `$${abbreviateNumber(min)}`;
  }

  return `$${abbreviateNumber(min)} - $${abbreviateNumber(max)}`;
}

export function displayPercentageRange(min: number, max: number): string {
  if (min === max) {
    return `${abbreviateNumber(min)}%`;
  }

  return `${abbreviateNumber(min)}% - ${abbreviateNumber(max)}%`;
}

export function getRandomInt(min: number, max: number) {
  const ceiledMin = Math.ceil(min);
  const flooredMax = Math.floor(max);
  return Math.floor(Math.random() * (flooredMax - ceiledMin) + ceiledMin);
}

export function getRandomIntArrayInRange(min: number, max: number, length = 1) {
  return Array.from(
    { length: length },
    () => Math.floor(Math.random() * (max - min + 1)) + min,
  );
}
