import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LinkShareMetadata } from '/../libs/shared-types/src/types/view/LinkShareMetadata';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import { PublicInvestor } from '/../libs/shared-types/src/types/view/investor/PublicInvestor';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { OnboardingFlows } from '/../libs/shared-types/src/constants/OnboardingFlows';
import { getOnboardingFlow } from '/../libs/shared-types/src/extensions/OnboardingFlowsExtensions';
import { LocalStorageAccountData } from '/src/middleware/LocalStorage';
import { STARTUP_PROFILE_ROUTE, SCREENING_ROUTE } from '/src/constants/Routes';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';

function getInvestorFirmOrType(investor: PublicInvestor | undefined) {
  if (!investor) {
    return '';
  }
  return investor.type === InvestorTypes.Angel
    ? '(Angel)'
    : `(${investor.firm})`;
}

interface TargetedMessage {
  header: string;
  body: string;
  callToAction: string;
  disclaimer: string;
}

/**
 *
 * @param linkShareMetadata The data about the account that is inviting the new user
 * @param onboardingAccount The data about the account that is onboarding
 * @param linkType The type of link the new user signed up from
 * @returns An object with the strings to display in the successful completion of the onboarding form
 */
function getTargetedMessage(
  onboardingAccount: LocalStorageAccountData,
  unconfirmedAccountType: AccountTypes | undefined,
  linkShareMetadata?: LinkShareMetadata,
  linkType?: LinkTypes
): TargetedMessage {
  let header = '';
  let body = '';
  let callToAction = '';
  let disclaimer = '';

  let invitedByAccountType = undefined;
  if (linkShareMetadata?.investor !== undefined) {
    invitedByAccountType = AccountTypes.Investor;
  } else if (linkShareMetadata?.startup !== undefined) {
    invitedByAccountType = AccountTypes.Startup;
  }

  const onboardingFlow = getOnboardingFlow(
    unconfirmedAccountType ?? '',
    invitedByAccountType,
    linkType
  );

  switch (onboardingFlow) {
    case OnboardingFlows.InvestorViaDealShareLink:
      header = `${onboardingAccount.accountFirstName}, thank you for connecting!`;
      body = `We can now keep track of each other’s investment theses, share deals, request intros, and more.`;
      callToAction =
        'Don’t forget to use your FlowLink as a deal intake form for founders who are fundraising (enabling you to automatically collect round details, company information, and financial data).';
      break;

    case OnboardingFlows.InvestorViaInvestorFlowLink:
      header = `${onboardingAccount.accountFirstName}, thank you for connecting!`;
      body = `We can now keep track of each other’s investment theses, share deals, request intros, and more.`;
      callToAction =
        'Don’t forget to use your FlowLink as a deal intake form for founders who are fundraising (enabling you to automatically collect round details, company information, and financial data). ';
      break;

    case OnboardingFlows.InvestorViaSignup:
      header = `Congrats ${onboardingAccount.accountFirstName}, you are now all set!`;
      body = `Get started by sharing your FlowLink with prospective founders and begin screening your dealflow automatically.`;
      callToAction =
        'Remember: share your FlowLink with investors to keep track of their investment preferences and share deals with one click using our smart fit algorithm. Ready to supercharge your work?';
      break;

    case OnboardingFlows.InvestorViaStartupFlowLink:
      header = `Congrats ${onboardingAccount.accountFirstName}, you now have access to ${linkShareMetadata?.startup?.name}’s full profile`;
      body = `And remember: share your FlowLink with other founders to add deals to your pipeline, or with investors to add them to your co-investing network.`;
      callToAction = 'Are you ready to supercharge your dealflow management?';
      break;

    case OnboardingFlows.StartupViaDealShareLink:
      header = `${onboardingAccount.accountFirstName}, thank you for joining the Flowlie community!`;
      body = `With Flowlie you can securely share your deck and company one-pager with investors in one link, manage your fundraising progress, discover investors, and track investor engagement with analytics.`;
      callToAction = 'Are you ready to supercharge your fundraising?';
      disclaimer =
        'Your one-pager and deck will stay private until you decide to share them with others';
      break;

    case OnboardingFlows.StartupViaInvestorFlowLink:
      header = `${onboardingAccount.accountFirstName}, thank you for sending me your deck and basic company information!`;
      body =
        'Please click the button below to continue, and provide me with the specific details of your fundraising round.';
      callToAction =
        'You will also be able to edit the information you submitted, and add futher details about your business that will help me run a quicker due dilegence process and therefore get back to you faster.';
      disclaimer = `Your information has only been shared with ${
        linkShareMetadata?.investor?.firstName
      } ${getInvestorFirmOrType(
        linkShareMetadata?.investor
      )} and will not be available to anyone until either you or ${
        linkShareMetadata?.investor?.firstName
      } share it.`;
      break;

    case OnboardingFlows.StartupViaSignup:
      header = `Congrats ${onboardingAccount.accountFirstName}, you've just created your company one-pager!`;
      body = `Share your FlowLink with investors to give them access to your one-pager and deck.`;
      callToAction =
        'Remember to visit the "Company One-Pager" tab to add any additional information you would like to share with investors and to preview what they will see';
      disclaimer =
        'Your one-pager and deck will stay private until you decide to share them with others';
      break;

    case OnboardingFlows.StartupViaStartupFlowLink:
      header = `Congrats ${onboardingAccount.accountFirstName}, you've just created your company one-pager!`;
      body = `Share your FlowLink with investors to give them access to your one-pager and deck.`;
      callToAction =
        'Remember to visit the "Company One-Pager" tab to add any additional information you would like to share with investors and to preview what they will see';
      disclaimer =
        'Your one-pager and deck will stay private until you decide to share them with others';
      break;

    case OnboardingFlows.UnknownViaDealShareLink:
    case OnboardingFlows.UnknownViaInvestorFlowLink:
    case OnboardingFlows.UnknownViaStartupFlowLink:
    default:
      break;
  }

  return {
    header,
    body,
    callToAction,
    disclaimer,
  };
}

interface CompleteProfileSuccessMessageProps {
  onboardingAccount: LocalStorageAccountData;
  linkShareMetadata?: LinkShareMetadata;
  linkType?: LinkTypes;
}

function CompleteProfileSuccessMessage({
  linkShareMetadata,
  onboardingAccount,
  linkType,
}: CompleteProfileSuccessMessageProps): JSX.Element {
  const { unconfirmedAccountType } = useContext(AuthenticationContext);
  const content = getTargetedMessage(
    onboardingAccount,
    unconfirmedAccountType,
    linkShareMetadata,
    linkType
  );

  return (
    <>
      <h2 className="login-signup-header text-2xl">{content.header}</h2>
      <p className="text-xl font-semibold text-gray-700">{content.body}</p>
      <p className="mt-6 text-xl text-gray-700">{content.callToAction}</p>
      <section className="my-10 flex flex-col space-y-8">
        {unconfirmedAccountType === AccountTypes.Startup && (
          <>
            <Link className="self-center" to={STARTUP_PROFILE_ROUTE}>
              <button type="button" className="button">
                Continue
              </button>
            </Link>
          </>
        )}
        {unconfirmedAccountType === AccountTypes.Investor && (
          <>
            <Link className="self-center" to={SCREENING_ROUTE}>
              <button type="button" className="button">
                Continue
              </button>
            </Link>
          </>
        )}
      </section>
      <p className="text-center text-sm text-gray-600">{content.disclaimer}</p>
    </>
  );
}

export default CompleteProfileSuccessMessage;
