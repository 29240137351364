import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import { Breadcrumbs } from '../../AdminRoutes';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { AdminGetFitScoreBenchmark } from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import saveAs from 'file-saver';
import { Histogram } from '../../../components/charts/Histogram';
import {
  FitScoreBenchmark,
  PaginatedFitScoreBenchmarkResponse,
} from '/../libs/shared-types/src/types/view/APIResponse';
import { RadioGroup } from '@headlessui/react';
import { joinClassNames } from '/src/util/formatting/strings';
import ToggleSwitch from '/src/components/utility/ToggleSwitch';
import { numberWithCommas } from '/src/util/formatting/numbers';

function downloadFile(fitScores: number[], name?: string) {
  const blob = new Blob([JSON.stringify(fitScores)], {
    type: 'text/plain;charset=utf-8',
  });
  const fileName = `${name ?? 'FitScores'}_${new Date().toISOString()}.txt`;
  saveAs(blob, fileName);
}

function FitScoreBenchmarkRoute() {
  const [isLoading, setIsLoading] = useState(true);
  const [benchmarkResponse, setBenchmarkResponse] =
    useState<PaginatedFitScoreBenchmarkResponse>();
  const [selectedFitScoreData, setSelectedFitScoreData] = useState<number[]>(
    []
  );
  const [fitScoreData, setFitScoreData] = useState<FitScoreBenchmark>({
    allFitScores: [],
    allInvestorsFitScores: [],
    allUnverifiedInvestorsFitScores: [],
  });
  const [histogramBucketSize, setHistogramBucketSize] = useState<number>(1);
  const [histogramHasUniformBuckets, setHistogramHasUniformBuckets] =
    useState<boolean>(true);

  async function fetchAllFitScores() {
    setIsLoading(true);
    try {
      const poll = {
        hasMore: true,
        page: 1,
        perPage: 100,
      };

      const benchmark: FitScoreBenchmark = {
        allFitScores: [],
        allInvestorsFitScores: [],
        allUnverifiedInvestorsFitScores: [],
      };

      while (poll.hasMore && poll.page < 50) {
        const data = await API.get<PaginatedFitScoreBenchmarkResponse>(
          AdminGetFitScoreBenchmark.buildEndpoint(undefined, {
            page: poll.page,
            perPage: poll.perPage,
          })
        );
        setBenchmarkResponse(data);

        poll.hasMore = data.hasMore;
        poll.page = data.page + 1;
        poll.perPage = data.perPage;

        benchmark.allFitScores = benchmark.allFitScores.concat(
          data.results[0].allFitScores
        );
        benchmark.allInvestorsFitScores =
          benchmark.allInvestorsFitScores.concat(
            data.results[0].allInvestorsFitScores
          );
        benchmark.allUnverifiedInvestorsFitScores =
          benchmark.allUnverifiedInvestorsFitScores.concat(
            data.results[0].allUnverifiedInvestorsFitScores
          );
      }

      setFitScoreData(benchmark);
      setSelectedFitScoreData(benchmark.allFitScores);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchAllFitScores();
  }, []);

  if (isLoading) {
    return <PageLoadingSpinner />;
  }

  return (
    <div className="flex w-full flex-col p-4">
      <Breadcrumbs />

      <header className="sticky top-0 z-20 bg-white pb-2 dark:bg-transparent dark:backdrop-blur">
        <div className="relative flex max-w-full items-center justify-between">
          <h3 className="page-title">Fit Score Benchmark</h3>
        </div>

        <div className="-mt-2 space-x-4 text-sm font-medium">
          <span>
            STARTUPS: {numberWithCommas(benchmarkResponse?.totalStartupsCount)}
          </span>
          <span>
            INVESTORS:{' '}
            {numberWithCommas(benchmarkResponse?.totalInvestorsCount)}
          </span>
          <span>
            UNVERIFIED INVESTORS:{' '}
            {numberWithCommas(benchmarkResponse?.totalUnverifiedInvestorsCount)}
          </span>
        </div>
      </header>

      <div className="flex flex-1 items-stretch overflow-hidden">
        <main className="flex-1 overflow-y-auto">
          <div className="mx-auto max-w-7xl border-2 border-black px-4 pt-8 sm:px-6 lg:px-8">
            <Histogram
              width={1000}
              height={700}
              data={selectedFitScoreData}
              dataBucketing={{
                hasUniformBuckets: histogramHasUniformBuckets,
                desiredBucketCount: histogramHasUniformBuckets
                  ? 100 - histogramBucketSize
                  : histogramBucketSize,
              }}
            />
          </div>
        </main>

        <aside className="hidden w-96 overflow-y-auto border-l border-gray-200 bg-white p-6 lg:block">
          <div className="space-y-6">
            <section>
              <h3 className="mb-3 text-lg font-bold text-gray-900">
                Select Data
              </h3>

              <RadioGroup
                value={selectedFitScoreData}
                onChange={setSelectedFitScoreData}
              >
                <RadioGroup.Label className="sr-only">
                  Select data to display
                </RadioGroup.Label>
                <div>
                  {Object.keys(fitScoreData).map((fitScoreLabel) => (
                    <RadioGroup.Option
                      key={fitScoreLabel}
                      className={({ checked }) =>
                        joinClassNames(
                          checked ? 'z-10 text-blue-600' : '',
                          'relative flex cursor-pointer p-2 focus:outline-none'
                        )
                      }
                      value={
                        fitScoreData[fitScoreLabel as keyof FitScoreBenchmark]
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <>
                            <span
                              className={joinClassNames(
                                checked
                                  ? 'border-transparent bg-blue-600'
                                  : 'border-gray-300 bg-white',
                                active
                                  ? 'ring-2 ring-blue-600 ring-offset-2'
                                  : '',
                                'mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border'
                              )}
                              aria-hidden="true"
                            >
                              <span className="h-1.5 w-1.5 rounded-full bg-white" />
                            </span>
                            <span className="ml-3 flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className={joinClassNames(
                                  checked ? 'text-blue-900' : 'text-gray-900',
                                  'block text-sm font-medium'
                                )}
                              >
                                {fitScoreLabel}
                              </RadioGroup.Label>
                            </span>
                          </>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </section>

            <section>
              <label htmlFor="bucketSize" className="input-label mb-4">
                <div>
                  Bucket Size{' '}
                  <input
                    id="bucketSize"
                    name="bucketSize"
                    type="number"
                    className="ml-3 rounded-md border-gray-300 py-0.5 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    step={1}
                    min={1}
                    max={100}
                    onChange={(e) =>
                      setHistogramBucketSize(Number(e.target.value))
                    }
                    value={histogramBucketSize}
                  />
                </div>
                <input
                  id="bucketSize_slider"
                  name="bucketSize_slider"
                  type="range"
                  className="my-2 w-72"
                  step={1}
                  min={1}
                  max={100}
                  onChange={(e) =>
                    setHistogramBucketSize(Number(e.target.value))
                  }
                  value={histogramBucketSize}
                />
              </label>
              <ToggleSwitch
                showToggleIcon
                toggleOn={() => setHistogramHasUniformBuckets(true)}
                toggleOff={() => setHistogramHasUniformBuckets(false)}
                name={'bucketSpacing'}
                label={'Uniform Buckets'}
                initialState={histogramHasUniformBuckets}
              />
            </section>

            <section className="space-y-6">
              <h3 className="mb-3 text-lg font-bold text-gray-900">Summary</h3>

              <ul className="ml-5 list-outside list-disc text-sm text-gray-700">
                <li>
                  FIT SCORES COUNT:{' '}
                  {numberWithCommas(selectedFitScoreData.length)}
                </li>
                <li>MIN: {d3.min(selectedFitScoreData)}</li>
                <li>MAX: {d3.max(selectedFitScoreData)}</li>
                <li>MEAN: {d3.mean(selectedFitScoreData)}</li>
                <li>MEDIAN: {d3.median(selectedFitScoreData)}</li>
                <li>STD DEVIATION: {d3.deviation(selectedFitScoreData)}</li>
              </ul>

              {selectedFitScoreData.length > 0 && (
                <button
                  type="button"
                  onClick={() =>
                    downloadFile(
                      selectedFitScoreData,
                      Object.keys(fitScoreData).find(
                        (key) =>
                          fitScoreData[key as keyof FitScoreBenchmark] ===
                          selectedFitScoreData
                      )
                    )
                  }
                  className="app-button--neutral"
                >
                  Download Data
                </button>
              )}
            </section>
          </div>
        </aside>
      </div>
    </div>
  );
}

export default FitScoreBenchmarkRoute;
