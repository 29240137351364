import React from 'react';
import * as yup from 'yup';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import {
  REVENUE_MIN,
  REVENUE_MAX,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import MoneyInput from '/src/components/inputs/MoneyInput';
import { REVENUE_TYPE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import { useFormikContext } from 'formik';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return (
    <MoneyInput
      label={
        values[StartupFieldNames.RevenueType] === REVENUE_TYPE_OPTIONS[3].value
          ? 'Monthly Recurring Revenue (MRR)'
          : 'Monthly Revenue'
      }
      name={StartupFieldNames.Revenue}
    />
  );
}

const RevenueField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.Revenue]: yup
      .number()
      .min(REVENUE_MIN, `Must be greater than or equal to $ ${REVENUE_MIN}`)
      .max(REVENUE_MAX, `Cannot be greater than $ ${REVENUE_MAX}`)
      .typeError('Must be a number')
      .when(StartupFieldNames.RevenueMonthlyGmv, {
        is: (gmv: number) => gmv !== undefined && gmv > 0,
        then: (schema) =>
          schema
            .typeError('Must be a number')
            .lessThan(
              yup.ref(StartupFieldNames.RevenueMonthlyGmv),
              'Must be less than Monthly GMV',
            ),
      }),
  }),
};

export default RevenueField;
