export enum PrimaryGrowthDriver {
  ProductLed = 'Product-led',
  SalesLed = 'Sales-led',
  CommunityLed = 'Community-led',
  MarketingLed = 'Marketing-led',
  PartnershipLed = 'Partnership-led',
  AffiliateLed = 'Affiliate-led',
}

export function getPrimaryGrowthDriverTooltip(
  primaryGrowthDriver: string
): string {
  switch (primaryGrowthDriver) {
    case PrimaryGrowthDriver.ProductLed:
      return 'Product usage alone allows existing users to attract new users';
    case PrimaryGrowthDriver.SalesLed:
      return 'Direct sales to enterprises and individuals';
    case PrimaryGrowthDriver.CommunityLed:
      return 'Events and programming for existing users and the community';
    case PrimaryGrowthDriver.MarketingLed:
      return 'Advertising (organic or paid) and content creation';
    case PrimaryGrowthDriver.PartnershipLed:
      return 'Leveraging distribution channels via partners with an existing audience';
    case PrimaryGrowthDriver.AffiliateLed:
      return 'Other enterprises and individuals selling on your behalf for a commission';
    default:
      return '';
  }
}
