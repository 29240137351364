import { QueryTypes, useQueryParam } from './useQueryParam';
import { SortOrder } from '/src/interfaces/Sortable';

function usePaginatedUrlsParams() {
  const [page, setPage] = useQueryParam<number>('page', QueryTypes.NumberType);
  const [perPage, setPerPage] = useQueryParam<number>(
    'perPage',
    QueryTypes.NumberType
  );
  const [filter, setFilter] = useQueryParam<string>(
    'filter',
    QueryTypes.StringType
  );
  const [sortOrder, setSortOrder] = useQueryParam<SortOrder>(
    'sortOrder',
    QueryTypes.NumberType
  );

  const [sortKey, setSortKey] = useQueryParam<string>(
    'sortKey',
    QueryTypes.StringType
  );

  const [fieldFilters, setFieldFilters] = useQueryParam<object>(
    'fieldFilters',
    QueryTypes.ObjectType
  );

  return {
    page,
    perPage,
    filter,
    sortOrder,
    sortKey,
    setPage,
    setPerPage,
    setFilter,
    setSortOrder,
    setSortKey,
    fieldFilters,
    setFieldFilters,
  };
}

export default usePaginatedUrlsParams;
