import { toDashCase } from './strings';

/**
 * This is used to support the CustomNavLink functionality after the upggrade to React Router V6.
 * It is needed to determine which route isActive and display the proper style
 * @param pathname The pathname from useLocation()
 * @param tabNames The array of tab names
 * @returns The invariant base path across all tabNames routes
 */
export function getRouteBasePath(pathname: string, tabNames: string[]) {
  let basePath = pathname;
  for (const tab of tabNames) {
    basePath = basePath.replace(`/${toDashCase(tab)}`, '');
  }
  return basePath;
}

/**
 * Formats a route by removing the prefix specified
 * by the user and optionally appending a /* for
 * routes that need to allow for subroutes to be rendered
 * @example ('/app/investor', '/app/', true) => 'investor/*'
 * @example ('/blabla/startup', '/app/', false) => '/blabla/startup'
 * @example ('/blabla/startup', '/app/', false, 'id') => '/blabla/startup/:id'
 */
export const formatRoute = (
  route: string,
  prefix: string,
  hasSubroutes = false,
  param?: string
) =>
  `${route.replace(prefix, '')}${param ? '/:' + param : ''}${
    hasSubroutes ? '/*' : ''
  }`;
