import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import { LIQUIDATION_PARTICIPATION_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/LiquidationParticipationOptions';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.LiquidationParticipation, values) ? (
    <Field
      className="custom-select"
      closeMenuOnSelect
      isClearable
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      component={CustomSelect}
      label="Liquidation Participation"
      name={RoundFieldNames.LiquidationParticipation}
      options={LIQUIDATION_PARTICIPATION_OPTIONS}
      placeholder="Select..."
      tooltip="A “Liquidation Participation” provision entitles the investor to either participate (capped or uncapped) or not in the additional proceeds after their preference is returned in the case of a liquidation."
    />
  ) : (
    <></>
  );
}

const LiquidationParticipationField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.LiquidationParticipation]: yup.string(),
  }),
};

export default LiquidationParticipationField;
