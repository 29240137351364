import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import { BOOLEAN_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[InvestorFieldNames.IsOpenToColdInbound] ? (
    <>
      <Field
        className="custom-select"
        component={CustomSelect}
        isClearable
        closeMenuOnSelect
        label="Do you prefer founders follow up if you don't respond?"
        name={InvestorFieldNames.PreferenceFollowUp}
        options={BOOLEAN_OPTIONS}
        placeholder="Select option..."
      />
    </>
  ) : (
    <></>
  );
}

const PreferenceFollowUpField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.PreferenceFollowUp]: yup.boolean().nullable(),
  }),
};

export default PreferenceFollowUpField;
