/**
 * Abstraction layer for integrating the cache API into the file fetching side of things
 */

import { getBaseAppUrl } from '../util/urls';

const MAX_FILES = 20;
const CACHE_NAME = 'files';

const cacheAvailable = () => 'caches' in window.self;

function buildCacheRequest(key: string): Request {
  const url = `${getBaseAppUrl()}/${key}`;
  return new Request(url);
}

async function numberOfFilesInCache(): Promise<number> {
  const cache = await caches.open(CACHE_NAME);
  return cache.keys().then((keys) => keys.length);
}

// delete oldest file in cache
async function deleteOldestFile(): Promise<void> {
  if (cacheAvailable()) {
    const cache = await caches.open(CACHE_NAME);
    // keys are returned in the order they were inserted
    const keys = await cache.keys();
    if (keys.length > 0) {
      await cache.delete(keys[0]);
    }
  }
}

/*
Get Entry from Cache, if it exists then remove from cache and
save again to update the timestamp, otherwise return undefined
*/
export async function getFileFromCache(key: string): Promise<Blob | undefined> {
  if (cacheAvailable()) {
    const cache = await caches.open(CACHE_NAME);
    const req = buildCacheRequest(key);
    const res = await cache.match(req);
    if (res) {
      const blob = await res.blob();
      await cache.delete(req);
      await cache.put(req, new Response(blob));
      return blob;
    }
  }
  return undefined;
}

export async function saveFileToCache(key: string, blob: Blob): Promise<void> {
  if (cacheAvailable()) {
    const cacheSize = await numberOfFilesInCache();
    if (cacheSize >= MAX_FILES) {
      await deleteOldestFile();
    }
    const cache = await caches.open(CACHE_NAME);
    await cache.put(buildCacheRequest(key), new Response(blob));
  }
}
