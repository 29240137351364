import { ValuationCapType } from '../constants/ValuationCapType';

export function calculatePercentFunded(
  raisedAmount: number,
  raiseTarget: number
): number {
  return Math.round((raisedAmount / raiseTarget) * 100);
}

export function calculateAmountRemaining(
  raiseTarget: number,
  raisedAmount: number
): number {
  const amount = raiseTarget - raisedAmount;
  return amount;
}

export function calculatePercentSold(
  raiseTarget: number,
  raisedAmount: number,
  roundCap: number,
  roundCapType: ValuationCapType
): number | undefined {
  const estimatedRaise =
    raisedAmount > raiseTarget ? raisedAmount : raiseTarget;

  let estimatedPostCap = roundCap;
  if (roundCapType === ValuationCapType.PreMoney) {
    estimatedPostCap = roundCap + estimatedRaise;
  }

  const value = parseFloat(
    ((estimatedRaise / estimatedPostCap) * 100).toFixed(1)
  );
  if (isNaN(value) || !isFinite(value)) {
    return undefined;
  }

  return value;
}

export function calculateRevenueMultiple(
  monthlyRevenue: number,
  estimatedPostCap: number
): number {
  const result = estimatedPostCap / (monthlyRevenue * 12);
  return result;
}
