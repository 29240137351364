enum FounderFieldNames {
  About = 'about',
  Commitment = 'commitment',
  CompaniesFoundedCount = 'companiesFoundedCount',
  Email = 'email',
  FirstName = 'firstName',
  HighestEducationLevel = 'highestEducationLevel',
  IsTechnical = 'isTechnical',
  LastName = 'lastName',
  LinkedIn = 'linkedIn',
  Location = 'location',
  Nationalities = 'nationalities',
  PreviousBackingAmount = 'previousBackingAmount',
  PreviousExitAmount = 'previousExitAmount',
  ProfilePic = 'profilePic',
  ProfilePicKey = 'profilePicKey', // Utility for formik
  Pronouns = 'pronouns',
  Role = 'role',
  Twitter = 'twitter',
  UniversityAffiliations = 'universityAffiliations',
  YearsOfIndustryExperience = 'yearsOfIndustryExperience',
}

export const FOUNDER_URL_FIELD_NAMES = [
  FounderFieldNames.LinkedIn,
  FounderFieldNames.Twitter,
];

export default FounderFieldNames;
