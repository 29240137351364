/**
 * Investor Profile Fields
 */
export const CHECK_RANGE_MAX = 100_000_000;
export const CHECK_RANGE_MIN = 1_000;

export const EXP_INVESTING_YEARS_MAX = 50;
export const EXP_INVESTING_YEARS_MIN = 1;

export const INITIAL_OWNERSHIP_TARGET_RANGE_MAX = 50;
export const INITIAL_OWNERSHIP_TARGET_RANGE_MIN = 1;

/**
 * Investor Investment Fields
 */
export const CARRY_CHARGED_MIN = 0;
export const CARRY_CHARGED_MAX = 99;

export const INVESTMENT_AMOUNT_MIN = 0;
export const INVESTMENT_AMOUNT_MAX = 1_000_000_000;

/**
 * Startup Investor Pipeline Fields
 */
export const COMMITMENT_AMOUNT_MIN = 100;
export const COMMITMENT_AMOUNT_MAX = 100_000_000;

/**
 * Startup Round Fields
 */
export const BRIDGE_NUMBER_MIN = 1;
export const BRIDGE_NUMBER_MAX = 5;

export const VALUATION_CAP_MIN = 1000;
export const VALUATION_CAP_MAX = 10_000_000_000;

export const PRICE_PER_SHARE_MIN = 0.0001;
export const PRICE_PER_SHARE_MAX = 1_000_000;

export const RAISED_AMOUNT_MIN = 0;
export const RAISED_AMOUNT_MAX = 500_000_000;

export const RAISE_TARGET_MIN = 10_000;
export const RAISE_TARGET_MAX = 500_000_000;

export const LIQUIDATION_PREFERENCE_MIN = 0;
export const LIQUIDATION_PREFERENCE_MAX = 10;

// Startup
export const CONVERSION_TERM_MAX = 60;
export const CONVERSION_TERM_MIN = 1;

export const DISCOUNT_MIN = 0;
export const DISCOUNT_MAX = 99;

export const INTEREST_MIN = 0;
export const INTEREST_MAX = 99;

/**
 * Startup profile fields
 */
export const RECURRING_MONETARY_MAX = 1_000_000_000;

export const AVERAGE_ORDER_VALUE_MIN = 0;
export const AVERAGE_ORDER_VALUE_MAX = 100_000_000;

export const AVERAGE_REVENUE_PER_USER_MIN = 0;
export const AVERAGE_REVENUE_PER_USER_MAX = 100_000_000;

export const BOOKINGS_MIN = 0;
export const BOOKINGS_MAX = 1_000_000_000;

export const COMPLETED_PILOTS_MIN = 0;
export const COMPLETED_PILOTS_MAX = 1_000_000;

export const NET_REVENUE_RETENTION_MIN = 0;
export const NET_REVENUE_RETENTION_MAX = 200;

export const NPS_MIN = 0;
export const NPS_MAX = 5;

export const GROSS_MARGIN_MIN = 0;
export const GROSS_MARGIN_MAX = 100;

export const LIFE_TIME_VALUE_MIN = 0;
export const LIFE_TIME_VALUE_MAX = RECURRING_MONETARY_MAX;

export const NET_MARGIN_MIN = 0;
export const NET_MARGIN_MAX = 100;

export const NET_MONTHLY_BURN_MIN = 0;
export const NET_MONTHLY_BURN_MAX = RECURRING_MONETARY_MAX;

export const REVENUE_MIN = 0;
export const REVENUE_MAX = 1_000_000_000;

export const REVENUE_GROWTH_MIN = -300;
export const REVENUE_GROWTH_MAX = 300;

export const RUNWAY_MIN = 1;
export const RUNWAY_MAX = 99;

export const CUSTOMER_COUNT_MIN = 1;
export const CUSTOMER_COUNT_MAX = 1_000_000_000;

export const TAKE_RATE_MIN = 0;
export const TAKE_RATE_MAX = 99;

export const TOTAL_ADDRESSABLE_MKT_MAX = 1_000_000_000_000;
export const TOTAL_ADDRESSABLE_MKT_MIN = 0;

export const USER_COUNT_MIN = 1;
export const USER_COUNT_MAX = 10_000_000_000;

export const USER_GROWTH_RATE_MOM_MIN = 0;
export const USER_GROWTH_RATE_MOM_MAX = 1_000;

export const USER_RETENTION_RATE_MOM_MIN = 0;
export const USER_RETENTION_RATE_MOM_MAX = 100;

export const YEARS_OF_INDUSTRY_EXPERIENCE_MIN = 0;
export const YEARS_OF_INDUSTRY_EXPERIENCE_MAX = 99;
