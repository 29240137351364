import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import { RoundStages } from '/../libs/shared-types/src/constants/RoundStages';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import {
  enumToList,
  enumToSelectOptions,
} from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';

function getDisabledOptions(invStages: RoundStages[]): string[] {
  if (!invStages || invStages.length === 0) {
    return [];
  }

  // Convert options to string array
  const optionsValues = enumToList(RoundStages);

  // Get index of 'largest" investment stage
  const index = optionsValues.indexOf(invStages[invStages.length - 1]);

  // Get array of option values before or at the index
  // NOTE: This assumes that RoundStages enum is sorted
  const disabledOptions = optionsValues.slice(0, index + 1);
  return disabledOptions;
}

function FieldWrapper() {
  const { values } = useFormikContext<any>();

  return (
    <Field
      className="custom-select"
      component={CustomSelect}
      isClearable
      isMulti
      label="Follow-on investment stages"
      secondaryLabel="For portfolio companies: what are the stages you follow-on (invest again) after the initial investment?"
      name={InvestorFieldNames.InvStagesFollowOn}
      options={enumToSelectOptions(RoundStages)}
      placeholder="Select stages..."
      disabledOptions={getDisabledOptions(values[InvestorFieldNames.InvStages])}
    />
  );
}

const InvStagesFollowOnField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.InvStagesFollowOn]: yup
      .array()
      .of(yup.string())
      .nullable()
      .test(
        'invStagesOverlapWithFollowOnStages',
        'Your primary investment stages overlap with your follow-on stages',
        (selectedFollowOnStages, context): boolean => {
          const invStages = context.parent[InvestorFieldNames.InvStages];
          const disabledOptions = getDisabledOptions(invStages);
          if (
            invStages &&
            selectedFollowOnStages?.some(
              (x) => x && disabledOptions.includes(x),
            )
          ) {
            // Return false to indicate the test failed
            return false;
          }
          return true;
        },
      ),
  }),
};

export default InvStagesFollowOnField;
