import React, { useEffect, useState } from 'react';
import Logger from '/src/services/logger';
import { InvestorList, ShareSetting } from '../../../types/model/InvestorList';
import { isMinDate } from '/src/util/time';
import ToggleSwitch from '/src/components/utility/ToggleSwitch';
import { MIN_DATE } from '/../libs/shared-types/src/constants/MinimumDate';
import CopyLinkInput from '/src/components/CopyLinkInput';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import {
  StartupUpdateInvestorListResetShareLink,
  StartupUpdateInvestorListShareSetting,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import DebugRender from '/src/components/utility/DebugRender';
import { INVESTOR_LIST_BASE_URL } from '/src/util/urls';

export function buildInvestorListShareLink(shareSlug: string): string {
  return `${INVESTOR_LIST_BASE_URL}${shareSlug}`;
}

function PrivacySettingAlertContent({
  shareSetting,
}: {
  shareSetting: ShareSetting;
}) {
  const privacySetting = isMinDate(shareSetting.disabledOn)
    ? 'Shared'
    : 'Private';

  switch (privacySetting) {
    case 'Shared':
      return (
        <span>
          This list is <strong>Shared</strong>. Anyone with the link can view
          the investors in this target list.
        </span>
      );
    case 'Private':
      return (
        <span>
          This list is <strong>Private</strong> to you. No one else can view it.
        </span>
      );
    default:
      return <></>;
  }
}

interface ShareInvestorListDialogProps {
  investorList: InvestorList;
  onShareSettingUpdated: (investorList: InvestorList) => void;
}

function ShareInvestorListDialog({
  investorList,
  onShareSettingUpdated,
}: ShareInvestorListDialogProps): JSX.Element {
  const [shareSetting, setShareSetting] = useState<ShareSetting>(
    investorList.shareSetting,
  );
  const link = buildInvestorListShareLink(shareSetting.slug);
  const [modalConfig, setModalConfig] = useState({
    isModalOpen: false,
  });

  function closeModal() {
    setModalConfig({ isModalOpen: false });
  }

  async function updateSetting(disabledOn: Date) {
    try {
      const list = await API.put(
        StartupUpdateInvestorListShareSetting.buildEndpoint(investorList._id),
        {
          shareSetting: {
            ...shareSetting,
            disabledOn,
          },
        },
      );
      setShareSetting(list.shareSetting);
      onShareSettingUpdated(list);
    } catch (error) {
      Logger.error(error);
    }
  }

  async function resetLink() {
    try {
      const list = await API.put(
        StartupUpdateInvestorListResetShareLink.buildEndpoint(investorList._id),
        {},
      );
      setShareSetting(list.shareSetting);
      onShareSettingUpdated(list);
      closeModal();
    } catch (error) {
      Logger.error(error);
    }
  }

  useEffect(() => {
    setShareSetting(investorList.shareSetting);
  }, [investorList]);

  return (
    <main className="bg-white p-4 sm:w-screen sm:max-w-3xl sm:p-7">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <h3
          className="text-lg font-medium leading-6 text-gray-900"
          id="modal-headline"
        >
          Share &quot;{investorList.name}&quot; list
        </h3>
        <p className="mt-1 text-base">
          Share this list with your network to ask for introductions to the
          investors
        </p>
      </div>
      <section className="mt-8 space-y-6">
        <ToggleSwitch
          showToggleIcon
          toggleOn={async () => {
            await updateSetting(MIN_DATE);
          }}
          toggleOff={async () => {
            await updateSetting(new Date());
          }}
          name="enableSharing"
          label="Shareable Link"
          initialState={isMinDate(shareSetting.disabledOn)}
        />
        <DebugRender>
          <pre className="text-2xs">
            {JSON.stringify(shareSetting, null, 2)}
          </pre>
        </DebugRender>
        {isMinDate(shareSetting.disabledOn) && (
          <section className="items-center sm:flex sm:flex-row">
            <CopyLinkInput
              link={link}
              qrCodeConfig={{
                title: investorList.name,
                allowDownload: true,
              }}
            />

            <div className="my-4 flex flex-1 flex-col sm:my-0 sm:ml-4 sm:shrink-0 sm:items-end">
              <button
                onClick={() => setModalConfig({ isModalOpen: true })}
                type="button"
                className="app-button--red justify-center truncate"
              >
                Reset Link
              </button>
            </div>
          </section>
        )}

        <p className="text-base text-gray-600">
          <PrivacySettingAlertContent shareSetting={shareSetting} />
        </p>
      </section>

      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => closeModal()}>
        <SimpleDialog
          onCancel={() => closeModal()}
          onPrimaryAction={resetLink}
          title="Confirm Link Reset?"
          text="Any link you have previously sent will become inactive"
          primaryAction="Confirm"
          color="red"
        />
      </ModalWrapper>
    </main>
  );
}

export default ShareInvestorListDialog;
