import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import ToggleSwitchInput from '../../inputs/ToggleSwitchInput';
import BooleanSelectInput from '../../inputs/BooleanSelectInput';
import { useLocation } from 'react-router-dom';
import { COMPLETE_PROFILE_ROUTE } from '/src/constants/Routes';

function FieldWrapper(): JSX.Element {
  const { pathname } = useLocation();

  const isCompleteProfile = pathname.includes(COMPLETE_PROFILE_ROUTE);

  if (isCompleteProfile) {
    return (
      <BooleanSelectInput
        label="Are you open to cold inbound from founders?"
        secondaryLabel="This allows founders to see your preferred contact methods."
        name={InvestorFieldNames.IsOpenToColdInbound}
      />
    );
  }

  return (
    <div className="mt-6 mb-2">
      <Field
        component={ToggleSwitchInput}
        label="Are you open to cold inbound from founders?"
        secondaryLabel="This allows founders to see your preferred contact methods."
        name={InvestorFieldNames.IsOpenToColdInbound}
        showToggleIcon
      />
    </div>
  );
}

const IsOpenToColdInboundField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.IsOpenToColdInbound]: yup.bool().required('Required'),
  }),
};

export default IsOpenToColdInboundField;
