export enum SortOrder {
  Asc = 1,
  Desc = -1,
}

export class Sortable<T, V> {
  items: T[];

  reducerFunc: (item: T) => V;

  constructor(items: T[], reducerFunc: (item: T) => V) {
    this.items = items;
    this.reducerFunc = reducerFunc;
  }

  /**
   * Sorts the items for this sortable collection
   * by a field and sort order
   * @param field keyof V
   * @param order Sort order
   * @returns Sorted items array
   * @note This does not use memoization, and it may not work well at scale
   */
  sort(field: keyof V, order: SortOrder): T[] {
    // USE THIS LOG TO CHECK HOW OFTEN YOU ARE SORTING THE ITEMS
    // We should cache the sorted items to not repeat the same sorting over and over
    // Logger.info('Sorting', field, order);
    return this.items.sort((a, b) => {
      const reducedA = this.reducerFunc(a);
      const reducedB = this.reducerFunc(b);

      if (reducedA[field] < reducedB[field]) {
        return -1 * order;
      }
      if (reducedA[field] > reducedB[field]) {
        return 1 * order;
      }
      // If reduced values are the same, do not change the order
      return 0;
    });
  }
}
