/* eslint-disable no-console */
import { EnvironmentTypes } from '/../libs/shared-types/src/constants/EnvironmentTypes';

export default class Logger {
  static info(...args: any[]): void {
    if (process.env.REACT_APP_ENV !== EnvironmentTypes.Production) {
      console.log(...args);
    }
  }

  static warn(...args: any[]): void {
    console.warn(...args);
  }

  static error(...args: any[]): void {
    console.error(...args);
  }
}
