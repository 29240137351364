import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import AngelListField from './Fields/AngelListField';
import LinkedInField from './Fields/LinkedInField';
import OtherUrlField from './Fields/OtherUrlField';
import TwitterField from './Fields/TwitterField';
import WebsiteField from './Fields/WebsiteField';
import CheckRangeMaxField from './Fields/CheckRangeMaxField';
import CheckRangeMinField from './Fields/CheckRangeMinField';
import ExpFunctionalField from './Fields/ExpFunctional';
import ExpIndustryField from './Fields/ExpIndustry';
import FirmField from './Fields/FirmField';
import IntroBioField from './Fields/IntroBioField';
import InvBusinessModelsField from './Fields/InvBusinessModelsField';
import InvGeographiesField from './Fields/InvGeographiesField';
import InvImpactsField from './Fields/InvImpactsField';
import InvIndustriesField from './Fields/InvIndustriesField';
import InvStagesField from './Fields/InvStagesField';
import InvTypesField from './Fields/InvTypesField';
import IsAccreditedField from './Fields/IsAccreditedField';
import LeadsRoundsField from './Fields/LeadsRoundsField';
import LocationField from './Fields/LocationField';
import PhoneField from './Fields/PhoneField';
import ProfilePicField from './Fields/ProfilePicField';
import PronounsField from './Fields/PronounsField';
import RoleField from './Fields/RoleField';
import TaxStateField from './Fields/TaxStateField';
import TypeField from './Fields/TypeField';
import PreferenceContactField from './Fields/PreferenceContactField';
import PreferenceFollowUpField from './Fields/PreferenceFollowUpField';
import TakesBoardSeatsField from './Fields/TakesBoardSeats';
import InvProductStageField from './Fields/InvProductStageField';
import YearlyAvgInvCountField from './Fields/YearlyAvgInvCountField';
import SubstackField from './Fields/SubstackField';
import MediumField from './Fields/MediumField';
import UniversityAffiliationsField from './Fields/UniversityAffiliationsField';
import NationalitiesField from './Fields/NationalitiesField';
import HighestEducationLevelField from './Fields/HighestEducationLevelField';
import ExpInvestingYearsField from '../StartupForm/Fields/ExpInvestingYearsField';
import ProfessionalAccreditationsField from './Fields/ProfessionalAccreditationsField';
import IsIncludedInInvestorDbForStartupsField from './Fields/IsIncludedInInvestorDbForStartupsField';
import IsOpenToColdInboundField from './Fields/IsOpenToColdInboundField';
import EmailField from './Fields/EmailField';
import InvStagesFollowOnField from './Fields/InvStagesFollowOnField';
import InitialOwnershipTargetMinField from './Fields/InitialOwnershipTargetMinField';
import InvProductCategoriesField from './Fields/InvProductCategoriesField';
import HasInitialOwnershipTargetField from './Fields/HasInitialOwnershipTargetField';
import InitialOwnershipTargetMaxField from './Fields/InitialOwnershipTargetMaxField';
import AntiInvIndustriesField from './Fields/AntiInvIndustriesField';

const INVESTOR_FIELD_MAPPING: { [key: string]: FormField } = {
  [InvestorFieldNames.AngelList]: AngelListField,
  [InvestorFieldNames.AntiInvIndustries]: AntiInvIndustriesField,
  [InvestorFieldNames.CheckRangeMax]: CheckRangeMaxField,
  [InvestorFieldNames.CheckRangeMin]: CheckRangeMinField,
  [InvestorFieldNames.Email]: EmailField,
  [InvestorFieldNames.ExpFunctional]: ExpFunctionalField,
  [InvestorFieldNames.ExpIndustry]: ExpIndustryField,
  [InvestorFieldNames.ExpInvestingYears]: ExpInvestingYearsField,
  [InvestorFieldNames.Firm]: FirmField,
  [InvestorFieldNames.HasInitialOwnershipTarget]:
    HasInitialOwnershipTargetField,
  [InvestorFieldNames.HighestEducationLevel]: HighestEducationLevelField,
  [InvestorFieldNames.InitialOwnershipTargetMax]:
    InitialOwnershipTargetMaxField,
  [InvestorFieldNames.InitialOwnershipTargetMin]:
    InitialOwnershipTargetMinField,
  [InvestorFieldNames.IntroBio]: IntroBioField,
  [InvestorFieldNames.InvBusinessModels]: InvBusinessModelsField,
  [InvestorFieldNames.InvGeographies]: InvGeographiesField,
  [InvestorFieldNames.InvImpacts]: InvImpactsField,
  [InvestorFieldNames.InvIndustries]: InvIndustriesField,
  [InvestorFieldNames.InvProductCategories]: InvProductCategoriesField,
  [InvestorFieldNames.InvProductStage]: InvProductStageField,
  [InvestorFieldNames.InvStagesFollowOn]: InvStagesFollowOnField,
  [InvestorFieldNames.InvStages]: InvStagesField,
  [InvestorFieldNames.InvTypes]: InvTypesField,
  [InvestorFieldNames.IsAccredited]: IsAccreditedField,
  [InvestorFieldNames.IsIncludedInInvestorDbForStartups]:
    IsIncludedInInvestorDbForStartupsField,
  [InvestorFieldNames.IsOpenToColdInbound]: IsOpenToColdInboundField,
  [InvestorFieldNames.LeadsRounds]: LeadsRoundsField,
  [InvestorFieldNames.LinkedIn]: LinkedInField,
  [InvestorFieldNames.Location]: LocationField,
  [InvestorFieldNames.Medium]: MediumField,
  [InvestorFieldNames.Nationalities]: NationalitiesField,
  [InvestorFieldNames.OtherUrl]: OtherUrlField,
  [InvestorFieldNames.Phone]: PhoneField,
  [InvestorFieldNames.PreferenceContact]: PreferenceContactField,
  [InvestorFieldNames.PreferenceFollowUp]: PreferenceFollowUpField,
  [InvestorFieldNames.ProfessionalAccreditations]:
    ProfessionalAccreditationsField,
  [InvestorFieldNames.ProfilePic]: ProfilePicField,
  [InvestorFieldNames.Pronouns]: PronounsField,
  [InvestorFieldNames.Role]: RoleField,
  [InvestorFieldNames.Substack]: SubstackField,
  [InvestorFieldNames.TakesBoardSeats]: TakesBoardSeatsField,
  [InvestorFieldNames.TaxState]: TaxStateField,
  [InvestorFieldNames.Twitter]: TwitterField,
  [InvestorFieldNames.Type]: TypeField,
  [InvestorFieldNames.UniversityAffiliations]: UniversityAffiliationsField,
  [InvestorFieldNames.Website]: WebsiteField,
  [InvestorFieldNames.YearlyAvgInvCount]: YearlyAvgInvCountField,
};

export default INVESTOR_FIELD_MAPPING;
