import React from 'react';
import { cn } from '/src/util/cn';

export type TagCellProps =
  | {
      type: 'range';
      tag?: number;
      defaultClassName?: string;
      tagStyles?: { limit: number; className: string }[];
      isPercentage: boolean;
      compareWith?: undefined;
    }
  | {
      type: 'comparison';
      tag?: string | number;
      defaultClassName?: string;
      compareWith?: string | number;
      tagStyles?: Record<string, string>;
      isPercentage?: boolean;
    };

function TagCell({
  tag,
  tagStyles,
  type,
  compareWith,
  defaultClassName,
  isPercentage = false,
}: TagCellProps) {
  const baseClassName = 'app-tag bg-slate-100 text-slate-800';
  const customTag = isPercentage ? `${tag}%` : tag;
  let customClassName = '';

  // range mode
  if (type === 'range') {
    const rangeStyles = [...(tagStyles || [])];
    const sortedAsc = rangeStyles.sort((a, b) => b.limit - a.limit);

    // Get classname based in asc sorting
    for (const item of sortedAsc) {
      customClassName = item.className;
      if (tag && tag >= item.limit) {
        break;
      }
    }
  }

  // comparison mode
  else if (tagStyles && tag) {
    customClassName =
      (compareWith && tagStyles[compareWith]
        ? tagStyles[compareWith]
        : tagStyles[tag]) || '';
  }

  if (tag === undefined || tag === null) {
    return (
      <span className={cn('app-table-row-text', defaultClassName)}>-</span>
    );
  }

  return (
    <span className={cn(baseClassName, defaultClassName, customClassName)}>
      {customTag}
    </span>
  );
}

export default TagCell;
