import { FormikValues } from 'formik';
import { RoundStages } from '/../libs/shared-types/src/constants/RoundStages';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';
import { shouldHaveValuationCap } from '/../libs/shared-types/src/extensions/RoundExtensions';
import { Round } from '/../libs/shared-types/src/types/model/Round';

/**
 * Determines whether a round input should be displayed based on the values of
 * the other inputs. Note that it defaults to displaying the input if it
 * doesn't know.
 * @param field 'name' of input field
 * @param values Formik values object
 * @returns True if the input should be displayed, false otherwise
 */
function isInputShown(field: string, values: FormikValues): boolean {
  // variables here are named ambiguously because there is no
  // clear option for naming them. They just represent arbitrary combinations
  // of input values
  switch (field) {
    case RoundFieldNames.ValuationCap:
    case RoundFieldNames.ValuationCapType:
      return shouldHaveValuationCap(values as Round);

    case RoundFieldNames.HasLeadInvestor:
    case RoundFieldNames.HasTermSheet:
    case 'percentSold':
    case 'revenueMultiple':
      return (
        values[RoundFieldNames.RoundType] &&
        values[RoundFieldNames.RoundType] !== RoundTypes.ToBeDetermined
      );

    case RoundFieldNames.Discount:
      return (
        values.roundType === RoundTypes.ConvertibleNote ||
        values.roundType === RoundTypes.Safe ||
        values.roundType === RoundTypes.CrowdSafe ||
        values.roundType === RoundTypes.Asa
      );

    case RoundFieldNames.ConversionTerm:
    case RoundFieldNames.Interest:
      return values[RoundFieldNames.RoundType] === RoundTypes.ConvertibleNote;

    case RoundFieldNames.AntidilutionRights:
    case RoundFieldNames.HasRightsDragAlong:
    case RoundFieldNames.HasRightsPayToPlay:
    case RoundFieldNames.LiquidationPreference:
      return (
        values[RoundFieldNames.RoundType] === RoundTypes.PricedPreferred &&
        values[RoundFieldNames.HasTermSheet]
      );

    case RoundFieldNames.HasRightsMfn:
      return (
        values[RoundFieldNames.RoundType] === RoundTypes.ConvertibleNote ||
        values[RoundFieldNames.RoundType] === RoundTypes.Safe
      );

    case RoundFieldNames.SafeType:
      return values[RoundFieldNames.RoundType] === RoundTypes.Safe;

    case RoundFieldNames.LiquidationParticipation:
      return (
        values[RoundFieldNames.RoundType] === RoundTypes.PricedPreferred &&
        values[RoundFieldNames.HasTermSheet] &&
        values[RoundFieldNames.LiquidationPreference] &&
        Number(values[RoundFieldNames.LiquidationPreference]) > 0
      );

    case RoundFieldNames.Prepayment:
      return (
        values[RoundFieldNames.RoundType] === RoundTypes.ConvertibleNote &&
        values[RoundFieldNames.HasTermSheet]
      );

    case RoundFieldNames.PricePerShare:
      return (
        (values[RoundFieldNames.RoundType] === RoundTypes.PricedPreferred ||
          values[RoundFieldNames.RoundType] === RoundTypes.PricedCommon) &&
        values[RoundFieldNames.HasTermSheet]
      );

    case RoundFieldNames.BridgeNumber:
      return values[RoundFieldNames.IsBridge];

    case RoundFieldNames.IsBridge:
    case RoundFieldNames.EstimatedCloseDate:
    case RoundFieldNames.OpenToInvestorTypes:
    case RoundFieldNames.RaisedAmount:
    case RoundFieldNames.RaiseTarget:
    case RoundFieldNames.RoundStage:
    case RoundFieldNames.RoundType:
    case RoundFieldNames.RunwayTarget:
      return true;
    default:
      return true;
  }
}

export default isInputShown;
