import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StartupAccessManagerViewSessionView } from '/../libs/shared-types/src/types/view/StartupAccessManagerView';
import {
  formatImageAddress,
  joinClassNames,
  splitOnWhiteSpace,
} from '/src/util/formatting/strings';
import {
  formatDate,
  formatDateTime,
  howLongBetween,
} from '/src/util/formatting/dates';
import BackendPaginatedTable, {
  PaginatedTableProps,
  isRowBlurred,
} from '/src/components/table/BackendPaginatedTable';
import CopyTextButton from '/src/components/CopyTextButton';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import TimeDurationString from '/src/components/utility/TimeDurationString';
import { isMinDate } from '/src/util/time';
import RoundImageWithPlaceholder from '/src/components/RoundImageWithPlaceholder';
import {
  ArrowDownTrayIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  UserPlusIcon,
} from '@heroicons/react/20/solid';
import { VerificationStatus } from '/../libs/shared-types/src/constants/VerificationStatus';
import { ViewerTypes } from '/../libs/shared-types/src/constants/ViewerTypes';
import { getColor } from '/src/util/colorLookup';
import DeviceTypeIcon from '/src/components/utility/RenderIconFromValue/DeviceTypeIcon';
import BrowserTypeIcon from '/src/components/utility/RenderIconFromValue/BrowserIcon';
import DeviceOsIcon from '/src/components/utility/RenderIconFromValue/DeviceOsIcon';
import DebugRender from '/src/components/utility/DebugRender';
import { INVESTOR_DETAIL } from '/src/constants/Routes';
import { DataRoomViewStatusMap } from '/../libs/shared-types/src/constants/DataRoom/DataRoomViewStatus';
import Tooltip from '/src/components/utility/Tooltip';
import ImportInvestorDialog from '../InvestorDatabase/ImportInvestorDialog';
import ModalWrapper from '/src/components/notifications/ModalWrapper';

function DeckViewed({
  viewSession,
}: {
  viewSession: StartupAccessManagerViewSessionView;
}) {
  return (
    <>
      <DebugRender>
        <span className="flex items-center text-2xs text-gray-500">
          <TimeDurationString
            timeMs={viewSession.totalMillisecondsSpentOnDeck}
          />
          <ArrowDownTrayIcon className="ml-2 mr-0.5 h-3 w-3" />
          <span>{viewSession.totalDeckDownloadCount}</span>
        </span>
      </DebugRender>
      {!viewSession.deckViewed.isViewed &&
        !viewSession.deckViewed.isDownloaded && (
          <span
            title="The viewer has not viewed nor downloaded your deck during this session"
            className="rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800"
          >
            Not Viewed
          </span>
        )}
      {viewSession.deckViewed.isViewed &&
        !viewSession.deckViewed.isDownloaded && (
          <span
            title="The viewer has opened your deck during this session"
            className="rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
          >
            Viewed
          </span>
        )}
      {!viewSession.deckViewed.isViewed &&
        viewSession.deckViewed.isDownloaded && (
          <span
            title="The viewer has downloaded your deck during this session"
            className="rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
          >
            Downloaded
          </span>
        )}
      {viewSession.deckViewed.isViewed &&
        viewSession.deckViewed.isDownloaded && (
          <span
            title="The viewer has viewed and downloaded your deck during this session"
            className="rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
          >
            Viewed & Downloaded
          </span>
        )}
      <span
        className="app-table-row-text--light ml-2 mt-0 flex"
        title={`Deck version ${viewSession.deckViewed.version.major}.${viewSession.deckViewed.version.minor}`}
      >
        V {viewSession.deckViewed.version.major}.
        {viewSession.deckViewed.version.minor}
        {viewSession.deckViewed.isLatest && (
          <div className="ml-1">(Latest)</div>
        )}
      </span>
    </>
  );
}

function DataRoomViewStatus({
  viewSession,
}: {
  viewSession: StartupAccessManagerViewSessionView;
}) {
  const viewStatus = DataRoomViewStatusMap.get(viewSession.dataRoomViewStatus);

  if (!viewStatus) {
    return <span>{viewSession.dataRoomViewStatus}</span>;
  }

  return (
    <span
      className={joinClassNames(
        'rounded-full px-2.5 py-0.5 text-xs font-medium',
        'inline-flex',
        viewStatus.color,
      )}
    >
      {viewSession.dataRoomViewStatus}
      <Tooltip tooltipText={viewStatus.tooltip} />
    </span>
  );
}

interface AccessManagerViewSessionsTableRowProps {
  viewSession: StartupAccessManagerViewSessionView;
  className?: string;
  isRowBlurred: boolean;
  onImportInvestor: (viewSession: StartupAccessManagerViewSessionView) => void;
}

function AccessManagerViewSessionsTableRow({
  viewSession,
  className = '',
  isRowBlurred,
  onImportInvestor,
}: AccessManagerViewSessionsTableRowProps): JSX.Element {
  const rowIsClickable = viewSession.investorId !== undefined;
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (rowIsClickable && !isRowBlurred) {
      navigate(`${INVESTOR_DETAIL}/${viewSession.investorId}`);
    }
  };

  let verificationStatusTooltip = '';

  if (viewSession.visitorVerificationStatus === VerificationStatus.Verified) {
    verificationStatusTooltip =
      'This view session was verified by the owner of the email address';
  }

  if (viewSession.visitorVerificationStatus === VerificationStatus.Fraudulent) {
    verificationStatusTooltip =
      'This view session was marked as fraudulent by the owner of the email address';
  }

  if (viewSession.viewerType === ViewerTypes.Authenticated) {
    const authenticatedTooltip =
      'This viewer has a Flowlie account and has verified their email adddress';
    verificationStatusTooltip =
      viewSession.role && viewSession.firm
        ? `${viewSession.role} at ${viewSession.firm}\n${authenticatedTooltip}`
        : authenticatedTooltip;
  }

  return (
    <tr
      onClick={handleOnClick}
      className={joinClassNames(
        'bg-white',
        rowIsClickable && !isRowBlurred
          ? 'cursor-pointer hover:bg-gray-100'
          : 'select-none',
        className,
      )}
    >
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            {viewSession.name === 'Anonymous' ? (
              <RoundImageWithPlaceholder
                containerStyles="h-10 w-10"
                text="?"
                textStyles="text-xl"
                imgAlt="Anonymous Viewer"
              />
            ) : (
              <UserAvatarWithInitials
                containerStyles="h-10 w-10"
                firstName={splitOnWhiteSpace(viewSession.name)[0]}
                lastName={splitOnWhiteSpace(viewSession.name)[1]}
                textStyles="text-xl"
                imgAlt="Viewer"
                imgSrc={formatImageAddress(viewSession.picKey)}
              />
            )}
          </div>
          <div className="ml-2">
            <DebugRender>
              <span className="text-2xs text-gray-500">
                sessionId: {viewSession.sessionId}
              </span>
            </DebugRender>
            <div
              title={verificationStatusTooltip}
              className="app-table-row-text--bold mb-0.5 flex flex-row"
            >
              <span className="mr-2">{viewSession.name}</span>
              {viewSession.visitorVerificationStatus ===
                VerificationStatus.Verified && (
                <ShieldCheckIcon className="h-5 w-5 text-green-600" />
              )}
              {viewSession.visitorVerificationStatus ===
                VerificationStatus.Fraudulent && (
                <ShieldExclamationIcon className="h-5 w-5 text-red-500" />
              )}
              {viewSession.investorId && viewSession.investorType && (
                <span
                  className={joinClassNames(
                    'inline-flex items-center rounded px-1.5 text-2xs font-medium leading-5',
                    getColor(viewSession.investorType).bgColor,
                    getColor(viewSession.investorType).textColor,
                  )}
                >
                  {viewSession.investorType}
                </span>
              )}
            </div>
            {viewSession.email && !isRowBlurred && (
              <div className="text-sm text-gray-500">
                <a
                  className="truncate hover:underline"
                  href={`mailto:${viewSession.email}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {viewSession.email}
                </a>
                <CopyTextButton
                  text={viewSession.email}
                  className="absolute ml-1"
                />
              </div>
            )}
          </div>
        </div>
      </td>
      <td className="app-table-row-item">
        <DebugRender>
          <span className="text-2xs text-gray-500">
            dealDetailType: {viewSession.dealDetailType}
          </span>
        </DebugRender>
        {isMinDate(viewSession.lastViewedOn) && (
          <div className="app-table-row-text">Never</div>
        )}
        {!isMinDate(viewSession.lastViewedOn) && (
          <>
            <div className="app-table-row-text">
              {howLongBetween(viewSession.lastViewedOn)}
            </div>
            <div className="app-table-row-text--light">
              {formatDate(viewSession.lastViewedOn)}
              {' at '}
              {formatDateTime(viewSession.lastViewedOn)}
            </div>
          </>
        )}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          <TimeDurationString
            timeMs={viewSession.totalMillisecondsSpentOnDeal}
          />
        </div>
      </td>
      <td className="app-table-row-item">
        <DeckViewed viewSession={viewSession} />
      </td>
      <td className="app-table-row-item">
        <DataRoomViewStatus viewSession={viewSession} />
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">{viewSession.location}</div>
        <div className="app-table-row-text--light flex items-center space-x-2">
          <DeviceTypeIcon
            className="h-5 w-5"
            deviceType={viewSession.deviceType}
          />
          <DeviceOsIcon className="h-4 w-4" deviceOs={viewSession.deviceOs} />
          <BrowserTypeIcon className="h-4 w-4" browser={viewSession.browser} />
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {viewSession.receivedFrom.name}
        </div>
        <div className="app-table-row-text--light">
          {viewSession.receivedFrom.firm ?? '-'}
        </div>
      </td>
      <td
        className="whitespace-nowrap px-3 py-3 text-right text-sm font-medium"
        role="gridcell"
      >
        {viewSession.viewerType === ViewerTypes.Visitor && (
          <button
            title="Import Investor"
            className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            onClick={() => onImportInvestor(viewSession)}
          >
            <UserPlusIcon className="h-5 w-5" />
          </button>
        )}
      </td>
    </tr>
  );
}

interface AccessManagerViewSessionsTableInterface extends PaginatedTableProps {
  viewSessionViews: StartupAccessManagerViewSessionView[];
}

function AccessManagerViewSessionsTable({
  viewSessionViews,
  refreshData,
  parentPage,
  parentTotalCount,
  parentSortOrder,
  parentSortedColumn,
  parentFilter,
  parentPerPage,
  headers,
  parentTotalPages,
  blurRowsAfterIndex,
}: AccessManagerViewSessionsTableInterface): JSX.Element {
  const [modalConfig, setModalConfig] = useState<{
    type: '' | 'importInvestor';
    isModalOpen: boolean;
    viewSession: StartupAccessManagerViewSessionView | undefined;
  }>({
    type: '',
    isModalOpen: false,
    viewSession: undefined,
  });

  function closeModal() {
    setModalConfig({ type: '', isModalOpen: false, viewSession: undefined });
  }

  function onImportInvestor(viewSession: StartupAccessManagerViewSessionView) {
    setModalConfig({ type: 'importInvestor', isModalOpen: true, viewSession });
  }

  return (
    <section>
      <BackendPaginatedTable
        headers={headers}
        refreshData={refreshData}
        parentPage={parentPage}
        parentTotalCount={parentTotalCount}
        parentSortOrder={parentSortOrder}
        parentSortedColumn={parentSortedColumn}
        parentTotalPages={parentTotalPages}
        parentFilter={parentFilter}
        parentPerPage={parentPerPage}
        rowComponents={viewSessionViews.map((data, index) => (
          <AccessManagerViewSessionsTableRow
            viewSession={data}
            key={data.sessionId}
            className={joinClassNames(
              isRowBlurred(parentPage, index, blurRowsAfterIndex)
                ? 'transform-gpu blur'
                : '',
            )}
            isRowBlurred={isRowBlurred(parentPage, index, blurRowsAfterIndex)}
            onImportInvestor={onImportInvestor}
          />
        ))}
      />

      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => closeModal()}>
        {modalConfig.type === 'importInvestor' && modalConfig.viewSession && (
          <ImportInvestorDialog
            investor={{
              email: modalConfig.viewSession.email ?? '',
              firstName: splitOnWhiteSpace(modalConfig.viewSession.name)[0],
              lastName: splitOnWhiteSpace(modalConfig.viewSession.name)[1],
            }}
            onCancel={() => closeModal()}
            onSave={closeModal}
          />
        )}
      </ModalWrapper>
    </section>
  );
}

export default AccessManagerViewSessionsTable;
