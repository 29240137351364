import React, { useEffect, useState } from 'react';
import PdfViewer2 from '/src/components/PdfViewer2';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import API from '/src/middleware/API';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import { getFileFromCache, saveFileToCache } from '/src/services/FileLoader';
import { getLatestDeck } from '/../libs/shared-types/src/extensions/DeckExtensions';
import { File as ReactPdfFile } from 'react-pdf/dist/cjs/shared/types';
import Logger from '/src/services/logger';
import { getDeckFetchUrl } from '/src/services/deck/getDeckFetchUrl';


interface DeckViewerProps {
  startup: PublicStartup;
  open: boolean;
  onClose: () => void;
}

function DeckViewer({ startup, open, onClose }: DeckViewerProps): JSX.Element {
  const [deck, setDeck] = useState<ReactPdfFile>();

  async function fetchFile() {
    const latestDeck = getLatestDeck(startup);
    const cachedFile = await getFileFromCache(latestDeck.key);
    if (cachedFile) {
      setDeck(cachedFile);
      Logger.info('Deck fetched from cache');
      return;
    }

    const url = getDeckFetchUrl(startup);
    if (url) {
      const deckSignedUrl = await API.get(url);
      Logger.info('Deck from signedUrl', deckSignedUrl);
      setDeck(deckSignedUrl);

      if (deckSignedUrl.url !== '') {
        const fileResponse = await API.getPdf(deckSignedUrl.url);
        const blob = await fileResponse.blob();
        saveFileToCache(latestDeck.key, blob);
      }
    }
  }

  useEffect(() => {
    fetchFile();
  }, []);

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <main className="w-screen max-w-7xl bg-white p-0 sm:min-h-[380px] sm:p-2">
        {!deck && <div>You do not have access to this deck</div>}
        {deck && <PdfViewer2 file={deck} />}
      </main>
    </ModalWrapper>
  );
}

export default DeckViewer;
