import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import {
  USER_COUNT_MAX,
  USER_COUNT_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import NumericInput from '../../inputs/NumericInput';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.HasUsers] ? (
    <NumericInput
      label="Total Registered Users"
      name={StartupFieldNames.UserCount}
    />
  ) : (
    <></>
  );
}

const UserCountField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.UserCount]: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .min(USER_COUNT_MIN, `Must be greater than or equal to ${USER_COUNT_MIN}`)
      .max(
        USER_COUNT_MAX,
        `Enter ${USER_COUNT_MAX} if you have more than ${USER_COUNT_MAX} users`
      ),
  }),
};

export default UserCountField;
