import * as yup from 'yup';
import { RECURRING_MONETARY_MAX } from '/../libs/shared-types/src/constants/NumericRanges';

type ValidationTypes =
  | 'USER_COUNT_MAX'
  | 'RECURRING_MONETARY_MAX'
  | 'SIMPLE_PERCENTAGE';

// This function provides a convenience utility, NOT an abstraction. The
// validation types it offers are based on shared behavior today. There's no
// fundamental reason that they must stay together in the future. You can
// extend this function for additional purposes. But if this function gets
// too complex, we're doing it wrong.
export const validateNumeric = (validation: ValidationTypes) => {
  let max: number | undefined;
  let errMessage: string | undefined;

  if (validation === 'RECURRING_MONETARY_MAX') {
    max = RECURRING_MONETARY_MAX;
    errMessage = `Enter ${RECURRING_MONETARY_MAX} if more`;
  } else if (validation === 'SIMPLE_PERCENTAGE') {
    max = 100;
    errMessage = 'Must be a value between 0 and 100';
  } else {
    throw Error('Unknown validation');
  }

  return yup
    .number()
    .typeError('Must be a number')
    .min(0, 'Must be greater than or equal to 0')
    .max(max!, errMessage);
};

// This is an abstraction over the yup validate method. It's meant to be
// used with Formik fields (field-level validation). For example,
// <Field component={FormikInput}
//        validate={(val) => validate(yup.number().positive(), val)}
// />
// Technically this doesn't need to be async. But I figured at some point
// we may need an async version (server side uniqueness check) and I can't
// think of a reason why we need to support both async and sync if we can
// just support async.
export const validate = async (
  schema: yup.AnySchema,
  value: any
): Promise<boolean | null | string> => {
  if (!value) {
    return null;
  }
  try {
    await schema.validate(value);
    return null;
  } catch (result: any) {
    return result.errors.join(';');
  }
};
