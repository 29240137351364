import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';

interface CsvDownloadButtonProps {
  handleDownload: () => void;
  tooltip: string;
  isDisabled: boolean;
}

export function CsvDownloadButton({
  handleDownload,
  tooltip,
  isDisabled,
}: CsvDownloadButtonProps) {
  return (
    <button
      title={tooltip}
      className="font-base mt-1 inline-flex items-center space-x-2 rounded-full border border-transparent bg-blue-600 px-3 py-1 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      onClick={handleDownload}
      disabled={isDisabled}
    >
      <ArrowDownTrayIcon className="h-4 w-4" />
      <span>Download CSV</span>
      {isDisabled && (
        <SubscriptionCTAPill
          id="cta_csv_download"
          tooltipText="Upgrade to Pro to unlock CSV Download"
        />
      )}
    </button>
  );
}
