import React, { useState } from 'react';
import DropdownButton from '../../DropdownButton';
import { Menu } from '@headlessui/react';
import {
  ArrowLongUpIcon,
  CurrencyDollarIcon,
  EllipsisVerticalIcon,
  PencilSquareIcon,
  QueueListIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { joinClassNames } from '/src/util/formatting/strings';
import {
  deleteFragmentInvestor,
  fetchActiveRound,
  fetchInvestorLists,
} from '/src/services/startupService';
import { InvestorIdentifier } from '../../../types/view/APIResponse';
import { useInvestorDatabaseContext } from '/src/routes/startup/InvestorDatabase/InvestorDatabaseContext';
import Toast from '../../notifications/Toast';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import { ERROR, SUCCESS } from '/src/constants/SuccessMessages';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import ModalWrapper from '../../notifications/ModalWrapper';
import ImportInvestorDialog from '/src/routes/startup/InvestorDatabase/ImportInvestorDialog';
import SimpleDialog from '../../notifications/SimpleDialog';
import { ImportedInvestorActionProps } from '/src/routes/startup/InvestorDatabase/ImportedInvestorMapper';
import { INVESTOR_ACTION } from '/src/routes/startup/InvestorDatabase/InvestorMapper';
import { InvestorFragmentView } from '../../../types/view/InvestorFragmentView';

export interface CellImportedInvestorActionProps {
  investor: InvestorFragmentView;
  // listId: string
  onAction: ({ type, payload }: ImportedInvestorActionProps) => void;
}

function CellImportedInvestorAction({
  investor,
  onAction,
}: CellImportedInvestorActionProps) {
  const { addInvestorToPipeline } = useInvestorDatabaseContext();
  const [investorToEdit, setInvestorToEdit] = useState<InvestorFragmentView>();
  const [modalConfig, setModalConfig] = useState<{
    type: '' | 'edit' | 'lists' | 'delete';
    isModalOpen: boolean;
  }>({
    type: '',
    isModalOpen: false,
  });
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();
  const [errorMessage, setErrorMessage] = useState('');

  const closeModal = () => {
    setModalConfig({ type: '', isModalOpen: false });
    setErrorMessage('');
  };

  const handleSaveInvestor = async () => {
    const { isSuccess } = await fetchInvestorLists();

    if (isSuccess) {
      onAction({ type: INVESTOR_ACTION.REFRESH });
      setToastConfig({
        isError: false,
        message: 'Investors data updated',
      });
    } else {
      setToastConfig({
        title: ERROR,
        message: 'Please refresh the page and try again.',
        isError: true,
      });
    }
    closeModal();
  };

  const handleDeleteInvestor = async () => {
    const { isSuccess, error } = await deleteFragmentInvestor(investor._id);

    if (isSuccess) {
      onAction({ type: INVESTOR_ACTION.REFRESH });
      setToastConfig({
        isError: false,
        message: 'Investor deleted',
      });

      closeModal();
    } else {
      setErrorMessage(error);
    }
  };

  const handleAddInvestorToPipeline = async ({
    investorDataType,
    investorId,
  }: InvestorIdentifier) => {
    const currentRound = await fetchActiveRound();
    if (!currentRound) {
      setToastConfig({
        title: 'You do not have an Investor Pipeline',
        message: 'Open a round to create an Investor Pipeline.',
        isError: true,
      });
      return;
    }
    await addInvestorToPipeline(investorId, investorDataType, undefined);
  };

  return (
    <div className="app-table-row-text">
      <DropdownButton
        label={'Manage Investor'}
        button={
          <Menu.Button
            className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            onClick={(e: any) => e.stopPropagation()}
          >
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        }
      >
        <Menu.Item>
          {({ active }) => (
            <button
              type="button"
              onClick={(e: any) => {
                e.stopPropagation();
                setInvestorToEdit(investor);
                setModalConfig({
                  type: 'edit',
                  isModalOpen: true,
                });
              }}
              className={joinClassNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex w-full items-center px-4 py-2 text-sm',
              )}
              title="Edit Investor"
            >
              <PencilSquareIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              Edit
            </button>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <button
              type="button"
              onClick={(e: any) => {
                e.stopPropagation();
                setInvestorToEdit(investor);
                setModalConfig({
                  type: 'delete',
                  isModalOpen: true,
                });
              }}
              className={joinClassNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex w-full items-center px-4 py-2 text-sm',
              )}
              title="Delete Investor"
            >
              <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              Delete
            </button>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <button
              type="button"
              onClick={(e: any) => {
                e.stopPropagation();
                handleAddInvestorToPipeline({
                  investorId: investor._id,
                  investorDataType: InvestorDataType.Fragment,
                });
              }}
              className={joinClassNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex w-full items-center px-4 py-2 text-sm',
              )}
              title="Add investor to Pipeline"
            >
              <CurrencyDollarIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              Add to Pipeline
            </button>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <button
              type="button"
              onClick={(e: any) => {
                e.stopPropagation();
                onAction({
                  type: INVESTOR_ACTION.ADD_TO_TARGET_LIST,
                  payload: investor,
                });
              }}
              className={joinClassNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex w-full items-center px-4 py-2 text-sm',
              )}
              title="Assign investor to Target Lists"
            >
              <div className="relative mr-3 text-gray-400 group-hover:text-gray-500">
                <QueueListIcon className="h-5 w-5" aria-hidden="true" />
                <ArrowLongUpIcon
                  className="absolute -bottom-1 -right-1 h-3.5 w-3.5 bg-white group-hover:bg-gray-100"
                  aria-hidden="true"
                />
              </div>
              Add to Target Lists
            </button>
          )}
        </Menu.Item>
      </DropdownButton>

      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.isError ? ERROR : SUCCESS}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => closeModal()}>
        {modalConfig.type === 'edit' && (
          <ImportInvestorDialog
            investor={investorToEdit}
            onCancel={() => closeModal()}
            onSave={handleSaveInvestor}
          />
        )}

        {modalConfig.type === 'delete' && investorToEdit && (
          <SimpleDialog
            onCancel={closeModal}
            onPrimaryAction={handleDeleteInvestor}
            title="Are you sure you want to delete this investor?"
            text="If you added it to your investor pipeline, you must first remove it from the pipeline."
            primaryAction="Delete Investor"
            color="red"
            errorMessage={errorMessage}
          />
        )}
      </ModalWrapper>
    </div>
  );
}

export default CellImportedInvestorAction;
