import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AggregateDealShareRequest } from '/../libs/shared-types/src/types/view/APIResponse';
import API from '/src/middleware/API';
import DealPreview from '/src/routes/common/PublicDeal/DealPreview';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import Logger from '/src/services/logger';
import { DealDetailTypes } from '/../libs/shared-types/src/constants/DealDetailTypes';
import { DealViewSessionUpdateType } from '/../libs/shared-types/src/types/model/DealViewSession';
import useTrackViewTime from '/src/hooks/useTrackViewTime';
import {
  createNewAuthenticatedDealViewSession,
  updateDealViewSession,
} from '/src/services/DealViewSession';
import IdlePrompt from '/src/components/utility/IdlePrompt';
import useIdleStatus from '/src/hooks/useIdleStatus';
import {
  InvestorRequestsDealPreview,
  SharedGetStartupDeckById,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { verifyDataRoomPassword } from '/src/services/DataRoom';
import { DataRoomAccessStatus } from '/../libs/shared-types/src/constants/DataRoom/DataRoomAccessStatus';
import { downloadPdf } from '/src/services/File';

interface DealPreviewContentProps {
  startup: PublicStartup;
  dealViewSessionId: string;
}

function DealPreviewContent({
  startup,
  dealViewSessionId,
}: DealPreviewContentProps): JSX.Element {
  const [isViewingDeck, setIsViewingDeck] = useState(false);
  const { activate, isIdle, isIdlePromptShown } = useIdleStatus();

  async function updateDealViewTime() {
    if (!dealViewSessionId || isIdle || isViewingDeck) {
      return;
    }

    await updateDealViewSession({
      dealViewSessionId,
      startupId: startup._id,
      updateType: DealViewSessionUpdateType.DealViewTime,
    });
  }

  async function updateDeckViewTime() {
    if (!dealViewSessionId || isIdle || !isViewingDeck) {
      return;
    }

    await updateDealViewSession({
      dealViewSessionId,
      startupId: startup._id,
      updateType: DealViewSessionUpdateType.DeckViewTime,
    });
  }

  async function onOpenDataRoomModal() {
    if (!dealViewSessionId) {
      return;
    }

    await updateDealViewSession({
      dealViewSessionId,
      startupId: startup._id,
      updateType: DealViewSessionUpdateType.DataRoomAccessStatus,
      dataRoomAccessStatus: DataRoomAccessStatus.PasswordRequested,
    });
  }

  async function onVerifyDataRoomPassword(password: string) {
    if (!dealViewSessionId) {
      return {
        isError: true,
        message: 'Deal view session not found',
      };
    }

    return await verifyDataRoomPassword(
      startup._id,
      password,
      dealViewSessionId,
    );
  }

  async function onViewDeck() {
    if (!dealViewSessionId) {
      return;
    }

    setIsViewingDeck(true);

    await updateDealViewSession({
      dealViewSessionId,
      startupId: startup._id,
      updateType: DealViewSessionUpdateType.DeckViewCount,
    });
  }

  async function onDownloadDeck() {
    if (!dealViewSessionId) {
      return;
    }

    const latestDeck = startup.decks[0];
    try {
      const deckUrlResponse = await API.get<{ url: string }>(
        SharedGetStartupDeckById.buildEndpoint(startup._id),
      );
      await downloadPdf(deckUrlResponse.url, latestDeck.fileName);

      await updateDealViewSession({
        dealViewSessionId,
        startupId: startup._id,
        updateType: DealViewSessionUpdateType.DeckDownloadCount,
      });
    } catch (error: any) {
      Logger.error(error);
    }
  }

  useTrackViewTime(updateDealViewTime);
  useTrackViewTime(updateDeckViewTime);

  return (
    <>
      <IdlePrompt open={isIdlePromptShown} onClose={activate} />
      <DealPreview
        partialStartup={startup}
        onOpenDataRoomModal={onOpenDataRoomModal}
        onVerifyDataRoomPassword={onVerifyDataRoomPassword}
        onViewDeck={onViewDeck}
        onDownloadDeck={onDownloadDeck}
        isViewingDeck={isViewingDeck}
        setIsViewingDeck={setIsViewingDeck}
      />
    </>
  );
}

/**
 * This displays a deal in app that the investor has requested access to,
 * but does not yet have access to
 * @returns The preview of a deal detail (not full access)
 */
function DealPreviewRoute(): JSX.Element {
  const { requestId } = useParams<{ requestId: string }>();
  const [aggregateDealShareRequest, setAggregateDealShareRequest] =
    useState<AggregateDealShareRequest>();
  const [isLoading, setIsLoading] = useState(true);
  const [dealViewSessionId, setDealViewSessionId] = useState<string>();

  async function createDealViewSession(startupId: string, requestId: string) {
    const newDealViewSession = await createNewAuthenticatedDealViewSession(
      DealDetailTypes.DealPreview,
      undefined,
      undefined,
      startupId,
      requestId,
    );
    setDealViewSessionId(newDealViewSession?.dealViewSession._id);
  }

  async function fetchDealByRequestId() {
    if (!requestId) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const data = await API.get<AggregateDealShareRequest>(
        InvestorRequestsDealPreview.buildEndpoint(requestId),
      );
      await createDealViewSession(data.startup._id, requestId);
      setAggregateDealShareRequest(data);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchDealByRequestId();
  }, []);

  if (isLoading) {
    return (
      <PageLoadingSpinner message="Loading deal preview&nbsp;...&nbsp;🦄" />
    );
  }

  return aggregateDealShareRequest && dealViewSessionId ? (
    <div className="bg-gray-100">
      <main className="min-h-screen w-full">
        <DealPreviewContent
          startup={aggregateDealShareRequest.startup}
          dealViewSessionId={dealViewSessionId}
        />
      </main>
    </div>
  ) : (
    <div className="min-h-screen bg-gray-100">
      <main className="py-10">
        <h3>
          Oops! This request either doesn&apos;t exist or you do not have access
          to it.
        </h3>
      </main>
    </div>
  );
}

export default DealPreviewRoute;
