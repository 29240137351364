export const MINUTE_MS = 60000;
export const HOUR_MS = MINUTE_MS * 60;
export const DAY_MS = HOUR_MS * 24;
export const WEEK_MS = DAY_MS * 7;
export const MONTH_MS = DAY_MS * 30.4167;
export const YEAR_MS = DAY_MS * 365;

export const MINUTE_S = MINUTE_MS / 1000;
export const HOUR_S = HOUR_MS / 1000;
export const DAY_S = DAY_MS / 1000;
export const WEEK_S = WEEK_MS / 1000;

export const GRACE_PERIOD = DAY_MS * 14;
export const CRON_INTERVAL = DAY_MS;

export const VIEW_TIME_UPDATE_INTERVAL_MS = 2000;
