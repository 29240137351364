import React from 'react';
import { PriorityLabel } from '../PrioritySelect';
import { StarRatingLabel } from '../StarRatingSelect';
import {
  RatingHistoryPerRound,
  PriorityHistoryPerRound,
} from '/../libs/shared-types/src/types/view/InvestorActivityView';
import { generateTag } from '/src/util/generateTagCloud';
import { sortDataByDate } from '/src/util/sorting';

interface DealDetailRatingPrioritySummaryCardProps {
  ratingHistoryPerRound: RatingHistoryPerRound[];
  priorityHistoryPerRound: PriorityHistoryPerRound[];
}

function DealDetailRatingPrioritySummaryCard({
  ratingHistoryPerRound,
  priorityHistoryPerRound,
}: DealDetailRatingPrioritySummaryCardProps) {
  function getLatestRatingAtRound(roundDisplayName: string): JSX.Element {
    const ratingsAtRound = ratingHistoryPerRound.find(
      (x) => x.roundDisplayName === roundDisplayName
    );

    if (!ratingsAtRound || ratingsAtRound.ratings.length === 0) {
      return <></>;
    }

    const latestRating = ratingsAtRound.ratings[0];
    return <StarRatingLabel rating={latestRating.rating} />;
  }

  function getLatestPriorityAtRound(roundDisplayName: string): JSX.Element {
    const prioritiesAtRound = priorityHistoryPerRound.find(
      (x) => x.roundDisplayName === roundDisplayName
    );

    if (!prioritiesAtRound || prioritiesAtRound.priorities.length === 0) {
      return <></>;
    }

    const latestPriority = prioritiesAtRound.priorities[0];
    return <PriorityLabel priority={latestPriority.priority} />;
  }

  // NOTE: We are considering only the priorityHistory for convenience
  // because it should have same length as ratingHistory
  const completeHistory = sortDataByDate(
    [...priorityHistoryPerRound],
    'roundOpenedOn'
  ).map((x) => x.roundDisplayName);

  return (
    <div className="rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6">
        <h2
          id="activity-summary-title"
          className="text-lg font-medium leading-6 text-gray-900"
        >
          Rating & Priority History
        </h2>
      </div>

      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="flow-root">
          <ul className="space-y-6">
            {completeHistory.map((roundDisplayName) => (
              <li
                className="relative rounded-xl border border-gray-200 py-4 px-6"
                key={roundDisplayName}
              >
                <div className="absolute -top-4 left-2">
                  {generateTag(roundDisplayName)}
                </div>
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Rating
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {getLatestRatingAtRound(roundDisplayName)}
                    </dd>
                  </div>

                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Priority
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {getLatestPriorityAtRound(roundDisplayName)}
                    </dd>
                  </div>
                </dl>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DealDetailRatingPrioritySummaryCard;
