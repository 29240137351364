import React, { useContext, useState } from 'react';
import { Route, useParams, Routes, Navigate } from 'react-router-dom';

import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { localStorageGetAuthenticatedAccountType } from '/src/middleware/LocalStorage';
import { InvestorDetailView } from '/../libs/shared-types/src/types/view/InvestorDetailView';
import { toDashCase } from '/src/util/formatting/strings';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import TabsHeader from '/src/components/tabs/TabsHeader';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import {
  SharedGetUnverifiedInvestorById,
  StartupAddInvestorToLists,
  StartupGetInvestorLists,
  StartupInvestorAccessActivity,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import InvestorDetailHeader from '../../investor/InvestorDetail/InvestorDetailHeader';
import InvestorDetailAboutCard from '../../investor/InvestorDetail/cards/InvestorDetailAboutCard';
import InvestorDetailContactCard from '../../investor/InvestorDetail/cards/InvestorDetailContactCard';
import InvestorDetailThesisCard from '../../investor/InvestorDetail/cards/InvestorDetailThesisCard';
import InvestorDetailValueAddCard from '../../investor/InvestorDetail/cards/InvestorDetailValueAddCard';
import {
  QueueListIcon,
  ArrowLongUpIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/20/solid';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import { InvestorList } from '/../libs/shared-types/src/types/model/InvestorList';
import AddInvestorToListsDialog from '../../investor/ActiveDealflow/dialogs/AddInvestorToListsDialog';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { useInvestorDatabaseContext } from '../../startup/InvestorDatabase/InvestorDatabaseContext';
import InvestorDetailFitScoreCard from '../../investor/InvestorDetail/cards/InvestorDetailFitScoreCard';
import InvestorActivityTab from '../../investor/InvestorDetail/tabs/InvestorActivityTab';
import { InvestorActivityTabView } from '../../../types/view/InvestorActivityTabView';

const tabs = [{ name: 'Profile' }, { name: 'Activity' }];

function InvestorDetailRoute(): JSX.Element {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [investor, setInvestor] = useState<InvestorDetailView>();
  const { addInvestorToPipeline } = useInvestorDatabaseContext();
  const { subscriptionTier } = useContext(AccountMetadataContext);

  const [aggregateInvestorActivity, setAggregateInvestorActivity] =
    useState<InvestorActivityTabView>();

  const canAccessFitScore = hasFeatureAccess(
    StartupPaidFeatures.InvestorFitScore,
    subscriptionTier,
  );

  const [investorLists, setInvestorLists] = useState<InvestorList[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  async function fetchInvestorLists() {
    try {
      const data = await API.get<InvestorList[]>(
        StartupGetInvestorLists.buildEndpoint(),
      );
      setInvestorLists(data);
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function updateListsWithInvestor(
    investorId: string,
    investorListIds: string[],
  ) {
    try {
      await API.put(StartupAddInvestorToLists.buildEndpoint(), {
        investorId: investorId,
        investorDataType: InvestorDataType.Unverified,
        investorListIds: investorListIds,
      });
      setIsModalOpen(false);
      fetchInvestorLists();
    } catch (error) {
      Logger.error(error);
    }
  }

  async function fetchData(isRefreshing = false) {
    if (id === undefined) {
      return;
    }

    setIsLoading(!isRefreshing);

    try {
      const investorData = await API.get<InvestorDetailView>(
        SharedGetUnverifiedInvestorById.buildEndpoint(id),
      );
      setInvestor(investorData);
      fetchInvestorLists();
      const investorActivityData: InvestorActivityTabView = await API.get(
        StartupInvestorAccessActivity.buildEndpoint(id, {
          investorType: investorData?.investorDataType,
        }),
      );
      setAggregateInvestorActivity(investorActivityData);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  // we listen for url changes and re-fetch data
  // if we get a new id
  React.useEffect(() => {
    fetchData();
  }, [id]);

  if (isLoading) {
    return <PageLoadingSpinner message="Loading a valued investor 🤝" />;
  }

  if (!investor) {
    return (
      <div className="min-h-screen bg-gray-100">
        <main className="py-10">
          {localStorageGetAuthenticatedAccountType() ===
            AccountTypes.Investor && (
            <h3>
              Oops! The investor either doesn&apos;t exist or you&apos;re not
              connected with them.
            </h3>
          )}
          {localStorageGetAuthenticatedAccountType() ===
            AccountTypes.Startup && (
            <h3>Oops! The investor you are looking for doesn&apos;t exist.</h3>
          )}
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <main className="pb-8">
        <header className="sticky top-0 z-10 bg-gray-100 sm:pt-6">
          <div className="relative mx-auto max-w-full lg:flex lg:items-center lg:justify-between lg:space-x-5">
            <InvestorDetailHeader
              hasFitScoreAccess={canAccessFitScore}
              investor={investor}
              fetchInvestor={fetchData}
            />

            <div className="flex space-x-3">
              <button
                className="app-button--neutral mt-3 lg:mt-0"
                onClick={() => setIsModalOpen(true)}
              >
                <div className="relative mr-2">
                  <QueueListIcon className="h-5 w-5" aria-hidden="true" />
                  <ArrowLongUpIcon
                    className="absolute -bottom-0.5 -right-1 h-3.5 w-3.5 bg-white"
                    aria-hidden="true"
                  />
                </div>
                Add to Target Lists
              </button>
              {investor.pipelineCurrentStage === undefined && (
                <button
                  className="app-button--primary mt-3 lg:mt-0"
                  onClick={async () => {
                    await addInvestorToPipeline(
                      investor._id,
                      InvestorDataType.Unverified,
                      undefined,
                    );
                    await fetchData(true);
                  }}
                >
                  <div className="relative mr-2">
                    <CurrencyDollarIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                  Add to Pipeline
                </button>
              )}
            </div>
          </div>

          <TabsHeader tabs={tabs} />
        </header>
        <div className="px-1">
          <>
            <Routes>
              {['', tabs[0].name].map((path) => (
                <Route
                  key={path}
                  path={toDashCase(path)}
                  element={
                    <main className="grid grid-cols-1 gap-4 lg:w-full lg:grid-cols-2">
                      <div className="flex flex-1 flex-col">
                        <div className="joyride-investorDetailThesis mt-4">
                          <InvestorDetailThesisCard investor={investor} />
                        </div>
                        <div className="mt-4">
                          <InvestorDetailValueAddCard investor={investor} />
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col space-y-4 pt-4">
                        <InvestorDetailFitScoreCard investor={investor} />
                        <InvestorDetailAboutCard investor={investor} />
                        <InvestorDetailContactCard
                          investor={investor}
                          isConnected={true}
                        />
                      </div>
                    </main>
                  }
                />
              ))}
              {aggregateInvestorActivity && investor?._id && (
                <Route
                  path={`${toDashCase(tabs[1].name)}/*`}
                  element={
                    <InvestorActivityTab
                      aggregateInvestorActivity={aggregateInvestorActivity}
                      investorId={investor._id}
                      investorName={
                        investor.firstName + ' ' + investor.lastName
                      }
                      investorDataType={investor.investorDataType}
                      fetchInvestorData={fetchData}
                    />
                  }
                />
              )}
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          </>
        </div>
      </main>

      <ModalWrapper open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddInvestorToListsDialog
          investorLists={investorLists}
          onSave={updateListsWithInvestor}
          investorId={investor._id}
          investorName={investor.firstName + ' ' + investor.lastName}
          onCancel={() => setIsModalOpen(false)}
        />
      </ModalWrapper>
    </div>
  );
}

export default InvestorDetailRoute;
