/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Debounces function calls to functions that are being called very frequently
 * to avoid overloading an API for example
 * @param func Function that calls to should be debounced
 * @param delayMs Delay time to call function (i.e. call function after "delayMs") ms
 * if no other new calls have been triggered
 */
export function debounce(func: any, delayMs: number) {
  let timer: any;
  return function (this: any, ...args: any) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delayMs);
  };
}
