import React, { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import {
  Bars2Icon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Priority } from '/../libs/shared-types/src/constants/Priority';

interface PriorityOption {
  priority: Priority;
  label: string;
  icon: JSX.Element;
}

export const PRIORITY_OPTIONS: PriorityOption[] = [
  {
    priority: Priority.None,
    label: Priority[Priority.None],
    icon: (
      <XMarkIcon
        key={Priority.None}
        className="h-4 w-4 text-gray-700"
        aria-hidden="true"
      />
    ),
  },
  {
    priority: Priority.Highest,
    label: Priority[Priority.Highest],
    icon: (
      <ChevronDoubleUpIcon
        key={Priority.Highest}
        className="h-4 w-4 text-red-700"
        aria-hidden="true"
      />
    ),
  },
  {
    priority: Priority.High,
    label: Priority[Priority.High],
    icon: (
      <ChevronUpIcon
        key={Priority.High}
        className="h-4 w-4 text-red-700"
        aria-hidden="true"
      />
    ),
  },
  {
    priority: Priority.Medium,
    label: Priority[Priority.Medium],
    icon: (
      <Bars2Icon
        key={Priority.Medium}
        className="h-4 w-4 text-orange-600"
        aria-hidden="true"
      />
    ),
  },
  {
    priority: Priority.Low,
    label: Priority[Priority.Low],
    icon: (
      <ChevronDownIcon
        key={Priority.Low}
        className="h-4 w-4 text-blue-700"
        aria-hidden="true"
      />
    ),
  },
  {
    priority: Priority.Lowest,
    label: Priority[Priority.Lowest],
    icon: (
      <ChevronDoubleDownIcon
        key={Priority.Lowest}
        className="h-4 w-4 text-blue-700"
        aria-hidden="true"
      />
    ),
  },
];

function getPriorityOption(value: Priority) {
  const priority = PRIORITY_OPTIONS.find((x) => x.priority === value);
  if (!priority) {
    throw Error('Priority not found');
  }
  return priority;
}

interface PriorityLabelProps {
  priority: Priority;
  isInput?: boolean;
}

export function PriorityLabel({
  priority,
  isInput = false,
}: PriorityLabelProps) {
  const priorityOption = getPriorityOption(priority);
  return (
    <div className="relative">
      {priorityOption.priority > 0 ? (
        <>
          <span className="ml-5 block truncate">{priorityOption.label}</span>
          <span className="absolute top-0 -left-2 flex items-center pl-2">
            {priorityOption.icon}
          </span>
        </>
      ) : (
        <span className="block truncate">
          {isInput ? 'Add Priority' : 'No Priority'}
        </span>
      )}
    </div>
  );
}

interface PrioritySelectProps {
  onSelect: (priority: Priority) => void;
  value: Priority;
  disabled?: boolean;
}

function PrioritySelect({
  onSelect,
  value,
  disabled = false,
}: PrioritySelectProps) {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      <div className="relative">
        <Listbox.Button
          onClick={(e: any) => e.stopPropagation()}
          className="relative mr-5 w-full cursor-default rounded-md px-2.5 py-1.5 text-left text-xs tracking-wider hover:bg-gray-200 focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 "
        >
          <PriorityLabel priority={selected} isInput />
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-32 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {PRIORITY_OPTIONS.map((option, index) => (
              <Listbox.Option
                key={index}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                  }`
                }
                value={option.priority}
                onClick={(e: any) => {
                  e.stopPropagation();
                  onSelect(option.priority);
                }}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate text-xs ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {option.label}
                    </span>
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      {option.icon}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export default PrioritySelect;
