import React, { useState } from 'react';
import {
  CurrencyDollarIcon,
  BuildingOfficeIcon,
  BanknotesIcon,
  UserGroupIcon,
  TrophyIcon,
  ChartBarIcon,
} from '@heroicons/react/20/solid';

import DateString from '/src/components/utility/DateString';
import { isMinDate } from '/src/util/time';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import { HighlightType } from '/../libs/shared-types/src/constants/HighlightType';
import { StartupOnePagerFeatures } from '/../libs/shared-types/src/constants/StartupOnePagerFeatures';
import { isRoundOpen } from '/src/util/rounds';

function TimelineIcon({ type }: { type: string }): JSX.Element {
  switch (type) {
    case HighlightType.Financial:
      return (
        <div>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-500 ring-8 ring-white">
            <BanknotesIcon className="h-5 w-8 text-white" />
          </span>
        </div>
      );
    case HighlightType.ProductAndMetrics:
      return (
        <div>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-500 ring-8 ring-white">
            <ChartBarIcon className="h-5 w-8 text-white" />
          </span>
        </div>
      );
    case HighlightType.PressAndAwards:
      return (
        <div>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-500 ring-8 ring-white">
            <TrophyIcon className="h-5 w-8 text-white" />
          </span>
        </div>
      );
    case HighlightType.Partnerships:
      return (
        <div>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-500 ring-8 ring-white">
            <UserGroupIcon className="h-5 w-8 text-white" />
          </span>
        </div>
      );
    case 'Raising':
      return (
        <div>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 ring-8 ring-white">
            <CurrencyDollarIcon className="h-5 w-8 text-white" />
          </span>
        </div>
      );
    case 'Raised':
      return (
        <div>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-green-500 ring-8 ring-white">
            <CurrencyDollarIcon className="h-5 w-8 text-white" />
          </span>
        </div>
      );
    case 'Founded':
      return (
        <div>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-500 ring-8 ring-white">
            <BuildingOfficeIcon className="h-5 w-8 text-white" />
          </span>
        </div>
      );
    default:
      return (
        <div>
          The only supported types are: &quot;Raising&quot;, &quot;Founded&quot;
          and &quot;Opened&quot;.
        </div>
      );
  }
}

interface Item {
  type: string;
  title: string;
  date: Date;
}

function DealDetailTimelineItem({ type, title, date }: Item) {
  return (
    <li>
      <div className="relative pb-8">
        {type !== 'Founded' && (
          <span
            className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        )}
        <div className="relative flex space-x-3">
          <TimelineIcon type={type} />
          <div className="flex min-w-0 flex-1 items-center justify-between space-x-4">
            <div>
              <p className="font-medium text-gray-900">{title}</p>
            </div>
            <div className="whitespace-nowrap text-right text-sm text-gray-500">
              {type !== 'Raising' && (
                <DateString date={date} tooltipPosition="top" />
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

function getTimelineItems(startup: PublicStartup): Item[] {
  const rounds = startup.roundHistory
    .filter(
      (round) =>
        // If the round is excluded from the one pager, do not show the open round in the timeline
        // aka Only show closed rounds
        !startup.flowLink.excludedFromOnePager.includes(
          StartupOnePagerFeatures.Round,
        ) || !isRoundOpen(round),
    )
    .map((round) => ({
      type: isRoundOpen(round) ? 'Raising' : 'Raised',
      title: `${isRoundOpen(round) ? 'Raising' : 'Raised'} ${
        round.roundDisplayName
      } round`,
      date: isRoundOpen(round) ? new Date() : round.closedOn,
    }));

  const highlights =
    startup.highlights?.map((highlight) => ({
      type: highlight.type,
      title: highlight.title,
      date: highlight.achievedOn,
    })) ?? [];

  const result: Item[] = [
    ...rounds,
    ...highlights,
    {
      type: 'Founded',
      title: 'Founded',
      date: startup.foundedOn,
    },
  ];

  return result.sort((a, b) => b.date.getTime() - a.date.getTime());
}

interface DealDetailTimelineProps {
  startup: PublicStartup;
}

function DealDetailTimeline({ startup }: DealDetailTimelineProps): JSX.Element {
  const [timelineItems] = useState(getTimelineItems(startup));

  return (
    <aside
      aria-labelledby="timeline-title"
      className="lg:col-span-1 lg:col-start-3"
    >
      <div className="rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="timeline-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Company History
          </h2>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="mt-2 flow-root">
            <ul className="-mb-8">
              {timelineItems.map((item) => (
                <DealDetailTimelineItem
                  key={item.date.toISOString() + item.title}
                  type={item.type}
                  title={item.title}
                  date={item.date}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default DealDetailTimeline;
