import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  DealSummaryView,
  PassedDealSummaryView,
} from '/../libs/shared-types/src/types/view/AggregatedDeals';
import { ACTIVE_DEALFLOW_ROUTE } from '/src/constants/Routes';
import { ComparablePassedDealSummaryView } from '/src/interfaces/Comparable';
import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import { SHARED_SUCCESS, SUCCESS } from '/src/constants/SuccessMessages';
import API from '/src/middleware/API';
import PageHeader from '/src/components/PageHeader';
import Logger from '/src/services/logger';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import PassedDealTableRow from './PassedDealTableRow';
import ShareDealDialog from '../ActiveDealflow/dialogs/ShareDealDialog';
import Toast from '/src/components/notifications/Toast';
import { TableHeader } from '/src/interfaces/TableHeader';
import SearchBar from '/src/components/inputs/SearchBar';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Alert from '/src/components/notifications/Alert';
import BackendPaginatedTable from '/src/components/table/BackendPaginatedTable';
import {
  PaginatedCollectionResponse,
  PaginatedRequestParams,
} from '/../libs/shared-types/src/types/view/APIResponse';
import usePaginatedUrlsParams from '/src/hooks/usePaginatedUrlParams';
import {
  InvestorDealflowPassed,
  InvestorUpdateDealStatus,
} from '/../libs/shared-types/src/constants/ApiRoutes';

const defaultSortKey = 'name' as keyof ComparablePassedDealSummaryView;

const headers: TableHeader<ComparablePassedDealSummaryView>[] = [
  { sortKey: defaultSortKey, element: <span>Company</span> },
  { sortKey: 'stage', element: <span>Current Stage</span> },
  { sortKey: 'industries', element: <span>Industries</span> },
  { sortKey: 'geography', element: <span>Geography</span> },
  { sortKey: 'round', element: <span>Round</span> },
  { sortKey: 'passedStage', element: <span>Passed Stage</span> },
  { sortKey: 'passedReasons', element: <span>Passed Reasons</span> },
  { element: <span className="sr-only">Actions</span> },
];

function PassedRoute(): JSX.Element {
  const [apiResponse, setApiResponse] =
    useState<
      PaginatedCollectionResponse<
        PassedDealSummaryView,
        ComparablePassedDealSummaryView
      >
    >();
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState<DealSummaryView>();
  const [shareFormModalOpen, setShareFormModalOpen] = useState(false);
  const [isSentAlertShown, setIsSentAlertShown] = useState(false);

  const toggleShareFormModal = () => setShareFormModalOpen(!shareFormModalOpen);
  const toggleShowSentAlert = () => setIsSentAlertShown(!isSentAlertShown);

  function showSentAlert() {
    setIsSentAlertShown(true);
    window.setTimeout(() => setIsSentAlertShown(false), 3000);
  }

  async function fetchPassedDeals(
    args: Partial<PaginatedRequestParams<ComparablePassedDealSummaryView>>
  ) {
    try {
      setIsSearchLoading(true);
      const requestParams = {
        sortKey: defaultSortKey,
        ...apiResponse,
        ...args,
      };

      const url = InvestorDealflowPassed.buildEndpoint(
        undefined,
        requestParams
      );

      const data = await API.get<
        PaginatedCollectionResponse<
          PassedDealSummaryView,
          ComparablePassedDealSummaryView
        >
      >(url);

      setApiResponse(data);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsSearchLoading(false);
    }
  }

  const { page, perPage, sortOrder, sortKey, filter } =
    usePaginatedUrlsParams();

  async function loadInitialPassedDeals() {
    try {
      setIsLoading(true);
      await fetchPassedDeals({
        page,
        perPage,
        sortKey: (sortKey ??
          defaultSortKey) as keyof ComparablePassedDealSummaryView,
        sortOrder,
        filter,
      });
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleFilter(filter: string) {
    try {
      await fetchPassedDeals({ filter, page: 1 });
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  function shareDeal(deal: DealSummaryView) {
    setSelectedDeal(deal);
    toggleShareFormModal();
  }

  async function moveToActive(id: string) {
    try {
      if (!apiResponse) {
        return;
      }

      await API.put(InvestorUpdateDealStatus.buildEndpoint(), {
        startupId: id,
        status: DealStatusTypes.Active,
      });

      setApiResponse({
        ...apiResponse,
        results: apiResponse.results.filter(
          (deal: PassedDealSummaryView) => deal.startupId !== id
        ),
      });
    } catch (error) {
      Logger.error(error);
    }
  }

  useEffect(() => {
    loadInitialPassedDeals();
  }, []);

  const emptyContent = (
    <p>
      Hmm... it looks like you have no passed deals. You can pass on deals that
      are in your&nbsp;
      <Link
        to={ACTIVE_DEALFLOW_ROUTE}
        className="hyperlink font-bold underline"
      >
        Active Dealflow
      </Link>
      .
    </p>
  );

  const content = (
    <div className="flex flex-col">
      <PageHeader
        title="Passed Deals"
        itemsCount={apiResponse?.totalCount}
        itemLabel="deal"
      />

      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {isLoading && (
            <PageLoadingSpinner message="Loading deals you passed on... 👎" />
          )}

          {apiResponse?.totalCount === 0 &&
            apiResponse.filter === '' &&
            !isLoading && (
              <Alert
                alertType="Info"
                color="blue"
                content={emptyContent}
                canDismiss={false}
                isShown
              />
            )}

          {!isLoading &&
            apiResponse &&
            (apiResponse.totalCount > 0 || apiResponse.filter !== '') && (
              <div className="mb-3 flex flex-row items-center">
                <SearchBar
                  isDebounce
                  placeholder="Search Passed Deals"
                  onQueryChange={handleFilter}
                  initialValue={apiResponse.filter}
                />
                {isSearchLoading && <LoadingSpinner color="blue" />}
              </div>
            )}

          {!isLoading &&
            apiResponse &&
            (apiResponse.results.length > 0 || apiResponse.filter !== '') && (
              <BackendPaginatedTable
                headers={headers}
                rowComponents={apiResponse.results.map((deal) => (
                  <PassedDealTableRow
                    key={deal.startupId}
                    deal={deal}
                    share={shareDeal}
                    moveToActive={moveToActive}
                  />
                ))}
                refreshData={fetchPassedDeals}
                parentPage={apiResponse.page}
                parentTotalCount={apiResponse.totalCount}
                parentSortOrder={apiResponse.sortOrder}
                parentSortedColumn={apiResponse.sortKey}
                parentFilter={apiResponse.filter}
                parentPerPage={apiResponse.perPage}
                parentTotalPages={apiResponse.totalPages}
              />
            )}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {content}
      <ModalWrapper
        open={shareFormModalOpen}
        onClose={() => setShareFormModalOpen(false)}
      >
        {selectedDeal && (
          <ShareDealDialog
            onClose={toggleShareFormModal}
            onSuccess={showSentAlert}
            deal={selectedDeal}
          />
        )}
      </ModalWrapper>
      <Toast
        isShown={isSentAlertShown}
        onClose={toggleShowSentAlert}
        title={SUCCESS}
        text={SHARED_SUCCESS}
      />
    </div>
  );
}

export default PassedRoute;
