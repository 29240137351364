import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { RoundStages } from '/../libs/shared-types/src/constants/RoundStages';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { enumToSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';

const InvStagesField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      isClearable
      isMulti
      label="Primary Investment Stages"
      name={InvestorFieldNames.InvStages}
      options={enumToSelectOptions(RoundStages)}
      placeholder="Select stages..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.InvStages]: yup
      .array()
      .of(yup.string())
      .min(1, 'At least one is required')
      .required('This field is required'),
  }),
};

export default InvStagesField;
