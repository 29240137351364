import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import BooleanSelectInput from '/src/components/inputs/BooleanSelectInput';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.HasRightsDragAlong, values) ? (
    <BooleanSelectInput
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      label="Drag-Along Rights"
      name={RoundFieldNames.HasRightsDragAlong}
      isClearable
      tooltip="A “Drag-Along” provision entitles the holders of preferred shares to force, or drag-along, all of the other investors and the founders to do a sale or liquidation of the company. This happens only if a majority of the holders of preferred shares decide to, regardless of how the other folks being dragged along feel about the deal."
    />
  ) : (
    <></>
  );
}

const HasRightsDragAlongField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.HasRightsDragAlong]: yup.bool().nullable(),
  }),
};

export default HasRightsDragAlongField;
