import { PaperAirplaneIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import React, { useState, useContext } from 'react';
import { Navigate, Route, Routes, Link } from 'react-router-dom';
import { PrivateStartup } from '/../libs/shared-types/src/types/view/startup/PrivateStartup';
import DealPreviewDetailHeader from '/src/routes/common/PublicDeal/DealPreviewDetailHeader';
import DealDetailContactCard from '/src/routes/investor/DealDetail/cards/DealDetailContactCard';
import DealDetailDeckCard from '/src/routes/investor/DealDetail/cards/DealDetailDeckCard';
import DealDetailFinancialCard from '/src/routes/investor/DealDetail/cards/DealDetailFinancialCard';
import DealDetailFoundersCard from '/src/routes/investor/DealDetail/cards/DealDetailFoundersCard';
import DealDetailGeneralCard from '/src/routes/investor/DealDetail/cards/DealDetailGeneralCard';
import DealDetailLegalCard from '/src/routes/investor/DealDetail/cards/DealDetailLegalCard';
import DealDetailTimeline from '/src/routes/investor/DealDetail/cards/DealDetailTimeline';
import DealDetailUserCard from '/src/routes/investor/DealDetail/cards/DealDetailUserCard';
import Alert from '/src/components/notifications/Alert';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { DealActionModals, ModalConfig } from '/src/interfaces/ModalConfig';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import { toDashCase } from '/src/util/formatting/strings';
import { NOT_FOUND_ROUTE, STARTUP_PROFILE_ROUTE } from '/src/constants/Routes';
import DealDetailRoundSummaryCard from '/src/routes/investor/DealDetail/cards/DealDetailRoundSummaryCard';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import getBoolean from '/src/util/converters/BooleanConverter';
import ShareProfileDialogContent from './ShareProfileDialogContent';
import DeckViewer from '../../investor/DealDetail/cards/DeckViewer';
import { getLatestDeck } from '/../libs/shared-types/src/extensions/DeckExtensions';
import { DeckVersionView } from '/../libs/shared-types/src/types/view/DeckVersionView';
import {
  StartupGet,
  StartupGetOnePagerPreview,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import DealDetailProductCard from '../../investor/DealDetail/cards/DealDetailProductCard';
import DealDetailDataRoomCard from '../../investor/DealDetail/cards/DealDetailDataRoomCard';
import { startupVerifyDataRoomPassword } from '/src/services/DataRoom';
import { downloadPdf } from '/src/services/File';

function StartupProfilePreview(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [isAlertShown, setIsAlertShown] = useState(
    !getBoolean(
      localStorage.getItem(
        LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING,
      ),
    ),
  );
  const [startup, setStartup] = useState<PrivateStartup>();
  const [isViewingDeck, setIsViewingDeck] = useState(false);
  const { joyrideActivationTourState, setJoyrideActivationTourState } =
    useContext(SharedStateContext);
  const [modalConfig, setModalConfig] = useState<ModalConfig<DealActionModals>>(
    {
      isOpen: false,
    },
  );
  const closeModal = () => setModalConfig({ isOpen: false });
  const openModal = (dialog: DealActionModals) =>
    setModalConfig({ isOpen: true, dialog });

  const tabs = [{ name: 'Overview' }];
  // Remember, in PrivateStartup the round history is sorted with latest at index 0
  const currentRound =
    startup && startup.roundHistory.length > 0
      ? startup.roundHistory[0]
      : undefined;

  function ModalContent(): JSX.Element {
    if (!startup) {
      return <></>;
    }
    switch (modalConfig.dialog) {
      case DealActionModals.Share:
        return <ShareProfileDialogContent startup={startup} />;
      default:
        return <></>;
    }
  }

  async function onVerifyDataRoomPassword(password: string) {
    return await startupVerifyDataRoomPassword(startup?._id ?? '', password);
  }

  function onViewDeck() {
    setIsViewingDeck(true);
  }

  async function onDownloadDeck(startup: PrivateStartup) {
    const latestDeck = getLatestDeck(startup) as DeckVersionView;
    await downloadPdf(latestDeck.signedUrl, latestDeck.fileName);
  }

  async function fetchStartup() {
    try {
      setIsLoading(true);
      let startupData = undefined;
      const isTourRunning = getBoolean(
        localStorage.getItem(
          LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING,
        ),
      );
      if (isTourRunning) {
        // If the onboarding tour is running, do not fetch the startup from the StartupGetOnePagerPreview endpoint,
        // othwerwise it will mark the task as complete
        startupData = await API.get<PrivateStartup>(StartupGet.buildEndpoint());
      } else {
        startupData = await API.get<PrivateStartup>(
          StartupGetOnePagerPreview.buildEndpoint(),
        );
      }
      setStartup(startupData);
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
      setJoyrideActivationTourState({
        ...joyrideActivationTourState,
        run: true,
      });
    }
  }

  React.useEffect(() => {
    fetchStartup();
  }, []);

  if (isLoading || !startup) {
    return <PageLoadingSpinner />;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      {isAlertShown && (
        <div className="sticky top-0 z-10 my-3 pt-2">
          <Alert
            alertType="Info"
            color="blue"
            content={
              <article>
                <p className="mb-2 text-base font-bold">
                  This is how your one-pager looks when someone clicks your
                  FlowLink
                </p>
                <p>
                  Remember to keep your one-pager, deck, and round information
                  up-to-date to communicate the latest changes with everyone who
                  has access to your company information.
                </p>
              </article>
            }
            isShown={isAlertShown}
            onClose={() => setIsAlertShown(false)}
          />
        </div>
      )}

      <main className="pb-8">
        <DealPreviewDetailHeader
          tabs={tabs}
          actionButton={
            <>
              <Link className="app-button--neutral" to={STARTUP_PROFILE_ROUTE}>
                <PencilSquareIcon className="mr-2 h-4 w-4" />
                Edit One-Pager
              </Link>
              <button
                type="button"
                className="app-button--primary"
                onClick={() => openModal(DealActionModals.Share)}
              >
                <PaperAirplaneIcon className="mr-2 h-4 w-4" />
                Send FlowLink
              </button>
            </>
          }
          startup={startup}
        />

        <section className="px-1">
          <Routes>
            {['', tabs[0].name].map((path) => (
              <Route
                key={path}
                path={toDashCase(path)}
                element={
                  <div className="mx-auto mt-8 grid max-w-full grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
                    <span className="lg:hidden">
                      <DealDetailDeckCard
                        startup={startup}
                        onDownloadDeck={() => onDownloadDeck(startup)}
                        onViewDeck={() => onViewDeck()}
                      />
                    </span>
                    <div className="joyride-startupProfilePreview space-y-6 lg:col-span-2 lg:col-start-1">
                      <DealDetailGeneralCard startup={startup} />
                      <DealDetailFoundersCard startup={startup} />
                      <DealDetailProductCard startup={startup} />
                      <DealDetailUserCard startup={startup} />
                      <DealDetailFinancialCard startup={startup} />
                      <DealDetailLegalCard startup={startup} />
                    </div>
                    <aside className="flex flex-col space-y-6">
                      <span className="hidden lg:block">
                        <DealDetailDeckCard
                          startup={startup}
                          onDownloadDeck={() => onDownloadDeck(startup)}
                          onViewDeck={() => onViewDeck()}
                        />
                      </span>
                      <DealDetailRoundSummaryCard
                        currentRound={currentRound}
                        startup={startup}
                      />
                      <DealDetailContactCard startup={startup} />
                      <DealDetailDataRoomCard
                        startup={startup}
                        onVerifyPassword={onVerifyDataRoomPassword}
                        onOpenModal={() => Promise.resolve()}
                      />
                      <DealDetailTimeline startup={startup} />
                    </aside>
                  </div>
                }
              />
            ))}

            <Route
              path="*"
              element={<Navigate to={NOT_FOUND_ROUTE} replace />}
            />
          </Routes>
        </section>

        <ModalWrapper open={modalConfig.isOpen} onClose={() => closeModal()}>
          <ModalContent />
        </ModalWrapper>

        <DeckViewer
          open={isViewingDeck}
          onClose={() => setIsViewingDeck(false)}
          startup={startup}
        />
      </main>
    </div>
  );
}

export default StartupProfilePreview;
