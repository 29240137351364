import jwt from 'jsonwebtoken';
import { AuthTokenWrapper } from '/../libs/shared-types/src/types/Tokens';

/**
 * Given a users JWT auth token, this function parses and returns
 * the metadata stored in the token.
 * Note that this function does NOT validate the legitimacy of the token,
 * that can only be done on the backend.
 */
export function parseFlowlieAuthToken(token: string) {
  return jwt.decode(token) as AuthTokenWrapper;
}
