import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';

export interface SubscriptionPrice {
  monthly: number;
  annually: number;
  annuallyTotal: number;
}

export interface SubscriptionOffer {
  discountRate: number;
  monthlyDiscountedPrice: number;
  monthlyPrice: number;
  annualDiscountedPrice: number;
  annualPrice: number;
}

export function getSubscriptionPrice(
  subscriptionTier: SubscriptionTiers,
): SubscriptionPrice {
  if (subscriptionTier === SubscriptionTiers.StartupPro) {
    return { monthly: 60, annually: 45, annuallyTotal: 540 };
  }

  if (subscriptionTier === SubscriptionTiers.StartupPremium) {
    return { monthly: 500, annually: 375, annuallyTotal: 4500 };
  }

  if (subscriptionTier === SubscriptionTiers.InvestorPro) {
    return { monthly: 69, annually: 55, annuallyTotal: 660 };
  }

  throw Error('Not a supported account type');
}

export function getEarlyBirdCouponDiscount(accountType: AccountTypes) {
  if (accountType === AccountTypes.Startup) {
    return 0;
  }

  if (accountType === AccountTypes.Investor) {
    return 0;
  }

  throw Error('Not a supported account type');
}

export function calculateSubscriptionOffer(
  accountType?: AccountTypes,
  subscriptionTier?: SubscriptionTiers,
): SubscriptionOffer | undefined {
  if (!accountType || !subscriptionTier) {
    return undefined;
  }

  const price = getSubscriptionPrice(subscriptionTier);
  const discount = getEarlyBirdCouponDiscount(accountType);
  const annualDiscountedPrice = price.annuallyTotal - discount;

  return {
    discountRate: Math.ceil((1 - price.annually / price.monthly) * 100),
    monthlyDiscountedPrice: price.annually,
    monthlyPrice: price.monthly,
    annualDiscountedPrice,
    annualPrice: price.annuallyTotal,
  };
}
