import React from 'react';
import { useNavigate } from 'react-router-dom';

import DateString from '/src/components/utility/DateString';
import PaginatedTable from '/src/components/table/PaginatedTable';
import { InvestorAccessDataSharedWithView } from '/../libs/shared-types/src/types/view/InvestorAccessDataSharedWithView';
import {
  formatImageAddress,
  splitOnWhiteSpace,
} from '/src/util/formatting/strings';
import { generateTag } from '/src/util/generateTagCloud';
import { isMinDate } from '/src/util/time';
import sharedTypeMap from '/src/util/shareType';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import { INVESTOR_DETAIL } from '/src/constants/Routes';

function DealDetailSharedWithTableRow({
  accountId,
  firm,
  name,
  picKey,
  role,
  sharedOn,
  currentStatus,
  userType,
  shareType,
  message,
}: InvestorAccessDataSharedWithView): JSX.Element {
  const rowIsClickable = true;
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (rowIsClickable) {
      navigate(`${INVESTOR_DETAIL}/${accountId}`);
    }
  };

  return (
    <tr
      onClick={handleOnClick}
      className={`bg-white ${rowIsClickable ? 'cursor-pointer hover:bg-gray-100' : ''}`}
    >
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <UserAvatarWithInitials
              containerStyles="h-10 w-10"
              firstName={splitOnWhiteSpace(name)[0]}
              lastName={splitOnWhiteSpace(name)[1]}
              textStyles="text-xl"
              imgAlt="Profile"
              imgSrc={formatImageAddress(picKey)}
            />
          </div>
          <div className="app-table-row-text--bold ml-2">{name}</div>
        </div>
      </td>
      <td className="whitespace-nowrap px-4 py-4">
        {generateTag(userType, true)}
      </td>
      <td className="app-table-row-item">
        {firm && role ? (
          <>
            <div className="app-table-row-text">{firm}</div>
            <div className="app-table-row-text--light">{role}</div>
          </>
        ) : (
          <div className="app-table-row-text">-</div>
        )}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">{message ?? ''}</div>
      </td>
      <td className="app-table-row-item">
        {!isMinDate(currentStatus.statusChangedOn) && (
          <div>
            <div className="app-table-row-text">
              {generateTag(currentStatus.status, true)}
            </div>
            <div className="app-table-row-text--light">
              Since&nbsp;
              <DateString date={currentStatus.statusChangedOn} />
            </div>
          </div>
        )}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {generateTag(sharedTypeMap(shareType))}
        </div>
        <div className="app-table-row-text--light">
          <DateString date={sharedOn} tooltipPosition="top" />
        </div>
      </td>
    </tr>
  );
}

interface DealDetailSharedWithTableProps {
  investorAccessData: InvestorAccessDataSharedWithView[];
}

function DealDetailSharedWithTable({
  investorAccessData,
}: DealDetailSharedWithTableProps): JSX.Element {
  const headerValues = [
    { element: <span>Name</span> },
    { element: <span>Type</span> },
    { element: <span>Firm & Role</span> },
    { element: <span>Message</span> },
    { element: <span>Status</span> },
    { element: <span>Via </span> },
  ];

  return (
    <main>
      <PaginatedTable
        headers={headerValues}
        rowComponents={investorAccessData.map(
          (data: InvestorAccessDataSharedWithView) => (
            <DealDetailSharedWithTableRow
              accountId={data.accountId}
              currentStatus={data.currentStatus}
              firm={data.firm}
              key={data.accountId}
              name={data.name}
              picKey={data.picKey}
              role={data.role}
              roundStage={data.roundStage}
              roundDisplayName={data.roundDisplayName}
              sharedOn={data.sharedOn}
              shareType={data.shareType}
              userType={data.userType}
              message={data.message}
            />
          ),
        )}
      />
    </main>
  );
}

export default DealDetailSharedWithTable;
