import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import websiteSchema from '/src/constants/validation/websiteSchema';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';

const CalendarLinkField: FormField = {
  fieldComponent: (
    <Field
      component={FormikInput}
      label="Calendar Link"
      secondaryLabel="Make it easy for investors to book a meeting with you by providing your calendar booking link."
      tooltip="The calendar link could be a link to your Calendly, Chili Piper, Doodle, Cal.com, Sprintful, HubSpot Meetings, or similar booking software."
      name={StartupFieldNames.CalendarLink}
      type="text"
    />
  ),
  validation: yup.object({
    [StartupFieldNames.CalendarLink]: websiteSchema(false),
  }),
};

export default CalendarLinkField;
