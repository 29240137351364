export enum AccountTypes {
  Investor = 'INVESTOR',
  Startup = 'STARTUP',
}

export enum PosthogAccountTypesEnum {
  Angel = 'ANGEL',
  FamilyOffice = 'FAMILY_OFFICE',
  Syndicate = 'SYNDICATE',
  Unconfirmed = 'UNCONFIRMED',
  VC = 'VC',
  CorporateVC = 'CORPORATE_VC',
  Visitor = 'VISITOR',
}

export type PosthogAccountTypes = AccountTypes | PosthogAccountTypesEnum;
