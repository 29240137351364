import React from 'react';
import { FieldProps } from 'formik';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import FormikDebugger from './FormikDebugger';
import FormikErrorMessage from './FormikErrorMessage';

const styledPhoneInput = React.forwardRef((props: any, ref: any) => (
  <input
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    className="block w-full rounded-md border-none sm:text-sm"
    ref={ref}
  />
));
styledPhoneInput.displayName = 'Styled Phone Input';

interface PhoneInputProps extends FieldProps {
  label: string;
  name: string;
  placeholder?: string;
  secondaryLabel?: string;
}

function FormikPhoneInput({
  field,
  form,
  label,
  meta,
  placeholder,
  secondaryLabel,
}: PhoneInputProps): JSX.Element {
  return (
    <section className="form-section">
      <label htmlFor={field.name} className="input-label">
        {label}
        {secondaryLabel && (
          <p className="text-xs text-gray-800">{secondaryLabel}</p>
        )}
        <div className="w-100 relative mt-1 block rounded-md border border-gray-300 bg-white pl-3 focus:border-blue-500 focus:ring-blue-500 sm:text-sm">
          <PhoneInput
            defaultCountry="US"
            initialValueFormat="national"
            inputComponent={styledPhoneInput}
            international
            onBlur={() => form.setFieldTouched(field.name, true)}
            onChange={(e) => form.setFieldValue(field.name, e)}
            placeholder={placeholder}
            value={field.value}
          />
        </div>
      </label>
      <FormikErrorMessage field={field} form={form} meta={meta} />
      <FormikDebugger field={field} form={form} meta={meta} />
    </section>
  );
}

export default FormikPhoneInput;
