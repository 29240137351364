import isInputShown from './InputShown';
import { Round } from '/../libs/shared-types/src/types/model/Round';
import { convertDatePickerInputToUtc } from '/src/util/formatting/dates';

function unsetHiddenFields(values: any) {
  const cleanRound: any = {};

  Object.keys(values).forEach((key) => {
    if (isInputShown(key, values) && values[key] !== '') {
      cleanRound[key] = values[key];

      const numberValue = Number(values[key]);
      if (!isNaN(numberValue)) {
        cleanRound[key] = numberValue;
      }
    }
  });

  return cleanRound;
}

export function buildRound(values: any): Round {
  const cleanRound = unsetHiddenFields(values);

  return {
    ...cleanRound,
    estimatedCloseDate: convertDatePickerInputToUtc(
      new Date(cleanRound.estimatedCloseDate)
    ),
    openToInvestorTypes:
      cleanRound.openToInvestorTypes?.length > 0
        ? cleanRound.openToInvestorTypes
        : undefined,
  };
}
