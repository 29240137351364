import React from 'react';

import { TooltipSelectOption } from '/../libs/shared-types/src/types/SelectOptions';
import { FormatOptionLabelMeta } from 'react-select';

interface FormatOptionWithTooltipLabelProps {
  option: TooltipSelectOption;
  formatOptionLabelMeta: FormatOptionLabelMeta<any>;
}

// This is used for react select
function FormatOptionWithTooltipLabel({
  option,
  formatOptionLabelMeta,
}: FormatOptionWithTooltipLabelProps): JSX.Element {
  return (
    <div className="flex items-center space-x-3">
      <div>{option.label}</div>
      {formatOptionLabelMeta.context === 'menu' && (
        <div
          title={option.tooltipText}
          className="flex h-3 w-3 items-center justify-center rounded-full bg-gray-700 p-2 text-xs text-white"
        >
          ?
        </div>
      )}
    </div>
  );
}

export default FormatOptionWithTooltipLabel;
