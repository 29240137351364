import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import { RoundStages } from '/../libs/shared-types/src/constants/RoundStages';
import CustomSelect from '/src/components/inputs/CustomSelect';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import { getLatestClosedRound } from '/src/util/rounds';
import DebugRender from '/src/components/utility/DebugRender';
import {
  enumToList,
  enumToSelectOptions,
} from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';

// Given the latestClosedRound it returns the options that should be disabled
function getDisabledOptions(
  latestClosedRoundStage: string,
  isRaisingBridge: boolean,
): string[] {
  // Convert options to string array
  const optionsValues = enumToList(RoundStages);

  // Get index of the stage
  let index = optionsValues.indexOf(latestClosedRoundStage);
  if (isRaisingBridge) {
    // If raising a bridge, allow to select the last concluded primary round
    index = index - 1;
  }

  // Get array of option values before or at the index
  // NOTE: This assumes that RoundStages enum is sorted
  const disabledOptions = optionsValues.slice(0, index + 1);
  return disabledOptions;
}

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  const roundHistory: RoundView[] = values[RoundFieldNames.RoundHistory];
  const latestClosedRound = getLatestClosedRound(roundHistory);

  return isInputShown(RoundFieldNames.RoundStage, values) ? (
    <div>
      <Field
        className="custom-select"
        closeMenuOnSelect
        isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
        component={CustomSelect}
        label={
          values[RoundFieldNames.IsBridge]
            ? 'What was the last primary round you raised?'
            : 'What round are you raising?'
        }
        name={RoundFieldNames.RoundStage}
        options={enumToSelectOptions(RoundStages)}
        placeholder="Select stage..."
        disabledOptions={
          latestClosedRound
            ? getDisabledOptions(
                latestClosedRound?.roundStage,
                values[RoundFieldNames.IsBridge],
              )
            : undefined
        }
      />
      <DebugRender className="space-y-2 px-4 text-2xs">
        <span title="Latest Closed Round">
          <pre>
            Latest Closed Round:{' '}
            {JSON.stringify(latestClosedRound?.roundStage, null, 2)}
          </pre>
          <pre>
            IsBridge: {JSON.stringify(latestClosedRound?.isBridge, null, 2)}
          </pre>
          <pre>
            BridgeNumber:{' '}
            {JSON.stringify(latestClosedRound?.bridgeNumber, null, 2)}
          </pre>
        </span>
      </DebugRender>
    </div>
  ) : (
    <></>
  );
}

const RoundStageField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.RoundStage]: yup
      .string()
      .typeError('Please provide a Stage')
      .when(RoundFieldNames.IsBridge, {
        is: (isBridge: boolean) => !isBridge,
        then: (schema) =>
          schema.test(
            'roundStageAlreadyClosed',
            'You have already closed a primary round as this stage',
            (
              selectedRoundStage,
              context: yup.TestContext<yup.AnyObject>,
            ): boolean => {
              const roundHistory = context.parent[RoundFieldNames.RoundHistory];
              const latestClosedRoundStage =
                getLatestClosedRound(roundHistory)?.roundStage;
              if (
                roundHistory &&
                latestClosedRoundStage &&
                latestClosedRoundStage === selectedRoundStage?.toString()
              ) {
                // Return false to indicate the test failed
                return false;
              }
              return true;
            },
          ),
      })
      .required('Required'),
  }),
};

export default RoundStageField;
