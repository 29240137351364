import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { LOGIN_ROUTE } from '/src/constants/Routes';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Logger from '/src/services/logger';
import ProgressStatus from '/../libs/shared-types/src/constants/ProgressStatusTypes';
import { AccountContext } from '../../../contexts/Accounts';

function VerifyEmailHandler(): JSX.Element {
  const { verifyEmail } = useContext(AccountContext);
  const navigate = useNavigate();
  const { codeParameter, userName } = useParams();

  const [verifyEmailStatus, setVerifyEmailStatus] = useState<ProgressStatus>(
    ProgressStatus.Unknown
  );

  useEffect(() => {
    if (codeParameter && userName) {
      setVerifyEmailStatus(ProgressStatus.InProgress);
      verifyEmail(userName, codeParameter)
        .then(() => setVerifyEmailStatus(ProgressStatus.Success))
        .catch((error: any) => {
          Logger.error(error);
          setVerifyEmailStatus(ProgressStatus.Failure);
        });
    }
  }, []);

  if (!codeParameter || !userName) {
    // We should not get here, but if we do just redirect to home
    navigate('/');
  }

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      {verifyEmailStatus === ProgressStatus.InProgress && (
        <>
          <h1 className="text-4xl font-bold">Verifying Your Email...</h1>
          <div className="py-4 text-2xl font-medium">
            <LoadingSpinner color="blue" size={8} />
          </div>
        </>
      )}
      {verifyEmailStatus === ProgressStatus.Success && (
        <>
          <h1 className="text-4xl font-bold">Email Verified</h1>
          <div className="mt-4">
            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-green-500 ring-8 ring-white">
              <CheckIcon className="h-5 w-8 text-white" />
            </span>
          </div>
          <p className="mt-4 text-xl">
            Complete your profile to get started now!
          </p>
          <button
            type="button"
            onClick={() => navigate(LOGIN_ROUTE)}
            className="button my-4"
          >
            Take Me to Log In
          </button>
        </>
      )}
      {verifyEmailStatus === ProgressStatus.Failure && (
        <>
          <h1 className="font-gray-800 text-4xl font-bold">
            Email Verification Failed
          </h1>
          <div className="mt-4">
            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-red-500 ring-8 ring-white">
              <XMarkIcon className="h-5 w-8 text-white" />
            </span>
          </div>
          <p className="mt-4 text-xl">Something went wrong...</p>
          <button
            type="button"
            onClick={() => navigate('/')}
            className="button my-4"
          >
            Take Me to Home Page
          </button>
        </>
      )}
    </div>
  );
}

export default VerifyEmailHandler;
