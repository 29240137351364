import React from 'react';
import { AggregateDealShareRequest } from '/../libs/shared-types/src/types/view/APIResponse';
import PaginatedTable from '/src/components/table/PaginatedTable';
import DealAccessRequestsReceivedTableRow from './DealAccessRequestsReceivedTableRow';
import Alert from '/src/components/notifications/Alert';
import ActivationTaskModal from '/src/constants/ActivationTaskMaps/ActivationModal';

interface DealAccessRequestsReceivedTableProps {
  requests: AggregateDealShareRequest[];
  onAcceptRequest: (requestId: string) => void;
  onRejectRequest: (requestId: string) => void;
  isLoading: boolean;
  isQuerying: boolean;
}

function DealAccessRequestsReceivedTable({
  requests,
  onAcceptRequest,
  onRejectRequest,
  isLoading,
  isQuerying,
}: DealAccessRequestsReceivedTableProps): JSX.Element {
  const headerValues = [
    { element: <span>Company</span> },
    { element: <span>Investor</span> },
    { element: <span>Type</span> },
    { element: <span>Firm & Role</span> },
    { element: <span>Requested</span> },
  ];

  if (isLoading) {
    return <></>;
  }

  if (isQuerying && requests.length === 0) {
    return <p>No results found</p>;
  }

  if (requests.length === 0) {
    return (
      <Alert
        color="blue"
        alertType="Info"
        content={
          <div>
            Hmm... it looks like no one has requested to access deals you
            shared.
            {/* NOTE: The following code is a bit of a hack... We should eventually create a generic dialog to render scribe hows */}
            <span className="hyperlink font-bold underline">
              <ActivationTaskModal
                name="Learn how to share deals with a DealShare Link"
                iframeURL="https://scribehow.com/embed/15-second_guide_to_DealShare_Links__lA4-fzLyQWuI6SJdYV11Iw"
                isCompleted={false}
              />
            </span>
          </div>
        }
        isShown={true}
        canDismiss={false}
      />
    );
  }

  return (
    <section>
      <PaginatedTable
        headers={headerValues}
        searchPlaceholder="Search Access Requests"
        rowComponents={requests.map((request) => (
          <DealAccessRequestsReceivedTableRow
            key={request.request._id}
            startup={request.startup}
            investor={request.investor}
            request={request.request}
            onAcceptRequest={onAcceptRequest}
            onRejectRequest={onRejectRequest}
          />
        ))}
      />
    </section>
  );
}

export default DealAccessRequestsReceivedTable;
