import React, { useEffect, useState } from 'react';
import { Startup } from '/../libs/shared-types/src/types/model/Startup';
import { Link } from 'react-router-dom';
import { STARTUP_FLOWLINK_MANAGER_ROUTE } from '/src/constants/Routes';
import { PublicProfilePrivacySetting } from '/../libs/shared-types/src/constants/PublicProfilePrivacySetting';
import { fetchFlowlink } from '/src/util/flowlink';
import Logger from '/src/services/logger';
import MailToLink from '/src/components/utility/MailToLink';
import CopyLinkInput from '/src/components/CopyLinkInput';

const PrivacySettingAlertContent = (
  privacySetting: PublicProfilePrivacySetting,
): JSX.Element => {
  switch (privacySetting) {
    case PublicProfilePrivacySetting.Public:
      return (
        <span>
          Your privacy setting is set to <strong>&quot;Public&quot;</strong>.
          Anyone with the link can view your deck and company one-pager.
        </span>
      );
    case PublicProfilePrivacySetting.RequireNameAndEmail:
      return (
        <span>
          Your privacy setting is set to <strong>&quot;Soft-gated&quot;</strong>
          . Investors must enter their name and email to view your deck and
          company one-pager.
        </span>
      );
    case PublicProfilePrivacySetting.RequireFlowlieAccount:
      return (
        <span>
          Your privacy setting is set to <strong>&quot;Hard-gated&quot;</strong>
          . Investors must sign up or log in to view your deck and company
          one-pager.
        </span>
      );
    default:
      return <></>;
  }
};

interface ShareProfileDialogContent {
  startup: Startup;
  showActivationBonus?: boolean;
}

function ShareProfileDialogContent({
  startup,
  showActivationBonus = false,
}: ShareProfileDialogContent): JSX.Element {
  const [flowlinkText, setFlowlinkText] = useState('Loading...');

  async function getFlowlink() {
    try {
      const link = await fetchFlowlink();
      setFlowlinkText(link);
    } catch (error) {
      Logger.error(error);
    }
  }

  useEffect(() => {
    getFlowlink();
  }, []);

  if (!startup) {
    return <></>;
  }

  return (
    <main className="bg-white p-4 sm:w-screen sm:max-w-3xl sm:p-7">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <h3
          className="text-lg font-medium leading-6 text-gray-900"
          id="modal-headline"
        >
          Send your deck and company one-pager all at once with this link!
        </h3>
        <p className="mt-1 text-base">
          You can send this link to anyone, even if they aren’t on Flowlie yet
        </p>
      </div>
      <section className="mt-8 space-y-6">
        <CopyLinkInput link={flowlinkText} />

        {showActivationBonus && (
          <div className="mb-8 mt-4">
            <p className="font-medium text-gray-900">
              Copy your FlowLink and email it to one of our co-founders to get
              feedback:
            </p>
            <ul className="mt-2 flex items-center justify-between space-x-8 px-2">
              <li>
                Vlad (CEO, former Angel Syndicator & VC)
                <br />
                <MailToLink
                  linkText={'vlad@flowlie.com'}
                  params={{
                    to: 'vlad@flowlie.com',
                    subject: 'Claim "Free Feedback" Welcome Bonus',
                    body: `Hi Vlad! \n\nHere is my FlowLink [PASTE_YOUR_LINK_HERE] \n\nCan you give me feedback on my company one-pager?`,
                  }}
                  className="text-blue-600 underline hover:text-blue-400"
                />
              </li>
              <li>
                Mark (CRO, former VC & B2B Sales)
                <br />
                <MailToLink
                  linkText={'mark@flowlie.com'}
                  params={{
                    to: 'vlad@flowlie.com',
                    subject: 'Claim "Free Feedback" Welcome Bonus',
                    body: `Hi Mark! \n\nHere is my FlowLink [PASTE_YOUR_LINK_HERE] \n\nCan you give me feedback on my company one-pager?`,
                  }}
                  className="text-blue-600 underline hover:text-blue-400"
                />
              </li>
            </ul>
          </div>
        )}

        <div>
          <p className="text-base text-gray-600">
            {PrivacySettingAlertContent(startup.flowLinks[0].privacySetting)}
          </p>
          <p className="text-base text-gray-600">
            Manage privacy settings in&nbsp;
            <Link to={STARTUP_FLOWLINK_MANAGER_ROUTE} className="hyperlink">
              FlowLink manager.
            </Link>
          </p>
        </div>
      </section>
    </main>
  );
}

export default ShareProfileDialogContent;
