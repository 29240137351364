import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import {
  PrimaryGrowthDriver,
  getPrimaryGrowthDriverTooltip,
} from '/../libs/shared-types/src/constants/PrimaryGrowthDriver';
import { enumToTooltipSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import { FormatOptionLabelMeta } from 'react-select';
import { TooltipSelectOption } from '../../../types/SelectOptions';
import FormatOptionWithTooltipLabel from '../../inputs/ReactSelectAdditions/formatOptionWithTooltipLabel';

const PrimaryGrowthDriverField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      formatOptionLabel={(
        option: TooltipSelectOption,
        formatOptionLabelMeta: FormatOptionLabelMeta<any>
      ) => (
        <FormatOptionWithTooltipLabel
          option={option}
          formatOptionLabelMeta={formatOptionLabelMeta}
        />
      )}
      isClearable
      label="Primary Growth Driver"
      secondaryLabel="What is the primary method you acquire new users and customers?"
      name={StartupFieldNames.PrimaryGrowthDriver}
      options={enumToTooltipSelectOptions(
        PrimaryGrowthDriver,
        getPrimaryGrowthDriverTooltip
      )}
      placeholder="Select..."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.PrimaryGrowthDriver]: yup.string().nullable(),
  }),
};

export default PrimaryGrowthDriverField;
