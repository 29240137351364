import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  formatImageAddress,
  shortenWebUrl,
} from '/src/util/formatting/strings';
import { generateTag, generateTagCloud } from '/src/util/generateTagCloud';
import DateString from '/src/components/utility/DateString';
import { PublicInvestor } from '/../libs/shared-types/src/types/view/investor/PublicInvestor';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import CopyTextButton from '/src/components/CopyTextButton';
import SquaredLogo from '/src/components/SquaredLogo';

interface DealAccessRequestsMadeTableRowProps {
  investor: PublicInvestor;
  startup: PublicStartup;
  requestId: string;
  requestedOn: Date;
}

function DealAccessRequestsMadeTableRow({
  investor,
  startup,
  requestId,
  requestedOn,
}: DealAccessRequestsMadeTableRowProps): JSX.Element {
  const navigate = useNavigate();
  const handleOnClick = React.useCallback(
    () => navigate(`/app/deal-preview/${requestId}`),
    [history],
  );

  return (
    <tr onClick={handleOnClick} className="cursor-pointer hover:bg-gray-100">
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <SquaredLogo
              containerClassName="size-10"
              src={formatImageAddress(startup.logoKey)}
              alt={`${startup.name} logo`}
            />
          </div>
          <div className="ml-2 max-w-[160px]">
            <div className="app-table-row-text--bold">{startup.name}</div>
            <div className="flex w-full text-sm text-gray-500">
              <a
                className="block truncate hover:underline"
                target="_blank"
                rel="noopener noreferrer"
                href={startup.website}
                onClick={(e) => e.stopPropagation()}
              >
                {shortenWebUrl(startup.website)}
              </a>
              <CopyTextButton text={startup.website} className="ml-1" />
            </div>
          </div>
        </div>
      </td>
      <td className="app-table-row-item">
        {generateTag(startup.currentStage)}
      </td>
      <td className="app-table-row-item">
        {generateTagCloud(startup.industries)}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">{startup.geography}</div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">{`${investor.firstName} ${investor.lastName}`}</div>
      </td>
      <td className="app-table-row-item">
        {investor.firm && investor.role ? (
          <>
            <div className="app-table-row-text">{investor.firm}</div>
            <div className="app-table-row-text--light">{investor.role}</div>
          </>
        ) : (
          <div className="app-table-row-text">-</div>
        )}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          <DateString date={requestedOn} tooltipPosition="top" />
        </div>
      </td>
    </tr>
  );
}

export default DealAccessRequestsMadeTableRow;
