import React from 'react';
import * as yup from 'yup';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import {
  NPS_MAX,
  NPS_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import NumericInput from '../../inputs/NumericInput';

const NetPromoterScoreField: FormField = {
  fieldComponent: (
    <NumericInput
      name={StartupFieldNames.NetPromoterScore}
      label="Net Promoter Score"
      allowDecimals
    />
  ),
  validation: yup.object({
    [StartupFieldNames.NetPromoterScore]: yup
      .number()
      .typeError('Must be a number')
      .min(NPS_MIN, `The min NPS is ${NPS_MIN}`)
      .max(NPS_MAX, `The max NPS is ${NPS_MAX}`)
      .test(
        'maxTwoDecimals',
        'Cannot have more than 2 decimals',
        (value): boolean => {
          if (value && value.toString().length > 4) {
            // Return false to indicate the test failed
            return false;
          }
          return true;
        }
      ),
  }),
};

export default NetPromoterScoreField;
