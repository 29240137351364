import React, { createContext, useState } from 'react';
import {
  AggregateDealShareRequest,
  ConnectionRequestAccountData,
} from '/../libs/shared-types/src/types/view/APIResponse';

export const NOTIFICATIONS_INVESTOR_CONTACTS = 'Investor Contacts';
export const NOTIFICATIONS_ACCESS_REQUESTS = 'Access Requests';

interface InvestorRequestsType {
  connectionRequests: ConnectionRequestAccountData[] | undefined;
  setConnectionRequests: React.Dispatch<
    React.SetStateAction<ConnectionRequestAccountData[] | undefined>
  >;
  accessRequests: AggregateDealShareRequest[] | undefined;
  setAccessRequests: React.Dispatch<
    React.SetStateAction<AggregateDealShareRequest[] | undefined>
  >;
}

const InvestorRequestsContext = createContext<InvestorRequestsType>(
  {} as InvestorRequestsType
);

const InvestorRequests = ({
  children,
}: {
  children: string | JSX.Element[] | JSX.Element;
}): JSX.Element => {
  const [connectionRequests, setConnectionRequests] = useState<
    ConnectionRequestAccountData[] | undefined
  >();
  const [accessRequests, setAccessRequests] = useState<
    AggregateDealShareRequest[] | undefined
  >();
  return (
    <InvestorRequestsContext.Provider
      value={{
        connectionRequests,
        setConnectionRequests,
        accessRequests,
        setAccessRequests,
      }}
    >
      {children}
    </InvestorRequestsContext.Provider>
  );
};

export { InvestorRequests, InvestorRequestsContext };
