import React from 'react';

import { dayDiff, formatDate } from '/src/util/formatting/dates';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import { PipelineInvestorItemView } from '../../../../types/view/InvestorPipelineView';
import { InvestorPipelineSystemStages } from '/../libs/shared-types/src/constants/InvestorPipelineSystemStages';
import { capitalize } from '/src/util/formatting/strings';
import {
  getPluralSuffix,
  numberWithCommas,
} from '/src/util/formatting/numbers';
import { generateTag } from '/src/util/generateTagCloud';
import ActiveStageDurationLeft from '../../DealDetail/ActiveStageDurationLeft';

interface InvestorDetailRoundEngagementCardProps {
  pipeline?: PipelineInvestorItemView;
  estimatedRoundCloseDate?: Date;
  updatePipeline?: () => void;
  title?: string;
}

function InvestorDetailRoundEngagementCard({
  pipeline,
  estimatedRoundCloseDate,
  updatePipeline,
  title,
}: InvestorDetailRoundEngagementCardProps): JSX.Element {
  if (!pipeline || !estimatedRoundCloseDate) {
    return <></>;
  }
  const isFinalStage =
    pipeline.currentStage.name === InvestorPipelineSystemStages.Wired ||
    pipeline.currentStage.name === InvestorPipelineSystemStages.NotInvested;

  const daysInPipeline = isFinalStage
    ? dayDiff(pipeline.currentStage.createdOn, pipeline.createdOn)
    : dayDiff(new Date(), pipeline.createdOn);

  const daysInPipelineText = `${daysInPipeline} day${getPluralSuffix(daysInPipeline)}`;

  const roundTitle = title || 'Current Round Engagement';
  const commitmentAmount = numberWithCommas(pipeline.commitment?.amount);
  return (
    <section>
      <div className="rounded-lg bg-white shadow">
        <div className="flex items-center justify-between px-4 py-5 sm:px-6">
          <div className="flex flex-col">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {roundTitle}
            </h3>
            <span className="text-sm text-gray-500">
              Last updated on {formatDate(pipeline.updatedOn)}
            </span>
          </div>

          <button
            className="app-button--neutral flex items-center"
            onClick={updatePipeline}
          >
            <PencilSquareIcon className="mr-2 size-[1rem]" aria-hidden="true" />
            <span>Update</span>
          </button>
        </div>

        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Current Stage
              </dt>
              <dd className="mt-1 flex flex-col text-sm text-gray-900">
                <span>
                  {capitalize(pipeline.currentStage.name.toLowerCase())}
                </span>
                <ActiveStageDurationLeft
                  durationLeft={pipeline.currentStage.durationLeft}
                />
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Days in Pipeline
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {daysInPipelineText}
              </dd>
            </div>

            {commitmentAmount && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Commitment
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  $ {commitmentAmount}
                </dd>
              </div>
            )}

            {pipeline.passReasons?.reasons &&
              pipeline.passReasons.reasons?.length && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Pass Reason
                  </dt>
                  {pipeline.passReasons.reasons.map((reason, index) => (
                    <dd key={index} className="mt-1 text-sm text-gray-900">
                      {generateTag(reason)}
                    </dd>
                  ))}
                </div>
              )}

            {pipeline.passFeedback?.feedback && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Pass Feedback
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {pipeline.passFeedback.feedback}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
}

export default InvestorDetailRoundEngagementCard;
