import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';
import { MEDIUM_TEXTAREA_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import TextCharacterCounter from '../../TextCharacterCounter';

function FieldWithCharCount(): JSX.Element {
  const { values } = useFormikContext<any>();

  return (
    <>
      <Field
        component={FormikInput}
        boxType="textarea"
        customStyle="h-28 max-h-28"
        label="Describe to the founder which round information is incorrect"
        name={RecordInvestmentFieldNames.IncorrectRoundFeedback}
        type="text"
        placeholder="Tell the founder what they should update in their round..."
      />
      <TextCharacterCounter
        textLength={
          values[RecordInvestmentFieldNames.IncorrectRoundFeedback]?.length ?? 0
        }
        maxLength={MEDIUM_TEXTAREA_LENGTH_MAX}
      />
    </>
  );
}

const IncorrectRoundFeedbackField: FormField = {
  fieldComponent: <FieldWithCharCount />,
  validation: yup.object({
    [RecordInvestmentFieldNames.IncorrectRoundFeedback]: yup
      .string()
      .max(
        MEDIUM_TEXTAREA_LENGTH_MAX,
        `Must be at most ${MEDIUM_TEXTAREA_LENGTH_MAX} characters`,
      )
      .when(RecordInvestmentFieldNames.IsRoundInformationCorrect, {
        is: (isCorrect: boolean) => isCorrect === false,
        then: (schema) => schema.required('Required'),
      }),
  }),
};

export default IncorrectRoundFeedbackField;
