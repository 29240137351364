import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { BOOLEAN_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

function FieldWrapper(): JSX.Element {
  return (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      isClearable
      label="Do you have an initial ownership target?"
      name={InvestorFieldNames.HasInitialOwnershipTarget}
      options={BOOLEAN_OPTIONS}
      placeholder="Select option..."
    />
  );
}

const HasInitialOwnershipTargetField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.HasInitialOwnershipTarget]: yup.bool().nullable(),
  }),
};

export default HasInitialOwnershipTargetField;
