import React from 'react';
import { formatImageAddress } from '/src/util/formatting/strings';
import DateString from '/src/components/utility/DateString';
import { AggregatedStartupReferral } from '/../libs/shared-types/src/types/view/APIResponse';
import SquaredLogo from '/src/components/SquaredLogo';

interface ReferredStartupTableRowProps {
  referral: AggregatedStartupReferral;
}

function ReferredStartupTableRow({
  referral,
}: ReferredStartupTableRowProps): JSX.Element {
  return (
    <tr>
      <td className="app-table-row-item">
        <div className="flex items-center">
          <SquaredLogo
            containerClassName="size-10"
            src={formatImageAddress(referral.logoKey)}
            alt={`${referral.startupName} logo`}
          />
          <div className="ml-2">
            <div className="app-table-row-text--bold">
              {referral.startupName}
            </div>
          </div>
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text--bold">{referral.founderName}</div>
        <div className="app-table-row-text--light">{referral.founderEmail}</div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          <DateString date={referral.joinedOn} />
        </div>
      </td>
    </tr>
  );
}

export default ReferredStartupTableRow;
