export class ZendeskClient {
  zendesk: any;

  constructor() {
    this.zendesk = (window as any).zE;
  }

  activate() {
    if (!this.zendesk) {
      return;
    }
    const zendeskInstance = this.zendesk;
    zendeskInstance(function () {
      zendeskInstance.activate({ hideOnClose: true });
    });
  }
}
