import { TooltipSelectOption } from '../types/SelectOptions';
import { NOT_SET_OPTION } from './SelectOptions/SelectOptions';

export enum Strength {
  None = -1,
  VeryWeak = 1,
  Weak = 2,
  Medium = 3,
  Strong = 4,
  VeryStrong = 5,
}

export const RELATIONSHIP_STRENGTH_OPTIONS: TooltipSelectOption[] = [
  {
    value: Strength.None.toString(),
    label: 'No Relationship',
    tooltipText: 'Never had any interaction or communication',
  },
  {
    value: Strength.VeryWeak.toString(),
    label: 'Very Weak',
    tooltipText: 'Remote connection, or simply exchanged contacts',
  },
  {
    value: Strength.Weak.toString(),
    label: Strength[Strength.Weak],
    tooltipText:
      'Very recent connection or old neglected connection, few interactions, email communication',
  },
  {
    value: Strength.Medium.toString(),
    label: Strength[Strength.Medium],
    tooltipText:
      'Somewhat recent connection, regular interactions, email and phone communication',
  },
  {
    value: Strength.Strong.toString(),
    label: Strength[Strength.Strong],
    tooltipText:
      'Connected for a while, frequent interactions, previous or current business relationship',
  },
  {
    value: Strength.VeryStrong.toString(),
    label: 'Very Strong',
    tooltipText:
      'Connected for a long time, friendship-level, frequent communication',
  },
];
export const RELATIONSHIP_STRENGTH_WITH_UNSET_OPTIONS: Array<
  | TooltipSelectOption
  | { value: typeof NOT_SET_OPTION.value; label: string; tooltipText: string }
> = [
  ...RELATIONSHIP_STRENGTH_OPTIONS,
  {
    value: NOT_SET_OPTION.value,
    label: NOT_SET_OPTION.label,
    tooltipText:
      'You have not set a value for the strength of this relationship',
  },
];
