import React from 'react';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';

function VerifiedInvestorBadge() {
  return (
    <span className="tooltip-wrapper ml-1">
      <span
        className={`tooltip tooltip-right max-w-72 break-words text-sm font-normal normal-case`}
      >
        This investor has verified their email and provided the information in
        the profile
      </span>
      <CheckBadgeIcon className="inline-flex h-5 w-5 text-blue-500" />
    </span>
  );
}

export default VerifiedInvestorBadge;
