import React, { useEffect, useRef } from 'react';
import TablePagination, {
  TablePaginationProps,
} from '../../pagination/TablePagination';
import BaseTable, { BaseTableProps } from './BaseTable';
import { PaginatedRequestParams } from '../../../types/view/APIResponse';
import usePaginatedUrlsParams from '/src/hooks/usePaginatedUrlParams';
import { SortOrder } from '/src/interfaces/Sortable';

interface BasePaginatedTableProps<ISortKeyType, IFieldFilters = any>
  extends BaseTableProps,
    TablePaginationProps {
  requestParams: PaginatedRequestParams<ISortKeyType, IFieldFilters>;
}

function BasePaginatedTable<ISortKeyType, IFieldFilters>({
  enableSelect,
  enableSort,
  headerStructure,
  onPageChange,
  onPerPageChange,
  onRowClick,
  onSelectedChange,
  onSortChange,
  page,
  pageCount,
  perPage,
  resetSelectedTrigger,
  resultCount,
  rows,
  tableName,
  requestParams,
}: BasePaginatedTableProps<ISortKeyType, IFieldFilters>) {
  const searchRef = useRef<HTMLInputElement>(null);

  const {
    // page,
    // perPage,
    // sortOrder,
    // sortKey,
    setPage,
    setPerPage,
    setFilter,
    setFieldFilters,
    setSortOrder,
    setSortKey,
  } = usePaginatedUrlsParams();

  useEffect(() => {
    setPage(requestParams.page ?? 1);
  }, [requestParams.page]);
  useEffect(() => {
    setPerPage(requestParams.perPage ?? 10);
  }, [requestParams.perPage]);
  useEffect(() => {
    setSortOrder(requestParams.sortOrder ?? SortOrder.Asc);
  }, [requestParams.sortOrder]);
  useEffect(() => {
    setSortKey(requestParams.sortKey as any);
  }, [requestParams.sortKey]);
  useEffect(() => {
    setFilter(requestParams.filter ?? '');
  }, [requestParams.filter]);
  useEffect(() => {
    setFieldFilters(requestParams.fieldFilters ?? {});
  }, [requestParams.fieldFilters]);

  useEffect(() => {
    if (rows && searchRef.current) {
      searchRef.current.value = '';
    }
  }, [rows]);

  return (
    <div className="w-full border-b border-gray-200 bg-gray-50 p-0.5 shadow dark:border-gray-800 dark:bg-gray-900 dark:shadow-gray-800 sm:rounded-lg">
      <BaseTable
        enableSelect={enableSelect}
        enableSort={enableSort}
        headerStructure={headerStructure.map((header) => {
          if (header.headerKey === requestParams.sortKey) {
            return {
              ...header,
              isDefaultSortKey: true,
              defaultSortOrder: requestParams.sortOrder,
            };
          }
          return header;
        })}
        onRowClick={onRowClick}
        onSelectedChange={onSelectedChange}
        onSortChange={onSortChange}
        resetSelectedTrigger={resetSelectedTrigger}
        rows={rows}
        tableName={tableName}
      />

      <TablePagination
        // key={`${sortKey}-${sortOrder}`}
        resultCount={resultCount}
        perPage={requestParams.perPage ?? 10}
        page={requestParams.page ?? 1}
        pageCount={pageCount}
        onPageChange={onPageChange}
        onPerPageChange={onPerPageChange}
      />
    </div>
  );
}

export default BasePaginatedTable;
