import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import {
  INV_IND_ANY_INDUSTRY,
  INV_IND_OPTIONS,
} from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import { MAX_INVESTOR_INDUSTRIES } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';
import { flattenOptionsToList } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';

function getDisabledOptions(antiInvIndustries: string[]): string[] {
  if (!antiInvIndustries || antiInvIndustries.length === 0) {
    return [];
  }
  const optionsValues = flattenOptionsToList(INV_IND_OPTIONS);

  const disabledOptions = optionsValues.filter((x) =>
    antiInvIndustries.includes(x),
  );
  return disabledOptions;
}

function FieldWrapper() {
  const { values } = useFormikContext<any>();

  return (
    <Field
      className="custom-select"
      component={CustomSelect}
      components={{ Menu: LimitedMenu }}
      isClearable
      isMulti
      label="What industries do you invest in?"
      maxCount={MAX_INVESTOR_INDUSTRIES}
      name={InvestorFieldNames.InvIndustries}
      options={INV_IND_OPTIONS}
      placeholder="Select industries..."
      disabledOptions={getDisabledOptions(
        values[InvestorFieldNames.AntiInvIndustries],
      )}
    />
  );
}

const InvIndustriesField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.InvIndustries]: yup
      .array()
      .of(yup.string())
      .required('At least one is required')
      .test(
        'agnosticOnly',
        `"${INV_IND_ANY_INDUSTRY}" already includes all industries`,
        (industries): boolean => {
          if (industries?.includes(INV_IND_ANY_INDUSTRY)) {
            // Return false to indicate the test failed
            return industries.length === 1;
          }
          return true;
        },
      )
      .test(
        'agnosticIsSelected',
        `You must remove all the anti-investment industries if you want to select "${INV_IND_ANY_INDUSTRY}"`,
        (selectedInvIndustries, context): boolean => {
          const antiInvIndustries =
            context.parent[InvestorFieldNames.AntiInvIndustries];
          if (
            antiInvIndustries &&
            antiInvIndustries.length > 0 &&
            selectedInvIndustries?.includes(INV_IND_ANY_INDUSTRY)
          ) {
            // Return false to indicate the test failed
            return false;
          }
          return true;
        },
      )
      .test(
        'invIndustriesOverlapWithAntiInvIndustries',
        'Your primary investment industries overlap with your anti-investment industries',
        (selectedInvIndustries, context): boolean => {
          const antiInvIndustries =
            context.parent[InvestorFieldNames.AntiInvIndustries];
          const disabledOptions = getDisabledOptions(antiInvIndustries);
          if (
            antiInvIndustries &&
            selectedInvIndustries?.some((x) => x && disabledOptions.includes(x))
          ) {
            // Return false to indicate the test failed
            return false;
          }
          return true;
        },
      )
      .min(1, 'At least one is required')
      .max(
        MAX_INVESTOR_INDUSTRIES,
        `At most ${MAX_INVESTOR_INDUSTRIES} investment industries are allowed`,
      ),
  }),
};

export default InvIndustriesField;
