import React from 'react';
import { Testimonial } from './TestimonialsData';

interface TestimonialCardProps {
  testimonial: Testimonial;
}

function TestimonialCard({ testimonial }: TestimonialCardProps) {
  if (!testimonial) {
    return <></>;
  }

  return (
    <div className="pt-8 sm:inline-block sm:w-full sm:px-4">
      <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
        <blockquote className="text-gray-900">
          <p>{`“${testimonial.body}”`}</p>
        </blockquote>
        <figcaption className="mt-6 flex items-center gap-x-4">
          <img
            className="h-10 w-10 rounded-full bg-gray-50"
            src={testimonial.author.imageUrl}
            alt={`Headshot of ${testimonial.author.name}`}
          />
          <a
            className="group"
            target="_blank"
            rel="noopener noreferrer"
            href={testimonial.url}
          >
            <div className="font-semibold text-gray-900">
              {testimonial.author.name}
            </div>
            <div className="text-gray-600 group-hover:underline">{`${testimonial.author.roleAtFirm}`}</div>
          </a>
        </figcaption>
      </figure>
    </div>
  );
}

export default TestimonialCard;
