import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { BOARD_PARTICIPATION_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/BoardParticipationOptions';
import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

const BoardParticipationField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      label="Have you received a role on the Board of Directors?"
      name={RecordInvestmentFieldNames.BoardParticipation}
      options={BOARD_PARTICIPATION_OPTIONS}
      placeholder="Select..."
    />
  ),
  validation: yup.object({
    [RecordInvestmentFieldNames.BoardParticipation]: yup
      .string()
      .required('Required'),
  }),
};

export default BoardParticipationField;
