import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  formatImageAddress,
  shortenWebUrl,
} from '/src/util/formatting/strings';
import { generateTag, generateTagCloud } from '/src/util/generateTagCloud';
import DealActionsDropdown from '/src/components/DealActionsDropdown';
import {
  DealSummaryView,
  PassedDealSummaryView,
} from '/../libs/shared-types/src/types/view/AggregatedDeals';
import CopyTextButton from '/src/components/CopyTextButton';
import SquaredLogo from '/src/components/SquaredLogo';

interface PassedDealTableRowProps {
  deal: PassedDealSummaryView;
  share: (deal: DealSummaryView) => void;
  moveToActive: (startupId: string) => void;
}

function PassedDealTableRow({
  deal,
  share,
  moveToActive,
}: PassedDealTableRowProps): JSX.Element {
  const navigate = useNavigate();

  const handleOnClick = useCallback(
    () => navigate(`/app/deal-detail/${deal.startupId}`),
    [history],
  );

  return (
    <tr onClick={handleOnClick} className="cursor-pointer hover:bg-gray-100">
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <SquaredLogo
              containerClassName="size-10"
              src={formatImageAddress(deal.startupLogoKey)}
              alt={`${deal.startupName} logo`}
            />
          </div>
          <div className="ml-2 max-w-[160px]">
            <div className="app-table-row-text--bold">{deal.startupName}</div>
            <div className="flex w-full text-sm text-gray-500">
              <a
                className="block truncate hover:underline"
                target="_blank"
                rel="noopener noreferrer"
                href={deal.website}
                onClick={(e) => e.stopPropagation()}
              >
                {shortenWebUrl(deal.website)}
              </a>
              <CopyTextButton text={deal.website} className="ml-1" />
            </div>
          </div>
        </div>
      </td>
      <td className="app-table-row-item">{generateTag(deal.currentStage)}</td>
      <td className="app-table-row-item">
        {generateTagCloud(deal.industries)}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">{deal.geography}</div>
      </td>
      <td className="app-table-row-item">
        {generateTag(deal.currentRoundLabel, true)}
      </td>
      <td className="app-table-row-item">{generateTag(deal.passedStage)}</td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {deal.passedReasons.map((reason) => generateTag(reason))}
        </div>
      </td>

      <td className="app-table-row-item" role="gridcell">
        <DealActionsDropdown
          deal={deal}
          handleMoveToActive={moveToActive}
          handlePass={() => null}
          handleRecordInvestment={() => null}
          handleShare={share}
        />
      </td>
    </tr>
  );
}

export default PassedDealTableRow;
