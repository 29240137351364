import { ProductCategory } from './ProductCategory';

export enum ProductType {
  WebApplication = 'Web Application',
  MobileApplication = 'Mobile Application',
  DesktopApplication = 'Desktop Application',
  ArVrHeadsetApplication = 'AR/VR Headset Application',
  Api = 'API',
  WearableHardware = 'Wearable Hardware',
  NonWearableHardware = 'Non-wearable Hardware',
  ConsumableProduct = 'Consumable Product',
  DigitalService = 'Digital Service',
  InPersonService = 'In-Person Service',
}

export function getProductTypeTooltip(productType: string): string {
  switch (productType) {
    case ProductType.WebApplication:
      return 'Ex: Google, Canva, Miro, Dropbox, Jira, Airtable, Deel, Ramp, Gusto, Webflow';
    case ProductType.MobileApplication:
      return 'Ex: Instagram, WhatsApp, Uber, DoorDash, Venmo, Revolut, Calm';
    case ProductType.DesktopApplication:
      return 'Ex: Slack, Zoom, Notion, VS Code, Tableau, Steam';
    case ProductType.ArVrHeadsetApplication:
      return 'Ex: Shapes XR, The Room VR, Virtual Desktop, Pavlov VR';
    case ProductType.Api:
      return 'Ex: OpenAI, Stripe, Twilio, SendGrid, Segment, Plaid, Auth0, Checkr, Algolia';
    case ProductType.WearableHardware:
      return 'Ex: Whoop, Oura Ring, Raycon, Tractive, Magic Leap, Neurotech EEG';
    case ProductType.NonWearableHardware:
      return 'Ex: Waymo, DJI, Nuro, Boston Robotics, Dyson, Lomi, 8 Sleep, Flock Safety';
    case ProductType.ConsumableProduct:
      return "Ex: Nestlé, Coca-Cola, Nike, L'Oréal, Harry's, Beyond Meat, Hims & Hers, JUUL";
    case ProductType.DigitalService:
      return 'Ex: Consulting, Telehealth, Accounting & Finance, Marketing, Branding';
    case ProductType.InPersonService:
      return 'Ex: Cleaning, Restaurants, Hotels, Massage & SPAs, Barbers & Beauty Salons';
    default:
      return '';
  }
}

export function getProductCategoryFromProductType(
  productType: ProductType
): ProductCategory | undefined {
  const software = [
    ProductType.WebApplication,
    ProductType.MobileApplication,
    ProductType.DesktopApplication,
    ProductType.ArVrHeadsetApplication,
    ProductType.Api,
  ];
  const hardware = [
    ProductType.WearableHardware,
    ProductType.NonWearableHardware,
  ];
  const consumables = [ProductType.ConsumableProduct];
  const services = [ProductType.DigitalService, ProductType.InPersonService];

  if (software.includes(productType)) {
    return ProductCategory.Software;
  }
  if (hardware.includes(productType)) {
    return ProductCategory.Hardware;
  }
  if (consumables.includes(productType)) {
    return ProductCategory.Consumables;
  }
  if (services.includes(productType)) {
    return ProductCategory.Services;
  }

  return undefined;
}
