import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { buildComparableConnectionRequestAccountData } from '/src/util/TableSorting';
import {
  InvestorConnectionView,
  ConnectionRequestAccountData,
  PaginatedCollectionResponse,
  PaginatedRequestParams,
} from '/../libs/shared-types/src/types/view/APIResponse';
import {
  NOT_FOUND_ROUTE,
  INVESTOR_FLOWLINK_MANAGER_ROUTE,
} from '/src/constants/Routes';
import { fetchConnectionRequests } from '/src/services/InvestorRequests';
import { InvestorRequestsContext } from '/src/contexts/InvestorRequestsContext';
import { Sortable, SortOrder } from '/src/interfaces/Sortable';
import { toDashCase } from '/src/util/formatting/strings';
import API from '/src/middleware/API';
import ConnectionRequestsTable from './ConnectionRequestsTable';
import ConnectionsTable from './ConnectionsTable';
import Logger from '/src/services/logger';
import PageHeader from '/src/components/PageHeader';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import TabsHeader from '/src/components/tabs/TabsHeader';
import ConnectionManagementRoute from './ConnectionManagementRoute';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import AddConnectionDialog from './AddConnectionDialog';
import Button from '/src/components/utility/Button';
import Toast from '/src/components/notifications/Toast';
import { SUCCESS } from '/src/constants/SuccessMessages';
import { PlusIcon } from '@heroicons/react/24/outline';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import {
  ComparableInvestorConnectionView,
  ComparableConnectionRequestAccountData,
} from '/../libs/shared-types/src/types/view/ComparableInvestorConnectionView';
import Alert from '/src/components/notifications/Alert';
import usePaginatedUrlsParams from '/src/hooks/usePaginatedUrlParams';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import SearchBar from '/src/components/inputs/SearchBar';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import { EXAMPLE_INVESTOR } from '/../libs/shared-types/src/constants/UserActivation/MockData/ExampleInvestor';
import { InvestorGetConnections } from '/../libs/shared-types/src/constants/ApiRoutes';

const defaultSortKey = 'name' as keyof ComparableInvestorConnectionView;

const investorConnectionsHeaders = [
  { sortKey: defaultSortKey, element: <span>Name</span> },
  { sortKey: 'type', element: <span>Type</span> },
  { sortKey: 'firm', element: <span>Firm & Role</span> },
  { element: <span>Location</span> },
  { sortKey: 'earliestStage', element: <span>Stages</span> },
  { sortKey: 'checkRangeMin', element: <span>Check Size</span> },
  { sortKey: 'leadsRounds', element: <span>Leads Rounds</span> },
];

function ConnectionsRoute(): JSX.Element {
  const tabs = [{ name: 'My Contacts' }, { name: 'Manage Contacts' }];
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [alertIsShown, setAlertIsShown] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const toggleAlert = () => setAlertIsShown(!alertIsShown);
  const { joyrideActivationTourState, setJoyrideActivationTourState } =
    useContext(SharedStateContext);
  const [apiResponse, setApiResponse] =
    useState<
      PaginatedCollectionResponse<
        InvestorConnectionView,
        ComparableInvestorConnectionView
      >
    >();

  const { page, perPage, sortOrder, sortKey, filter } =
    usePaginatedUrlsParams();
  const { connectionRequests, setConnectionRequests } = useContext(
    InvestorRequestsContext,
  );
  const [sortableConnectionRequests, setSortableConnectionRequests] =
    useState<
      Sortable<
        ConnectionRequestAccountData,
        ComparableConnectionRequestAccountData
      >
    >();

  function showAlert() {
    setAlertIsShown(true);
    window.setTimeout(() => setAlertIsShown(false), 3000);
  }

  async function fetchConnections(
    args: Partial<PaginatedRequestParams<ComparableInvestorConnectionView>>,
  ) {
    try {
      setIsSearchLoading(true);
      const requestParams = {
        sortKey: defaultSortKey,
        ...apiResponse,
        ...args,
      };

      const url = InvestorGetConnections.buildEndpoint(
        undefined,
        requestParams,
      );

      const data =
        await API.get<
          PaginatedCollectionResponse<
            InvestorConnectionView,
            ComparableInvestorConnectionView
          >
        >(url);

      if (
        localStorage.getItem(
          LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING,
        )
      ) {
        // Add mock data
        const exampleConnection: InvestorConnectionView = {
          ...EXAMPLE_INVESTOR,
          id: EXAMPLE_INVESTOR._id,
        };
        data.results.splice(0, 0, exampleConnection);
        data.totalCount = data.results.length;
      }

      setApiResponse(data);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsSearchLoading(false);
    }
  }

  async function fetchContactRequests() {
    try {
      const connectionRequests = await fetchConnectionRequests();
      setConnectionRequests(connectionRequests);
      const sortableItems = new Sortable<
        ConnectionRequestAccountData,
        ComparableConnectionRequestAccountData
      >(connectionRequests, buildComparableConnectionRequestAccountData);
      setSortableConnectionRequests(sortableItems);
    } catch (error: any) {
      Logger.error(error);
    }
  }

  // on close function
  const onAddConnectionDialogClose = () => {
    setShowModal(false);
  };

  const onAddConnectionSuccess = (message: string) => {
    setAlertText(message);
    showAlert();
    setShowModal(false);
  };

  const addConnectionDialog = (
    <ModalWrapper onClose={onAddConnectionDialogClose} open={showModal}>
      <AddConnectionDialog
        onClose={onAddConnectionDialogClose}
        onSuccess={onAddConnectionSuccess}
      ></AddConnectionDialog>
    </ModalWrapper>
  );

  async function dataRefresh(
    page?: number,
    perPage?: number,
    sortOrder?: SortOrder,
    sortKey?: string,
    filter?: string,
  ) {
    try {
      setIsLoading(true);
      await fetchConnections({
        page,
        perPage,
        sortKey: (sortKey ??
          defaultSortKey) as keyof ComparableInvestorConnectionView,
        sortOrder,
        filter,
      });

      await fetchContactRequests();
    } catch (error: any) {
      Logger.error('Investor connections, could not refresh data', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleFilter(filter: string) {
    try {
      await fetchConnections({ filter, page: 1 });
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  const handleSortConnectionRequests = (
    sortKey: string,
    sortOrder: SortOrder,
  ) => {
    const sorted = sortableConnectionRequests?.sort(
      sortKey as keyof ComparableConnectionRequestAccountData,
      sortOrder,
    );
    setConnectionRequests(sorted ? [...sorted] : []);
  };

  useEffect(() => {
    dataRefresh(page, perPage, sortOrder, sortKey, filter);
  }, []);

  // Event listener for handling Activation Tour
  useEffect(() => {
    if (
      isLoading ||
      !apiResponse ||
      !localStorage.getItem(
        LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING,
      )
    ) {
      // Do not resume the tour yet
      return;
    }

    // Resume joyride tour
    setJoyrideActivationTourState({
      ...joyrideActivationTourState,
      run: true,
    });
  }, [apiResponse, isLoading]);

  return (
    <main className="flex flex-col">
      <header>
        {connectionRequests && connectionRequests.length > 0 && (
          <div className="mb-8">
            <h2 className="page-title">Invitations</h2>
            <ConnectionRequestsTable
              contacts={connectionRequests}
              onRefresh={() =>
                dataRefresh(1, 10, SortOrder.Asc, defaultSortKey, '')
              }
              onSort={handleSortConnectionRequests}
            />
          </div>
        )}
        <div className="justify-between sm:flex sm:flex-row">
          <PageHeader
            title="Investor Contacts"
            itemsCount={apiResponse?.totalCount}
            itemLabel="contact"
          />
          <aside className=" inline-flex items-center space-x-4">
            <Button
              className="inline-flex w-full max-w-max items-center justify-center rounded-md border border-transparent bg-blue-600 px-2 py-2 text-sm font-medium text-white shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <PlusIcon className="h-5 w-5" />
              Add Contact
            </Button>
          </aside>
        </div>
        <TabsHeader tabs={tabs} />
      </header>
      {addConnectionDialog}
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {isLoading && (
            <PageLoadingSpinner message="Gathering the greatest investors... 🙌" />
          )}

          {!isLoading && apiResponse && (
            <Routes>
              {['', tabs[0].name].map((path) => (
                <Route
                  key={path}
                  path={toDashCase(path)}
                  element={
                    <>
                      {apiResponse.totalCount === 0 &&
                        apiResponse.filter === '' && (
                          <Alert
                            color="blue"
                            alertType="Info"
                            content={
                              <p>
                                Hmm... it looks like you have no contacts.&nbsp;
                                <Link
                                  to={INVESTOR_FLOWLINK_MANAGER_ROUTE}
                                  className="hyperlink font-bold underline"
                                >
                                  Share your FlowLink
                                </Link>
                                &nbsp;with awesome investors to connect with
                                them.
                              </p>
                            }
                            isShown={true}
                            canDismiss={false}
                          />
                        )}

                      {(apiResponse.totalCount > 0 ||
                        apiResponse.filter !== '') && (
                        <>
                          <div className="mb-3 flex flex-row items-center">
                            <SearchBar
                              isDebounce
                              placeholder="Search Investors"
                              onQueryChange={handleFilter}
                              initialValue={apiResponse.filter}
                            />
                            {isSearchLoading && <LoadingSpinner color="blue" />}
                          </div>
                          <ConnectionsTable
                            headers={investorConnectionsHeaders}
                            refreshData={fetchConnections}
                            parentPage={apiResponse.page}
                            parentTotalCount={apiResponse.totalCount}
                            parentSortOrder={apiResponse.sortOrder}
                            parentSortedColumn={apiResponse.sortKey}
                            parentFilter={apiResponse.filter}
                            parentPerPage={apiResponse.perPage}
                            parentTotalPages={apiResponse.totalPages}
                            connections={apiResponse.results}
                          />
                        </>
                      )}

                      {apiResponse.results.length <= 5 &&
                        apiResponse.results.length > 0 && (
                          <div className="my-4">
                            <Alert
                              color="blue"
                              alertType="Info"
                              content={
                                <p>
                                  <button
                                    type="button"
                                    onClick={() => setShowModal(true)}
                                    className="hyperlink font-bold underline"
                                  >
                                    Invite contacts
                                  </button>{' '}
                                  from your investor network to keep track of
                                  their investment thesis
                                </p>
                              }
                              isShown={true}
                              canDismiss={false}
                            />
                          </div>
                        )}
                    </>
                  }
                />
              ))}
              <Route
                path={toDashCase(tabs[1].name)}
                element={
                  <ConnectionManagementRoute setModalOpen={setShowModal} />
                }
              />
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          )}
        </div>
      </div>
      <Toast
        isShown={alertIsShown}
        title={SUCCESS}
        text={alertText}
        onClose={toggleAlert}
      />
    </main>
  );
}

export default ConnectionsRoute;
