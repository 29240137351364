import { RoundStages } from '../constants/RoundStages';
import { RoundTypes } from '../constants/RoundTypes';
import { SafeType } from '../constants/SafeType';
import { ValuationCapType } from '../constants/ValuationCapType';
import { Round } from '../types/model/Round';

export function formatRoundDisplayName(
  roundStage: RoundStages,
  isBridge: boolean,
  bridgeNumber?: number
): string {
  if (!isBridge) {
    return roundStage;
  }

  if (bridgeNumber === undefined) {
    return `${roundStage} ${isBridge ? 'Bridge' : ''}`;
  }

  // Round names have different spacing:
  // For example:
  // - "Seed 1 Bridge"
  // - "Series A1 Bridge"
  const stageNameLabel = roundStage.startsWith('Series')
    ? roundStage
    : `${roundStage} `;

  return `${stageNameLabel}${bridgeNumber} Bridge`;
}

export function isPricedRoundType(roundType: string): boolean {
  if (
    roundType === RoundTypes.PricedCommon ||
    roundType === RoundTypes.PricedPreferred
  ) {
    return true;
  }

  return false;
}

/**
 * A round should have Valuation Cap data if:
 * it is NOT To Be Determined, or NOT a SAFE Uncapped
 * @param round
 * @returns True if the Round should have a valuation
 * @note This does not check if the round.valuationCap and ValuationCapType are defined
 */
export function shouldHaveValuationCap(round: Round): boolean {
  if (
    round.roundType &&
    (round.roundType === RoundTypes.ToBeDetermined ||
      (round.roundType === RoundTypes.Safe &&
        round.safeType === SafeType.Uncapped))
  ) {
    return false;
  }

  return true;
}

/**
 * The round estimated raise is the larger value between the Amount (already) raised and the Raise Target
 * @param round
 * @returns
 */
export function getEstimatedRaise(round: Round): number {
  return round.raisedAmount > round.raiseTarget
    ? round.raisedAmount
    : round.raiseTarget;
}

export function getEstimatedPostCap(round: Round): number | undefined {
  if (
    !shouldHaveValuationCap(round) ||
    round.valuationCap === undefined ||
    round.valuationCapType === undefined
  ) {
    return undefined;
  }

  const estimatedRaise = getEstimatedRaise(round);

  const estimatedPostCap =
    round.valuationCapType === ValuationCapType.PreMoney
      ? round.valuationCap + estimatedRaise
      : round.valuationCap;

  return estimatedPostCap;
}
