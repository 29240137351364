import React from 'react';
import { SocialHandle } from '/../libs/shared-types/src/types/model/SocialHandle';

function generateSocialHandles(socialHandles: SocialHandle[]): JSX.Element[] {
  return socialHandles.map((handle, index) => (
    <div key={`${handle.url}_${index}`} className="sm:col-span-1">
      <dt className="sr-only text-sm font-medium text-gray-500">
        {handle.name}
      </dt>
      <dd className="hyperlink mt-1 text-sm" title={handle.url}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={handle.url}
          onClick={(e) => e.stopPropagation()}
        >
          {handle.icon}
        </a>
      </dd>
    </div>
  ));
}

export default generateSocialHandles;
