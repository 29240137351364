import STARTUP_FIELD_MAPPING from './StartupFormFieldMapping';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormStep from '/src/interfaces/FormStep';

const steps: FormStep[] = [
  {
    label: 'Tell us about your company',
    fields: [
      STARTUP_FIELD_MAPPING[StartupFieldNames.Name],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Website],
      STARTUP_FIELD_MAPPING[StartupFieldNames.FoundedOn],
      STARTUP_FIELD_MAPPING[StartupFieldNames.FounderRole],
      STARTUP_FIELD_MAPPING[StartupFieldNames.FounderLinkedIn],
      STARTUP_FIELD_MAPPING[StartupFieldNames.FounderIsTechnical],
    ],
    isOptional: false,
  },
  {
    label: 'Intriguing! Can we learn more?',
    fields: [
      STARTUP_FIELD_MAPPING[StartupFieldNames.Industries],
      STARTUP_FIELD_MAPPING[StartupFieldNames.BusinessModels],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Geography],
      STARTUP_FIELD_MAPPING[StartupFieldNames.CurrentStage],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Impacts],
    ],
    isOptional: false,
  },
  {
    label: "Almost there, let's securely upload your logo and deck...",
    fields: [
      STARTUP_FIELD_MAPPING[StartupFieldNames.Logo],
      STARTUP_FIELD_MAPPING[StartupFieldNames.Deck],
    ],
    isOptional: false,
  },
  {
    label: 'Add your legal information to help with due diligence',
    fields: [
      STARTUP_FIELD_MAPPING[StartupFieldNames.LegalEntityType],
      STARTUP_FIELD_MAPPING[StartupFieldNames.LegalName],
      STARTUP_FIELD_MAPPING[StartupFieldNames.LegalFormationState],
    ],
    isOptional: false,
  },
];

export default steps;
