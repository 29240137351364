import React from 'react';
import * as yup from 'yup';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import { validateNumeric } from '/src/components/utility/Validators';
import FormField from '/src/interfaces/FormField';
import MoneyInput from '../../inputs/MoneyInput';

const CustomerAcquisitionCostField: FormField = {
  fieldComponent: (
    <MoneyInput
      name={StartupFieldNames.CustomerAcquisitionCost}
      label="Customer Acquisition Cost"
      tooltip="The total cost of acquiring a customer. This includes the costs of marketing and sales efforts, divided by the number of new customers acquired."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.CustomerAcquisitionCost]: validateNumeric(
      'RECURRING_MONETARY_MAX'
    ),
  }),
};

export default CustomerAcquisitionCostField;
