import React, { useEffect, useState } from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';

import { ACTIVE_DEALFLOW_ROUTE, NOT_FOUND_ROUTE } from '/src/constants/Routes';
import {
  DealSummaryView,
  PortfolioDealSummaryView,
} from '/../libs/shared-types/src/types/view/AggregatedDeals';
import {
  buildComparableInvestment,
  buildComparablePortfolioDealSummaryView,
} from '/src/util/TableSorting';
import {
  ComparableInvestment,
  ComparablePortfolioDealSummaryView,
} from '/src/interfaces/Comparable';
import { Sortable, SortOrder } from '/src/interfaces/Sortable';
import { SUCCESS, SHARED_SUCCESS } from '/src/constants/SuccessMessages';
import { TableHeader } from '/src/interfaces/TableHeader';
import API from '/src/middleware/API';
import InvestmentsTableRow from './tables/InvestmentsTableRow';
import Logger from '/src/services/logger';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import PageHeader from '/src/components/PageHeader';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import PaginatedTable from '/src/components/table/PaginatedTable';
import PortfolioCompaniesTableRow from './tables/PortfolioCompaniesTableRow';
import PortfolioKpis from './PortfolioKpiCards';
import ShareDealDialog from '../ActiveDealflow/dialogs/ShareDealDialog';
import Toast from '/src/components/notifications/Toast';
import Tooltip from '/src/components/utility/Tooltip';
import { toDashCase } from '/src/util/formatting/strings';
import TabsHeader from '/src/components/tabs/TabsHeader';
import { InvestmentView } from '/../libs/shared-types/src/types/view/InvestmentView';
import { MoicExplanation } from '/src/constants/Tooltips';
import SearchBar from '/src/components/inputs/SearchBar';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Alert from '/src/components/notifications/Alert';
import { InvestorDealflowPortfolio } from '/../libs/shared-types/src/constants/ApiRoutes';

const portfolioCompaniesTableHeaders: TableHeader<ComparablePortfolioDealSummaryView>[] =
  [
    { sortKey: 'name', element: <span>Company</span> },
    { sortKey: 'stage', element: <span>Current Stage</span> },
    { sortKey: 'firstInvestedAt', element: <span>First Invested At</span> },
    { sortKey: 'totalInvested', element: <span>Total Invested</span> },
    {
      sortKey: 'estimatedValue',
      element: <span>Estimated Value</span>,
    },
    {
      sortKey: 'moic',
      element: (
        <span className="flex flex-row">
          MOIC
          <Tooltip tooltipText={MoicExplanation} position="left" />
        </span>
      ),
    },
    {
      element: <span>Board Participation</span>,
    },
    {
      sortKey: 'investorSince',
      element: <span>Investor Since</span>,
    },
    { element: <span className="sr-only">Actions</span> },
  ];

const investmentsTableHeaders: TableHeader<ComparableInvestment>[] = [
  { sortKey: 'name', element: <span>Company</span> },
  { sortKey: 'investmentStage', element: <span>Invested At</span> },
  { sortKey: 'investmentType', element: <span>Investment Type</span> },
  { sortKey: 'amount', element: <span>Investment Amount</span> },
  { sortKey: 'pricePerShare', element: <span>PPS</span> },
  {
    sortKey: 'investmentValuation',
    element: <span>Investment Valuation</span>,
  },
  {
    element: <span>Rights</span>,
  },
  {
    sortKey: 'moic',
    element: (
      <span className="flex flex-row">
        MOIC
        <Tooltip tooltipText={MoicExplanation} position="left" />
      </span>
    ),
  },
  {
    sortKey: 'investedOn',
    element: <span>Invested On</span>,
  },
];

function PortfolioRoute(): JSX.Element {
  const tabs = [{ name: 'Portfolio Companies' }, { name: 'Investments' }];
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [portfolioDeals, setPortfolioDeals] = useState<
    PortfolioDealSummaryView[]
  >([]);
  const [investments, setInvestments] = useState<
    { deal: PortfolioDealSummaryView; investment: InvestmentView }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState<DealSummaryView>();
  const [shareFormModalOpen, setShareFormModalOpen] = useState(false);
  const [isSentAlertShown, setIsSentAlertShown] = useState(false);
  const [userQuery, setUserQuery] = useState('');
  const [sortableDeals, setSortableDeals] =
    useState<
      Sortable<PortfolioDealSummaryView, ComparablePortfolioDealSummaryView>
    >();
  const [sortableInvestments, setSortableInvestments] =
    useState<
      Sortable<
        { deal: PortfolioDealSummaryView; investment: InvestmentView },
        ComparableInvestment
      >
    >();
  const toggleShareFormModal = () => setShareFormModalOpen(!shareFormModalOpen);
  const toggleShowSentAlert = () => setIsSentAlertShown(!isSentAlertShown);

  function showSentAlert() {
    setIsSentAlertShown(true);
    window.setTimeout(() => setIsSentAlertShown(false), 3000);
  }

  function handleShare(deal: DealSummaryView) {
    setSelectedDeal(deal);
    toggleShareFormModal();
  }

  function processDataAndSetState(deals: PortfolioDealSummaryView[]) {
    setPortfolioDeals(deals);
    setInvestments(
      [...deals]
        .map((deal) =>
          deal.investments.map((investment) => ({ investment, deal }))
        )
        .flat()
        .sort(
          (a, b) =>
            b.investment.investedOn.getTime() -
            a.investment.investedOn.getTime()
        )
    );

    const sortableDealsInstance = new Sortable<
      PortfolioDealSummaryView,
      ComparablePortfolioDealSummaryView
    >(deals, buildComparablePortfolioDealSummaryView);
    setSortableDeals(sortableDealsInstance);

    const sortableInvestmentsInstance = new Sortable<
      { deal: PortfolioDealSummaryView; investment: InvestmentView },
      ComparableInvestment
    >(
      [...deals]
        .map((deal) =>
          deal.investments.map((investment) => ({ investment, deal }))
        )
        .flat(),
      buildComparableInvestment
    );
    setSortableInvestments(sortableInvestmentsInstance);
  }

  async function queryPortfolioDeals(query: string) {
    setIsSearchLoading(true);
    setUserQuery(query);
    try {
      const deals = await API.get<PortfolioDealSummaryView[]>(
        InvestorDealflowPortfolio.buildEndpoint(undefined, { query })
      );
      processDataAndSetState(deals);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsSearchLoading(false);
    }
  }

  async function fetchPortfolioDeals() {
    setIsLoading(true);
    try {
      const deals = await API.get<PortfolioDealSummaryView[]>(
        InvestorDealflowPortfolio.buildEndpoint()
      );
      processDataAndSetState(deals);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSortPortfolioCompanies = (
    sortKey: string,
    sortOrder: SortOrder
  ) => {
    const sorted = sortableDeals?.sort(
      sortKey as keyof ComparablePortfolioDealSummaryView,
      sortOrder
    );
    setPortfolioDeals(sorted ? [...sorted] : []);
  };

  const handleSortInvestments = (sortKey: string, sortOrder: SortOrder) => {
    const sorted = sortableInvestments?.sort(
      sortKey as keyof ComparableInvestment,
      sortOrder
    );
    setInvestments(sorted ? [...sorted] : []);
  };

  useEffect(() => {
    fetchPortfolioDeals();
  }, []);

  const emptyContent = (
    <p>
      Hmm... it looks like you have no deals in your portfolio.&nbsp;
      <Link
        to={ACTIVE_DEALFLOW_ROUTE}
        className="hyperlink font-bold underline"
      >
        Head over to Active Dealflow
      </Link>
      &nbsp;to record new investments.
    </p>
  );

  return (
    <div>
      <div className="flex flex-col">
        <PageHeader
          title="Portfolio"
          itemsCount={portfolioDeals.length}
          itemLabel="deal"
        />
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {isLoading && (
              <PageLoadingSpinner message="Loading your investments... 🤑" />
            )}

            {!isLoading && <PortfolioKpis deals={portfolioDeals} />}
            <TabsHeader tabs={tabs} />
            {!isLoading && (portfolioDeals.length > 0 || userQuery !== '') && (
              <div className="mb-3 flex flex-row items-center">
                <SearchBar
                  isDebounce
                  placeholder="Search Portfolio Deals"
                  onQueryChange={queryPortfolioDeals}
                />
                {isSearchLoading && <LoadingSpinner color="blue" />}
              </div>
            )}
            <Routes>
              {['', tabs[0].name].map((path) => (
                <Route
                  key={path}
                  path={toDashCase(path)}
                  element={
                    <>
                      {portfolioDeals.length === 0 && userQuery == '' && (
                        <Alert
                          alertType="Info"
                          color="blue"
                          content={emptyContent}
                          canDismiss={false}
                          isShown
                        />
                      )}
                      {portfolioDeals.length > 0 && (
                        <section className="my-2">
                          <PaginatedTable
                            headers={portfolioCompaniesTableHeaders}
                            key="portfolio"
                            onSort={handleSortPortfolioCompanies}
                            searchPlaceholder="Search Portfolio"
                            rowComponents={[...portfolioDeals].map((deal) => (
                              <PortfolioCompaniesTableRow
                                key={`PORT_${deal.startupId}`}
                                deal={deal}
                                onShareDeal={handleShare}
                              />
                            ))}
                          />
                        </section>
                      )}
                    </>
                  }
                />
              ))}

              <Route
                path={toDashCase(tabs[1].name)}
                element={
                  <>
                    {investments.length === 0 && userQuery === '' && (
                      <Alert
                        alertType="Info"
                        color="blue"
                        canDismiss={false}
                        content={
                          <p>
                            When you record investments, you will see all your
                            transactions here.
                          </p>
                        }
                        isShown
                      />
                    )}
                    {investments.length > 0 && (
                      <section className="my-2">
                        <PaginatedTable
                          headers={investmentsTableHeaders}
                          key="investments"
                          onSort={handleSortInvestments}
                          searchPlaceholder="Search Investments"
                          rowComponents={[...investments].map(
                            ({ deal, investment }) => (
                              <InvestmentsTableRow
                                key={`INV_${deal.startupId}_${investment.investedOn}`}
                                investment={investment}
                                deal={deal}
                              />
                            )
                          )}
                        />
                      </section>
                    )}
                  </>
                }
              />
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          </div>
        </div>
      </div>
      <ModalWrapper
        open={shareFormModalOpen}
        onClose={() => setShareFormModalOpen(false)}
      >
        {selectedDeal && (
          <ShareDealDialog
            onClose={toggleShareFormModal}
            onSuccess={showSentAlert}
            deal={selectedDeal}
          />
        )}
      </ModalWrapper>
      <Toast
        isShown={isSentAlertShown}
        onClose={toggleShowSentAlert}
        title={SUCCESS}
        text={SHARED_SUCCESS}
      />
    </div>
  );
}

export default PortfolioRoute;
