import Carlos from '/src/res/Testimonials/carlos_del_carpio.jpg';
import Chuck from '/src/res/Testimonials/chuck_walter.jpg';
import Sam from '/src/res/Testimonials/sam_hornsby.jpg';
import Rux from '/src/res/Testimonials/ruxandra_muys_stoian.jpg';
import Mike from '/src/res/Testimonials/mike_lightman.jpg';
import Louise from '/src/res/Testimonials/louise-asaye.jpg';

export interface Testimonial {
  body: string;
  author: {
    name: string;
    roleAtFirm: string;
    imageUrl: string;
  };
  url?: string;
}

export const TESTIMONIALS_STARTUP: Testimonial[] = [
  {
    body: 'Having all the data in one place and being able to update it regularly without sending any additional emails to investors is just so useful. Especially for founders who have very little time, the ability to easily share the deck and keep track of all the engagement analytics on top of the rich investor profiles and fit ranking is a true productivity booster.',
    author: {
      name: 'Sam Hornsby',
      roleAtFirm: 'Co-founder & CEO of ERIC',
      imageUrl: Sam,
    },
    url: 'https://www.meet-eric.com/',
  },
  {
    body: "Much better than just a deck! - Flowlie has boosted my confidence when reaching out to investors. Our FlowLink provides us with a clear framework for sharing key information, and it's great to know when an investor has accessed the link and our pitch deck.",
    author: {
      name: 'Chuck Walter',
      roleAtFirm: 'Co-founder & CEO of FriendApp',
      imageUrl: Chuck,
    },
    url: 'https://www.friendapp.com/',
  },
  {
    body: 'Flowlie allowed us to get more information about investors than any other tool we tried. Since investors have a dedicated page, we see not only their engagement with our deck but also their thesis and degree of fit. This proved extremely valuable throughout our fundraising saving us time and allowing us to focus on the best ones.',
    author: {
      name: 'Carlos Del Carpio',
      roleAtFirm: 'Co-founder & CEO of Valia',
      imageUrl: Carlos,
    },
    url: 'https://www.valiapro.com/',
  },
  {
    body: "We love the fit score - it helps us prioritize the deals we should be looking at and gives a high level oversight on where we should focus. Otherwise my inbox is full of deals I didn't get the chance to look at or don't know if I should. If it's not on Flowlie, I'm not looking at it.",
    author: {
      name: 'Louise Asaye',
      roleAtFirm: 'Founding Partner at Ultrasound VC',
      imageUrl: Louise,
    },
    url: 'https://ultrasound.vc/',
  },
];

export const TESTIMONIALS_INVESTORS: Testimonial[] = [
  {
    body: "We love the fit score - it helps us prioritize the deals we should be looking at and gives a high level oversight on where we should focus. Otherwise my inbox is full of deals I didn't get the chance to look at or don't know if I should. If it's not on Flowlie, I'm not looking at it.",
    author: {
      name: 'Louise Asaye',
      roleAtFirm: 'Founding Partner at Ultrasound VC',
      imageUrl: Louise,
    },
    url: 'https://ultrasound.vc/',
  },
  {
    body: "Flowlie works: founders love it because it makes their lives easier, and it makes VCs' lives simpler as well. As an ex-VC and angel investor, I know how tough it can be to manage the fundraising funnel, from finding the right investors to communicating effectively with them. That's why I recommend Flowlie to every company I work with. Flowlie helps identify the right investors based on their thesis, size, and fit and simplifies the entire fundraising process. It's easy to use and just works.",
    author: {
      name: 'Mike Lightman',
      roleAtFirm: 'Founder & CEO of Hate Your Deck',
      imageUrl: Mike,
    },
    url: 'https://www.hateyourdeck.com/',
  },
  {
    body: "You clearly nailed deal screening. The experience is impeccable, your fit scoring is great, and you provide investors with a one-pager overview for each deal that includes everything they're looking for. The network tracker and deal-sharing capabilities are the perfect extensions of my process. Investors want to help startups, foster community, hear new ideas, and give back to society - this gives them an easier way to do so.",
    author: {
      name: 'Rux Muys-Stoian',
      roleAtFirm: 'Founder & Managing Partner of WIT Angels',
      imageUrl: Rux,
    },
    url: 'https://witangels.club/',
  },
];
