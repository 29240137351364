import { BillingFrequency } from './BillingFrequency';
import { EnvironmentTypes } from './EnvironmentTypes';
import { SubscriptionTiers } from './SubscriptionTiers';

const ProductionPriceIds = {
  PRODUCTION_INVESTOR_PRO_PRICE_ID_ANNUALLY: 'price_1NXDH7Bx9OgURn8e68kCSpeK',
  PRODUCTION_INVESTOR_PRO_PRICE_ID_MONTHLY: 'price_1NXDH7Bx9OgURn8emAEPisIZ',
  PRODUCTION_STARTUP_PRO_PRICE_ID_ANNUALLY: 'price_1Oxz0yBx9OgURn8eZOQJokyD',
  PRODUCTION_STARTUP_PRO_PRICE_ID_MONTHLY: 'price_1OxyzyBx9OgURn8es0BvGHRM',
};

const DevPriceIds = {
  DEV_INVESTOR_PRO_PRICE_ID_ANNUALLY: 'price_1NXXRrBx9OgURn8eEm5gXNsb',
  DEV_INVESTOR_PRO_PRICE_ID_MONTHLY: 'price_1NXXRrBx9OgURn8eAVtDzUZN',
  DEV_STARTUP_PRO_PRICE_ID_ANNUALLY: 'price_1Oxz3QBx9OgURn8eeTDR6ygY',
  DEV_STARTUP_PRO_PRICE_ID_MONTHLY: 'price_1Oxz3ABx9OgURn8eSUn0CeOG',
};

export function getPriceId(
  env: string | undefined,
  billingFrequency: BillingFrequency,
  subscriptionTier: SubscriptionTiers
): string {
  if (!env) {
    throw Error('Cannot compute price because env is undefined');
  }

  if (env === EnvironmentTypes.Production) {
    if (
      subscriptionTier === SubscriptionTiers.StartupPro &&
      billingFrequency === BillingFrequency.Annually
    ) {
      return ProductionPriceIds.PRODUCTION_STARTUP_PRO_PRICE_ID_ANNUALLY;
    }
    if (
      subscriptionTier === SubscriptionTiers.StartupPro &&
      billingFrequency === BillingFrequency.Monthly
    ) {
      return ProductionPriceIds.PRODUCTION_STARTUP_PRO_PRICE_ID_MONTHLY;
    }

    if (
      subscriptionTier === SubscriptionTiers.InvestorPro &&
      billingFrequency === BillingFrequency.Annually
    ) {
      return ProductionPriceIds.PRODUCTION_INVESTOR_PRO_PRICE_ID_ANNUALLY;
    }
    if (
      subscriptionTier === SubscriptionTiers.InvestorPro &&
      billingFrequency === BillingFrequency.Monthly
    ) {
      return ProductionPriceIds.PRODUCTION_INVESTOR_PRO_PRICE_ID_MONTHLY;
    }
  }

  if (env === EnvironmentTypes.Development || env === EnvironmentTypes.Local) {
    if (
      subscriptionTier === SubscriptionTiers.StartupPro &&
      billingFrequency === BillingFrequency.Annually
    ) {
      return DevPriceIds.DEV_STARTUP_PRO_PRICE_ID_ANNUALLY;
    }
    if (
      subscriptionTier === SubscriptionTiers.StartupPro &&
      billingFrequency === BillingFrequency.Monthly
    ) {
      return DevPriceIds.DEV_STARTUP_PRO_PRICE_ID_MONTHLY;
    }

    if (
      subscriptionTier === SubscriptionTiers.InvestorPro &&
      billingFrequency === BillingFrequency.Annually
    ) {
      return DevPriceIds.DEV_INVESTOR_PRO_PRICE_ID_ANNUALLY;
    }
    if (
      subscriptionTier === SubscriptionTiers.InvestorPro &&
      billingFrequency === BillingFrequency.Monthly
    ) {
      return DevPriceIds.DEV_INVESTOR_PRO_PRICE_ID_MONTHLY;
    }
  }
  throw Error('Account/environment type not supported');
}
