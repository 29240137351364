/* eslint-disable */

import React from 'react';

import { components } from 'react-select';

const LimitedMenu = (props: any) => {
  const optionSelectedLength = props.getValue().length || 0;
  return (
    <components.Menu {...props}>
      {optionSelectedLength < props.selectProps.maxCount ? (
        props.children
      ) : (
        <div className="p-2">
          You can only select&nbsp;
          {props.selectProps.maxCount}
        </div>
      )}
    </components.Menu>
  );
};

export default LimitedMenu;
