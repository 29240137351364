import React from 'react';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import { splitOnWhiteSpace } from '/src/util/formatting/strings';
import { generateFitScoreTag } from '/src/util/generateTagCloud';
import { FitScore } from '/../libs/shared-types/src/types/model/FitScore';

interface TopMatchProps {
  picUrl: string | undefined;
  name: string;
  firm?: string;
  role?: string;
  type: InvestorTypes;
  fitScore: FitScore;
  id: string;
  onAdd: (id: string) => void;
}

function TopMatch({
  picUrl = '',
  fitScore,
  firm = '',
  role = '',
  type,
  name,
  onAdd,
  id,
}: TopMatchProps): JSX.Element {
  const handleClick = () => onAdd(id);
  const investorOccupation =
    type === InvestorTypes.Angel ? 'Angel' : `${role}\n${firm}`;

  return (
    <button
      type="button"
      onClick={handleClick}
      className="relative mx-1 w-full max-w-[164px] flex-1 cursor-pointer rounded-md border border-gray-300 bg-white py-2 transition-all hover:border-2 hover:border-blue-500"
    >
      <div className="relative mb-2">
        <UserAvatarWithInitials
          containerStyles="h-16 w-16 mx-auto"
          firstName={splitOnWhiteSpace(name)[0]}
          lastName={splitOnWhiteSpace(name)[1]}
          textStyles="text-2xl"
          imgAlt="Investor Profile"
          imgSrc={picUrl}
        />
        <div className="absolute -bottom-2 right-7 text-2xs">
          {generateFitScoreTag(fitScore)}
        </div>
      </div>

      <p className="text-sm font-medium text-gray-800">{name}</p>
      <p className="whitespace-pre-wrap text-xs font-medium text-gray-700">
        {investorOccupation}
      </p>
    </button>
  );
}

export default TopMatch;
