import React, { useState } from 'react';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';

const iFrameStyle: React.CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  border: 'none',
  padding: '0',
  margin: '0',
};

const iFrameContainerStyle: React.CSSProperties = {
  position: 'relative',
  width: '100%',
  height: '0',
  paddingTop: '56.2500%',
  paddingBottom: '0',
  boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
  marginTop: '1.6em',
  marginBottom: '0.9em',
  overflow: 'hidden',
  borderRadius: '8px',
  willChange: 'transform',
};

interface IframeCanvaProps {
  frameSrc: string;
  frameTitle: string;
}

function IframeCanva({ frameSrc, frameTitle }: IframeCanvaProps): JSX.Element {
  const [isLoadingFrame, setIsLoadingFrame] = useState(true);

  return (
    <div>
      <div style={iFrameContainerStyle}>
        {isLoadingFrame && (
          <div className="absolute left-[45%] top-1/2 ">
            <LoadingSpinner color="blue" size={10} />
          </div>
        )}
        <iframe
          title={frameTitle}
          loading="lazy"
          style={iFrameStyle}
          src={frameSrc}
          allowFullScreen
          allow="fullscreen"
          onLoad={() => setIsLoadingFrame(false)}
        ></iframe>
      </div>
    </div>
  );
}

export default IframeCanva;
