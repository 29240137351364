import React from 'react';
import generateSocialHandles from '/src/util/generateSocialHandles';
import { LinkedIn } from '/src/components/CustomIcons';
import CopyTextButton from '/src/components/CopyTextButton';
import { InvestorFragmentView } from '../../../types/view/InvestorFragmentView';
import { displayMoneyRange } from '/src/util/formatting/numbers';
import { generateTag } from '/src/util/generateTagCloud';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import { getFitLabelColor } from '/../libs/shared-types/src/types/model/FitScore';

interface InvestorFragmentDetailAboutCardProps {
  investor: InvestorFragmentView;
  onEditClick: () => void;
}

function InvestorFragmentDetailAboutCard({
  investor,
  onEditClick,
}: InvestorFragmentDetailAboutCardProps): JSX.Element {
  const socialHandles = [];
  if (investor.linkedIn) {
    socialHandles.push({
      icon: <LinkedIn />,
      name: 'LinkedIn',
      url: investor.linkedIn,
    });
  }

  const fitLabelColor = investor.fitLabel
    ? getFitLabelColor(investor.fitLabel)
    : '';

  return (
    <section>
      <div className="rounded-lg bg-white shadow">
        <div className="flex items-center justify-between px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">About</h3>
          <button
            className="app-button--neutral flex items-center"
            onClick={onEditClick}
          >
            <PencilSquareIcon className="mr-2 size-[1rem]" aria-hidden="true" />
            <span>Edit</span>
          </button>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 xl:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Investor Type
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {generateTag(investor.type)}
              </dd>
            </div>

            {investor.fitLabel && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Investor Fit
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {generateTag(
                    investor.fitLabel,
                    false,
                    'Represents how relevant this investor is to your company',
                    fitLabelColor
                      ? fitLabelColor.bgColor + ' ' + fitLabelColor.textColor
                      : '',
                  )}
                </dd>
              </div>
            )}

            {investor.checkRangeMin && investor.checkRangeMax && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Investment Check Size
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {displayMoneyRange(
                    investor.checkRangeMin,
                    investor.checkRangeMax,
                  )}
                </dd>
              </div>
            )}

            {investor.leadsRounds && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Leading Rounds
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {investor.leadsRounds}
                </dd>
              </div>
            )}
            {investor.email && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="hyperlink mt-1 flex items-center text-sm">
                  <a className="truncate" href={`mailto:${investor.email}`}>
                    {investor.email}
                  </a>
                  <CopyTextButton text={investor.email} className="ml-2" />
                </dd>
              </div>
            )}
            {investor.website && (
              <div className="sm:col-span-2 2xl:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Website</dt>
                <dd className="hyperlink mt-1 text-sm">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={investor.website}
                  >
                    {investor.website}
                  </a>
                </dd>
              </div>
            )}
            {socialHandles.length > 0 && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Social Handles
                </dt>
                <span className="mt-1 flex flex-wrap space-x-2">
                  {generateSocialHandles(socialHandles)}
                </span>
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
}

export default InvestorFragmentDetailAboutCard;
