export const LINK_DISCOVERY_OPTIONS = [
  { value: 'Website', label: 'Website' },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'Twitter', label: 'Twitter' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Online Investor Database', label: 'Online Investor Database' },
  { value: 'Flowlie Investor Discovery', label: 'Flowlie Investor Discovery' },
  { value: 'Press Article', label: 'Press Article' },
  { value: 'Podcast', label: 'Podcast' },
  { value: 'Blog', label: 'Blog' },
  { value: 'In-Person Event', label: 'In-Person Event' },
  { value: 'Online Event', label: 'Online Event' },
  { value: 'I Invited You To Connect', label: 'I Invited You To Connect' },
  { value: 'Other', label: 'Other' },
];
