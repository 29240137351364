import { FLOWLINK_BASE_URL } from './urls';
import { SharedLinkSharingGetFlowlink } from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';

export function buildLinkFromToken(token: string): string {
  return `${FLOWLINK_BASE_URL}${token}`;
}

export async function fetchFlowlink(): Promise<string> {
  try {
    const data = await API.get(SharedLinkSharingGetFlowlink.buildEndpoint());
    const { token } = data;
    return await Promise.resolve(buildLinkFromToken(token));
  } catch (error: any) {
    throw Error(error.message);
  }
}
