import { Investment } from '/../libs/shared-types/src/types/model/Deal';

export function getInvestmentRights(investment: Investment): string[] {
  const investorRights = [];
  if (investment.hasRightsInformation) {
    investorRights.push('Information Rights');
  }
  if (investment.hasRightsMfn) {
    investorRights.push('Most Favorable Nation Rights');
  }
  if (investment.hasRightsProRata) {
    investorRights.push('Pro Rata Rights');
  }
  if (investorRights.length === 0) {
    investorRights.push('None');
  }

  return investorRights;
}

export function getAbbreviatedInvestmentRights(
  investment: Investment
): string[] {
  const investorRights = [];
  if (investment.hasRightsInformation) {
    investorRights.push('IR');
  }
  if (investment.hasRightsMfn) {
    investorRights.push('MFN');
  }
  if (investment.hasRightsProRata) {
    investorRights.push('PR');
  }
  if (investorRights.length === 0) {
    investorRights.push('None');
  }

  return investorRights;
}
