import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import PercentageInput from '/src/components/inputs/PercentageInput';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import {
  DISCOUNT_MAX,
  DISCOUNT_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.Discount, values) ? (
    <PercentageInput
      disabled={values[RoundFieldNames.IsEditable] === 'false'}
      label="Discount Rate"
      name={RoundFieldNames.Discount}
      tooltip="The discount investors receive on the share price at the next priced round if triggered. A lower discount rate is more founder-friendly, but it may make it harder to attract investors. If a valuation cap and a discount are both set, the SAFE or Convertible Note will convert at the more investor-friendly of the two. "
      secondaryLabel="The most common discount value is 20%, but it typically ranges from 15% to 25%."
    />
  ) : (
    <></>
  );
}

const DiscountField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.Discount]: yup
      .number()
      .nullable()
      .when(RoundFieldNames.RoundType, {
        is: (roundType: string) =>
          [
            RoundTypes.ConvertibleNote,
            RoundTypes.Safe,
            RoundTypes.CrowdSafe,
            RoundTypes.Asa,
          ].includes(roundType as RoundTypes),
        then: (schema) =>
          schema
            .typeError('Must be a number')
            .min(
              DISCOUNT_MIN,
              `Discount rate cannot be less than ${DISCOUNT_MIN}%`,
            )
            .max(
              DISCOUNT_MAX,
              `Discount rate cannot be higher than ${DISCOUNT_MAX}%`,
            )
            .required('Required'),
      }),
  }),
};

export default DiscountField;
