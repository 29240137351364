enum StartupFieldNames {
  AccountEmail = 'accountEmail',
  AccountFirstName = 'accountFirstName',
  AccountLastName = 'accountLastName',
  AccountPhone = 'accountPhone',
  AdditionalTractionInfo = 'additionalTractionInfo',
  AngelList = 'angelList',
  AvgOrderValue = 'avgOrderValue',
  AvgRevenuePerUser = 'avgRevenuePerUser',
  AvgTakeRate = 'avgTakeRate',
  BookingsAmount = 'bookingsAmount',
  BusinessModels = 'businessModels',
  CalendarLink = 'calendarLink',
  CompanyType = 'companyType',
  CrunchBase = 'crunchBase',
  CurrentStage = 'currentStage',
  CustomerAcquisitionCost = 'customerAcquisitionCost',
  CustomerCount = 'customerCount',
  CustomerGrowthRateMom = 'customerGrowthRateMom',
  CustomerRetentionRateMom = 'customerRetentionRateMom',
  DailyActiveUserCount = 'dailyActiveUserCount',
  Discord = 'discord',
  Deck = 'deck',
  DeckChangeReason = 'deckChangeReason',
  DeckChangeType = 'deckChangeType',
  DeckMustValidate = 'deckMustValidate',
  Description = 'description',
  EmployeesCount = 'employeesCount',
  FoundedOn = 'foundedOn',
  FounderIsTechnical = 'founderIsTechnical',
  FounderLinkedIn = 'founderLinkedIn',
  FounderRole = 'founderRole',
  Founders = 'founders',
  Geography = 'geography',
  GrossMargin = 'grossMargin',
  HasIncubator = 'hasIncubator',
  HasInventory = 'hasInventory',
  HasPendingLawsuits = 'hasPendingLawsuits',
  HasPreviousBankruptcies = 'hasPreviousBankruptcies',
  HasPreviousConvictions = 'hasPreviousConvictions',
  HasCustomers = 'hasCustomers',
  HasUsers = 'hasUsers',
  Highlights = 'highlights',
  Icp = 'icp',
  Impacts = 'impacts',
  Incubators = 'incubators',
  Industries = 'industries',
  IpProtections = 'ipProtections',
  LegalEntityType = 'legalEntityType',
  LegalFormationState = 'legalFormationState',
  LegalName = 'legalName',
  LifeTimeValue = 'lifeTimeValue',
  LinkedIn = 'linkedIn',
  Logo = 'logo',
  LogoMustValidate = 'logoMustValidate',
  MonthlyActiveUserCount = 'monthlyActiveUserCount',
  Name = 'name',
  NetMargin = 'netMargin',
  NetMonthlyBurn = 'netMonthlyBurn',
  NetPromoterScore = 'netPromoterScore',
  NetRevenueLastYear = 'netRevenueLastYear',
  NetRevenueRetention = 'netRevenueRetention',
  NetRevenueYTD = 'netRevenueYTD',
  OtherUrl = 'otherUrl',
  PilotsCount = 'pilotsCount',
  PrimaryGrowthDriver = 'primaryGrowthDriver',
  ProductDemoVideoUrl = 'productDemoVideoUrl',
  ProductStatus = 'productStatus',
  ProductType = 'productType',
  Revenue = 'revenue',
  RevenueGrowth = 'revenueGrowth',
  RevenueMonthlyGmv = 'revenueMonthlyGmv',
  RevenueType = 'revenueType',
  Runway = 'runway',
  TeamPresentationVideoUrl = 'teamPresentationVideoUrl',
  TotalAddressableMarket = 'totalAddressableMarket',
  Twitter = 'twitter',
  UserCount = 'userCount',
  UserGrowthRateMom = 'userGrowthRateMom',
  UserRetentionRateMom = 'userRetentionRateMom',
  Website = 'website',
  WeeklyActiveUserCount = 'weeklyActiveUserCount',
}

export const STARTUP_URL_FIELDS = [
  StartupFieldNames.AngelList,
  StartupFieldNames.CalendarLink,
  StartupFieldNames.CrunchBase,
  StartupFieldNames.Discord,
  StartupFieldNames.FounderLinkedIn,
  StartupFieldNames.LinkedIn,
  StartupFieldNames.OtherUrl,
  StartupFieldNames.ProductDemoVideoUrl,
  StartupFieldNames.Twitter,
  StartupFieldNames.Website,
];

export default StartupFieldNames;
