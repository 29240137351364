import { RequestTypes } from './RequestTypes';
import {
  InvestorRequestsIncoming,
  InvestorRequestsOutgoing,
} from '/../libs/shared-types/src/constants/ApiRoutes';

const requests = {
  getOutgoing: (requestType: RequestTypes, query?: string) =>
    InvestorRequestsOutgoing.buildEndpoint(requestType, { query }),
  getIncoming: (requestType: RequestTypes, query?: string) =>
    InvestorRequestsIncoming.buildEndpoint(requestType, { query }),
};

export default {
  requests,
};
