import React from 'react';
import * as yup from 'yup';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import {
  TOTAL_ADDRESSABLE_MKT_MAX,
  TOTAL_ADDRESSABLE_MKT_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import MoneyInput from '../../inputs/MoneyInput';

const TotalAddressableMarketField: FormField = {
  fieldComponent: (
    <MoneyInput
      name={StartupFieldNames.TotalAddressableMarket}
      label="Total Addressable Market"
    />
  ),
  validation: yup.object({
    [StartupFieldNames.TotalAddressableMarket]: yup
      .number()
      .typeError('Must be a number')
      .min(
        TOTAL_ADDRESSABLE_MKT_MIN,
        `Must be greater than or equal to ${TOTAL_ADDRESSABLE_MKT_MIN}`
      )
      .max(
        TOTAL_ADDRESSABLE_MKT_MAX,
        `Enter ${TOTAL_ADDRESSABLE_MKT_MAX} if your total addressable market is greater.`
      ),
  }),
};

export default TotalAddressableMarketField;
