import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { toDashCase } from '/src/util/formatting/strings';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import { FlowlieBadge } from '/src/components/FlowlieIcons';
import DealDetailTimeline from '/src/routes/investor/DealDetail/cards/DealDetailTimeline';
import DealDetailContactCard from '/src/routes/investor/DealDetail/cards/DealDetailContactCard';
import DealDetailDeckCard from '/src/routes/investor/DealDetail/cards/DealDetailDeckCard';
import DealDetailGeneralCard from '/src/routes/investor/DealDetail/cards/DealDetailGeneralCard';
import DealDetailLegalCard from '/src/routes/investor/DealDetail/cards/DealDetailLegalCard';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import DealDetailFoundersCard from '/src/routes/investor/DealDetail/cards/DealDetailFoundersCard';
import DealPreviewDetailHeader from './DealPreviewDetailHeader';
import DeckViewer from '/src/routes/investor/DealDetail/cards/DeckViewer';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';
import DealDetailProductCard from '../../investor/DealDetail/cards/DealDetailProductCard';
import AuthService from '/src/services/AuthService';
import {
  InboxArrowDownIcon,
  PresentationChartBarIcon,
} from '@heroicons/react/20/solid';
import DealDetailDataRoomCard from '../../investor/DealDetail/cards/DealDetailDataRoomCard';
import { VerifyLinkPasswordResponse } from '../../../types/view/APIResponse';
import DealDetailUserCard from '../../investor/DealDetail/cards/DealDetailUserCard';
import DealDetailFinancialCard from '../../investor/DealDetail/cards/DealDetailFinancialCard';
import DealDetailRoundSummaryCard from '../../investor/DealDetail/cards/DealDetailRoundSummaryCard';

interface RequestAccessButtonProps {
  requestAccess?: () => void;
  text: string;
}

function RequestAccessButton({
  requestAccess,
  text,
}: RequestAccessButtonProps): JSX.Element {
  const canRequestAccess = requestAccess !== undefined;
  return (
    <button
      type="button"
      onClick={requestAccess}
      className="button flex items-center"
      disabled={!canRequestAccess}
    >
      <InboxArrowDownIcon className="mr-2 h-5 w-5" />
      {canRequestAccess ? text : 'Request Sent'}
    </button>
  );
}

interface DealPreviewProps {
  partialStartup: PublicStartup;
  requestAccess?: () => void;
  linkType?: LinkTypes | undefined;
  onViewDeck: () => Promise<void>;
  onDownloadDeck: () => Promise<void>;
  onOpenDataRoomModal: () => Promise<void>;
  onVerifyDataRoomPassword: (
    password: string,
  ) => Promise<VerifyLinkPasswordResponse>;
  isViewingDeck: boolean;
  setIsViewingDeck: React.Dispatch<React.SetStateAction<boolean>>;
}
function DealPreview({
  partialStartup,
  requestAccess,
  linkType,
  onViewDeck,
  onDownloadDeck,
  onOpenDataRoomModal,
  onVerifyDataRoomPassword,
  isViewingDeck,
  setIsViewingDeck,
}: DealPreviewProps): JSX.Element {
  const tabs = [{ name: 'Overview' }];

  const currentRound = partialStartup.roundHistory[0];
  return (
    <section className="flex min-h-screen flex-col justify-between pb-5">
      <DealPreviewDetailHeader
        tabs={tabs}
        actionButton={
          linkType === LinkTypes.Flowlink ? (
            <button
              type="button"
              onClick={onViewDeck}
              className="button flex items-center"
            >
              <PresentationChartBarIcon className="mr-2 h-5 w-5" />
              View Deck
            </button>
          ) : (
            <RequestAccessButton
              requestAccess={requestAccess}
              text="Request Access"
            />
          )
        }
        startup={partialStartup}
      />

      <main className="mb-auto">
        <Routes>
          {['', tabs[0].name].map((path) => (
            <Route
              key={path}
              path={toDashCase(path)}
              element={
                <div className="mx-auto mt-8 grid max-w-full grid-cols-1 gap-6 px-1 lg:grid-flow-col-dense lg:grid-cols-3">
                  <span className="lg:hidden">
                    <DealDetailDeckCard
                      startup={partialStartup}
                      onDownloadDeck={onDownloadDeck}
                      onViewDeck={onViewDeck}
                    />
                  </span>
                  <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                    <DealDetailGeneralCard startup={partialStartup} />
                    <DealDetailFoundersCard startup={partialStartup} />
                    <DealDetailProductCard startup={partialStartup} />
                    <DealDetailUserCard startup={partialStartup} />
                    <DealDetailFinancialCard startup={partialStartup} />
                    <DealDetailLegalCard startup={partialStartup} />
                  </div>
                  <aside className="flex flex-col space-y-6">
                    <span className="hidden lg:block">
                      <DealDetailDeckCard
                        startup={partialStartup}
                        onDownloadDeck={onDownloadDeck}
                        onViewDeck={onViewDeck}
                      />
                    </span>
                    <DealDetailRoundSummaryCard
                      currentRound={currentRound}
                      startup={partialStartup}
                    />
                    <DealDetailContactCard startup={partialStartup} />
                    <DealDetailDataRoomCard
                      startup={partialStartup}
                      onVerifyPassword={onVerifyDataRoomPassword}
                      onOpenModal={onOpenDataRoomModal}
                    />
                    <DealDetailTimeline
                      startup={{
                        ...partialStartup,
                        roundHistory:
                          currentRound !== undefined ? [currentRound] : [],
                      }}
                    />
                  </aside>
                </div>
              }
            />
          ))}

          <Route path="*" element={<Navigate to={NOT_FOUND_ROUTE} replace />} />
        </Routes>

        <DeckViewer
          open={isViewingDeck}
          onClose={() => setIsViewingDeck(false)}
          startup={partialStartup}
        />
      </main>

      {!AuthService.isLoggedIn() && (
        <footer>
          <FlowlieBadge />
          <p className="block px-4 py-3 text-center text-2xs text-gray-600">
            This information was provided by the company founders. Please seek
            independent financial advice when considering an investment and do
            not make any investment decision based solely upon any materials
            found on this website. Flowlie Technologies, Inc. has not verified
            the source, information, or data in this document and does not
            warrant its accuracy. Flowlie Technologies, Inc. is not registered
            as a securities broker-dealer or an investment adviser with the U.S.
            Securities and Exchange Commission, the Financial Industry
            Regulatory Authority (“FINRA”) or any state securities regulatory
            authority.
          </p>
        </footer>
      )}
    </section>
  );
}

export default DealPreview;
