import React from 'react';
import { InvestorListItemView } from '../../../types/view/InvestorListView';
import { generateTag } from '/src/util/generateTagCloud';

function InvestorCellIsIntroNeeded({
  investor,
}: {
  investor: InvestorListItemView;
}) {
  return (
    <div className="app-table-row-text">
      {investor.relationship &&
      investor.relationship.isIntroNeeded !== undefined
        ? generateTag(investor.relationship.isIntroNeeded ? 'Yes' : 'No')
        : '-'}
    </div>
  );
}

export default InvestorCellIsIntroNeeded;
