import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import { MEDIUM_TEXTAREA_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import TextCharacterCounter from '../../TextCharacterCounter';
import StartupFieldNames from '/src/enums/StartupFieldNames';

function FieldWithCharCount(): JSX.Element {
  const { values } = useFormikContext<any>();

  const isUploadingDeck =
    values[StartupFieldNames.DeckMustValidate] &&
    values[StartupFieldNames.Deck] &&
    values[StartupFieldNames.Deck] instanceof File &&
    values;

  return (
    isUploadingDeck && (
      <>
        <Field
          component={FormikInput}
          boxType="textarea"
          customStyle="h-28 max-h-28"
          label="What have you changed?"
          secondaryLabel="This is a private note for you, to help you keep track of deck changes"
          name={StartupFieldNames.DeckChangeReason}
          type="text"
          placeholder="Ex: Updated market sizing and competitor analysis slides..."
        />
        <TextCharacterCounter
          textLength={values[StartupFieldNames.DeckChangeReason]?.length ?? 0}
          maxLength={MEDIUM_TEXTAREA_LENGTH_MAX}
        />
      </>
    )
  );
}

const DeckChangeReasonField: FormField = {
  fieldComponent: <FieldWithCharCount />,
  validation: yup.object({
    [StartupFieldNames.DeckChangeReason]: yup
      .string()
      .when(StartupFieldNames.DeckMustValidate, {
        is: (hasChanged: boolean) => hasChanged === true,
        then: (schema) =>
          schema
            .max(
              MEDIUM_TEXTAREA_LENGTH_MAX,
              `Must be at most ${MEDIUM_TEXTAREA_LENGTH_MAX} characters`,
            )
            .required('Required'),
      }),
  }),
};

export default DeckChangeReasonField;
