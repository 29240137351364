import React from 'react';
import * as yup from 'yup';
import { validateNumeric } from '/src/components/utility/Validators';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import MoneyInput from '../../inputs/MoneyInput';

const NetRevenueYTDField: FormField = {
  fieldComponent: (
    <MoneyInput
      label="Net Revenue (YTD)"
      name={StartupFieldNames.NetRevenueYTD}
    />
  ),
  validation: yup.object({
    [StartupFieldNames.NetRevenueYTD]: validateNumeric(
      'RECURRING_MONETARY_MAX'
    ),
  }),
};

export default NetRevenueYTDField;
