import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import { STARTUP_NAME_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';

const NameField: FormField = {
  fieldComponent: (
    <Field
      component={FormikInput}
      label="Startup Name"
      name={StartupFieldNames.Name}
      type="text"
      placeholder="Acme Technologies"
      secondaryLabel="This is your primary marketing name (or DBA), not the full legal company name"
    />
  ),
  validation: yup.object({
    [StartupFieldNames.Name]: yup
      .string()
      .max(
        STARTUP_NAME_LENGTH_MAX,
        `Must be at most ${STARTUP_NAME_LENGTH_MAX} characters`
      )
      .required('Required'),
  }),
};

export default NameField;
