import { isMinDate } from '../time';
import { getPluralSuffix } from './numbers';
import {
  DAY_MS,
  DAY_S,
  HOUR_MS,
  MINUTE_MS,
  MONTH_MS,
  YEAR_MS,
} from '/../libs/shared-types/src/constants/time';
import Logger from '/src/services/logger';

/**
 * Returns current date in yyy-mm-dd
 * @returns string :: yyyy-mm-dd
 */
export function formatCurrentDate(): string {
  const currentDate = new Date();
  return currentDate.toISOString().split('T')[0];
}

export function formatDatePickerInput(time: number | Date): string {
  const today = new Date(time);
  const dd = today.getDate();
  const mm = today.getMonth() + 1;

  const d0 = dd < 10;
  const m0 = mm < 10;

  const yyyy = today.getFullYear();

  return `${yyyy}-${m0 ? '0' : ''}${mm}-${d0 ? '0' : ''}${dd}`;
}

/**
 * This is to be used only for date picker inputs,
 * because the picked date has a time of 00:00 but
 * with the TimeZone set by the browser settings
 * @param date the date to be converted
 * @returns a new date in UTC, but the timezone information is not converted
 */
export function convertDatePickerInputToUtc(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}

/**
 * Formats a UTC date into the local date string
 * or returns - if the date is undefined
 * @param date Date object
 */
export function formatDate(date: Date | undefined): string {
  if (date === undefined || date === null || isMinDate(date)) {
    return '-';
  }
  return date.toLocaleDateString();
}

export function formatDateTime(
  date: Date | undefined,
  includeSeconds = false,
): string {
  if (date === undefined || date === null || isMinDate(date)) {
    return '-';
  }

  if (includeSeconds) {
    return date.toLocaleTimeString();
  }

  return date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' });
}

/**
 * Given a number of milliseconds it returns the equivalent time in different time measuring units.
 * @param timeMs
 * @returns Time equivalences between Days OR Hours OR Minutes OR Seconds
 */
export function millisecondsAsDaysHoursMinutesSeconds(timeMs: number) {
  const seconds = parseInt((timeMs / 1000).toFixed(2), 10);
  const minutes = parseInt((timeMs / MINUTE_MS).toFixed(2), 10);
  const hours = parseInt((timeMs / HOUR_MS).toFixed(2), 10);
  const days = parseInt((timeMs / DAY_MS).toFixed(2), 10);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

/**
 * Converts milliseconds into string format 'dd hh mm ss' and display up the most significant unit of measure
 * @param timeMs Time in milliseconds
 * @returns
 * @example millisecondsAsDaysHoursMinutesSeconds(3 * DAY_MS + 0 * HOUR_MS + 5 * MINUTE_MS + 67 * 1000)
 * => "3d 0h 6m 7s"
 * @example millisecondsAsDaysHoursMinutesSeconds(0 * DAY_MS + 2 * HOUR_MS + 4 * MINUTE_MS + 0 * 1000)
 * => "2h 4m 0s"
 */
export function formatMilliSecondsAsTimeDuration(timeMs: number) {
  const { days, hours, minutes, seconds } =
    millisecondsAsDaysHoursMinutesSeconds(timeMs);

  let duration = `${seconds % 60}s`;
  if (minutes > 0) {
    duration = `${minutes % 60}m ` + duration;
  }
  if (hours > 0) {
    duration = `${hours % 24}h ` + duration;
  }
  if (days > 0) {
    duration = `${days}d ` + duration;
  }

  return duration;
}

export function formatMillisecondsToTime(ms: number): string {
  const { days, hours, minutes, seconds } =
    millisecondsAsDaysHoursMinutesSeconds(ms);
  if (seconds < 60) return `${seconds} Second${getPluralSuffix(seconds)}`;
  if (minutes < 60) return `${minutes} Minute${getPluralSuffix(minutes)}`;
  if (hours < 24) return `${hours} Hour${getPluralSuffix(hours)}`;
  return `${days} Day${getPluralSuffix(days)}`;
}

/**
 * @param date How many days from now until {date}
 * @returns Number of days from now till {date}, or 0 if date is the past
 */
export function dayDiff(date: Date | undefined, dateRef?: Date): number {
  let dateToCompare = new Date();
  if (dateRef) {
    dateToCompare = dateRef;
  }
  // handle edge cases
  if (date === undefined || isMinDate(date)) {
    return 0;
  }

  const seconds = Math.floor((date.getTime() - dateToCompare.getTime()) / 1000);
  const interval = Math.ceil(seconds / DAY_S);

  // Today
  if (seconds < DAY_S && dateToCompare.getDay() === date.getDay()) {
    return 0;
  }

  // handle past dates
  if (interval < -1) {
    return interval;
  }

  return interval;
}

export function formatMsToDays(milliSeconds: number): string {
  const days = milliSeconds / DAY_MS;

  return `${days} day${getPluralSuffix(days)}`;
}

export function formatDaysUntil(date: Date | undefined): string {
  const days = dayDiff(date);

  if (days < 0) {
    return '0 days';
  }

  if (days === 0) {
    return 'Today';
  }

  if (days === 1) {
    return 'Tomorrow';
  }

  return `${days} day${getPluralSuffix(days)}`;
}

/**
 * How long has it been between two dates?
 * @param date the older date
 * @param newerDate the newer date, defaults to new Date() if not explicitly passed
 * @returns A human readable string telling how long ago the older date is from the newer date
 */
export function howLongBetween(date: Date, newerDate = new Date()): string {
  // Take the absolute value so that we cannot obotain a negative time difference
  // in case newerDate is older than date
  const differenceDate = Math.abs(newerDate.getTime() - date.getTime());

  const diffYears = Math.floor(differenceDate / YEAR_MS);
  const diffMonths = Math.floor(differenceDate / MONTH_MS);
  const diffDays = Math.floor(differenceDate / DAY_MS);
  const diffWeeks = Math.floor(diffDays / 7);

  // Today
  if (diffYears === 0 && diffMonths === 0 && diffDays === 0) {
    return 'Today';
  }

  // 1 Day Ago
  if (diffYears === 0 && diffMonths === 0 && diffDays === 1) {
    return 'Yesterday';
  }

  // Less than 7 days ago
  if (diffYears === 0 && diffMonths === 0 && diffDays < 7) {
    return `${diffDays} day${getPluralSuffix(diffDays)} ago`;
  }

  // Less than 30 days ago
  if (diffYears === 0 && diffMonths === 0 && diffDays <= 31) {
    return `${diffWeeks} week${getPluralSuffix(diffWeeks)} ago`;
  }

  // Less than a year ago
  if (diffYears === 0 && diffMonths > 0) {
    return `${diffMonths} month${getPluralSuffix(diffMonths)} ago`;
  }

  // More than a year ago
  if (diffYears > 0) {
    return `${diffYears} year${getPluralSuffix(diffYears)} ${
      diffMonths - 12 * diffYears > 0
        ? `and ${diffMonths - 12 * diffYears} month${getPluralSuffix(
            diffMonths - 12 * diffYears,
          )}`
        : ''
    } ago`;
  }

  // We should never get here
  Logger.error('howLongBetween DATE_ERROR', date.toLocaleDateString());
  return ' - ';
}
