import { SubscriptionBillingInterval } from '/../libs/shared-types/src/types/model/Subscription';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { BillingFrequency } from '/../libs/shared-types/src/constants/BillingFrequency';
import {
  DEFAULT_CREDITS,
  SubscriptionTiers,
  getSubscriptionTierCredits,
} from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { getSubscriptionPrice } from '/src/services/SubscriptionPricingService';

enum FeatureType {
  Bonus = 'Bonus',
  LimitedTimeBonus = 'Limited Time Bonus',
  ComingSoon = 'Coming Soon',
  New = 'New',
}

interface Feature {
  name: string;
  type?: FeatureType;
}

export interface SubscriptionTierView {
  name: SubscriptionTiers;
  id: string;
  href: string;
  price: {
    [BillingFrequency.Monthly]: string;
    [BillingFrequency.Annually]: string;
    annuallyTotal: string;
  };
  annualBillingDiscount: number;
  description: string;
  features: Feature[];
  isMostPopular: boolean;
}

export const StartupTiers: SubscriptionTierView[] = [
  {
    name: SubscriptionTiers.StartupFree,
    id: 'tier-startupFree',
    href: '#',
    price: {
      [BillingFrequency.Monthly]: '$0',
      [BillingFrequency.Annually]: '$0',
      annuallyTotal: '$0',
    },
    annualBillingDiscount: 0,
    description:
      'For indie hackers and solopreneurs who want to start planning their round and securely share their fundraising pitch deck.',
    features: [
      { name: 'Customizable company one-pager' },
      { name: 'Secure link to share deck and one-pager' },
      { name: 'Track the 5 most recent view sessions' },
      { name: 'Track the 5 most recent investors engaged' },
      { name: 'Limited deck version history (2 most recent)' },
      { name: 'Limited investor database (top 10 only)' },
      { name: 'Round manager with progress tracker' },
      { name: `${DEFAULT_CREDITS} AI credits` },
      { name: 'Generate an effective company description and elevator pitch' },
      {
        name: 'Generate a summary of the Ideal Customer Profile for your product',
      },
    ],
    isMostPopular: false,
  },
  {
    name: SubscriptionTiers.StartupPro,
    id: 'tier-startupPro',
    href: '#',
    price: {
      [BillingFrequency.Monthly]: `$${
        getSubscriptionPrice(SubscriptionTiers.StartupPro).monthly
      }`,
      [BillingFrequency.Annually]: `$${
        getSubscriptionPrice(SubscriptionTiers.StartupPro).annually
      }`,
      annuallyTotal: `$${
        getSubscriptionPrice(SubscriptionTiers.StartupPro).annuallyTotal
      }`,
    },
    annualBillingDiscount: 25,
    description:
      'For early-stage founders who are serious about raising a funding round from institutional VCs, individual angels, and family offices.',
    features: [
      { name: 'All Free features' },
      {
        name: `${getSubscriptionTierCredits(
          SubscriptionTiers.StartupPro,
          SubscriptionBillingInterval.Month,
          1,
        )} AI credits per month`,
      },
      { name: 'Unlimited view session tracking' },
      { name: 'Unlimited deck version control and analytics' },
      { name: 'Password-protected data room link and checklist' },
      { name: 'Dilution calculator' },
      { name: 'Investor fit scoring' },
      // { name: 'Data room checklist to prepare for due diligence' },
      // { name: 'Export view sessions and emails to CSV' },
      { name: 'Full investor database with contact info' },
      { name: 'Investor Lists to organize outreach' },
      {
        name: 'Investor Pipeline with customizable stages',
        type: FeatureType.New,
      },
      { name: 'Import your own investor records' },
      // { name: 'Search, sort and filter tables' },
      // { name: 'Over $1k in discounts & perks' },
      { name: 'Priority email support (48h response time)' },
      {
        name: 'Legal Entity Verification (US & Canada based companies)',
        type: FeatureType.ComingSoon,
      },
      {
        name: '50% off our Fundraising Masterclass',
        type: FeatureType.LimitedTimeBonus,
      },
    ],
    isMostPopular: true,
  },
  {
    name: SubscriptionTiers.StartupPremium,
    id: 'tier-startupPremium',
    href: '#',
    price: {
      [BillingFrequency.Monthly]: '',
      [BillingFrequency.Annually]: '',
      annuallyTotal: '',
    },
    annualBillingDiscount: 0,
    description:
      'For founders who want to: reduce their fundraising workload by over 50% and stay focused on growth; avoid costly mistakes and common pitfalls, so they can raise again in the future; and close the right investors at the best terms possible.',
    features: [
      { name: 'All Pro features' },
      {
        name: `${getSubscriptionTierCredits(
          SubscriptionTiers.StartupPremium,
          SubscriptionBillingInterval.Month,
          1,
        )} AI credits per month`,
      },
      { name: '1 hour white-glove onboarding call' },
      { name: '45 min monthly strategy call' },
      { name: 'Pitch deck & one-pager review' },
      { name: 'Data room audit' },
      { name: 'Company valuation report' },
      { name: 'Dedicated email support (24h response time)' },
      {
        name: 'Dedicated analyst work for fundraising and investor relations, including:',
      },
      { name: '-> Outreach messaging optimization' },
      { name: '-> Target investor list building' },
      { name: '-> Multi-round dilution modeling' },
      { name: '-> KPI tracking' },
      { name: '-> Financial model review' },
      { name: '-> Term sheet review' },
      { name: '-> Investor update review' },
      {
        name: 'Free ticket to our Fundraising Masterclass',
        type: FeatureType.Bonus,
      },
    ],
    isMostPopular: false,
  },
];

export const InvestorTiers: SubscriptionTierView[] = [
  {
    name: SubscriptionTiers.InvestorFree,
    id: 'tier-investorFree',
    href: '#',
    price: {
      [BillingFrequency.Monthly]: '$0',
      [BillingFrequency.Annually]: '$0',
      annuallyTotal: '',
    },
    annualBillingDiscount: 0,
    description:
      'For angel investors who want to track dealflow and their investor network in one place.',
    features: [
      { name: 'Shareable profile' },
      { name: 'Deal intake form' },
      { name: 'Standardized deal one-pagers' },
      { name: 'Deal screening' },
      { name: 'Investor network management' },
    ],
    isMostPopular: false,
  },
  {
    name: SubscriptionTiers.InvestorPro,
    id: 'tier-investorPro',
    href: '#',
    price: {
      [BillingFrequency.Monthly]: `$${
        getSubscriptionPrice(SubscriptionTiers.InvestorPro).monthly
      }`,
      [BillingFrequency.Annually]: `$${
        getSubscriptionPrice(SubscriptionTiers.InvestorPro).annually
      }`,
      annuallyTotal: `$${
        getSubscriptionPrice(SubscriptionTiers.InvestorPro).annuallyTotal
      }`,
    },
    annualBillingDiscount: 20,
    description:
      'A Digital Analyst for angel investors and venture associates who want to close more of the right deals using data analytics and AI.',
    features: [
      { name: 'All Free features' },
      { name: 'Fit scoring' },
      { name: 'AI-powered deal sharing' },
      { name: 'Streamlined portfolio tracking' },
      { name: 'Priority email support (48h response time)' },
    ],
    isMostPopular: true,
  },
  {
    name: SubscriptionTiers.InvestorTeam,
    id: 'tier-investorTeam',
    href: '#',
    price: {
      [BillingFrequency.Monthly]: '',
      [BillingFrequency.Annually]: '',
      annuallyTotal: '',
    },
    annualBillingDiscount: 0,
    description:
      'For angel investing groups, syndicates, investor communities, and small funds that want to set up scalable solutions for investment processes.',
    features: [
      { name: 'All Pro Features' },
      { name: '1 hour white-glove onboarding call' },
      { name: 'Dedicated email support (24h response time)' },
      { name: 'Centralized billing for your team' },
    ],
    isMostPopular: false,
  },
];
