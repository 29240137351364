import API from '../middleware/API';
import Logger from './logger';
import { PublicGetShareTokenMetadata } from '/../libs/shared-types/src/constants/ApiRoutes';
import { ParsedInvitationToken } from '/../libs/shared-types/src/types/Tokens';

export async function validateToken(
  shareToken: string | undefined
): Promise<ParsedInvitationToken | undefined> {
  if (shareToken === undefined) {
    return undefined;
  }

  try {
    const result = await API.get(
      PublicGetShareTokenMetadata.buildEndpoint(shareToken),
      false
    );
    if (result === null) {
      return undefined;
    }
    return result;
  } catch (error: any) {
    Logger.error('validateToken: ' + error.message);
    return undefined;
  }
}
