import React from 'react';
import RoundCard from '/src/components/RoundCard';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import Alert from '/src/components/notifications/Alert';
import { Link } from 'react-router-dom';
import { STARTUP_ROUND_MANAGER_ROUTE } from '/src/constants/Routes';
import RoundHistoryKpis from '/src/components/RoundHistoryKpis';

interface RoundHistoryProps {
  rounds: RoundView[];
}

function RoundHistory({ rounds }: RoundHistoryProps): JSX.Element {
  return (
    <section>
      <h3 className="sr-only">Funding History</h3>

      {rounds.length === 0 && (
        <Alert
          alertType={'Info'}
          color={'blue'}
          content={
            <p>
              You do not have a fundraising history yet. When you are
              ready,&nbsp;
              <Link
                to={STARTUP_ROUND_MANAGER_ROUTE}
                className="hyperlink font-bold underline"
              >
                open a round
              </Link>
              &nbsp;to inform investors that you are raising and on what terms.
            </p>
          }
          isShown={true}
          canDismiss={false}
        />
      )}

      {rounds.length !== 0 && <RoundHistoryKpis roundHistory={rounds} />}

      {rounds.map((round: RoundView) => (
        <RoundCard key={round.openedOn.toISOString()} round={round} />
      ))}
    </section>
  );
}

export default RoundHistory;
