import React from 'react';

import { AggregatedStartupReferral } from '/../libs/shared-types/src/types/view/APIResponse';
import PaginatedTable from '/src/components/table/PaginatedTable';
import ReferredStartupTableRow from './ReferredStartupTableRow';

const headerValues = [
  { element: <span>Company Name</span> },
  { element: <span>Founder Name</span> },
  { element: <span>Joined</span> },
];

function ReferredStartupsTable({
  referrals,
}: {
  referrals: AggregatedStartupReferral[];
}): JSX.Element {
  if (referrals.length === 0) {
    return <p>You have not referred any startups.</p>;
  }

  return (
    <div className="flex flex-col">
      <PaginatedTable
        headers={headerValues}
        rowsPerPage={5}
        rowComponents={referrals.map((referral) => (
          <ReferredStartupTableRow referral={referral} key={referral.id} />
        ))}
      />
    </div>
  );
}

export default ReferredStartupsTable;
