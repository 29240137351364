import React from 'react';

import { generateTag, generateTagCloud } from '/src/util/generateTagCloud';
import DateString from '/src/components/utility/DateString';
import { howLongBetween } from '/src/util/formatting/dates';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import { getCountryCode } from '/../libs/shared-types/src/constants/SelectOptions/StartupGeographyOptions';
import FlagEmoji from '/src/components/utility/FlagEmoji';

export interface DealDetailGeneralCardProps {
  startup: PublicStartup;
}

function DealDetailGeneralCard({
  startup,
}: DealDetailGeneralCardProps): JSX.Element {
  const countryCode = getCountryCode(startup.geography);
  return (
    <section aria-labelledby="startup-general-information-title">
      <div className="rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            General
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Industries</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {generateTagCloud(startup.industries)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Stage</dt>
              {generateTag(startup.currentStage, false)}
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Business Model
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {generateTagCloud(startup.businessModels)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Headquarters
              </dt>
              <dd className="mt-1 flex items-center text-sm text-gray-900">
                {startup.geography}
                {countryCode && (
                  <FlagEmoji
                    className="ml-2 h-7 w-7"
                    countryCode={countryCode}
                  />
                )}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Founded On</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <DateString date={startup.foundedOn} tooltipPosition="bottom" />
                &nbsp;({howLongBetween(startup.foundedOn)})
              </dd>
            </div>
            {startup.employeesCount && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Number of Employees
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {startup.employeesCount}
                </dd>
              </div>
            )}
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Special Impact &amp; Team Diversity
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {generateTagCloud(startup.impacts)}
              </dd>
            </div>
            {startup.hasIncubator !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Incubators &amp; Accelerators
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {!startup.hasIncubator && generateTag('Never participated')}

                  {startup.hasIncubator &&
                    (!startup.incubators || startup.incubators.length === 0) &&
                    generateTag('Has participated')}

                  {startup.hasIncubator &&
                    startup.incubators &&
                    startup.incubators.length > 0 &&
                    generateTagCloud(startup.incubators)}
                </dd>
              </div>
            )}

            {startup.description && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Description
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {startup.description}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
}

export default DealDetailGeneralCard;
