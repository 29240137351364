import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import { LONG_TEXTAREA_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import TextCharacterCounter from '../../TextCharacterCounter';
import { GenAiButtonWithModal } from '../../GenAiButton';
import { GenAiTransactionTypes } from '/../libs/shared-types/src/constants/GenAiTransactionTypes';

function FieldWithCharCount(): JSX.Element {
  const { values } = useFormikContext<any>();

  return (
    <>
      <div className="relative">
        <span className="absolute top-2 right-0">
          <GenAiButtonWithModal
            feedbackType={GenAiTransactionTypes.FeedbackCompanyDescription}
            valueToGetFeedbackOn={values[StartupFieldNames.Description]}
            text={'Improve'}
            tooltipText={'Let Flowlie AI suggest improvements'}
            disabled={
              values[StartupFieldNames.Description] === undefined ||
              values[StartupFieldNames.Description].trim() === ''
            }
          />
        </span>
        <Field
          component={FormikInput}
          boxType="textarea"
          customStyle="h-28 max-h-28"
          label="Brief Description"
          secondaryLabel="Investors want to understand what your company does in 3-4 sentences. Be specific."
          name={StartupFieldNames.Description}
          tooltip={
            <div>
              You must cover 4 key points:
              <ol className="list-inside list-decimal">
                <li>what you do (product)</li>
                <li>who is it for (customers/market)</li>
                <li>why they need it (problem/solution)</li>
                <li>how you are different (insight/secret sauce)</li>
              </ol>
              <br />
              Avoid buzzwords, jargon, acronyms, marketing-speak, and any
              ambiguous terms.
              <br />A narrow description of your project is better than trying
              to describe it in full detail and risk losing the reader
              completely.
            </div>
          }
          type="text"
          placeholder="Example: We're making open-source software to collaborate on code. It started as 'run your own GitHub' that most users deploy on their own server(s). GitLab allows you to version control code including pull/merge requests, forking, and public projects. It also includes project wikis and an issue tracker. Over 100k organizations around the world use it."
        />
        <TextCharacterCounter
          textLength={values[StartupFieldNames.Description]?.length ?? 0}
          maxLength={LONG_TEXTAREA_LENGTH_MAX}
        />
      </div>
    </>
  );
}

const DescriptionField: FormField = {
  fieldComponent: <FieldWithCharCount />,
  validation: yup.object({
    [StartupFieldNames.Description]: yup
      .string()
      .max(
        LONG_TEXTAREA_LENGTH_MAX,
        `Must be at most ${LONG_TEXTAREA_LENGTH_MAX} characters`
      ),
  }),
};

export default DescriptionField;
