import React from 'react';
import { EnvironmentTypes } from '/../libs/shared-types/src/constants/EnvironmentTypes';
import { localStorageGetDebugMode } from '/src/middleware/LocalStorage';

interface DebugRenderProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

function DebugRender({ children, className }: DebugRenderProps): JSX.Element {
  const isDebugModeEnabled = localStorageGetDebugMode();
  if (
    process.env.REACT_APP_ENV === EnvironmentTypes.Production ||
    (process.env.REACT_APP_ENV === EnvironmentTypes.Development &&
      !isDebugModeEnabled) ||
    (process.env.REACT_APP_ENV === EnvironmentTypes.Local &&
      !isDebugModeEnabled)
  ) {
    return <></>;
  }
  return <div className={className}>{children}</div>;
}

export default DebugRender;
