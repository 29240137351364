import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import { MEDIUM_TEXTAREA_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import TextCharacterCounter from '../../TextCharacterCounter';
import InvestorFlowlinkFormFieldNames from '/src/enums/InvestorFlowlinkFormFieldNames';
import { getBasePromoUrl } from '/src/util/urls';

function FieldWithCharCount(): JSX.Element {
  const { values, errors } = useFormikContext<any>();

  return (
    <>
      <Field
        component={FormikInput}
        boxType="textarea"
        customStyle="h-28 max-h-28"
        label="You can add a message to personalize your request"
        name={InvestorFlowlinkFormFieldNames.ConnectionMessage}
        type="text"
        placeholder="Ex: It was great to meet you at..."
      />
      <TextCharacterCounter
        textLength={
          values[InvestorFlowlinkFormFieldNames.ConnectionMessage]?.length ?? 0
        }
        maxLength={MEDIUM_TEXTAREA_LENGTH_MAX}
      />
      {errors[InvestorFlowlinkFormFieldNames.ConnectionMessage] ===
        'Do not add a DocSend link.' && (
        <div className="mt-2 text-sm text-gray-700">
          By continuing, you will create a Flowlie account and be able to share
          both your company one-pager and deck with a single link.{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${getBasePromoUrl()}/en/compare/docsend`}
            className="hyperlink"
          >
            Learn about how Flowlie replaces Docsend.
          </a>
        </div>
      )}
    </>
  );
}

const ConnectionMessageField: FormField = {
  fieldComponent: <FieldWithCharCount />,
  validation: yup.object({
    [InvestorFlowlinkFormFieldNames.ConnectionMessage]: yup
      .string()
      .nullable()
      .test('docsendNotAllowed', 'Do not add a DocSend link.', (value) => {
        if (value && value.includes('docsend.com')) {
          return false;
        }
        return true;
      })
      .max(
        MEDIUM_TEXTAREA_LENGTH_MAX,
        `Must be at most ${MEDIUM_TEXTAREA_LENGTH_MAX} characters`
      ),
  }),
};

export default ConnectionMessageField;
