import * as yup from 'yup';
import { WEBSITE_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';

/**
 * Regular expression to check if a string is a valid URL.
 *
 * The regular expression checks for the following criteria:
 * - Must not contain any whitespace
 * - Starts with an optional "http:" or "https:" followed by "//"
 * - Contains an optional "www."
 * - Contains valid characters (alphanumeric and -)
 * - Has a valid domain name (contains at least one dot)
 * - Allows optional path and query parameters
 *
 * @note URL validation with regex is not a bullet-proof solution, it's not able to check if the domain is valid or if the URL is accessible and it is not able to cover all possible variations of URLs.
 *
 * @example
 * const url = 'https://www.google.com'
 * const isValid = URL_REGEX.test(url) // true
 */
export const URL_REGEX = /^(https?:\/\/)?(www\.)?[\w-]+(\.[\w-]+)+([/?#]\S*)?$/;

const BASE_SCHEMA = yup
  .string()
  .max(WEBSITE_LENGTH_MAX, `Must be at most ${WEBSITE_LENGTH_MAX} characters`)
  .matches(URL_REGEX, 'The URL is not valid');

const REQUIRED_SCHEMA = BASE_SCHEMA.required('Required');
const NULLABLE_SCHEMA = BASE_SCHEMA.nullable();

const websiteSchema = (
  required: boolean,
):
  | yup.StringSchema<string | undefined | null, Record<string, any>>
  | yup.StringSchema<
      string | undefined | null,
      Record<string, any>,
      string | undefined | null
    > => {
  if (required) return REQUIRED_SCHEMA;
  // if it is not required it should be nullable
  return NULLABLE_SCHEMA;
};

export default websiteSchema;
