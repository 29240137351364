import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import NumericInput from '../../inputs/NumericInput';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.HasUsers] ? (
    <NumericInput
      label="Daily Active Users"
      name={StartupFieldNames.DailyActiveUserCount}
    />
  ) : (
    <></>
  );
}

const DailyActiveUserCountField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.DailyActiveUserCount]: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .min(0)
      .when(StartupFieldNames.WeeklyActiveUserCount, {
        is: (userCount: number) => userCount !== undefined && userCount > 0,
        then: (schema) =>
          schema.max(
            yup.ref(StartupFieldNames.WeeklyActiveUserCount),
            'You cannot have more daily users than weekly users',
          ),
      }),
  }),
};

export default DailyActiveUserCountField;
