import { FormikValues } from 'formik';
import API from '../middleware/API';
import { updateHeapStartupUser } from '../middleware/Heap';
import { Posthog } from '../middleware/Posthog';
import { updateFile } from '../util/FileHelpers';
import { convertDatePickerInputToUtc } from '../util/formatting/dates';
import { removeUnsetOptionalValues } from '../util/forms';
import Logger from './logger';
import { StartupUpdate } from '/../libs/shared-types/src/constants/ApiRoutes';
import { Startup } from '/../libs/shared-types/src/types/model/Startup';
import { cleanFoundersData } from '/src/components/StartupForm/StartupFormValuesCleaner';
import { Highlight } from '/../libs/shared-types/src/types/model/Highlight';

export async function updateStartup(
  formValues: FormikValues,
  originalLogoKey: string
) {
  // Build the Startup object that must be posted
  const updatedData = removeUnsetOptionalValues(formValues);
  const startupProfile: Startup = {
    ...updatedData,

    // Date picker inputs must be converted to a UTC date
    foundedOn: convertDatePickerInputToUtc(new Date(formValues.foundedOn)),

    // Clean up founders array & upload profile Pics
    founders: await cleanFoundersData(formValues),

    highlights:
      formValues.highlights !== null && formValues.highlights !== undefined
        ? formValues.highlights?.map((highlight: Highlight) => {
            return {
              ...highlight,
              achievedOn: convertDatePickerInputToUtc(
                new Date(highlight.achievedOn)
              ),
            };
          })
        : null,
  };

  // Check if a new logo was uploaded
  if (formValues.logo !== undefined) {
    const logoKey = await updateFile(formValues.logo, originalLogoKey);
    if (logoKey === undefined) {
      // The key is invalid
      throw Error('Error: Logo upload unsuccessful');
    }
    // We have a valid key, so add it to the startup object
    startupProfile.logoKey = logoKey;
  }

  // NOTE: startupProfile contains the properties
  // "logoMustValidate" and "deckMustValidate" but they will be ignored by API
  try {
    await API.put(StartupUpdate.buildEndpoint(), startupProfile);
    updateHeapStartupUser(startupProfile);
    const posthogClient = new Posthog();
    posthogClient.addUserProperties({
      company_name: startupProfile.name,
    });
    return await Promise.resolve('Success');
  } catch (error: any) {
    Logger.error('Could not update startup', startupProfile);
    throw Error(error.message);
  }
}
