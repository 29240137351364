import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import { EMPLOYEE_COUNT_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

const EmployeesCountField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      label="Number of Employees"
      isClearable
      name={StartupFieldNames.EmployeesCount}
      options={EMPLOYEE_COUNT_OPTIONS}
      placeholder="Select range..."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.EmployeesCount]: yup.string().nullable(),
  }),
};

export default EmployeesCountField;
