import React from 'react';
import {
  generateTagCloud,
  generateWarningYesNoTag,
} from '/src/util/generateTagCloud';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';

interface DealDetailLegalCardProps {
  startup: PublicStartup;
}

function DealDetailLegalCard({
  startup,
}: DealDetailLegalCardProps): JSX.Element {
  // This card is optional so we must check that at last one value is defined
  if (
    startup.legalEntityType ||
    startup.legalFormationState ||
    startup.legalName ||
    startup.companyType ||
    (startup.ipProtections && startup.ipProtections.length > 0) ||
    startup.hasPreviousBankruptcies !== undefined ||
    startup.hasPendingLawsuits !== undefined ||
    startup.hasPreviousConvictions !== undefined
  ) {
    return (
      <section>
        <div className="rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Legal Structure
            </h3>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              {startup.legalName && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Legal Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {startup.legalName}
                  </dd>
                </div>
              )}
              {startup.companyType && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Company Type
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {startup.companyType}
                  </dd>
                </div>
              )}
              {startup.legalEntityType && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Entity Type
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {startup.legalEntityType}
                  </dd>
                </div>
              )}
              {startup.legalFormationState && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Formation State
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {startup.legalFormationState}
                  </dd>
                </div>
              )}
              {startup.ipProtections && startup.ipProtections.length > 0 && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    IP Protections
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateTagCloud(startup.ipProtections)}
                  </dd>
                </div>
              )}
              {startup.hasPreviousBankruptcies !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Previous Bankruptcies
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateWarningYesNoTag(
                      startup.hasPreviousBankruptcies ? 'Yes' : 'No',
                    )}
                  </dd>
                </div>
              )}
              {startup.hasPendingLawsuits !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Pending Lawsuits
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateWarningYesNoTag(
                      startup.hasPendingLawsuits ? 'Yes' : 'No',
                    )}
                  </dd>
                </div>
              )}
              {startup.hasPreviousConvictions !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Previous Convictions
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateWarningYesNoTag(
                      startup.hasPreviousConvictions ? 'Yes' : 'No',
                    )}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </div>
      </section>
    );
  }
  return <></>;
}

export default DealDetailLegalCard;
