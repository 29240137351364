import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';

type DropdownMultiCheckBoxesProps = {
  label: string;
  button?: JSX.Element;
  disabled?: boolean;
  checkboxes: { label: string; value: boolean }[];
  onCheckboxesChange: (checkboxStates: { [label: string]: boolean }) => void;
};

function DropdownMultiCheckBoxes({
  label,
  button,
  disabled,
  checkboxes,
  onCheckboxesChange,
}: DropdownMultiCheckBoxesProps): JSX.Element {
  const [checkboxStates, setCheckboxStates] = useState<Record<string, boolean>>(
    Object.fromEntries(
      checkboxes.map((checkbox) => [checkbox.label, checkbox.value]),
    ),
  );

  function handleCheckboxChange(label: string) {
    return function (e: React.ChangeEvent<HTMLInputElement>) {
      const newCheckboxStates = {
        ...checkboxStates,
        [label]: e.target.checked,
      };
      setCheckboxStates(newCheckboxStates); // Update state
      onCheckboxesChange(newCheckboxStates); // Call parent function with new state
    };
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      {button ? (
        button
      ) : (
        <Menu.Button
          className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          disabled={disabled}
        >
          {
            // If any checkbox is checked, show a badge with the number of checked checkboxes
            Object.values(checkboxStates).some((x) => x) && (
              <span className="absolute right-2 top-1 inline-flex -translate-y-1/2 translate-x-1/2 transform items-center justify-center rounded-full bg-blue-600 px-1.5 py-1 text-2xs font-bold leading-none text-white">
                {Object.values(checkboxStates).filter((x) => x).length}
              </span>
            )
          }
          {label}
          <ChevronUpDownIcon
            className="-mr-1 ml-2 h-5 w-5"
            aria-hidden="true"
          />
        </Menu.Button>
      )}

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="space-y-3 p-3">
            {checkboxes.map((checkbox) => (
              <div className="relative flex items-start" key={checkbox.label}>
                <div className="flex h-6 items-center">
                  <input
                    id={checkbox.label}
                    name={checkbox.label}
                    type="checkbox"
                    aria-describedby={`${checkbox.label}-description`}
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                    checked={checkboxStates[checkbox.label]}
                    onChange={handleCheckboxChange(checkbox.label)}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor={checkbox.label}
                    className="font-medium text-gray-900"
                  >
                    {checkbox.label}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default DropdownMultiCheckBoxes;
