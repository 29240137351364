import React from 'react';
import { EXAMPLE_STARTUP } from '/../libs/shared-types/src/constants/UserActivation/MockData/ExampleStartup';
import InvestorActivationTours from '/../libs/shared-types/src/constants/UserActivation/InvestorTours';
import ActivationTour, { ActivationStep } from './ActivationTour';
import {
  SCREENING_ROUTE,
  INVESTOR_FLOWLINK_MANAGER_ROUTE,
  ACTIVE_DEALFLOW_ROUTE,
  INVESTOR_CONTACTS_ROUTE,
} from '/src/constants/Routes';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import { InvestorUserActivationTours } from '/../libs/shared-types/src/constants/ApiRoutes';

export const NewInvestorFirstLogInSteps: ActivationStep[] = [
  {
    redirectUrl: SCREENING_ROUTE,
    target: 'body',
    content:
      'Welcome to Flowlie! This interactive walkthrough will introduce you to the main features of our app.',
    placement: 'center',
    disableBeacon: true,
    locale: {
      next: 'Give me a quick tour!',
    },
    showProgress: false,
  },
  {
    redirectUrl: INVESTOR_FLOWLINK_MANAGER_ROUTE,
    target: '.joyride-flowLinkManager',
    content: (
      <div className="text-left">
        <header>FlowLink is your new smart intake form:</header>
        <ul className="mt-1.5 list-inside list-disc space-y-1 pl-3">
          <li>Send it to startups to request their deck and company details</li>
          <li>Send it to investors to connect with them on Flowlie</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
    placement: 'bottom',
  },
  {
    redirectUrl: SCREENING_ROUTE,
    target: '.joyride-screeningFirstRow',
    content:
      'This is where startups land after they submit their deck through your FlowLink. It acts like an inbox where you can screen incoming deals',
    disableBeacon: true,
    placement: 'bottom',
  },
  {
    redirectUrl: `/app/deal-detail/${EXAMPLE_STARTUP._id}`,
    target: '.joyride-dealDetail',
    content:
      'Access detailed information about the startup and view their deck. You can choose to pass on it, or add it to your active dealflow.',
    disableBeacon: true,
    placement: 'bottom',
  },
  {
    redirectUrl: ACTIVE_DEALFLOW_ROUTE,
    target: '.joyride-activeDealflow',
    content:
      'This is your due diligence pipeline. You can can customize the stages to suit your process by clicking this button.',
    disableBeacon: true,
    placement: 'top',
  },
  {
    redirectUrl: INVESTOR_CONTACTS_ROUTE,
    target: '.joyride-contacts',
    content:
      'After you connect with investors, your network will show up here. View their investment thesis and easily share deals with Flowlie’s smart suggestions',
    disableBeacon: true,
    placement: 'auto',
    locale: {
      last: 'Complete',
    },
  },
];

export function NewInvestorFirstLogInTour(): JSX.Element {
  function onTourFinished() {
    // This is a bit of hack
    // Reload the page to reset the state of Joyride, so that scrolling is not "stuck"
    window.location.replace(SCREENING_ROUTE);
  }
  return (
    <>
      <ActivationTour
        localStorageKey={
          LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING
        }
        steps={NewInvestorFirstLogInSteps}
        tourApiRoute={InvestorUserActivationTours.buildEndpoint()}
        tourId={InvestorActivationTours.NewUserFirstLogIn}
        onTourFinished={onTourFinished}
      />
    </>
  );
}
