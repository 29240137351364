import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';

const emptyRecordInvestment = {
  [RecordInvestmentFieldNames.Amount]: '',
  [RecordInvestmentFieldNames.CarryCharged]: '',
  [RecordInvestmentFieldNames.DealRating]: 0,
  [RecordInvestmentFieldNames.HasRightsInformation]: '',
  [RecordInvestmentFieldNames.HasRightsProRata]: '',
  [RecordInvestmentFieldNames.IncorrectRoundFeedback]: '',
  [RecordInvestmentFieldNames.IsLead]: '',
  [RecordInvestmentFieldNames.IsRoundInformationCorrect]: '',
  [RecordInvestmentFieldNames.Reasons]: '',
};

export default emptyRecordInvestment;
