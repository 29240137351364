import React from 'react';

import { ActivationTaskView } from '/../libs/shared-types/src/types/view/ActivationTaskView';
import { Link } from 'react-router-dom';
import { STARTUP_DASHBOARD_ROUTE } from '/src/constants/Routes';

interface StartupActivationTasksProps {
  tasks: ActivationTaskView[];
}

function StartupActivationTasks({
  tasks,
}: StartupActivationTasksProps): JSX.Element {
  const isAllTasksCompleted = tasks.every((x) => x.isCompleted);
  if (isAllTasksCompleted) {
    return <></>;
  }

  const completedCount = tasks.filter((x) => x.isCompleted).length;

  return (
    <section className="mt-3 border-t border-gray-200 px-4 dark:border-gray-800">
      <h3 className="pt-4 pb-3 text-sm font-medium text-gray-700 dark:text-gray-100">
        Get started on Flowlie:
      </h3>
      <div
        className="space-y-2"
        role="group"
        aria-labelledby="projects-headline"
      >
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div
            className="h-2 rounded-full bg-blue-600"
            style={{ width: `${(completedCount / tasks.length) * 100}%` }}
          />
        </div>
        <div className="mt-2 flex justify-between">
          <p className="text-sm font-medium text-gray-700 dark:text-gray-100">
            {completedCount}/{tasks.length} complete
          </p>
          <Link to={STARTUP_DASHBOARD_ROUTE} className="hyperlink text-sm">
            View Tasks
          </Link>
        </div>
      </div>
    </section>
  );
}

export default StartupActivationTasks;
