// If items in Enum ever change order you must update getHeapInvStage() in Heap.ts
export enum RoundStages {
  Angel = 'Angel',
  PreSeed = 'Pre-Seed',
  Seed = 'Seed',
  SeriesA = 'Series A',
  SeriesB = 'Series B',
  SeriesC = 'Series C',
  SeriesD = 'Series D',
  SeriesE = 'Series E',
  SeriesF = 'Series F',
}

export const ALL_ROUNDS = 'All Rounds';
