import {
  AccountTypes,
  PosthogAccountTypes,
  PosthogAccountTypesEnum,
} from '/../libs/shared-types/src/constants/AccountTypes';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import { OnboardingFlows } from '/../libs/shared-types/src/constants/OnboardingFlows';

interface PosthogUserProperties {
  name: string;
  email: string;
  invited_by_name: string;
  company_name: string;
  mongoid_onboarded: string;
  mongoid_unconfirmed: string;
  type: PosthogAccountTypes;
  sub_type: PosthogAccountTypes;
  onboarding_flow: OnboardingFlows;
  user_activation_score: number;
}

interface PosthogClient {
  identify: (email: string) => void;
  people: {
    set: (x: Partial<PosthogUserProperties>) => void;
    set_once: (x: Partial<PosthogUserProperties>) => void;
  };
}

export class Posthog {
  isPosthogAvailable = false;
  isLocalEnvironment = false;
  posthog: PosthogClient;

  constructor() {
    this.posthog = (window as any).posthog;
    this.isPosthogAvailable = (window as any).posthog !== undefined;
  }

  static getInvestorSubType(
    investorAccountType?: InvestorTypes
  ): PosthogAccountTypes {
    if (investorAccountType === InvestorTypes.Angel) {
      return PosthogAccountTypesEnum.Angel;
    }

    if (investorAccountType === InvestorTypes.FamilyOffice) {
      return PosthogAccountTypesEnum.FamilyOffice;
    }

    if (investorAccountType === InvestorTypes.VC) {
      return PosthogAccountTypesEnum.VC;
    }

    if (investorAccountType === InvestorTypes.CorporateVC) {
      return PosthogAccountTypesEnum.CorporateVC;
    }

    if (investorAccountType === InvestorTypes.Syndicator) {
      return PosthogAccountTypesEnum.Syndicate;
    }

    return AccountTypes.Investor;
  }

  identify(email: string) {
    if (!this.isPosthogAvailable) {
      return;
    }
    this.posthog.identify(email);
  }

  addUserProperties(userProperties: Partial<PosthogUserProperties>) {
    if (!this.isPosthogAvailable) {
      return;
    }
    this.posthog.people.set(userProperties);
  }
}
