import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import {
  ErrorCodes,
  ErrorMessages,
} from '/../libs/shared-types/src/constants/ErrorCodes';

import UserPool from '../Userpool';
import { AuthenticationContextType } from '../contexts/AuthenticationContext';
import API from '../middleware/API';
import { Posthog } from '../middleware/Posthog';
import { validateToken } from './ValidateToken';
import { PosthogAccountTypesEnum } from '/../libs/shared-types/src/constants/AccountTypes';
import { PublicVerifyHumanity } from '/../libs/shared-types/src/constants/ApiRoutes';
import { EnvironmentTypes } from '/../libs/shared-types/src/constants/EnvironmentTypes';
import { getOnboardingFlow } from '/../libs/shared-types/src/extensions/OnboardingFlowsExtensions';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import { createUnconfirmedUser } from '/src/services/UnconfirmedUser';

export async function signupUser(
  formValues: any,
  authenticationContext: AuthenticationContextType
): Promise<void> {
  const posthogClient = new Posthog();

  try {
    await API.post(PublicVerifyHumanity.buildEndpoint(), {
      reCaptchaToken: formValues[AuthFieldNames.ReCaptchaToken],
    });
  } catch (error: any) {
    return Promise.reject(error);
  }

  let validatedInvitation = authenticationContext.invitation;

  if (authenticationContext.invitation) {
    const parsedToken = await validateToken(
      authenticationContext.invitation.shareToken
    );
    if (parsedToken === undefined) {
      authenticationContext.setInvitation(undefined);
      validatedInvitation = undefined;
    }
  }

  localStorage.setItem(
    LocalStorageKeysConst.ACCOUNT_FIRST_NAME,
    formValues.firstName
  );

  // ### THIS CASE IS JUST FOR LOCAL ENVIRONMENT ###
  // When running locally don't save any user on cognito
  if (process.env.REACT_APP_ENV === EnvironmentTypes.Local) {
    try {
      await createUnconfirmedUser(
        formValues[AuthFieldNames.Email],
        formValues[AuthFieldNames.FirstName],
        formValues[AuthFieldNames.LastName],
        validatedInvitation?.shareToken,
        authenticationContext.unconfirmedAccountType,
        validatedInvitation?.discoveredFrom,
        validatedInvitation?.message
      );
      return;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  const attributeList = [
    new CognitoUserAttribute({
      Name: 'given_name',
      Value: formValues.firstName,
    }),
    new CognitoUserAttribute({
      Name: 'family_name',
      Value: formValues.lastName,
    }),
  ];

  return new Promise((resolve, reject) => {
    UserPool.signUp(
      formValues[AuthFieldNames.Email],
      formValues[AuthFieldNames.PasswordCreate],
      attributeList,
      [],
      async (error: any) => {
        if (error) {
          reject(Error(ErrorMessages[ErrorCodes.CognitoSignupError]));
        }

        try {
          const unconfirmedUser = await createUnconfirmedUser(
            formValues[AuthFieldNames.Email],
            formValues[AuthFieldNames.FirstName],
            formValues[AuthFieldNames.LastName],
            validatedInvitation?.shareToken,
            authenticationContext.unconfirmedAccountType,
            validatedInvitation?.discoveredFrom,
            validatedInvitation?.message
          );

          posthogClient.identify(formValues[AuthFieldNames.Email]);
          posthogClient.addUserProperties({
            name: `${unconfirmedUser.firstName} ${unconfirmedUser.lastName}`,
            email: unconfirmedUser.email,
            type: PosthogAccountTypesEnum.Unconfirmed,
            invited_by_name: unconfirmedUser.invitation?.fullName,
            mongoid_unconfirmed: unconfirmedUser._id,
            sub_type: unconfirmedUser.accountType,
          });
          if (unconfirmedUser.accountType) {
            posthogClient.addUserProperties({
              onboarding_flow: getOnboardingFlow(
                unconfirmedUser.accountType,
                unconfirmedUser?.invitation?.accountType,
                unconfirmedUser?.invitation?.linkType
              ),
            });
          }

          resolve();
        } catch (error: any) {
          reject(error);
        }
      }
    );
  });
}
