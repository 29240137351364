import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import {
  REVENUE_MIN,
  REVENUE_MAX,
} from '/../libs/shared-types/src/constants/NumericRanges';
import { REVENUE_TYPE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import FormField from '/src/interfaces/FormField';
import MoneyInput from '/src/components/inputs/MoneyInput';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.RevenueType] ===
    REVENUE_TYPE_OPTIONS[5].value ? (
    <MoneyInput
      label="Monthly GMV/GTV"
      name={StartupFieldNames.RevenueMonthlyGmv}
      tooltip="Gross Merchandise or Transaction Value refers to the total sales volume transacted through the platform"
    />
  ) : (
    <></>
  );
}

const RevenueMonthlyGmvField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.RevenueMonthlyGmv]: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .when(StartupFieldNames.RevenueType, {
        is: (revenueType: string) =>
          revenueType === REVENUE_TYPE_OPTIONS[5].value,
        then: (schema) =>
          schema
            .min(
              REVENUE_MIN,
              `Must be greater than or equal to $ ${REVENUE_MIN}`,
            )
            .max(REVENUE_MAX, `Cannot be greater than $ ${REVENUE_MAX}`)
            .typeError('Must be a number'),
      }),
  }),
};

export default RevenueMonthlyGmvField;
