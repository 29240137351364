import { Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

type SlideProps = {
  appear?: boolean;
  as?: any;
  show?: boolean;
  key?: any;
  children: React.ReactNode;
  durationMs?:
    | 'duration-0'
    | 'duration-75'
    | 'duration-100'
    | 'duration-150'
    | 'duration-200'
    | 'duration-300'
    | 'duration-500'
    | 'duration-700'
    | 'duration-1000'
    | 'duration-[1500ms]'
    | 'duration-[2000ms]';
};

export function SlideUp({
  appear = true,
  as = 'div',
  show = true,
  children,
  key,
  durationMs = 'duration-1000',
}: SlideProps): JSX.Element {
  return (
    <Transition
      as={as}
      key={key}
      appear={appear}
      show={show}
      enter={`transform transition ease-in-out ${durationMs}`}
      enterFrom="opacity-0 translate-y-24"
      enterTo="opacity-100 translate-y-0"
      leave={`transform transition ease-in-out ${durationMs}`}
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-24"
    >
      {children}
    </Transition>
  );
}

export function SlideDown({
  appear = true,
  as = 'div',
  show = true,
  children,
  key,
  durationMs = 'duration-1000',
}: SlideProps): JSX.Element {
  return (
    <Transition
      as={as}
      key={key}
      appear={appear}
      show={show}
      enter={`transform transition ease-in-out ${durationMs}`}
      enterFrom="opacity-0 -translate-y-24"
      enterTo="opacity-100 -translate-y-0"
      leave={`transform transition ease-in-out ${durationMs}`}
      leaveFrom="opacity-100 -translate-y-0"
      leaveTo="opacity-0 -translate-y-24"
    >
      {children}
    </Transition>
  );
}

export function SlideLeft({
  appear = true,
  as = 'div',
  show = true,
  children,
  key,
  durationMs = 'duration-1000',
}: SlideProps): JSX.Element {
  return (
    <Transition
      as={as}
      key={key}
      appear={appear}
      show={show}
      enter={`transform transition ease-in-out ${durationMs}`}
      enterFrom="opacity-0 translate-x-24"
      enterTo="opacity-100 translate-x-0"
      leave={`transform transition ease-in-out ${durationMs}`}
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-24"
    >
      {children}
    </Transition>
  );
}

export function SlideRight({
  appear = true,
  as = 'div',
  show = true,
  children,
  key,
  durationMs = 'duration-1000',
}: SlideProps): JSX.Element {
  return (
    <Transition
      as={as}
      key={key}
      appear={appear}
      show={show}
      enter={`transform transition ease-in-out ${durationMs}`}
      enterFrom="opacity-0 -translate-x-24"
      enterTo="opacity-100 -translate-x-0"
      leave={`transform transition ease-in-out ${durationMs}`}
      leaveFrom="opacity-100 -translate-x-0"
      leaveTo="opacity-0 -translate-x-24"
    >
      {children}
    </Transition>
  );
}
