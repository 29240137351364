import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { LINK_DISCOVERY_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/LinkDiscoveryOptions';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import InvestorFlowlinkFormFieldNames from '/src/enums/InvestorFlowlinkFormFieldNames';

const LinkDiscoveryField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      component={CustomSelect}
      label="How did you discover me?"
      name={InvestorFlowlinkFormFieldNames.LinkDiscovery}
      options={LINK_DISCOVERY_OPTIONS}
      placeholder="Select..."
    />
  ),
  validation: yup.object({
    [InvestorFlowlinkFormFieldNames.LinkDiscovery]: yup
      .string()
      .required('Required'),
  }),
};

export default LinkDiscoveryField;
