import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import ToggleSwitchInput from '../../inputs/ToggleSwitchInput';
import { useLocation } from 'react-router-dom';
import { COMPLETE_PROFILE_ROUTE } from '/src/constants/Routes';
import BooleanSelectInput from '../../inputs/BooleanSelectInput';

function FieldWrapper(): JSX.Element {
  const { pathname } = useLocation();

  const isCompleteProfile = pathname.includes(COMPLETE_PROFILE_ROUTE);

  if (isCompleteProfile) {
    return (
      <BooleanSelectInput
        label="Would you like to be included in our Investor Database for founders?"
        secondaryLabel="This allows founders to learn more about you and your investment thesis and leads to higher quality dealflow."
        name={InvestorFieldNames.IsIncludedInInvestorDbForStartups}
      />
    );
  }

  return (
    <div className="my-4">
      <Field
        component={ToggleSwitchInput}
        label="Would you like to be included in our Investor Database for founders?"
        secondaryLabel="This allows founders to learn more about you and your investment thesis and leads to higher quality dealflow."
        name={InvestorFieldNames.IsIncludedInInvestorDbForStartups}
        showToggleIcon
      />
    </div>
  );
}

const IsIncludedInInvestorDbForStartupsField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.IsIncludedInInvestorDbForStartups]: yup
      .bool()
      .required('Required'),
  }),
};

export default IsIncludedInInvestorDbForStartupsField;
