import React from 'react';
import { LOGIN_ROUTE } from '/src/constants/Routes';

function ShareLinkDataAccessRoute(): JSX.Element {
  return (
    <div className="flex h-screen flex-col bg-gray-50 lg:relative">
      <main className="flex flex-grow flex-col bg-gray-50">
        <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8">
          <div className="my-auto shrink-0 py-16 sm:py-32">
            <p className="text-sm font-semibold uppercase tracking-wide text-blue-600"></p>
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Oops! You don&apos;t have access to this link.
            </h1>
            <p className="mt-2 text-lg text-gray-700">
              The owner of the link may have reset it or changed its privacy
              settings.
            </p>
            <p className="mt-2 text-base text-gray-500">
              You can reach out to whoever shared the link with you to see if
              you can get a new one. If you have further questions feel free to
              reach us at{' '}
              <a
                href="mailto:support@flowlie.com"
                className="hyperlink text-base text-gray-500 hover:text-gray-900"
              >
                support@flowlie.com
              </a>
            </p>
            <div className="mt-6">
              <a
                href={LOGIN_ROUTE}
                className="text-base font-medium text-blue-600 hover:text-blue-500"
              >
                Go back to login<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ShareLinkDataAccessRoute;
