import API from '../middleware/API';
import { SharedLinkSharingFlowlinkPrivacySetting } from '/../libs/shared-types/src/constants/ApiRoutes';
import { PublicProfilePrivacySetting } from '/../libs/shared-types/src/constants/PublicProfilePrivacySetting';

export async function getPrivacySetting() {
  const { privacy } = await API.get<{ privacy: PublicProfilePrivacySetting }>(
    SharedLinkSharingFlowlinkPrivacySetting.buildEndpoint()
  );
  return privacy;
}
