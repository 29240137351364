import RoundFieldNames from '/src/enums/RoundFieldNames';

export const emptyRound = {
  [RoundFieldNames.ConversionTerm]: '',
  [RoundFieldNames.Discount]: '',
  [RoundFieldNames.EstimatedCloseDate]: '',
  [RoundFieldNames.HasLeadInvestor]: '',
  [RoundFieldNames.HasTermSheet]: '',
  [RoundFieldNames.Interest]: '',
  [RoundFieldNames.IsBridge]: '',
  [RoundFieldNames.RaiseTarget]: '',
  [RoundFieldNames.RaisedAmount]: '',
  [RoundFieldNames.RoundStage]: '',
  [RoundFieldNames.RoundType]: '',
  [RoundFieldNames.ValuationCapType]: '',
  [RoundFieldNames.ValuationCap]: '',

  [RoundFieldNames.AntidilutionRights]: '',
  [RoundFieldNames.BridgeNumber]: '',
  [RoundFieldNames.HasRightsDragAlong]: '',
  [RoundFieldNames.HasRightsMfn]: '',
  [RoundFieldNames.HasRightsPayToPlay]: '',
  [RoundFieldNames.LiquidationParticipation]: '',
  [RoundFieldNames.LiquidationPreference]: '',
  [RoundFieldNames.OpenToInvestorTypes]: '',
  [RoundFieldNames.Prepayment]: '',
  [RoundFieldNames.PricePerShare]: '',
  [RoundFieldNames.RunwayTarget]: '',
  [RoundFieldNames.SafeType]: '',
};
