import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import { PaginatedRequestParams } from '/../libs/shared-types/src/types/view/APIResponse';
import usePaginatedUrlsParams from '/src/hooks/usePaginatedUrlParams';
import { SortOrder } from '/../libs/shared-types/src/constants/SortOrder';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  StartupPaidFeatures,
  hasFeatureAccess,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { StartupGetPersons } from '/../libs/shared-types/src/constants/ApiRoutes';
import { toDashCase } from '/src/util/formatting/strings';
import TabsHeader from '/src/components/tabs/TabsHeader';
import {
  ComparablePersonView,
  PersonsPaginatedCollectionResponse,
} from '../../../types/view/PersonView';
import MyNetworkPersons from './MyNetworkPersons';

export const defaultSortKeyPersons = 'name' as keyof ComparablePersonView;

function MyNetworkRoute(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [personResponse, setPersonResponse] =
    useState<PersonsPaginatedCollectionResponse>();

  const { subscriptionTier } = useContext(AccountMetadataContext);

  const { page, perPage, sortOrder, sortKey, filter, fieldFilters } =
    usePaginatedUrlsParams();

  const ref = useRef<boolean | null>(null);

  const tabs = [
    {
      name: 'Imported Connections',
      stat: personResponse?.totalCount ?? 0,
    },
  ];

  async function fetchPersons(
    args: Partial<PaginatedRequestParams<ComparablePersonView>>,
  ) {
    if (
      subscriptionTier &&
      !hasFeatureAccess(StartupPaidFeatures.Persons, subscriptionTier)
    ) {
      return;
    }

    try {
      setIsSearchLoading(true);

      const requestParams = {
        sortKey: defaultSortKeyPersons,
        ...personResponse,
        ...args,
      };

      const url = StartupGetPersons.buildEndpoint(undefined, requestParams);

      const data = await API.get<PersonsPaginatedCollectionResponse>(url);
      setPersonResponse(data);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsSearchLoading(false);
    }
  }

  async function handleFilterPersons(filter: string) {
    try {
      await fetchPersons({ filter, page: 1 });
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function loadInitialData() {
    try {
      setIsLoading(true);
      await Promise.all([
        fetchPersons({
          page,
          perPage,
          sortKey: (sortKey ??
            defaultSortKeyPersons) as keyof ComparablePersonView,
          sortOrder: !sortOrder ? SortOrder.Asc : sortOrder,
          filter,
        }),
      ]);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadInitialData();
  }, []);

  return (
    <main>
      <header>
        <h2 className="page-title">My Network</h2>
        <TabsHeader tabs={tabs} />
      </header>

      <section className="my-4">
        {isLoading && (
          <PageLoadingSpinner message="Loading your fundraising network... 💰" />
        )}

        {!isLoading && (
          <>
            <Routes>
              {['', tabs[0].name].map((path) => (
                <Route
                  key={path}
                  path={toDashCase(path)}
                  element={
                    <MyNetworkPersons
                      apiResponse={personResponse}
                      isSearchLoading={isSearchLoading}
                      handleFilter={handleFilterPersons}
                      fetchPersons={fetchPersons}
                    />
                  }
                />
              ))}
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          </>
        )}
      </section>
    </main>
  );
}

export default MyNetworkRoute;
