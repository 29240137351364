import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import {
  NetworkGraphData,
  NetworkAccountNode,
} from '/../libs/shared-types/src/types/model/NetworkGraphData';

interface ReactGraphData {
  nodes: any[];
  edges: any[];
}

function getNodeColor(node: NetworkAccountNode) {
  if (node.isFlowlieTeamMember) {
    return '#2BD3BF';
  }
  if (node.accountType === AccountTypes.Investor) {
    return '#FFC107';
  }
  if (node.accountType === AccountTypes.Startup) {
    return '#3BBCF7';
  }
  return '#FFFFFF';
}
export default function prepareConnections(
  data: NetworkGraphData
): ReactGraphData {
  const nodes: any[] = [];
  const edges: any[] = [];

  if (data.nodes.length === 0) {
    return { nodes, edges };
  }

  data.nodes.forEach((account) => {
    nodes.push({
      id: account.id,
      label: account.name,
      title: account.name,
      color: getNodeColor(account),
      shape: account.isPublicSignUp ? 'box' : 'ellipse',
    });
  });
  data.edges.forEach((connection) => {
    edges.push({
      from: connection.from,
      to: connection.to,
    });
  });
  return { nodes, edges };
}
