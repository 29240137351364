/* eslint-disable import/prefer-default-export */

/**
 * Sorts an array of objects by date given the date field
 * with the latest date at the 0 index
 * @param data Array of object you want to support
 * @param dateField Field that holds date, this function support mongoose dot notation
 * For example you can drill down like so: deal.createdOn
 * @returns Sorted array of objects
 */
export function sortDataByDate<T>(data: T[], dateField: keyof T): T[] {
  const fields = (dateField as string).split('.');

  function getField(element: T): Date {
    let currentObject: any = element;
    fields.forEach((field: string) => {
      currentObject = currentObject[field];
    });
    return currentObject;
  }

  return data.sort((x, y) => getField(y).getTime() - getField(x).getTime());
}
