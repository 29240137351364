import React from 'react';
import { useNavigate } from 'react-router-dom';
import { XMarkIcon, CheckIcon } from '@heroicons/react/20/solid';

import {
  formatImageAddress,
  shortenWebUrl,
} from '/src/util/formatting/strings';
import { generateTag } from '/src/util/generateTagCloud';
import {
  DealShareRequestPayload,
  Request,
} from '/../libs/shared-types/src/types/model/Request';
import DateString from '/src/components/utility/DateString';
import { PublicInvestor } from '/../libs/shared-types/src/types/view/investor/PublicInvestor';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import CopyTextButton from '/src/components/CopyTextButton';
import { INVESTOR_DETAIL } from '/src/constants/Routes';
import SquaredLogo from '/src/components/SquaredLogo';

interface DealAccessRequestsReceivedTableRowProps {
  investor: PublicInvestor;
  startup: PublicStartup;
  request: Request<DealShareRequestPayload>;
  onAcceptRequest: (requestId: string) => void;
  onRejectRequest: (requestId: string) => void;
}

function DealAccessRequestsReceivedTableRow({
  investor,
  startup,
  request,
  onAcceptRequest,
  onRejectRequest,
}: DealAccessRequestsReceivedTableRowProps): JSX.Element {
  const navigate = useNavigate();
  const handleOnClick = React.useCallback(
    () => navigate(`${INVESTOR_DETAIL}/${investor._id}`),
    [history],
  );

  function handleRejectRequest(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void {
    event.stopPropagation();
    onRejectRequest(request._id);
  }

  function handleAcceptRequest(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void {
    event.stopPropagation();
    onAcceptRequest(request._id);
  }

  return (
    <tr onClick={handleOnClick} className="cursor-pointer hover:bg-gray-100">
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <SquaredLogo
              containerClassName="size-10"
              src={formatImageAddress(startup.logoKey)}
              alt={`${startup.name} logo`}
            />
          </div>
          <div className="ml-2 max-w-[160px]">
            <div className="app-table-row-text--bold">{startup.name}</div>
            <div className="flex w-full text-sm text-gray-500">
              <a
                className="block truncate hover:underline"
                target="_blank"
                rel="noopener noreferrer"
                href={startup.website}
                onClick={(e) => e.stopPropagation()}
              >
                {shortenWebUrl(startup.website)}
              </a>
              <CopyTextButton text={startup.website} className="ml-1" />
            </div>
          </div>
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <UserAvatarWithInitials
              containerStyles="h-10 w-10"
              firstName={investor.firstName}
              lastName={investor.lastName}
              textStyles="text-xl"
              imgAlt="Investor Profile"
              imgSrc={formatImageAddress(investor.profilePicKey)}
            />
          </div>
          <div className="app-table-row-text--bold ml-4">{`${investor.firstName} ${investor.lastName}`}</div>
        </div>
      </td>
      <td className="whitespace-nowrap px-4 py-4">
        {generateTag(investor.type, true)}
      </td>
      <td className="app-table-row-item">
        {investor.firm && investor.role ? (
          <>
            <div className="app-table-row-text">{investor.firm}</div>
            <div className="app-table-row-text--light">{investor.role}</div>
          </>
        ) : (
          <div className="app-table-row-text">-</div>
        )}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          <DateString date={request.createdOn} tooltipPosition="top" />
        </div>
      </td>
      <td className="whitespace-normal px-3 py-4 text-right">
        <span className="relative z-0 inline-flex rounded-md shadow-sm">
          <div className="tooltip-wrapper">
            <span className="tooltip tooltip-top w-max">Reject Request</span>
            <button
              type="button"
              onClick={handleRejectRequest}
              className="relative -ml-px inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            >
              <span className="sr-only">Reject Request</span>
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>

          <div className="tooltip-wrapper">
            <span className="tooltip tooltip-top w-max">Accept Request</span>
            <button
              type="button"
              onClick={handleAcceptRequest}
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            >
              <span className="sr-only">Accept Request</span>
              <CheckIcon className="h-5 w-5" />
            </button>
          </div>
        </span>
      </td>
    </tr>
  );
}

export default DealAccessRequestsReceivedTableRow;
