import React from 'react';
import { FieldProps } from 'formik';
import Tooltip from '/src/components/utility/Tooltip';

interface CheckBoxProps extends FieldProps {
  customStyle?: string;
  disabled?: boolean;
  label: string | JSX.Element;
  name: string;
  tooltip?: string;
}

function FormikCheckBox({
  form: { errors, touched },
  field,
  customStyle,
  disabled,
  label,
  tooltip,
}: CheckBoxProps): JSX.Element {
  return (
    <section>
      <label className="mb-1 flex flex-row" htmlFor={field.name}>
        <input
          type="checkbox"
          className={`input-checkbox ${customStyle}`}
          disabled={disabled}
          {...field}
        />

        <span className="ml-2 flex flex-row items-center">
          {label}
          {tooltip && <Tooltip tooltipText={tooltip} />}
        </span>
      </label>
      {errors[field.name] && touched[field.name] && (
        <p className="app-error-message">{errors[field.name]?.toString()}</p>
      )}
    </section>
  );
}

export default FormikCheckBox;
