import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import { MAX_STARTUP_BUSINESS_MODELS } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import { BUSINESS_MODEL_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import LimitedMenu from '../../inputs/ReactSelectAdditions/LimitedMenu';
import FormatOptionWithTooltipLabel from '../../inputs/ReactSelectAdditions/formatOptionWithTooltipLabel';
import { FormatOptionLabelMeta } from 'react-select';
import { TooltipSelectOption } from '/../libs/shared-types/src/types/SelectOptions';

const BusinessModelsField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      components={{ Menu: LimitedMenu }}
      formatOptionLabel={(
        option: TooltipSelectOption,
        formatOptionLabelMeta: FormatOptionLabelMeta<any>
      ) => (
        <FormatOptionWithTooltipLabel
          option={option}
          formatOptionLabelMeta={formatOptionLabelMeta}
        />
      )}
      isClearable
      isMulti
      label="Business Models"
      maxCount={MAX_STARTUP_BUSINESS_MODELS}
      name={StartupFieldNames.BusinessModels}
      options={BUSINESS_MODEL_OPTIONS}
      placeholder="Select business models..."
    />
  ),
  validation: yup.object({
    [StartupFieldNames.BusinessModels]: yup
      .array()
      .of(yup.string())
      .max(
        MAX_STARTUP_BUSINESS_MODELS,
        `At most ${MAX_STARTUP_BUSINESS_MODELS} business models are allowed`
      )
      .min(1, 'At least one is required')
      .required('This field is required'),
  }),
};

export default BusinessModelsField;
