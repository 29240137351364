// Implementation from https://github.com/solydhq/typed-local-store/tree/master

export type RetrievalMode = 'fail' | 'raw' | 'safe';

export interface ITypedStorage<T> {
  length: number;
  key<U extends keyof T>(index: number): U;
  setItem<U extends keyof T>(key: U, value: T[U]): void;
  getItem<U extends keyof T>(key: U): T[U] | null;
  removeItem<U extends keyof T>(key: U, retrievalMode: RetrievalMode): void;
  clear(): void;
}

export interface TypedStorageOptions {
  storage?: 'localStorage' | 'sessionStorage';
  fallbackStorage?: Storage;
  ignoreMissingStorage?: boolean;
}

export default class TypedStorage<T> implements ITypedStorage<T> {
  private readonly storage: Storage;

  constructor({
    storage = 'localStorage',
    ignoreMissingStorage = false,
    fallbackStorage = undefined,
  }: TypedStorageOptions = {}) {
    const browserStorage = typeof window !== 'undefined' && window?.[storage];
    this.storage = browserStorage || global[storage] || fallbackStorage;

    if (!this.storage && !ignoreMissingStorage) {
      throw Error('Web Storage API not found.');
    }
  }

  public get length(): number {
    return this.storage?.length;
  }

  public key<U extends keyof T>(index: number): U {
    return this.storage?.key(index) as U;
  }

  /**
   * @param key 
   * @param retrievalMode 
   *```
    'fail': If something to be restored from the store can not be parsed by JSON.parse() an error is thrown.
    'raw': If parsing of the retrieval value fails, the unparsed value is returned.
    'safe':	If parsing of the retrieval value fails, null is returned.
    ```
   * @returns 
   */
  public getItem<U extends keyof T>(
    key: U,
    retrievalMode: RetrievalMode = 'fail'
  ): T[U] | null {
    const item = this.storage?.getItem(key.toString());

    if (item == null) {
      return item;
    }

    try {
      return JSON.parse(item) as T[U];
    } catch (error) {
      switch (retrievalMode) {
        case 'safe':
          return null;
        case 'raw':
          return item as unknown as T[U];
        default:
          throw error;
      }
    }
  }

  public setItem<U extends keyof T>(key: U, value: T[U]): void {
    this.storage?.setItem(key.toString(), JSON.stringify(value));
  }

  public removeItem<U extends keyof T>(key: U): void {
    this.storage?.removeItem(key.toString());
  }

  public clear(): void {
    this.storage?.clear();
  }
}
