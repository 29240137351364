// In Mega bytes
export const MAX_IMAGE_SIZE = 2;

// In Mega bytes
export const MAX_DECK_SIZE = 40;

export enum FileType {
  Image = '.png, .gif, .jpeg, .jpg',
  Pdf = '.pdf',
}
