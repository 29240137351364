import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import { enumToTooltipSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import {
  RoundTypes,
  getRoundTypeTooltip,
} from '/../libs/shared-types/src/constants/RoundTypes';
import { FormatOptionLabelMeta } from 'react-select';
import { TooltipSelectOption } from '../../../../types/SelectOptions';
import FormatOptionWithTooltipLabel from '/src/components/inputs/ReactSelectAdditions/formatOptionWithTooltipLabel';
import { getHelperTextUsualRoundType, roundHelper } from '../RoundHelpers';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  const helper = roundHelper.find(
    (x) =>
      x.roundStage === values[RoundFieldNames.RoundStage] &&
      x.isBridge === (values[RoundFieldNames.IsBridge] === true)
  );
  const secondaryLabel = helper ? getHelperTextUsualRoundType(helper) : '';

  return isInputShown(RoundFieldNames.RoundType, values) ? (
    <Field
      className="custom-select"
      component={CustomSelect}
      formatOptionLabel={(
        option: TooltipSelectOption,
        formatOptionLabelMeta: FormatOptionLabelMeta<any>
      ) => (
        <FormatOptionWithTooltipLabel
          option={option}
          formatOptionLabelMeta={formatOptionLabelMeta}
        />
      )}
      closeMenuOnSelect
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      label="What fundraising instrument do you plan to use?"
      name={RoundFieldNames.RoundType}
      options={enumToTooltipSelectOptions(RoundTypes, getRoundTypeTooltip)}
      placeholder="Select Round Type..."
      tooltip="This is the legal contract you plan to issue and sell to investors."
      secondaryLabel={secondaryLabel}
    />
  ) : (
    <></>
  );
}

const RoundTypeField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.RoundType]: yup.string().required('Required'),
  }),
};

export default RoundTypeField;
