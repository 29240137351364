import React, { useState, useLayoutEffect, useEffect } from 'react';
import usePrevious from '/src/hooks/usePrevious';
import getBoundingBoxesForDomNodes from '/src/util/getBoundingBoxesForDomNodes';

interface AnimateListItemsProps {
  children: any;
  animateY?: boolean;
  animateX?: boolean;
}

function AnimateListItems({
  children,
  animateX = true,
  animateY = true,
}: AnimateListItemsProps): JSX.Element {
  const [boundingBox, setBoundingBox] = useState<{ [key: string]: DOMRect }>(
    {}
  );
  const [prevBoundingBox, setPrevBoundingBox] = useState<{
    [key: string]: DOMRect;
  }>({});
  const prevChildren = usePrevious(children);

  useLayoutEffect(() => {
    const newBoundingBox = getBoundingBoxesForDomNodes(children);
    setBoundingBox(newBoundingBox);
  }, [children]);

  useLayoutEffect(() => {
    const previousBoundingBox = getBoundingBoxesForDomNodes(prevChildren);
    setPrevBoundingBox(previousBoundingBox);
  }, [prevChildren]);

  useEffect(() => {
    const hasPrevBoundingBox = Object.keys(prevBoundingBox).length;

    if (hasPrevBoundingBox) {
      React.Children.forEach(children, (child) => {
        const domNode: HTMLDivElement | null = child.ref.current;
        if (!domNode) {
          return;
        }

        const firstBox = prevBoundingBox[child.key];
        const lastBox = boundingBox[child.key];
        const changeInX = firstBox?.left - lastBox?.left;
        const changeInY = firstBox?.top - lastBox?.top;

        if (animateX && changeInX) {
          requestAnimationFrame(() => {
            // Before the DOM paints, invert child to old position
            domNode.style.transform = `translateX(${changeInX}px)`;
            domNode.style.transition = 'transform 0s';

            requestAnimationFrame(() => {
              // After the previous frame, remove
              // the transistion to play the animation
              domNode.style.transform = '';
              domNode.style.transition = 'transform 500ms';
            });
          });
        }

        if (animateY && changeInY) {
          requestAnimationFrame(() => {
            // Before the DOM paints, invert child to old position
            domNode.style.transform = `translateY(${changeInY}px)`;
            domNode.style.transition = 'transform 0s';

            requestAnimationFrame(() => {
              // After the previous frame, remove
              // the transistion to play the animation
              domNode.style.transform = '';
              domNode.style.transition = 'transform 500ms';
            });
          });
        }
      });
    }
  }, [boundingBox, prevBoundingBox, children]);

  return children;
}

export default AnimateListItems;
