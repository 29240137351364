export enum UA_DeviceType {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Tablet = 'tablet',
  Smarttv = 'smarttv',
  Console = 'console',
  Wearable = 'wearable',
  Embedded = 'embedded',
}

/**
 * Remember: This is an extensive but not complete set of values
 * @see https://faisalman.github.io/ua-parser-js-docs/v2/api/ua-parser-js/get-os.html#name-string
 */
export enum UA_DeviceOsNames {
  AIX = 'AIX',
  AmigaOS = 'Amiga OS',
  Android = 'Android',
  Android_x86 = 'Android-x86',
  Arch = 'Arch',
  Bada = 'Bada',
  BeOS = 'BeOS',
  BlackBerry = 'BlackBerry',
  CentOS = 'CentOS',
  ChromiumOS = 'Chromium OS',
  ChromeOS = 'Chrome OS',
  Contiki = 'Contiki',
  Fedora = 'Fedora',
  FirefoxOS = 'Firefox OS',
  FreeBSD = 'FreeBSD',
  Debian = 'Debian',
  Deepin = 'Deepin',
  DragonFly = 'DragonFly',
  elementaryOS = 'elementary OS',
  Fuchsia = 'Fuchsia',
  Gentoo = 'Gentoo',
  GhostBSD = 'GhostBSD',
  GNU = 'GNU',
  Haiku = 'Haiku',
  HarmonyOS = 'HarmonyOS',
  HP_UX = 'HP-UX',
  Hurd = 'Hurd',
  iOS = 'iOS',
  Joli = 'Joli',
  KaiOS = 'KaiOS',
  Linpus = 'Linpus',
  Linspire = 'Linspire',
  Linux = 'Linux',
  MacOS = 'Mac OS',
  MacOS_V2 = 'macOS',
  Maemo = 'Maemo',
  Mageia = 'Mageia',
  Mandriva = 'Mandriva',
  Manjaro = 'Manjaro',
  MeeGo = 'MeeGo',
  Minix = 'Minix',
  Mint = 'Mint',
  MorphOS = 'Morph OS',
  NetBSD = 'NetBSD',
  NetRange = 'NetRange',
  NetTV = 'NetTV',
  Nintendo = 'Nintendo',
  OpenBSD = 'OpenBSD',
  OpenVMS = 'OpenVMS',
  Palm = 'Palm',
  PC_BSD = 'PC-BSD',
  PCLinuxOS = 'PCLinuxOS',
  Plan9 = 'Plan9',
  PlayStation = 'PlayStation',
  QNX = 'QNX',
  Raspbian = 'Raspbian',
  RedHat = 'RedHat',
  RIM_Tablet_OS = 'RIM Tablet OS',
  RISC_OS = 'RISC OS',
  Sabayon = 'Sabayon',
  Sailfish = 'Sailfish',
  SerenityOS = 'SerenityOS',
  Series40 = 'Series40',
  Slackware = 'Slackware',
  Solaris = 'Solaris',
  SUSE = 'SUSE',
  Symbian = 'Symbian',
  Tizen = 'Tizen',
  Ubuntu = 'Ubuntu',
  Unix = 'Unix',
  VectorLinux = 'VectorLinux',
  Viera = 'Viera',
  watchOS = 'watchOS',
  WebOS = 'WebOS',
  Windows = 'Windows',
  WindowsMobile = 'Windows Mobile',
  WindowsPhone = 'Windows Phone',
  Zenwalk = 'Zenwalk',
}

/**
 * Remember: This is an extensive but not complete set of values
 * @see https://faisalman.github.io/ua-parser-js-docs/v2/api/ua-parser-js/get-browser.html#name-string
 */
export enum UA_BrowserNames {
  Amaya = 'Amaya',
  AndroidBrowser = 'Android Browser',
  Arora = 'Arora',
  Avant = 'Avant',
  Avast = 'Avast',
  AVG = 'AVG',
  BIDUBrowser = 'BIDUBrowser',
  Baidu = 'Baidu',
  Basilisk = 'Basilisk',
  Blazer = 'Blazer',
  Bolt = 'Bolt',
  Brave = 'Brave',
  Bowser = 'Bowser',
  Camino = 'Camino',
  Chimera = 'Chimera',
  Chrome = 'Chrome',
  MobileChrome = 'Mobile Chrome',
  ChromeHeadless = 'Chrome Headless',
  ChromeWebView = 'Chrome WebView',
  Chromium = 'Chromium',
  Cobalt = 'Cobalt',
  ComodoDragon = 'Comodo Dragon',
  Dillo = 'Dillo',
  Dolphin = 'Dolphin',
  Doris = 'Doris',
  DuckDuckGo = 'DuckDuckGo',
  Edge = 'Edge',
  Electron = 'Electron',
  Epiphany = 'Epiphany',
  Facebook = 'Facebook',
  Falkon = 'Falkon',
  Fennec = 'Fennec',
  Firebird = 'Firebird',
  Firefox = 'Firefox',
  MobileFirefox = 'Mobile Firefox',
  FirefoxFocus = 'Firefox Focus',
  FirefoxReality = 'Firefox Reality',
  Flock = 'Flock',
  Flow = 'Flow',
  GSA = 'GSA',
  GoBrowser = 'GoBrowser',
  HeyTap = 'HeyTap',
  HuaweiBrowser = 'Huawei Browser',
  ICEBrowser = 'ICE Browser',
  IE = 'IE',
  IEMobile = 'IEMobile',
  IceApe = 'IceApe',
  IceCat = 'IceCat',
  IceDragon = 'IceDragon',
  Iceweasel = 'Iceweasel',
  Instagram = 'Instagram',
  Iridium = 'Iridium',
  Iron = 'Iron',
  Jasmine = 'Jasmine',
  K_Meleon = 'K-Meleon',
  Kindle = 'Kindle',
  Klar = 'Klar',
  Konqueror = 'Konqueror',
  LBBROWSER = 'LBBROWSER',
  Line = 'Line',
  LinkedIn = 'LinkedIn',
  Links = 'Links',
  Lunascape = 'Lunascape',
  Lynx = 'Lynx',
  MIUIBrowser = 'MIUI Browser',
  MaemoBrowser = 'Maemo Browser',
  Maemo = 'Maemo',
  Maxthon = 'Maxthon',
  MetaSr = 'MetaSr',
  Midori = 'Midori',
  Minimo = 'Minimo',
  Mosaic = 'Mosaic',
  Mozilla = 'Mozilla',
  NetFront = 'NetFront',
  NetSurf = 'NetSurf',
  Netfront = 'Netfront',
  Netscape = 'Netscape',
  NokiaBrowser = 'NokiaBrowser',
  Obigo = 'Obigo',
  OculusBrowser = 'Oculus Browser',
  OmniWeb = 'OmniWeb',
  OperaCoast = 'Opera Coast',
  Opera = 'Opera',
  OperaMini = 'Opera Mini',
  OperaMobi = 'Opera Mobi',
  OperaTablet = 'Opera Tablet',
  PaleMoon = 'PaleMoon',
  PhantomJS = 'PhantomJS',
  Phoenix = 'Phoenix',
  Polaris = 'Polaris',
  Puffin = 'Puffin',
  QQ = 'QQ',
  QQBrowser = 'QQBrowser',
  QQBrowserLite = 'QQBrowserLite',
  Quark = 'Quark',
  QupZilla = 'QupZilla',
  RockMelt = 'RockMelt',
  Safari = 'Safari',
  MobileSafari = 'Mobile Safari',
  SailfishBrowser = 'Sailfish Browser',
  SamsungBrowser = 'Samsung Browser',
  SeaMonkey = 'SeaMonkey',
  Silk = 'Silk',
  Skyfire = 'Skyfire',
  Sleipnir = 'Sleipnir',
  Slim = 'Slim',
  SlimBrowser = 'SlimBrowser',
  Swiftfox = 'Swiftfox',
  Tesla = 'Tesla',
  TikTok = 'TikTok',
  TizenBrowser = 'Tizen Browser',
  UCBrowser = 'UCBrowser',
  UPBrowser = 'UP.Browser',
  Viera = 'Viera',
  Vivaldi = 'Vivaldi',
  Waterfox = 'Waterfox',
  WeChat = 'WeChat',
  Weibo = 'Weibo',
  Yandex = 'Yandex',
  baidu = 'baidu',
  iCab = 'iCab',
  w3m = 'w3m',
  WhaleBrowser = 'Whale Browser',
}
