import React from 'react';
import { FieldProps } from 'formik';
import FormikDebugger from './FormikDebugger';
import FormikErrorMessage from './FormikErrorMessage';
import ToggleSwitch, { ToggleSwitchProps } from '../utility/ToggleSwitch';

interface ToggleSwitchInputProps extends FieldProps, ToggleSwitchProps {}

function ToggleSwitchInput({
  canToggleOnLabelClick,
  field,
  form,
  isDisabled,
  label,
  meta,
  name,
  secondaryLabel,
  showToggleIcon,
}: ToggleSwitchInputProps): JSX.Element {
  return (
    <section className="text-base text-gray-800">
      <ToggleSwitch
        canToggleOnLabelClick={canToggleOnLabelClick}
        initialState={field.value}
        isDisabled={isDisabled}
        label={label}
        name={name}
        secondaryLabel={secondaryLabel}
        showToggleIcon={showToggleIcon}
        toggleOff={() => form.setFieldValue(field.name, false)}
        toggleOn={() => form.setFieldValue(field.name, true)}
      />
      <FormikErrorMessage field={field} form={form} meta={meta} />
      <FormikDebugger field={field} form={form} meta={meta} />
    </section>
  );
}

export default ToggleSwitchInput;
