import React from 'react';
import { FieldProps, getIn } from 'formik';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { FileType } from '/src/constants/FileUploadTypes';
import { getMaxFileSize } from '/src/util/FileHelpers';
import FormikDebugger from './FormikDebugger';
import FormikErrorMessage from './FormikErrorMessage';
import DebugRender from '/src/components/utility/DebugRender';

function getPreviewImage(): JSX.Element {
  return <ArrowUpTrayIcon className="mx-auto h-12 w-12 text-gray-400" />;
}

interface PdfInputProps extends FieldProps {
  canRemoveFile?: boolean;
  disabled?: boolean;
  label: string;
}

const PdfInput = ({
  canRemoveFile = false,
  field,
  form,
  disabled = false,
  label,
  meta,
}: PdfInputProps): JSX.Element => {
  function onInputValueChange(): void {
    form.setFieldValue(`${field.name}MustValidate`, true);
    form.setFieldTouched(field.name, true);
  }

  function deleteFileDataAndResetPreview(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    event.stopPropagation();

    form.setFieldValue(field.name, null);
    onInputValueChange();
  }

  const readFileDataAndUpdatePreview = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      form.setFieldValue(field.name, file);
      onInputValueChange();
    };

    reader.readAsDataURL(file);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const input = e.target as HTMLInputElement;
    const file = input.files ? input.files[0] : null;

    if (!file) {
      return;
    }

    readFileDataAndUpdatePreview(file);
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    // This click handler is needed for Safari compatibility
    // Safari buttons do not receive focus, so the onBlur would never fire
    const input = event.currentTarget;
    input.focus();
  };

  function getFile() {
    if (field.value instanceof File) {
      return field.value;
    }

    return null;
  }

  function getFileName() {
    // If the field value does not have the fileName property, then we may have an actual File
    // So return the File.name
    return field.value?.fileName ?? field.value?.name;
  }

  // Re-render component whenever the Formik field value changes
  // Remember, the field contains the File data, not the file key
  React.useEffect(() => {
    if (getFile()) {
      readFileDataAndUpdatePreview(field.value);
    }
  }, [field.value]);

  return (
    <div>
      <label htmlFor={field.name} className="input-label">
        {`${label}`}
        <div className="mt-2 flex justify-center rounded-md border-2 border-solid border-gray-300 px-6 pt-5 pb-6">
          <div className="space-y-1 text-center">
            {getPreviewImage()}
            {getFileName() && <p>{getFileName()}</p>}
            <div className="flex justify-center text-sm text-gray-600">
              <div className="relative cursor-pointer rounded-md bg-white font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500">
                <span>Click to upload a file ({FileType.Pdf})</span>
                <input
                  accept={FileType.Pdf.toString()}
                  className="sr-only"
                  disabled={disabled}
                  id={field.name}
                  name={field.name}
                  onBlur={() => form.setFieldTouched(field.name, true)}
                  onChange={handleChange}
                  onClick={handleClick}
                  type="file"
                />
              </div>
            </div>
            <p className="text-xs text-gray-500">
              {`File up to ${getMaxFileSize(FileType.Pdf)}MB`}
            </p>
            {canRemoveFile && getFile() && (
              <button
                type="button"
                className="mt-6 cursor-pointer rounded-md bg-white text-sm font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500"
                onClick={deleteFileDataAndResetPreview}
              >
                Remove File
              </button>
            )}
          </div>
        </div>
      </label>
      <FormikErrorMessage field={field} form={form} meta={meta} />
      <FormikDebugger field={field} form={form} meta={meta} />
      <DebugRender className="max-w-md truncate px-4 text-2xs">
        <pre>
          {field.name}Object:
          {JSON.stringify(field.value, null, 2)}
        </pre>
        <pre>
          {field.name}MustValidate:
          {JSON.stringify(getIn(form.values, `${field.name}MustValidate`))}
        </pre>
      </DebugRender>
    </div>
  );
};

export default PdfInput;
