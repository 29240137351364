import React, { useEffect, useState } from 'react';
import { FolderIcon } from '@heroicons/react/20/solid';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import FormikInput from '/src/components/inputs/FormikInput';
import Logger from '/src/services/logger';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Alert from '/src/components/notifications/Alert';
import { VerifyLinkPasswordResponse } from '../../../../types/view/APIResponse';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { SlideUp } from '/src/components/animations/Slide';
import { StartupOnePagerFeatures } from '/../libs/shared-types/src/constants/StartupOnePagerFeatures';

enum FieldNames {
  Password = 'password',
}

interface DealDetailDataRoomCardProps {
  startup: PublicStartup;
  onOpenModal: () => Promise<void>;
  onVerifyPassword: (password: string) => Promise<VerifyLinkPasswordResponse>;
}

function DealDetailDataRoomCard({
  startup,
  onOpenModal,
  onVerifyPassword,
}: DealDetailDataRoomCardProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [dataRoomLink, setDataRoomLink] = useState<string>();

  async function onSubmit(
    values: { [FieldNames.Password]: string },
    { setSubmitting }: FormikHelpers<any>,
  ) {
    try {
      setSubmitting(true);

      const response = await onVerifyPassword(values[FieldNames.Password]);
      if (response.isError) {
        setErrorMessage(response.message);
      } else {
        setDataRoomLink(response.linkUrl);
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    if (!isModalOpen) {
      // The timeout is purely for the UI
      // to ensure the modal content updates after the modal is closed
      setTimeout(() => {
        setErrorMessage('');
        setDataRoomLink('');
      }, 500);
    }
  }, [isModalOpen]);

  if (
    !startup.dataRoom.link ||
    startup.flowLink.excludedFromOnePager.includes(
      StartupOnePagerFeatures.DataRoom,
    )
  ) {
    return <></>;
  }

  return (
    <section>
      <div className="rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Data Room
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 2xl:grid-cols-3">
            <div className="col-span-2">
              <dd className="mt-1">
                <button
                  onClick={() => {
                    setIsModalOpen(true);
                    onOpenModal();
                  }}
                  className="mt-1 inline-flex w-full items-center justify-center rounded-full border border-transparent bg-blue-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  <FolderIcon className="mr-2 h-5 w-5" />
                  View Data Room
                </button>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <ModalWrapper
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="bg-white p-4 sm:w-screen sm:max-w-lg sm:p-7">
          <header className="mb-8 text-center text-lg font-medium leading-6 text-gray-900">
            {!dataRoomLink && 'Please enter the data room password to access'}

            {dataRoomLink && 'Password verified successfully'}
          </header>
          {!dataRoomLink && (
            <Formik
              initialValues={{ [FieldNames.Password]: '' }}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, dirty }) => (
                <Form className="flex flex-col space-y-4">
                  <Field
                    component={FormikInput}
                    label="Password"
                    secondaryLabel="If you don't know the password, you should reach out to the founder"
                    name={FieldNames.Password}
                    type="password"
                  />
                  <button
                    type="submit"
                    className="app-button--primary items-center justify-center truncate"
                    disabled={!dirty || isSubmitting}
                  >
                    Continue
                    {isSubmitting && (
                      <div className="ml-2">
                        <LoadingSpinner color="white" />
                      </div>
                    )}
                  </button>

                  <Alert
                    color="red"
                    alertType="Error"
                    content={errorMessage}
                    isShown={errorMessage !== ''}
                    onClose={() => setErrorMessage('')}
                  />
                </Form>
              )}
            </Formik>
          )}

          {dataRoomLink && (
            <SlideUp>
              <div className="mt-4 flex flex-col">
                <CheckCircleIcon className="mx-auto h-10 w-10 text-green-500" />
                <a
                  href={dataRoomLink}
                  target="_blank"
                  rel="noreferrer"
                  className="app-button--primary mt-8 items-center justify-center truncate"
                >
                  Access Data Room
                </a>
              </div>
            </SlideUp>
          )}
        </div>
      </ModalWrapper>
    </section>
  );
}

export default DealDetailDataRoomCard;
