import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { YEARLY_AVG_INVESTMENT_COUNT_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

const YearlyAvgInvCountField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      closeMenuOnSelect
      isClearable
      label="On average, how many investments do you make per year?"
      name={InvestorFieldNames.YearlyAvgInvCount}
      options={YEARLY_AVG_INVESTMENT_COUNT_OPTIONS}
      placeholder="Select option..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.YearlyAvgInvCount]: yup.string().nullable(),
  }),
};

export default YearlyAvgInvCountField;
