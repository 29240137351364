import React, { useState, Fragment, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Stage } from '/../libs/shared-types/src/types/model/Stage';
import { joinClassNames } from '/src/util/formatting/strings';
import { StartupInvestorPipelineStages } from '/../libs/shared-types/src/constants/ApiRoutes';
import { calculateStageDurationLeft } from '/../libs/shared-types/src/extensions/StageStatusExtensions';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import { STARTUP_ROUND_MANAGER_ROUTE } from '/src/constants/Routes';
import { useNavigate } from 'react-router-dom';
import { StageStatusView, StageView } from '../../../../types/view/StageView';
import { toPipelineStageViews } from './InvestorPipeline';
import { getColorByIndex } from '/src/util/colorLookup';
import DateString from '/src/components/utility/DateString';
import ActiveStageDurationLeft from '/src/routes/investor/DealDetail/ActiveStageDurationLeft';

function getStageByName(
  stages: StageView[],
  stageName: string,
): StageView | undefined {
  const stage = stages.find((x) => x.name === stageName);

  return stage;
}
interface InvestorPipelineStageSelectorProps {
  currentStageStatus: StageStatusView;
  onStageSelected: (stageName: string) => Promise<void>;
}

function InvestorPipelineStageSelector({
  currentStageStatus,
  onStageSelected,
}: InvestorPipelineStageSelectorProps): JSX.Element {
  const [stages, setStages] = useState<StageView[]>([]);
  const [currentStage, setCurrentStage] = useState<StageView>();

  const navigate = useNavigate();

  async function fetchStages() {
    try {
      const stages = await API.get<Stage[]>(
        StartupInvestorPipelineStages.buildEndpoint(),
      );

      const stageViews = toPipelineStageViews(stages);
      setStages(stageViews);
      setCurrentStage(getStageByName(stageViews, currentStageStatus.name));
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  useEffect(() => {
    fetchStages();
  }, []);

  if (stages.length === 0) {
    return (
      <div
        className={joinClassNames(
          'relative inline-block animate-pulse justify-center rounded-md px-2.5 py-1.5 text-xs font-bold tracking-wider shadow-sm',
          getColorByIndex(0, 5).allColors,
        )}
      >
        {currentStageStatus.name}
      </div>
    );
  }

  return (
    <div className="flex items-center space-x-2">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button
          className={joinClassNames(
            currentStage && currentStage.bgColor
              ? currentStage.bgColor + ' ' + currentStage.textColor
              : getColorByIndex(
                  stages.findIndex((x) => x.name === currentStageStatus.name),
                  stages.length,
                ).allColors,
            'inline-flex w-full justify-center rounded-md px-2.5 py-1.5 text-xs font-bold tracking-wider shadow-sm',
            'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100',
          )}
        >
          {currentStage?.name}
          <ChevronUpDownIcon className="-mr-1 ml-2 size-4" aria-hidden="true" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2 w-52 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            {stages.filter((x) => x.name !== currentStageStatus.name).length >
              0 && (
              <div className="py-1">
                {stages
                  .filter((x) => x.name !== currentStageStatus.name)
                  .map((stage, index) => (
                    <Menu.Item
                      key={stage._id}
                      as={Fragment}
                      disabled={stage.name === currentStageStatus.name}
                    >
                      {({ active }) => (
                        <button
                          onClick={async () => {
                            await onStageSelected(stage.name);
                            setCurrentStage(getStageByName(stages, stage.name));
                          }}
                          type="button"
                          key={stage._id}
                          name={stage.name}
                          id={stage._id}
                          className={joinClassNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            stage.name === currentStageStatus.name
                              ? 'opacity-75'
                              : '',
                            'group flex w-full items-center px-2 py-1.5',
                          )}
                        >
                          <span
                            className={joinClassNames(
                              stage.bgColor
                                ? stage.bgColor + ' ' + stage.textColor
                                : getColorByIndex(index, stages.length)
                                    .allColors,
                              'inline-flex items-center rounded-sm px-2.5 py-0.5 text-2xs font-semibold tracking-wide',
                            )}
                          >
                            {stage.name}
                          </span>
                        </button>
                      )}
                    </Menu.Item>
                  ))}
              </div>
            )}
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={joinClassNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'flex w-full px-3 py-1.5 text-xs',
                    )}
                    onClick={() => {
                      navigate(STARTUP_ROUND_MANAGER_ROUTE, {
                        state: {
                          openDialog: 'manageStages',
                        },
                      });
                    }}
                  >
                    {stages.filter((x) => x.name !== currentStageStatus.name)
                      .length === 0
                      ? 'Add Pipeline Stages'
                      : 'Manage Stages'}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {currentStageStatus.name === currentStage?.name && (
        <div className="flex flex-col items-center justify-start text-xs">
          <p>
            Since&nbsp;
            <DateString date={currentStageStatus.createdOn} />
          </p>

          <ActiveStageDurationLeft
            durationLeft={currentStageStatus.durationLeft}
          />
        </div>
      )}
    </div>
  );
}

export default InvestorPipelineStageSelector;
