import React from 'react';
import { AggregateDealShareRequest } from '/../libs/shared-types/src/types/view/APIResponse';
import PaginatedTable from '/src/components/table/PaginatedTable';
import DealAccessRequestsMadeTableRow from './DealAccessRequestsMadeTableRow';
import Alert from '/src/components/notifications/Alert';

interface DealAccessRequestsMadeTableProps {
  requests: AggregateDealShareRequest[];
  isLoading: boolean;
  isQuerying: boolean;
}

function DealAccessRequestsMadeTable({
  requests,
  isLoading,
  isQuerying,
}: DealAccessRequestsMadeTableProps): JSX.Element {
  const headerValues = [
    { element: <span>Company</span> },
    { element: <span>Stage</span> },
    { element: <span>Industries</span> },
    { element: <span>Geography</span> },
    { element: <span>Investor</span> },
    { element: <span>Firm & Role</span> },
    { element: <span>Requested</span> },
  ];

  if (isLoading) {
    return <></>;
  }

  if (isQuerying && requests.length === 0) {
    return <p>No results found</p>;
  }

  if (requests.length === 0) {
    return (
      <Alert
        color="blue"
        alertType="Info"
        content={
          <p>
            Hmm... it looks like you have not made any requests to access deals.
          </p>
        }
        isShown={true}
        canDismiss={false}
      />
    );
  }

  return (
    <section>
      <PaginatedTable
        headers={headerValues}
        searchPlaceholder="Search Access Requests"
        rowComponents={requests.map((request) => (
          <DealAccessRequestsMadeTableRow
            key={request.request._id}
            startup={request.startup}
            investor={request.investor}
            requestId={request.request._id}
            requestedOn={request.request?.createdOn}
          />
        ))}
      />
    </section>
  );
}

export default DealAccessRequestsMadeTable;
