import React from 'react';
import * as yup from 'yup';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import BooleanSelectInput from '../../inputs/BooleanSelectInput';

const HasUsersField: FormField = {
  fieldComponent: (
    <BooleanSelectInput
      label="Do you have users?"
      name={StartupFieldNames.HasUsers}
      isClearable
      tooltip="Are there users (outside of the startup team) who use the product?"
    />
  ),
  validation: yup.object({
    [StartupFieldNames.HasUsers]: yup.bool().nullable(),
  }),
};

export default HasUsersField;
