import React, { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  STARTUP_PROFILE_ROUTE,
  SCREENING_ROUTE,
  SHARE_LINK_DATA_ACCESS_ROUTE,
  PUBLIC_NOT_FOUND_ROUTE,
  LOGIN_ROUTE,
} from '/src/constants/Routes';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';

import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import { localStorageGetAuthenticatedAccountType } from '/src/middleware/LocalStorage';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import AuthService from '/src/services/AuthService';
import consumeLink from '../../../services/LinkManager';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import PublicDeal from '../PublicDeal/PublicDeal';
import PublicInvestorProfile from '../PublicInvestorProfile/PublicInvestorProfile';
import { validateToken } from '/src/services/ValidateToken';
import { parseFlowlieAuthToken } from '/src/services/jwt';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';

function LinkShareEntryPoint(): JSX.Element {
  const { linkShareToken } = useParams();
  const navigate = useNavigate();
  const path = useLocation();

  const { setToastConfiguration } = useContext(SharedStateContext);
  const { invitation, setInvitation } = useContext(AuthenticationContext);

  function redirectToApp() {
    const accountType = localStorageGetAuthenticatedAccountType();

    if (accountType === AccountTypes.Investor) {
      navigate(SCREENING_ROUTE);
    } else if (accountType === AccountTypes.Startup) {
      navigate(STARTUP_PROFILE_ROUTE);
    } else {
      navigate(LOGIN_ROUTE);
    }
  }

  async function parseTokenAndRedirect() {
    if (!linkShareToken) {
      navigate(PUBLIC_NOT_FOUND_ROUTE);
      return;
    }

    const parsedLinkShareToken = await validateToken(linkShareToken);

    if (parsedLinkShareToken === undefined) {
      setToastConfiguration({
        isError: true,
        message: 'The link you used was invalid',
      });
      navigate(SHARE_LINK_DATA_ACCESS_ROUTE);
      return;
    }

    setInvitation({
      shareToken: linkShareToken,
      linkType: parsedLinkShareToken.linkType,
      accountType: parsedLinkShareToken.originatorType,
      accountId: parsedLinkShareToken.originatorId,
      fullName: parsedLinkShareToken.originatorName,
    });

    if (
      (parsedLinkShareToken.linkType === LinkTypes.Dealshare &&
        path.pathname.includes(LinkTypes.Flowlink)) ||
      (parsedLinkShareToken.linkType === LinkTypes.Flowlink &&
        path.pathname.includes(LinkTypes.Dealshare))
    ) {
      redirectToApp();
      return;
    }

    if (
      path.pathname.includes(LinkTypes.Dealshare) &&
      AuthService.isLoggedIn() &&
      localStorageGetAuthenticatedAccountType() === AccountTypes.Startup
    ) {
      // Nothing to do, because startups do not consume dealshare links
      redirectToApp();
      return;
    }

    if (path.pathname.includes(LinkTypes.Flowlink)) {
      const isStartupFlowlink =
        parsedLinkShareToken.originatorType === AccountTypes.Startup;

      if (!AuthService.isLoggedIn()) {
        return;
      }

      const flowlieAuthToken = localStorage.getItem(
        LocalStorageKeysConst.FLOWLIE_TOKEN
      );
      if (!flowlieAuthToken) {
        throw Error('User should be logged in');
      }

      const parsedFlowlieAuthToken = parseFlowlieAuthToken(flowlieAuthToken);

      const isOwnFlowlink =
        parsedLinkShareToken.originatorId ===
        parsedFlowlieAuthToken.userInfo._id;
      if (isOwnFlowlink) {
        redirectToApp();
        return;
      }

      if (
        isStartupFlowlink &&
        localStorageGetAuthenticatedAccountType() === AccountTypes.Investor
      ) {
        try {
          setInvitation(undefined);
          const data = await consumeLink(linkShareToken, LinkTypes.Flowlink);
          setToastConfiguration({
            isError: data.error,
            message: data.message,
          });
          navigate(`/app/deal-detail/${parsedLinkShareToken?.originatorId}`);
        } catch {
          redirectToApp();
          return;
        }
      }

      if (
        isStartupFlowlink &&
        localStorageGetAuthenticatedAccountType() === AccountTypes.Startup
      ) {
        redirectToApp();
        return;
      }
    }
  }

  const spinner = (
    <div className="flex h-screen items-center justify-center">
      <LoadingSpinner size={14} color="blue" />
    </div>
  );

  React.useEffect(() => {
    parseTokenAndRedirect();
  }, []);

  if (
    !invitation ||
    !linkShareToken ||
    invitation.shareToken !== linkShareToken
  ) {
    return spinner;
  }

  if (invitation.linkType === LinkTypes.Dealshare) {
    return (
      <PublicDeal
        shareToken={invitation.shareToken}
        linkType={LinkTypes.Dealshare}
      />
    );
  }

  if (
    invitation.accountType === AccountTypes.Startup &&
    invitation.linkType === LinkTypes.Flowlink
  ) {
    return (
      <PublicDeal
        shareToken={invitation.shareToken}
        linkType={LinkTypes.Flowlink}
      />
    );
  }

  if (
    invitation.accountType === AccountTypes.Investor &&
    invitation.linkType === LinkTypes.Flowlink
  ) {
    return <PublicInvestorProfile token={invitation.shareToken} />;
  }

  return spinner;
}

export default LinkShareEntryPoint;
