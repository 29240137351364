import React from 'react';
import { Field } from 'formik';
import CustomNumericInput from './CustomNumericInput';

export default function MoneyInput(props: any): JSX.Element {
  return (
    <Field
      className="input"
      component={CustomNumericInput}
      currency="USD"
      step={1000}
      {...props}
    />
  );
}
