import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

// On MAC OS Keyboard, the Command key is the MetaKey

export enum KeyModifiers {
  Alt = 'alt',
  Ctrl = 'ctrl',
  Meta = 'meta',
  Shift = 'shift',
}

function isKeyModifier(event: KeyboardEvent, modifier: KeyModifiers): boolean {
  switch (modifier) {
    case KeyModifiers.Alt: {
      return event.altKey === true;
    }
    case KeyModifiers.Ctrl: {
      return event.ctrlKey === true;
    }
    case KeyModifiers.Meta: {
      return event.metaKey === true;
    }
    case KeyModifiers.Shift: {
      return event.shiftKey === true;
    }
    default:
      return false;
  }
}

function useKeyPress(
  keys: string[],
  callback: any,
  modifier?: KeyModifiers,
  node = null
): void {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // Listen only if the right key modifier was pressed
      if (
        modifier !== undefined &&
        isKeyModifier(event, modifier) &&
        keys.some((key) => event.key === key)
      ) {
        callbackRef.current(event);
        return;
      }

      // No modifier provided, so check if key pressed is part of the keys given
      if (modifier === undefined && keys.some((key) => event.key === key)) {
        event.preventDefault();
        callbackRef.current(event);
      }
    },
    [keys]
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;

    // attach the event listener
    targetNode.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () =>
      targetNode && targetNode.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, node]);
}

export default useKeyPress;
