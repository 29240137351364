import { refreshTokens } from '../middleware/Cognito';
import { heapReset } from '../middleware/Heap';
import { getVisitorSessionToken } from '../middleware/SessionStorage';
import { parseFlowlieAuthToken } from './jwt';
import { EnvironmentTypes } from '/../libs/shared-types/src/constants/EnvironmentTypes';
import { MINUTE_MS, WEEK_MS } from '/../libs/shared-types/src/constants/time';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';

class AuthService {
  /**
   * Checks if there is a (valid) user logged in on the site.
   * @returns if a balid user logged in.
   */
  static isLoggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = AuthService.getFlowlieToken();
    return token !== '';
  }

  static isVisitorSession() {
    const token = AuthService.getSessionToken();
    return token !== '';
  }

  static isAdmin() {
    if (!AuthService.isLoggedIn()) {
      return false;
    }
    const token = AuthService.getFlowlieToken();
    const parsedToken = parseFlowlieAuthToken(token);
    return parsedToken.userInfo.isAdmin;
  }

  static hasGoogleAuth() {
    return Object.keys(localStorage).some((key) => key.includes('google'));
  }

  /**
   * Gets flowlie auth token from storage (JWT)
   * @returns The Token
   */
  static getFlowlieToken() {
    let token = localStorage.getItem(LocalStorageKeysConst.FLOWLIE_TOKEN);
    if (token != null) {
      token = token.replace(/"/g, '');
      return token;
    }
    return '';
  }

  /**
   * Gets visitor session token from session storage
   * @returns The Token
   */
  static getSessionToken() {
    let token = getVisitorSessionToken();
    if (token != null) {
      token = token.replace(/"/g, '');
      return token;
    }
    return '';
  }

  /**
   * @returns The Token or null.
   */
  static async getAWSToken(): Promise<string | null> {
    // in a local env, we don't use cognito, so this token doesn't apply
    if (process.env.REACT_APP_ENV === EnvironmentTypes.Local) {
      return '';
    }

    // extract the token from local storage, note that it could be null
    let token = localStorage.getItem(LocalStorageKeysConst.COGNITO_ID);
    if (!token) {
      return '';
    }
    const lastUpdate = Number(
      localStorage.getItem(LocalStorageKeysConst.LAST_AUTH_RELOAD)
    );
    const loginDate = Number(
      localStorage.getItem(LocalStorageKeysConst.LOGIN_DATE)
    );

    // We Substract one minute from the week to avoid race conditions
    // since the token itself expires in one week
    if (Date.now() - loginDate > WEEK_MS - MINUTE_MS) {
      this.logout();
      return '';
    }

    const timeSinceLastUpdate = Date.now() - lastUpdate;
    const refreshIdTokenWindow = MINUTE_MS * 50; // 50 minutes

    if (timeSinceLastUpdate > refreshIdTokenWindow) {
      // the regular auth token expires, in 50 minutes, but in this case we can
      // use the refresh token to get them a new auth token
      const session = await refreshTokens();
      // update the token variable to the newly fetched token
      token = session.getIdToken().getJwtToken();

      // ensure we update local storage
      localStorage.setItem(LocalStorageKeysConst.COGNITO_ID, token);
      localStorage.setItem(
        LocalStorageKeysConst.LAST_AUTH_RELOAD,
        Date.now().toString()
      );
    }

    return token;
  }

  static async getInternalAuthHeaders(): Promise<string[][]> {
    return [
      ['x-flowlie-auth', `Bearer ${AuthService.getFlowlieToken()}`],
      ['Authorization', `${await AuthService.getAWSToken()}`],
    ];
  }

  static async getVisitorSessionAuthHeaders(): Promise<string[][]> {
    return [['x-flowlie-auth', `Bearer ${AuthService.getSessionToken()}`]];
  }

  /**
   * Logs out a user.
   */
  static logout() {
    // Clear user token and profile data from storage & redirect to landing page
    heapReset();
    localStorage.clear();
    window.location.href = '/';
  }
}

export default AuthService;
