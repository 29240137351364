import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { enumToSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';

const TypeField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      closeMenuOnSelect
      label="Investor Type"
      name={InvestorFieldNames.Type}
      options={enumToSelectOptions(InvestorTypes)}
      placeholder="Select investor type..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.Type]: yup.string().required('Required'),
  }),
};

export default TypeField;
