import React from 'react';
import { Formik, Form } from 'formik';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import INVESTOR_FLOWLINK_FORM_FIELD_MAPPING from './InvestorFlowlinkFormFieldMapping';
import InvestorFlowlinkFormFieldNames from '/src/enums/InvestorFlowlinkFormFieldNames';

export interface DiscoveryDialogValues {
  [InvestorFlowlinkFormFieldNames.LinkDiscovery]: string;
  [InvestorFlowlinkFormFieldNames.ConnectionMessage]?: string;
}

interface DiscoveredFromDialogProps {
  onSuccess: (value: DiscoveryDialogValues) => void;
  initialValues: DiscoveryDialogValues;
}

export function DiscoveredFromDialog({
  onSuccess,
  initialValues,
}: DiscoveredFromDialogProps): JSX.Element {
  return (
    <main>
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <Formik
          initialValues={initialValues}
          validateOnMount
          validationSchema={Object.values(INVESTOR_FLOWLINK_FORM_FIELD_MAPPING)
            .map((field) => field?.validation)
            .reduce((merged, schema) => merged.concat(schema))}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            onSuccess(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              {Object.values(INVESTOR_FLOWLINK_FORM_FIELD_MAPPING).map(
                (field) => (
                  <div
                    key={Object.values(
                      INVESTOR_FLOWLINK_FORM_FIELD_MAPPING
                    ).indexOf(field)}
                  >
                    {field.fieldComponent}
                  </div>
                )
              )}
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="app-button--primary"
                  disabled={!isValid || isSubmitting}
                >
                  Continue
                  {isSubmitting && (
                    <div className="mr-2 w-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </main>
  );
}
