import AntidilutionRightsField from './fields/AntidilutionRightsField';
import BridgeNumberField from './fields/BridgeNumberField';
import ConversionTermField from './fields/ConversionTermField';
import DiscountField from './fields/DiscountField';
import EstimatedCloseDateField from './fields/EstimatedCloseDateField';
import HasLeadInvestorField from './fields/HasLeadInvestorField';
import HasTermSheetField from './fields/HasTermSheetField';
import InterestField from './fields/InterestField';
import IsBridgeField from './fields/IsBridgeField';
import LiquidationParticipationField from './fields/LiquidationParticipationField';
import LiquidationPreferenceField from './fields/LiquidationPreferenceField';
import HasRightsPayToPlayField from './fields/HasRightsPayToPlayField';
import PrepaymentField from './fields/PrepaymentField';
import PricePerShareField from './fields/PricePerShareField';
import RaisedAmountField from './fields/RaisedAmountField';
import RaiseTargetField from './fields/RaiseTargetField';
import RoundStageField from './fields/RoundStageField';
import RoundTypeField from './fields/RoundTypeField';
import RunwayTargetField from './fields/RunwayTargetField';
import ValuationCapField from './fields/ValuationCapField';
import ValuationCapTypeField from './fields/ValuationCapTypeField';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import FormField from '/src/interfaces/FormField';
import HasRightsMfnField from './fields/HasRightsMfnField';
import HasRightsDragAlongField from './fields/HasRightsDragAlongField';
import SafeTypeField from './fields/SafeTypeField';
import OpenToInvestorTypesField from './fields/OpenToInvestorTypesField';

const ROUND_MANAGER_CORE_MAPPING: { [key: string]: FormField } = {
  [RoundFieldNames.BridgeNumber]: BridgeNumberField,
  [RoundFieldNames.ConversionTerm]: ConversionTermField,
  [RoundFieldNames.Discount]: DiscountField,
  [RoundFieldNames.EstimatedCloseDate]: EstimatedCloseDateField,
  [RoundFieldNames.HasLeadInvestor]: HasLeadInvestorField,
  [RoundFieldNames.HasTermSheet]: HasTermSheetField,
  [RoundFieldNames.Interest]: InterestField,
  [RoundFieldNames.IsBridge]: IsBridgeField,
  [RoundFieldNames.RaiseTarget]: RaiseTargetField,
  [RoundFieldNames.RaisedAmount]: RaisedAmountField,
  [RoundFieldNames.RoundStage]: RoundStageField,
  [RoundFieldNames.RoundType]: RoundTypeField,
  [RoundFieldNames.SafeType]: SafeTypeField,
  [RoundFieldNames.ValuationCapType]: ValuationCapTypeField,
  [RoundFieldNames.ValuationCap]: ValuationCapField,
};

export const ROUND_MANAGER_ROUND_FIELD_MAPPING: { [key: string]: FormField } = {
  ...ROUND_MANAGER_CORE_MAPPING,
  [RoundFieldNames.AntidilutionRights]: AntidilutionRightsField,
  [RoundFieldNames.HasRightsDragAlong]: HasRightsDragAlongField,
  [RoundFieldNames.HasRightsMfn]: HasRightsMfnField,
  [RoundFieldNames.HasRightsPayToPlay]: HasRightsPayToPlayField,
  [RoundFieldNames.LiquidationParticipation]: LiquidationParticipationField,
  [RoundFieldNames.LiquidationPreference]: LiquidationPreferenceField,
  [RoundFieldNames.OpenToInvestorTypes]: OpenToInvestorTypesField,
  [RoundFieldNames.Prepayment]: PrepaymentField,
  [RoundFieldNames.PricePerShare]: PricePerShareField,
  [RoundFieldNames.RunwayTarget]: RunwayTargetField,
};

export const COMPLETE_PROFILE_ROUND_FIELD_MAPPING: {
  [key: string]: FormField;
} = {
  ...ROUND_MANAGER_CORE_MAPPING,
};
