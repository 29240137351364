import { RoundStages } from '../constants/RoundStages';
import {
  getEstimatedPostCap,
  shouldHaveValuationCap,
} from '../extensions/RoundExtensions';
import { ElementColor } from '../types/Utility/Color';
import { Round } from '../types/model/Round';
import { calculateRevenueMultiple } from './RoundCalculations';

export enum RevenueMultipleMetric {
  NoMultiple = 'No Revenue Multiple',
  HighMultiple = 'High Revenue Multiple',
  AppropriateMultiple = 'Appropriate Revenue Multiple',
  LowMultiple = 'Low Revenue Multiple',
  VeryLowMultiple = 'Very Low Revenue Multiple',
}

const revenueMultipleMetricMap = new Map<
  RevenueMultipleMetric,
  {
    explanation: string;
    color: ElementColor;
  }
>([
  [
    RevenueMultipleMetric.NoMultiple,
    {
      explanation: 'A revenue multiple does not apply to your current raise.',
      color: {
        bgColor: 'bg-gray-100',
        textColor: 'text-gray-900',
      },
    },
  ],
  [
    RevenueMultipleMetric.HighMultiple,
    {
      explanation:
        'You are raising at a high revenue multiple which yields a great valuation at the current round but may put additional pressure on revenue targets for the future or risk raising at a flat or lower valuation for the next round.',
      color: {
        bgColor: 'bg-lime-100',
        textColor: 'text-lime-900',
      },
    },
  ],
  [
    RevenueMultipleMetric.AppropriateMultiple,
    {
      explanation:
        'You are raising at an appropriate revenue multiple which reduces the risk that the next round will be at a lower valuation in case the revenue goals are not met.',
      color: {
        bgColor: 'bg-green-100',
        textColor: 'text-green-900',
      },
    },
  ],
  [
    RevenueMultipleMetric.LowMultiple,
    {
      explanation:
        'You are raising at a low revenue multiple which means you can probably negotiate better terms at the current round.',
      color: {
        bgColor: 'bg-orange-100',
        textColor: 'text-orange-900',
      },
    },
  ],
  [
    RevenueMultipleMetric.VeryLowMultiple,
    {
      explanation:
        'You are raising at a very low revenue multiple which means you are likely significantly undervaluing your company at the current round.',
      color: {
        bgColor: 'bg-red-100',
        textColor: 'text-red-900',
      },
    },
  ],
]);

function getRevenueMultipleInfo(
  revenueMultiple: number,
  upperThresholds: {
    appropriate: number;
    low: number;
    veryLow: number;
  },
  additionalInfo: string
): RoundRevenueMultipleAnalysis {
  let revenueMultipleMetric = RevenueMultipleMetric.VeryLowMultiple;

  if (revenueMultiple > upperThresholds.appropriate) {
    revenueMultipleMetric = RevenueMultipleMetric.HighMultiple;
  } else if (revenueMultiple > upperThresholds.low) {
    revenueMultipleMetric = RevenueMultipleMetric.AppropriateMultiple;
  } else if (revenueMultiple > upperThresholds.veryLow) {
    revenueMultipleMetric = RevenueMultipleMetric.LowMultiple;
  }

  const mapValue = revenueMultipleMetricMap.get(revenueMultipleMetric);
  if (!mapValue) {
    throw new Error(
      `Revenue multiple metric ${revenueMultipleMetric} not found`
    );
  }

  return {
    revenueMultiple,
    revenueMultipleMetric,
    explanation: mapValue.explanation,
    additionalInfo,
    color: mapValue.color,
  };
}

export interface RoundRevenueMultipleAnalysis {
  revenueMultiple: number;
  revenueMultipleMetric: RevenueMultipleMetric;
  explanation: string;
  additionalInfo: string;
  color: ElementColor;
}

export function getRevenueMultipleAnalysis(
  round: Round,
  monthlyRevenue: number
): RoundRevenueMultipleAnalysis | undefined {
  const estimatedPostCap = getEstimatedPostCap(round);

  if (estimatedPostCap === undefined) {
    return undefined;
  }

  const revenueMultiple = calculateRevenueMultiple(
    monthlyRevenue,
    estimatedPostCap
  );

  if (
    round.roundStage === RoundStages.Angel ||
    round.roundStage === RoundStages.PreSeed
  ) {
    const mapValue = revenueMultipleMetricMap.get(
      RevenueMultipleMetric.NoMultiple
    );
    return {
      revenueMultiple: revenueMultiple > 100 ? 100 : revenueMultiple,
      revenueMultipleMetric: RevenueMultipleMetric.NoMultiple,
      explanation: mapValue?.explanation ?? '',
      additionalInfo: `During ${round.roundStage} rounds, due to the fact that most companies are generating little or no revenue, the revenue multiple is not a metric that investors use.`,
      color: mapValue?.color ?? {
        bgColor: 'bg-gray-100',
        textColor: 'text-gray-900',
      },
    };
  }

  if (round.roundStage === RoundStages.Seed) {
    const upperThresholds = { appropriate: 40, low: 15, veryLow: 7.5 };
    return getRevenueMultipleInfo(
      revenueMultiple,
      upperThresholds,
      `During ${round.roundStage} rounds founders typically raise at a revenue multiple between ${upperThresholds.low}x and ${upperThresholds.appropriate}x`
    );
  }

  if (round.roundStage === RoundStages.SeriesA) {
    const upperThresholds = { appropriate: 30, low: 10, veryLow: 5 };
    return getRevenueMultipleInfo(
      revenueMultiple,
      upperThresholds,
      `During ${round.roundStage} rounds founders typically raise at a revenue multiple between ${upperThresholds.low}x and ${upperThresholds.appropriate}x`
    );
  }

  if (
    round.roundStage === RoundStages.SeriesB ||
    round.roundStage === RoundStages.SeriesC ||
    round.roundStage === RoundStages.SeriesD ||
    round.roundStage === RoundStages.SeriesE ||
    round.roundStage === RoundStages.SeriesF
  ) {
    const upperThresholds = { appropriate: 15, low: 7.5, veryLow: 2.5 };
    return getRevenueMultipleInfo(
      revenueMultiple,
      upperThresholds,
      `During ${round.roundStage} rounds founders typically raise at a revenue multiple between ${upperThresholds.low}x and ${upperThresholds.appropriate}x`
    );
  }

  // We should never get here
  return undefined;
}
