import React from 'react';
import * as yup from 'yup';

import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import BooleanSelectInput from '../../inputs/BooleanSelectInput';
import { useFormikContext } from 'formik';
import { REVENUE_TYPE_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[StartupFieldNames.RevenueType] ===
    REVENUE_TYPE_OPTIONS[5].value ? (
    <BooleanSelectInput
      label="Do you hold an inventory of goods transacted?"
      name={StartupFieldNames.HasInventory}
      tooltip={
        <ul className="list-disc space-y-1 pl-4">
          <li>
            Example companies that hold inventory: Amazon, Walmart, BestBuy,
            Costco, Home Depot, Aldi
          </li>
          <li>
            Example companies that DO NOT hold inventory: Uber, Instacart,
            Stripe, eBay, Turo, SeatGeek
          </li>
        </ul>
      }
      isClearable
    />
  ) : (
    <></>
  );
}

const HasInventoryField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.HasInventory]: yup.boolean().nullable(),
  }),
};

export default HasInventoryField;
