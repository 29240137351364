import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import { InvestorAccessDataReceivedFromView } from '/../libs/shared-types/src/types/view/InvestorAccessDataReceivedFromView';
import { InvestorAccessDataSharedWithView } from '/../libs/shared-types/src/types/view/InvestorAccessDataSharedWithView';
import { toDashCase } from '/src/util/formatting/strings';
import DropdownSelect from '/src/components/DropdownSelect';
import SecondaryTabsHeader from '/src/components/tabs/SecondaryTabsHeader';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import DealDetailSharedWithTable from '../DealDetailSharedWithTable';
import DealDetailReceivedFromTable from '../DealDetailReceivedFromTable';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';

function generateReceivedFromTable(
  receivedFromInvestorAccess: InvestorAccessDataReceivedFromView[]
): JSX.Element {
  if (receivedFromInvestorAccess.length === 0) {
    return <p>At this stage no one has shared this startup with you.</p>;
  }

  return (
    <DealDetailReceivedFromTable
      investorAccessData={receivedFromInvestorAccess}
    />
  );
}

function generateSharedWithTable(
  sharedWithInvestorAccess: InvestorAccessDataSharedWithView[]
): JSX.Element {
  if (sharedWithInvestorAccess.length === 0) {
    return <p>You have not shared this startup at this stage yet.</p>;
  }

  return (
    <DealDetailSharedWithTable investorAccessData={sharedWithInvestorAccess} />
  );
}

interface DealDetailSharingTabProps {
  dealStatus: DealStatusTypes;
  receivedFrom: InvestorAccessDataReceivedFromView[];
  sharedWith: InvestorAccessDataSharedWithView[];
  stages: string[];
  currentStage: string;
}

function DealDetailSharingTab({
  dealStatus,
  receivedFrom,
  sharedWith,
  stages,
  currentStage,
}: DealDetailSharingTabProps): JSX.Element {
  const tabNames = ['Received From', 'Shared With'];

  const [initialReceivedFrom, setInitialReceivedFrom] = React.useState<
    InvestorAccessDataReceivedFromView[]
  >([]);
  const [initialSharedWith, setInitialSharedWith] = React.useState<
    InvestorAccessDataSharedWithView[]
  >([]);

  const [filteredReceivedFrom, setFilteredReceivedFrom] = React.useState<
    InvestorAccessDataReceivedFromView[]
  >([]);
  const [filteredSharedWith, setFilteredSharedWith] = React.useState<
    InvestorAccessDataSharedWithView[]
  >([]);

  const adjustedStages = stages.length === 0 ? [currentStage] : stages;

  function filterLists(stage: string) {
    setFilteredReceivedFrom(
      initialReceivedFrom.filter((x) => x.roundDisplayName === stage)
    );
    setFilteredSharedWith(
      initialSharedWith.filter((x) => x.roundDisplayName === stage)
    );
  }

  React.useEffect(() => {
    setInitialReceivedFrom(receivedFrom);
    setInitialSharedWith(sharedWith);
  }, [receivedFrom, sharedWith]);

  React.useEffect(() => {
    filterLists(currentStage);
  }, [initialReceivedFrom, initialSharedWith]);

  return receivedFrom === undefined || sharedWith === undefined ? (
    <div className="mx-auto mt-8 max-w-full">
      <PageLoadingSpinner message="Gathering data... 👌" />
    </div>
  ) : (
    <main className="mx-auto mt-8 max-w-full">
      <section className="flex items-center justify-between">
        <SecondaryTabsHeader
          tabNames={
            dealStatus !== DealStatusTypes.Screening ? tabNames : [tabNames[0]]
          }
        />
        <DropdownSelect
          onSelectionChange={filterLists}
          initialValue={currentStage}
          label="Round Stage"
          items={adjustedStages}
        />
      </section>
      <div className="mt-4">
        <Routes>
          {['', tabNames[0]].map((path) => (
            <Route
              key={path}
              path={toDashCase(path)}
              element={generateReceivedFromTable(filteredReceivedFrom)}
            />
          ))}

          <Route
            path={toDashCase(tabNames[1])}
            element={generateSharedWithTable(filteredSharedWith)}
          />
          <Route path="*" element={<Navigate to={NOT_FOUND_ROUTE} replace />} />
        </Routes>
      </div>
    </main>
  );
}

export default DealDetailSharingTab;
