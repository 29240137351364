import React from 'react';
import * as yup from 'yup';
import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';
import FormField from '/src/interfaces/FormField';
import StarRatingSelectInput from '../../inputs/StarRatingSelectInput';

const DealRatingField: FormField = {
  fieldComponent: (
    <StarRatingSelectInput
      label="What would you rate this deal?"
      menuPortalTarget={document.querySelector('body')}
      name={RecordInvestmentFieldNames.DealRating}
      secondaryLabel="This rating is optional and will only be visible to you"
    />
  ),
  validation: yup.object({
    [RecordInvestmentFieldNames.DealRating]: yup
      .number()
      .typeError('Must be a valid star rating'),
  }),
};

export default DealRatingField;
