import React from 'react';

interface GaugeProgressProps {
  value: number;
  maxValue: number;
  displayValue?: string;
  label?: string;
  color?: 'blue' | 'red' | 'orange' | 'yellow' | 'teal' | 'green';
}

const colorClasses = {
  blue: { strokeColor: 'text-blue-500', textColor: 'text-blue-600' },
  red: { strokeColor: 'text-red-500', textColor: 'text-red-600' },
  green: { strokeColor: 'text-green-500', textColor: 'text-green-600' },
  yellow: { strokeColor: 'text-yellow-500', textColor: 'text-yellow-600' },
  orange: { strokeColor: 'text-orange-500', textColor: 'text-orange-600' },
  teal: { strokeColor: 'text-teal-500', textColor: 'text-teal-600' },
};

function GaugeProgress({
  value,
  maxValue,
  displayValue = value.toString(),
  label,
  color = 'blue',
}: GaugeProgressProps) {
  const { strokeColor, textColor } = colorClasses[color];

  const percentage = (value / maxValue) * 100;
  const segments = 8; // Number of segments for 3/4 circumference
  const segmentAngle = 270 / segments; // Angle covered by each segment
  const colors = [
    '#ef4444',
    '#f59e0b',
    '#fbbf24',
    '#facc15',
    '#a3e635',
    '#4ade80',
    '#34d399',
    '#10b981',
  ]; // Segment colors
  const radius = 56; // Radius of the circle
  const gap = 5; // Gap between segments

  const renderSegments = () => {
    const segmentsArray = [];
    for (let i = 0; i < segments; i++) {
      const isActive = percentage >= ((i + 1) / segments) * 100;
      const startAngle = segmentAngle * i - 135; // Rotate 135 degrees to the left
      const endAngle = segmentAngle * (i + 1) - 135 - gap;
      const startX = 64 + radius * Math.cos((Math.PI * startAngle) / 180);
      const startY = 64 + radius * Math.sin((Math.PI * startAngle) / 180);
      const endX = 64 + radius * Math.cos((Math.PI * endAngle) / 180);
      const endY = 64 + radius * Math.sin((Math.PI * endAngle) / 180);

      segmentsArray.push(
        <path
          key={i}
          d={`M ${startX} ${startY} A ${radius} ${radius} 0 0 1 ${endX} ${endY}`}
          stroke={isActive ? colors[i] : '#e5e7eb'}
          strokeWidth="8"
          fill="none"
        />,
      );
    }
    return segmentsArray;
  };

  return (
    <div className="flex justify-center">
      <div className="relative size-40">
        <svg
          className="size-full rotate-[135deg]"
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className="stroke-current text-gray-200"
            strokeWidth="1"
            strokeDasharray="75 100"
            strokeLinecap="round"
          ></circle>

          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className={`stroke-current ${strokeColor}`}
            strokeWidth="2"
            strokeDasharray={`${75 * (value / maxValue)} 100`}
            strokeLinecap="round"
          ></circle>
        </svg>

        <div className="absolute start-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-center">
          <span className={`text-4xl font-bold ${textColor}`}>
            {displayValue}
          </span>
          <span className={`block ${textColor}`}>{label}</span>
        </div>
      </div>
    </div>
  );
}

export default GaugeProgress;
