import React from 'react';
import { numberWithCommas } from '/src/util/formatting/numbers';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import { generateTag } from '/src/util/generateTagCloud';

interface DealDetailUserCardProps {
  startup: PublicStartup;
}

function DealDetailUserCard({ startup }: DealDetailUserCardProps): JSX.Element {
  if (
    startup.hasCustomers !== undefined ||
    startup.hasUsers !== undefined ||
    startup.userCount !== undefined ||
    startup.customerCount !== undefined ||
    startup.monthlyActiveUserCount !== undefined ||
    startup.weeklyActiveUserCount !== undefined ||
    startup.dailyActiveUserCount !== undefined ||
    startup.userGrowthRateMom !== undefined ||
    startup.userRetentionRateMom !== undefined ||
    startup.customerGrowthRateMom !== undefined ||
    startup.customerRetentionRateMom !== undefined ||
    startup.pilotsCount !== undefined ||
    startup.netPromoterScore !== undefined ||
    startup.additionalTractionInfo !== undefined
  ) {
    return (
      <section>
        <div className="rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Customer Traction
            </h3>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
              {startup.hasUsers !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Users</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {!startup.hasUsers && numberWithCommas(0)}

                    {startup.hasUsers &&
                      !startup.userCount &&
                      generateTag('Has users')}

                    {startup.hasUsers &&
                      startup.userCount &&
                      numberWithCommas(startup.userCount)}
                  </dd>
                </div>
              )}
              {startup.hasCustomers !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Paying Customers
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {!startup.hasCustomers && numberWithCommas(0)}

                    {startup.hasCustomers &&
                      !startup.customerCount &&
                      generateTag('Has customers')}

                    {startup.hasCustomers &&
                      startup.customerCount &&
                      numberWithCommas(startup.customerCount)}
                  </dd>
                </div>
              )}
              {startup.monthlyActiveUserCount !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Monthly Active Users
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {numberWithCommas(startup.monthlyActiveUserCount)}
                  </dd>
                </div>
              )}
              {startup.weeklyActiveUserCount !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Weekly Active Users
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {numberWithCommas(startup.weeklyActiveUserCount)}
                  </dd>
                </div>
              )}
              {startup.dailyActiveUserCount !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Daily Active Users
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {numberWithCommas(startup.dailyActiveUserCount)}
                  </dd>
                </div>
              )}
              {startup.userGrowthRateMom !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    User Growth (MoM)
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {`${startup.userGrowthRateMom} %`}
                  </dd>
                </div>
              )}
              {startup.userRetentionRateMom !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    User Retention (MoM)
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {`${startup.userRetentionRateMom} %`}
                  </dd>
                </div>
              )}
              {startup.userRetentionRateMom !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    User Churn (MoM)
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {`${100 - startup.userRetentionRateMom} %`}
                  </dd>
                </div>
              )}
              {startup.customerGrowthRateMom !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Customer Growth (MoM)
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {`${startup.customerGrowthRateMom} %`}
                  </dd>
                </div>
              )}
              {startup.customerRetentionRateMom !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Customer Retention (MoM)
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {`${startup.customerRetentionRateMom} %`}
                  </dd>
                </div>
              )}

              {startup.pilotsCount !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Completed & Ongoing Pilots
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {numberWithCommas(startup.pilotsCount)}
                  </dd>
                </div>
              )}

              {startup.netPromoterScore !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Net Promoter Score
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {startup.netPromoterScore.toFixed(2)}
                  </dd>
                </div>
              )}

              {startup.additionalTractionInfo !== undefined && (
                <div className="sm:col-span-3">
                  <dt className="text-sm font-medium text-gray-500">
                    Additional Information
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {startup.additionalTractionInfo}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </div>
      </section>
    );
  }
  return <></>;
}

export default DealDetailUserCard;
