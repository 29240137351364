import React from 'react';
import * as yup from 'yup';
import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';
import FormField from '/src/interfaces/FormField';
import MoneyInput from '../../inputs/MoneyInput';
import {
  INVESTMENT_AMOUNT_MAX,
  INVESTMENT_AMOUNT_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';

const AmountField: FormField = {
  fieldComponent: (
    <MoneyInput
      label="How much are you investing?"
      name={RecordInvestmentFieldNames.Amount}
      step={100}
      tooltip="How much capital you have invested in this company at this round, without including any fees that may have been charged to setup or conduct the investment."
    />
  ),
  validation: yup.object({
    [RecordInvestmentFieldNames.Amount]: yup
      .number()
      .typeError('Must be a number')
      .positive('Must be greater than 0')
      .test(
        'onlyHundredMultiples',
        'Only multiples of $100 are accepted',
        (value): boolean => {
          if (value && value > 0 && value % 100 !== 0) {
            // Return false to indicate the test failed
            return false;
          }
          return true;
        }
      )
      .min(INVESTMENT_AMOUNT_MIN, `Must be at least $ ${INVESTMENT_AMOUNT_MIN}`)
      .max(
        INVESTMENT_AMOUNT_MAX,
        `Cannot be greater than $ ${INVESTMENT_AMOUNT_MAX}`
      )
      .required('Required'),
  }),
};

export default AmountField;
