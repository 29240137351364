import React from 'react';
import * as yup from 'yup';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import {
  REVENUE_GROWTH_MIN,
  REVENUE_GROWTH_MAX,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import PercentageInput from '../../inputs/PercentageInput';

const RevenueGrowthField: FormField = {
  fieldComponent: (
    <PercentageInput
      label="Revenue Growth (MoM)"
      name={StartupFieldNames.RevenueGrowth}
    />
  ),
  validation: yup.object({
    [StartupFieldNames.RevenueGrowth]: yup
      .number()
      .min(
        REVENUE_GROWTH_MIN,
        `Must be greater than or equal to ${REVENUE_GROWTH_MIN}`
      )
      .max(REVENUE_GROWTH_MAX, `Cannot be greater than ${REVENUE_GROWTH_MAX}`)
      .nullable()
      .typeError('Must be a number'),
  }),
};

export default RevenueGrowthField;
