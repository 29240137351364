import { AccountTypes } from '../../constants/AccountTypes';
import { DataRoomAccessStatus } from '../../constants/DataRoom/DataRoomAccessStatus';
import { DealDetailTypes } from '../../constants/DealDetailTypes';
import { LinkTypes } from '../../constants/LinkshareTypes';
import { VerificationStatus } from '../../constants/VerificationStatus';
import { ViewerTypes } from '../../constants/ViewerTypes';
import { Location } from './Location';

export interface Visitor {
  authToken: string;
  email: string;
  firstName: string;
  lastName: string;
  verificationStatus: VerificationStatus;
}

export interface LinkCreatedBy {
  accountId: string;
  accountType: AccountTypes;
  linkType: LinkTypes;
}

export interface DeckStat {
  deckId: string;
  downloadCount: number;
  lastDownloadedOn: Date;
  lastViewedOn: Date;
  viewCount: number;
  viewTime: number;
}

export interface DataRoomStat {
  accessStatus: DataRoomAccessStatus;
  createdOn: Date;
}

export interface DealViewSession {
  _id: string;
  browser: string;
  createdOn: Date;
  dataRoomStats?: DataRoomStat[];
  dealDetailViewTime: number;
  deckStat: DeckStat;
  deviceOs: string;
  deviceType: string;
  investorId?: string;
  ipAddress: string;
  linkCreatedBy?: LinkCreatedBy;
  location?: Location;
  dealDetailType: DealDetailTypes;
  requestId?: string;
  startupId: string;
  viewerType: ViewerTypes;
  visitor?: Visitor;
}

export enum DealViewSessionUpdateType {
  DealViewTime = 'DealViewTime',
  DeckDownloadCount = 'DeckDownloadCount',
  DeckViewCount = 'DeckViewCount',
  DeckViewTime = 'DeckViewTime',
  DataRoomAccessStatus = 'DataRoomAccessStatus',
}

export interface DealViewSessionUpdate {
  shareToken?: string;
  dataRoomAccessStatus?: DataRoomAccessStatus;
  startupId: string;
  dealViewSessionId: string;
  updateType: DealViewSessionUpdateType;
}
