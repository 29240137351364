import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import MoneyInput from '/src/components/inputs/MoneyInput';
import {
  RAISED_AMOUNT_MAX,
  RAISED_AMOUNT_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import { ValuationCapType } from '/../libs/shared-types/src/constants/ValuationCapType';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return isInputShown(RoundFieldNames.RaisedAmount, values) ? (
    <MoneyInput
      label="How much money has already been wired or committed?"
      secondaryLabel="Do not include amounts that have been soft-committed, such as investors telling you a verbal 'yes' or 'maybe'"
      disabled={values[RoundFieldNames.IsEditable] === 'false'}
      name={RoundFieldNames.RaisedAmount}
      tooltip="The amount of money you have already secured from investors during the current financing round"
    />
  ) : (
    <></>
  );
}

const RaisedAmountField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.RaisedAmount]: yup
      .number()
      .typeError('Must be a number')
      .min(RAISED_AMOUNT_MIN, `Must be at least $ ${RAISED_AMOUNT_MIN}`)
      .test(
        'onlyHundredMultiples',
        'Only multiples of $100 are accepted',
        (value: any): boolean => {
          if (value && value > 0 && value % 100 !== 0) {
            // Return false to indicate the test failed
            return false;
          }
          return true;
        },
      )
      .max(RAISED_AMOUNT_MAX, `Cannot be greater than $ ${RAISED_AMOUNT_MAX}`)
      .when(RoundFieldNames.ValuationCap, {
        is: (valuationCap: number) => valuationCap > 0,
        then: (schema) =>
          schema
            .typeError('Must be a number')
            .when(RoundFieldNames.ValuationCapType, {
              is: (capType: string) => capType === ValuationCapType.PostMoney,
              then: (schema) =>
                schema.lessThan(
                  yup.ref(RoundFieldNames.ValuationCap),
                  'Must be less than the Valuation Cap',
                ),
            }),
      })
      .required('Required'),
  }),
};

export default RaisedAmountField;
