import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import websiteSchema from '/src/constants/validation/websiteSchema';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';

const LinkedInField: FormField = {
  fieldComponent: (
    <Field
      component={FormikInput}
      label="LinkedIn URL "
      name={StartupFieldNames.LinkedIn}
      type="text"
    />
  ),
  validation: yup.object({
    [StartupFieldNames.LinkedIn]: websiteSchema(false),
  }),
};

export default LinkedInField;
