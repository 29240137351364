import { listToSelectOptions } from '../../extensions/SelectOptionsExtensions';

const STARTUP_INDUSTRY_VALUES = [
  'Ad-Tech & Marketing',
  'Aerospace & Aviation',
  'Ag-Tech & Farming',
  'Alcoholic Beverages',
  'Animals, Veterinary & Pet-Tech',
  'AR, VR & Metaverse',
  'Architecture & Interior Design',
  'Archiving & Knowledge Storage',
  'Art, Culture & Design',
  'Astrology & Spirituality',
  'Auctions, Authentication & Appraisal',
  'Automotive & AV/EV',
  'Beauty, Cosmetics & Personal Care',
  'Bio-Tech & Pharma',
  'Blockchain & NFTs',
  'Cannabis & CBD',
  'Chemicals, Plastics & Polymers',
  'Clean-Tech & Smart Grids',
  'Cleaning & Laundry',
  'Climate-Tech & Sustainability',
  'Cloud Computing',
  'Communities',
  'Construction-Tech',
  'Consumer Internet',
  'Content Management & Distribution',
  'Creator & Passion Economy',
  'Cybersecurity',
  'Data Analytics & AI/ML',
  'Databases & Software Infrastructure',
  'Dating & Relationships',
  'Death-Tech',
  'Deep-Tech & Hard-Tech',
  'Defense & Military-Tech',
  'Developer Tools',
  'Diet, Nutrition & Supplements',
  'Drones & Autonomous Flight Systems',
  'DTC & DNVB',
  'E-Commerce & Marketplaces',
  'Ed-Tech & Training',
  'Assistive-Tech, Elder-Tech & Care Economy',
  'Energy',
  'ERP, RPA & System Automation',
  'Event Planning',
  'Fashion, Retail & Accessories',
  'Fem-Tech',
  'Fin-Tech: Accounting & Finance',
  'Fin-Tech: Crypto & DeFi',
  'Fin-Tech: Digital Banking & Infrastructure',
  'Fin-Tech: Lending & Marketplaces',
  'Fin-Tech: Payments & POS',
  'Fin-Tech: Personal Finance & Investments',
  'Fin-Tech: Regulatory & Compliance',
  'First Responder-Tech',
  'Food-Tech & Alternative Protein',
  'Food, Nonalcoholic Beverages & CPG',
  'Forestry & Logging',
  'Furniture',
  'Future of Work',
  'Gambling & Betting',
  'Gaming & E-Sports',
  'Gardening & Floral',
  'Genomics & Bio-Hacking',
  'Gov-Tech & Smart Cities',
  'Hardware & IoT',
  'Healthcare, Digital Health & Telemedicine',
  'Home Services',
  'Hospitality',
  'Housing & Co-Living',
  'HR-Tech: Talent & Staffing',
  'HR-Tech: Payroll & Benefits',
  'Identity & Privacy-Tech',
  'Infrastructure & Utilities',
  'Insurance-Tech',
  'Legal-Tech',
  'Manufacturing & 3D Printing',
  'Medical Devices',
  'Mental Health & Wellness',
  'Messaging & Communications',
  'Micromobility',
  'Mining & Materials',
  'Music & Entertainment',
  'Naval & Marine-Tech',
  'News, Media & Publishing',
  'Generative AI & NLP',
  'No-Code Tools',
  'Oil & Gas',
  'Packaging & Bottling',
  'Parenting, Childcare & Baby-Tech',
  'Philanthropy, Volunteerism & Charity',
  'Photography',
  'Physical Security & Asset Protection',
  'Podcasting & Audio Social',
  'Politics & Civic Engagement',
  'Productivity Tools',
  'Consulting & Professional Services',
  'Prop-Tech & Real Estate',
  'Quantum Computing',
  'Religion & Faith',
  'Restaurant-Tech',
  'Robotics',
  'Sales-Tech & CRM',
  'Semiconductors & Chips',
  'Sensors & Perception-Tech',
  'Sex-Tech',
  'Sleep-Tech',
  'Smart Homes',
  'Civic-Tech & Social Assistance',
  'Social & Networking',
  'Space, Satellites & Rockets',
  'Sports & Fitness',
  'Supply Chain & Logistics',
  'Telecommunications & Internet Infrastructure',
  'Ticketing, Booking & Scheduling',
  'Tobacco, Smoking & Vaping',
  'Transportation & Delivery',
  'Travel & Tourism',
  'Video Production & Editing',
  'Waste Management & Recycling',
  'Weapons & Armament',
  'Wearable-Tech & Quantified Self',
  'Web 3.0, dApps & DAOs',
  'Weddings',
];

export const STARTUP_IND_OPTIONS = listToSelectOptions(STARTUP_INDUSTRY_VALUES);
