import React from 'react';

import { InvestorConnectionView } from '/../libs/shared-types/src/types/view/APIResponse';
import ContactsTableRow from './ConnectionsTableRow';
import BackendPaginatedTable, {
  PaginatedTableProps,
} from '/src/components/table/BackendPaginatedTable';

interface ConnectionsTableInterface extends PaginatedTableProps {
  connections: InvestorConnectionView[];
}

function ConnectionsTable({
  connections,
  refreshData,
  parentPage,
  parentTotalCount,
  parentSortOrder,
  parentSortedColumn,
  parentFilter,
  parentPerPage,
  headers,
  parentTotalPages,
}: ConnectionsTableInterface): JSX.Element {
  return (
    <section>
      <BackendPaginatedTable
        headers={headers}
        refreshData={refreshData}
        parentPage={parentPage}
        parentTotalCount={parentTotalCount}
        parentSortOrder={parentSortOrder}
        parentSortedColumn={parentSortedColumn}
        parentTotalPages={parentTotalPages}
        parentFilter={parentFilter}
        parentPerPage={parentPerPage}
        rowComponents={connections.map((contact, index) => (
          <ContactsTableRow
            key={contact.email}
            contact={contact}
            className={index === 0 ? 'joyride-contacts' : ''}
          />
        ))}
      />
    </section>
  );
}

export default ConnectionsTable;
