import React, { useEffect, useState } from 'react';
import prepareConnections from './ConnectionsDataProvider';
import NetworkGraph from './NetworkGraph';
import API from '/src/middleware/API';
import { AdminGetUsersConnections } from '/../libs/shared-types/src/constants/ApiRoutes';
import { NetworkGraphData } from '/../libs/shared-types/src/types/model/NetworkGraphData';
import Logger from '/src/services/logger';
import SearchBar from '/src/components/inputs/SearchBar';
import ToggleSwitch from '/src/components/utility/ToggleSwitch';
import { Breadcrumbs } from '../../AdminRoutes';

function NetworkRoute(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [baseGraphData, setBaseGraphData] = useState<NetworkGraphData>();
  const [graphData, setGraphData] = useState<{
    nodes: any[];
    edges: any[];
  }>();
  const [moreThanOneConnection, setMoreThanOneConnection] = useState(false);
  const [removeTeamMembers, setRemoveTeamMembers] = useState(false);
  const [removeVlad, setRemoveVlad] = useState(false);
  const [removePublicSignUps, setRemovePublicSignUps] = useState(false);

  const settings = [
    {
      label: 'Has Invited More Than 1',
      id: 'moreThanOneConnection',
      onChange: (value: boolean) => setMoreThanOneConnection(value),
      value: moreThanOneConnection,
    },
    {
      label: 'Remove Public Sign Ups',
      id: 'removePublicSignUps',
      onChange: (value: boolean) => setRemovePublicSignUps(value),
      value: removePublicSignUps,
    },
    {
      label: 'Remove Vlad',
      id: 'removeVlad',
      onChange: (value: boolean) => setRemoveVlad(value),
      value: removeVlad,
    },
    {
      label: 'Remove Flowlie team',
      id: 'removeTeamMembers',
      onChange: (value: boolean) => setRemoveTeamMembers(value),
      value: removeTeamMembers,
    },
  ];

  function remount(newData: any) {
    setIsLoading(true);
    setTimeout(() => {
      setGraphData(newData);
      setIsLoading(false);
    }, 10);
  }

  function prepareDataFromSettings(search?: string) {
    if (!baseGraphData) {
      return;
    }
    const { edges, outgoingConnectionsMap } = baseGraphData;
    let { nodes } = baseGraphData;

    if (removeTeamMembers) {
      const filteredConnections = nodes.filter(
        (account) => !account.isFlowlieTeamMember
      );
      nodes = filteredConnections;
    }
    if (removeVlad) {
      const filteredConnections = nodes.filter(
        (account) => account.name !== 'Vlad Cazacu'
      );
      nodes = filteredConnections;
    }

    if (removePublicSignUps) {
      const filteredConnections = nodes.filter(
        (account) => !account.isPublicSignUp
      );
      nodes = filteredConnections;
    }

    if (moreThanOneConnection) {
      const accountsWithMoreThanOneConnection = nodes.filter(
        (account) => outgoingConnectionsMap[account.id] > 1
      );
      nodes = accountsWithMoreThanOneConnection;
    }

    const data = prepareConnections({
      nodes,
      edges,
      outgoingConnectionsMap: {},
    });
    if (search && search.length > 0) {
      data.nodes = data.nodes.map((node) => {
        if (node.label.toLowerCase().includes(search.toLowerCase())) {
          // eslint-disable-next-line no-param-reassign
          node.color = '#FF5AF7';
        }
        return node;
      });
      remount(data);
      // setIsLoading(false);
    } else {
      remount(data);
    }
  }

  function handleFilter(filter: string) {
    prepareDataFromSettings(filter);
  }

  async function fetchGraphData() {
    try {
      setIsLoading(true);
      const data = await API.get<NetworkGraphData>(
        AdminGetUsersConnections.buildEndpoint()
      );
      setBaseGraphData(data);
      setGraphData(prepareConnections(data));
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (graphData === undefined) {
      fetchGraphData();
    }
  }, [graphData]);

  useEffect(() => {
    if (baseGraphData) {
      prepareDataFromSettings();
    }
  }, [
    moreThanOneConnection,
    removeTeamMembers,
    removeVlad,
    removePublicSignUps,
  ]);

  return (
    <div className="flex min-h-screen flex-col">
      <div className="p-4">
        <Breadcrumbs />
      </div>
      <h2 className="page-title text-center">User Sign Ups Invitations</h2>
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="flex h-full">
            {/* Content area */}
            <div className="flex flex-1 flex-col overflow-hidden">
              {/* Main content */}
              <div className="flex flex-1 items-stretch overflow-hidden">
                <main className="flex-1 overflow-y-auto">
                  <div className="mx-auto max-w-7xl border-2 border-black px-4 pt-8 sm:px-6 lg:px-8">
                    {isLoading ? (
                      <div>Loading...</div>
                    ) : (
                      <NetworkGraph graphData={graphData} />
                    )}
                  </div>
                </main>

                <aside className="hidden w-96 overflow-y-auto border-l border-gray-200 bg-white p-6 lg:block">
                  <div className="space-y-6">
                    <section>
                      <h3 className="mb-3 text-lg font-bold text-gray-900">
                        Legend
                      </h3>

                      <ul className="ml-5 list-outside list-disc text-sm text-gray-700">
                        <li>Investors are yellow</li>
                        <li>Startups are blue</li>
                        <li>Ovals are users who joined via invitation</li>
                        <li>
                          Rectangles are users who joined via public sign up
                        </li>
                      </ul>
                    </section>

                    <section>
                      <h3 className="mb-3 text-lg font-bold text-gray-900">
                        Filters
                      </h3>
                      <label
                        htmlFor="search"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Highlight Nodes
                        <div className="relative mt-1 flex items-center">
                          <SearchBar
                            isDebounce
                            placeholder="Search by Name"
                            onQueryChange={handleFilter}
                          />
                        </div>
                      </label>
                      <dl className="mt-5 divide-y text-sm text-gray-700">
                        {settings.map((setting) => (
                          <div key={setting.id} className="my-auto py-3">
                            <ToggleSwitch
                              showToggleIcon
                              toggleOn={() => setting.onChange(true)}
                              toggleOff={() => setting.onChange(false)}
                              name={setting.id}
                              label={setting.label}
                              initialState={setting.value}
                            />
                          </div>
                        ))}
                      </dl>
                    </section>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NetworkRoute;
