export enum ErrorCodes {
  InvalidFlowlinkError = 1,
  ProfilePicUploadError = 2,
  StartupUploadDataError = 3,
  LinkshareConsumeError = 4,
  ConfirmThroughEmailError = 5,
  IncorrectPasswordError = 6,
  UnconfirmedUserAccountAlreadyExistsError = 7,
  AccountExistsWithGoogleError = 8,
  CognitoSignupError = 9,
  ReCaptchaError = 10,
  InsufficientDataAccessRights = 11,
  EmailIsAlreadyRegisteredWithAnExistingAccount = 12,
  EmailIsAlreadyRegisteredWithAnExistingUnverifiedInvestor = 13,
}

export const ErrorMessages = {
  [ErrorCodes.InvalidFlowlinkError]:
    'Share link was invalidated, you are being redirected to the homepage.',
  [ErrorCodes.ProfilePicUploadError]:
    'There was an error uploading your profile picture',
  [ErrorCodes.StartupUploadDataError]:
    'There was an error uploading your logo and/or deck',
  [ErrorCodes.LinkshareConsumeError]: 'Sorry! We encountered an error.',
  [ErrorCodes.ConfirmThroughEmailError]:
    'Please verify your email using the link delivered to your inbox.',
  [ErrorCodes.IncorrectPasswordError]: 'Incorrect username or password.',
  [ErrorCodes.UnconfirmedUserAccountAlreadyExistsError]:
    'We encountered an error. If you already signed up, try logging in.',
  [ErrorCodes.AccountExistsWithGoogleError]:
    'Please log in with Google sign in instead.',
  [ErrorCodes.CognitoSignupError]:
    'We encountered an error. If you already signed up, try confirming your email or logging in.',
  [ErrorCodes.ReCaptchaError]: 'ReCAPTCHA validation failed. Please try again.',
  [ErrorCodes.InsufficientDataAccessRights]:
    'You are not able to access this data.',
  [ErrorCodes.EmailIsAlreadyRegisteredWithAnExistingAccount]:
    'Email is already in use',
  [ErrorCodes.EmailIsAlreadyRegisteredWithAnExistingUnverifiedInvestor]:
    'Unverified Investor email is already in use',
};
