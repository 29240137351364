import {
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from 'amazon-cognito-identity-js';

import React, { useEffect, useState, useContext } from 'react';

import { localStorageSetAccountData } from '/src/middleware/LocalStorage';
import {
  getUnconfirmedUserByEmail,
  createUnconfirmedUser,
} from '/src/services/UnconfirmedUser';

import { AccountContext } from '/src/contexts/Accounts';
import { heapIdentify } from '/src/middleware/Heap';
import { UserInfo } from '/src/interfaces/UserInfo';
import API from '/src/middleware/API';
import GoogleWaitScreen from './GoogleWaitScreen';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import Logger from '/src/services/logger';
import { getHomepageForAccountType } from '/src/util/urls';
import { useQuery } from '/src/hooks/useQuery';
import {
  setupLocalStorageUponLogin,
  validateAndSyncShareTokens,
} from '/src/services/LoginUser';
import { Navigate, useNavigate } from 'react-router-dom';
import { parseFlowlieAuthToken } from '/src/services/jwt';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';
import { Posthog } from '/src/middleware/Posthog';
import { UserMetadata } from '/../libs/shared-types/src/types/view/UserMetadata';
import {
  AccountTypes,
  PosthogAccountTypesEnum,
} from '/../libs/shared-types/src/constants/AccountTypes';
import { getOnboardingFlow } from '/../libs/shared-types/src/extensions/OnboardingFlowsExtensions';
import { validateToken } from '/src/services/ValidateToken';
import {
  PublicLogIn,
  PublicGoogleCognitoTokenExchange,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { ACCOUNT_CHOICE_ROUTE } from '/src/constants/Routes';

function GoogleCognitoTokensExchange(): JSX.Element {
  const { instantiateWithTokens } = useContext(AccountContext);
  const [isRedirect, setIsRedirect] = useState(false);
  const [isOnboard, setIsOnboard] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const query = useQuery();
  const authenticationContext = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const handleCreateUnconfirmedUser = async (userInfo: UserInfo) => {
    let validatedInvitation = authenticationContext.invitation;

    if (authenticationContext.invitation) {
      const parsedToken = await validateToken(
        authenticationContext.invitation.shareToken
      );
      if (parsedToken === undefined) {
        authenticationContext.setInvitation(undefined);
        validatedInvitation = undefined;
      }
    }

    const unconfirmedUser = await createUnconfirmedUser(
      userInfo.email,
      userInfo.firstName,
      userInfo.lastName,
      validatedInvitation?.shareToken,
      authenticationContext.unconfirmedAccountType,
      validatedInvitation?.discoveredFrom,
      validatedInvitation?.message
    );

    Logger.warn('token exchange: posted unconfirmed user data');

    const posthogClient = new Posthog();
    posthogClient.identify(unconfirmedUser.email);
    posthogClient.addUserProperties({
      name: `${unconfirmedUser.firstName} ${unconfirmedUser.lastName}`,
      email: unconfirmedUser.email,
      type: PosthogAccountTypesEnum.Unconfirmed,
      invited_by_name: unconfirmedUser.invitation?.fullName,
      mongoid_unconfirmed: unconfirmedUser._id,
      sub_type: unconfirmedUser.accountType,
    });
    if (unconfirmedUser.accountType) {
      posthogClient.addUserProperties({
        onboarding_flow: getOnboardingFlow(
          unconfirmedUser.accountType,
          unconfirmedUser?.invitation?.accountType,
          unconfirmedUser?.invitation?.linkType
        ),
      });
    }

    localStorageSetAccountData(
      userInfo.email,
      userInfo.firstName,
      userInfo.lastName
    );

    setIsRedirect(true);
  };

  const onBoardDecision = async (userInfo: UserInfo) => {
    const posthogClient = new Posthog();

    const existingCompletedUser = await API.get<UserMetadata>(
      PublicLogIn.buildEndpoint(userInfo.email)
    );

    if (existingCompletedUser) {
      Logger.warn('token exchange: existing completed user');
      const { token, accountType } = existingCompletedUser;

      if (token !== null && accountType !== null) {
        setupLocalStorageUponLogin(existingCompletedUser);

        const parsedToken = parseFlowlieAuthToken(token);
        heapIdentify(parsedToken.userInfo._id);

        posthogClient.identify(userInfo.email);
        posthogClient.addUserProperties({
          name: `${existingCompletedUser.firstName} ${existingCompletedUser.lastName}`,
          email: existingCompletedUser.email,
          type: existingCompletedUser.accountType,
          invited_by_name:
            existingCompletedUser.unconfirmedUser?.invitation?.fullName,
          company_name: existingCompletedUser.companyName,
          mongoid_onboarded: existingCompletedUser.id,
          mongoid_unconfirmed: existingCompletedUser.unconfirmedUser?._id,
          sub_type:
            existingCompletedUser.accountType === AccountTypes.Startup
              ? AccountTypes.Startup
              : Posthog.getInvestorSubType(existingCompletedUser.investorType),
          onboarding_flow: getOnboardingFlow(
            existingCompletedUser.accountType,
            existingCompletedUser.unconfirmedUser?.invitation?.accountType,
            existingCompletedUser.unconfirmedUser?.invitation?.linkType
          ),
        });

        setIsOnboard(false);
        setIsRedirect(true);
      }
    } else {
      const unconfirmedUser = await getUnconfirmedUserByEmail(userInfo.email);
      Logger.warn('token exchange: fetched unconfirmed user');
      if (unconfirmedUser) {
        posthogClient.identify(unconfirmedUser.email);
        localStorageSetAccountData(
          unconfirmedUser.email,
          unconfirmedUser.firstName,
          unconfirmedUser.lastName
        );
        authenticationContext.setUnconfirmedAccountType(
          unconfirmedUser.accountType
        );

        await validateAndSyncShareTokens(
          unconfirmedUser,
          authenticationContext
        );
        setIsOnboard(true);
        setIsRedirect(true);
      } else {
        try {
          await handleCreateUnconfirmedUser(userInfo);
          Logger.warn('token exchange: created unconfirmed user');
          setIsOnboard(true);
          setIsRedirect(true);
        } catch (error: any) {
          Logger.error(error);
          setIsOnboard(false);
          setIsRedirect(true);
        }
      }
    }
    setIsLoading(false);
  };

  const exchangeTokens = async (token: string) => {
    const awsTokens = await API.get(
      PublicGoogleCognitoTokenExchange.buildEndpoint(token)
    );
    if (awsTokens.error) {
      Logger.error(
        'Failed to exchange tokens with AWS Cognito',
        awsTokens.error
      );
      return;
    }
    const cognitoId = new CognitoIdToken({
      IdToken: awsTokens.id_token,
    });
    const cognitoAccess = new CognitoAccessToken({
      AccessToken: awsTokens.access_token,
    });
    const cognitoRefresh = new CognitoRefreshToken({
      RefreshToken: awsTokens.refresh_token,
    });

    const userInfo: UserInfo = {
      email: cognitoId.payload.email,
      firstName: cognitoId.payload.given_name,
      lastName: cognitoId.payload.family_name,
    };

    const picture = cognitoId.payload.picture;
    if (picture) {
      localStorage.setItem(
        LocalStorageKeysConst.GOOGLE_ACCOUNT_PICTURE,
        picture
      );
    }
    await instantiateWithTokens(
      cognitoRefresh,
      cognitoId,
      cognitoAccess,
      cognitoId.payload['cognito:username'],
      awsTokens.id_token
    );

    await onBoardDecision(userInfo);
  };

  useEffect(() => {
    if (
      query &&
      authenticationContext.invitation !== null &&
      authenticationContext.unconfirmedAccountType !== null
    ) {
      const code = query.get('code');
      if (typeof code === 'string') {
        exchangeTokens(code);
      }
    }
  }, [authenticationContext]);

  if (isOnboard && isRedirect) {
    return <Navigate to={ACCOUNT_CHOICE_ROUTE} />;
  }

  if (!isOnboard && isRedirect) {
    return <Navigate to={getHomepageForAccountType()} />;
  }

  return <GoogleWaitScreen isLoading={isLoading} />;
}
export default GoogleCognitoTokensExchange;
