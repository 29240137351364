import React from 'react';
import {
  FitScore,
  getFitScoreColor,
} from '/../libs/shared-types/src/types/model/FitScore';
import TagCell from './TagCell';

interface FitScoreTagCellProps {
  fitScore: FitScore | undefined;
}

function FitScoreTagCell({ fitScore }: FitScoreTagCellProps) {
  if (!fitScore) {
    return <span className="app-table-row-text">-</span>;
  }

  const color = getFitScoreColor(fitScore);

  return (
    <TagCell
      type="range"
      tag={fitScore.score}
      defaultClassName={color.bgColor + ' ' + color.textColor}
      isPercentage={true}
    />
  );
}

export default FitScoreTagCell;
