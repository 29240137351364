import React, {
  createContext,
  useContext,
  FunctionComponent,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import API from '/src/middleware/API';
import { StartupInvestorPipelineAddInvestors } from '/../libs/shared-types/src/constants/ApiRoutes';
import Logger from '/src/services/logger';
import Toast from '/src/components/notifications/Toast';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import { ERROR, SUCCESS } from '/src/constants/SuccessMessages';
import { InvestorIdentifier } from '/../libs/shared-types/src/types/view/APIResponse';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import { getPluralSuffix } from '/src/util/formatting/numbers';

export interface InvestorDatabaseContextType {
  addInvestorsToPipeline: (
    investors: InvestorIdentifier[],
    addFromInvestorListId: string | undefined,
  ) => Promise<void>;
  addInvestorToPipeline: (
    investorId: string,
    investorDataType: InvestorDataType,
    addFromInvestorListId: string | undefined,
  ) => Promise<void>;
}

export const InvestorDatabaseContext = createContext<
  InvestorDatabaseContextType | undefined
>(undefined);

type Props = {
  children: ReactNode;
};

export const InvestorDatabaseProvider: FunctionComponent<Props> = ({
  children,
}) => {
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();

  /**
   *
   * @param investors The investors to add to the pipeline
   * @param addFromInvestorListId The ID of the Investor List, only to be set if the investor is being added from a List view
   * @returns
   */
  async function addInvestorsToPipeline(
    investors: InvestorIdentifier[],
    addFromInvestorListId: string | undefined,
  ) {
    if (investors.length === 0) {
      return;
    }

    try {
      await API.put(StartupInvestorPipelineAddInvestors.buildEndpoint(), {
        investors,
        addFromInvestorListId,
      });
      setToastConfig({
        isError: false,
        message: `Investor${getPluralSuffix(investors.length)} added to pipeline`,
      });
    } catch (error: any) {
      Logger.error(error.message);
      setToastConfig({
        isError: true,
        message: error.message,
      });
    }
  }

  async function addInvestorToPipeline(
    investorId: string,
    investorDataType: InvestorDataType,
    addFromInvestorListId: string | undefined,
  ) {
    await addInvestorsToPipeline(
      [
        {
          investorId,
          investorDataType,
        },
      ],
      addFromInvestorListId,
    );
  }

  useEffect(() => {
    if (toastConfig) {
      setTimeout(() => {
        setToastConfig(undefined);
      }, 5000);
    }
  }, [toastConfig]);

  return (
    <InvestorDatabaseContext.Provider
      value={{
        addInvestorsToPipeline,
        addInvestorToPipeline,
      }}
    >
      {children}
      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.isError ? ERROR : SUCCESS}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
    </InvestorDatabaseContext.Provider>
  );
};

export const useInvestorDatabaseContext = (): InvestorDatabaseContextType => {
  const context = useContext(InvestorDatabaseContext);
  if (!context) {
    throw new Error(
      'useInvestorDatabaseContext must be used within a InvestorDatabaseProvider',
    );
  }
  return context;
};
