import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { LINKSHARE_CONSUME_ERROR } from '/src/constants/ErrorMessages';
import { localStorageGetAuthenticatedAccountType } from '/src/middleware/LocalStorage';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { fetchConnectionRequests } from '/src/services/InvestorRequests';
import { InvestorRequestsContext } from '/src/contexts/InvestorRequestsContext';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import {
  INVESTOR_CONTACTS_ROUTE,
  STARTUP_PROFILE_ROUTE,
  SCREENING_ROUTE,
} from '/src/constants/Routes';
import { SUCCESS } from '/src/constants/SuccessMessages';
import consumeLink from '../../services/LinkManager';
import ShareConsumptionContext from '/src/contexts/ShareConsumptionContext';
import Toast from '../notifications/Toast';
import { validateToken } from '/src/services/ValidateToken';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';

const WHITELISTED_PATHS = ['/app/file/preview'];

interface LinkShareWrapperProps {
  children: ReactElement<any, any>;
}

function LinkShareWrapper({ children }: LinkShareWrapperProps): JSX.Element {
  const [message, setMessage] = useState('');
  const [isConsumedAlertShown, setIsConsumedAlertShown] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLinkShareConsumedSuccess, setIsLinkShareConsumedSuccess] =
    useState(false);
  const [isConsumingLinkShare, setIsConsumingLinkShare] = useState(false);
  const [accountType, setAccountType] = useState<AccountTypes | undefined>();
  const { invitation, setInvitation } = useContext(AuthenticationContext);

  const navigate = useNavigate();
  const location = useLocation();

  const { setConnectionRequests } = useContext(InvestorRequestsContext);

  function getAccountType() {
    if (accountType) {
      return accountType;
    }
    const retrievedAccountType = localStorageGetAuthenticatedAccountType();
    setAccountType(retrievedAccountType as AccountTypes);
    return retrievedAccountType;
  }

  function redirectToApp() {
    const accountType = getAccountType();

    if (accountType === AccountTypes.Investor) {
      navigate(SCREENING_ROUTE);
    } else if (accountType === AccountTypes.Startup) {
      navigate(STARTUP_PROFILE_ROUTE);
    } else {
      navigate('/');
    }
  }

  async function conditionalRedirect(token: string, linkType: string) {
    const accountType = getAccountType();
    if (accountType !== AccountTypes.Investor) {
      return;
    }

    if (linkType === LinkTypes.Dealshare) {
      navigate('/app/access-requests/requests-made');
      return;
    }

    if (linkType === LinkTypes.Flowlink) {
      try {
        const response = await validateToken(token);

        if (response?.originatorType === AccountTypes.Investor) {
          navigate(INVESTOR_CONTACTS_ROUTE);
          return;
        }

        if (response?.originatorType === AccountTypes.Startup) {
          navigate(`/app/deal-detail/${response?.originatorId}`);
          return;
        }
      } catch {
        redirectToApp();
      }
    }
  }

  function showAlert() {
    setIsConsumedAlertShown(true);
    window.setTimeout(() => setIsConsumedAlertShown(false), 8000);
  }

  const inWhilelistedPaths = (path: string) =>
    WHITELISTED_PATHS.some((whitelistedPath) => path.includes(whitelistedPath));

  async function checkPathnameAndConsume(locationPathname: string) {
    if (!invitation || isConsumingLinkShare) {
      return;
    }

    const { shareToken, linkType, discoveredFrom, message } = invitation;
    const accountType = getAccountType();
    if (
      accountType === AccountTypes.Startup &&
      linkType === LinkTypes.Dealshare
    ) {
      // Startups do not consume DealShare links
      setMessage('Startup accounts cannot get access to startup one-pagers');
      showAlert();
      setInvitation(undefined);
      return;
    }

    if (
      locationPathname.includes('/app') &&
      !inWhilelistedPaths(locationPathname)
    ) {
      try {
        setIsConsumingLinkShare(true);
        const response = await consumeLink(
          shareToken,
          linkType,
          message,
          discoveredFrom
        );
        if (accountType === AccountTypes.Investor) {
          const connectionRequests = await fetchConnectionRequests();
          setConnectionRequests(connectionRequests);
        }
        setIsLinkShareConsumedSuccess(true);
        setInvitation(undefined);

        if (response.message === '') {
          return;
        }

        conditionalRedirect(shareToken, linkType);
        setIsError(response.error);
        setMessage(response.message);
        showAlert();
      } catch (error: any) {
        setIsError(true);
        setInvitation(undefined);
        setMessage(error.message);
        showAlert();
      } finally {
        setIsConsumingLinkShare(false);
      }
    }
  }

  useEffect(() => {
    checkPathnameAndConsume(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <ShareConsumptionContext.Provider value={isLinkShareConsumedSuccess}>
        {children}
      </ShareConsumptionContext.Provider>
      <Toast
        isShown={isConsumedAlertShown}
        onClose={() => setIsConsumedAlertShown(false)}
        title={isError ? LINKSHARE_CONSUME_ERROR : SUCCESS}
        isError={isError}
        text={message}
      />
    </>
  );
}

export default LinkShareWrapper;
