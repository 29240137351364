import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { localStorageGetDebugMode } from '../middleware/LocalStorage';
import { MINUTE_MS } from '/../libs/shared-types/src/constants/time';

interface TimerConfig {
  timeout: number;
  promptBeforeIdle: number;
}

const DEBUG_TIMEOUT = 1000 * 15;
const DEBUG_PROMPT_BEFORE_IDLE = 1000 * 10;

const TIMEOUT = MINUTE_MS * 3;
const PROMPT_BEFORE_IDLE = 1000 * 5;

export default function useIdleStatus() {
  const [isIdle, setIsIdle] = useState<boolean>(false);
  const [isIdlePromptShown, setIsIdlePromptShown] = useState<boolean>(false);
  const [timerConfig, setTimerConfig] = useState<TimerConfig>({
    timeout: TIMEOUT,
    promptBeforeIdle: PROMPT_BEFORE_IDLE,
  });

  const onIdle = () => {
    setIsIdle(true);
  };

  const onPrompt = () => {
    if (isIdle || isIdlePromptShown) {
      return;
    }

    setIsIdlePromptShown(true);
  };

  const onActive = () => {
    setIsIdle(false);
    setIsIdlePromptShown(false);
  };

  const { getTotalActiveTime, activate } = useIdleTimer({
    onActive,
    onIdle,
    onPrompt,
    promptBeforeIdle: timerConfig.promptBeforeIdle,
    throttle: 500,
    timeout: timerConfig.timeout,
  });

  /**
   * This event fires with a visibilityState of hidden when a user navigates to a new page,
   * switches tabs, closes the tab, minimizes or closes the browser, or, on mobile, switches from the browser to a different app.
   */
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      onIdle();
      return;
    }

    if (document.visibilityState === 'visible') {
      onActive();
      return;
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    if (localStorageGetDebugMode()) {
      setTimerConfig({
        timeout: DEBUG_TIMEOUT,
        promptBeforeIdle: DEBUG_PROMPT_BEFORE_IDLE,
      });
    }

    return function cleanup() {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return { isIdle, isIdlePromptShown, getTotalActiveTime, activate };
}
