import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '/src/services/AuthService';
import { NOT_FOUND_ROUTE, LOGIN_ROUTE } from '/src/constants/Routes';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';

export interface AdminEntrypointProps {
  authenticationPath?: string;
  children: JSX.Element;
}

const AdminEntryPoint = ({
  authenticationPath = LOGIN_ROUTE,
  children,
}: AdminEntrypointProps): JSX.Element => {
  if (!AuthService.isLoggedIn()) {
    return <Navigate replace to={{ pathname: authenticationPath }} />;
  }

  if (!AuthService.isAdmin()) {
    localStorage.removeItem(LocalStorageKeysConst.IS_ADMIN);
    return <Navigate to={NOT_FOUND_ROUTE} />;
  }

  return children;
};

export default AdminEntryPoint;
