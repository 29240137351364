import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import NetworkRoute from './admin/UsersConnectionsNetwork/NetworkRoute';
import {
  ADMIN_ROUTE,
  ADMIN_SIGN_UP_NEWTORK_ROUTE,
  ADMIN_UNVERIFIED_INVESTOR_UPLOAD_ROUTE,
  ADMIN_UNVERIFIED_INVESTOR_FORM_ROUTE,
  ADMIN_FIT_SCORE_BENCHMARK_ROUTE,
} from '../constants/Routes';
import { formatRoute } from '../util/formatting/routes';
import UnverifiedInvestorForm from './admin/UnverifiedInvestors/UnverifiedInvestorForm';

import { HomeIcon } from '@heroicons/react/20/solid';
import UnverifiedInvestorUpload from './admin/UnverifiedInvestors/UnverifiedInvestorUpload';
import FitScoreBenchmarkRoute from './admin/FitScoreBenchmark/FitScoreBenchmarkRoute';
export function Breadcrumbs() {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link
              to={ADMIN_ROUTE}
              className="text-gray-400 hover:text-gray-500"
            >
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
      </ol>
    </nav>
  );
}

function AdminMenu() {
  return (
    <div className="ml-8 mt-10">
      <h1 className="page-title">Hello Flowlie Admin 🦺</h1>
      <h2 className="mt-5 font-medium">Menu</h2>
      <nav className="my-2 flex w-fit flex-col space-y-5">
        <Link className="app-button--primary" to={ADMIN_SIGN_UP_NEWTORK_ROUTE}>
          Sign Ups Invitations Network
        </Link>
        <Link
          className="app-button--primary"
          to={ADMIN_FIT_SCORE_BENCHMARK_ROUTE}
        >
          Fit Score Benchmark
        </Link>
        <Link
          className="app-button--primary"
          to={ADMIN_UNVERIFIED_INVESTOR_FORM_ROUTE}
        >
          Unverified Investor Form
        </Link>
        <Link
          className="app-button--primary"
          to={ADMIN_UNVERIFIED_INVESTOR_UPLOAD_ROUTE}
        >
          Unverified Investor Bulk Upload
        </Link>
      </nav>
    </div>
  );
}

export default (
  <Routes>
    <Route
      element={<NetworkRoute />}
      path={formatRoute(ADMIN_SIGN_UP_NEWTORK_ROUTE, ADMIN_ROUTE)}
    />
    <Route
      element={<FitScoreBenchmarkRoute />}
      path={formatRoute(ADMIN_FIT_SCORE_BENCHMARK_ROUTE, ADMIN_ROUTE)}
    />

    <Route
      element={<UnverifiedInvestorForm />}
      path={formatRoute(
        ADMIN_UNVERIFIED_INVESTOR_FORM_ROUTE,
        ADMIN_ROUTE,
        false
      )}
    />
    <Route
      element={<UnverifiedInvestorForm />}
      path={formatRoute(
        ADMIN_UNVERIFIED_INVESTOR_FORM_ROUTE,
        ADMIN_ROUTE,
        false,
        'unverifiedInvestorId'
      )}
    />

    <Route
      element={<UnverifiedInvestorUpload />}
      path={formatRoute(
        ADMIN_UNVERIFIED_INVESTOR_UPLOAD_ROUTE,
        ADMIN_ROUTE,
        false
      )}
    />

    <Route path="*" element={<AdminMenu />} />
  </Routes>
);
