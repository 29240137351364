import React from 'react';

import {
  DAY_S,
  HOUR_S,
  MINUTE_S,
} from '/../libs/shared-types/src/constants/time';
import { formatDate } from '/src/util/formatting/dates';
import { isMinDate } from '/src/util/time';
import { getPluralSuffix } from '/src/util/formatting/numbers';

function timeSince(date: Date | undefined) {
  if (date === undefined || isMinDate(date)) {
    return 0;
  }
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  let interval = Math.floor(seconds / DAY_S);

  if (interval > 7) {
    return formatDate(date);
  }

  if (interval > 1) {
    const value = Math.floor(interval);
    return `${Math.floor(interval)} day${getPluralSuffix(value)} ago`;
  }

  interval = seconds / HOUR_S;
  if (interval > 1) {
    const value = Math.floor(interval);
    return `${Math.floor(interval)} hour${getPluralSuffix(value)} ago`;
  }

  interval = seconds / MINUTE_S;
  if (interval > 1) {
    const value = Math.floor(interval);
    return `${Math.floor(interval)} minute${getPluralSuffix(value)} ago`;
  }

  if (seconds < 10) {
    return 'Just now';
  }

  return `${Math.floor(seconds)} seconds ago`;
}

interface DateProps {
  date: Date | undefined | null;
  tooltipPosition?: 'top' | 'bottom' | 'right';
}

function getMarginSide(position: string) {
  switch (position) {
    case 'right':
      return 'mr-2';
    default:
      return '';
  }
}

function DateString({
  date,
  tooltipPosition = 'right',
}: DateProps): JSX.Element {
  if (date === undefined || date === null || isMinDate(date)) {
    return <span>-</span>;
  }

  return (
    <span className="tooltip-wrapper">
      <span className={`tooltip tooltip-${tooltipPosition} w-max`}>
        {new Intl.DateTimeFormat('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        }).format(date)}
      </span>
      <span className={getMarginSide(tooltipPosition)}>{timeSince(date)}</span>
    </span>
  );
}

export default DateString;
