import React, { useContext, useState } from 'react';
import { useNavigate, Route, Routes, Navigate } from 'react-router-dom';
import { Stage } from '/../libs/shared-types/src/types/model/Stage';
import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import { toDashCase } from '/src/util/formatting/strings';
import { roundIsInGracePeriod } from '/src/util/rounds';
import {
  ACTIVE_DEALFLOW_ROUTE,
  NOT_FOUND_ROUTE,
  PASSED_ROUTE,
  PORTFOLIO_ROUTE,
} from '/src/constants/Routes';
import { DealDetailData } from '/../libs/shared-types/src/types/view/AggregatedDeals';
import { SHARED_SUCCESS, SUCCESS } from '/src/constants/SuccessMessages';
import { sortDataByDate } from '/src/util/sorting';
import { DealActionModals, ModalConfig } from '/src/interfaces/ModalConfig';
import Alert from '/src/components/notifications/Alert';
import API from '/src/middleware/API';
import DealActionButtons from '/src/components/DealActionButtons';
import DealDetailActivityTab from './tabs/DealDetailActivityTab';
import DealDetailContactCard from './cards/DealDetailContactCard';
import DealDetailDeckCard from './cards/DealDetailDeckCard';
import DealDetailFinancialCard from './cards/DealDetailFinancialCard';
import DealDetailFoundersCard from './cards/DealDetailFoundersCard';
import DealDetailGeneralCard from './cards/DealDetailGeneralCard';
import DealDetailHeader from './DealDetailHeader';
import DealDetailInvestmentSummaryCard from './cards/DealDetailInvestmentSummaryCard';
import DealDetailLegalCard from './cards/DealDetailLegalCard';
import DealDetailRoundSummaryCard from './cards/DealDetailRoundSummaryCard';
import DealDetailSharingTab from './tabs/DealDetailSharingTab';
import DealDetailTimeline from './cards/DealDetailTimeline';
import DealDetailUserCard from './cards/DealDetailUserCard';
import Logger from '/src/services/logger';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import PassDialog from '../ActiveDealflow/dialogs/PassDialog';
import RecordInvestmentDialog from '../ActiveDealflow/dialogs/RecordInvestmentDialog';
import RoundCard from '/src/components/RoundCard';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import ShareDealDialog from '../ActiveDealflow/dialogs/ShareDealDialog';
import Toast, { ToastProps } from '/src/components/notifications/Toast';
import useTrackViewTime from '/src/hooks/useTrackViewTime';
import RoundHistoryKpis from '/src/components/RoundHistoryKpis';
import { updateDealViewSession } from '/src/services/DealViewSession';
import { DealViewSessionUpdateType } from '/../libs/shared-types/src/types/model/DealViewSession';
import DeckViewer from './cards/DeckViewer';
import { getLatestDeck } from '/../libs/shared-types/src/extensions/DeckExtensions';
import useIdleStatus from '/src/hooks/useIdleStatus';
import IdlePrompt from '/src/components/utility/IdlePrompt';
import {
  InvestorPaidFeatures,
  hasFeatureAccess,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  SharedGetStartupDeckById,
  InvestorUpdateDealStatus,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import DealDetailProductCard from './cards/DealDetailProductCard';
import DealDetailDataRoomCard from './cards/DealDetailDataRoomCard';
import { verifyDataRoomPassword } from '/src/services/DataRoom';
import { DataRoomAccessStatus } from '/../libs/shared-types/src/constants/DataRoom/DataRoomAccessStatus';
import { downloadPdf } from '/src/services/File';

const tabs = [
  { name: 'Overview' },
  { name: 'Funding History' },
  { name: 'Activity' },
  { name: 'Sharing History' },
];

interface DealDetailRouteContentProps {
  dealDetailData: DealDetailData;
  activeBoardStages: Stage[];
  fetchDeal: (isRefreshing: boolean) => void;
  isInDealflow: boolean;
  dealViewSessionId: string;
}

function DealDetailRouteContent({
  dealDetailData,
  activeBoardStages,
  fetchDeal,
  isInDealflow,
  dealViewSessionId,
}: DealDetailRouteContentProps): JSX.Element {
  const navigate = useNavigate();
  let shouldShowAlert = false;
  if (dealDetailData.currentRound) {
    shouldShowAlert =
      (dealDetailData.currentDealStatus.status === DealStatusTypes.Screening ||
        dealDetailData.currentDealStatus.status === DealStatusTypes.Active) &&
      roundIsInGracePeriod(dealDetailData.currentRound);
  }
  const [isViewingDeck, setIsViewingDeck] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState<boolean>(shouldShowAlert);
  const { activate, isIdle, isIdlePromptShown } = useIdleStatus();

  const [toast, setToast] = useState<ToastProps>({
    isShown: false,
    onClose: () => null,
    title: '',
    text: '',
    isError: false,
  });

  const startupId = dealDetailData.startup._id;

  const { subscriptionTier } = useContext(AccountMetadataContext);
  const hasFitScoreAccess = hasFeatureAccess(
    InvestorPaidFeatures.DealFitScore,
    subscriptionTier,
  );

  const [modalConfig, setModalConfig] = useState<ModalConfig<DealActionModals>>(
    {
      isOpen: false,
    },
  );

  const redirectToPassed = () => navigate(PASSED_ROUTE);
  const redirectToPortfolio = () => navigate(PORTFOLIO_ROUTE);
  const closeModal = () => setModalConfig({ isOpen: false });
  const openModal = (dialog: DealActionModals) =>
    setModalConfig({ isOpen: true, dialog });

  async function updateDealViewTime() {
    if (!dealViewSessionId || isIdle || isViewingDeck) {
      return;
    }

    await updateDealViewSession({
      dealViewSessionId: dealViewSessionId,
      startupId: dealDetailData.startup._id,
      updateType: DealViewSessionUpdateType.DealViewTime,
    });
  }

  async function updateDeckViewTime() {
    if (!dealViewSessionId || isIdle || !isViewingDeck) {
      return;
    }

    await updateDealViewSession({
      dealViewSessionId: dealViewSessionId,
      startupId: dealDetailData.startup._id,
      updateType: DealViewSessionUpdateType.DeckViewTime,
    });
  }

  useTrackViewTime(updateDealViewTime);
  useTrackViewTime(updateDeckViewTime);

  async function moveToActive() {
    try {
      await API.put(InvestorUpdateDealStatus.buildEndpoint(), {
        startupId: startupId,
        status: DealStatusTypes.Active,
      });
      fetchDeal(true);
    } catch (error: any) {
      Logger.error(error);
    }
  }

  function acceptDeal() {
    moveToActive();
  }

  async function onVerifyDataRoomPassword(password: string) {
    if (!dealViewSessionId) {
      return {
        isError: true,
        message: 'Deal view session not found',
      };
    }

    return await verifyDataRoomPassword(startupId, password, dealViewSessionId);
  }

  async function onOpenDataRoomModal() {
    if (!dealViewSessionId) {
      return;
    }

    await updateDealViewSession({
      dealViewSessionId,
      startupId: dealDetailData.startup._id,
      updateType: DealViewSessionUpdateType.DataRoomAccessStatus,
      dataRoomAccessStatus: DataRoomAccessStatus.PasswordRequested,
    });
  }

  async function onViewDeck() {
    if (!dealViewSessionId) {
      return;
    }

    setIsViewingDeck(true);

    await updateDealViewSession({
      dealViewSessionId,
      startupId: dealDetailData.startup._id,
      updateType: DealViewSessionUpdateType.DeckViewCount,
    });
  }

  async function onDownloadDeck() {
    if (!dealViewSessionId) {
      return;
    }

    const latestDeck = getLatestDeck(dealDetailData.startup);
    try {
      const deckUrlResponse = await API.get<{ url: string }>(
        SharedGetStartupDeckById.buildEndpoint(dealDetailData.startup._id),
      );
      await downloadPdf(deckUrlResponse.url, latestDeck.fileName);

      await updateDealViewSession({
        dealViewSessionId,
        startupId: dealDetailData.startup._id,
        updateType: DealViewSessionUpdateType.DeckDownloadCount,
      });
    } catch (error: any) {
      Logger.error(error);
    }
  }

  function showSentAlert() {
    // A deal has just been shared via in app,
    // so re-fetch the deal to update the sharing data
    fetchDeal(true);
    const successToast = {
      isShown: true,
      onClose: () => setToast({ ...toast, isShown: false }),
      title: SUCCESS,
      text: SHARED_SUCCESS,
    };
    setToast(successToast);
    window.setTimeout(
      () => setToast({ ...successToast, isShown: false }),
      3000,
    );
  }

  function shareDeal() {
    openModal(DealActionModals.Share);
  }

  function getModalContent() {
    switch (modalConfig.dialog) {
      case DealActionModals.Invest:
        return (
          <RecordInvestmentDialog
            onClose={closeModal}
            onSuccess={redirectToPortfolio}
            deal={dealDetailData.dealSummaryView}
          />
        );
      case DealActionModals.Pass:
        return (
          <PassDialog
            onClose={closeModal}
            onSuccess={redirectToPassed}
            deal={dealDetailData.dealSummaryView}
          />
        );
      case DealActionModals.Share:
        return (
          <ShareDealDialog
            onClose={closeModal}
            onSuccess={showSentAlert}
            deal={dealDetailData.dealSummaryView}
          />
        );
      default:
        return <></>;
    }
  }

  const gracePeriodAlertContent = (
    <div className="text-sm font-normal">
      <p className="text-base font-medium leading-6">
        The founder has just closed their{' '}
        {dealDetailData?.currentRound?.roundStage} round.
      </p>
      <p className="my-2">
        You must decide on whether to pass or invest by&nbsp;
        <span className="font-medium">
          {dealDetailData?.currentRound?.gracePeriodEndsOn &&
            dealDetailData?.currentRound?.gracePeriodEndsOn.toLocaleString()}
          .
        </span>
      </p>
      <p>
        If you do not take any action Flowlie will automatically pass the deal
        for you.
      </p>
    </div>
  );

  if (!isInDealflow) {
    return (
      <div className="min-h-screen bg-gray-100">
        <main className="py-10">
          <h3>
            Oops! This deal either doesn&apos;t exist or is not in your
            dealflow!
          </h3>
        </main>
      </div>
    );
  }

  // main page content
  return (
    <div className="min-h-screen bg-gray-100">
      <main className="pb-8">
        <DealDetailHeader
          dealDetailData={dealDetailData}
          tabs={tabs}
          activeBoardStages={activeBoardStages}
          hasFitScoreAccess={hasFitScoreAccess}
          actionButton={
            <DealActionButtons
              label={dealDetailData.currentDealStatus.status}
              currentRound={dealDetailData.currentRound}
              accept={acceptDeal}
              pass={() => openModal(DealActionModals.Pass)}
              recordInvestment={() => openModal(DealActionModals.Invest)}
              share={shareDeal}
              moveToActive={moveToActive}
            />
          }
        />

        {/* Main Content On Page */}
        <section className="px-1">
          <Routes>
            {['', tabs[0].name].map((path) => (
              <Route
                key={path}
                path={toDashCase(path)}
                element={
                  <div className="mx-auto mt-8 grid max-w-full grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
                    <span className="lg:hidden">
                      <DealDetailDeckCard
                        startup={dealDetailData?.startup}
                        hasAccessedLatestDeck={
                          dealDetailData.investorActivity.hasAccessedLatestDeck
                        }
                        onDownloadDeck={onDownloadDeck}
                        onViewDeck={onViewDeck}
                      />
                    </span>
                    <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                      <Alert
                        alertType="Warning"
                        color="yellow"
                        content={gracePeriodAlertContent}
                        isShown={isAlertShown}
                        onClose={() => setIsAlertShown(false)}
                      />
                      <div className="joyride-dealDetail">
                        <DealDetailGeneralCard
                          startup={dealDetailData?.startup}
                        />
                      </div>
                      <DealDetailFoundersCard
                        startup={dealDetailData?.startup}
                      />
                      <DealDetailProductCard
                        startup={dealDetailData?.startup}
                      />
                      <DealDetailUserCard startup={dealDetailData?.startup} />
                      <DealDetailFinancialCard
                        startup={dealDetailData?.startup}
                      />
                      <DealDetailLegalCard startup={dealDetailData?.startup} />
                    </div>
                    <aside className="flex flex-col space-y-6">
                      <span className="hidden lg:block">
                        <DealDetailDeckCard
                          startup={dealDetailData?.startup}
                          hasAccessedLatestDeck={
                            dealDetailData.investorActivity
                              .hasAccessedLatestDeck
                          }
                          onDownloadDeck={onDownloadDeck}
                          onViewDeck={onViewDeck}
                        />
                      </span>

                      <DealDetailInvestmentSummaryCard
                        dealDetailData={dealDetailData}
                      />
                      <DealDetailRoundSummaryCard
                        currentRound={dealDetailData.currentRound}
                        startup={dealDetailData?.startup}
                      />
                      <DealDetailContactCard
                        startup={dealDetailData?.startup}
                      />
                      <DealDetailDataRoomCard
                        startup={dealDetailData?.startup}
                        onVerifyPassword={onVerifyDataRoomPassword}
                        onOpenModal={onOpenDataRoomModal}
                      />
                      <DealDetailTimeline startup={dealDetailData?.startup} />
                    </aside>
                  </div>
                }
              />
            ))}
            <Route
              path={toDashCase(tabs[1].name)}
              element={
                <div className="mt-8">
                  {dealDetailData.startup.roundHistory.length !== 0 ? (
                    <>
                      <RoundHistoryKpis
                        roundHistory={dealDetailData.startup.roundHistory}
                      />
                      {dealDetailData.startup.roundHistory.map(
                        (round: RoundView) => (
                          <RoundCard
                            key={round.openedOn.toISOString()}
                            round={round}
                          />
                        ),
                      )}
                    </>
                  ) : (
                    <Alert
                      alertType="Info"
                      canDismiss={false}
                      color="blue"
                      content={`${dealDetailData.startup.name} does not have a fundraising history
                      yet.`}
                      isShown
                      onClose={() => false}
                    />
                  )}
                </div>
              }
            />
            <Route
              path={toDashCase(tabs[2].name)}
              element={
                <DealDetailActivityTab
                  investorActivity={dealDetailData?.investorActivity}
                />
              }
            />
            <Route
              path={`${toDashCase(tabs[3].name)}/*`}
              element={
                <DealDetailSharingTab
                  currentStage={
                    dealDetailData.startup.roundHistory.length > 0
                      ? sortDataByDate(
                          [...dealDetailData.startup.roundHistory],
                          'openedOn',
                        ).map((round: RoundView) => round.roundDisplayName)[0]
                      : dealDetailData?.startup.currentStage
                  }
                  stages={sortDataByDate(
                    [...dealDetailData.startup.roundHistory],
                    'openedOn',
                  ).map((round: RoundView) => round.roundDisplayName)}
                  dealStatus={dealDetailData?.currentDealStatus.status}
                  receivedFrom={
                    dealDetailData?.aggregateSharingData.receivedFrom
                  }
                  sharedWith={dealDetailData?.aggregateSharingData.sharedWith}
                />
              }
            />
            <Route
              path="*"
              element={<Navigate to={NOT_FOUND_ROUTE} replace />}
            />
          </Routes>
        </section>
        <IdlePrompt open={isIdlePromptShown} onClose={activate} />

        <ModalWrapper open={modalConfig.isOpen} onClose={() => closeModal()}>
          {getModalContent()}
        </ModalWrapper>
        <DeckViewer
          open={isViewingDeck}
          onClose={() => setIsViewingDeck(false)}
          startup={dealDetailData.startup}
        />
        <Toast
          isShown={toast.isShown}
          onClose={toast.onClose}
          title={toast.title}
          text={toast.text}
          isError={toast.isError}
        />
      </main>
    </div>
  );
}

export default DealDetailRouteContent;
