enum AuthFieldNames {
  AcceptLegal = 'acceptLegal',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  Password = 'password',
  PasswordCreate = 'passwordCreate',
  ReCaptchaToken = 'reCaptchaToken',
}

export default AuthFieldNames;
