import SessionStorageKeys from '/src/constants/SessionStorageKeys';
import AuthFieldNames from '/src/enums/AuthFieldNames';

export function saveVisitorNameAndEmail(
  firstName: string,
  lastName: string,
  email: string
): void {
  const visitorInfo = {
    firstName,
    lastName,
    email,
  };
  sessionStorage.setItem(
    SessionStorageKeys.VISITOR_SESSION_NAME_AND_EMAIL,
    JSON.stringify(visitorInfo)
  );
}

export function saveVisitorSessionToken(token: string): void {
  sessionStorage.setItem(SessionStorageKeys.VISITOR_SESSION_TOKEN, token);
}

export function getVisitorNameAndEmail(): {
  [AuthFieldNames.FirstName]: string;
  [AuthFieldNames.LastName]: string;
  [AuthFieldNames.Email]: string;
} | null {
  const visitorInfo = sessionStorage.getItem(
    SessionStorageKeys.VISITOR_SESSION_NAME_AND_EMAIL
  );
  return visitorInfo ? JSON.parse(visitorInfo) : null;
}

export function getVisitorSessionToken(): string | null {
  return sessionStorage.getItem(SessionStorageKeys.VISITOR_SESSION_TOKEN);
}

export function removeVisitorNameAndEmail(): void {
  sessionStorage.removeItem(SessionStorageKeys.VISITOR_SESSION_NAME_AND_EMAIL);
}

export function removeVisitorSessionToken(): void {
  sessionStorage.removeItem(SessionStorageKeys.VISITOR_SESSION_TOKEN);
}
