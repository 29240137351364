import { SubscriptionBillingInterval } from '../types/model/Subscription';

export enum SubscriptionTiers {
  StartupFree = 'StartupFree',
  StartupPro = 'StartupPro',
  StartupPremium = 'StartupPremium',
  InvestorFree = 'InvestorFree',
  InvestorPro = 'InvestorPro',
  InvestorTeam = 'InvestorTeam', // Same as Pro, but reserved for the billing manager of a group of investors
}

export const SubscriptionTiersNameMap = new Map<SubscriptionTiers, string>([
  [SubscriptionTiers.StartupFree, 'Free'],
  [SubscriptionTiers.StartupPro, 'Pro'],
  [SubscriptionTiers.StartupPremium, 'Premium'],
  [SubscriptionTiers.InvestorFree, 'Free'],
  [SubscriptionTiers.InvestorPro, 'Pro'],
  [SubscriptionTiers.InvestorTeam, 'Team'],
]);

export const DEFAULT_CREDITS = 2;
const STARTUP_PRO_MONTHLY_CREDITS = 25;
const STARTUP_PREMIUM_MONTHLY_CREDITS = 75;

export function getSubscriptionTierCredits(
  tier: SubscriptionTiers,
  billingInterval: SubscriptionBillingInterval,
  billingIntervalCount: number
): number {
  if (
    tier === SubscriptionTiers.StartupFree ||
    tier === SubscriptionTiers.InvestorFree
  ) {
    return DEFAULT_CREDITS;
  }

  const monthlyIntervals =
    billingInterval === SubscriptionBillingInterval.Year
      ? 12
      : billingInterval === SubscriptionBillingInterval.Month
      ? 1
      : 0;

  if (tier === SubscriptionTiers.StartupPro) {
    return (
      STARTUP_PRO_MONTHLY_CREDITS * monthlyIntervals * billingIntervalCount
    );
  }
  if (tier === SubscriptionTiers.StartupPremium) {
    return (
      STARTUP_PREMIUM_MONTHLY_CREDITS * monthlyIntervals * billingIntervalCount
    );
  }

  // At the moment the investor product does not have any AI features
  // So just return the default amount?
  if (
    tier === SubscriptionTiers.InvestorPro ||
    tier === SubscriptionTiers.InvestorTeam
  ) {
    return DEFAULT_CREDITS;
  }

  return 0;
}
