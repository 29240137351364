import React, { useContext, useEffect, useState } from 'react';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import {
  DiscoveredFromDialog,
  DiscoveryDialogValues,
} from '/src/components/InvestorFlowlinkForm/DiscoveredFromDialog';
import { configureForm } from '/src/components/Authentication/AuthDialog/AuthDialogConfiguration';
import { FlowlieBadge } from '/src/components/FlowlieIcons';
import { formatImageAddress, toDashCase } from '/src/util/formatting/strings';
import { FormOptions } from '/src/components/Authentication/AuthDialog/FormOptions';
import { Helmet } from 'react-helmet-async';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import { localStorageGetAuthenticatedAccountType } from '/src/middleware/LocalStorage';
import { PublicInvestor } from '/../libs/shared-types/src/types/view/investor/PublicInvestor';
import { PublicProfilePrivacySetting } from '/../libs/shared-types/src/constants/PublicProfilePrivacySetting';
import { Routes, Route, useNavigate } from 'react-router-dom';
import AccountTypeForm from '/src/components/Authentication/AccountTypeForm';
import Alert from '/src/components/notifications/Alert';
import API from '/src/middleware/API';
import AuthDialog from '/src/components/Authentication/AuthDialog/AuthDialog';
import AuthDialogWrapper from '/src/components/Authentication/AuthDialog/AuthDialogWrapper';
import InvestorDetailAboutCard from '/src/routes/investor/InvestorDetail/cards/InvestorDetailAboutCard';
import InvestorDetailContactCard from '/src/routes/investor/InvestorDetail/cards/InvestorDetailContactCard';
import InvestorDetailHeader from '/src/routes/investor/InvestorDetail/InvestorDetailHeader';
import InvestorDetailThesisCard from '/src/routes/investor/InvestorDetail/cards/InvestorDetailThesisCard';
import InvestorDetailValueAddCard from '/src/routes/investor/InvestorDetail/cards/InvestorDetailValueAddCard';
import InvestorFlowlinkFormFieldNames from '/src/enums/InvestorFlowlinkFormFieldNames';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Logger from '/src/services/logger';
import TabsHeader from '/src/components/tabs/TabsHeader';
import AuthService from '/src/services/AuthService';
import {
  STARTUP_ACCESS_MANAGER_ROUTE,
  CONTACT_MANAGER_ROUTE,
} from '/src/constants/Routes';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';
import { PublicGetPublicInvestor } from '/../libs/shared-types/src/constants/ApiRoutes';

interface RequestAccessButtonProps {
  requestAccess?: () => void;
  initialAccountType: AccountTypes | undefined;
}

function RequestAccessButton({
  requestAccess,
  initialAccountType,
}: RequestAccessButtonProps): JSX.Element {
  const updatedAccountType = useContext(
    AuthenticationContext,
  ).unconfirmedAccountType;

  const buttonText =
    updatedAccountType === AccountTypes.Investor
      ? 'Connect with me'
      : initialAccountType === AccountTypes.Startup
        ? 'Pitch me'
        : "Let's connect";

  if (!buttonText) {
    return <></>;
  }

  return (
    <button type="button" onClick={requestAccess} className="button">
      {buttonText}
    </button>
  );
}

interface PublicInvesorProfileProps {
  token: string;
}

function PublicInvestorProfile({
  token,
}: PublicInvesorProfileProps): JSX.Element {
  const tabs = [{ name: 'Profile' }];

  const {
    unconfirmedAccountType,
    setUnconfirmedAccountType,
    invitation,
    setInvitation,
  } = useContext(AuthenticationContext);
  const [investor, setInvestor] = useState<PublicInvestor>();
  const [showAuthDialog, setShowAuthDialog] = useState(false);
  const [showDiscoveredFromDialog, setShowDiscoveredFromDialog] =
    useState(false);
  const [
    discoveredFromDialogInitialValues,
    setDiscoveredFromDialogInitialValues,
  ] = useState<DiscoveryDialogValues>({
    [InvestorFlowlinkFormFieldNames.LinkDiscovery]: '',
    [InvestorFlowlinkFormFieldNames.ConnectionMessage]: '',
  });
  const navigate = useNavigate();
  const [showAccountTypeDialog, setShowAccountTypeDialog] =
    useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);

  const investorFullName = investor
    ? `${investor.firstName} ${investor.lastName}`
    : '';
  const investorFirmAndRole =
    investor?.firm && investor?.role
      ? `${investor.role} at ${investor.firm}`
      : 'Angel Investor';

  function redirectToApp() {
    const accountType = localStorageGetAuthenticatedAccountType();

    if (accountType === AccountTypes.Investor) {
      navigate(CONTACT_MANAGER_ROUTE);
    } else if (accountType === AccountTypes.Startup) {
      navigate(STARTUP_ACCESS_MANAGER_ROUTE);
    } else {
      navigate('/');
    }
  }

  async function fetchPublicInvestor() {
    try {
      setIsLoading(true);
      const publicInvestor = await API.get<PublicInvestor>(
        PublicGetPublicInvestor.buildEndpoint(token),
      );
      setInvestor(publicInvestor);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleDiscoveryFormCompletion(
    discoveryDialogValues: DiscoveryDialogValues,
  ) {
    setDiscoveredFromDialogInitialValues(discoveryDialogValues);
    if (invitation) {
      setInvitation({
        ...invitation,
        discoveredFrom: discoveryDialogValues.LinkDiscovery,
        message: discoveryDialogValues.ConnectionMessage,
      });
    }
    setShowDiscoveredFromDialog(false);

    if (AuthService.isLoggedIn()) {
      redirectToApp();
      return;
    }

    setShowAuthDialog(true);
  }

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      const loggedInAccountType = localStorageGetAuthenticatedAccountType();
      if (Object.values<string>(AccountTypes).includes(loggedInAccountType)) {
        setUnconfirmedAccountType(loggedInAccountType as AccountTypes);
      }
    }
    fetchPublicInvestor();
  }, []);

  useEffect(() => {
    if (unconfirmedAccountType) {
      setShowAccountTypeDialog(false);
    }
  }, [unconfirmedAccountType]);

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner size={14} color="blue" />
      </div>
    );
  }

  if (!investor) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Alert
          alertType="Info"
          canDismiss={false}
          color="blue"
          content="The investor's profile could not be loaded"
          isShown={true}
        />
      </div>
    );
  }

  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>{investorFullName} on Flowlie</title>
      </Helmet>
      <div className="mx-auto min-h-screen w-full px-6 lg:max-w-7xl">
        <div className="min-h-screen bg-gray-100">
          <main className="pb-8">
            <header className="sticky top-0 z-10 bg-gray-100 sm:pt-6">
              <div className="relative mx-auto max-w-full md:flex md:items-center md:justify-between md:space-x-5">
                <div className="flex w-full items-center justify-between">
                  <InvestorDetailHeader investor={investor} />
                  <RequestAccessButton
                    initialAccountType={unconfirmedAccountType}
                    requestAccess={() => setShowDiscoveredFromDialog(true)}
                  />
                </div>
              </div>

              <TabsHeader tabs={tabs} />
            </header>
            <div className="px-1">
              <>
                <Routes>
                  {investor &&
                    ['', tabs[0].name].map((path) => (
                      <Route
                        key={path}
                        path={toDashCase(path)}
                        element={
                          <main className="grid grid-cols-1 gap-4 lg:w-full lg:grid-cols-2">
                            <div className="flex flex-1 flex-col">
                              <div className="joyride-investorDetailThesis mt-4">
                                <InvestorDetailThesisCard investor={investor} />
                              </div>
                              <div className="mt-4">
                                <InvestorDetailValueAddCard
                                  investor={investor}
                                />
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col">
                              <div className="mt-4">
                                <InvestorDetailAboutCard investor={investor} />
                              </div>
                              <div className="mt-4">
                                <InvestorDetailContactCard
                                  investor={investor}
                                  isConnected={false}
                                />
                              </div>
                            </div>
                          </main>
                        }
                      />
                    ))}
                </Routes>
              </>
            </div>
            <footer>
              <FlowlieBadge />
              <p className="block px-4 py-3 text-center text-2xs text-gray-600">
                The information was provided by the respective investor, and
                Flowlie Technologies, Inc. has not verified the source,
                information, or data in this document and does not warrant its
                accuracy.
              </p>
            </footer>
          </main>
        </div>
      </div>
      <AuthDialogWrapper
        wrapperConfig={{
          title: `${investorFullName}`,
          subTitle: `${investorFirmAndRole}`,
          imageSrc: formatImageAddress(investor.profilePicKey) ?? '',
          imageAlt: 'Investor profile',
        }}
        showXButton={false}
        isOpen={showAccountTypeDialog}
        onClose={() => setShowAccountTypeDialog(true)}
      >
        <div className="mt-12">
          <AccountTypeForm
            setUnconfirmedAccountType={setUnconfirmedAccountType}
          />
        </div>
      </AuthDialogWrapper>

      <AuthDialogWrapper
        wrapperConfig={{
          title: configureForm(
            LinkTypes.Flowlink,
            PublicProfilePrivacySetting.Public,
            {
              consumerAccountType: unconfirmedAccountType,
              startupName: '',
              startupAccountFirstName: '',
              sharerAccountType: AccountTypes.Investor,
              sharerFirstName: investorFullName,
              companyLogo: investor.profilePicKey ?? '',
              role: investor.role,
              firm: investor.firm,
            },
          ).title,
          subTitle: '',
          imageSrc: formatImageAddress(investor.profilePicKey) ?? '',
          imageAlt: 'Investor profile',
        }}
        showXButton
        isOpen={showDiscoveredFromDialog}
        onClose={() => setShowDiscoveredFromDialog(false)}
      >
        <DiscoveredFromDialog
          onSuccess={handleDiscoveryFormCompletion}
          initialValues={discoveredFromDialogInitialValues}
        />
      </AuthDialogWrapper>

      <AuthDialog
        // Prevent closing the form is the accountType has been reset
        canCloseForm={unconfirmedAccountType !== undefined}
        isOpen={showAuthDialog}
        setIsOpen={setShowAuthDialog}
        linkType={LinkTypes.Flowlink}
        privacySetting={PublicProfilePrivacySetting.Public}
        onVisitorSessionCreated={() => Promise.resolve()}
        initialForm={FormOptions.Signup}
        config={{
          consumerAccountType: unconfirmedAccountType,
          startupName: '',
          startupAccountFirstName: '',
          sharerAccountType: AccountTypes.Investor,
          sharerFirstName: investorFullName,
          companyLogo: investor.profilePicKey ?? '',
          role: investor.role,
          firm: investor.firm,
        }}
      />
    </div>
  );
}
export default PublicInvestorProfile;
