import { RoundStages } from '../constants/RoundStages';
import { ValuationCapType } from '../constants/ValuationCapType';
import {
  getEstimatedRaise,
  shouldHaveValuationCap,
} from '../extensions/RoundExtensions';
import { ElementColor } from '../types/Utility/Color';
import { Round } from '../types/model/Round';
import { calculatePercentSold } from './RoundCalculations';

export enum DilutionMetric {
  GreatDilution = 'Great Dilution',
  FairDilution = 'Fair Dilution',
  RiskOverDilution = 'Risk of Over-dilution',
  VeryHighRiskOverDilution = 'Very High Risk of Over-dilution',
}

const dilutionMetricMap = new Map<
  DilutionMetric,
  {
    explanation: string;
    color: ElementColor;
  }
>([
  [
    DilutionMetric.GreatDilution,
    {
      explanation:
        'You are not at any risk of over-dilution for this current round, giving you more flexibility for future rounds.',
      color: {
        bgColor: 'bg-green-100',
        textColor: 'text-green-900',
      },
    },
  ],
  [
    DilutionMetric.FairDilution,
    {
      explanation:
        'You are taking an average amount of dilution and are not at risk of over-dilution for this current round.',
      color: {
        bgColor: 'bg-lime-100',
        textColor: 'text-lime-900',
      },
    },
  ],
  [
    DilutionMetric.RiskOverDilution,
    {
      explanation:
        'You are at risk of over-dilution for this current round which may lower your chances of securing venture capital financing in the future.',
      color: {
        bgColor: 'bg-orange-100',
        textColor: 'text-orange-900',
      },
    },
  ],
  [
    DilutionMetric.VeryHighRiskOverDilution,
    {
      explanation:
        'You are at a very high risk of over-dilution for this current round which will dramatically impact your chances of securing venture capital financing in the future.',
      color: {
        bgColor: 'bg-red-100',
        textColor: 'text-red-900',
      },
    },
  ],
]);

export interface RoundDilutionAnalysis {
  percentSold: number;
  dilutionMetric: DilutionMetric;
  explanation: string;
  additionalInfo: string;
  color: ElementColor;
}

function getDilutionMetricExplanation(metric: DilutionMetric) {
  const info = dilutionMetricMap.get(metric);
  if (!info) {
    throw new Error(`Dilution metric ${metric} not found`);
  }
  return { explanation: info.explanation, color: info.color };
}

function getMetric(
  percentSold: number,
  upperThresholds: { great: number; fair: number; risk: number },
  additionalInfo: string
): RoundDilutionAnalysis {
  let dilutionMetric = DilutionMetric.VeryHighRiskOverDilution;

  if (percentSold <= upperThresholds.great) {
    dilutionMetric = DilutionMetric.GreatDilution;
  } else if (percentSold <= upperThresholds.fair) {
    dilutionMetric = DilutionMetric.FairDilution;
  } else if (percentSold <= upperThresholds.risk) {
    dilutionMetric = DilutionMetric.RiskOverDilution;
  }

  const { explanation, color } = getDilutionMetricExplanation(dilutionMetric);
  return {
    percentSold,
    dilutionMetric,
    explanation,
    additionalInfo,
    color,
  };
}

export function getRoundDilutionAnalysis(
  round: Round
): RoundDilutionAnalysis | undefined {
  if (
    !shouldHaveValuationCap(round) ||
    round.valuationCap === undefined ||
    round.valuationCapType === undefined
  ) {
    return undefined;
  }

  const percentSold = calculatePercentSold(
    round.raiseTarget,
    round.raisedAmount,
    round.valuationCap,
    round.valuationCapType
  );
  if (percentSold === undefined) {
    return undefined;
  }

  const estimatedRaise = getEstimatedRaise(round);

  if (round.isBridge) {
    const upperThresholds = {
      great: 5,
      fair: 10,
      risk: 17.5,
    };
    return getMetric(
      percentSold,
      upperThresholds,
      `During bridge rounds, founders typically sell between ${upperThresholds.great}% and ${upperThresholds.fair}% of their company.`
    );
  }

  if (
    round.roundStage === RoundStages.Angel ||
    round.roundStage === RoundStages.PreSeed
  ) {
    // TODO: If we move the abbreviateNumber function to shared utils, we can use it here
    // NOTE: If raiseTargets change, make sure to update the exaplanations!
    const raiseTargets = {
      small: 250_000,
      large: 1_000_000,
    };

    if (estimatedRaise <= raiseTargets.small) {
      const upperThresholds = {
        great: 2.5,
        fair: 5,
        risk: 10,
      };
      return getMetric(
        percentSold,
        upperThresholds,
        `During ${round.roundStage} rounds smaller than $250K, founders typically sell between ${upperThresholds.great}% and ${upperThresholds.fair}% of their company.`
      );
    }

    if (estimatedRaise <= raiseTargets.large) {
      const upperThresholds = {
        great: 5,
        fair: 10,
        risk: 15,
      };
      return getMetric(
        percentSold,
        upperThresholds,
        `During ${round.roundStage} rounds between $250K and $1M, founders typically sell between ${upperThresholds.great}% and ${upperThresholds.fair}% of their company.`
      );
    }

    const upperThresholds = { great: 10, fair: 20, risk: 25 };
    return getMetric(
      percentSold,
      upperThresholds,
      `During ${round.roundStage} rounds larger than $1M, founders typically sell between ${upperThresholds.great}% and ${upperThresholds.fair}% of their company.`
    );
  }

  if (round.roundStage === RoundStages.Seed) {
    const raiseTargets = {
      small: 1_500_000,
    };
    if (estimatedRaise <= raiseTargets.small) {
      const upperThresholds = { great: 10, fair: 17.5, risk: 25 };
      return getMetric(
        percentSold,
        upperThresholds,
        `During ${round.roundStage} rounds smaller than $1.5M, founders typically sell between ${upperThresholds.great}% and ${upperThresholds.fair}% of their company.`
      );
    }

    const upperThresholds = { great: 10, fair: 20, risk: 27.5 };
    return getMetric(
      percentSold,
      upperThresholds,
      `During ${round.roundStage} rounds larger than $1.5M, founders typically sell between ${upperThresholds.great}% and ${upperThresholds.fair}% of their company.`
    );
  }

  if (round.roundStage === RoundStages.SeriesA) {
    const upperThresholds = { great: 15, fair: 25, risk: 30 };
    return getMetric(
      percentSold,
      upperThresholds,
      `During ${round.roundStage} rounds, founders typically sell between ${upperThresholds.great}% and ${upperThresholds.fair}% of their company.`
    );
  }

  if (round.roundStage === RoundStages.SeriesB) {
    const upperThresholds = { great: 10, fair: 20, risk: 30 };
    return getMetric(
      percentSold,
      upperThresholds,
      `During ${round.roundStage} rounds, founders typically sell between ${upperThresholds.great}% and ${upperThresholds.fair}% of their company.`
    );
  }

  if (
    round.roundStage === RoundStages.SeriesC ||
    round.roundStage === RoundStages.SeriesD ||
    round.roundStage === RoundStages.SeriesE ||
    round.roundStage === RoundStages.SeriesF
  ) {
    const upperThresholds = { great: 7.5, fair: 15, risk: 20 };
    return getMetric(
      percentSold,
      upperThresholds,
      `During ${round.roundStage} and later primary rounds, founders typically sell between ${upperThresholds.great}% and ${upperThresholds.fair}% of their company.`
    );
  }

  // We should never get here
  return undefined;
}
