import React from 'react';
import * as yup from 'yup';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';
import BooleanSelectInput from '../../inputs/BooleanSelectInput';

const HasCustomersField: FormField = {
  fieldComponent: (
    <BooleanSelectInput
      label="Do you have paying customers?"
      name={StartupFieldNames.HasCustomers}
      isClearable
      tooltip="A paying customer is a customer who pays for the goods or services they receive"
    />
  ),
  validation: yup.object({
    [StartupFieldNames.HasCustomers]: yup.bool().nullable(),
  }),
};

export default HasCustomersField;
