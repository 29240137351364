import React from 'react';
import { joinClassNames } from '../util/formatting/strings';

interface RoundImageWithPlaceholderProps {
  containerStyles: string;
  textStyles: string;
  text: string;
  imgSrc?: string;
  imgAlt: string;
}

function RoundImageWithPlaceholder({
  containerStyles,
  text,
  textStyles,
  imgAlt,
  imgSrc,
}: RoundImageWithPlaceholderProps): JSX.Element {
  if (imgSrc) {
    return (
      <img
        className={joinClassNames(containerStyles, 'rounded-full')}
        src={imgSrc}
        alt={imgAlt}
      />
    );
  }

  return (
    <span
      className={joinClassNames(
        containerStyles,
        'inline-flex items-center justify-center rounded-full bg-slate-500 '
      )}
    >
      <span
        className={joinClassNames(
          textStyles,
          'font-medium leading-none text-white'
        )}
      >
        {text}
      </span>
    </span>
  );
}

export default RoundImageWithPlaceholder;
