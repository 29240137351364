import React, { useEffect, useState } from 'react';
import DebugRender from './DebugRender';
import { PlayCircleIcon, PlayIcon } from '@heroicons/react/20/solid';
import Logger from '/src/services/logger';
import LoadingSpinner from './LoadingSpinner';

const videoPaths = {
  youtube: 'https://www.youtube.com',
  youtubeShort: 'https://youtu.be',
  loom: 'https://www.loom.com',
  vimeo: 'https://vimeo.com/',
};

async function canEmbedYoutube(youtubeUrl: string): Promise<string | null> {
  if (
    !(
      youtubeUrl.includes(videoPaths.youtube) ||
      youtubeUrl.includes(videoPaths.youtubeShort)
    )
  ) {
    return null;
  }

  try {
    const response = await fetch(
      `https://www.youtube.com/oembed?url=${encodeURIComponent(
        youtubeUrl
      )}&format=json`
    );

    if (!response.ok) {
      return null;
    }

    const data = await response.json();
    return data.html ? data.html : null;
  } catch (error) {
    Logger.error('Cannot embed Youtube video:', error);
    return null;
  }
}

async function canEmbedLoom(loomUrl: string): Promise<string | null> {
  if (!loomUrl.includes(videoPaths.loom)) {
    return null;
  }

  try {
    const response = await fetch(loomUrl);

    if (!response.ok) {
      return null;
    }

    // Does not have an oEmbed endpoint, so we won't do a full check
    return loomUrl;
  } catch (error) {
    Logger.error('Cannot embed Loom video:', error);
    return null;
  }
}

async function canEmbedVimeo(vimeoUrl: string): Promise<string | null> {
  if (!vimeoUrl.includes(videoPaths.vimeo)) {
    return null;
  }

  try {
    const response = await fetch(
      `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(vimeoUrl)}`
    );

    if (!response.ok) {
      return null;
    }

    const vimeoEmbedUrl = `https://player.vimeo.com/video/${
      vimeoUrl.split(videoPaths.vimeo)[1]
    }?title=0&byline=0&portrait=0`;

    const data = await response.json();
    if (data.domain_status_code === undefined) {
      return vimeoEmbedUrl;
    }

    if (data.domain_status_code !== 200 || !data.html) {
      return null;
    }

    const embedUrlResponse = await fetch(vimeoEmbedUrl);
    if (!embedUrlResponse.ok) {
      return null;
    }
    return vimeoEmbedUrl;
  } catch (error) {
    Logger.error('Cannot embed Vimeo video:', error);
    return null;
  }
}

interface VideoEmbedUrl {
  embedUrl: string;
  type: 'youtu.be' | 'youtube' | 'loom' | 'vimeo' | 'notSupported';
}

async function getVideoEmbedUrl(urlSrc: string): Promise<VideoEmbedUrl> {
  if (
    urlSrc.includes(videoPaths.loom) &&
    (await canEmbedLoom(urlSrc)) !== null
  ) {
    const loomEmbedUrl = `${videoPaths.loom}/embed/${
      urlSrc.split('share/')[1]
    }`;
    return {
      type: 'loom',
      embedUrl: loomEmbedUrl,
    };
  }

  if (
    urlSrc.includes(videoPaths.vimeo)
    // && (await canEmbedVimeo(urlSrc)) !== null
  ) {
    // const vimeoEmbedUrl = `https://player.vimeo.com/video/${
    //   urlSrc.split(videoPaths.vimeo)[1]
    // }?title=0&byline=0&portrait=0`;
    const vimeoEmbedUrl = await canEmbedVimeo(urlSrc);
    if (vimeoEmbedUrl !== null) {
      return {
        type: 'vimeo',
        embedUrl: vimeoEmbedUrl,
      };
    }
  }

  const canEmbedYoutubeUrl = await canEmbedYoutube(urlSrc);
  if (urlSrc.includes(videoPaths.youtube) && canEmbedYoutubeUrl !== null) {
    const youtubeEmbedUrl = `${videoPaths.youtube}/embed/${
      urlSrc.split('watch?v=')[1]
    }`;
    return {
      type: 'youtube',
      embedUrl: youtubeEmbedUrl,
    };
  }

  if (urlSrc.includes(videoPaths.youtubeShort) && canEmbedYoutubeUrl !== null) {
    const youtubeEmbedUrl = `${videoPaths.youtube}/embed/${
      urlSrc.split('.be/')[1]
    }`;
    return {
      type: 'youtube',
      embedUrl: youtubeEmbedUrl,
    };
  }

  return {
    type: 'notSupported',
    embedUrl: urlSrc,
  };
}

interface VideoPlayerProps {
  urlSrc: string;
  videoTitle: string;
}

function VideoPlayer({ urlSrc, videoTitle }: VideoPlayerProps) {
  const [embedVideo, setEmbedVideo] = useState<VideoEmbedUrl | null>(null);

  useEffect(() => {
    const fetchEmbedVideo = async () => {
      const embedUrl = await getVideoEmbedUrl(urlSrc);
      setEmbedVideo(embedUrl);
    };

    fetchEmbedVideo();
  }, [urlSrc]);

  if (embedVideo === null || embedVideo.type === 'notSupported') {
    return (
      <div className="group relative h-0 overflow-hidden rounded-lg bg-slate-900 pb-[62.5%]">
        <a
          href={urlSrc}
          target="_blank"
          rel="noopener noreferrer"
          className="absolute top-0 left-0 flex h-full w-full items-center justify-center"
        >
          {embedVideo === null && <LoadingSpinner color="white" size={7} />}

          {embedVideo !== null && (
            <>
              <span className="absolute top-5 left-7 truncate font-medium text-white">
                {videoTitle}
              </span>

              <div className="relative h-20 w-20">
                <PlayIcon className="absolute top-1/4 left-1/4 h-10 w-10 text-white group-hover:text-gray-100" />
                <PlayCircleIcon className="absolute h-20 w-20 text-blue-500 group-hover:text-blue-600" />
              </div>
            </>
          )}
        </a>
      </div>
    );
  }

  return (
    <>
      <div className="rounded-lg bg-slate-900">
        <div className="relative h-0 overflow-hidden rounded-lg pb-[62.5%]">
          <iframe
            title={`${embedVideo.type} video player`}
            src={embedVideo.embedUrl}
            className="absolute top-0 left-0 h-full w-full"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            frameBorder="0"
          />
          {embedVideo.type === 'vimeo' && (
            <script src="https://player.vimeo.com/api/player.js"></script>
          )}
        </div>
      </div>
      <DebugRender>
        <pre>url: {urlSrc}</pre>
        <pre>embedUrl: {embedVideo.embedUrl}</pre>
      </DebugRender>
    </>
  );
}

export default VideoPlayer;
