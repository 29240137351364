import React from 'react';
import RoundImageWithPlaceholder from './RoundImageWithPlaceholder';

interface UserAvatarWithInitialsProps {
  containerStyles: string;
  textStyles: string;
  firstName: string;
  lastName?: string;
  imgSrc?: string;
  imgAlt: string;
}

function UserAvatarWithInitials({
  containerStyles,
  textStyles,
  firstName,
  lastName,
  imgSrc,
  imgAlt,
}: UserAvatarWithInitialsProps): JSX.Element {
  const initials =
    firstName.length > 0 && (!lastName || lastName.length > 0)
      ? (firstName[0] + (lastName ?? [''])[0]).toUpperCase()
      : '';

  return (
    <RoundImageWithPlaceholder
      containerStyles={containerStyles}
      text={initials}
      textStyles={textStyles}
      imgAlt={imgAlt}
      imgSrc={imgSrc}
    />
  );
}

export default UserAvatarWithInitials;
