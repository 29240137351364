import React from 'react';
import { generateTag } from '/src/util/generateTagCloud';
import { InternalStartup } from '/../libs/shared-types/src/types/view/startup/InternalStartup';
import VideoPlayer from '/src/components/utility/VideoPlayer';
import { numberWithCommas } from '/src/util/formatting/numbers';

interface DealDetailProductCardProps {
  startup: InternalStartup;
}

function DealDetailProductCard({
  startup,
}: DealDetailProductCardProps): JSX.Element {
  if (
    startup.productType ||
    startup.productStatus ||
    startup.primaryGrowthDriver ||
    startup.revenueType ||
    startup.hasInventory !== undefined ||
    startup.icp ||
    startup.totalAddressableMarket !== undefined ||
    startup.productDemoVideoUrl
  ) {
    return (
      <section>
        <div className="rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Product &amp; Market
            </h3>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
              {startup.productType && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Product Type
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateTag(startup.productType)}
                  </dd>
                </div>
              )}
              {startup.productStatus && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Product Status
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateTag(startup.productStatus)}
                  </dd>
                </div>
              )}
              {startup.primaryGrowthDriver !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Primary Growth Driver
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateTag(startup.primaryGrowthDriver)}
                  </dd>
                </div>
              )}
              {startup.revenueType !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Primary Revenue Type
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateTag(startup.revenueType)}
                  </dd>
                </div>
              )}
              {startup.hasInventory !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Holds Inventory
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateTag(startup.hasInventory ? 'Yes' : 'No')}
                  </dd>
                </div>
              )}
              {startup.totalAddressableMarket !== undefined && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Total Addressable Market
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {`$ ${numberWithCommas(startup.totalAddressableMarket)}`}
                  </dd>
                </div>
              )}
              {startup.icp && (
                <div className="sm:col-span-3">
                  <dt className="text-sm font-medium text-gray-500">
                    Ideal Customer Profile (ICP)
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{startup.icp}</dd>
                </div>
              )}
            </dl>
            <dl className="mt-8 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-x-8">
              {startup.productDemoVideoUrl && (
                <div className="sm:col-span-1">
                  <dt className="sr-only text-sm font-medium text-gray-500">
                    Brief Product Demo
                  </dt>
                  <VideoPlayer
                    videoTitle="Brief Product Demo"
                    urlSrc={startup.productDemoVideoUrl}
                  />
                </div>
              )}
            </dl>
          </div>
        </div>
      </section>
    );
  }
  return <></>;
}

export default DealDetailProductCard;
