import React, { useContext, useEffect, useState } from 'react';
import {
  Subscription,
  SubscriptionStatus,
} from '/../libs/shared-types/src/types/model/Subscription';
import {
  SubscriptionTiers,
  SubscriptionTiersNameMap,
} from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { createStripeCustomerPortal } from '../../../services/StripeService';
import { getFeedbackFormUrl } from '/src/util/urls';
import API from '/src/middleware/API';
import { SharedGetSubscription } from '/../libs/shared-types/src/constants/ApiRoutes';
import { getPluralSuffix } from '/src/util/formatting/numbers';
import Logger from '/src/services/logger';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { BoltIcon } from '@heroicons/react/20/solid';
import MailToLink from '/src/components/utility/MailToLink';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import PremiumCTA from '../../../components/PremiumCTA';
import {
  MARK_FUNDRAISING_CONSULTATION,
  MARK_SALES_CALL,
} from '../../../constants/Marketing/CalendlyLinks';

function ManagePaidSubscriptionCard(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [subscription, setSubscription] = useState<Subscription>();
  const [isTrial, setIsTrial] = useState<boolean>();
  const { accountType } = useContext(AccountMetadataContext);

  async function fetchSubscription() {
    try {
      const data = await API.get<Subscription>(
        SharedGetSubscription.buildEndpoint(),
      );
      setSubscription(data);
      setIsTrial(data.stripeSubscriptionStatus === SubscriptionStatus.Trialing);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchSubscription();
  }, []);

  if (isLoading) {
    return <PageLoadingSpinner />;
  }

  if (!subscription) {
    return <></>;
  }

  return (
    <section className="max-w-4xl space-y-6 py-24 sm:px-6 sm:py-24 lg:px-8">
      <div className="relative isolate overflow-hidden bg-white px-6 py-12 shadow ring-1 ring-gray-200 sm:rounded-3xl sm:px-16">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900">
          You are on the{' '}
          <span className="text-blue-600">
            {SubscriptionTiersNameMap.get(subscription.currentTier)}
          </span>
          &nbsp;plan&nbsp;
          {isTrial && 'Trial '}
          🚀
        </h2>
        {accountType === AccountTypes.Startup && (
          <p className="mt-3 text-lg font-medium leading-8 text-gray-900">
            You have&nbsp;
            <span className="font-medium text-purple-800">
              <BoltIcon className="-mt-0.5 mr-0.5 inline-flex h-3.5 w-3.5" />
              {subscription.credits} Flowlie AI credit
              {getPluralSuffix(subscription.credits)}
            </span>
            &nbsp; remaining.
            <br />
            <MailToLink
              linkText={'Get more Flowlie AI credits (25 for $10)'}
              params={{
                to: 'support@flowlie.com',
                subject: 'Purchase Flowlie AI Credits',
                body: `I would like to purchase additional Flowlie AI credits.`,
              }}
              className="hyperlink text-base"
            />
          </p>
        )}

        <p className="mt-3 text-base font-medium text-gray-700">
          {subscription.stripeSubscriptionCurrentPeriodEnd && isTrial && (
            <>
              Your free trial will end on{' '}
              {subscription.stripeSubscriptionCurrentPeriodEnd?.toLocaleDateString()}
              , and will then automatically renew.
            </>
          )}
          {subscription.stripeSubscriptionCurrentPeriodEnd &&
            !isTrial &&
            !subscription.stripeSubscriptionCancelAt && (
              <>
                Subscription will renew on{' '}
                {subscription.stripeSubscriptionCurrentPeriodEnd?.toLocaleDateString()}
              </>
            )}
          {subscription.stripeSubscriptionCurrentPeriodEnd &&
            !isTrial &&
            subscription.stripeSubscriptionCancelAt && (
              <>
                You have canceled your subscription and will lose access to all
                Pro feaures on{' '}
                {subscription.stripeSubscriptionCurrentPeriodEnd?.toLocaleDateString()}
              </>
            )}
        </p>

        <div className="mt-6 space-x-5">
          <a
            href={getFeedbackFormUrl(subscription.accountType)}
            target="_blank"
            rel="noopener noreferrer"
            className="app-button--primary"
          >
            Share Feedback
          </a>
          <button
            type="button"
            onClick={() => createStripeCustomerPortal()}
            className="app-button--neutral"
          >
            Manage subscription & billing
          </button>
        </div>

        <p className="relative mt-12 rounded-md border border-blue-700 bg-blue-600/10 p-5 font-medium text-blue-600">
          <span className="absolute -top-3 left-5 inline-flex h-fit rounded border border-blue-600 bg-blue-50 px-2 py-0.5 text-xs font-semibold leading-5 text-blue-600">
            BONUS!
          </span>
          {accountType === AccountTypes.Investor &&
            'Book a call with our co-founders to get the most out of Flowlie. The best time slots fill up fast, so we encourage you to claim your bonus ASAP.'}
          {accountType === AccountTypes.Startup &&
            'Book a call with our co-founders to get a free fundraising consultation and learn how to get the most out of Flowlie. The best time slots fill up fast, so we encourage you to claim your bonus ASAP.'}
          <br />
          <a
            href={MARK_FUNDRAISING_CONSULTATION}
            target="_blank"
            rel="noopener noreferrer"
            className="app-button--primary mt-4"
          >
            {'Claim "Flowlie Fast-track" Bonus'}
          </a>
        </p>
        <p className="mt-6 font-medium leading-8 text-gray-600">
          If you have any questions about your subscription, email us at&nbsp;
          <a
            href="mailto:support@flowlie.com"
            className="underline hover:text-gray-900"
          >
            support@flowlie.com
          </a>
        </p>
      </div>

      {accountType === AccountTypes.Startup &&
        subscription.currentTier === SubscriptionTiers.StartupPro && (
          <PremiumCTA
            id="cta_upgrade_to_flowlie_premium"
            href={MARK_SALES_CALL}
            content={`Do you want hands-on support from a dedicated expert? With our premium services you get a dedicated analyst to help you find target investors, handle outreach, negotiate term sheets, and more. Book a free call to see if you qualify.`}
          />
        )}
    </section>
  );
}

export default ManagePaidSubscriptionCard;
