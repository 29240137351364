import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '/src/services/AuthService';
import { SharedStateContext } from '../../contexts/SharedStateContext';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { localStorageGetAuthenticatedAccountType } from '/src/middleware/LocalStorage';
import { NOT_FOUND_ROUTE, LOGIN_ROUTE } from '/src/constants/Routes';

export interface ProtectedRouteProps {
  accountType?: AccountTypes;
  authenticationPath?: string;
  children: JSX.Element;
  path?: string;
}

const ProtectedRoute = ({
  accountType,
  authenticationPath = LOGIN_ROUTE,
  children,
  path,
}: ProtectedRouteProps): JSX.Element => {
  const { setCachedPage } = useContext(SharedStateContext);

  const validAccountType =
    !accountType || localStorageGetAuthenticatedAccountType() === accountType;

  if (!validAccountType && AuthService.isLoggedIn()) {
    return <Navigate to={NOT_FOUND_ROUTE} />;
  }

  if (AuthService.isLoggedIn()) {
    return children;
  }

  if (path) {
    // Set the window.pathname so it includes the path to any sub routes
    setCachedPage({
      url: window.location.pathname,
      accountType: accountType ?? null,
    });
  }

  return <Navigate replace to={{ pathname: authenticationPath }} />;
};

export default ProtectedRoute;
