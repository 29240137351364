import React from 'react'
import { InvestorListItemView } from '../../../types/view/InvestorListView';
import { displayMoneyRange } from '/src/util/formatting/numbers';

export interface CellCheckSizeProps {
  checkRangeMin?: number,
  checkRangeMax?: number
}

function CellCheckSize({ checkRangeMin, checkRangeMax }: CellCheckSizeProps) {
  return (
    <div className="app-table-row-text">
      {checkRangeMin !== undefined &&
        checkRangeMax !== undefined
        ? displayMoneyRange(
          checkRangeMin,
          checkRangeMax,
        )
        : '-'}
    </div>
  )
}

export default CellCheckSize