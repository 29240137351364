import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AccountContext } from '../../../contexts/Accounts';
import {
  AccountTypes,
  PosthogAccountTypesEnum,
} from '/../libs/shared-types/src/constants/AccountTypes';
import { loginUser } from '/src/services/LoginUser';
import { formatImageAddress } from '/src/util/formatting/strings';
import { FormOptions } from './FormOptions';
import {
  getVisitorNameAndEmail,
  saveVisitorNameAndEmail,
} from '/src/middleware/SessionStorage';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import { PublicProfilePrivacySetting } from '/../libs/shared-types/src/constants/PublicProfilePrivacySetting';
import { SharedStateContext } from '../../../contexts/SharedStateContext';
import { signupUser } from '/src/services/SignupUser';
import {
  SCREENING_ROUTE,
  SIGNUP_SUCCESS_ROUTE,
  ACCOUNT_CHOICE_ROUTE,
  STARTUP_DASHBOARD_ROUTE,
} from '/src/constants/Routes';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import AuthForm from '../AuthForm';
import AuthFormTypes from '/src/enums/AuthFormTypes';
import VisitorSessionForm from '../VisitorSessionForm';
import { AuthStatus } from '/src/enums/AuthStatus';
import { localStorageGetAuthenticatedAccountType } from '/src/middleware/LocalStorage';
import Alert from '../../notifications/Alert';
import AuthDialogWrapper from './AuthDialogWrapper';
import {
  AuthDialogConfig,
  AuthDialogData,
  configureForm,
} from './AuthDialogConfiguration';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';
import { Posthog } from '/src/middleware/Posthog';
import Logger from '/src/services/logger';

interface AuthDialogProps {
  config: AuthDialogConfig;
  canCloseForm: boolean;
  initialForm: FormOptions;
  linkType: LinkTypes;
  onVisitorSessionCreated: () => Promise<void>;
  privacySetting: PublicProfilePrivacySetting;
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  parentError?: string;
}

function AuthDialog({
  config,
  canCloseForm,
  initialForm,
  linkType,
  onVisitorSessionCreated,
  privacySetting,
  isOpen,
  setIsOpen,
  parentError,
}: AuthDialogProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isVisitorSessionFormLoading, setIsVisitorSessionFormLoading] =
    useState(false);
  const { cognitoAuthenticate } = useContext<any>(AccountContext);
  const { cachedPage, setCachedPage } = useContext(SharedStateContext);
  const authenticationContext = useContext(AuthenticationContext);
  const [currentForm, setCurrentForm] = useState<FormOptions>();
  const [form, setForm] = useState<AuthDialogData>(
    configureForm(linkType, privacySetting, config),
  );

  const navigate = useNavigate();

  const hideAlert = () => setErrorMessage('');

  useEffect(() => {
    if (!parentError) {
      hideAlert();
      return;
    }
    setErrorMessage(parentError);
  }, [parentError]);

  const handleSignupUser = async (formValues: any) => {
    try {
      await signupUser(formValues, authenticationContext);
      navigate(SIGNUP_SUCCESS_ROUTE);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const handleLoginUser = async (formValues: any) => {
    try {
      const loginResult = await loginUser(
        formValues,
        cognitoAuthenticate,
        authenticationContext,
      );
      if (
        cachedPage !== null &&
        cachedPage.url !== '/app/access-requests/requests-made'
      ) {
        setCachedPage(null);
      }

      if (loginResult === AuthStatus.COMPLETE_ONBOARDING) {
        navigate(ACCOUNT_CHOICE_ROUTE);
        return;
      }

      if (loginResult === AuthStatus.FULL_ACCOUNT) {
        const accountType = localStorageGetAuthenticatedAccountType();
        if (accountType === AccountTypes.Startup) {
          navigate(STARTUP_DASHBOARD_ROUTE);
        }

        if (
          accountType === AccountTypes.Investor &&
          linkType === LinkTypes.Dealshare
        ) {
          window.location.reload();
          return;
        }

        if (accountType === AccountTypes.Investor) {
          navigate(SCREENING_ROUTE);
        }
      }
    } catch (errorString: any) {
      setErrorMessage(errorString);
    }
  };

  async function handleSubmitVisitorSessionForm(values: any) {
    try {
      setIsVisitorSessionFormLoading(true);

      saveVisitorNameAndEmail(
        values[AuthFieldNames.FirstName],
        values[AuthFieldNames.LastName],
        values[AuthFieldNames.Email],
      );
      const posthogClient = new Posthog();
      posthogClient.identify(values[AuthFieldNames.Email]);
      posthogClient.addUserProperties({
        email: values[AuthFieldNames.Email],
        name: `${values[AuthFieldNames.FirstName]} ${
          values[AuthFieldNames.LastName]
        }`,
        type: PosthogAccountTypesEnum.Visitor,
        sub_type: PosthogAccountTypesEnum.Visitor,
      });

      await onVisitorSessionCreated();
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsVisitorSessionFormLoading(false);
    }
  }

  const toggleFormType = () => {
    if (currentForm === FormOptions.Login) {
      setCurrentForm(FormOptions.Signup);
    } else if (currentForm === FormOptions.Signup) {
      setCurrentForm(FormOptions.Login);
    } else if (currentForm === FormOptions.VisitorSession) {
      setCurrentForm(FormOptions.Login);
    }
    setErrorMessage('');
  };

  useEffect(() => {
    setCurrentForm(initialForm);
  }, [initialForm]);

  useEffect(() => {
    setForm(configureForm(linkType, privacySetting, config));
  }, [config.consumerAccountType]);

  const handleClose = () => {
    if (setIsOpen) {
      setIsOpen(!canCloseForm)
    }
  }

  return (
    <AuthDialogWrapper
      wrapperConfig={{
        title: form.title,
        subTitle: form.subTitle,
        imageSrc: formatImageAddress(config.companyLogo) ?? '',
        imageAlt: 'Company logo',
      }}
      showXButton={canCloseForm}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div>
        <section>
          {currentForm === FormOptions.Login && (
            <AuthForm
              authenticateOnSubmit={handleLoginUser}
              formType={AuthFormTypes.Login}
              showUrlRedirectLinks={false}
              preFilledValues={getVisitorNameAndEmail()}
            />
          )}

          {currentForm === FormOptions.Signup && (
            <AuthForm
              authenticateOnSubmit={handleSignupUser}
              formType={AuthFormTypes.Signup}
              showUrlRedirectLinks={false}
              preFilledValues={getVisitorNameAndEmail()}
            />
          )}

          {currentForm === FormOptions.VisitorSession && (
            <VisitorSessionForm
              handleOnSubmit={handleSubmitVisitorSessionForm}
              isLoading={isVisitorSessionFormLoading}
            />
          )}

          <Alert
            color="red"
            alertType="Error"
            content={errorMessage}
            isShown={errorMessage !== ''}
            onClose={hideAlert}
          />
        </section>

        <section className="mt-8 flex w-full flex-col justify-center px-8">
          {privacySetting !==
            PublicProfilePrivacySetting.RequireNameAndEmail && (
            <button
              type="button"
              onClick={toggleFormType}
              className="mb-2 text-sm font-normal text-blue-500 hover:underline"
            >
              {currentForm === FormOptions.Login &&
                'First time here? Create an account'}
              {(currentForm === FormOptions.Signup ||
                currentForm === FormOptions.VisitorSession) &&
                'Already have an account? Log in'}
            </button>
          )}

          {form.footerText && (
            <p className="mt-1 text-center text-2xs font-light text-gray-500">
              {form.footerText}
            </p>
          )}
        </section>
      </div>
    </AuthDialogWrapper>
  );
}

export default AuthDialog;
