import React from 'react';

import { AggregatedInvestorReferral } from '/../libs/shared-types/src/types/view/APIResponse';
import PaginatedTable from '/src/components/table/PaginatedTable';
import ReferredInvestorTableRow from './ReferredInvestorTableRow';

const headerValues = [
  { element: <span>Name</span> },
  { element: <span>Type</span> },
  { element: <span>Firm & Role</span> },
  { element: <span>Joined</span> },
];

function ReferredInvestorsTable({
  referrals,
}: {
  referrals: AggregatedInvestorReferral[];
}): JSX.Element {
  if (referrals.length === 0) {
    return <p>You have not referred any investors.</p>;
  }

  return (
    <div>
      <PaginatedTable
        headers={headerValues}
        rowsPerPage={5}
        rowComponents={referrals.map((referral) => (
          <ReferredInvestorTableRow referral={referral} key={referral.id} />
        ))}
      />
    </div>
  );
}

export default ReferredInvestorsTable;
