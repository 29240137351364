import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useContext, useEffect } from 'react';
import {
  formatImageAddress,
  joinClassNames,
} from '/src/util/formatting/strings';
import AuthService from '/src/services/AuthService';
import UserAvatarWithInitials from '../../UserAvatarWithInitials';
import { Link } from 'react-router-dom';
import { AccountMetadataContext } from '../../../contexts/AccountMetadataContext';
import { SubscriptionTierPill } from '../../SubscriptionTierPill';
import { EnvironmentTypes } from '/../libs/shared-types/src/constants/EnvironmentTypes';
import ToggleSwitch from '../../utility/ToggleSwitch';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import {
  localStorageGetDebugMode,
  localStorageGetIsDarkTheme,
} from '/src/middleware/LocalStorage';

const userNavigation = [
  // { name: 'Account Settings', href: '#' },
  // { name: 'Manage Notifications', href: '#' },
  { name: 'Manage Subscription', href: 'settings/billing' },
];

interface AccountDropDownMenuProps {
  email: string;
}

function AccountDropDownMenu({ email }: AccountDropDownMenuProps): JSX.Element {
  const {
    subscriptionTier,
    firstName,
    lastName,
    profilePicKey,
    refreshUserMetadata,
  } = useContext(AccountMetadataContext);

  useEffect(() => {
    refreshUserMetadata();
  }, []);

  if (!subscriptionTier || !firstName || !lastName) {
    return <></>;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="-m-1.5 flex items-center p-1.5">
        <span className="sr-only">Open account menu</span>
        <UserAvatarWithInitials
          containerStyles="h-10 w-10"
          firstName={firstName}
          lastName={lastName}
          textStyles="text-xl"
          imgSrc={formatImageAddress(profilePicKey)}
          imgAlt="Account Avatar"
        />
        <span className="hidden lg:flex lg:items-center">
          <span
            className="ml-4 text-sm font-semibold leading-6 text-gray-900"
            aria-hidden="true"
          >
            {`${firstName} ${lastName}`}
          </span>
          <ChevronDownIcon
            className="ml-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-10 bottom-12 z-10 mt-2 min-w-max origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-4 py-3">
            <div className="flex w-full flex-row items-center justify-between space-x-6">
              <p className="text-sm">Signed in as</p>
              <SubscriptionTierPill />
            </div>
            <p
              title={email}
              className="truncate text-sm font-medium text-gray-900"
            >
              {email}
            </p>
          </div>
          <div className="py-1">
            {userNavigation.map((item) => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <Link
                    to={item.href}
                    className={joinClassNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    {item.name}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={joinClassNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block w-full px-4 py-2 text-left text-sm'
                  )}
                  onClick={AuthService.logout}
                >
                  Log Out
                </button>
              )}
            </Menu.Item>
          </div>

          {process.env.REACT_APP_ENV !== EnvironmentTypes.Production && (
            <div className="space-y-3 px-4 pt-2 pb-1 text-sm text-gray-700">
              <ToggleSwitch
                showToggleIcon
                toggleOn={() =>
                  localStorage.setItem(LocalStorageKeysConst.DEBUG_MODE, 'true')
                }
                toggleOff={() =>
                  localStorage.setItem(
                    LocalStorageKeysConst.DEBUG_MODE,
                    'false'
                  )
                }
                name="enableDebugMode"
                label="Debug Mode"
                initialState={localStorageGetDebugMode()}
              />
              <ToggleSwitch
                showToggleIcon
                toggleOn={() => {
                  localStorage.setItem(
                    LocalStorageKeysConst.IS_DARK_THEME,
                    'true'
                  );
                  document.documentElement.classList.add('dark');
                }}
                toggleOff={() => {
                  localStorage.setItem(
                    LocalStorageKeysConst.IS_DARK_THEME,
                    'false'
                  );
                  document.documentElement.classList.remove('dark');
                }}
                name="enableDarkTheme"
                label="Dark Theme"
                initialState={localStorageGetIsDarkTheme()}
              />
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default AccountDropDownMenu;
