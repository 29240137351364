import React from 'react';
import * as yup from 'yup';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import {
  CHECK_RANGE_MAX,
  CHECK_RANGE_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import { numberWithCommas } from '/src/util/formatting/numbers';
import FormField from '/src/interfaces/FormField';
import MoneyInput from '/src/components/inputs/MoneyInput';

export function checkRangeMinSchema({ required = false } = {}) {
  let schema = yup
    .number()
    .typeError('Must be a number')
    .min(
      CHECK_RANGE_MIN,
      `Must be at least $ ${numberWithCommas(CHECK_RANGE_MIN)}`,
    )
    .max(
      CHECK_RANGE_MAX,
      `Cannot be greater than $ ${numberWithCommas(CHECK_RANGE_MAX)}`,
    )
    .test(
      'onlyHundredMultiples',
      'Only multiples of $100 are accepted',
      (value): boolean => {
        if (value && value > 0 && value % 100 !== 0) {
          // Return false to indicate the test failed
          return false;
        }
        return true;
      },
    );

  if (required) {
    schema = schema.required('Required');
  }

  return schema;
}

const CheckRangeMinField: FormField = {
  fieldComponent: (
    <MoneyInput
      label="Minimum Check Size"
      name={InvestorFieldNames.CheckRangeMin}
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.CheckRangeMin]: checkRangeMinSchema({ required: true }),
  }),
};

export const checkRangeMinFieldOptional: FormField = {
  ...CheckRangeMinField,
  validation: yup.object({
    [InvestorFieldNames.CheckRangeMin]: checkRangeMinSchema({
      required: false,
    }),
  }),
};

export default CheckRangeMinField;
