import React, { useState } from 'react';
import { EXAMPLE_INVESTOR } from '/../libs/shared-types/src/constants/UserActivation/MockData/ExampleInvestor';
import StartupActivationTours from '/../libs/shared-types/src/constants/UserActivation/StartupTours';
import ActivationTour, { ActivationStep } from './ActivationTour';
import {
  STARTUP_INVESTOR_LEADS_ROUTE,
  STARTUP_FLOWLINK_MANAGER_ROUTE,
  STARTUP_ACCESS_MANAGER_ROUTE,
  STARTUP_PROFILE_PREVIEW_ROUTE,
  STARTUP_DASHBOARD_ROUTE,
  INVESTOR_DETAIL,
} from '/src/constants/Routes';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import { StartupUserActivationTours } from '/../libs/shared-types/src/constants/ApiRoutes';
import ModalWrapper from '../notifications/ModalWrapper';
import FeedbackForm from './FeedbackForm';

export const NewStartupFirstLogInSteps: ActivationStep[] = [
  {
    redirectUrl: STARTUP_DASHBOARD_ROUTE,
    target: 'body',
    content:
      'Welcome to Flowlie! This tour will teach you how to update your one-pager, send it to investors, and track their engagement.',
    placement: 'center',
    disableBeacon: true,
    locale: {
      next: 'Give me a quick tour!',
    },
    showProgress: false,
  },
  {
    redirectUrl: STARTUP_PROFILE_PREVIEW_ROUTE,
    target: '.joyride-startupProfilePreview',
    content:
      "This is how your company's one-pager looks to the investors you share it with. It includes all the relevant information, your deck, and round details in the best format for their review. You can update it anytime.",
    disableBeacon: true,
    placement: 'top-end',
  },
  {
    redirectUrl: STARTUP_FLOWLINK_MANAGER_ROUTE,
    target: '.joyride-flowLinkManager',
    content:
      'When you finish updating your one-pager and deck, use your FlowLink to share it with investors. You can select your desired privacy setting and send everything in one secure link.',
    disableBeacon: true,
    placement: 'bottom',
  },
  {
    redirectUrl: `${STARTUP_ACCESS_MANAGER_ROUTE}/investors-engaged`,
    target: '.joyride-accessManagerFirstRow',
    content:
      'When you send someone your FlowLink and they view your one-pager, they will show up here, so you can monitor their activity including how long they spent looking and what deck version they saw.',
    disableBeacon: true,
    placement: 'bottom',
  },
  {
    redirectUrl: STARTUP_INVESTOR_LEADS_ROUTE,
    target: '.joyride-investorDatabaseFirstRow',
    content:
      'You can use the Investor Database to find potential investors. Our algorithms find investors who are best suited for your startup so you can begin your research process. Let’s click on John.',
    disableBeacon: true,
    placement: 'bottom',
  },
  {
    redirectUrl: `${INVESTOR_DETAIL}/${EXAMPLE_INVESTOR._id}`,
    target: '.joyride-investorDetailThesis',
    content:
      'All investor profiles show detailed investment thesis information and their preferred contact channel. If you reach out to them, make sure to include your FlowLink as part of the message.',
    disableBeacon: true,
    placement: 'auto',
    locale: {
      last: 'Complete',
    },
  },
];

export function NewStartupFirstLogInTour(): JSX.Element {
  const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false);

  function handleFeedbackFormSubmit() {
    setIsFeedbackFormOpen(false);

    // This is a bit of hack
    // Reload the page to reset the state of Joyride, so that scrolling is not "stuck"
    window.location.replace(STARTUP_DASHBOARD_ROUTE);
  }

  function onTourFinished() {
    setIsFeedbackFormOpen(true);
  }

  return (
    <>
      <ActivationTour
        localStorageKey={
          LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING
        }
        steps={NewStartupFirstLogInSteps}
        tourApiRoute={StartupUserActivationTours.buildEndpoint()}
        tourId={StartupActivationTours.NewUserFirstLogIn}
        onTourFinished={onTourFinished}
      />
      <ModalWrapper
        open={isFeedbackFormOpen}
        onClose={() => setIsFeedbackFormOpen(true)}
      >
        <div className="bg-white p-4 sm:max-w-2xl sm:p-7">
          <header className="mb-3 text-lg font-medium text-gray-700">
            Thank you for joining Flowlie! <span className="text-xl">🚀</span>
          </header>
          <FeedbackForm onSubmit={handleFeedbackFormSubmit} />
        </div>
      </ModalWrapper>
    </>
  );
}
