import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import { EXTRA_LONG_TEXTAREA_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import TextCharacterCounter from '../../TextCharacterCounter';

function FieldWithCharCount(): JSX.Element {
  const { values } = useFormikContext<any>();

  return (
    <>
      <Field
        component={FormikInput}
        boxType="textarea"
        customStyle="h-36 max-h-48"
        label="Additional product and customer traction information"
        name={StartupFieldNames.AdditionalTractionInfo}
        secondaryLabel="Investors want to see proof of your commercial progress, use this space to share further details."
        tooltip="You can write about your current or upcoming pilots, progress on the sales pipeline, key product launches, regulatory deadlines, or more. Focus on the most important proof-points of product market fit."
        type="text"
        placeholder="Ex: Since last summer we have begun paid pilots with Company X and Company Y for which we both have CEO sponsorship. We are now getting ready to onboard Company Z which could lead to a seven-figure ARR contract as early as next year. The customer feedback has been overwhelmingly positive and referrals are available upon request."
      />
      <TextCharacterCounter
        textLength={
          values[StartupFieldNames.AdditionalTractionInfo]?.length ?? 0
        }
        maxLength={EXTRA_LONG_TEXTAREA_LENGTH_MAX}
      />
    </>
  );
}

const AdditionalTractionInfoField: FormField = {
  fieldComponent: <FieldWithCharCount />,
  validation: yup.object({
    [StartupFieldNames.AdditionalTractionInfo]: yup
      .string()
      .max(
        EXTRA_LONG_TEXTAREA_LENGTH_MAX,
        `Must be at most ${EXTRA_LONG_TEXTAREA_LENGTH_MAX} characters`
      ),
  }),
};

export default AdditionalTractionInfoField;
