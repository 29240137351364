import React, { useContext, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { signupUser } from '/src/services/SignupUser';
import Alert from '/src/components/notifications/Alert';
import AuthenticationForm from '/src/components/Authentication/AuthForm';
import AuthFormTypes from '/src/enums/AuthFormTypes';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';
import { LOGIN_ROUTE, SIGNUP_SUCCESS_ROUTE } from '/src/constants/Routes';
import flowlieLogo from '/src/res/flowlie_f.svg';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { CheckIcon } from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/20/solid';
import {
  TESTIMONIALS_INVESTORS,
  TESTIMONIALS_STARTUP,
} from '../Testimonials/TestimonialsData';
import TestimonialCarousel from '../Testimonials/TestimonialCarousel';

function StarRating(): JSX.Element {
  return (
    <div className="flex flex-col-reverse items-center justify-center sm:divide-gray-300 lg:inline-flex lg:flex-row">
      <div className="flex shrink-0 pr-[1.8rem]">
        <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        <StarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        <span className="relative -ml-[1.8rem] overflow-hidden">
          <StarIcon
            className="relative -right-1/2 h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </span>
      </div>
      <div className="min-w-0 flex-1 py-1 text-sm text-gray-500 sm:px-5">
        <span className="font-medium text-gray-900">Rated 4.8 stars</span>
        &nbsp;by over&nbsp;
        <span className="font-medium text-blue-500">
          2,000 users in 60+ countries across the world
        </span>
      </div>
    </div>
  );
}

const startupSignUpCopy = [
  'Plan your round',
  'Prepare your investor outreach',
  'Share your deck and company one-pager',
  'Track investor engagement',
];

const investorSignUpCopy = [
  'All your deals in a standard format in one place',
  'Deal screening with Fit score',
  'Track your investor network',
  'AI-powered deal sharing',
];

function getCopyForAccountType(accountType: AccountTypes | undefined) {
  if (accountType === AccountTypes.Investor) {
    return investorSignUpCopy;
  }

  if (accountType === AccountTypes.Startup) {
    return startupSignUpCopy;
  }

  return [];
}

function getTestimonialsForAccountType(accountType: AccountTypes | undefined) {
  if (accountType === AccountTypes.Investor) {
    return TESTIMONIALS_INVESTORS;
  }

  if (accountType === AccountTypes.Startup) {
    return TESTIMONIALS_STARTUP;
  }

  return [];
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

function SignupRoute(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const authenticationContext = useContext(AuthenticationContext);
  const toggleAlert = () => setErrorMessage('');
  const navigate = useNavigate();

  const handleSignupUser = async (formValues: FormValues) => {
    try {
      await signupUser(formValues, authenticationContext);
      navigate(SIGNUP_SUCCESS_ROUTE);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const testimonials = useMemo(
    () =>
      getTestimonialsForAccountType(
        authenticationContext.unconfirmedAccountType,
      ),
    [authenticationContext],
  );

  return (
    <main className="flex h-screen w-full items-center justify-center bg-gray-50">
      <Link
        to={LOGIN_ROUTE}
        className="absolute top-8 mx-auto flex shrink-0 items-center px-4 dark:text-white"
      >
        <img className="h-10 w-auto" src={flowlieLogo} alt="flowlie" />
        <div className="ml-2 flex flex-col">
          <h1 className="-mb-1 text-xl font-bold tracking-wide">flowlie</h1>
        </div>
      </Link>
      <div className="relative flex w-full max-w-md flex-col items-center px-8">
        <div className="absolute top-52 -left-4 hidden h-72 w-72 animate-blob rounded-full bg-green-300 opacity-70 mix-blend-multiply blur-xl filter sm:block" />
        <div className="animation-delay-2000 absolute top-24 -right-4 hidden h-72 w-72 animate-blob rounded-full bg-blue-300 opacity-70 mix-blend-multiply blur-xl filter sm:block" />
        <div className="animation-delay-4000 absolute bottom-0 left-20 hidden h-96 w-96 animate-blob rounded-full bg-teal-300 opacity-70 mix-blend-multiply blur-xl filter sm:block" />
        <div className="flex items-center md:space-x-8 md:rounded-lg md:bg-blue-200 md:p-4">
          <aside className="hidden rounded-lg p-4 md:block md:w-[20rem] lg:w-[32rem]">
            {!authenticationContext.unconfirmedAccountType && (
              <div>
                <h4 className="text-3xl font-bold text-black">
                  Get started with Flowlie
                </h4>
                <p className="text-xl font-semibold">
                  The next-gen OS for venture capital
                </p>
                <p className="mt-4">
                  A Fundraising Hub for early-stage founders.
                </p>
                <p>A Digital analyst for angel investors.</p>
                <div className="mt-10 p-4 ">
                  <StarRating />
                </div>
              </div>
            )}

            <h4 className="text-3xl font-bold text-black">
              {authenticationContext.unconfirmedAccountType ===
                AccountTypes.Startup && (
                <>
                  Build your dream cap table with Flowlie&apos;s all-in-one
                  Fundraising Hub
                </>
              )}
              {authenticationContext.unconfirmedAccountType ===
                AccountTypes.Investor && (
                <>
                  Close the right deals faster with Flowlie&apos;s digital
                  analyst
                </>
              )}
            </h4>

            <ul className="mt-6 space-y-3 leading-6 text-gray-800 xl:mt-8">
              {getCopyForAccountType(
                authenticationContext.unconfirmedAccountType,
              ).map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-blue-600"
                    aria-hidden="true"
                  />
                  <p className="text-lg font-semibold">{feature}</p>
                </li>
              ))}
            </ul>

            <div className="hidden lg:block">
              {testimonials.length > 0 && (
                <TestimonialCarousel testimonials={testimonials} />
              )}
            </div>
          </aside>

          <section className="relative mt-32 sm:mt-0 sm:rounded-xl sm:bg-white/70 sm:p-8 sm:shadow-xl">
            <h3 className="mb-2 text-center text-3xl font-bold text-gray-800">
              Sign Up
            </h3>
            <AuthenticationForm
              authenticateOnSubmit={handleSignupUser}
              formType={AuthFormTypes.Signup}
            />
            <Alert
              color="red"
              alertType="Error"
              content={errorMessage}
              isShown={errorMessage !== ''}
              onClose={toggleAlert}
            />
          </section>
        </div>
      </div>
    </main>
  );
}

export default SignupRoute;
