import { MIN_DATE } from '/../libs/shared-types/src/constants/MinimumDate';
import { CRON_INTERVAL } from '/../libs/shared-types/src/constants/time';

export function isMinDate(date: Date | undefined): boolean {
  if (date === undefined) {
    return false;
  }
  return date.getTime() === MIN_DATE.getTime();
}

export function roundUpToCronInterval(date: Date): Date {
  return new Date(Math.ceil(date.getTime() / CRON_INTERVAL) * CRON_INTERVAL);
}
