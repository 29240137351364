import React from 'react';

import { LinkIcon } from '@heroicons/react/20/solid';
import generateSocialHandles from '/src/util/generateSocialHandles';
import { SocialHandle } from '/../libs/shared-types/src/types/model/SocialHandle';
import {
  AngelList,
  CrunchBase,
  Discord,
  LinkedIn,
  Twitter,
} from '/src/components/CustomIcons';

export enum SocialHandleName {
  AngelList = 'AngelList',
  CrunchBase = 'CrunchBase',
  Discord = 'Discord',
  LinkedIn = 'LinkedIn',
  Twitter = 'Twitter',
  Other = 'Other',
}

function getSocialHandles(
  socialUrls: { name: SocialHandleName; url: string | undefined }[],
): SocialHandle[] {
  const socialHandles: SocialHandle[] = [];
  socialUrls.forEach((x) => {
    if (!x.url) {
      return;
    }

    if (x.name === SocialHandleName.AngelList) {
      socialHandles.push({
        icon: <AngelList />,
        name: SocialHandleName.AngelList,
        url: x.url,
      });
    }
    if (x.name === SocialHandleName.CrunchBase) {
      socialHandles.push({
        icon: <CrunchBase />,
        name: SocialHandleName.CrunchBase,
        url: x.url,
      });
    }
    if (x.name === SocialHandleName.Discord) {
      socialHandles.push({
        icon: <Discord />,
        name: SocialHandleName.Discord,
        url: x.url,
      });
    }
    if (x.name === SocialHandleName.LinkedIn) {
      socialHandles.push({
        icon: <LinkedIn />,
        name: SocialHandleName.LinkedIn,
        url: x.url,
      });
    }
    if (x.name === SocialHandleName.Twitter) {
      socialHandles.push({
        icon: <Twitter />,
        name: SocialHandleName.Twitter,
        url: x.url,
      });
    }
    if (x.name === SocialHandleName.Other) {
      socialHandles.push({
        icon: <LinkIcon className="hyperlink h-6 w-5" />,
        name: SocialHandleName.Other,
        url: x.url,
      });
    }
  });
  return socialHandles;
}

export interface SocialHandlesProps {
  socialUrls: { name: SocialHandleName; url: string | undefined }[];
}

function SocialHandles({ socialUrls }: SocialHandlesProps): JSX.Element {
  const socialHandles = getSocialHandles(socialUrls);
  if (socialHandles.length === 0) {
    return <></>;
  }

  return (
    <section aria-labelledby="social handles">
      {socialHandles.length > 0 && (
        <span className="flex flex-wrap space-x-2">
          {generateSocialHandles(socialHandles)}
        </span>
      )}
    </section>
  );
}

export default SocialHandles;
