import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormField from '/src/interfaces/FormField';

import AccountPhoneField from './Fields/AccountPhoneField';
import AdditionalTractionInfoField from './Fields/AdditionalTractionInfoField';
import AngelListField from './Fields/AngelListField';
import AvgOrderValueField from './Fields/AvgOrderValueField';
import AvgRevenuePerUserField from './Fields/AvgRevenuePerUserField';
import AvgTakeRateField from './Fields/AvgTakeRateField';
import BookingsAmountField from './Fields/BookingsAmountField';
import BusinessModelsField from './Fields/BusinessModelsField';
import CalendarLinkField from './Fields/CalendarLinkField';
import CompanyTypeField from './Fields/CompanyTypeField';
import CrunchbaseField from './Fields/CrunchbaseField';
import CurrentStageField from './Fields/CurrentStageField';
import CustomerAcquisitionCostField from './Fields/CustomerAcquisitionCostField';
import CustomerCountField from './Fields/CustomerCountField';
import CustomerGrowthRateMomField from './Fields/CustomerGrowthRateMomField';
import CustomerRetentionRateMomField from './Fields/CustomerRetentionRateMomField';
import DailyActiveUserCountField from './Fields/DailyActiveUserCountField';
import DeckField from './Fields/DeckField';
import DescriptionField from './Fields/DescriptionField';
import DiscordField from './Fields/Discord';
import EmployeesCountField from './Fields/EmployeesCountField';
import FoundedOnField from './Fields/FoundedOnField';
import FounderLinkedInField from './Fields/FounderLinkedInField';
import FounderRoleField from './Fields/FounderRoleField';
import FoundersFieldArray from './Fields/FoundersFieldArray';
import GeographyField from './Fields/GeographyField';
import GrossMarginField from './Fields/GrossMarginField';
import HasCustomersField from './Fields/HasCustomersField';
import HasIncubatorField from './Fields/HasIncubatorField';
import HasPendingLawsuitsField from './Fields/HasPendingLawsuitsField';
import HasPreviousBankruptcyField from './Fields/HasPreviousBankruptcyField';
import HasPreviousConvictionsField from './Fields/HasPreviousConvictionsField';
import HasUsersField from './Fields/HasUsersField';
import ImpactsField from './Fields/ImpactsField';
import IncubatorsField from './Fields/IncubatorsField';
import IndustriesField from './Fields/IndustriesField';
import IpProtectionsField from './Fields/IpProtectionsField';
import LegalEntityTypeField from './Fields/LegalEntityTypeField';
import LegalFormationStateField from './Fields/LegalFormationStateField';
import LegalNameField from './Fields/LegalNameField';
import LifeTimeValueField from './Fields/LifeTimeValueField';
import LinkedInField from './Fields/LinkedInField';
import LogoField from './Fields/LogoField';
import MonthlyActiveUserCountField from './Fields/MonthlyActiveUserCountField';
import NameField from './Fields/NameField';
import NetMarginField from './Fields/NetMarginField';
import NetMonthlyBurnField from './Fields/NetMonthyBurnField';
import NetPromoterScoreField from './Fields/NetPromoterScoreField';
import NetRevenueLastYearField from './Fields/NetRevenueLastYearField';
import NetRevenueRetentionField from './Fields/NetRevenueRetentionField';
import NetRevenueYTDField from './Fields/NetRevenueYTDField';
import OtherUrlField from './Fields/OtherUrlField';
import PilotsCountField from './Fields/PilotsCountField';
import PrimaryGrowthDriverField from './Fields/PrimaryGrowthDriverField';
import ProductDemoVideoUrlField from './Fields/ProductDemoVideoUrlField';
import ProductStatusField from './Fields/ProductStatusField';
import RevenueField from './Fields/RevenueField';
import RevenueGrowthField from './Fields/RevenueGrowthField';
import RevenueMonthlyGmvField from './Fields/RevenueMonthlyGmvField';
import RevenueTypeField from './Fields/RevenueTypeField';
import RunwayField from './Fields/RunwayField';
import TeamPresentationVideoUrlField from './Fields/TeamPresentationVideoUrlField';
import TotalAddressableMarketField from './Fields/TotalAddressableMarketField';
import TwitterField from './Fields/TwitterField';
import UserCountField from './Fields/UserCountField';
import UserGrowthRateMomField from './Fields/UserGrowthRateMomField';
import UserRetentionRateMomField from './Fields/UserRetentionRateMomField';
import WebsiteField from './Fields/WebsiteField';
import WeeklyActiveUserCountField from './Fields/WeeklyActiveUserCountField';

import DeckChangeTypeField from './Fields/DeckChangeTypeField';
import DeckChangeReasonField from './Fields/DeckChangeReasonField';
import AccountEmailField from './Fields/AccountEmailField';
import AccountFirstNameField from './Fields/AccountFirstNameField';
import AccountLastNameField from './Fields/AccountLastNameField';
import HasInventoryField from './Fields/HasInventoryField';
import IcpField from './Fields/IcpField';
import ProductTypeField from './Fields/ProductTypeField';
import FounderIsTechnicalField from './Fields/FounderIsTechnicalField';
import HighlightsFieldArray from './Fields/HighlightsFieldArray';

const STARTUP_FIELD_MAPPING: { [key: string]: FormField } = {
  [StartupFieldNames.AccountEmail]: AccountEmailField,
  [StartupFieldNames.AccountFirstName]: AccountFirstNameField,
  [StartupFieldNames.AccountLastName]: AccountLastNameField,
  [StartupFieldNames.AccountPhone]: AccountPhoneField,
  [StartupFieldNames.AdditionalTractionInfo]: AdditionalTractionInfoField,
  [StartupFieldNames.AngelList]: AngelListField,
  [StartupFieldNames.AvgOrderValue]: AvgOrderValueField,
  [StartupFieldNames.AvgRevenuePerUser]: AvgRevenuePerUserField,
  [StartupFieldNames.AvgTakeRate]: AvgTakeRateField,
  [StartupFieldNames.BookingsAmount]: BookingsAmountField,
  [StartupFieldNames.BusinessModels]: BusinessModelsField,
  [StartupFieldNames.CalendarLink]: CalendarLinkField,
  [StartupFieldNames.CompanyType]: CompanyTypeField,
  [StartupFieldNames.CrunchBase]: CrunchbaseField,
  [StartupFieldNames.CurrentStage]: CurrentStageField,
  [StartupFieldNames.CustomerAcquisitionCost]: CustomerAcquisitionCostField,
  [StartupFieldNames.CustomerCount]: CustomerCountField,
  [StartupFieldNames.CustomerGrowthRateMom]: CustomerGrowthRateMomField,
  [StartupFieldNames.CustomerRetentionRateMom]: CustomerRetentionRateMomField,
  [StartupFieldNames.DailyActiveUserCount]: DailyActiveUserCountField,
  [StartupFieldNames.Deck]: DeckField,
  [StartupFieldNames.DeckChangeReason]: DeckChangeReasonField,
  [StartupFieldNames.DeckChangeType]: DeckChangeTypeField,
  [StartupFieldNames.Description]: DescriptionField,
  [StartupFieldNames.Discord]: DiscordField,
  [StartupFieldNames.EmployeesCount]: EmployeesCountField,
  [StartupFieldNames.FoundedOn]: FoundedOnField,
  [StartupFieldNames.FounderIsTechnical]: FounderIsTechnicalField,
  [StartupFieldNames.FounderLinkedIn]: FounderLinkedInField,
  [StartupFieldNames.FounderRole]: FounderRoleField,
  [StartupFieldNames.Founders]: FoundersFieldArray,
  [StartupFieldNames.Geography]: GeographyField,
  [StartupFieldNames.GrossMargin]: GrossMarginField,
  [StartupFieldNames.HasCustomers]: HasCustomersField,
  [StartupFieldNames.HasIncubator]: HasIncubatorField,
  [StartupFieldNames.HasInventory]: HasInventoryField,
  [StartupFieldNames.HasPendingLawsuits]: HasPendingLawsuitsField,
  [StartupFieldNames.HasPreviousBankruptcies]: HasPreviousBankruptcyField,
  [StartupFieldNames.HasPreviousConvictions]: HasPreviousConvictionsField,
  [StartupFieldNames.HasUsers]: HasUsersField,
  [StartupFieldNames.Highlights]: HighlightsFieldArray,
  [StartupFieldNames.Icp]: IcpField,
  [StartupFieldNames.Impacts]: ImpactsField,
  [StartupFieldNames.Incubators]: IncubatorsField,
  [StartupFieldNames.Industries]: IndustriesField,
  [StartupFieldNames.IpProtections]: IpProtectionsField,
  [StartupFieldNames.LegalEntityType]: LegalEntityTypeField,
  [StartupFieldNames.LegalFormationState]: LegalFormationStateField,
  [StartupFieldNames.LegalName]: LegalNameField,
  [StartupFieldNames.LifeTimeValue]: LifeTimeValueField,
  [StartupFieldNames.LinkedIn]: LinkedInField,
  [StartupFieldNames.Logo]: LogoField,
  [StartupFieldNames.MonthlyActiveUserCount]: MonthlyActiveUserCountField,
  [StartupFieldNames.Name]: NameField,
  [StartupFieldNames.NetMargin]: NetMarginField,
  [StartupFieldNames.NetMonthlyBurn]: NetMonthlyBurnField,
  [StartupFieldNames.NetPromoterScore]: NetPromoterScoreField,
  [StartupFieldNames.NetRevenueLastYear]: NetRevenueLastYearField,
  [StartupFieldNames.NetRevenueRetention]: NetRevenueRetentionField,
  [StartupFieldNames.NetRevenueYTD]: NetRevenueYTDField,
  [StartupFieldNames.OtherUrl]: OtherUrlField,
  [StartupFieldNames.PilotsCount]: PilotsCountField,
  [StartupFieldNames.PrimaryGrowthDriver]: PrimaryGrowthDriverField,
  [StartupFieldNames.ProductDemoVideoUrl]: ProductDemoVideoUrlField,
  [StartupFieldNames.ProductStatus]: ProductStatusField,
  [StartupFieldNames.ProductType]: ProductTypeField,
  [StartupFieldNames.RevenueGrowth]: RevenueGrowthField,
  [StartupFieldNames.RevenueMonthlyGmv]: RevenueMonthlyGmvField,
  [StartupFieldNames.RevenueType]: RevenueTypeField,
  [StartupFieldNames.Revenue]: RevenueField,
  [StartupFieldNames.Runway]: RunwayField,
  [StartupFieldNames.TeamPresentationVideoUrl]: TeamPresentationVideoUrlField,
  [StartupFieldNames.TotalAddressableMarket]: TotalAddressableMarketField,
  [StartupFieldNames.Twitter]: TwitterField,
  [StartupFieldNames.UserCount]: UserCountField,
  [StartupFieldNames.UserGrowthRateMom]: UserGrowthRateMomField,
  [StartupFieldNames.UserRetentionRateMom]: UserRetentionRateMomField,
  [StartupFieldNames.Website]: WebsiteField,
  [StartupFieldNames.WeeklyActiveUserCount]: WeeklyActiveUserCountField,
};

export default STARTUP_FIELD_MAPPING;
