import React from 'react';
import {
  PlusCircleIcon,
  ArrowRightCircleIcon,
  CheckCircleIcon,
  XCircleIcon
} from '@heroicons/react/24/outline';
import DateString from '/src/components/utility/DateString';
import { StageStatus } from '../../../../types/model/Stage';
import { InvestorPipelineSystemStages } from '/../libs/shared-types/src/constants/InvestorPipelineSystemStages';
import { cn } from '/src/util/cn';
import { PipelineHistoryDetail } from '../../../../types/view/InvestorActivityTabView';

function GetTimelineIcon(stage: StageStatus, isFirst?: boolean): JSX.Element {
  if (isFirst) {
    return (
      <div>
        <span className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-500 ring-8 ring-white">
          <PlusCircleIcon className="h-5 w-8 text-white" />
        </span>
      </div>
    )
  }
  if (isFinalStage(stage)) {
    const isWired = stage.name === InvestorPipelineSystemStages.Wired
    return (
      <div>
        <span className={cn("flex h-8 w-8 items-center justify-center rounded-full bg-red-500 ring-8 ring-white", isWired ? "bg-green-500" : "")}>
          {isWired ?
            <CheckCircleIcon className="h-5 w-8 text-white" />
            : <XCircleIcon className="h-5 w-8 text-white" />
          }
        </span>
      </div>
    )
  }
  return (
    (
      <div>
        <span className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 ring-8 ring-white">
          <ArrowRightCircleIcon className="h-5 w-8 text-white" />
        </span>
      </div>
    )
  )

}

interface Item {
  type: StageStatus;
  label: string;
  date: Date;
  isLast: boolean;
  isInvestorFirstChange?: boolean
}

function InvestorActivityTimelineItem({ type, label, date, isLast, isInvestorFirstChange = false }: Item) {
  return (
    <li>
      <div className="relative pb-8">
        {!isLast && (
          <span
            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        )}
        <div className="relative flex space-x-3">
          {GetTimelineIcon(type, isInvestorFirstChange)}
          <div className="flex min-w-0 flex-1 items-center justify-between space-x-4">
            <div>
              <p className="text-base font-medium text-gray-900">{label}</p>
            </div>
            <div className="whitespace-nowrap text-right text-sm text-gray-500">
              <DateString date={date} tooltipPosition="top" />
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

interface InvestorPipelineActivityTimelineProps {
  pipelineHistory: PipelineHistoryDetail[]
}

const isFinalStage = (stage: StageStatus) => {
  const isFinalStage = [
    InvestorPipelineSystemStages.Wired,
    InvestorPipelineSystemStages.NotInvested
  ].includes(stage.name as InvestorPipelineSystemStages)

  return isFinalStage
}

interface GetLabelProps {
  stage: StageStatus,
  isFirstChangeInRound: boolean,
  roundName: string
}

function getLabel({ stage, isFirstChangeInRound, roundName }: GetLabelProps) {
  if (isFirstChangeInRound) {
    return `Added to ${roundName} pipeline`
  }
  if (isFinalStage(stage)) {
    return `Marked as ${stage.name.toLowerCase()}`
  }
  return `Moved to ${stage.name.toLowerCase()}`
}

function InvestorPipelineActivityTimeline({
  pipelineHistory
}: InvestorPipelineActivityTimelineProps): JSX.Element {

  return (
    <div className="rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6">
        <h2
          id="timeline-title"
          className="text-lg font-medium leading-6 text-gray-900"
        >
          Timeline
        </h2>
      </div>

      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="mt-2 flow-root">
          <ul className="-mb-8">
            {
              pipelineHistory.map((pipelineHistoryItem, pipelineHistoryItemIndex) => {
                const stageStatusChanges = pipelineHistoryItem.stageStatusChanges
                const roundName = pipelineHistoryItem.round.roundDisplayName
                const pipelineActivity = stageStatusChanges
                  ?.sort((a, b) => Number(b.createdOn) - Number(a.createdOn))
                  ?.map((stageStatusChange, index) => (
                    <InvestorActivityTimelineItem
                      key={stageStatusChange._id}
                      type={stageStatusChange.stageStatus}
                      label={getLabel({
                        stage: stageStatusChange.stageStatus,
                        isFirstChangeInRound: (stageStatusChanges.length - 1) === index,
                        roundName
                      })}
                      date={stageStatusChange.stageStatus.createdOn}
                      isLast={
                        (pipelineHistory.length - 1) === pipelineHistoryItemIndex &&
                        (stageStatusChanges.length - 1) === index
                      }
                      isInvestorFirstChange={(stageStatusChanges.length - 1) === index}
                    />
                  ))
                return pipelineActivity
              })
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

export default InvestorPipelineActivityTimeline;
