import React from 'react';
import * as yup from 'yup';
import FormikInput from '/src/components/inputs/FormikInput';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import FormField from '/src/interfaces/FormField';
import { Field } from 'formik';

const EmailField: FormField = {
  fieldComponent: (
    <Field
      component={FormikInput}
      label="Email"
      name={AuthFieldNames.Email}
      type="email"
    />
  ),
  validation: yup.object({
    [AuthFieldNames.Email]: yup
      .string()
      .max(255, 'Must be at most 255 characters')
      .email('A valid email is required')
      .required('Required'),
  }),
};

export default EmailField;
