// these are reused error messages for our application
export const INVALID_FLOWLINK_MESSAGE = 'The invitation link has expired.';
export const PROFILE_PIC_UPLOAD_FAILURE = 'Profile picture upload unsucessful.';
export const STARTUP_UPLOAD_DATA_FAILURE =
  'There was an error uploading your logo and/or deck.';
export const LINKSHARE_CONSUME_ERROR = 'Sorry! We encountered an error.';
export const GENERIC_LOGIN_ERROR =
  'Sorry! We encountered an error during log in, please contact support@flowlie.com';
export const CONFIRM_THROUGH_EMAIL_ERROR =
  'Please verify your email using the link delivered to your inbox.';
export const INCORRECT_PASSWORD = 'Incorrect username or password.';
export const LOGIN_WITH_GOOGLE = 'Please log in with Google sign in instead.';
export const UNCONFIRMED_USER_NOT_FOUND =
  'We encountered an error creating your account. There is no temporary account associated with this email.';
