import React from 'react';
import * as yup from 'yup';
import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';

import FormField from '/src/interfaces/FormField';
import BooleanSelectInput from '../../inputs/BooleanSelectInput';

const HasRightsInformationField: FormField = {
  fieldComponent: (
    <BooleanSelectInput
      label="Do you have Information Rights?"
      name={RecordInvestmentFieldNames.HasRightsInformation}
      tooltip="Information Rights are reflected in the purchase documents or the respective side letters and allow you to access the company’s books and records, inspect its facilities, and request information as well as the right to receive materials the company provides to directors and to address the board about significant business issues."
    />
  ),
  validation: yup.object({
    [RecordInvestmentFieldNames.HasRightsInformation]: yup
      .bool()
      .required('Required'),
  }),
};

export default HasRightsInformationField;
