import React from 'react';

import { STARTUP_IND_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/StartupIndustryOptions';
import { STARTUP_GEO_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/StartupGeographyOptions';
import { RoundStages } from '/../libs/shared-types/src/constants/RoundStages';
import { getColor } from './colorLookup';
import { joinClassNames } from './formatting/strings';
import { enumToList } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { getRandomInt } from './formatting/numbers';
import { useNavigate } from 'react-router-dom';
import { BILLING_ROUTE } from '../constants/Routes';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import { Strength } from '../constants/Strength';
import { SelectOption } from '../types/SelectOptions';
import {
  FitScore,
  getFitScoreColor,
} from '/../libs/shared-types/src/types/model/FitScore';
import { cn } from './cn';

const multiSelectOptionToString = (object: any) => object.value;
const allSelectValuesOrdered = STARTUP_GEO_OPTIONS.map(
  multiSelectOptionToString,
)
  .concat(STARTUP_IND_OPTIONS.map(multiSelectOptionToString))
  .concat(enumToList(InvestorTypes))
  .concat(enumToList(RoundTypes))
  .concat(enumToList(RoundStages));

function sortTagsAgainstOptions(a: string, b: string) {
  return allSelectValuesOrdered.indexOf(a) - allSelectValuesOrdered.indexOf(b);
}

export function generateWarningYesNoTag(tag: string): JSX.Element {
  if (tag === 'Yes') {
    return (
      <span key={tag} className="app-tag bg-orange-100 text-orange-800">
        {tag}
      </span>
    );
  }
  return (
    <span key={tag} className="app-tag bg-slate-100 text-slate-800">
      {tag}
    </span>
  );
}

export function generateTag(
  tag: string,
  lookupColors = false,
  title = '',
  className?: string,
): JSX.Element {
  if (lookupColors) {
    return (
      <span
        key={tag}
        className={`app-tag ${getColor(tag).bgColor} ${
          getColor(tag).textColor
        }`}
      >
        {tag}
      </span>
    );
  }
  return (
    <span
      key={tag}
      title={title}
      className={cn('app-tag bg-slate-100 text-slate-800', className)}
    >
      {tag}
    </span>
  );
}

export function generateTagCloud(
  tags: string[] | undefined,
  lookupColors = false,
): JSX.Element[] {
  if (tags === undefined) {
    return [<span key="tag" className="app-tag bg-slate-100 text-slate-800" />];
  }
  [...tags].sort(sortTagsAgainstOptions);
  return tags.map((tag) => generateTag(tag, lookupColors));
}

export function generateFitScoreTag(
  fitScore: FitScore,
  displayLabel = false,
  classes = '',
): JSX.Element {
  const colorClasses = getFitScoreColor(fitScore);

  let percentage = `${fitScore.score}%`;
  if (displayLabel) {
    percentage += ` - ${fitScore.label} Fit`;
  }

  return (
    <span
      className={joinClassNames(
        'inline-flex items-center rounded-md px-2.5 py-1 text-sm font-medium',
        colorClasses.bgColor,
        colorClasses.textColor,
        classes,
      )}
      title="Represents how well your company fits in the investor's thesis"
    >
      {percentage}
    </span>
  );
}

export function HiddenFitScore({ fixedMatch }: { fixedMatch?: number }) {
  const fitScore = fixedMatch ?? getRandomInt(50, 100);
  const colorClasses = `bg-gray-200 text-gray-800`;

  const navigate = useNavigate();

  function handleBillingLinkClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    navigate(BILLING_ROUTE);
  }

  return (
    <div
      className={joinClassNames(
        'group inline-flex select-none items-center space-x-2 rounded-md px-2.5 py-0.5 text-sm font-medium',
        colorClasses,
      )}
    >
      <span className="blur-sm">{fitScore}%</span>
      <span className="relative ml-2">
        <span className="absolute -top-1/4 right-full z-50 hidden w-48 rounded bg-white px-3 py-2 text-sm normal-case text-gray-800 shadow-2xl group-hover:block">
          <button
            className="max-w-max cursor-pointer truncate text-blue-500 hover:text-blue-600 hover:underline"
            onClick={handleBillingLinkClick}
            type="button"
            id="cta_fit_score"
          >
            Upgrade
          </button>{' '}
          to unlock fit scores. This score represents how well your company fits
          in the investor&apos;s thesis
        </span>
        <LockClosedIcon className="h-4 w-4" />
      </span>
    </div>
  );
}

export function StrengthTag({
  strength,
  options,
}: {
  strength: Strength;
  options?: SelectOption[];
}): JSX.Element {
  const color = getColor(strength.toString());
  let label = '';
  if (options) {
    label =
      options.find((x) => x.value.toString() === strength.toString())?.label ??
      '';
  }

  return (
    <span className={`app-tag ${color.bgColor} ${color.textColor}`}>
      {label ? label : strength}
    </span>
  );
}
