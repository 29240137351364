import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import { localStorageGetAuthenticatedAccountType } from '../middleware/LocalStorage';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { BaseUrls } from '/../libs/shared-types/src/constants/BaseUrls';
import { EnvironmentTypes } from '/../libs/shared-types/src/constants/EnvironmentTypes';
import {
  SCREENING_ROUTE,
  STARTUP_DASHBOARD_ROUTE,
} from '/src/constants/Routes';

export const getHomepageForAccountType = (): string => {
  if (localStorageGetAuthenticatedAccountType() === AccountTypes.Investor) {
    return SCREENING_ROUTE;
  }

  if (localStorageGetAuthenticatedAccountType() === AccountTypes.Startup) {
    return STARTUP_DASHBOARD_ROUTE;
  }

  return '/';
};

export function getFeedbackFormUrl(accountType: AccountTypes): string {
  if (accountType === AccountTypes.Investor) {
    return BaseUrls.PRODUCTION_INVESTOR_FEEBDACK_FORM;
  }

  if (accountType === AccountTypes.Startup) {
    return BaseUrls.PRODUCTION_STARTUP_FEEBDACK_FORM;
  }

  return '#';
}

const APP_ENV = process.env.REACT_APP_ENV;

export function getBaseAppUrl() {
  if (APP_ENV === EnvironmentTypes.Production) {
    return BaseUrls.PRODUCTION_APP;
  }

  if (APP_ENV === EnvironmentTypes.Development) {
    return BaseUrls.DEVELOPMENT_APP;
  }

  return BaseUrls.LOCAL_APP;
}

export function getBaseApiUrl() {
  if (APP_ENV === EnvironmentTypes.Production) {
    return BaseUrls.PRODUCTION_API;
  }

  if (APP_ENV === EnvironmentTypes.Development) {
    return BaseUrls.DEVELOPMENT_API;
  }

  return BaseUrls.LOCAL_API;
}

export function getBasePromoUrl() {
  return BaseUrls.PRODUCTION_PROMO;
}

export function getBaseBucketUrl() {
  if (APP_ENV === EnvironmentTypes.Production) {
    return BaseUrls.PRODUCTION_BUCKET_URL;
  }

  return BaseUrls.DEVELOPMENT_BUCKET_URL;
}

export const FLOWLINK_BASE_URL = `${getBaseAppUrl()}/${LinkTypes.Flowlink}/`;
export const DEALSHARE_BASE_URL = `${getBaseAppUrl()}/${LinkTypes.Dealshare}/`;
export const INVESTOR_LIST_BASE_URL = `${getBaseAppUrl()}/${LinkTypes.InvestorList}/`;
