import { StageStatus } from '../types/model/Stage';
import { sortDataByDate } from './sorting';
import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import { Deal } from '/../libs/shared-types/src/types/model/Deal';
import { DealDetailData } from '/../libs/shared-types/src/types/view/AggregatedDeals';

export function getActiveDealBoardStageStatus(
  deal: Deal,
): StageStatus | undefined {
  const latestDealStatus =
    deal.investorActivity.statusHistory[
      deal.investorActivity.statusHistory.length - 1
    ];
  if (
    latestDealStatus.status !== DealStatusTypes.Active ||
    !latestDealStatus.boardStageHistory
  ) {
    return undefined;
  }

  const latestBoardStageStatus =
    latestDealStatus.boardStageHistory[
      latestDealStatus.boardStageHistory.length - 1
    ];

  return latestBoardStageStatus;
}

export function getCurrentBoardStageStatusFromDealDetailData(
  dealDetailData: DealDetailData,
): StageStatus | undefined {
  const latestDealStatus = sortDataByDate(
    dealDetailData.investorActivity.statusHistory,
    'statusChangedOn',
  )[0];

  if (
    latestDealStatus.status !== DealStatusTypes.Active ||
    !latestDealStatus.boardStageHistory
  ) {
    return undefined;
  }

  const latestBoardStageStatus = sortDataByDate(
    latestDealStatus.boardStageHistory,
    'createdOn',
  )[0];

  return latestBoardStageStatus;
}
